import React from 'react';
import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { usePersonalDataEditorState } from 'hooks/setings/usePersonalDataEditorState';
import { useAsyncController } from '../useAsyncController';
import { AuthorizationError } from 'utils/errors';

export const useUpdateCurrentUserPersonalDataService = () => {
  const services = useServices();
  const dispatch = useAppDispatch();

  const { formValues, submited } = usePersonalDataEditorState();

  const {
    wrapAsync: wrapUpdate,
    asyncProcess: updateProcess
  } = useAsyncController<void>();

  React.useEffect(() => {
    if (submited) {
      const promise = services
        .updateCurrentUserPersonalData({
          lastName: formValues.lastName,
          firstName: formValues.firstName,
          phoneNumber: formValues.phoneNumber
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }

          return error;
        });

      wrapUpdate(promise);
    }
  }, [
    services,
    submited,
    wrapUpdate,
    formValues.lastName,
    formValues.firstName,
    formValues.phoneNumber
  ]);

  React.useEffect(() => {
    if (updateProcess.status === 'PENDING') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_PERSONAL_DATA_SERVICE',
        status: 'PENDING'
      });
    }
    if (updateProcess.status === 'RESOLVED') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_PERSONAL_DATA_SERVICE',
        status: 'RESOLVED',
        payload: updateProcess.value
      });
    }
    if (updateProcess.status === 'REJECTED') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_PERSONAL_DATA_SERVICE',
        status: 'REJECTED',
        payload: updateProcess.error
      });
    }
  }, [dispatch, updateProcess]);
};
