import React from 'react';

import styles from './BookingTimeWorkFormDailyFieldLayoutStyles.module.scss';

type Props = {
  input: React.ReactNode;
  dayLabel: React.ReactNode;
};

export const BookingTimeWorkFormDailyFieldLayoutComponent = (props: Props) => {
  const { input, dayLabel } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputSlot}>{input}</div>
      <div className={styles.dayLabelSlot}>{dayLabel}</div>
    </div>
  );
};
