import React from 'react';

import './PersonalDataForm.scss';
import { SettingsFormLegend } from 'components/SettingsForm/SettingsFormLegend';
import { SettingsFormFieldset } from 'components/SettingsForm/SettingsFormFieldset';
import { CustomInput } from 'components/shared/CustomInput';
import { SettingsFormFooter } from 'components/SettingsForm/SettingsFormFooter';
import { RectangleButton } from 'components/shared/RectangleButton';
import { SettingsFormWrapper } from 'components/SettingsForm/SettingsFormWrapper';
import { useIntlContext } from 'hooks/useIntlContext';
import { useUserInfoStrict } from 'hooks/useUserInfoCache';
import { CustomSelect } from '../CustomSelect';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useUpdateCurrentUserPersonalDataService } from 'hooks/setings/useUpdateCurrentUserPersonalDataService';
import {
  usePersonalDataFormBusy,
  usePersonalDataFormError,
  usePersonalDataFormValues,
  usePersonalDataSubmitDidSucceed
} from 'hooks/setings/usePersonalDataEditorState';
import { usePhoneNumberSelectOptions } from 'hooks/usePhoneNumberSelectOptions';

export const PersonalDataForm = () => {
  useUpdateCurrentUserPersonalDataService();
  const appDispatch = useAppDispatch();
  const userInfo = useUserInfoStrict();

  const firstNameFromCache = userInfo.firstName ? userInfo.firstName : '';
  const lastNameFromCache = userInfo.lastName ? userInfo.lastName : '';
  const phoneNumberFromCache = userInfo.phoneNumber ? userInfo.phoneNumber : '';

  const { personalDataForm: personalDataFormIntl } = useIntlContext();

  const lastNameInputRef = React.useRef<null | HTMLInputElement>(null);
  const firstNameInputRef = React.useRef<null | HTMLInputElement>(null);

  const phoneNumberSelectOptions = usePhoneNumberSelectOptions();

  const phoneNumbersLoaded = phoneNumberSelectOptions.length > 1;

  const values = usePersonalDataFormValues();

  const busy = usePersonalDataFormBusy();

  const fieldsetDisabled = busy;

  const empty = !values.lastName && !values.firstName;

  const fieldChanged =
    values.lastName !== lastNameFromCache ||
    values.firstName !== firstNameFromCache ||
    values.phoneNumber !== phoneNumberFromCache;

  const submitDidSucceed = usePersonalDataSubmitDidSucceed();

  const submitButtonLabel =
    submitDidSucceed && !fieldChanged
      ? personalDataFormIntl.submitDidSucceedLabel
      : personalDataFormIntl.submitLabel;

  const hasCancelButton = fieldChanged && !busy;

  const hasFooter = (!empty && fieldChanged) || submitDidSucceed;

  const submitButtonDisabled = busy || (submitDidSucceed && !fieldChanged);

  const submitButtonHasPreloader = busy;

  const handleLastNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      appDispatch({
        name: 'PERSONAL_DATA_FORM__LAST_NAME_INPUT_CHANGE',
        value: event.target.value
      });
    },
    [appDispatch]
  );

  const handleFirstNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      appDispatch({
        name: 'PERSONAL_DATA_FORM__FIRST_NAME_INPUT_CHANGE',
        value: event.target.value
      });
    },
    [appDispatch]
  );

  const handlePhoneNumberChange = React.useCallback(
    (nextPhoneNumber: string) => {
      appDispatch({
        name: 'PERSONAL_DATA_FORM__PHONE_NUMBER_SELECT_CHANGE',
        value: nextPhoneNumber
      });
    },
    [appDispatch]
  );

  const handleCancel = React.useCallback(() => {
    appDispatch({
      name: 'PERSONAL_DATA_FORM__CANCEL',
      oldValue: {
        firstName: firstNameFromCache,
        lastName: lastNameFromCache,
        phoneNumber: phoneNumberFromCache
      }
    });
  }, [
    appDispatch,
    firstNameFromCache,
    lastNameFromCache,
    phoneNumberFromCache
  ]);

  const handleSubmit = React.useCallback(() => {
    appDispatch({
      name: 'PERSONAL_DATA_FORM__SUBMIT'
    });
  }, [appDispatch]);

  const formError = usePersonalDataFormError();

  const lastNameErrorText =
    formError && formError === 'LASTNAME_IS_EMPTY'
      ? personalDataFormIntl.lastNameEmptyError
      : undefined;
  const firstNameErrorText =
    formError && formError === 'FIRSTNAME_IS_EMPTY'
      ? personalDataFormIntl.firstNameEmptyError
      : undefined;

  // Устанавливаем начальные значения для формы
  React.useEffect(() => {
    const { current: lastNameInputElement } = lastNameInputRef;
    const { current: firstNameInputElement } = firstNameInputRef;
    if (
      lastNameInputElement &&
      firstNameInputElement &&
      (lastNameInputElement.value !== lastNameFromCache ||
        firstNameInputElement.value !== firstNameFromCache)
    ) {
      appDispatch({
        name: 'PERSONAL_DATA_FORM__LAST_NAME_INPUT_CHANGE',
        value: lastNameFromCache
      });
      appDispatch({
        name: 'PERSONAL_DATA_FORM__FIRST_NAME_INPUT_CHANGE',
        value: firstNameFromCache
      });
      appDispatch({
        name: 'PERSONAL_DATA_FORM__PHONE_NUMBER_SELECT_CHANGE',
        value: phoneNumberFromCache
      });
    }
  }, [
    appDispatch,
    firstNameFromCache,
    lastNameFromCache,
    phoneNumberFromCache
  ]);

  React.useEffect(() => {
    const { current: lastNameInputElement } = lastNameInputRef;
    const { current: firstNameInputElement } = firstNameInputRef;
    if (formError && lastNameInputElement && firstNameInputElement) {
      if (formError === 'LASTNAME_IS_EMPTY') {
        lastNameInputElement.focus();
      }
      if (formError === 'FIRSTNAME_IS_EMPTY') {
        firstNameInputElement.focus();
      }
    }
  }, [formError]);

  return (
    <SettingsFormWrapper onSubmit={handleSubmit}>
      <SettingsFormFieldset disabled={fieldsetDisabled}>
        <SettingsFormLegend
          titleText={personalDataFormIntl.lastNameInputLabel}
          errorText={lastNameErrorText}
        />
        <CustomInput
          ref={lastNameInputRef}
          type="text"
          name="lastName"
          placeholder={personalDataFormIntl.lastNameInputPlaceholder}
          value={values.lastName}
          onChange={handleLastNameChange}
        />
        <br />
        <SettingsFormLegend
          titleText={personalDataFormIntl.firstNameInputLabel}
          errorText={firstNameErrorText}
        />
        <CustomInput
          ref={firstNameInputRef}
          type="text"
          name="firstName"
          placeholder={personalDataFormIntl.firstNameInputPlaceholder}
          value={values.firstName}
          onChange={handleFirstNameChange}
        />
        <br />
        <SettingsFormLegend
          titleText={personalDataFormIntl.phoneNumberInputLabel}
        />
        <CustomSelect
          className={'personalData__phoneNumberSelect'}
          formFieldBordered={true}
          options={phoneNumberSelectOptions}
          selected={
            phoneNumbersLoaded && values.phoneNumber != null
              ? values.phoneNumber
              : ''
          }
          onChange={handlePhoneNumberChange}
        />
      </SettingsFormFieldset>
      {hasFooter && (
        <SettingsFormFooter>
          {hasCancelButton && (
            <RectangleButton
              type="button"
              label={personalDataFormIntl.cancelLabel}
              styleType="secondary"
              onClick={handleCancel}
            />
          )}
          <RectangleButton
            label={submitButtonLabel}
            hasPreloader={submitButtonHasPreloader}
            styleType="primary"
            isDisabled={submitButtonDisabled}
          />
        </SettingsFormFooter>
      )}
    </SettingsFormWrapper>
  );
};
