import { SportingAnswerDraft } from 'services/sporting/types';
import { MessageTemplate } from 'types/messageTemplate';

export const normalizeSportingAnswerDraft = (draft: SportingAnswerDraft) => ({
  toMessageTemplate(): MessageTemplate {
    return {
      id: draft.id,
      text: draft.text || '',
      position: draft.orderNumber
    };
  }
});
