import { DeleteUserParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const deleteUser = async (params: DeleteUserParams): Promise<void> => {
  const { userId } = params;

  await sportingAPI.service.removeAdmin({
    payload: { idUser: userId }
  });
};
