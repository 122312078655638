import { Place } from 'types/state';
import { createRuntimeUniqueId } from 'utils/createRuntimeUniqueId';

let storeMock: Place[] = [];

storeMock.push({
  id: createRuntimeUniqueId(),
  title: 'Главный зал',
  location: 'RESTAURANT',
  orderNumber: 1,
  items: [
    {
      id: createRuntimeUniqueId(),
      title: 'Столик 1',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Столик 2',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Столик 3',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Столик 4',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    }
  ]
});

storeMock.push({
  id: createRuntimeUniqueId(),
  title: 'VIP зал',
  location: 'RESTAURANT',
  orderNumber: 1,
  items: [
    {
      id: createRuntimeUniqueId(),
      title: 'Столик 1',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Столик 2',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Столик 3',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    }
  ]
});

storeMock.push({
  id: createRuntimeUniqueId(),
  title: 'Лук',
  location: 'CLUB',
  orderNumber: 1,
  items: [
    {
      id: createRuntimeUniqueId(),
      title: 'Место 1',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Место 2',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Место 3',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Место 4',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    }
  ]
});

storeMock.push({
  id: createRuntimeUniqueId(),
  title: 'Тарелки',
  location: 'CLUB',
  orderNumber: 1,
  items: [
    {
      id: createRuntimeUniqueId(),
      title: 'Место 1',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Место 2',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    },
    {
      id: createRuntimeUniqueId(),
      title: 'Место 3',
      minVisitorsCount: 1,
      maxVisitorsCount: 2,
      orderNumber: 1
    }
  ]
});

const getAll = (): Place[] => [...storeMock];

const add = (item: Place) => {
  storeMock.push(item);
};

const replaceById = (id: number, nextItem: Place) => {
  storeMock = storeMock.map(item => {
    if (item.id === id) {
      return nextItem;
    }

    return item;
  });
};

const deleteById = (id: number) => {
  storeMock = storeMock.filter(item => {
    return item.id !== id;
  });
};

export const placesStoreMock = {
  getAll,
  add,
  replaceById,
  deleteById
};
