import { getSportingUserURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';
import { SportingData } from 'services/sporting/types';

export const getSportingData = async (): Promise<SportingData> => {
  const endpointURL = getSportingUserURL('getSportingData');

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  return response.json();
};
