export type ClassNameConstructor = {
  return(): string;
  withConstructor(
    consumer: (constructor: ClassNameConstructor) => ClassNameConstructor
  ): ClassNameConstructor;
  addClass(newClass: string): ClassNameConstructor;
};

export const createClassNameConstructor = (
  baseClassName: string
): ClassNameConstructor => ({
  return() {
    return baseClassName;
  },
  withConstructor(
    consumer: (constructor: ClassNameConstructor) => ClassNameConstructor
  ) {
    const constructor = consumer(createClassNameConstructor(baseClassName));

    return constructor;
  },
  addClass(newClass: string) {
    return createClassNameConstructor(`${baseClassName} ${newClass}`);
  }
});
