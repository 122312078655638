import React from 'react';

import { useAudio } from 'hooks/useAudio';
import { NOTIFICATION_SOUND_URL } from 'utils/constants';
import { useAppState } from 'hooks/useAppState';

export const useToastNotificationSound = () => {
  const playAudio = useAudio(NOTIFICATION_SOUND_URL);

  const { toastCandidates } = useAppState();

  const candidatesCount = toastCandidates.length;

  const prevCandidatesCountRef = React.useRef(0);

  React.useEffect(() => {
    const { current: prevCandidatesCount } = prevCandidatesCountRef;

    if (candidatesCount > prevCandidatesCount) {
      playAudio();
    }

    prevCandidatesCountRef.current = candidatesCount;
  }, [candidatesCount, playAudio]);
};
