import { ChatMessagesCollection, ChatMessage } from 'types/chats';
import { AppEvent } from 'types/appEvent';
import {
  modifyCollectionCache,
  createCollectionCacheSelectors
} from 'utils/collectionCacheUtils';

export const chatMessagesCollectionReducer = (
  state: ChatMessagesCollection,
  event: AppEvent
): ChatMessagesCollection => {
  switch (event.name) {
    case 'CHAT_ROOMS_SERVICE': {
      if (event.status === 'RESOLVED') {
        return event.payload
          .reduce(
            (acc, [room, message]) => acc.putItem(message.id, message),
            modifyCollectionCache(state)
          )
          .return();
      }

      return state;
    }
    case 'CHAT_ROOM_MESSAGES_SERVICE': {
      if (event.status === 'RESOLVED') {
        return event.payload
          .reduce(
            (acc, message) => acc.putItem(message.id, message),
            modifyCollectionCache(state)
          )
          .return();
      }

      return state;
    }
    case 'CHAT_MESSAGE_EDIT_SERVICE': {
      if (event.status === 'RESOLVED') {
        const message = createCollectionCacheSelectors(state).selectItem(
          event.meta.messageId
        );

        if (message) {
          const trimmedText = event.meta.text.trim();

          const nextMessage: ChatMessage = {
            ...message,
            text: trimmedText,
            edited: !!trimmedText,
            deleted: !trimmedText
          };

          return modifyCollectionCache(state)
            .putItem(event.meta.messageId, nextMessage)
            .return();
        }
      }

      return state;
    }
    case 'CHAT_MESSAGE_EDITED': {
      const message = createCollectionCacheSelectors(state).selectItem(
        event.messageId
      );

      if (message) {
        const nextMessage: ChatMessage = {
          ...message,
          text: event.text,
          edited: true
        };

        return modifyCollectionCache(state)
          .putItem(event.messageId, nextMessage)
          .return();
      }

      return state;
    }
    case 'CHAT_MESSAGE_DELETED': {
      const message = createCollectionCacheSelectors(state).selectItem(
        event.messageId
      );

      if (message) {
        const nextMessage: ChatMessage = {
          ...message,
          text: '',
          deleted: true
        };

        return modifyCollectionCache(state)
          .putItem(event.messageId, nextMessage)
          .return();
      }

      return state;
    }
    default:
      return state;
  }
};
