import { Session } from 'types/state';
import { MINUTE } from 'utils/constants';
import {
  createSessionAuthorized,
  createSessionUnauthorized
} from 'utils/createSession';
import { setCustomTimeout } from 'utils/setCustomTimeout';

let session: Session = createSessionAuthorized();

let expired: boolean = false;

let cancelExpiration: () => void;

function setSession(nextSession: Session): void {
  session = nextSession;
  expired = false;

  if (cancelExpiration) {
    cancelExpiration();
  }

  cancelExpiration = setCustomTimeout(() => {
    expired = true;
  }, MINUTE);
}

function getSession(): null | Session {
  return session;
}

function isSessionExpired(): boolean {
  return expired;
}

function deleteSession(): void {
  if (cancelExpiration) {
    cancelExpiration();
  }

  session = createSessionUnauthorized();
  expired = false;
}

export const sessionModel = {
  set: setSession,
  get: getSession,
  isExpired: isSessionExpired,
  delete: deleteSession
};
