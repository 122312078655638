import { AppEvent } from 'types/appEvent';
import { SheduleInfo } from 'types/state';
import { Cache } from 'types/util';
import { patchCache } from 'utils/patchCache';

export const sheduleInfoCacheReducer = (
  state: Cache<SheduleInfo>,
  event: AppEvent
): Cache<SheduleInfo> => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return patchCache(state)
        .invalidate()
        .done();
    }
    case 'SheduleInfoService': {
      if (event.status === 'RESOLVED') {
        return patchCache(state)
          .setValue(event.payload)
          .done();
      }

      return state;
    }
    case 'UpdateSheduleInfoService': {
      if (event.status === 'RESOLVED') {
        return patchCache(state)
          .invalidate()
          .done();
      }

      return state;
    }
    default:
      return state;
  }
};
