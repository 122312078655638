import { TUpdateEnabledSectionsParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';
import { normalizeSection } from 'services/sporting/utils/normalizeSection';

export const updateEnabledSections = async (
  params: TUpdateEnabledSectionsParams
): Promise<void> => {
  const { sectionsToEnable, sectionsToDisable } = params;

  const addPromise = Promise.all(
    sectionsToEnable.map(section =>
      sportingAPI.service.addMenuItemToUser({
        payload: { menuItemType: normalizeSection.toSportingMenuItem(section) }
      })
    )
  );

  const removePromise = Promise.all(
    sectionsToDisable.map(section =>
      sportingAPI.service.removeMenuItemFromUser({
        payload: { menuItemType: normalizeSection.toSportingMenuItem(section) }
      })
    )
  );

  await Promise.all([addPromise, removePromise]);
};
