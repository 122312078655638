import React from 'react';

import { useWindowEvent } from 'hooks/useWindowEvent';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getWindowDimensions } from 'utils/getWindowDimensions';

export const useWindowResizeWatcher = () => {
  const dispatch = useAppDispatch();

  const handleResize = React.useCallback(() => {
    const windowDimensions = getWindowDimensions();

    dispatch({
      name: 'WINDOW__RESIZE',
      payload: windowDimensions
    });
  }, [dispatch]);

  useWindowEvent('resize', handleResize);
};
