import React from 'react';
import { useHistory } from 'react-router';

import { TNewsPageScreenMap } from 'types/state';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';
import { authorizedRoutes } from 'utils/authorizedRoutes';
import { NewsPageView } from 'pages/NewsPage/NewsPageView';
import { useIntlContext } from 'hooks/useIntlContext';
import { useNewsPage } from 'pages/NewsPage/hooks/useNewsPage';
import { useNewsPageFilter } from 'hooks/useNewsPageFilter';

export const NewsPage = () => {
  // Подключаются параметры пути.
  const activeFilter = useNewsPageFilter();

  // Подключается API истории браузера.
  const history = useHistory();

  // Подключается словарь интернационализации.
  const { newsPageCreateItem: previewFallbackText } = useIntlContext();

  // Состояние и методы взаимодействия с выбранной новостью.
  const {
    selectedArticle,
    selectArticleById,
    editedArticle,
    setEditedArticle,
    patchEditedArticle,
    feedItems,
    loadFeed,
    reloadFeed,
    loading
  } = useNewsPage(activeFilter);

  // Эффект вызывает первоначальную загрузку данных.
  React.useEffect(() => {
    reloadFeed();
  }, [reloadFeed]);

  // Обработчк успешного удаления новости.
  // Полностью перезагружает страницу.
  const handleArticleDeleted = reloadFeed;

  // Обработчк успешной публикации новости.
  // Если новость была сохранена в качестве черновика,
  // но пользователь находится на странице опубликованных
  // производится редирект на страницу со всеми новостями.
  // Если новость была опубликована, но пользователь
  // находится на странице черновиков, производится редирект
  // на страницу со всеми новостями.
  // Иначе обновляет ленту
  const handleArticleSubmited = React.useCallback(
    (id: number, asDraft: boolean) => {
      if (
        (activeFilter === 'drafts' && !asDraft) ||
        (activeFilter === 'published' && asDraft)
      ) {
        history.push(authorizedRoutes.news('all'));
      } else {
        reloadFeed(id);
      }
    },
    [reloadFeed, activeFilter, history]
  );

  // Функция-обаботчик клика по элементу ленты новостей.
  // Устанавливает id новости-кандидата на id новости, по
  // которой был произведен клик.
  const handleFeedItemClick = selectArticleById;

  // Обаботчик намерения создать новость.
  // Заполняет состояние новости-кандидата шаблоном новости
  // и устанавливает значение id новости-кандидата.
  const handleCreateArticle = React.useCallback(() => {
    selectArticleById(NEWS_ARTICLE_CANDIDATE_ID);
  }, [selectArticleById]);

  // Обработчик изменений новости-кандидата в ходе редактирования.
  const handleArticleChange = patchEditedArticle;

  // Обработчик намерения отменить редактирование новости.
  const handleCancelEditArticle = React.useCallback(() => {
    if (editedArticle && editedArticle.id === NEWS_ARTICLE_CANDIDATE_ID) {
      selectArticleById(null);
    } else {
      setEditedArticle(null);
    }
  }, [editedArticle, selectArticleById, setEditedArticle]);

  // Обработчик намерения отредактировать статью.
  // При наличии выбранной статьи устанавливает ее в качестве
  // статьи-кандидата.
  const handleEditArticle = React.useCallback(() => {
    setEditedArticle(selectedArticle);
  }, [setEditedArticle, selectedArticle]);

  // Обработчик намерения загрузить следующую страницу
  // новостей.
  const handleLoadMore = React.useCallback(() => {
    loadFeed();
  }, [loadFeed]);

  // При редактировании статьи вместо выбранной статьи
  // подставляется объект редактируемой статьи.
  const article = editedArticle || selectedArticle;

  // Id актульной статьи, если такая есть.
  const articleId = article && article.id;

  // Объект определяет актуальное состояние экрана.
  const screenMap = React.useMemo<TNewsPageScreenMap>(() => {
    return {
      NO_CONTENT_SCEEN: loading === false && feedItems.length === 0,
      CONTENT_PRELOADING_SCREEN:
        (loading === null || loading === true) && feedItems.length === 0,
      CONTENT_LOADING_SCREEN: loading === true && feedItems.length > 0,
      CONTENT_PREVIEW_SCREEN:
        loading === false && feedItems.length > 0 && !editedArticle,
      CONTENT_EDITING_SCREEN: loading === false && !!editedArticle
    };
  }, [feedItems, loading, editedArticle]);

  const viewProps = {
    previewFallbackText,
    article,
    articleId,
    activeFilter,
    feedItems,
    screenMap,
    handleLoadMore,
    handleFeedItemClick,
    handleCreateArticle,
    handleArticleChange,
    handleArticleDeleted,
    handleArticleSubmited,
    handleEditArticle,
    handleCancelEditArticle
  };

  return <NewsPageView {...viewProps} />;
};
