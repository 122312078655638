import { useSectionsEditorState } from 'hooks/useSectionsEditorState';
import { useUserInfoCache } from 'hooks/useUserInfoCache';

export const useSessionFormBusy = () => {
  const { submited } = useSectionsEditorState();

  const { didInvalidate } = useUserInfoCache();

  return submited || didInvalidate;
};
