import { parse } from 'date-fns';
import { Reservation } from 'types/state';
import { DATE_FNS__24H_TIME_FORMAT } from 'utils/constants';
import { createRuntimeUniqueId } from 'utils/createRuntimeUniqueId';
import { placesStoreMock } from './placesModel';

let storeMock: Reservation[] = [];

storeMock.push({
  id: createRuntimeUniqueId(),
  personsCount: 2,
  period: {
    startDateISO: parse(
      '12:30',
      DATE_FNS__24H_TIME_FORMAT,
      new Date()
    ).toISOString(),
    endDateISO: parse(
      '13:30',
      DATE_FNS__24H_TIME_FORMAT,
      new Date()
    ).toISOString()
  },
  places: [placesStoreMock.getAll()[0].items[0]],
  comment: 'COMMENT',
  preBooking: true,
  userId: createRuntimeUniqueId(),
  fio: 'FIO',
  phoneNumber: '+375291112233',
  cardNumber: 'CARD_NUMBER',
  processedBy: 'PROCESSED_BY'
});

storeMock.push({
  id: createRuntimeUniqueId(),
  personsCount: 4,
  period: {
    startDateISO: parse(
      '09:00',
      DATE_FNS__24H_TIME_FORMAT,
      new Date()
    ).toISOString(),
    endDateISO: parse(
      '12:00',
      DATE_FNS__24H_TIME_FORMAT,
      new Date()
    ).toISOString()
  },
  places: [
    placesStoreMock.getAll()[0].items[0],
    placesStoreMock.getAll()[1].items[0]
  ],
  comment: 'COMMENT_2',
  preBooking: true,
  userId: createRuntimeUniqueId(),
  fio: 'FIO_2',
  phoneNumber: '+375293332211',
  cardNumber: 'CARD_NUMBER_2',
  processedBy: 'PROCESSED_BY_2'
});

storeMock.push({
  id: createRuntimeUniqueId(),
  personsCount: 6,
  period: {
    startDateISO: parse(
      '12:00',
      DATE_FNS__24H_TIME_FORMAT,
      new Date()
    ).toISOString(),
    endDateISO: parse(
      '14:30',
      DATE_FNS__24H_TIME_FORMAT,
      new Date()
    ).toISOString()
  },
  places: [
    placesStoreMock.getAll()[1].items[1],
    placesStoreMock.getAll()[1].items[2]
  ],
  comment: 'COMMENT_3',
  preBooking: false,
  userId: createRuntimeUniqueId(),
  fio: 'FIO_3',
  phoneNumber: '+375293333333',
  cardNumber: 'CARD_NUMBER_3',
  processedBy: 'PROCESSED_BY_3'
});

const getAll = (): Reservation[] => [...storeMock];

const add = (item: Reservation) => {
  storeMock.push(item);
};

const replaceById = (id: number, nextItem: Reservation) => {
  storeMock = storeMock.map(place => {
    if (place.id === id) {
      return nextItem;
    }

    return place;
  });
};

const deleteById = (id: number) => {
  storeMock = storeMock.filter(item => {
    return item.id !== id;
  });
};

export const reservationsStoreMock = {
  getAll,
  add,
  replaceById,
  deleteById
};
