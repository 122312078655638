import React from 'react';

import styles from './ApplicationsPage.module.css';
import { Spinner } from 'components/shared/Spinner';

type TProps = {
  testId: string;
};

export const ApplicationsPagePreloader = ({ testId }: TProps) => (
  <div className={styles.ApplicationsPage__Preloader} data-testid={testId}>
    <Spinner />
  </div>
);
