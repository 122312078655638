import { useAppState } from 'hooks/useAppState';
import { PersonalDataFormError, PersonalDataFormValues } from 'types/state';

export const usePersonalDataEditorState = () => {
  const { personalDataEditorState } = useAppState();

  return personalDataEditorState;
};

export const usePersonalDataFormValues = (): PersonalDataFormValues => {
  const { formValues } = usePersonalDataEditorState();

  return formValues;
};

export const usePersonalDataFormError = ():
  | undefined
  | PersonalDataFormError => {
  const { formError } = usePersonalDataEditorState();

  return formError;
};

export const usePersonalDataFormBusy = (): boolean => {
  const { submited, submitDidSucceed } = usePersonalDataEditorState();

  return submited && !submitDidSucceed;
};

export const usePersonalDataSubmitDidSucceed = () => {
  const { submitDidSucceed } = usePersonalDataEditorState();

  return submitDidSucceed;
};
