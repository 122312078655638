import { useAppState } from 'hooks/useAppState';
import { ServiceScope } from 'types/serviceScope';

const getNextInvalidatedPage = (invalidatedPages: Set<number>) => {
  const pagesIterator = invalidatedPages.values().next();

  // Проверка результата итерации на done позволяет
  // убедиться, что текущее значение существует
  // и вернуть типизированное значение.
  if (!pagesIterator.done) {
    return pagesIterator.value;
  }

  return null;
};

export const useNewApplicationsListsInvalidatedPage = (
  domain: ServiceScope
): number | null => {
  const {
    newSportApplicationsList,
    newRestaurantApplicationsList
  } = useAppState();

  if (domain === 'CLUB') {
    return getNextInvalidatedPage(newSportApplicationsList.invalidatedPages);
  }

  if (domain === 'RESTAURANT') {
    return getNextInvalidatedPage(
      newRestaurantApplicationsList.invalidatedPages
    );
  }

  return null;
};

export const useProcessedApplicationsListsInvalidatedPage = (
  domain: ServiceScope
): number | null => {
  const {
    processedSportApplicationsList,
    processedRestaurantApplicationsList
  } = useAppState();

  if (domain === 'CLUB') {
    return getNextInvalidatedPage(
      processedSportApplicationsList.invalidatedPages
    );
  }

  if (domain === 'RESTAURANT') {
    return getNextInvalidatedPage(
      processedRestaurantApplicationsList.invalidatedPages
    );
  }

  return null;
};
