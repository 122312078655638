import React from 'react';

import styles from './CompetitionParticipants.module.css';

type TProps = {
  children: React.ReactNode;
};

export const CompetitionParticipantsTable = ({ children }: TProps) => {
  return (
    <table className={styles.CompetitionParticipants__Table}>{children}</table>
  );
};
