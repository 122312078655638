import React from 'react';

import { CustomDatePicker } from '../CustomDatePicker';
import { RectangleButton } from '../shared/RectangleButton';
import { DateLineItem } from './DateLineItem';
import { checkDate } from '../../utils/checkDate';
import { getDateWithAddDays } from '../../utils/dateUtils';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DayEventsIcon } from 'svg/20x20/day-events';
import { ArrowLeftIcon } from 'svg/20x20/arrow-left';
import { ArrowRightIcon } from 'svg/20x20/arrow-right';
import { DateLineLayout } from './DateLineLayout';
import { useBookingPage } from 'hooks/booking/useBookingPage';
import { useIntlContext } from 'hooks/useIntlContext';
import { Text } from 'design/text';
import { isToday } from 'date-fns';
import { PopupGroupLayoutComponent } from 'design/popupGroupLayout/popupGroupLayoutComponents';
import { ServiceScope } from 'types/serviceScope';

type Props = {
  location: ServiceScope;
};

export const DateLine = (props: Props) => {
  const { location } = props;

  const { selectedDateISO, startLineDate } = useBookingPage(location);

  const { dateLine: ownDictionary } = useIntlContext();

  const selectedDate = React.useMemo(() => {
    return new Date(selectedDateISO);
  }, [selectedDateISO]);

  const dispatch = useAppDispatch();

  const setStartLineDate = React.useCallback(
    (nextDate: Date) => {
      dispatch({
        name: 'BookingPage__ChangeStartLineDate',
        date: nextDate,
        location
      });
    },
    [dispatch, location]
  );

  const selectDate = React.useCallback(
    (nextDate: Date) => {
      dispatch({
        name: 'BookingPage__DateSelected',
        dateISO: nextDate.toISOString(),
        location
      });
    },
    [dispatch, location]
  );

  const selectToday = React.useCallback(() => {
    const todayDate = new Date();
    selectDate(todayDate);
    setStartLineDate(todayDate);
  }, [selectDate, setStartLineDate]);

  const [isOpened, setOpened] = React.useState<boolean>(false);

  const daysCountInList = 7;

  const createDaysList = React.useCallback((): Date[] => {
    return Array.from(Array(daysCountInList), (_, i) =>
      getDateWithAddDays(startLineDate, i)
    );
  }, [startLineDate]);

  const daysList = createDaysList();

  const toggleDatePicker = React.useCallback(() => {
    setOpened(prev => !prev);
  }, []);

  const closeDatePicker = React.useCallback(() => {
    setOpened(false);
  }, []);

  const onSetStartDate = React.useCallback(
    (date: string) => {
      let dateForSelect = new Date(date);
      setStartLineDate(dateForSelect);
      selectDate(dateForSelect);
      setOpened(false);
    },
    [setStartLineDate, selectDate]
  );

  const onSelectDate = React.useCallback(
    (date: Date) => {
      selectDate(date);
      setOpened(false);
    },
    [selectDate]
  );

  const isDaysListContainsDay = React.useCallback(
    (date: Date): boolean => {
      return (
        daysList.filter(value => checkDate(date).equalTo(value)).length > 0
      );
    },
    [daysList]
  );

  const onToPrevDate = React.useCallback(() => {
    let prevDate = getDateWithAddDays(selectedDate, -1);
    if (isDaysListContainsDay(prevDate)) {
      selectDate(prevDate);
    } else {
      onSetStartDate(prevDate.toISOString());
    }
  }, [isDaysListContainsDay, onSetStartDate, selectDate, selectedDate]);

  const onToNextDate = React.useCallback(() => {
    let nextDate = getDateWithAddDays(selectedDate, 1);
    if (isDaysListContainsDay(nextDate)) {
      selectDate(nextDate);
    } else {
      onSetStartDate(nextDate.toISOString());
    }
  }, [isDaysListContainsDay, onSetStartDate, selectDate, selectedDate]);

  const isActiveDate = React.useCallback(
    (date: Date): boolean => {
      return checkDate(date).equalTo(new Date(selectedDate));
    },
    [selectedDate]
  );

  const isTodaySelected = React.useMemo(() => {
    return isToday(selectedDate);
  }, [selectedDate]);

  return (
    <DateLineLayout
      dateInput={
        <PopupGroupLayoutComponent
          onOutsideClick={closeDatePicker}
          trigger={
            <RectangleButton
              className={`booking-header-btn ${isOpened ? 'active' : ''}`}
              styleType={'secondary'}
              label={''}
              onClick={toggleDatePicker}
              icon={<DayEventsIcon />}
            />
          }
          popup={
            isOpened && (
              <CustomDatePicker
                value={selectedDateISO}
                onDateSelect={onSetStartDate}
              />
            )
          }
        />
      }
      dateLineNavigationLeft={
        <RectangleButton
          className={'booking-header-btn'}
          styleType={'secondary'}
          label={''}
          onClick={onToPrevDate}
          icon={<ArrowLeftIcon />}
        />
      }
      dateLineNavigationRight={
        <RectangleButton
          className={'booking-header-btn'}
          styleType={'secondary'}
          label={''}
          onClick={onToNextDate}
          icon={<ArrowRightIcon />}
        />
      }
      dateLineOptions={daysList.map(date => (
        <DateLineItem
          key={date.getDate()}
          date={date}
          onSelect={onSelectDate}
          isActive={() => isActiveDate(date)}
        />
      ))}
      resetAction={
        !isTodaySelected && (
          <RectangleButton
            className={'booking-header-reset-action'}
            styleType={'secondary'}
            label={
              <Text size="s" weight="semi-bold" color="primary">
                {ownDictionary.resetActionText}
              </Text>
            }
            onClick={selectToday}
          />
        )
      }
    />
  );
};
