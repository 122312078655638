import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useServices } from 'hooks/useServices';
import { useChatDomain } from 'hooks/chats/useChatDomain';
import { chatRoomsThunk } from 'thunks/chatRoomsThunk';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { GetChatRoomsParams } from 'types/services';
import { useChatRoomIds } from 'hooks/chats/useChatsState';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

export const useChatRoomsService = () => {
  const domain = useChatDomain();

  const dispatch = useAppDispatch();

  const chatRoomIds = useChatRoomIds();

  const page = createListCacheSelectors(
    chatRoomIds
  ).selectNextInvalidatedPage();

  const { getChatRooms: getChatRoomsService } = useServices();

  const params = React.useMemo<null | GetChatRoomsParams>(() => {
    if (page) {
      return {
        domain,
        page
      };
    }

    return null;
  }, [domain, page]);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    if (params) {
      const promise = chatRoomsThunk(
        getChatRoomsService(params),
        params
      )(dispatch);

      wrapAsyncSafely(promise);
    }
  }, [dispatch, getChatRoomsService, params, wrapAsyncSafely]);
};
