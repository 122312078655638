import {
  addMilliseconds,
  areIntervalsOverlapping,
  isAfter,
  isEqual
} from 'date-fns';
import { CalendarPeriod } from 'types/state';

export function areTwoCalendarPeriodsOverlapping(
  period1: CalendarPeriod,
  period2: CalendarPeriod
) {
  const period1StartDate = new Date(period1.startDateISO);

  const period1EndDate = new Date(period1.endDateISO);

  const period2StartDate = new Date(period2.startDateISO);

  const period2EndDate = new Date(period2.endDateISO);

  const overlapping = areIntervalsOverlapping(
    {
      start: period1StartDate,
      end: period1EndDate
    },
    {
      start: period2StartDate,
      end: period2EndDate
    }
  );

  return overlapping;
}

export function splitCalendarPeriod(
  period: CalendarPeriod,
  stepMs: number
): CalendarPeriod[] {
  const { startDateISO, endDateISO } = period;

  const calendarPeriods: CalendarPeriod[] = [];

  const addPeriod = () => {
    const prevPeriod = calendarPeriods[calendarPeriods.length - 1];

    let nextStartDateISO, nextEndDateISO;

    if (prevPeriod) {
      const { endDateISO: prevEndDateISO } = prevPeriod;

      nextStartDateISO = prevEndDateISO;
    } else {
      nextStartDateISO = startDateISO;
    }

    nextEndDateISO = addMilliseconds(
      new Date(nextStartDateISO),
      stepMs
    ).toISOString();

    calendarPeriods.push({
      startDateISO: nextStartDateISO,
      endDateISO: nextEndDateISO
    });
  };

  const isEnogh = (): boolean => {
    const prevPeriod = calendarPeriods[calendarPeriods.length - 1];

    if (prevPeriod) {
      const prevPeriodEndDate = new Date(prevPeriod.endDateISO);

      return (
        isAfter(prevPeriodEndDate, new Date(endDateISO)) ||
        isEqual(prevPeriodEndDate, new Date(endDateISO))
      );
    }

    return false;
  };

  while (isEnogh() === false) {
    addPeriod();
  }

  return calendarPeriods;
}
