const META_TAG_PREFIX = 'REACT_APP_META__';

export function renderMetaTags() {
  for (let [key, value] of Object.entries(process.env)) {
    if (key.startsWith(META_TAG_PREFIX) && value) {
      const [, name] = key.split(META_TAG_PREFIX);

      const metaElement = document.createElement('meta');
      metaElement.name = name;
      metaElement.content = value;

      document.head.prepend(metaElement);
    }
  }
}
