import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useUserRoleStrict } from 'hooks/useUserRole';
import { useAsyncController } from './useAsyncController';
import { useEnabledSectionsStrict } from './useEnabledSections';
import { useNewReviewsCount } from './useNewReviewsCount';

export const useNewReviewsCountUpdateProcess = () => {
  const count = useNewReviewsCount();

  const { getNewReviewsCount } = useServices();

  const dispatch = useAppDispatch();

  const { asyncProcess, wrapAsync } = useAsyncController<number>();

  const role = useUserRoleStrict();

  const sections = useEnabledSectionsStrict();

  const shouldUpdate =
    role === 'MANAGER' && sections.includes('feedback') && count.didInvalidate;

  React.useEffect(() => {
    if (shouldUpdate) {
      const promise = getNewReviewsCount();

      wrapAsync(promise);
    }
  }, [getNewReviewsCount, shouldUpdate, wrapAsync]);

  React.useEffect(() => {
    if (asyncProcess.status === 'PENDING') {
      dispatch({
        name: 'NEW_REVIEWS_COUNT_SERVICE',
        status: 'PENDING'
      });
    }
    if (asyncProcess.status === 'RESOLVED') {
      dispatch({
        name: 'NEW_REVIEWS_COUNT_SERVICE',
        status: 'RESOLVED',
        payload: asyncProcess.value
      });
    }
  }, [asyncProcess, dispatch]);
};
