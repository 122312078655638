import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineGridLayout.scss';

type Props = {
  labels: React.ReactNode;
  tracks: React.ReactNode;
  reservations: React.ReactNode;
};

const bemConstructor = createBEMConstructor('bookingTimelineGridLayout');

export const BookingTimelineGridLayout: React.FC<Props> = props => {
  const { labels, tracks, reservations } = props;

  const wrapperClassName = bemConstructor('wrapper');

  const labelsSlotClassName = bemConstructor('labelsSlot');

  const tracksSlotClassName = bemConstructor('tracksSlot');

  const bodySlotClassName = bemConstructor('body');

  return (
    <div className={wrapperClassName}>
      <div className={labelsSlotClassName}>{labels}</div>
      <div className={bodySlotClassName}>
        <div className={tracksSlotClassName}>{tracks}</div>
        {reservations}
      </div>
    </div>
  );
};
