import { Reservation } from '../../../types/state';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';
import { reservationsStoreMock } from '../utils/reservationsModel';

export const getReservations = async (): Promise<Reservation[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return reservationsStoreMock.getAll();
};
