import React from 'react';

import { useMediaFlags } from 'hooks/useMediaFlags';

export const useBodyMediaClassNames = () => {
  const { isTablet, isDesktopL, isDesktopXL, isMobile } = useMediaFlags();

  React.useEffect(() => {
    document.body.classList.toggle('mq-tablet', isTablet);
    document.body.classList.toggle('mq-desktop-l', isDesktopL);
    document.body.classList.toggle('mq-desktop-xl', isDesktopXL);
    document.body.classList.toggle('mq-mobile', isMobile);
  }, [isTablet, isDesktopL, isDesktopXL, isMobile]);
};
