import React from 'react';

import './EmployeeEditModal.scss';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useIntlContext } from 'hooks/useIntlContext';
import { ModalPortal } from 'components/shared/Modal';
import { ModalWindow } from 'components/Modal/ModalWindow';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { RectangleButton } from 'components/shared/RectangleButton';
import { CustomSelect } from 'containers/CustomSelect';
import { CustomInput } from 'components/shared/CustomInput';
import { EmployeeFormValues, UserInfo, UserRole } from 'types/state';
import { useEmployeeEditorFormError } from 'hooks/employee/useEmployeeEditorFormError';
import { EmployeeFormLegend } from 'components/EmployeeFormLegend/EmployeeFormLegend';
import { usePhoneNumberSelectOptions } from 'hooks/usePhoneNumberSelectOptions';
import { getAvailableRoleForEdit } from 'utils/employee/getAvailableRole';
import { useUserInfoStrict } from 'hooks/useUserInfoCache';
import { useEmployeeEditorState } from 'hooks/employee/useEmployeeEditorState';

type Props = {
  user: UserInfo;
};

const useInput = (initialValue: string) => {
  const [value, setValue] = React.useState(initialValue ? initialValue : '');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return { value, onChange };
};

export const EmployeeEditModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useUserInfoStrict();

  const { user } = props;

  const formError = useEmployeeEditorFormError();

  const {
    employeeEditModalLabels: {
      roleSelectLabel,
      numberSelectLabel,
      lastNameInputLabel,
      lastNameInputPlaceholder,
      lastNameInputEmpty,
      firstNameInputLabel,
      firstNameInputPlaceholder,
      firstNameInputEmpty,
      loginInputLabel,
      loginInputPlaceholder,
      loginInputEmpty,
      loginInputExist,
      passwordInputLabel,
      passwordInputPlaceholder,
      passwordConfirmInputPlaceholder,
      passwordInputEmpty,
      passwordNotEqualPasswordConfirm,
      submitBtnLabel,
      cancelBtnLabel
    },
    roleIntl
  } = useIntlContext();

  const roleSelectOptions = getAvailableRoleForEdit(currentUserInfo.role).map(
    role => {
      return {
        label: roleIntl.toLabel(role),
        value: role
      };
    }
  );

  const phoneNumberSelectOptions = usePhoneNumberSelectOptions();

  const phoneNumbersLoaded = phoneNumberSelectOptions.length > 1;

  const [role, setRole] = React.useState(user.role);
  const [phoneNumber, setPhoneNumber] = React.useState(
    user.phoneNumber ? user.phoneNumber : ''
  );
  const lastName = useInput(user.lastName);
  const firstName = useInput(user.firstName);
  const login = useInput(user.login);
  const password = useInput('');
  const passwordConfirm = useInput('');

  const createEmployeeFormValues = (): EmployeeFormValues => ({
    id: user.id,
    role: role,
    phoneNumber: phoneNumber,
    lastName: lastName.value,
    firstName: firstName.value,
    login: login.value,
    password: password.value,
    passwordConfirm: passwordConfirm.value
  });

  const formValues = createEmployeeFormValues();

  // Обработка кнопок футера
  const onCancel = React.useCallback(() => {
    dispatch({
      name: 'EMPLOYEE_EDIT_MODAL__CANCEL',
      value: formValues
    });
  }, [dispatch, formValues]);

  const onSubmit = React.useCallback(() => {
    dispatch({
      name: 'EMPLOYEE_EDIT_MODAL__SUBMIT',
      employeeId: user.id,
      value: formValues
    });
  }, [dispatch, user.id, formValues]);

  const lastNameInputRef = React.useRef<null | HTMLInputElement>(null);
  const firstNameInputRef = React.useRef<null | HTMLInputElement>(null);
  const loginInputRef = React.useRef<null | HTMLInputElement>(null);
  const passwordInputRef = React.useRef<null | HTMLInputElement>(null);
  const passwordConfirmInputRef = React.useRef<null | HTMLInputElement>(null);

  // Обработка ошибок
  React.useEffect(() => {
    const { current: lastNameInputElement } = lastNameInputRef;
    const { current: firstNameInputElement } = firstNameInputRef;
    const { current: loginInputElement } = loginInputRef;
    const { current: passwordInputElement } = passwordInputRef;
    const { current: passwordConfirmInputElement } = passwordConfirmInputRef;
    if (
      formError &&
      lastNameInputElement &&
      firstNameInputElement &&
      loginInputElement &&
      passwordInputElement &&
      passwordConfirmInputElement
    ) {
      if (formError.includes('LASTNAME_IS_EMPTY')) {
        lastNameInputElement.focus();
      } else if (formError.includes('FIRSTNAME_IS_EMPTY')) {
        firstNameInputElement.focus();
      } else if (
        formError.includes('LOGIN_IS_EMPTY') ||
        formError.includes('LOGIN_IN_USE')
      ) {
        loginInputElement.focus();
      } else if (
        formError.includes('PASSWORD_IS_EMPTY') ||
        formError.includes('PASSWORDS_DID_NOT_MATCH')
      ) {
        passwordInputElement.focus();
      }
    }
  }, [formError]);

  const employeeEditorState = useEmployeeEditorState();

  const busy =
    employeeEditorState.submited && !employeeEditorState.submitDidSucceed;

  return (
    <ModalPortal>
      <ModalWindow additionalClassName={'employeeEditModal__modalWindow'}>
        <div className={'employeeEditModal__contentWrapper'}>
          <div className={'employeeEditModal__roleAndNumberSelectContainer'}>
            <EmployeeFormLegend titleText={roleSelectLabel}>
              <CustomSelect
                className={'employeeEditModal__roleSelect'}
                formFieldBordered={true}
                options={roleSelectOptions}
                selected={role || ''}
                onChange={(value: UserRole) => setRole(value)}
              />
            </EmployeeFormLegend>
            <EmployeeFormLegend titleText={numberSelectLabel}>
              <CustomSelect
                className={'employeeEditModal__numberSelect'}
                formFieldBordered={true}
                options={phoneNumberSelectOptions}
                selected={
                  phoneNumbersLoaded && phoneNumber != null ? phoneNumber : ''
                }
                onChange={value => setPhoneNumber(value)}
              />
            </EmployeeFormLegend>
          </div>
          <EmployeeFormLegend
            titleText={lastNameInputLabel}
            errorText={
              formError && formError.includes('LASTNAME_IS_EMPTY')
                ? lastNameInputEmpty
                : undefined
            }
          >
            <CustomInput
              ref={lastNameInputRef}
              type="text"
              name="lastName"
              placeholder={lastNameInputPlaceholder}
              value={lastName.value}
              onChange={lastName.onChange}
            />
          </EmployeeFormLegend>
          <EmployeeFormLegend
            titleText={firstNameInputLabel}
            errorText={
              formError && formError.includes('FIRSTNAME_IS_EMPTY')
                ? firstNameInputEmpty
                : undefined
            }
          >
            <CustomInput
              ref={firstNameInputRef}
              type="text"
              name="firstName"
              placeholder={firstNameInputPlaceholder}
              value={firstName.value}
              onChange={firstName.onChange}
            />
          </EmployeeFormLegend>
          <EmployeeFormLegend
            titleText={loginInputLabel}
            errorText={
              formError && formError.includes('LOGIN_IS_EMPTY')
                ? loginInputEmpty
                : formError && formError.includes('LOGIN_IN_USE')
                ? loginInputExist
                : undefined
            }
          >
            <CustomInput
              ref={loginInputRef}
              type="text"
              name="login"
              placeholder={loginInputPlaceholder}
              value={login.value}
              onChange={login.onChange}
            />
          </EmployeeFormLegend>
          <EmployeeFormLegend
            titleText={passwordInputLabel}
            errorText={
              formError && formError.includes('PASSWORD_IS_EMPTY')
                ? passwordInputEmpty
                : formError && formError.includes('PASSWORDS_DID_NOT_MATCH')
                ? passwordNotEqualPasswordConfirm
                : undefined
            }
          >
            <CustomInput
              ref={passwordInputRef}
              type="password"
              name="new-password"
              placeholder={passwordInputPlaceholder}
              value={password.value}
              onChange={password.onChange}
              autoComplete="new-password"
            />
            <div className={'employeeEditModal__btnDivider'} />
            <CustomInput
              ref={passwordConfirmInputRef}
              type="password"
              name="new-password-confirm"
              placeholder={passwordConfirmInputPlaceholder}
              value={passwordConfirm.value}
              onChange={passwordConfirm.onChange}
              autoComplete="new-password"
            />
          </EmployeeFormLegend>
        </div>
        <ModalFooter additionalClassName={'employeeEditModal__modalFooter'}>
          <RectangleButton
            styleType={'secondary'}
            label={cancelBtnLabel}
            isDisabled={busy}
            onClick={onCancel}
          />
          <RectangleButton
            styleType={'primary'}
            label={submitBtnLabel}
            hasPreloader={busy}
            onClick={onSubmit}
          />
        </ModalFooter>
      </ModalWindow>
    </ModalPortal>
  );
};
