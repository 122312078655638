import React from 'react';

import { CompetitionNotesWrapper } from 'components/CompetitionNotes/CompetitionNotesWrapper';
import { CompetitionNotesInput } from 'components/CompetitionNotes/CompetitionNotesInput';

type TProps = {
  hasPreloader: boolean;
  inputValue: string;
  competitionNotesPlaceholder: string;
  inputDisabled: boolean;
  handleChange(nextValue: string): void;
  handleBlur(): void;
};

export const CompetitionNotesView = (props: TProps) => {
  const {
    hasPreloader,
    inputValue,
    competitionNotesPlaceholder,
    inputDisabled,
    handleChange,
    handleBlur
  } = props;

  if (hasPreloader) {
    return null;
  }

  return (
    <CompetitionNotesWrapper>
      <CompetitionNotesInput
        value={inputValue}
        placeholder={competitionNotesPlaceholder}
        disabled={inputDisabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </CompetitionNotesWrapper>
  );
};
