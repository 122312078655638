import { Cache } from 'types/util';

export const patchCache = <ValueType>(cache: Cache<ValueType>) => ({
  done() {
    return cache;
  },
  invalidate() {
    return patchCache({
      ...cache,
      didInvalidate: true
    });
  },
  setValue(nextValue: ValueType) {
    return patchCache({
      value: nextValue,
      didInvalidate: false
    });
  }
});
