import { TMakePhoneCallParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';
import { UnexpectedError } from 'utils/errors';

export const makePhoneCall = async (
  params: TMakePhoneCallParams
): Promise<void> => {
  const { applicationId, userId } = params;

  if (userId) {
    await sportingAPI.service.doCallByUser({
      payload: { idUser: userId }
    });
  } else if (applicationId) {
    await sportingAPI.service.doCall({
      payload: { idClaim: applicationId }
    });
  } else {
    throw new UnexpectedError();
  }
};
