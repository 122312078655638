import React from 'react';

import styles from './BookingTableSettingsContentLayoutStyles.module.scss';

type Props = {
  title: React.ReactNode;
  addButton: React.ReactNode;
  form: React.ReactNode;
};

export const BookingTableSettingsContentLayout = (props: Props) => {
  const { title, addButton, form } = props;

  return (
    <div className={styles.content}>
      <div className={styles.controls}>
        <div className={styles.titleSlot}>{title}</div>
        <div className={styles.addButtonSlot}>{addButton}</div>
      </div>
      <div className={styles.formSlot}>{form}</div>
    </div>
  );
};
