import React from 'react';

import styles from './bage.module.css';

import {
  createClassNameConstructor,
  ClassNameConstructor
} from 'utils/craeteClassNameConstructor';

type BageTheme = 'silver' | 'gold' | 'black';

type Props = {
  theme: BageTheme;
  label: string;
  testId?: string;
};

const applyThemeModifier = (theme: BageTheme) => (
  constructor: ClassNameConstructor
) => {
  switch (theme) {
    case 'black':
      return constructor.addClass(styles.Bage_themeBlack);
    case 'gold':
      return constructor.addClass(styles.Bage_themeGold);
    case 'silver':
      return constructor.addClass(styles.Bage_themeSilver);
    default:
      return constructor;
  }
};

export const Bage = (props: Props) => {
  const { label, theme, testId } = props;

  const classNameConstructor = createClassNameConstructor(
    styles.Bage
  ).withConstructor(applyThemeModifier(theme));

  const wrapperClassName = classNameConstructor
    .addClass(styles.Bage__Wrapper)
    .return();

  const slotClassName = classNameConstructor
    .addClass(styles.Bage__Slot)
    .return();

  return (
    <div className={wrapperClassName} data-testid={testId}>
      <div className={slotClassName}>{label}</div>
    </div>
  );
};
