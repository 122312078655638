import React from 'react';

import styles from './Navigation.module.scss';

type Props = {
  children: React.ReactNode;
  position: 'top' | 'bottom';
};

export const NavigationItems: React.FC<Props> = props => {
  const { children, position } = props;

  const className = (() => {
    const base = styles.Navigation__Items;

    if (position === 'top') {
      return `${base} ${styles.Navigation__Items_top}`;
    }

    if (position === 'bottom') {
      return `${base} ${styles.Navigation__Items_bottom}`;
    }

    return base;
  })();

  return <div className={className}>{children}</div>;
};
