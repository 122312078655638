import React from 'react';

import styles from './ReviewItem.module.css';

import { testId } from 'utils/testId';

type TProps = {
  children: React.ReactNode;
  hasAccentTheme: boolean;
  hasSecondaryTheme: boolean;
  selected: boolean;
  onClick(): void;
};

export const ReviewItemWrapper = ({
  children,
  hasAccentTheme,
  hasSecondaryTheme,
  selected,
  onClick
}: TProps) => (
  <button
    onClick={onClick}
    className={`${styles.ReviewItem__Wrapper} ${
      selected ? styles.ReviewItem__Wrapper_selected : ''
    } ${hasAccentTheme ? styles.ReviewItem__Wrapper_accent : ''} ${
      hasSecondaryTheme ? styles.ReviewItem__Wrapper_secondary : ''
    }`}
    data-testid={testId.REVIEW_ITEM}
    data-selected={selected}
  >
    {children}
  </button>
);
