import React from 'react';

import styles from './Popup.module.css';

type TProps = {
  children: React.ReactNode;
  className: string;
  testId?: string;
};

export const Popup: React.FC<TProps> = ({
  children,
  className = '',
  testId
}) => {
  return (
    <div className={`${styles.Popup} ${className}`} data-testid={testId}>
      {children}
    </div>
  );
};
