import { AppEvent } from 'types/appEvent';
import { CollectionCache } from 'types/util';
import { UserInfo } from 'types/state';
import {
  createCollectionCache,
  createCollectionCacheSelectors,
  modifyCollectionCache
} from 'utils/collectionCacheUtils';

export const usersCacheReducer = (
  state: CollectionCache<number, UserInfo>,
  event: AppEvent
): CollectionCache<number, UserInfo> => {
  switch (event.name) {
    case 'USERS_PAGE_DATA_SERVICE':
    case 'MOVE_EMPLOYEE_SERVICE':
      if (event.status === 'RESOLVED') {
        let modifier = modifyCollectionCache(
          createCollectionCache<number, UserInfo>()
        );

        event.payload.forEach(user => {
          modifier = modifier.putItem(user.id, user);
        });

        return modifier.return();
      }
      return state;
    case 'UPDATE_USER_PHONE_NUMBER_SERVICE': {
      if (event.status === 'RESOLVED') {
        const params = event.meta;
        let user = createCollectionCacheSelectors(state).selectItem(
          params.userId
        );
        if (user) {
          const modifyUser: UserInfo = {
            ...user,
            phoneNumber: params.phoneNumber
          };
          return modifyCollectionCache(state)
            .putItem(modifyUser.id, modifyUser)
            .return();
        }
        return state;
      }
      return state;
    }
    case 'EMPLOYEE_EDIT_SERVICE': {
      if (event.status === 'RESOLVED') {
        const userInfo = event.payload;
        return modifyCollectionCache(state)
          .putItem(userInfo.id, userInfo)
          .return();
      }
      return state;
    }
    case 'USER_TO_BLOCKED_SERVICE':
      if (event.status === 'RESOLVED') {
        const params = event.meta;
        let user = createCollectionCacheSelectors(state).selectItem(
          params.userId
        );
        if (user) {
          const modifyUser: UserInfo = {
            ...user,
            blocked: params.toBlocked
          };
          return modifyCollectionCache(state)
            .putItem(modifyUser.id, modifyUser)
            .return();
        }
        return state;
      }
      return state;
    case 'DELETE_USER_SERVICE': {
      if (event.status === 'RESOLVED') {
        const deletedUserId = event.meta;
        let modifier = modifyCollectionCache(state);
        modifier = modifier.removeItem(deletedUserId);
        return modifier.return();
      }
      return state;
    }
    default:
      return state;
  }
};
