import { NewUserFormValues, NewUserFormError } from 'types/state';

export const validateNewUserFormValues = (
  values: NewUserFormValues
): NewUserFormError | undefined => {
  if (!values.login) {
    return 'LOGIN_IS_MISSING';
  }
  if (!values.password) {
    return 'PASSWORD_IS_MISSING';
  }
  if (values.password !== values.passwordConfirm) {
    return 'PASSWORDS_DID_NOT_MATCH';
  }
};
