import React from 'react';
import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';

export const usePlacesService = () => {
  const dispatch = useAppDispatch();

  const services = useServices();

  const { wrapAsync, asyncProcess } = useAsyncController();

  const loadPlaces = React.useCallback(() => {
    const promise = services.getPlaces().then(places => {
      dispatch({
        name: 'PlacesRecieved',
        places
      });
    });

    wrapAsync(promise);
  }, [dispatch, services, wrapAsync]);

  return { loadPlaces, asyncProcess };
};
