import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useBookingPlacesEditorState } from './useBookingPlacesEditorState';
import { validateBookingPlacesFormValues } from 'utils/validateBookingPlasesFormValues';

export const useBookingPlaceEditorImmediateValidation = () => {
  const dispatch = useAppDispatch();

  const { formValues, formError } = useBookingPlacesEditorState();

  const shouldValidateImmediately = formError !== undefined;

  React.useEffect(() => {
    if (shouldValidateImmediately) {
      const violations = validateBookingPlacesFormValues(formValues);

      dispatch({ name: 'BOOKING_PLACE_FORM_VALUES_VALIDATED', violations });
    }
  }, [dispatch, formValues, shouldValidateImmediately]);
};
