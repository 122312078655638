import React from 'react';

import { BookingTimelineLegendLayout } from 'design/bookingTimelineLegendLayout/bookingTimelineLegendLayout';
import { Text } from 'design/text';
import { format, differenceInMinutes } from 'date-fns';
import { useNowISODate } from 'hooks/useNowISODate';
import { DATE_FNS__24H_TIME_FORMAT } from 'utils/constants';
import { useWorkingHoursPeriods } from 'hooks/booking/useWorkingHoursPeriods';
import { CalendarPeriod } from 'types/state';

type Props = {
  workingHours: CalendarPeriod;
};

export const BookingTimelineLegend = (props: Props) => {
  const { workingHours } = props;

  const workingHoursPeriods = useWorkingHoursPeriods(workingHours);

  const nowISODate = useNowISODate();

  const pastTimePercent = React.useMemo(() => {
    const allDiff = differenceInMinutes(
      new Date(workingHours.endDateISO),
      new Date(workingHours.startDateISO)
    );

    const pastDiff = differenceInMinutes(
      new Date(nowISODate),
      new Date(workingHours.startDateISO)
    );

    // Сейчас ещё не вошло в пределы рабочего дня
    if (pastDiff < 0) {
      return -1;
    }

    // Сейчас уже вышло за пределы рабочего дня
    if (pastDiff > allDiff) {
      return -1;
    }

    const minutesInOnePercent = allDiff / 100;

    return pastDiff / minutesInOnePercent;
  }, [nowISODate, workingHours.endDateISO, workingHours.startDateISO]);

  return (
    <BookingTimelineLegendLayout
      pastTimePercent={pastTimePercent}
      items={workingHoursPeriods.map(period => {
        const visible = new Date(period.startDateISO).getMinutes() === 0;

        return (
          <Text key={period.startDateISO} color="primary">
            {visible
              ? format(new Date(period.startDateISO), DATE_FNS__24H_TIME_FORMAT)
              : ''}
          </Text>
        );
      })}
    />
  );
};
