import React from 'react';

import styles from './NewsArticle.module.css';

import {
  CustomTextarea,
  TCustomTextareaProps
} from 'components/shared/CustomTextarea';

interface TProps extends TCustomTextareaProps {
  name: 'description' | 'title';
}

export const NewsArticleTextInput = (props: TProps) => (
  <CustomTextarea
    {...props}
    wrapperClassName={`${styles.NewsArticle__TextInput} ${
      props.name === 'description'
        ? styles.NewsArticle__TextInput_description
        : ''
    } ${props.name === 'title' ? styles.NewsArticle__TextInput_title : ''}`}
  />
);
