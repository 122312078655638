import { asyncTimeout } from 'utils/asyncTimeout';
import {
  SERVICES_MOCK_DELAY,
  COMPETITIONS_ARTICLE_CANDIDATE_ID
} from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TSubmitCompetitionsArticleParams } from 'types/services';
import { TCompetitionsArticle } from 'types/state';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const SUBMIT_COMPETITIONS_ARTICLE_ID_MOCK__CONNECTION_ERROR = -2;

export const submitCompetitionsArticle = async (
  params: TSubmitCompetitionsArticleParams
): Promise<TCompetitionsArticle> => {
  const { data, saveAsDraft } = params;
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (data.id === SUBMIT_COMPETITIONS_ARTICLE_ID_MOCK__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }
  const saveOrUpdate =
    data.id === COMPETITIONS_ARTICLE_CANDIDATE_ID
      ? competitionsStoreMock.saveArticle
      : competitionsStoreMock.updateArticle;
  if (saveAsDraft) {
    return saveOrUpdate({
      ...data,
      isDraft: true
    });
  } else {
    return saveOrUpdate({
      ...params.data,
      isDraft: false
    });
  }
};
