import React from 'react';

import {
  TCompetitionsArticle,
  TCompetitionsFilter,
  TCompetitionsPreviewTab,
  TCompetitionsPageScreenMap
} from 'types/state';
import { CompetitionsFeedItem } from 'containers/CompetitionsFeedItem';
import { CompetitionsFeed } from 'containers/CompetitionsFeed';
import { CompetitionsPreview } from 'containers/CompetitionsPreview';
import { CompetitionsPageTemplate } from 'pages/CompetitionsPage/styled/CompetitionsPageTemplate';
import { CompetitionsPageLeftSection } from 'pages/CompetitionsPage/styled/CompetitionsPageLeftSection';
import { CompetitionsPageRightSection } from 'pages/CompetitionsPage/styled/CompetitionsPageRightSection';
import { CompetitionsPageRightSectionFallback } from 'pages/CompetitionsPage/styled/CompetitionsPageRightSectionFallback';

type TProps = {
  screenMap: TCompetitionsPageScreenMap;
  previewFallbackText: string;
  activeFilter: TCompetitionsFilter;
  activeTab: TCompetitionsPreviewTab;
  article: null | TCompetitionsArticle;
  articleId: null | number;
  feedItems: TCompetitionsArticle[];
  handleLoadMore(): void;
  handleFeedItemClick(itemId: number): void;
  handleCreateArticle(): void;
  handleArticleChange(nextData: TCompetitionsArticle): void;
  handleArticleDeleted(): void;
  handleArticleSubmited(id: number): void;
  handleEditArticle(): void;
  handleCancelEditArticle(): void;
  handlePreviewTabClick(nextTab: TCompetitionsPreviewTab): void;
};

export const CompetitionsPageView = (props: TProps) => {
  const {
    screenMap,
    previewFallbackText,
    article,
    articleId,
    activeFilter,
    activeTab,
    feedItems,
    handleLoadMore,
    handleFeedItemClick,
    handleCreateArticle,
    handleArticleChange,
    handleArticleDeleted,
    handleArticleSubmited,
    handleEditArticle,
    handleCancelEditArticle,
    handlePreviewTabClick
  } = props;

  const competitionsList = React.useMemo(() => {
    if (feedItems) {
      return feedItems.map((feedItem: TCompetitionsArticle) => {
        const hasSelectedView = articleId === feedItem.id;
        const data = article && articleId === feedItem.id ? article : feedItem;
        return (
          <CompetitionsFeedItem
            key={feedItem.id}
            data={data}
            hasSelectedView={hasSelectedView}
            onClick={handleFeedItemClick}
          />
        );
      });
    }
    return null;
  }, [feedItems, handleFeedItemClick, articleId, article]);

  return (
    <CompetitionsPageTemplate>
      <CompetitionsPageLeftSection>
        <CompetitionsFeed
          hasPreloader={screenMap.CONTENT_PRELOADING_SCREEN}
          hasEmptyFallback={screenMap.NO_CONTENT_SCEEN}
          hasCreateArticleButton={
            screenMap.CONTENT_PREVIEW_SCREEN || screenMap.CONTENT_LOADING_SCREEN
          }
          activeFilter={activeFilter}
          isFilterDisabled={screenMap.CONTENT_EDITING_SCREEN}
          onLoadMore={handleLoadMore}
          onCreateArticle={handleCreateArticle}
        >
          {competitionsList}
        </CompetitionsFeed>
      </CompetitionsPageLeftSection>
      <CompetitionsPageRightSection>
        {!!article && (
          <CompetitionsPreview
            article={article}
            activeTab={activeTab}
            hasEditView={screenMap.CONTENT_EDITING_SCREEN}
            onChange={handleArticleChange}
            onDeleted={handleArticleDeleted}
            onSumbited={handleArticleSubmited}
            onEdit={handleEditArticle}
            onCancelEdit={handleCancelEditArticle}
            onTabClick={handlePreviewTabClick}
          />
        )}
        {screenMap.NO_CONTENT_SCEEN && (
          <CompetitionsPageRightSectionFallback
            text={previewFallbackText}
            onCreateItem={handleCreateArticle}
          />
        )}
      </CompetitionsPageRightSection>
    </CompetitionsPageTemplate>
  );
};
