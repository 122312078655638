import { useAppState } from 'hooks/useAppState';
import { BookingPage } from 'types/booking';
import { ServiceScope } from 'types/serviceScope';

export function useBookingPage(location: ServiceScope): BookingPage {
  const { restaurantBookingPage, clubBookingPage } = useAppState();

  if (location === 'RESTAURANT') {
    return restaurantBookingPage;
  }

  if (location === 'CLUB') {
    return clubBookingPage;
  }

  throw new TypeError(`Unknown booking location: ${location}`);
}
