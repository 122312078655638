import React from 'react';

import { useChatMessageForm } from 'hooks/chats/useChatsState';
import { useServices } from 'hooks/useServices';
import { setCustomTimeout } from 'utils/setCustomTimeout';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { SendTypingStatusServiceParams } from 'types/services';

const STOPPED_TYPING_TIMEOUT = 10000;

export const useChatsPageTypingService = (roomId: number) => {
  const services = useServices();

  const { value } = useChatMessageForm(roomId);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const sendTypingStatus = React.useCallback(
    (params: SendTypingStatusServiceParams) => {
      const promise = services.sendTypingStatus(params);

      wrapAsyncSafely(promise);
    },
    [services, wrapAsyncSafely]
  );

  React.useEffect(() => {
    const typing = value.length > 0;

    sendTypingStatus({ roomId, typing });

    if (typing) {
      return setCustomTimeout(() => {
        sendTypingStatus({ roomId, typing: false });
      }, STOPPED_TYPING_TIMEOUT);
    }
  }, [roomId, sendTypingStatus, value.length]);
};
