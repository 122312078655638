import React from 'react';

import styles from './ClipboardData.module.scss';
import { multiplyClassName } from 'utils/className';

type TProps = {
  children: React.ReactNode;
  className?: string;
};

export const ClipboardDataWrapper: React.FC<TProps> = ({
  children,
  className
}) => (
  <div
    className={multiplyClassName([
      className ? className : styles.ClipboardData__Wrapper
    ])}
  >
    {children}
  </div>
);
