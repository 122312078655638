import { TNewsAudienceGroup } from 'types/state';
import { UnreachableError } from 'utils/errors';

export const normalizeAudienceGroup = {
  toSportingPersonType(group: TNewsAudienceGroup) {
    switch (group) {
      case 'force':
        return 3;
      case 'gman':
        return 2;
      case 'hunter':
        return 5;
      case 'sportsmen':
        return 4;
      case 'usual':
        return 1;
      default:
        throw new UnreachableError(group);
    }
  }
};
