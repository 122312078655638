import { TApplication, AppDispatch } from 'types/state';

export const processedRestaurantApplicationsThunk = (
  promise: Promise<TApplication[]>,
  meta: number
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'PROCESSED_RESTAURANT_APPLICATIONS_SERVICE',
    status: 'PENDING',
    meta
  });

  return promise.then(applications => {
    dispatch({
      name: 'PROCESSED_RESTAURANT_APPLICATIONS_SERVICE',
      status: 'RESOLVED',
      payload: applications,
      meta
    });
  });
};
