import { TCompetitionParticipantStatus } from 'types/state';
import { TIntl } from 'types/intl';
import { UnreachableError } from 'utils/errors';

export const getCompetitionParticipantStatusIntl = (
  status: TCompetitionParticipantStatus,
  intl: TIntl
) => {
  switch (status) {
    case 'PARTICIPATION_REQUESTED':
      return intl.competitionParticipantStatusSelectParticipationRequested;
    case 'PARTICIPATION_CONFIRMED':
      return intl.competitionParticipantStatusSelectParticipationConfirmed;
    case 'CANCELATION_REQUESTED':
      return intl.competitionParticipantStatusSelectCancelationRequested;
    case 'CANCELATION_CONFIRMED':
      return intl.competitionParticipantStatusSelectCancelationConfirmed;
    default:
      throw new UnreachableError(status);
  }
};
