import * as React from 'react';

export const useSafeAsyncWrapper = () => {
  const [error, setError] = React.useState(null);

  if (error) {
    throw error;
  }

  const handleAsyncSafely = React.useCallback((promise: Promise<void>) => {
    promise.catch(setError);
  }, []);

  return handleAsyncSafely;
};
