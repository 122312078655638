import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { SportingSecureParams } from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  idUser: number;
};

export const deleteEmployee = async (
  params: SportingSecureParams<Payload>
): Promise<void> => {
  const { payload } = params;

  const headers = createSportingHeaders();

  const endpointURL = getSportingServiceURL(`deleteEmployee/${payload.idUser}`);

  const response = await customFetch(endpointURL, {
    method: 'DELETE',
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
