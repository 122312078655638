import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError, NotFoundError } from 'utils/errors';
import { TGetCompetitionRequestsCountParams } from 'types/services';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const GET_COMPETITION_PARTICIPANTS_COUNT_MOCK_ID__CONNECTION_ERROR = -1;

export const getCompetitionRequestsCount = async (
  params: TGetCompetitionRequestsCountParams
): Promise<number> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (
    params.id === GET_COMPETITION_PARTICIPANTS_COUNT_MOCK_ID__CONNECTION_ERROR
  ) {
    throw new HTTPConnectionError();
  }
  const competition = competitionsStoreMock
    .getAllArticles()
    .find(({ id }) => id === params.id);
  if (!competition) {
    throw new NotFoundError();
  } else {
    return competition.participants.reduce((acc, { status }) => {
      if (
        status === 'CANCELATION_REQUESTED' ||
        status === 'PARTICIPATION_REQUESTED'
      ) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }
};
