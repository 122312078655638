import React from 'react';

import { NewsArticle } from 'containers/NewsArticle';
import { NewsPreviewFooter } from 'containers/NewsPreviewFooter';
import { DeleteArticleModal } from 'containers/DeleteArticleModal';
import { ArticlePreviewContent } from 'containers/ArticlePreviewContent';
import { TNewsArticle } from 'types/state';
import { NewsPreviewTemplate } from 'containers/NewsPreview/view/styled/NewsPreviewTemplate';

type TProps = {
  hasEditView: boolean;
  hasDeleteView: boolean;
  article: TNewsArticle;
  handleEdit(): void;
  handleCancelEdit(): void;
  handleDelete(): void;
  handleDeleted(): void;
  handleCancelDelete(): void;
  handleChange(nextArticle: Partial<TNewsArticle>): void;
  handleSubmited(id: number, asDraft: boolean): void;
};

export const NewsPreviewView = (props: TProps) => {
  const {
    hasEditView,
    hasDeleteView,
    article,
    handleEdit,
    handleCancelEdit,
    handleDelete,
    handleDeleted,
    handleCancelDelete,
    handleChange,
    handleSubmited
  } = props;

  return (
    <NewsPreviewTemplate hasEditView={hasEditView}>
      <ArticlePreviewContent hasScrollShadow={hasEditView}>
        <NewsArticle
          hasEditView={hasEditView}
          data={article}
          onChange={handleChange}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </ArticlePreviewContent>
      {hasEditView && (
        <NewsPreviewFooter
          data={article}
          onCancel={handleCancelEdit}
          onSumbited={handleSubmited}
        />
      )}
      {hasDeleteView && (
        <DeleteArticleModal
          domain="NEWS"
          id={article.id}
          onDeleted={handleDeleted}
          onCancel={handleCancelDelete}
        />
      )}
    </NewsPreviewTemplate>
  );
};
