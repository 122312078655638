import React from 'react';

import styles from './SettingsBlock.module.css';

type Props = {
  children: React.ReactNode;
  position: 'left' | 'right';
};

export const SettingsBlockContent: React.FC<Props> = ({
  children,
  position
}) => {
  const className = (() => {
    const base = styles.SettingsBlock__Content;

    if (position === 'left') {
      return `${base} ${styles.SettingsBlock__Content_left}`;
    }

    if (position === 'right') {
      return `${base} ${styles.SettingsBlock__Content_right}`;
    }

    return base;
  })();

  return <div className={className}>{children}</div>;
};
