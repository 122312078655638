import { TSetReviewStatusParams } from 'types/services';

import { sportingAPI } from 'services/sporting/api';
import { normalizeReviewStatus } from 'services/sporting/utils/normalizeReviewStatus';

export const setReviewStatus = async (
  params: TSetReviewStatusParams
): Promise<void> => {
  const { reviewId, status } = params;

  await sportingAPI.service.setCommentStatus({
    payload: {
      idComment: reviewId,
      idStatus: normalizeReviewStatus.toSportingCommentStatusId(status)
    }
  });
};
