import { ChatRoom } from 'types/chats';
import { getUid } from 'utils/getUid';
import { createRandomBoolean } from 'utils/createRandomBoolean';

export const createChatRoomMock = (): ChatRoom => {
  const read = createRandomBoolean();

  const unreadMessagesCount = read ? 0 : 4;

  const domain = createRandomBoolean() ? 'RESTAURANT' : 'CLUB';

  return {
    id: getUid(),
    clientInfo: {
      id: 0,
      firstName: 'Иван',
      lastName: 'Иванов',
      middleName: 'Иванович',
      status: 'PREMIUM',
      cardNumber: '100500',
      phoneNumber: '+375293044555'
    },
    lastMessageId: getUid(),
    read,
    unreadMessagesCount,
    domain
  };
};

export const createChatRoomMocks = (count: number): ChatRoom[] => {
  const mocks: ChatRoom[] = [];

  while (count > 0) {
    mocks.push(createChatRoomMock());

    count = count - 1;
  }

  return mocks;
};
