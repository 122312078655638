import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from './useAsyncController';

export const useCompetitionRequestsCountService = () => {
  const { getCompetitionRequestsCount } = useServices();

  const dispatch = useAppDispatch();

  // Состояние и метод отправки запроса на количество
  // заявок на участие / отмену участия для соревнования.
  const {
    wrapAsync: wrapCompetitionRequestsCountPulling
  } = useAsyncController();

  // Метод отправки запроса с проброшенными аргументами.
  const updateCompetitonRequestsCount = React.useCallback(
    (id: number) => {
      const promise = getCompetitionRequestsCount({ id }).then(value => {
        dispatch({
          name: 'COMPETITION_REQUESTS_COUNT_SERVICE',
          status: 'RESOLVED',
          payload: value,
          meta: id
        });
      });

      wrapCompetitionRequestsCountPulling(promise);
    },
    [dispatch, getCompetitionRequestsCount, wrapCompetitionRequestsCountPulling]
  );

  return updateCompetitonRequestsCount;
};
