import React from 'react';

import { NotificationPopup } from 'components/Notification/NotificationPopup';
import { NotificationWrapper } from 'components/Notification/NotificationWrapper';
import { testId } from 'utils/testId';
import { useCriticalNotification } from 'hooks/useCriticalNotification';

export const Notification = () => {
  const { notification, close } = useCriticalNotification();

  if (notification) {
    return (
      <NotificationWrapper
        hasOverlay={notification.blocker}
        testId={testId.NOTIFICATION_WRAPPER}
      >
        <NotificationPopup
          message={notification.message}
          onClose={notification.blocker ? undefined : close}
          testIdMessage={testId.NOTIFICATION_MESSAGE}
          testIdCloseButton={testId.NOTIFICATION_CLOSE_BUTTON}
        />
      </NotificationWrapper>
    );
  }
  return null;
};
