import React from 'react';

import styles from './feedItem.module.css';

import {
  createClassNameConstructor,
  ClassNameConstructor
} from 'utils/craeteClassNameConstructor';

type Props = {
  children: React.ReactNode;
  onClick?(): void;
  active?: boolean;
  testId?: string;
};

const applyActiveModification = (active?: boolean) => (
  constructor: ClassNameConstructor
) => {
  if (active) {
    return constructor.addClass(styles.FeedItem_active);
  }

  return constructor;
};

export const FeedItem = (props: Props) => {
  const { children, active, testId, onClick } = props;

  const className = createClassNameConstructor(styles.FeedItem)
    .withConstructor(applyActiveModification(active))
    .return();

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <div className={className} data-testid={testId} onClick={handleClick}>
      {children}
    </div>
  );
};
