import { EmployeeFormError } from '../../types/state';
import { useEmployeeEditorState } from './useEmployeeEditorState';

export const useEmployeeEditorFormError = ():
  | undefined
  | EmployeeFormError[] => {
  const { formError } = useEmployeeEditorState();

  return formError;
};
