import { SubmitChatMessageParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const submitChatMessage = async (params: SubmitChatMessageParams) => {
  const { text, roomId } = params;

  await sportingAPI.chat.pushMessage({
    payload: { idChat: roomId, message: text }
  });
};
