import { SportingPlace } from '../types';
import { Place } from '../../../types/state';
import { normalizeSportingPlaceDestinationType } from './normalizeSportingPlaceDestinationType';

export const normalizeSportingPlace = {
  toPlace(sportingPlace: SportingPlace): Place {
    return {
      id: sportingPlace.id,
      title: sportingPlace.title,
      location: normalizeSportingPlaceDestinationType(
        sportingPlace.location
      ).toServiceScope(),
      orderNumber: sportingPlace.orderNumber,
      items: sportingPlace.items
    };
  }
};
