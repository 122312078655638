import React from 'react';

import styles from './ReviewsPage.module.css';
import { testId } from 'utils/testId';

type TProps = {
  text: string;
};

export const ReviewsPageFallback = ({ text }: TProps) => (
  <div
    className={styles.ReviewsPage__Fallback}
    data-testid={testId.REVIEWS_PAGE_FALLBACK}
  >
    {text}
  </div>
);
