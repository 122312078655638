import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import React from 'react';

export const useBookingWaitersDeleteService = () => {
  const dispatch = useAppDispatch();

  const services = useServices();

  const deleteWaiters = React.useCallback(
    (ids: number[]): Promise<void> => {
      return services.deleteWaiters({ ids }).then(() => {
        dispatch({ name: 'BOOKING_WAITERS_DELETED', deletedWaiterIds: ids });
      });
    },
    [dispatch, services]
  );

  return { deleteWaiters };
};
