import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineLegendLayout.scss';

type Props = {
  items: React.ReactNode;
  pastTimePercent: number;
};

const bemConstructor = createBEMConstructor('bookingTimelineLegendLayout');

export const BookingTimelineLegendLayout: React.FC<Props> = props => {
  const { items, pastTimePercent } = props;

  const wrapperClassName = bemConstructor('wrapper');

  const itemsSlotClassName = bemConstructor('itemsSlot');

  const nowLineSlotClassName = bemConstructor('nowLineSlot');

  const nowLineClassName = bemConstructor('nowLine');

  return (
    <div className={wrapperClassName}>
      <div className={itemsSlotClassName}>{items}</div>
      <div className={nowLineSlotClassName}>
        {pastTimePercent >= 0 && (
          <div
            className={nowLineClassName}
            style={{ left: `${pastTimePercent}%` }}
          />
        )}
      </div>
    </div>
  );
};
