import React from 'react';

import styles from './ReviewNotes.module.css';

type TProps = {
  children: React.ReactNode;
};

export const ReviewNotesWrapper = ({ children }: TProps) => (
  <div className={styles.ReviewNotes__Wrapper}>{children}</div>
);
