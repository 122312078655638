import React from 'react';

type TValuesSignature = {
  [x: string]: string;
};

type TSanitizer = (params: HTMLInputElement | HTMLTextAreaElement) => boolean;

interface ICustomInput<TValues> extends HTMLInputElement {
  name: Extract<keyof TValues, string>;
}

interface ICustomTextArea<TValues> extends HTMLInputElement {
  name: Extract<keyof TValues, string>;
}

/**
 * Хук содержит переиспользуемую логику формы.
 * @param {object} param0 - изначальные значения формы.
 * @param {TSanitizer} param1 - функция, отвечающая за предотвращение недопустимых изменений значений.
 */

export const useFormValues = <TValues extends TValuesSignature>(
  initialValues: TValues,
  sanitizer?: TSanitizer
) => {
  const [values, setValues] = React.useState(initialValues);

  const handleChange = (
    e: React.ChangeEvent<ICustomInput<TValues> | ICustomTextArea<TValues>>
  ) => {
    if (!sanitizer || sanitizer(e.currentTarget)) {
      const { name, value } = e.currentTarget;
      setValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  return {
    values,
    onChange: handleChange
  };
};
