import { GetChatRoomsParams } from 'types/services';
import { ChatRoom, ChatMessage } from 'types/chats';
import { sportingAPI } from 'services/sporting/api';
import { normalizeSportingChat } from 'services/sporting/utils/normalizeSportingChat';
import { normalizeSportingMessage } from 'services/sporting/utils/normalizeSportingMessage';
import { CHAT_ROOMS_PAGE_LENGTH } from 'utils/constants';
import { normalizeServiceScope } from '../utils/normalizeServiceScope';

export const getChatRooms = async (
  params: GetChatRoomsParams
): Promise<[ChatRoom, ChatMessage][]> => {
  const { domain } = params;

  const chats = await sportingAPI.chat.getChats({
    payload: {
      page: params.page - 1,
      size: CHAT_ROOMS_PAGE_LENGTH,
      destinactionType: normalizeServiceScope(domain).toSportingServiceScope()
    }
  });

  return chats.content.map(chat => {
    const { lastMessage } = chat;

    const chatMessage = normalizeSportingMessage(lastMessage).toChatMessage();

    const chatRoom = normalizeSportingChat(chat).toChatRoom();

    return [chatRoom, chatMessage];
  });
};
