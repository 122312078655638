import { TApplication } from 'types/state';
import {
  getNewApplicationMocks,
  getProcessedApplicationMocks
} from 'services/mock/utils/applicationInstances';

let storeMock: TApplication[] = [
  ...getNewApplicationMocks(),
  ...getProcessedApplicationMocks()
];

const pageSize = 10;

const getAllNewApplications = () => {
  return storeMock.filter(({ status }) => status === 'NEW');
};

const getNewApplications = (page: number) => {
  const newApplications = getAllNewApplications();

  return newApplications.slice(pageSize * (page - 1), pageSize);
};

const getAllProcessedApplications = () => {
  return storeMock.filter(({ status }) => status === 'DONE');
};

const getProcessedApplications = (page: number) => {
  const doneApplications = getAllProcessedApplications();

  return doneApplications.slice(pageSize * (page - 1), pageSize);
};

const getAllApplications = () => [...storeMock];

export const restaurantApplicationsStoreMock = {
  getAllNewApplications,
  getNewApplications,
  getProcessedApplications,
  getAllApplications
};
