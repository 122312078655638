import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TGetCompetitionsFeedParams } from 'types/services';
import { TCompetitionsArticle } from 'types/state';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const GET_COMPETITIONS_FEED_PAGE_MOCK__CONNECTION_ERROR = -1;
export const GET_COMPETITIONS_FEED_PAGE_MOCK__EMPTY_FEED = -2;

export const getCompetitionsFeed = async (
  params: TGetCompetitionsFeedParams
): Promise<TCompetitionsArticle[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (params.page === GET_COMPETITIONS_FEED_PAGE_MOCK__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }
  if (params.page === GET_COMPETITIONS_FEED_PAGE_MOCK__EMPTY_FEED) {
    return [];
  }
  return competitionsStoreMock.getArticlesByPage(params.page);
};
