import { addDays, startOfDay } from 'date-fns';
import { ServiceScope } from 'types/serviceScope';
import {
  BookingTimeWorkFormValues,
  CalendarPeriod,
  DayOfWeek
} from '../../types/state';

function create24HPeriod(): CalendarPeriod {
  return {
    startDateISO: startOfDay(new Date()).toISOString(),
    endDateISO: startOfDay(addDays(new Date(), 1)).toISOString()
  };
}

export const createBookingTimeWorkFormValues = (
  location: ServiceScope
): BookingTimeWorkFormValues => {
  const periodsPerDayOfWeek = new Map<DayOfWeek, CalendarPeriod>();

  periodsPerDayOfWeek.set('MONDAY', create24HPeriod());
  periodsPerDayOfWeek.set('TUESDAY', create24HPeriod());
  periodsPerDayOfWeek.set('WEDNESDAY', create24HPeriod());
  periodsPerDayOfWeek.set('THURSDAY', create24HPeriod());
  periodsPerDayOfWeek.set('FRIDAY', create24HPeriod());
  periodsPerDayOfWeek.set('SATURDAY', create24HPeriod());
  periodsPerDayOfWeek.set('SUNDAY', create24HPeriod());

  return {
    location,
    weekly: true,
    dailyPreiod: create24HPeriod(),
    periodsPerDayOfWeek
  };
};
