import React from 'react';

import styles from './PlaceItemOption.module.scss';
import { PlaceItem } from '../../../../types/state';
import { multiplyClassName } from '../../../../utils/className';

type Props = {
  placeItem: PlaceItem;
  onClick(placeItem: PlaceItem): void;
  isSelected(placeItem: PlaceItem): boolean;
  isDisabled(placeItem: PlaceItem): boolean;
};
export const PlaceItemOption = (props: Props) => {
  const { placeItem, onClick, isSelected, isDisabled } = props;

  const order = React.useMemo(() => placeItem.orderNumber, [
    placeItem.orderNumber
  ]);

  return (
    <div
      className={multiplyClassName([
        styles.wrapper,
        isSelected(placeItem) ? styles.selected : '',
        isDisabled(placeItem) ? styles.disabled : ''
      ])}
      style={{ order }}
      onClick={() => {
        if (!isDisabled(placeItem)) {
          onClick(placeItem);
        }
      }}
    >
      <div className={styles.title}>{placeItem.title}</div>
      <div className={styles.count}>
        {placeItem.minVisitorsCount + '-' + placeItem.maxVisitorsCount}
      </div>
    </div>
  );
};
