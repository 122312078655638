import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { ScrollControl, ScrollInfo } from 'design/scrollControl';
import { MessageTemplatesListLayout } from 'design/messageTemplatesListLayout';
import { Shadow } from 'design/shadow';
import { useMessageTemplatesState } from 'hooks/messageTemplates/useMessageTemplatesState';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { MessageTemplatesListItem } from 'containers/MessageTemplates';
import { MessageTemplatesCandidate } from 'containers/MessageTemplates';
import { scrollToBottom } from 'utils/scrollToBottom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { MESSAGE_TEMPLATES_LIST_SCROLL_LEFT_TO_LOAD_MORE } from 'utils/constants';
import { MessageTemplate } from 'types/messageTemplate';
import { useServices } from 'hooks/useServices';
import { messageTemplateMoveThunk } from 'thunks/messageTemplateMoveThunk';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

export const MessageTemplatesList = () => {
  const updateOnReadyRef = React.useRef(false);

  const scrollControlRef = React.useRef<HTMLDivElement>(null);

  const [scrolledToBottom, setScrolledToBottom] = React.useState<
    null | boolean
  >(null);

  const [messageTemplates, setMessageTemplates] = React.useState<
    MessageTemplate[]
  >([]);

  const {
    messageTemplatesList,
    mode,
    templateCandidateText
  } = useMessageTemplatesState();

  const { moveMessageTemplate } = useServices();

  const messageTemplatesListSelectors = React.useMemo(
    () => createListCacheSelectors(messageTemplatesList),
    [messageTemplatesList]
  );

  const messageTemplatesListReady =
    messageTemplatesListSelectors.selectInvalidatedPages().length === 0;

  const messageTemplatesListHasEmptyPage = messageTemplatesListSelectors.hasEmptyPage();

  const allowedToLoadMore =
    !messageTemplatesListHasEmptyPage && messageTemplatesListReady;

  const dispatch = useAppDispatch();

  const handleScroll = React.useCallback(
    (info: ScrollInfo) => {
      if (info.dimensions.bottom === 0) {
        setScrolledToBottom(true);
      } else {
        setScrolledToBottom(false);
      }

      const withinTreshold =
        info.dimensions.top < MESSAGE_TEMPLATES_LIST_SCROLL_LEFT_TO_LOAD_MORE;

      if (allowedToLoadMore && withinTreshold && info.direction === 'up') {
        dispatch({
          name: 'MESSAGE_TEMPLATES__SCROLL_LEFT_TO_LOAD_MORE_REACHED'
        });
      }
    },
    [allowedToLoadMore, dispatch]
  );

  React.useLayoutEffect(() => {
    const { current: updateOnReady } = updateOnReadyRef;

    if (!updateOnReady || messageTemplatesListReady) {
      const nextValue = messageTemplatesListSelectors
        .selectAllItems()
        .reverse();

      setMessageTemplates(nextValue);
    }

    updateOnReadyRef.current = false;
  }, [messageTemplatesListReady, messageTemplatesListSelectors]);

  const hasContent = messageTemplates.length > 0;

  React.useLayoutEffect(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (scrollControlNode && hasContent) {
      scrollToBottom(scrollControlNode);
    }
  }, [hasContent]);

  React.useLayoutEffect(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (scrollControlNode && templateCandidateText.length > 0) {
      scrollToBottom(scrollControlNode);
    }
  }, [templateCandidateText.length]);

  const memoizedMessageTemplatesListItems = React.useMemo(
    () =>
      messageTemplates.map((messageTemplate, index) => (
        <MessageTemplatesListItem
          key={messageTemplate.id}
          messageTemplate={messageTemplate}
          index={index}
        />
      )),
    [messageTemplates]
  );

  const reorderLocalTemplates = React.useCallback(
    (startIndex: number, endIndex: number) => {
      setMessageTemplates(prevTemplates => {
        const nextTemplates = [...prevTemplates];

        const [removed] = nextTemplates.splice(startIndex, 1);

        nextTemplates.splice(endIndex, 0, removed);

        return nextTemplates;
      });
    },
    []
  );

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const reorderPersistedTemplates = React.useCallback(
    (startIndex: number, endIndex: number) => {
      const movedTemplate = messageTemplates[startIndex];

      const targetTemplate = messageTemplates[endIndex];

      const params = {
        templateId: movedTemplate.id,
        newPosition: targetTemplate.position
      };

      const promise = messageTemplateMoveThunk(
        moveMessageTemplate(params),
        params
      )(dispatch);

      wrapAsyncSafely(promise);
    },
    [dispatch, messageTemplates, moveMessageTemplate, wrapAsyncSafely]
  );

  const handleDragEnd = React.useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const startIndex = result.source.index;

      const endIndex = result.destination.index;

      reorderLocalTemplates(startIndex, endIndex);

      reorderPersistedTemplates(startIndex, endIndex);

      updateOnReadyRef.current = true;
    },
    [reorderLocalTemplates, reorderPersistedTemplates]
  );

  return (
    <>
      <ScrollControl ref={scrollControlRef} onScroll={handleScroll}>
        <MessageTemplatesListLayout>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {memoizedMessageTemplatesListItems}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {mode === 'ADD' && <MessageTemplatesCandidate />}
        </MessageTemplatesListLayout>
      </ScrollControl>
      <Shadow enable={scrolledToBottom === false} direction="up" />
    </>
  );
};
