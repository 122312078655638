import { SportingSecureParams, SportingSubmitPlacePayload } from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { customFetch } from '../../../../utils/customFetch';
import { PlaceDeleteError, UnexpectedError } from '../../../../utils/errors';
import { appendHeadersWithJSON } from '../../../../utils/headers';

export const submitPlaces = async (
  params: SportingSecureParams<SportingSubmitPlacePayload[]>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingBookingURL('submitPlaces');

  const headers = createSportingHeaders();
  appendHeadersWithJSON(headers);

  const body = JSON.stringify(payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body,
    headers
  });

  if (!response.ok) {
    if (response.status === 400) {
      throw new PlaceDeleteError();
    }
    throw new UnexpectedError();
  }
};
