import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { SportingSecureParams } from 'services/sporting/types';
import { createQueryString } from 'utils/createQueryString';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  idCompetition: number;
  note: string;
};

export const setCompetitionNote = async (
  params: SportingSecureParams<Payload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL(
    `setCompetitionNote?${createQueryString(payload)}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    method: 'POST',
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
