import { BookingReservationEditorState } from '../../types/state';
import { AppEvent } from '../../types/appEvent';
import {
  createBookingReservationEditorState,
  createBookingReservationFormValues
} from '../../utils/booking/createBookingReservationEditorState';
import { normalizeReservation } from 'utils/booking/normalizeReservation';
import { normalizePerson } from 'utils/normalizePersonLike';

export const bookingReservationEditorStateReducer = (
  state: BookingReservationEditorState,
  event: AppEvent
): BookingReservationEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return createBookingReservationEditorState(null);
    }
    case 'BOOKING_RESERVATION_UPDATED':
    case 'BOOKING_RESERVATION_DELETED':
    case 'BOOKING_RESERVATION_CREATED': {
      return createBookingReservationEditorState(null);
    }
    case 'APPLICATIONS_PAGE__BOOKING_BUTTON_CLICKED': {
      const { initialDateISO, serviceScope, application } = event;

      const initialFormValues = createBookingReservationFormValues(
        serviceScope
      );

      if (application.clientId) {
        initialFormValues.userId = application.clientId;
      }

      if (application.fullName) {
        initialFormValues.fio = application.fullName;
      }

      if (application.cardNumber) {
        initialFormValues.cardNumber = application.cardNumber;
      }

      if (application.phone) {
        initialFormValues.phoneNumber = application.phone;
      }

      initialFormValues.period = {
        startDateISO: initialDateISO,
        endDateISO: initialDateISO
      };

      initialFormValues.initialized = true;

      return {
        ...state,
        formValues: initialFormValues
      };
    }
    case 'CHATS_PAGE__BOOKING_BUTTON_CLICKED': {
      const { clientInfo, serviceScope, initialDateISO } = event;

      const initialFormValues = createBookingReservationFormValues(
        serviceScope
      );

      initialFormValues.userId = clientInfo.id;

      if (clientInfo.cardNumber) {
        initialFormValues.cardNumber = clientInfo.cardNumber;
      }

      if (clientInfo.phoneNumber) {
        initialFormValues.phoneNumber = clientInfo.phoneNumber;
      }

      initialFormValues.fio = normalizePerson(clientInfo).toFullName();

      initialFormValues.period = {
        startDateISO: initialDateISO,
        endDateISO: initialDateISO
      };

      initialFormValues.initialized = true;

      return {
        ...state,
        formValues: initialFormValues
      };
    }
    case 'BookingPage__CreateReservationButtonClicked': {
      const { serviceScope } = event;

      const initialFormValues = createBookingReservationFormValues(
        serviceScope
      );

      initialFormValues.period = {
        startDateISO: new Date().toISOString(),
        endDateISO: new Date().toISOString()
      };

      initialFormValues.initialized = true;

      return {
        ...state,
        formValues: initialFormValues
      };
    }
    case 'BookingPage__ReservationClicked': {
      const { serviceScope, reservation } = event;

      const initialFormValues = normalizeReservation(
        reservation
      ).toBookingReservationFormValues(serviceScope);

      initialFormValues.initialized = true;

      return {
        ...state,
        formValues: initialFormValues
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_GUEST_COUNT': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          personsCount: event.value
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_PERIOD': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          period: event.value
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_PLACE_ITEMS': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          placeItems: event.value
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_COMMENT': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          comment: event.value
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_GUEST_WITHOUT_PRE_BOOKING': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          preBooking: event.value,
          userId: 0,
          fio: '',
          phoneNumber: '',
          cardNumber: ''
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_CLIENT_ID': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          userId: event.value
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_CLIENT_NAME': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          fio: event.value
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_CLIENT_PHONE_NUMBER': {
      const prevValue = state.formValues.phoneNumber;
      const value = event.value;
      const nextValue =
        prevValue.length === 0 && value.substr(0, 1) !== '+'
          ? '+' + value
          : value;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          phoneNumber: nextValue
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_CLIENT_CARD_NUMBER': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          cardNumber: event.value
        }
      };
    }
    case 'BOOKING_RESERVATION_CHANGE_WAITER': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          processedBy: event.value
        }
      };
    }
    case 'BOOKING_WAITERS_DELETED': {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          processedBy: ''
        }
      };
    }
    case 'BOOKING_RESERVATION_CANCEL': {
      return createBookingReservationEditorState(null);
    }
    case 'BookingPage__TrackItemClicked': {
      const { period, placeItem, serviceScope } = event;

      const formValues = createBookingReservationFormValues(serviceScope);

      formValues.initialized = true;

      return createBookingReservationEditorState({
        ...formValues,
        period,
        placeItems: [placeItem]
      });
    }
    case 'BOOKING_RESERVATION_PLACE_ITEMS_DROPPED':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          placeItems: state.formValues.placeItems.filter(
            placeItem => !event.placeItemIds.includes(placeItem.id)
          )
        }
      };
    default:
      return state;
  }
};
