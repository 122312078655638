import React from 'react';

import styles from './SettingsForm.module.css';

type Props = {
  children?: React.ReactNode;
  titleText: string;
  errorText?: string;
};

export const SettingsFormLegend: React.FC<Props> = props => {
  const { children, titleText, errorText } = props;

  return (
    <div className={styles.SettingsForm__Legend}>
      {errorText !== undefined ? (
        <div className={styles.SettingsForm__LegendError}>{errorText}</div>
      ) : (
        <div className={styles.SettingsForm__LegendTitle}>{titleText}</div>
      )}
      <div className={styles.SettingsForm__LegendSlot}>{children}</div>
    </div>
  );
};
