import { NewUserFormError } from 'types/state';
import { LoginInUseError } from 'utils/errors';

export const getNewUserFormErrorFromServiceError = (
  error: Error
): NewUserFormError => {
  if (error instanceof LoginInUseError) {
    return 'LOGIN_IN_USE';
  }

  return 'SERVICE_FAILED';
};
