import { TNewsArticle } from 'types/state';
import { getUid } from 'utils/getUid';

export const newsArticlePublishedMock: TNewsArticle = {
  id: 1,
  title: 'Новое ланч-меню: пенне с говядиной, крем-суп и многое другое',
  description:
    'Сегодня понедельник хоть и дождливый, но уже почти весений! Радуемся этому факту и готовим для вас ланч-меню с 12:00 до 16:00',
  audience: ['usual'],
  publishDate: new Date().toISOString(),
  image: {
    cropped: new Image().src,
    original: new Image().src
  },
  isDraft: false
};

export const newsArticleDraftMock: TNewsArticle = {
  id: 2,
  title: '',
  description: '',
  audience: [],
  publishDate: null,
  image: null,
  isDraft: true
};

// Функция помогает быстро создать определенное
// количество записей базы данных.
export const getNewsArticleMocks = (count: number = 1) => {
  const mocks: TNewsArticle[] = [];
  while (count > 0) {
    const id = getUid();

    mocks.push({
      id,
      title: `Title ${id}`,
      description: `Description ${id}`,
      image: null,
      isDraft: true,
      audience: [],
      publishDate: new Date().toISOString()
    });

    count -= 1;
  }

  return mocks;
};
