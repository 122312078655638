import React from 'react';

import styles from './textInputControl.module.css';

import {
  createClassNameConstructor,
  ClassNameConstructor
} from 'utils/craeteClassNameConstructor';

type Props = {
  value: string;
  id?: string;
  name?: string;
  multiline?: boolean;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  testId?: string;
  maxLength?: number;
  autoFocus?: boolean;
  inputMode?: 'numeric';
  onValueChange(nextValue: string): void;
  onKeyPress?(event: React.KeyboardEvent): void;
  onKeyDown?(event: React.KeyboardEvent): void;
  onKeyUp?(event: React.KeyboardEvent): void;
};

const applyMultilineModification = (multiline?: boolean) => (
  constructor: ClassNameConstructor
) => {
  if (multiline) {
    return constructor.addClass(styles.TextInputControl_textarea);
  }
  return constructor.addClass(styles.TextInputControl_input);
};

export const TextInputControl = (props: Props) => {
  const {
    value,
    id,
    name,
    multiline,
    placeholder,
    disabled,
    invalid,
    maxLength,
    autoFocus,
    inputMode,
    onValueChange,
    onKeyPress,
    onKeyUp,
    onKeyDown,
    testId
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onValueChange(event.currentTarget.value);
    },
    [onValueChange]
  );

  const initialHeightRef = React.useRef<number | null>(null);

  React.useLayoutEffect(() => {
    const { current: textArea } = textAreaRef;
    const { current: initialHeight } = initialHeightRef;

    if (textArea) {
      if (initialHeight !== null) {
        textArea.style.height = initialHeight + 'px';
      } else {
        initialHeightRef.current = textArea.clientHeight;
      }

      textArea.style.height = textArea.scrollHeight + 'px';
    }
  }, [value]);

  const className = createClassNameConstructor(styles.TextInputControl)
    .withConstructor(applyMultilineModification(multiline))
    .return();

  if (multiline) {
    return (
      <textarea
        ref={textAreaRef}
        value={value}
        id={id}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        className={className}
        autoFocus={autoFocus}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        data-testid={testId}
        data-invalid={invalid}
      />
    );
  }

  return (
    <input
      ref={inputRef}
      value={value}
      id={id}
      name={name}
      inputMode={inputMode}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      className={className}
      autoFocus={autoFocus}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      data-testid={testId}
      data-invalid={invalid}
    />
  );
};
