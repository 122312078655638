import * as React from 'react';
import {
  AsyncProcess,
  createAsyncProcess,
  createAsyncProcessConstructor
} from 'utils/asyncProcess';
import { useSafeAsyncWrapper } from './useSafeAsyncWrapper';

export function useAsyncController<ValueType>() {
  const idRef = React.useRef(0);

  const [asyncProcess, setAsyncProcess] = React.useState<
    AsyncProcess<ValueType>
  >(createAsyncProcess);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const wrapAsync = React.useCallback(
    (promise: Promise<ValueType>) => {
      idRef.current++;

      const currentId = idRef.current;

      setAsyncProcess(
        createAsyncProcessConstructor(
          createAsyncProcess<ValueType>()
        ).toPending()
      );

      wrapAsyncSafely(
        promise.then(value => {
          if (currentId === idRef.current) {
            setAsyncProcess(prev => {
              if (value instanceof Error) {
                return createAsyncProcessConstructor(prev).toRejected(value);
              } else {
                return createAsyncProcessConstructor(prev).toResolved(value);
              }
            });
          }
        })
      );
    },
    [wrapAsyncSafely]
  );

  const reset = React.useCallback(() => {
    setAsyncProcess(createAsyncProcess());

    idRef.current++;
  }, []);

  return { asyncProcess, wrapAsync, reset };
}
