import { useUserInfo } from 'hooks/useUserInfoCache';

export const useEnabledSections = () => {
  const userInfo = useUserInfo();

  return userInfo?.enabledSections;
};

export const useEnabledSectionsStrict = () => {
  const sections = useEnabledSections();

  if (!sections) {
    throw new TypeError();
  }

  return sections;
};
