import React from 'react';

import { Text } from 'design/text';
import { MessageBox } from 'design/messageBox';
import { ChatMessageLayout } from 'design/chatMessageLayout';
import { useChatMessageForm } from 'hooks/chats/useChatsState';
import { useIntlContext } from 'hooks/useIntlContext';
import { ActionControl } from 'design/actionControl';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { RetryIcon } from 'svg/16x16/retry';

type Props = {
  roomId: number;
};

export const ChatsPageMessageCandidate = (props: Props) => {
  const { roomId } = props;

  const chatMessageForm = useChatMessageForm(roomId);

  const { chatsPage } = useIntlContext();

  const dispatch = useAppDispatch();

  const handleRetryButtonClick = React.useCallback(() => {
    dispatch({
      name: 'CHATS_PAGE_MESSAGES__RETRY_BUTTON_CLICK',
      roomId
    });
  }, [dispatch, roomId]);

  if (chatMessageForm.status === 'REJECTED') {
    return (
      <ChatMessageLayout
        align="right"
        primaryLabel={null}
        secondaryLabel={
          <Text size="s" color="critical">
            {chatsPage.messageCandidateRetry}
          </Text>
        }
        message={
          <MessageBox theme="secondary">
            <Text>{chatMessageForm.submittedValue}</Text>
          </MessageBox>
        }
        action={
          <ActionControl theme="round-small" onClick={handleRetryButtonClick}>
            <Text color="critical">
              <RetryIcon />
            </Text>
          </ActionControl>
        }
      />
    );
  }

  return null;
};
