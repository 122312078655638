import React from 'react';

import styles from './AudienceInput.module.css';

type TProps = {
  children: React.ReactNode;
  title: string;
};

export const AudienceInputTemplate: React.FC<TProps> = ({
  title,
  children
}) => (
  <div className={styles.AudienceInput__Wrapper}>
    <div className={styles.AudienceInput__Header}>{title}</div>
    <div className={styles.AudienceInput__Body}>{children}</div>
  </div>
);
