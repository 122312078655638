import {
  UsersEditorState,
  NewUserEditorState,
  AdministratorInfo,
  UserEditorState
} from 'types/state';
import { Cache } from 'types/util';

export const patchUsersEditorState = (state: UsersEditorState) => ({
  done() {
    return state;
  },
  setUserEditorState(userId: number, editorState: UserEditorState) {
    const nextUserEditors = new Map(state.userEditorStatesByUserId);

    nextUserEditors.set(userId, editorState);

    return patchUsersEditorState({
      ...state,
      userEditorStatesByUserId: nextUserEditors
    });
  },
  setUserEditorStatesByUserId(nextValue: Map<number, UserEditorState>) {
    return patchUsersEditorState({
      ...state,
      userEditorStatesByUserId: nextValue
    });
  },
  setNewUserEditorState(nextValue: null | NewUserEditorState) {
    return patchUsersEditorState({
      ...state,
      newUserEditorState: nextValue
    });
  },
  setAdministratorInfosCache(nextValue: Cache<Set<AdministratorInfo>>) {
    return patchUsersEditorState({
      ...state,
      administratorInfosCache: nextValue
    });
  }
});
