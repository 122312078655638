import React from 'react';

import styles from './phoneButton.module.scss';

import { RectangleButton } from 'components/shared/RectangleButton';
import { PhoneIcon } from 'svg/24x24/phone';

type Props = {
  phone: string;
  onClick(): void;
};

export const PhoneButtonMobile = ({ phone, onClick }: Props) => {
  return (
    <RectangleButton
      className={styles.PhoneButton_mobile}
      label={phone}
      onClick={onClick}
      icon={<PhoneIcon />}
    />
  );
};
