import { createStartOfDate } from 'utils/dateUtils';

/**
 * @deprecated
 * Используйте утилиты из date-fns
 */
export const checkDate = (date1: Date) => {
  const date1Time = createStartOfDate(date1).getTime();

  const equalTo = (date2: Date) =>
    date1Time === createStartOfDate(date2).getTime();

  const lessThen = (date2: Date) =>
    date1Time < createStartOfDate(date2).getTime();

  const moreThen = (date2: Date) =>
    date1Time > createStartOfDate(date2).getTime();

  return {
    equalTo,
    lessThen,
    moreThen
  };
};
