import React from 'react';

import {
  TCompetitionsFilter,
  TCompetitionsFeedFilterOption
} from 'types/state';
import { authorizedRoutes } from 'utils/authorizedRoutes';
import { getScrollLeftPx } from 'utils/getScrollLeftPx';
import { COMPETITIONS_FEED_SCROLL_LEFT_TO_LOAD_MORE } from 'utils/constants';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  children: React.ReactNode;
  activeFilter: TCompetitionsFilter;
  isFilterDisabled: boolean;
  hasCreateArticleButton: boolean;
  hasPreloader: boolean;
  hasEmptyFallback: boolean;
  onLoadMore(): void;
  onCreateArticle(): void;
};

export const useCompetitionsFeedState = (props: TProps) => {
  const {
    children,
    activeFilter,
    isFilterDisabled,
    hasCreateArticleButton,
    hasPreloader,
    hasEmptyFallback,
    onLoadMore,
    onCreateArticle
  } = props;

  const {
    competitionsPageEmpty: emptyFallbackText,
    competitionsFeedFilterUpcoming,
    competitionsFeedFilterPast
  } = useIntlContext();

  const [selectedFilter, setSelectedFilter] = React.useState<
    TCompetitionsFilter
  >(activeFilter);

  const handleCreateArticle = onCreateArticle;

  const handleScroll = (event: React.SyntheticEvent) => {
    const { target } = event;
    if (target instanceof Element) {
      const scrollLeftPx = getScrollLeftPx(target);
      if (scrollLeftPx < COMPETITIONS_FEED_SCROLL_LEFT_TO_LOAD_MORE) {
        onLoadMore();
      }
    }
  };

  const handleFilterChange = React.useCallback(
    (nextFilter: TCompetitionsFilter) => {
      setSelectedFilter(nextFilter);
    },
    []
  );

  const filterOptions = React.useMemo<TCompetitionsFeedFilterOption[]>(
    () => [
      {
        value: 'upcoming',
        label: competitionsFeedFilterUpcoming
      },
      {
        value: 'past',
        label: competitionsFeedFilterPast
      }
    ],
    [competitionsFeedFilterPast, competitionsFeedFilterUpcoming]
  );

  const redirectPath = React.useMemo(() => {
    if (selectedFilter !== activeFilter) {
      return authorizedRoutes.competitions(selectedFilter);
    }
  }, [selectedFilter, activeFilter]);

  return {
    children,
    activeFilter,
    isFilterDisabled,
    redirectPath,
    filterOptions,
    emptyFallbackText,
    handleCreateArticle,
    handleFilterChange,
    handleScroll,
    hasCreateArticleButton,
    hasPreloader,
    hasEmptyFallback
  };
};
