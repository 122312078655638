import { ServiceScope } from 'types/serviceScope';
import { BookingPlaceFormValues } from 'types/state';
import { SportingSubmitPlacePayload } from '../types';
import { normalizeBookingPlaceItemFormValues } from './normalizeBookingPlaceItemFormValues';
import { normalizeServiceScope } from './normalizeServiceScope';

export const normalizeBookingPlaceFormValues = (
  placeFormValues: BookingPlaceFormValues
) => ({
  toSportingSubmitPlacePayload(
    location: ServiceScope
  ): SportingSubmitPlacePayload {
    return {
      dataAction: placeFormValues.dataAction,
      id: Number(placeFormValues.id),
      title: placeFormValues.title,
      location: normalizeServiceScope(location).toSportingServiceScope(),
      orderNumber: placeFormValues.orderNumber,
      items: placeFormValues.items.map(item => {
        return normalizeBookingPlaceItemFormValues(
          item
        ).toSportingSubmitPlaceItemPayload();
      })
    };
  }
});
