import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSectionsEditorState } from 'hooks/useSectionsEditorState';
import { useSectionsToSync } from 'hooks/useSectionsToSync';
import { useAsyncController } from './useAsyncController';
import { AuthorizationError } from 'utils/errors';

export const useUpdateEnabledSectionsService = () => {
  const { submited } = useSectionsEditorState();

  const dispatch = useAppDispatch();

  const services = useServices();

  const {
    wrapAsync: wrapEnabledSectionsUpdate,
    asyncProcess: enabledSectionsUpdateProcess
  } = useAsyncController();

  const { sectionsToEnable, sectionsToDisable } = useSectionsToSync();

  React.useEffect(() => {
    if (submited) {
      const promise = services
        .updateEnabledSections({
          sectionsToDisable,
          sectionsToEnable
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }

          return error;
        });

      wrapEnabledSectionsUpdate(promise);
    }
  }, [
    submited,
    sectionsToDisable,
    sectionsToEnable,
    services,
    wrapEnabledSectionsUpdate
  ]);

  React.useEffect(() => {
    if (enabledSectionsUpdateProcess.status === 'PENDING') {
      dispatch({
        name: 'UPDATE_ENABLED_SECTIONS_SERVICE',
        status: 'PENDING'
      });
    }
    if (enabledSectionsUpdateProcess.status === 'RESOLVED') {
      dispatch({
        name: 'UPDATE_ENABLED_SECTIONS_SERVICE',
        status: 'RESOLVED',
        payload: undefined
      });
    }
    if (enabledSectionsUpdateProcess.status === 'REJECTED') {
      dispatch({
        name: 'UPDATE_ENABLED_SECTIONS_SERVICE',
        status: 'REJECTED',
        payload: enabledSectionsUpdateProcess.error
      });
    }
  }, [dispatch, enabledSectionsUpdateProcess]);
};
