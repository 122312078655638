import { DeleteMessageTemplateParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const deleteMessageTemplate = async (
  params: DeleteMessageTemplateParams
): Promise<void> => {
  const { messageTemplateId } = params;

  await sportingAPI.chat.deleteAnswerDraft({
    payload: { idDraft: messageTemplateId }
  });
};
