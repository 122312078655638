import React from 'react';

import styles from './CustomInput.module.css';

type Props = {
  placeholder: string;
  name: string;
  type: 'text' | 'password';
  value: string;
  autoComplete?: 'on' | 'off' | 'new-password';
  autoFocus?: boolean;
  testId?: string;
  className?: string;
  isUnderlined?: boolean;
  maxLength?: number;
  isDisabled?: boolean;

  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

export const CustomInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      isDisabled,
      isUnderlined,
      className = '',
      testId,
      maxLength,
      ...nativeProps
    } = props;

    const forwardClassName = `${styles.CustomInput} ${
      isUnderlined
        ? styles.CustomInput_underlined
        : styles.CustomInput_surrounded
    } ${className}`;

    return (
      <input
        {...nativeProps}
        ref={ref}
        className={forwardClassName}
        disabled={isDisabled}
        maxLength={maxLength}
        data-testid={testId}
      />
    );
  }
);
