import { PlusIcon } from 'svg/20x20/plus';
import React from 'react';

import styles from './CreateButton.module.css';

type TProps = {
  useDiv?: boolean;
  testId?: string;
  className?: string;
  onClick?: () => void;
};

export const CreateButton = ({
  onClick,
  useDiv,
  className = '',
  testId
}: TProps) => {
  if (useDiv) {
    return (
      <div
        className={`${styles.CreateButton} ${className}`}
        onClick={onClick}
        data-testid={testId}
      >
        <PlusIcon />
      </div>
    );
  }
  return (
    <button
      className={`${styles.CreateButton} ${className}`}
      onClick={onClick}
      data-testid={testId}
    >
      <PlusIcon />
    </button>
  );
};
