import { TGetCompetitionNoteParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const getCompetitionNote = async (
  params: TGetCompetitionNoteParams
): Promise<string> => {
  const { id } = params;

  const { note } = await sportingAPI.service.getCompetitionNote({
    payload: { idCompetition: id }
  });

  return note;
};
