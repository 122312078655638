import React from 'react';

import { useServices } from 'hooks/useServices';
import { chatRoomReadStatusThunk } from 'thunks/chatRoomReadStatusThunk';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

export const useChangeChatReadStatusCallback = () => {
  const { setChatRoomReadStatus } = useServices();

  const dispatch = useAppDispatch();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const changeReadStatus = React.useCallback(
    (roomId: number, nextValue: boolean) => {
      const params = {
        roomId,
        nextValue
      };

      const promise = chatRoomReadStatusThunk(
        setChatRoomReadStatus(params),
        params
      )(dispatch);

      wrapAsyncSafely(promise);
    },
    [dispatch, setChatRoomReadStatus, wrapAsyncSafely]
  );

  return changeReadStatus;
};
