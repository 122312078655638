import React from 'react';

import { REVIEWS_FEED_PAGE_LENGTH } from 'utils/constants';
import { useServices } from 'hooks/useServices';
import { useAppState } from './useAppState';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { useAsyncController } from './useAsyncController';
import { useAppDispatch } from './useAppDispatch';

export const useReviewsFeed = () => {
  const { reviewsCache } = useAppState();

  const noMoreItemsRef = React.useRef(false);

  const invalidatedPages = React.useMemo(() => {
    return createListCacheSelectors(reviewsCache).selectInvalidatedPages();
  }, [reviewsCache]);

  const dispath = useAppDispatch();

  // Логика отправки запроса за отзывами
  const { getReviews } = useServices();

  const { asyncProcess: reviewsProcess, wrapAsync } = useAsyncController<
    void[]
  >();

  const loadReviews = React.useCallback(() => {
    const promises = invalidatedPages.map(page =>
      getReviews({ pageNumber: page }).then(reviews => {
        dispath({
          name: 'ReviewsPageEvent__ReviewsPageRecieved',
          page: page,
          reviews
        });
      })
    );

    const promise = Promise.all(promises);

    wrapAsync(promise);
  }, [dispath, getReviews, invalidatedPages, wrapAsync]);

  // Логика обработки состояния запроса
  // и добавления новых отзывов в кэш
  React.useEffect(() => {
    for (let [, reviews] of Array.from(reviewsCache.byPage)) {
      if (reviews.length < REVIEWS_FEED_PAGE_LENGTH) {
        noMoreItemsRef.current = true;
      }
    }
  }, [reviewsCache.byPage]);

  // Отправка запроса при каждом изменении
  // соответствующего хэндлера
  React.useEffect(() => {
    loadReviews();
  }, [loadReviews]);

  // Нормализация состояния

  const reviewsFeed = React.useMemo(() => {
    return createListCacheSelectors(reviewsCache).selectAllItems();
  }, [reviewsCache]);

  const loadNextPage = React.useCallback(() => {
    if (reviewsProcess.status !== 'PENDING' && !noMoreItemsRef.current) {
      dispath({
        name: 'ReviewsPageEvent__NextPageRequested'
      });
    }
  }, [dispath, reviewsProcess.status]);

  return {
    reviewsFeed,
    reviewsProcess,
    loadNextPage
  };
};
