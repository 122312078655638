import { Place } from '../../types/state';
import { AppEvent } from '../../types/appEvent';
import { createCache, modifyCache } from 'utils/createCache';
import { Cache } from 'types/util';

export const placesCacheReducer = (
  state: Cache<Map<number, Place>>,
  event: AppEvent
): Cache<Map<number, Place>> => {
  switch (event.name) {
    case 'PlacesRecieved':
      const map = new Map();

      event.places.forEach(place => {
        map.set(place.id, place);
      });

      return createCache(map);
    case 'ROUTER__LOCATION_PATHNAME_CHANGE':
    case 'BOOKING_AREA_UPDATED':
    case 'BOOKING_PLACE_SUBMIT_DID_SUCCEED':
      return modifyCache(state)
        .invalidate()
        .return();
    default:
      return state;
  }
};
