import { TAddNewUserParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const addNewUser = async (params: TAddNewUserParams): Promise<void> => {
  const { password, login, phoneNumber } = params;

  const adminInfo = await sportingAPI.service.addNewAdmin({
    payload: {
      password,
      username: login
    }
  });

  const internalNumber = phoneNumber === 'null' ? undefined : phoneNumber;

  await sportingAPI.service.attachDevice({
    payload: {
      idUser: adminInfo.id,
      internalNumber
    }
  });
};
