import {
  BookingPlaceFormValues,
  BookingPlaceItemFormValues,
  BookingPlacesEditorState,
  PlaceDataAction
} from '../../types/state';
import { AppEvent } from '../../types/appEvent';
import {
  createBookingPlacesEditorState,
  createNewBookingPlaceFormValues,
  createNewPlaceItemFormValues
} from '../../utils/booking/createBookingPlacesEditorState';
import { PlaceChangeOrderType } from 'components/BookingTableSettingsModal/components/BookingPlace/BookingPlace';
import { authorizedRoutes } from 'utils/authorizedRoutes';
import { validateBookingPlacesFormValues } from 'utils/validateBookingPlasesFormValues';

export const bookingPlacesEditorStateReducer = (
  state: BookingPlacesEditorState,
  event: AppEvent
): BookingPlacesEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      if (event.nextLocation === authorizedRoutes.bookingRestaurant()) {
        return createBookingPlacesEditorState(null, 'RESTAURANT');
      }
      if (event.nextLocation === authorizedRoutes.bookingClub()) {
        return createBookingPlacesEditorState(null, 'CLUB');
      }

      return state;
    }
    case 'BOOKING_PLACE_FORM_INIT': {
      if (event.value.length > 0) {
        return {
          ...createBookingPlacesEditorState(null, event.location),
          formValues: {
            ...state.formValues,
            initialized: true,
            places: event.value.map(place => {
              return {
                dataAction: 'NOTHING',
                id: place.id.toString(),
                title: place.title,
                orderNumber: place.orderNumber,
                items: place.items.map(item => {
                  return {
                    dataAction: 'NOTHING',
                    id: item.id.toString(),
                    title: item.title,
                    minVisitorsCount: item.minVisitorsCount
                      ? item.minVisitorsCount.toString()
                      : '',
                    maxVisitorsCount: item.maxVisitorsCount
                      ? item.maxVisitorsCount.toString()
                      : '',
                    orderNumber: item.orderNumber
                  };
                })
              };
            })
          }
        };
      }

      return createBookingPlacesEditorState(null, event.location);
    }
    case 'BOOKING_PLACE_ADD': {
      const placesCount = state.formValues.places.length;
      const newPlaceFormValues = createNewBookingPlaceFormValues(placesCount);
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: [...state.formValues.places, newPlaceFormValues]
        }
      };
    }
    case 'BOOKING_PLACE_DELETE': {
      const places = state.formValues.places;
      const placeForDelete = places.find(place => place.id === event.id);
      let newPlaces;
      if (placeForDelete && placeForDelete.dataAction === 'CREATE_NEW') {
        newPlaces = places.filter(place => place.id !== event.id);
      } else {
        newPlaces = places.map<BookingPlaceFormValues>(place => {
          if (place.id === event.id) {
            return {
              ...place,
              dataAction: 'DELETE',
              items: place.items.map<BookingPlaceItemFormValues>(item => ({
                ...item,
                dataAction: 'DELETE'
              }))
            };
          }

          return place;
        });
      }
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: newPlaces
        }
      };
    }
    case 'BOOKING_PLACE_CHANGE_TITLE': {
      const places = state.formValues.places.map(place => {
        if (place.id === event.id) {
          return {
            ...place,
            title: event.value,
            dataAction: (place.dataAction !== 'CREATE_NEW'
              ? 'UPDATE_EXISTING'
              : 'CREATE_NEW') as PlaceDataAction
          };
        }
        return place;
      });
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: places
        }
      };
    }
    case 'BOOKING_PLACE_CHANGE_ORDER': {
      const startIndex = state.formValues.places.findIndex(
        place => place.id === event.id
      );

      const nextIndex =
        event.type === PlaceChangeOrderType.UP
          ? startIndex - 1
          : startIndex + 1;

      if (nextIndex < 0 || nextIndex >= state.formValues.places.length) {
        return state;
      }

      let nextPlaces = [...state.formValues.places];

      const [removed] = nextPlaces.splice(startIndex, 1);

      nextPlaces.splice(nextIndex, 0, removed);

      nextPlaces = nextPlaces.map<BookingPlaceFormValues>((place, index) => ({
        ...place,
        orderNumber: index,
        dataAction:
          place.dataAction === 'CREATE_NEW' ? 'CREATE_NEW' : 'UPDATE_EXISTING'
      }));

      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: nextPlaces
        }
      };
    }

    case 'BOOKING_PLACE_ITEM_CHANGE_ORDER': {
      const nextPlaces = state.formValues.places.map<BookingPlaceFormValues>(
        (place, index) => {
          if (place.id === event.placeId) {
            const targetPlace: BookingPlaceFormValues = {
              ...place,
              dataAction: 'UPDATE_EXISTING'
            };

            const nextItems = [...targetPlace.items];

            const [removed] = nextItems.splice(event.startIndex, 1);

            nextItems.splice(event.endIndex, 0, removed);

            targetPlace.items = nextItems.map<BookingPlaceItemFormValues>(
              (item, index) => ({
                ...item,
                orderNumber: index,
                dataAction:
                  item.dataAction === 'CREATE_NEW'
                    ? 'CREATE_NEW'
                    : 'UPDATE_EXISTING'
              })
            );

            return targetPlace;
          }

          return place;
        }
      );

      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: nextPlaces
        }
      };
    }
    case 'BOOKING_PLACE_ITEM_ADD': {
      const places = state.formValues.places.map(place => {
        if (place.id === event.placeId) {
          const itemsCount = place.items.length;
          const newPlaceItemFormValues = createNewPlaceItemFormValues(
            itemsCount
          );
          return {
            ...place,
            items: [...place.items, newPlaceItemFormValues]
          };
        }
        return place;
      });
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: places
        }
      };
    }
    case 'BOOKING_PLACE_ITEM_DELETE': {
      const places = state.formValues.places.map(place => {
        if (place.id === event.placeId) {
          const items = place.items;
          const itemForDelete = items.find(
            item => item.id === event.placeItemId
          );
          let newItems;
          if (itemForDelete && itemForDelete.dataAction === 'CREATE_NEW') {
            newItems = items.filter(item => item.id !== event.placeItemId);
          } else {
            newItems = items.map(item => {
              if (item.id === event.placeItemId) {
                return {
                  ...item,
                  dataAction: 'DELETE' as PlaceDataAction
                };
              }
              return item;
            });
          }
          return {
            ...place,
            items: newItems
          };
        }
        return place;
      });
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: places
        }
      };
    }
    case 'BOOKING_PLACE_ITEM_CHANGE_TITLE': {
      const places = state.formValues.places.map(place => {
        if (place.id === event.placeId) {
          return {
            ...place,
            items: place.items.map(item => {
              if (item.id === event.placeItemId) {
                return {
                  ...item,
                  title: event.value,
                  dataAction: (item.dataAction !== 'CREATE_NEW'
                    ? 'UPDATE_EXISTING'
                    : 'CREATE_NEW') as PlaceDataAction
                };
              }
              return item;
            })
          };
        }
        return place;
      });
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: places
        }
      };
    }
    case 'BOOKING_PLACE_ITEM_CHANGE_MIN_COUNT': {
      const places = state.formValues.places.map(place => {
        if (place.id === event.placeId) {
          return {
            ...place,
            items: place.items.map(item => {
              if (item.id === event.placeItemId) {
                return {
                  ...item,
                  minVisitorsCount: event.value,
                  dataAction: (item.dataAction !== 'CREATE_NEW'
                    ? 'UPDATE_EXISTING'
                    : 'CREATE_NEW') as PlaceDataAction
                };
              }
              return item;
            })
          };
        }
        return place;
      });
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: places
        }
      };
    }
    case 'BOOKING_PLACE_ITEM_CHANGE_MAX_COUNT': {
      const places = state.formValues.places.map(place => {
        if (place.id === event.placeId) {
          return {
            ...place,
            items: place.items.map(item => {
              if (item.id === event.placeItemId) {
                return {
                  ...item,
                  maxVisitorsCount: event.value,
                  dataAction: (item.dataAction !== 'CREATE_NEW'
                    ? 'UPDATE_EXISTING'
                    : 'CREATE_NEW') as PlaceDataAction
                };
              }
              return item;
            })
          };
        }
        return place;
      });
      return {
        ...state,
        formValues: {
          ...state.formValues,
          places: places
        }
      };
    }
    case 'BOOKING_PLACE_FORM_VALUES_VALIDATED': {
      if (event.violations.length === 0) {
        return {
          ...state,
          formError: undefined
        };
      }

      return {
        ...state,
        formError: event.violations
      };
    }
    case 'BOOKING_PLACE_CANCEL': {
      return createBookingPlacesEditorState(null, event.location);
    }
    case 'BOOKING_PLACE_SUBMIT': {
      const violations = validateBookingPlacesFormValues(state.formValues);

      if (violations.length > 0) {
        return {
          ...state,
          formError: violations
        };
      }

      return {
        ...state,
        formError: undefined,
        submited: true
      };
    }
    case 'BOOKING_PLACE_SUBMIT_DID_SUCCEED': {
      return {
        ...state,
        submitDidSucceed: true
      };
    }
    default:
      return state;
  }
};
