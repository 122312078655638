import React from 'react';

import { useUsersEditorState } from 'hooks/useUsersEditorState';

export const useDeletedUserId = () => {
  const { userEditorStatesByUserId } = useUsersEditorState();

  return React.useMemo(() => {
    const entry = Array.from(userEditorStatesByUserId.entries()).find(
      ([id, editorState]) => editorState.formValues.deleted
    );

    if (entry) {
      const [id] = entry;

      return id;
    }
  }, [userEditorStatesByUserId]);
};
