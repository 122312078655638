import { MoveEmployeeParams } from '../types/services';
import { AppDispatch, UserInfo } from '../types/state';

export const moveEmployeeThunk = (
  promise: Promise<UserInfo[]>,
  meta: MoveEmployeeParams
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'MOVE_EMPLOYEE_SERVICE',
    status: 'PENDING',
    meta
  });

  return promise.then(res => {
    dispatch({
      name: 'MOVE_EMPLOYEE_SERVICE',
      status: 'RESOLVED',
      payload: res,
      meta
    });
  });
};
