import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TGetSportApplicationsParams } from 'types/services';
import { TApplication } from 'types/state';
import { sportApplicationsStoreMock } from 'services/mock/utils/sportApplicationsModel';

export const GET_SPORT_APPLICATIONS_MOCK_PAGE_NUMBER__CONNECTION_ERROR = -1;

export const getSportProcessedApplications = async (
  params: TGetSportApplicationsParams
): Promise<TApplication[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (
    params.pageNumber ===
    GET_SPORT_APPLICATIONS_MOCK_PAGE_NUMBER__CONNECTION_ERROR
  ) {
    throw new HTTPConnectionError();
  }
  return sportApplicationsStoreMock.getProcessedApplications(params.pageNumber);
};
