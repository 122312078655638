import { AppEvent } from 'types/appEvent';
import { UserInfo } from 'types/state';
import { patchCache } from 'utils/patchCache';
import { Cache } from 'types/util';

export const userInfoCacheReducer = (
  state: Cache<null | UserInfo>,
  event: AppEvent
): Cache<null | UserInfo> => {
  switch (event.name) {
    case 'UPDATE_ENABLED_SECTIONS_SERVICE':
    case 'UPDATE_CURRENT_USER_PERSONAL_DATA_SERVICE':
    case 'UPDATE_CURRENT_USER_LOGIN_SERVICE':
    case 'SIGN_IN_SERVICE': {
      if (event.status === 'RESOLVED') {
        return patchCache(state)
          .invalidate()
          .done();
      }

      return state;
    }
    case 'USER_INFO_SERVICE': {
      if (event.status === 'RESOLVED') {
        return patchCache(state)
          .setValue(event.payload)
          .done();
      }

      return state;
    }
    default:
      return state;
  }
};
