import { TimeLine } from '../../../types/state';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';
import { timeLinesStoreMock } from '../utils/timeLinesModel';

export const getTimeLines = async (): Promise<TimeLine[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return timeLinesStoreMock.getAll();
};
