import { UnreachableError } from 'utils/errors';

type Demployment = 'testing' | 'production';

const TESTING_HOSTNAME = 's-club-admin.agmsoft.org';

const PRODUCTION_HOSTNAME = 'admin.klub.by';

export const checkDeployedOn = (value: Demployment) => {
  switch (value) {
    case 'testing':
      return window.location.hostname === TESTING_HOSTNAME;
    case 'production':
      return window.location.hostname === PRODUCTION_HOSTNAME;
    default:
      throw new UnreachableError(value);
  }
};
