import React from 'react';

import styles from './MobileHeader.module.scss';

type Props = {
  collapsed: boolean;
  onClick(): void;
};

export const MobileHeaderDrawerButton = ({ onClick, collapsed }: Props) => {
  const className = (() => {
    const base = styles.MobileHeader__DrawerButton;

    if (collapsed) {
      return `${base} ${styles.MobileHeader__DrawerButton_collapsed}`;
    }

    return base;
  })();

  return (
    <div onClick={onClick} className={className}>
      <span />
      <span />
      <span />
    </div>
  );
};
