import { Session } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { createSessionUnauthorized } from 'utils/createSession';

export const sessionReducer = (
  state: null | Session,
  event: AppEvent
): null | Session => {
  switch (event.name) {
    case 'SESSION_RECIEVED': {
      return event.session;
    }
    case 'NAVIGATION__SIGNED_OUT':
    case 'SESSION_TERMINATED': {
      return createSessionUnauthorized();
    }
    default:
      return state;
  }
};
