import { Cache } from '../../types/util';
import { Reservation } from '../../types/state';
import { AppEvent } from '../../types/appEvent';
import { createCache, modifyCache } from 'utils/createCache';

export const reservationsCacheReducer = (
  state: Cache<Map<number, Reservation>>,
  event: AppEvent
): Cache<Map<number, Reservation>> => {
  switch (event.name) {
    case 'ReservationsRecieved':
      const map = new Map();

      for (let reservation of event.reservations) {
        map.set(reservation.id, reservation);
      }

      return createCache(map);
    case 'BookingPage__DateSelected':
    case 'ROUTER__LOCATION_PATHNAME_CHANGE':
    case 'BOOKING_RESERVATION_DELETED':
    case 'BOOKING_RESERVATION_UPDATED':
    case 'BOOKING_RESERVATION_CREATED':
    case 'BOOKING_AREA_UPDATED':
      return modifyCache(state)
        .invalidate()
        .return();
    default:
      return state;
  }
};
