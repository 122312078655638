import React from 'react';
import { Crop } from 'react-image-crop';

import { ModalPortal } from 'components/shared/Modal';
import { TCropperParams } from 'types/state';
import { CropImageModalWindow } from 'containers/CropImageModal/view/styled/CropImageModalWindow';
import { CropImageModalCropper } from 'containers/CropImageModal/view/styled/CropImageModalCropper';
import { CropImageModalFooter } from 'containers/CropImageModal/view/styled/CropImageModalFooter';
import { CropImageModalButton } from 'containers/CropImageModal/view/styled/CropImageModalButton';

type TProps = {
  cropperParams: TCropperParams;
  src: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  isHidden: boolean;
  hasPendingView: boolean;
  handleChange(nextCrop: Crop): void;
  handleImageLoaded(image: HTMLImageElement): void;
  handleCancel(): void;
  handleConfirm(): void;
};

export const CropImageModalView = (props: TProps) => {
  const {
    cropperParams,
    src,
    isHidden,
    cancelButtonLabel,
    confirmButtonLabel,
    hasPendingView,
    handleChange,
    handleImageLoaded,
    handleCancel,
    handleConfirm
  } = props;

  return (
    <ModalPortal noPadding>
      <CropImageModalWindow isHidden={isHidden}>
        <CropImageModalCropper
          {...cropperParams}
          src={src}
          isDisabled={hasPendingView}
          onChange={handleChange}
          onImageLoaded={handleImageLoaded}
        />
        <CropImageModalFooter>
          <CropImageModalButton
            styleType={'secondary'}
            isDisabled={hasPendingView}
            label={cancelButtonLabel}
            onClick={handleCancel}
          />
          <CropImageModalButton
            styleType={'primary'}
            hasPreloader={hasPendingView}
            label={confirmButtonLabel}
            onClick={handleConfirm}
          />
        </CropImageModalFooter>
      </CropImageModalWindow>
    </ModalPortal>
  );
};
