import { ChatMessage } from 'types/chats';
import {
  createChatMessageMock,
  createChatMessageMocks
} from 'services/mock/utils/chatMessageInstances';
import { startOfYesterday, subMinutes } from 'date-fns';
import { startOfMonth } from 'date-fns';
import { CHAT_MESSAGES_PAGE_LENGTH } from 'utils/constants';

// В этом файле содержится in-memory база данных,
// которая используется mock-сервисами для имитации
// взаимодействия с сервером при dev-разработке.

// Меняя эту константу можно влиять на количество
// предварительно созданных записей в базе данных.
const INITIAL_STORE_LENGTH = 0;

// База данных.
let storeMock: ChatMessage[] = createChatMessageMocks(INITIAL_STORE_LENGTH);

storeMock.push({
  ...createChatMessageMock(),
  text:
    'Длинное сообщение чтобы проверить пернос текста на новую строку, отправленное в начале месяца.',
  createdAt: startOfMonth(new Date()).toISOString()
});

storeMock.push({
  ...createChatMessageMock(),
  text: 'Короткое сообщение, отправленное вчера.',
  createdAt: startOfYesterday().toISOString()
});

storeMock.push({
  ...createChatMessageMock(),
  text: 'Короткое сообщение, отправленное 6 минут назад.',
  createdAt: subMinutes(new Date(), 6).toISOString()
});

storeMock.push({
  ...createChatMessageMock(),
  text: 'Короткое сообщение, отправленное 5 минут назад.',
  createdAt: subMinutes(new Date(), 5).toISOString()
});

storeMock.push({
  ...createChatMessageMock(),
  text: 'Короткое сообщение, отправленное 4 минут назад.',
  createdAt: subMinutes(new Date(), 4).toISOString()
});

storeMock.push({
  ...createChatMessageMock(),
  text: 'Короткое сообщение, отправленное 2 минут назад.',
  createdAt: subMinutes(new Date(), 2).toISOString()
});

// Методы взаимодействия с базой данных:
const getMessagesByPage = (page: number): ChatMessage[] => {
  const startIndex = (page - 1) * CHAT_MESSAGES_PAGE_LENGTH;
  const endIndex = startIndex + CHAT_MESSAGES_PAGE_LENGTH;

  return storeMock.slice(startIndex, endIndex);
};

const getMessageById = (id: number): null | ChatMessage => {
  const message = storeMock.find(message => message.id === id);

  return message || null;
};

const getAllMessages = (): ChatMessage[] => [...storeMock];

const addMessage = (message: ChatMessage) => {
  storeMock.push(message);
};

const replaceMessageById = (id: number, nextMessage: ChatMessage) => {
  storeMock = storeMock.map(message => {
    if (message.id === id) {
      return nextMessage;
    }

    return message;
  });
};

export const chatMessagesStoreMock = {
  getMessagesByPage,
  getAllMessages,
  getMessageById,
  addMessage,
  replaceMessageById
};
