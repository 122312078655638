import { SheduleEditorState } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { createSheduleEditorState } from 'utils/createSheduleEditorState';

export const sheduleEditorStateReducer = (
  state: SheduleEditorState,
  event: AppEvent
): SheduleEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return createSheduleEditorState();
    }
    case 'SheduleInfoService': {
      if (event.status === 'RESOLVED') {
        return {
          ...state,
          formValues: {
            huntingShedule: event.payload.hunting,
            restaurantShedule: event.payload.restaurant
          }
        };
      }

      return state;
    }
    case 'SheduduleEditorForm__HuntingChange':
    case 'SheduduleEditorForm__RestaurantChange': {
      let nextFormValues = {
        ...state.formValues
      };

      if (event.name === 'SheduduleEditorForm__RestaurantChange') {
        nextFormValues = {
          ...nextFormValues,
          restaurantShedule: event.value
        };
      }

      if (event.name === 'SheduduleEditorForm__HuntingChange') {
        nextFormValues = {
          ...nextFormValues,
          huntingShedule: event.value
        };
      }

      return {
        ...createSheduleEditorState(),
        formValues: nextFormValues
      };
    }

    case 'SheduduleEditorForm__Cancel': {
      return {
        ...state,
        formError: undefined,
        formValues: {
          huntingShedule: event.oldValue.hunting,
          restaurantShedule: event.oldValue.restaurant
        }
      };
    }

    case 'SheduduleEditorForm__Submit': {
      return {
        ...state,
        formError: undefined,
        submited: true
      };
    }

    case 'UpdateSheduleInfoService': {
      if (event.status === 'RESOLVED') {
        return {
          ...state,
          formError: undefined,
          submited: false,
          submitDidSucceed: true
        };
      }

      if (event.status === 'REJECTED') {
        return {
          ...state,
          formError: 'SERVICE_FAILED',
          submited: false
        };
      }

      return {
        ...state,
        formError: undefined,
        submitDidSucceed: false
      };
    }
    default:
      return state;
  }
};
