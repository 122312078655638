import React from 'react';

import './clickControlStyles.css';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'button';
  onClick?(): void;
};

export const ClickControlComponent: React.FC<Props> = props => {
  const { children, type = 'button', disabled, onClick } = props;

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const className = createBEMConstructor('clickControl')({ type, disabled });

  return (
    <button
      type={type}
      disabled={disabled}
      className={className}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
