import React from 'react';
import { format } from 'date-fns';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useServices } from 'hooks/useServices';
import { useAsyncController } from '../useAsyncController';
import { BookingTimeWorkFormValues, TimeLineDay } from 'types/state';
import { DATE_FNS__24H_TIME_FORMAT } from 'utils/constants';
import { TimeConflictError } from 'utils/errors';
import { createDaysOfWeekList } from 'utils/dayOfWeekUtils';
import { TSubmitTimeLineParams } from 'types/services';

export const useBookingTimeWorkEditorService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const { wrapAsync, asyncProcess } = useAsyncController();

  const submitWorkingHours = React.useCallback(
    (params: TSubmitTimeLineParams) => {
      const promise = services
        .submitTimeline(params)
        .then(() => {
          appDispatch({
            name: 'BOOKING_TIME_WORK_FORM_SUBMITTED'
          });
        })
        .catch(error => {
          if (error instanceof TimeConflictError) {
            return error;
          }

          throw error;
        });

      wrapAsync(promise);
    },
    [appDispatch, services, wrapAsync]
  );

  const submitWeeklyWorkingHours = React.useCallback(
    (formValues: BookingTimeWorkFormValues) => {
      const days: TimeLineDay[] = [];

      for (let [day, period] of Array.from(
        formValues.periodsPerDayOfWeek.entries()
      )) {
        days.push({
          day,
          startAt: format(
            new Date(period.startDateISO),
            DATE_FNS__24H_TIME_FORMAT
          ),
          endAt: format(new Date(period.endDateISO), DATE_FNS__24H_TIME_FORMAT)
        });
      }

      submitWorkingHours({
        location: formValues.location,
        forAllWeek: true,
        days
      });
    },
    [submitWorkingHours]
  );

  const submitDailyWorkingHours = React.useCallback(
    (formValues: BookingTimeWorkFormValues) => {
      const days: TimeLineDay[] = [];

      const daysOfWeekList = createDaysOfWeekList();

      for (const dayOfWeek of daysOfWeekList) {
        days.push({
          day: dayOfWeek,
          startAt: format(
            new Date(formValues.dailyPreiod.startDateISO),
            DATE_FNS__24H_TIME_FORMAT
          ),
          endAt: format(
            new Date(formValues.dailyPreiod.endDateISO),
            DATE_FNS__24H_TIME_FORMAT
          )
        });
      }

      submitWorkingHours({
        location: formValues.location,
        forAllWeek: false,
        days
      });
    },
    [submitWorkingHours]
  );

  return { asyncProcess, submitDailyWorkingHours, submitWeeklyWorkingHours };
};
