import { TIntl } from 'types/intl';
import { TReview } from 'types/state';

export const createReviewTitle = (intl: TIntl, review: TReview) => {
  if (review.type === 'RESTAURANT') {
    return `${intl.reviewPreviewTitlePrefix} №${review.orderNumber}`;
  }

  return `${intl.reviewPreviewTitlePrefix} №${review.number} Mob`;
};
