import React from 'react';

import styles from './CompetitionParticipantLog.module.css';

import { ReactComponent as CloseIcon } from './close-button-icon.svg';

type TProps = {
  children: React.ReactNode;
  onClose(): void;
};

export const CompetitionParticipantLogContent = (props: TProps) => {
  const { children, onClose } = props;
  return (
    <div className={styles.CompetitionParticipantLog__Window}>
      <button
        className={styles.CompetitionParticipantLog__CloseButton}
        onClick={onClose}
      >
        <CloseIcon className={styles.CompetitionParticipantLog__CloseIcon} />
      </button>
      <div className={styles.CompetitionParticipantLog__Body}>{children}</div>
    </div>
  );
};
