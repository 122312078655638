import { TSetReviewNoteParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const setReviewNote = async (
  params: TSetReviewNoteParams
): Promise<void> => {
  const { reviewId, note } = params;

  await sportingAPI.service.setCommentNote({
    payload: { idComment: reviewId, note }
  });
};
