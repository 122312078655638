import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';

import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import {
  TSportingCommentStatusId,
  SportingSecureParams
} from 'services/sporting/types';
import { appendFormDataWithObjectValues } from 'utils/appendFormDataWithObjectValues';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  idComment: number;
  idStatus: TSportingCommentStatusId;
};

export const setCommentStatus = async (
  params: SportingSecureParams<Payload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL('setCommentStatus');

  const headers = createSportingHeaders();

  const body = new FormData();
  appendFormDataWithObjectValues(body, payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    headers,
    body
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
