import { TSubmitTimeLineParams } from '../../../types/services';
import { sportingAPI } from '../api';
import { normalizeServiceScope } from '../utils/normalizeServiceScope';

export const submitTimeline = async (params: TSubmitTimeLineParams) => {
  const { location, forAllWeek, days } = params;

  await sportingAPI.booking.submitTimeline({
    payload: {
      location: normalizeServiceScope(location).toSportingServiceScope(),
      forAllWeek,
      days: days.map(day => ({
        ...day,
        endAt: day.endAt === '00:00' ? '23:59' : day.endAt // Костыль для того, чтобы избежать ошибки KLUB-373 30 пункт
      }))
    }
  });
};
