import React from 'react';

import { useNewSportApplicationsCount } from 'hooks/useNewSportApplicationsCount';
import { useNewRestaurantApplicationsCount } from 'hooks/useNewRestaurantApplicationsCount';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ServiceScope } from 'types/serviceScope';

export const useNewApplciationsCountWatcher = (domain: ServiceScope) => {
  const dispatch = useAppDispatch();

  const sportCount = useNewSportApplicationsCount();

  const restaurantCount = useNewRestaurantApplicationsCount();

  React.useEffect(() => {
    if (domain === 'RESTAURANT' && restaurantCount !== null) {
      dispatch({
        name: 'APPLICATIONS_PAGE__NEW_APPLICATIONS_COUNT_CHANGED'
      });
    }
  }, [dispatch, domain, restaurantCount]);

  React.useEffect(() => {
    if (domain === 'CLUB' && sportCount !== null) {
      dispatch({
        name: 'APPLICATIONS_PAGE__NEW_APPLICATIONS_COUNT_CHANGED'
      });
    }
  }, [dispatch, domain, restaurantCount, sportCount]);
};
