import { TUpdateCurrentUserLogin } from 'types/services';
import { sportingAPI } from '../api';

export const updateCurrentUserLogin = async (
  params: TUpdateCurrentUserLogin
): Promise<void> => {
  await sportingAPI.service.changeLoginForCurrentUser({
    payload: { newLogin: params.login, password: params.password }
  });
};
