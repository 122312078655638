import React from 'react';

import styles from './EmployeeGroup.module.css';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { EmployeeItem } from '../EmployeeItem/EmployeeItem';
import { UserInfo, UserRole } from 'types/state';
import { CreateButton } from 'components/shared/CreateButton';
import { useIntlContext } from 'hooks/useIntlContext';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { moveEmployeeThunk } from 'thunks/moveEmployeeThunk';
import { useServices } from 'hooks/useServices';

type Props = {
  role: UserRole;
  users: UserInfo[];
  isEditable: boolean;
  onCreate(): void;
  onChangePhoneNumber(userId: number, phoneNumber: string): void;
  onEditItem(user: UserInfo): void;
  onBlockItem(user: UserInfo): void;
  onDeleteItem(userId: number): void;
};

// Группа сотрудников (например Админы, Менеджеры и т.д.)
export const EmployeeGroup = (props: Props) => {
  const { roleIntl } = useIntlContext();
  const role = props.role;

  const [users, setUsers] = React.useState<UserInfo[]>([]);

  const localUsersUpdateBlockedRef = React.useRef(false);

  React.useEffect(() => {
    if (!localUsersUpdateBlockedRef.current) {
      setUsers(props.users);
    }
  }, [props.users]);

  const reorderLocalList = React.useCallback(
    (startIndex: number, endIndex: number) => {
      setUsers(prevValue => {
        const nextValue = [...prevValue];
        const [removed] = nextValue.splice(startIndex, 1);
        nextValue.splice(endIndex, 0, removed);
        return nextValue;
      });
    },
    []
  );

  const dispatch = useAppDispatch();
  const { moveEmployee } = useServices();
  const wrapAsyncSafely = useSafeAsyncWrapper();

  const reorderPersistedList = React.useCallback(
    (draggableId: string, targetIdx: number) => {
      const targetUser = users[targetIdx];

      const params = {
        userId: Number(draggableId),
        refUserId: targetUser.id
      };

      const promise = moveEmployeeThunk(moveEmployee(params), params)(dispatch);

      wrapAsyncSafely(promise);

      promise.finally(() => (localUsersUpdateBlockedRef.current = false));
    },
    [users, dispatch, moveEmployee, wrapAsyncSafely]
  );

  const handleDragEnd = React.useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const startIndex = result.source.index;
      const endIndex = result.destination.index;

      reorderLocalList(startIndex, endIndex);
      reorderPersistedList(result.draggableId, endIndex);

      localUsersUpdateBlockedRef.current = true;
    },
    [reorderLocalList, reorderPersistedList]
  );

  const memoizedUsersListItems = React.useMemo(
    () =>
      users.map((user, index) => (
        <EmployeeItem
          key={user.id}
          user={user}
          index={index}
          isEditable={props.isEditable}
          onChangePhoneNumber={(phoneNumber: string) =>
            props.onChangePhoneNumber(user.id, phoneNumber)
          }
          onEdit={() => props.onEditItem(user)}
          onBlock={() => props.onBlockItem(user)}
          onDelete={() => props.onDeleteItem(user.id)}
        />
      )),
    [users, props]
  );

  return users.length > 0 || props.isEditable ? (
    <div className={styles.EmployeeGroup}>
      <div className={styles.EmployeeGroup__Header}>
        <div className={styles.EmployeeGroup__Title}>
          {roleIntl.toLabel(role)}
        </div>
        {props.isEditable && (
          <CreateButton
            className={styles.EmployeeGroup__AddBtn}
            useDiv={false}
            onClick={props.onCreate}
          />
        )}
      </div>
      <div className={styles.EmployeeGroup__Body}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {memoizedUsersListItems}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  ) : (
    <></>
  );
};
