import React from 'react';

import styles from './clientSearchResultsGroupLayoutStyles.module.scss';

type Props = {
  labelText: React.ReactNode;
  items: React.ReactNode;
};

export const ClientSearchResultsGroupLayoutComponent = (props: Props) => {
  const { labelText, items } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.labelTextSlot}>{labelText}</div>
      <div className={styles.itemsSlot}>{items}</div>
    </div>
  );
};
