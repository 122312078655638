import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';
import React from 'react';
import { TCreateReservationParams } from '../../types/services';

export const useBookingReservationCreateService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const { wrapAsync: wrap, asyncProcess } = useAsyncController();

  const createReservation = React.useCallback(
    (params: TCreateReservationParams) => {
      const promise = services.createReservation(params).then(() => {
        appDispatch({
          name: 'BOOKING_RESERVATION_CREATED'
        });
      });

      wrap(promise);
    },
    [appDispatch, services, wrap]
  );

  return { createReservation, asyncProcess };
};
