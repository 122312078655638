import { MESSAGE_TEMPLATES_PAGE_LENGTH } from 'utils/constants';
import { MessageTemplate } from 'types/messageTemplate';
import { getUid } from 'utils/getUid';

// В этом файле содержится in-memory база данных,
// которая используется mock-сервисами для имитации
// взаимодействия с сервером при dev-разработке.

// База данных.
let storeMock: MessageTemplate[] = [];

// Методы взаимодействия с базой данных:

const recalcPositions = () => {
  storeMock = storeMock.map((item, position) => {
    return {
      ...item,
      position
    };
  });
};

const getTemplatesByPage = (page: number): MessageTemplate[] => {
  const startIndex = (page - 1) * MESSAGE_TEMPLATES_PAGE_LENGTH;
  const endIndex = startIndex + MESSAGE_TEMPLATES_PAGE_LENGTH;

  return storeMock.slice(startIndex, endIndex);
};

const getTemplateById = (id: number): null | MessageTemplate => {
  const message = storeMock.find(message => message.id === id);

  return message || null;
};

const getAllTemplates = (): MessageTemplate[] => [...storeMock];

const addTemplate = (message: MessageTemplate) => {
  storeMock.unshift(message);

  storeMock = [...storeMock];

  recalcPositions();
};

const replaceTemplate = (id: number, template: MessageTemplate) => {
  storeMock = storeMock.map(storeTemplate => {
    if (storeTemplate.id === id) {
      return template;
    }

    return storeTemplate;
  });
};

const deleteTemplateById = (id: number) => {
  storeMock = storeMock.filter(item => item.id !== id);
};

export const messageTemplateStoreMock = {
  addTemplate,
  replaceTemplate,
  getAllTemplates,
  getTemplateById,
  getTemplatesByPage,
  deleteTemplateById
};

addTemplate({
  id: getUid(),
  text: 'Добрый день',
  position: 0
});

addTemplate({
  id: getUid(),
  text: 'Чем могу помочь?',
  position: 0
});

addTemplate({
  id: getUid(),
  text: 'Я могу вам перезвонить?',
  position: 0
});

for (let i = 0; i < 100; i += 1) {
  addTemplate({
    id: getUid(),
    text: `Шаблон ответа ${i}`,
    position: 0
  });
}
