import { UserEditorState } from 'types/state';
import { createUserEditorState } from 'utils/createUserEditorState';

export const getUserEditorStateByUserId = (
  userEditorStatesByUserId: Map<number, UserEditorState>,
  userId: number
): void | UserEditorState => {
  return userEditorStatesByUserId.get(userId);
};

export const getUserEditorStateByUserIdStrict = (
  userEditorStatesByUserId: Map<number, UserEditorState>,
  userId: number
): UserEditorState => {
  const userEditorState = getUserEditorStateByUserId(
    userEditorStatesByUserId,
    userId
  );

  if (!userEditorState) {
    throw new TypeError();
  }

  return userEditorState;
};

export const getUserEditorStateByUserIdOrFallback = (
  userEditorStatesByUserId: Map<number, UserEditorState>,
  userId: number
): UserEditorState => {
  const userEditorState = getUserEditorStateByUserId(
    userEditorStatesByUserId,
    userId
  );

  return userEditorState || createUserEditorState();
};
