import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineTrackLabelLayout.scss';

type Props = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
};

const bemConstructor = createBEMConstructor('bookingTimelineTrackLabelLayout');

export const BookingTimelineTrackLabelLayout: React.FC<Props> = props => {
  const { title, subtitle } = props;

  const wrapperClassName = bemConstructor('wrapper');

  const titleSlotClassName = bemConstructor('titleSlot');

  const subtitleSlotClassName = bemConstructor('subtitleSlot');

  return (
    <div className={wrapperClassName}>
      <div className={titleSlotClassName}>{title}</div>
      <div className={subtitleSlotClassName}>{subtitle}</div>
    </div>
  );
};
