import { useIntlContext } from 'hooks/useIntlContext';
import { usePasswordFormError } from 'hooks/usePasswordFormError';

export const usePasswordFormErrorText = (): string | undefined => {
  const formError = usePasswordFormError();

  const { passwordForm, unexpectedError } = useIntlContext();

  switch (formError) {
    case 'PASSWORDS_DID_NOT_MATCH':
      return passwordForm.passwordsDidNotMatchError;
    case 'SERVICE_FAILED':
      return unexpectedError;
    default:
      return undefined;
  }
};
