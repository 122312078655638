import { Toggler } from 'types/util';

export const createToggler = (): Toggler => {
  let enabled = false;

  const enable = () => {
    enabled = true;
  };

  const disable = () => {
    enabled = false;
  };

  const isEnabled = () => {
    return enabled;
  };

  return { enable, disable, isEnabled };
};
