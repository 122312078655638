import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useUserInfoCache } from 'hooks/useUserInfoCache';
import { useSession } from 'hooks/useSession';
import { useAsyncController } from './useAsyncController';
import { UserInfo } from 'types/state';

export const useUserInfoService = () => {
  const { didInvalidate } = useUserInfoCache();

  const dispatch = useAppDispatch();

  const { getUserInfo } = useServices();

  const { asyncProcess, wrapAsync } = useAsyncController<UserInfo>();

  const session = useSession();

  React.useEffect(() => {
    if (didInvalidate && session?.type === 'AUTHORIZED') {
      const promise = getUserInfo();

      wrapAsync(promise);
    }
  }, [didInvalidate, getUserInfo, session, wrapAsync]);

  React.useEffect(() => {
    if (asyncProcess.status === 'PENDING') {
      dispatch({
        name: 'USER_INFO_SERVICE',
        status: 'PENDING'
      });
    }
    if (asyncProcess.status === 'RESOLVED') {
      dispatch({
        name: 'USER_INFO_SERVICE',
        status: 'RESOLVED',
        payload: asyncProcess.value
      });
    }
  }, [asyncProcess, dispatch]);
};
