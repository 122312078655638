import React from 'react';

import { CompetitionParticipantsWrapper } from 'components/CompetitionParticipants/CompetitionParticipantsWrapper';
import { CompetitionParticipantsTable } from 'components/CompetitionParticipants/CompetitionParticipantsTable';
import { CompetitionParticipantsHeader } from 'components/CompetitionParticipants/CompetitionParticipantsHeader';
import { CompetitionParticipantsHeaderCell } from 'components/CompetitionParticipants/CompetitionParticipantsHeaderCell';
import { CompetitionParticipantsBody } from 'components/CompetitionParticipants/CompetitionParticipantsBody';
import { CompetitionsPreviewPreloader } from 'components/CompetitionsPreview/CompetitionsPreviewPreloader';
import { CompetitionParticipant } from 'containers/CompetitionParticipant';
import {
  TCompetitionParticipant,
  TCompetitionParticipantStatus
} from 'types/state';

type Props = {
  data: null | TCompetitionParticipant[];
  competitionId: number;
  hasPreloader: boolean;
  phoneColumnLabel: string;
  nameColumnLabel: string;
  statusColumnLabel: string;
  handleParticipantStatusChange(
    id: number,
    nextStatus: TCompetitionParticipantStatus
  ): void;
};

export const CompetitionParticipantsView = (props: Props) => {
  const {
    data,
    competitionId,
    hasPreloader,
    phoneColumnLabel,
    nameColumnLabel,
    statusColumnLabel,
    handleParticipantStatusChange
  } = props;

  const renderedRows = React.useMemo(() => {
    if (data) {
      return data.map(item => (
        <CompetitionParticipant
          key={item.id}
          data={item}
          competitionId={competitionId}
          onChangeStatus={handleParticipantStatusChange}
        />
      ));
    } else {
      return null;
    }
  }, [data, competitionId, handleParticipantStatusChange]);

  if (hasPreloader) {
    return <CompetitionsPreviewPreloader />;
  }

  return (
    <CompetitionParticipantsWrapper>
      <CompetitionParticipantsTable>
        <CompetitionParticipantsHeader>
          <CompetitionParticipantsHeaderCell label={phoneColumnLabel} />
          <CompetitionParticipantsHeaderCell label={nameColumnLabel} />
          <CompetitionParticipantsHeaderCell label={statusColumnLabel} />
        </CompetitionParticipantsHeader>
        <CompetitionParticipantsBody>
          {renderedRows}
        </CompetitionParticipantsBody>
      </CompetitionParticipantsTable>
    </CompetitionParticipantsWrapper>
  );
};
