import { AppEvent } from 'types/appEvent';
import { EditChatMessageForm } from 'types/chats';
import {
  checkMessageIsAllowedToEdit,
  selectClosestMessageToEdit
} from 'utils/chatUtils';
import { AppState } from 'types/appState';
import { createCollectionCacheSelectors } from 'utils/collectionCacheUtils';
import { createListCacheSelectors } from 'utils/listCacheUtils';

export const editChatMessageFormReducer = (
  state: null | EditChatMessageForm,
  event: AppEvent,
  appState: AppState
): null | EditChatMessageForm => {
  switch (event.name) {
    case 'CHATS_PAGE__CONTENT_DID_MOUNT':
    case 'CHATS_PAGE__ESCAPE_CLICKED':
      return null;
    case 'CHATS_PAGE__ARROW_UP_CLICKED_ON_EMPTY_INPUT': {
      const {
        activeChatRoom: { messageIdsList },
        chatMessagesCollection
      } = appState.chatsState;

      const ids = createListCacheSelectors(messageIdsList).selectAllItems();

      const messages = createCollectionCacheSelectors(
        chatMessagesCollection
      ).selectItemsStrict(ids);

      const messageToEdit = selectClosestMessageToEdit(messages);

      if (messageToEdit) {
        return {
          messageId: messageToEdit.id,
          text: messageToEdit.text,
          status: 'IDLE'
        };
      }
      return null;
    }
    case 'CHATS_PAGE__MESSAGE_CLICK': {
      const allowedToEdit = checkMessageIsAllowedToEdit(event.message);

      if (allowedToEdit) {
        return {
          messageId: event.message.id,
          text: event.message.text,
          status: 'IDLE'
        };
      }

      return state;
    }
    case 'CHATS_PAGE__MESSAGE_EDITOR_SUBMIT':
    case 'CHATS_PAGE__MESSAGE_EDITOR_BLUR': {
      if (state && state.messageId === event.message.id) {
        const actualMessage = createCollectionCacheSelectors(
          appState.chatsState.chatMessagesCollection
        ).selectItem(state.messageId);

        if (actualMessage && actualMessage.text !== state.text) {
          return {
            ...state,
            status: 'SUBMITTED'
          };
        }

        return null;
      }

      return state;
    }
    case 'CHATS_PAGE__EDITING_MESSAGE_CHANGE': {
      if (state) {
        return {
          ...state,
          text: event.nextText
        };
      }

      return state;
    }
    case 'CHAT_MESSAGE_EDIT_SERVICE': {
      if (event.status === 'RESOLVED') {
        return null;
      }

      return state;
    }
    default:
      return state;
  }
};
