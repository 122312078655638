import React from 'react';

import styles from './ReviewPreview.module.css';

type TProps = {
  children: React.ReactNode;
};

export const ReviewPreviewInfoTable = ({ children }: TProps) => (
  <div className={styles.ReviewPreview__InfoTable}>{children}</div>
);
