import React from 'react';

import styles from './BookingPlacesSelect.module.scss';
import { Popup } from '../shared/Popup';
import { Place, PlaceItem } from '../../types/state';
import { PlaceOption } from './components/PlaceOption/PlaceOption';
import { multiplyClassName } from '../../utils/className';
import { ArrowDownIcon } from '../../svg/16x16/arrow-down';
import { PopupGroupLayoutComponent } from 'design/popupGroupLayout/popupGroupLayoutComponents';

type Props = {
  places: Place[];
  value: PlaceItem[];
  onChange(selectedPlaceItems: PlaceItem[]): void;
  isItemDisabled(item: PlaceItem): boolean;
};

export const BookingPlacesSelect = (props: Props) => {
  const { places, value, onChange, isItemDisabled } = props;

  const [isOpenedPopup, setOpenedPopup] = React.useState<boolean>(false);

  const isPlaceSelected = React.useCallback(
    (place: Place) => {
      return (
        place.items.length > 0 &&
        place.items
          .filter(item => {
            return !isItemDisabled(item);
          })
          .every(item => value.includes(item))
      );
    },
    [isItemDisabled, value]
  );

  const isItemSelected = React.useCallback(
    (placeItem: PlaceItem) => {
      return value.some(item => item.id === placeItem.id);
    },
    [value]
  );

  const addPlaceItem = React.useCallback(
    (placeItem: PlaceItem) => {
      if (!isItemSelected(placeItem)) {
        onChange([...value, placeItem]);
      }
    },
    [isItemSelected, onChange, value]
  );

  const removePlaceItem = React.useCallback(
    (placeItem: PlaceItem) => {
      onChange(value.filter(item => item.id !== placeItem.id));
    },
    [onChange, value]
  );

  const onPlaceSelect = React.useCallback(
    (place: Place) => {
      if (isPlaceSelected(place)) {
        onChange(value.filter(item => !place.items.includes(item)));
      } else {
        const items = place.items.filter(
          item => !value.includes(item) && !isItemDisabled(item)
        );
        onChange([...value, ...items]);
      }
    },
    [isPlaceSelected, onChange, value, isItemDisabled]
  );

  const onItemSelect = React.useCallback(
    (placeItem: PlaceItem) => {
      if (isItemSelected(placeItem)) {
        removePlaceItem(placeItem);
      } else {
        addPlaceItem(placeItem);
      }
    },
    [isItemSelected, addPlaceItem, removePlaceItem]
  );

  return (
    <PopupGroupLayoutComponent
      onOutsideClick={() => setOpenedPopup(false)}
      trigger={
        <div
          className={styles.input}
          onClick={() => setOpenedPopup(prevState => !prevState)}
        >
          <div>{value.map(item => item.title).join(', ')}</div>
          <ArrowDownIcon
            className={multiplyClassName([
              styles.arrow,
              isOpenedPopup ? styles.arrow_up : ''
            ])}
          />
        </div>
      }
      popup={
        isOpenedPopup && (
          <Popup className={styles.popup}>
            <div className={styles.popupContent}>
              {places.map(place => (
                <PlaceOption
                  key={place.id}
                  place={place}
                  onPlaceSelect={onPlaceSelect}
                  isPlaceSelected={isPlaceSelected}
                  onItemSelect={onItemSelect}
                  isItemSelected={isItemSelected}
                  isItemDisabled={isItemDisabled}
                />
              ))}
            </div>
          </Popup>
        )
      }
    />
  );
};
