import React from 'react';

import { useFaviconService } from 'hooks/useFaviconService';
import { useShouldUpdateFaviconFlag } from 'hooks/useShouldUpdateFaviconFlag';

export const useFaviconUpdater = () => {
  const shouldUpdateFavicon = useShouldUpdateFaviconFlag();

  const { startAttentionMode, stopAttentionMode } = useFaviconService();

  // Эффект переключает иконку таба браузера при
  // наличии или отсутствии бейджа хотя бы у одного
  // раздела админки.
  React.useEffect(() => {
    if (shouldUpdateFavicon) {
      startAttentionMode();
    } else {
      stopAttentionMode();
    }
  }, [shouldUpdateFavicon, startAttentionMode, stopAttentionMode]);
};
