import { Waiter } from '../../../types/state';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';

export const getWaiters = async (): Promise<Waiter[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return [
    {
      id: 1,
      fio: 'FIO1'
    },
    {
      id: 2,
      fio: 'FIO2'
    },
    {
      id: 3,
      fio: 'FIO3'
    }
  ];
};
