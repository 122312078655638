import React from 'react';

import styles from './CompetitionDurationInput.module.css';
import { CustomDatePicker } from 'components/CustomDatePicker';

type TProps = {
  label: string;
  value: string;
  isOpened: boolean;
  disableBefore?: string;
  disableAfter?: string;
  onChange(nextValue: string): void;
  onClick(): void;
};

export const CompetitionDurationInputPicker = ({
  value,
  label,
  isOpened,
  disableBefore,
  disableAfter,
  onChange,
  onClick
}: TProps) => {
  const handleDateChange = onChange;

  const handleTriggerClick = onClick;

  return (
    <div
      className={`${styles.CompetitionDurationInput__Picker} ${
        isOpened ? styles.CompetitionDurationInput__Picker_opened : ''
      }`}
    >
      <button
        className={styles.CompetitionDurationInput__Trigger}
        onClick={handleTriggerClick}
      >
        <span className={styles.CompetitionDurationInput__Label}>{label}</span>
      </button>
      {isOpened && (
        <CustomDatePicker
          value={value}
          className={styles.CompetitionDurationInput__Popup}
          onDateSelect={handleDateChange}
          disableBefore={disableBefore}
          disableAfter={disableAfter}
        />
      )}
    </div>
  );
};
