import React from 'react';

import './messageBox.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type MessageBoxTheme =
  | 'primary'
  | 'secondary'
  | 'input'
  | 'input-large'
  | 'editing'
  | 'deleted'
  | 'template'
  | 'template-input';

type Props = {
  theme: MessageBoxTheme;
  children: React.ReactNode;
};

export const MessageBox = (props: Props) => {
  const { children, theme } = props;

  const className = createBEMConstructor('message-box')({ theme });

  return <div className={className}>{children}</div>;
};
