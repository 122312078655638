import React from 'react';
import { TrashIcon } from 'svg/24x24/trash';
import { DNDIcon } from 'svg/24x24/dnd';
import {
  BOOKING_PLACE_ITEM_NAME_INPUT_MAX_LENGTH,
  BOOKING_PLACE_ITEM_VISITORS_COUNT_INPUT_MAX_LENGTH
} from 'utils/constants';
import { BookingPlaceItemLayout } from './BookingPlaceItemLayout';
import { Draggable } from 'react-beautiful-dnd';
import { TextInputControl } from 'design/textInputControl';
import { BookingPlaceItemFormValues } from 'types/state';
import { useIntlContext } from 'hooks/useIntlContext';
import { EmployeeItemBtn } from 'components/EmployeeItemBtn/EmployeeItemBtn';
import { useBookingPlacesEditorState } from 'hooks/booking/useBookingPlacesEditorState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useServices } from 'hooks/useServices';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

type Props = {
  placeId: string;
  placeItem: BookingPlaceItemFormValues;
  index: number;
  onChangeTitle(value: string): void;
  onChangeMinCount(value: string): void;
  onChangeMaxCount(value: string): void;
  onForbiddenDeleteAttempt(): void;
};

export const BookingPlaceItem = (props: Props) => {
  const {
    placeId,
    placeItem,
    index,
    onChangeTitle,
    onChangeMinCount,
    onChangeMaxCount,
    onForbiddenDeleteAttempt
  } = props;

  const { id: placeItemId } = placeItem;

  const { bookingPlaceItemIntl: msgs } = useIntlContext();

  const { formError } = useBookingPlacesEditorState();

  const dispatch = useAppDispatch();

  const services = useServices();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const onDelete = React.useCallback(() => {
    const promise = Promise.resolve(Number(placeItemId))
      .then(realPlaceItemId => {
        if (!Number.isNaN(realPlaceItemId)) {
          return services.doPlacesHaveForthcomingReservations([
            realPlaceItemId
          ]);
        }

        return false;
      })
      .then(placesHaveForthComingReservations => {
        if (!placesHaveForthComingReservations) {
          dispatch({
            name: 'BOOKING_PLACE_ITEM_DELETE',
            placeId,
            placeItemId: placeItemId
          });
        } else {
          onForbiddenDeleteAttempt();
        }
      });

    wrapAsyncSafely(promise);
  }, [
    dispatch,
    onForbiddenDeleteAttempt,
    placeId,
    placeItemId,
    services,
    wrapAsyncSafely
  ]);

  const violations = formError ? formError : [];

  const nameIsInvalid = violations.some(violation => {
    if (violation.name === 'PLACE_ITEM_NAME_REQUIRED') {
      return violation.itemId === placeItem.id;
    }

    return false;
  });

  const minCountIsInvalid = violations.some(violation => {
    if (violation.name === 'PLACE_ITEM_MIN_VISITORS_COUNT_REQUIRED') {
      return violation.itemId === placeItem.id;
    }

    return false;
  });

  const maxCountIsInvalid = violations.some(violation => {
    if (violation.name === 'PLACE_ITEM_MAX_VISITORS_COUNT_REQUIRED') {
      return violation.itemId === placeItem.id;
    }

    return false;
  });

  return (
    <Draggable draggableId={placeItem.id} index={index}>
      {provided => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <BookingPlaceItemLayout
            nameInput={
              <TextInputControl
                invalid={nameIsInvalid}
                placeholder={msgs.namePlaceholder}
                name={`placeItemName__${placeItem.id}`}
                value={placeItem.title}
                maxLength={BOOKING_PLACE_ITEM_NAME_INPUT_MAX_LENGTH}
                onValueChange={value => onChangeTitle(value)}
                autoFocus={
                  !placeItem.title && placeItem.dataAction === 'CREATE_NEW'
                }
              />
            }
            minCountInput={
              <TextInputControl
                placeholder={msgs.minCountGuestPlaceholder}
                invalid={minCountIsInvalid}
                name={`placeItemMinCount__${placeItem.id}`}
                value={placeItem.minVisitorsCount}
                maxLength={BOOKING_PLACE_ITEM_VISITORS_COUNT_INPUT_MAX_LENGTH}
                onValueChange={value => onChangeMinCount(value)}
              />
            }
            maxCountInput={
              <TextInputControl
                placeholder={msgs.maxCountGuestPlaceholder}
                invalid={maxCountIsInvalid}
                name={`placeItemMaxCount__${placeItem.id}`}
                value={placeItem.maxVisitorsCount}
                maxLength={BOOKING_PLACE_ITEM_VISITORS_COUNT_INPUT_MAX_LENGTH}
                onValueChange={value => onChangeMaxCount(value)}
              />
            }
            dndControl={
              <EmployeeItemBtn
                dragHandleProps={provided.dragHandleProps}
                icon={<DNDIcon />}
              />
            }
            deleteControl={
              <EmployeeItemBtn
                hoverAccent="critical"
                icon={<TrashIcon />}
                onClick={onDelete}
              />
            }
          />
        </div>
      )}
    </Draggable>
  );
};
