type TCalendarMonthInfo = {
  monthName: string;
  datesList: number[];
};

export const getEmptyDays = (firstDate: number) => {
  const dayOfTheWeek = new Date(firstDate).getDay();

  const emptyDatesCount = (() => {
    switch (dayOfTheWeek) {
      case 0: // Воскресенье
        return 6;
      default:
        return dayOfTheWeek - 1;
    }
  })();

  return Array.from(Array(emptyDatesCount).keys());
};

export const getMonthName = (date: Date, nominativeMonthNames: string[]) =>
  `${nominativeMonthNames[date.getMonth()]}'${date
    .getFullYear()
    .toString()
    .substring(2)}`;

export const getDatesList = (date: Date) => {
  // Массив хранит пустые и настоящие дни месяца
  // в порядке возрастания.
  const datesList = [];

  // Копия переданной даты устанавливается на первый
  // день месяца.
  const dateCopy = new Date(date);
  dateCopy.setDate(1);

  // Массив заполняется пустыми днями месяца.
  datesList.push(...getEmptyDays(dateCopy.getTime()));

  // Массив заполняется настоящними днями месяца.
  while (dateCopy.getMonth() === date.getMonth()) {
    datesList.push(dateCopy.getTime());
    dateCopy.setDate(dateCopy.getDate() + 1);
  }

  return datesList;
};

export const getCalendarMonthInfo = (
  date: Date,
  nominativeMonthNames: string[]
): TCalendarMonthInfo => {
  const monthName = getMonthName(date, nominativeMonthNames);
  const datesList = getDatesList(date);
  return {
    monthName,
    datesList
  };
};
