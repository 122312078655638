import React from 'react';

import { authorizedRoutes } from 'utils/authorizedRoutes';
import { useIntlContext } from 'hooks/useIntlContext';
import { useRouteMatchStrict } from 'hooks/useRouteMatchStrict';

export const useBrowserTabTitleUpdater = () => {
  const intl = useIntlContext();

  const { path } = useRouteMatchStrict();

  const actualTitle = React.useMemo(() => {
    if (path === authorizedRoutes.applicationsRestaurantPattern()) {
      return intl.navigation.applocationsRestarauntLabel;
    }
    if (path === authorizedRoutes.applicationsSportPattern()) {
      return intl.navigation.applicationsSportLabel;
    }
    if (path === authorizedRoutes.bookingRestaurant()) {
      return intl.navigation.bookingRestaurantLabel;
    }
    if (path === authorizedRoutes.bookingClub()) {
      return intl.navigation.bookingClubLabel;
    }
    if (path === authorizedRoutes.newsPattern()) {
      return intl.navigation.newsLabel;
    }
    if (path === authorizedRoutes.competitionsPattern()) {
      return intl.navigation.competitionsLabel;
    }
    if (path === authorizedRoutes.feedbackPattern()) {
      return intl.navigation.feedbackLabel;
    }
    if (path === authorizedRoutes.membersPattern()) {
      return intl.navigation.membersLabel;
    }
    if (path === authorizedRoutes.usersPattern()) {
      return intl.navigation.usersLabel;
    }
    if (path === authorizedRoutes.settingsPattern()) {
      return intl.navigation.settingsLabel;
    }
    return path;
  }, [path, intl]);

  React.useLayoutEffect(() => {
    document.title = actualTitle;
  }, [actualTitle]);
};
