import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TGetRestaurantApplicationsParams } from 'types/services';
import { TApplication } from 'types/state';
import { restaurantApplicationsStoreMock } from 'services/mock/utils/restaurantApplicationsModel';

export const GET_RESTAURANT_APPLICATIONS_MOCK_PAGE_NUMBER__CONNECTION_ERROR = -1;

export const getRestaurantProcessedApplications = async (
  params: TGetRestaurantApplicationsParams
): Promise<TApplication[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (
    params.pageNumber ===
    GET_RESTAURANT_APPLICATIONS_MOCK_PAGE_NUMBER__CONNECTION_ERROR
  ) {
    throw new HTTPConnectionError();
  }
  return restaurantApplicationsStoreMock.getProcessedApplications(
    params.pageNumber
  );
};
