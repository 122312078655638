import { TGetSportApplicationsParams } from 'types/services';
import { TApplication } from 'types/state';
import { sportingAPI } from 'services/sporting/api';
import { normalizeSportingClaim } from 'services/sporting/utils/normalizeSportingClaim';

export const getSportProcessedApplications = async (
  params: TGetSportApplicationsParams
): Promise<TApplication[]> => {
  const { pageNumber } = params;

  const claims = await sportingAPI.service.getProcessedShootingClaims({
    payload: { pageNum: pageNumber }
  });

  return claims.map(claim =>
    normalizeSportingClaim.toApplication(claim, 'SPORT_PROCESSED')
  );
};
