import React from 'react';

import styles from './Theme.module.scss';

type TProps = {
  children: React.ReactNode;
};

export const Theme = (props: TProps) => (
  <div {...props} className={styles.CSSThemeProvider} />
);
