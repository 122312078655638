import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';

export const doPlacesHaveForthcomingReservations = async (
  placeIds: number[]
): Promise<boolean> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return false;
};
