import React from 'react';

import styles from './SettingsPage.module.css';

type Props = {
  children: React.ReactNode;
};

export const SettingsPageWrapper: React.FC<Props> = ({ children }) => {
  return <div className={styles.SettingsPage__Wrapper}>{children}</div>;
};
