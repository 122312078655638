import { AppEvent } from 'types/appEvent';

export const onlineFlagReducer = (state: boolean, event: AppEvent): boolean => {
  switch (event.name) {
    case 'WINDOW__ONLINE': {
      return true;
    }
    case 'WINDOW__OFFLINE': {
      return false;
    }
    default:
      return state;
  }
};
