import React, { CSSProperties } from 'react';

type ScrollDimensions = {
  top: number;
  bottom: number;
};

type ScrollDirection = 'up' | 'down';

export type ScrollInfo = {
  direction: ScrollDirection;
  dimensions: ScrollDimensions;
};

type Props = {
  children: React.ReactNode;
  onScroll(scrollInfo: ScrollInfo): void;
};

const style: CSSProperties = {
  overflow: 'auto'
};

const createScrollTop = (element: Element) => {
  return element.scrollTop;
};

const createScrollBottom = (element: Element) => {
  return element.scrollHeight - (element.scrollTop + element.clientHeight);
};

const createScrollDimensions = (element: Element) => {
  const top = createScrollTop(element);

  const bottom = createScrollBottom(element);

  return { top, bottom };
};

const createScrollInfo = (
  element: Element,
  prevDimensions: ScrollDimensions
): ScrollInfo => {
  const dimensions = createScrollDimensions(element);

  const direction = dimensions.top < prevDimensions.top ? 'up' : 'down';

  return {
    direction,
    dimensions
  };
};

export const ScrollControl = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const prevDimensionsRef = React.useRef<null | ScrollDimensions>(null);

    const { children, onScroll } = props;

    const handleScroll = React.useCallback(
      (event: React.SyntheticEvent) => {
        const { target } = event;

        const { current: prevDimensions } = prevDimensionsRef;

        if (target instanceof Element && prevDimensions) {
          const scrollInfo = createScrollInfo(target, prevDimensions);

          onScroll(scrollInfo);

          prevDimensionsRef.current = scrollInfo.dimensions;
        }
      },

      [onScroll]
    );

    React.useLayoutEffect(() => {
      if (ref !== null && typeof ref !== 'function') {
        const { current: scrollableNode } = ref;

        if (scrollableNode) {
          prevDimensionsRef.current = createScrollDimensions(scrollableNode);
        }
      }
    }, [ref]);

    return (
      <div ref={ref} style={style} onScroll={handleScroll}>
        {children}
      </div>
    );
  }
);
