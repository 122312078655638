import React from 'react';

import styles from './section.module.css';

import { createClassNameConstructor } from 'utils/craeteClassNameConstructor';

type Props = {
  name?: 'primary' | 'secondary';
  children: React.ReactNode;
};

export const Section = (props: Props) => {
  const { children, name = 'primary' } = props;

  const className = createClassNameConstructor(styles.Section)
    .withConstructor(constructor => {
      switch (name) {
        case 'primary':
          return constructor.addClass(styles.Section_name_primary);
        case 'secondary':
          return constructor.addClass(styles.Section_name_secondary);
        default:
          return constructor;
      }
    })
    .return();

  return <div className={className}>{children}</div>;
};
