import React from 'react';
import { multiplyClassName } from 'utils/className';

import styles from './inputFieldLayoutStyles.module.scss';

type Props = {
  theme?: 'labelOnTheTop' | 'labelOnTheRight';
  label: React.ReactNode;
  input: React.ReactNode;
};

export const InputFieldLayoutComponent = (props: Props) => {
  const { label, input, theme = 'labelOnTheTop' } = props;

  return (
    <div
      className={multiplyClassName([
        styles.wrapper,
        styles[`wrapper__theme_${theme}`]
      ])}
    >
      <div className={styles.labelSlot}>{label}</div>
      <div className={styles.inputSlot}>{input}</div>
    </div>
  );
};
