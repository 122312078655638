import React from 'react';

import styles from './Bage.module.css';

type TProps = {
  count: number;
  className?: string;
  testId?: string;
};

export const Bage = (props: TProps) => {
  const { count, className = '', testId } = props;

  return (
    <div
      className={`${styles.Bage__Wrapper} ${className}`}
      data-testid={testId}
    >
      {count}
    </div>
  );
};
