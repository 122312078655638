import React from 'react';

import styles from './ReviewItem.module.css';

type TProps = {
  text: string;
};

export const ReviewItemStatus = ({ text }: TProps) => (
  <div className={styles.ReviewItem__Status}>{text}</div>
);
