import {
  ChatsState,
  ChatMessageForm,
  UnreadChatIdLists,
  ActiveChatRoom
} from 'types/chats';
import { createCollectionCache } from 'utils/collectionCacheUtils';
import { createListCache, modifyListCache } from 'utils/listCacheUtils';

export const createUnreadChatIdLists = (): UnreadChatIdLists => ({
  sportIds: modifyListCache(createListCache<number>())
    .invalidatePage(1)
    .return(),
  restaurantIds: modifyListCache(createListCache<number>())
    .invalidatePage(1)
    .return()
});

export const createChatsMessageForm = (): ChatMessageForm => ({
  submittedValue: '',
  value: '',
  status: 'IDLE'
});

export const createActiveChatRoom = (): ActiveChatRoom => ({
  id: null,
  messageIdsList: createListCache(),
  scrolledToBottom: false
});

export const createChatsState = (): ChatsState => ({
  chatMessagesCollection: createCollectionCache(),
  chatRoomsCollection: createCollectionCache(),
  chatRoomIds: createListCache(),
  unreadChatIdLists: createUnreadChatIdLists(),
  activeChatRoom: createActiveChatRoom(),
  chatMessageFormById: new Map(),
  editChatMessageForm: null,
  typingMap: new Map()
});
