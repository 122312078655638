import { AppDispatch, SheduleInfo } from 'types/state';

export const sheduleInfoServiceThunk = (promise: Promise<SheduleInfo>) => (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch({
    name: 'SheduleInfoService',
    status: 'PENDING'
  });

  return promise.then(sheduleInfo => {
    dispatch({
      name: 'SheduleInfoService',
      status: 'RESOLVED',
      payload: sheduleInfo
    });
  });
};
