import { AddMessageTemplateParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const addMessageTemplate = async (
  params: AddMessageTemplateParams
): Promise<void> => {
  const { text } = params;

  await sportingAPI.chat.addAnswerDraft({
    payload: {
      text
    }
  });
};
