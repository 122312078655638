import React from 'react';
import { useLocation } from 'react-router';

import { useAppDispatch } from 'hooks/useAppDispatch';

export const useRouterWatcher = () => {
  const prevLocationRef = React.useRef<null | string>(null);

  const location = useLocation();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch({
      name: 'ROUTER__LOCATION_PATHNAME_CHANGE',
      nextLocation: location.pathname,
      prevLocation: prevLocationRef.current
    });

    prevLocationRef.current = location.pathname;
  }, [dispatch, location.pathname]);
};
