import { SubmitChatMessageParams } from 'types/services';
import { chatMessagesStoreMock } from 'services/mock/utils/chatMessagesModel';
import { getUid } from 'utils/getUid';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { UnexpectedError } from 'utils/errors';

const FAIL_KEYWORD = 'FAIL';

const CLIENT_KEYWORD = 'CLIENT';

export const submitChatMessage = async (params: SubmitChatMessageParams) => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  // Для проверки UI повторной отправки сообщения.
  // Если сообщение содержит ключевое слово -
  // сервис бросает ошибку.
  if (params.text.includes(FAIL_KEYWORD)) {
    throw new UnexpectedError();
  }

  // Для проверки UI клиентского сообщения.
  // Если сообщение содержит ключевое слово -
  // сервис сохраняет сообщение как отправленное клиентом.
  if (params.text.includes(CLIENT_KEYWORD)) {
    chatMessagesStoreMock.addMessage({
      id: getUid(),
      origin: 'CLIENT',
      text: params.text,
      createdAt: new Date().toISOString(),
      read: false,
      deleted: false,
      edited: false
    });
  } else {
    chatMessagesStoreMock.addMessage({
      id: getUid(),
      origin: 'STAFF',
      text: params.text,
      createdAt: new Date().toISOString(),
      read: true,
      deleted: false,
      edited: false
    });
  }
};
