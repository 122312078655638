import { useNewReviewsCountUpdateProcess } from './useNewReviewsCountUpdateProcess';
import { useCompetitionsRequestsCountUpdateProcess } from './useCompetitionsRequestsCountUpdateProcess';
import { useNewRestaurantApplicationsCountUpdateProcess } from './useNewRestaurantApplicationsCountUpdateProcess';
import { useNewSportApplicationsCountUpdateProcess } from './useNewSportApplicationsCountUpdateProcess';
import { useUnreadRestaurantChatIdsUpdateProcess } from './chats/useUnreadRestaurantChatIdsUpdateProcess';
import { useUnreadSportChatIdsUpdateProcess } from './chats/useUnreadSportChatIdsUpdateProcess';

export const useCountsUpdateProcess = () => {
  useNewReviewsCountUpdateProcess();

  useCompetitionsRequestsCountUpdateProcess();

  useNewRestaurantApplicationsCountUpdateProcess();

  useNewSportApplicationsCountUpdateProcess();

  useUnreadRestaurantChatIdsUpdateProcess();

  useUnreadSportChatIdsUpdateProcess();
};
