import React from 'react';

import { useIntlContext } from '../../hooks/useIntlContext';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { CalendarPeriod, DayOfWeek, TimeLine } from '../../types/state';
import { ModalFooter } from '../Modal/ModalFooter';
import { RectangleButton } from '../shared/RectangleButton';
import { BookingTimeWorkForm } from './components/BookingTimeWorkForm/BookingTimeWorkForm';
import { useBookingTimeWorkEditorService } from '../../hooks/booking/useBookingTimeWorkEditorService';
import { CloseIcon } from 'svg/24x24/close';
import { Text } from 'design/text';
import { ModalPortal } from 'components/shared/Modal';
import { ActionControl } from 'design/actionControl';
import { useKeyDown } from 'hooks/useKeyDown';
import { BookingModalLayout } from 'components/BookingModalLayout/BookingModalLayoutComponents';
import { NotificationMessageModal } from '../NotificationMessageModal/NotificationMessageModal';
import { ServiceScope } from 'types/serviceScope';
import { TimeConflictError } from 'utils/errors';
import { useToggler } from 'hooks/useToggler';
import { createBookingTimeWorkFormValues } from 'utils/booking/createBookingTimeWorkEditorState';
import { parse } from 'date-fns';
import { DATE_FNS__24H_TIME_FORMAT } from 'utils/constants';

type Props = {
  location: ServiceScope;
  timeLine: TimeLine | null;
  onClose(): void;
};

export const BookingTimeWorkSettingsModal = (props: Props) => {
  const {
    asyncProcess,
    submitDailyWorkingHours,
    submitWeeklyWorkingHours
  } = useBookingTimeWorkEditorService();

  const { bookingTimeWorkSettingsIntl: msgs } = useIntlContext();
  const appDispatch = useAppDispatch();
  const { location, timeLine, onClose } = props;

  const [formValues, setFormValues] = React.useState(
    createBookingTimeWorkFormValues(location)
  );

  // Устанавливаем начальные значения для формы
  React.useEffect(() => {
    if (timeLine != null) {
      const { forAllWeek, days } = timeLine;

      const periodsPerDayOfWeek: Map<DayOfWeek, CalendarPeriod> = new Map();

      for (let day of days) {
        const calendarPeriod: CalendarPeriod = {
          startDateISO: parse(
            day.startAt,
            DATE_FNS__24H_TIME_FORMAT,
            new Date()
          ).toISOString(),
          endDateISO: parse(
            day.endAt,
            DATE_FNS__24H_TIME_FORMAT,
            new Date()
          ).toISOString()
        };

        periodsPerDayOfWeek.set(day.day, calendarPeriod);
      }

      const dailyPreiod: CalendarPeriod = {
        startDateISO: parse(
          days[0].startAt,
          DATE_FNS__24H_TIME_FORMAT,
          new Date()
        ).toISOString(),
        endDateISO: parse(
          days[0].endAt,
          DATE_FNS__24H_TIME_FORMAT,
          new Date()
        ).toISOString()
      };

      setFormValues(prev => ({
        ...prev,
        weekly: forAllWeek,
        dailyPreiod,
        periodsPerDayOfWeek
      }));
    }
  }, [appDispatch, location, timeLine]);

  const busy =
    asyncProcess.status === 'PENDING' || asyncProcess.status === 'RESOLVED';

  const onSubmit = React.useCallback(() => {
    if (formValues.weekly) {
      submitWeeklyWorkingHours(formValues);
    } else {
      submitDailyWorkingHours(formValues);
    }
  }, [formValues, submitDailyWorkingHours, submitWeeklyWorkingHours]);

  const handleWeeklyPeriodChange = React.useCallback(
    (dayOfWeek: DayOfWeek, nextValue: CalendarPeriod) => {
      setFormValues(prev => {
        const nextPeriodsPerDayOfWeek = new Map(prev.periodsPerDayOfWeek);

        nextPeriodsPerDayOfWeek.set(dayOfWeek, nextValue);

        return {
          ...prev,
          periodsPerDayOfWeek: nextPeriodsPerDayOfWeek
        };
      });
    },
    []
  );

  const handleDailyPeriodChange = React.useCallback(
    (nextValue: CalendarPeriod) => {
      setFormValues(prev => {
        return {
          ...prev,
          dailyPreiod: nextValue
        };
      });
    },
    []
  );

  const setWeekly = React.useCallback((nextValue: boolean) => {
    setFormValues(prev => {
      return {
        ...prev,
        weekly: nextValue
      };
    });
  }, []);

  React.useEffect(() => {
    if (asyncProcess.status === 'RESOLVED') {
      onClose();
    }
  }, [asyncProcess.status, onClose]);

  useKeyDown({
    Escape: onClose
  });

  const [hasSubmitErrorDialog, toggleSubmitErrorDialog] = useToggler();

  React.useEffect(() => {
    if (
      asyncProcess.status === 'REJECTED' &&
      asyncProcess.error instanceof TimeConflictError
    ) {
      toggleSubmitErrorDialog();
    }
  }, [asyncProcess, toggleSubmitErrorDialog]);

  return (
    <>
      <ModalPortal toRight={true} noPadding={true}>
        <BookingModalLayout
          closeButton={
            <ActionControl theme="invisible" onClick={onClose}>
              <CloseIcon />
            </ActionControl>
          }
          body={
            <>
              <Text size="xl" weight="semi-bold">
                {msgs.title}
              </Text>
              <BookingTimeWorkForm
                onDailyPeriodChange={handleDailyPeriodChange}
                onWeeklyPeriodChange={handleWeeklyPeriodChange}
                onSwitchToWeeklyShedule={() => setWeekly(true)}
                onSwitchToDailyShedule={() => setWeekly(false)}
                formValues={formValues}
              />
            </>
          }
          footer={
            <ModalFooter>
              <RectangleButton
                styleType={'secondary'}
                label={msgs.cancelBtnLabel}
                isDisabled={busy}
                onClick={onClose}
              />
              <RectangleButton
                styleType={'primary'}
                label={msgs.saveBtnLabel}
                hasPreloader={busy}
                onClick={onSubmit}
              />
            </ModalFooter>
          }
        />
      </ModalPortal>
      {hasSubmitErrorDialog && (
        <NotificationMessageModal
          title={msgs.notificationMessageTitle}
          content={msgs.notificationMessageText}
          submitBtnLabel={msgs.notificationMessageSubmitLabel}
          onSubmit={toggleSubmitErrorDialog}
        />
      )}
    </>
  );
};
