import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { SettingsBlockWrapper } from 'components/SettingsBlock/SettingsBlockWrapper';
import { SettingsBlockContent } from 'components/SettingsBlock/SettingsBlockContent';
import { SettingsBlockTitle } from 'components/SettingsBlock/SettingsBlockTitle';
import { SettingsBlockDescription } from 'components/SettingsBlock/SettingsBlockDescription';
import { SectionsForm } from 'containers/SectionsForm';

export const SectionsEditor = () => {
  const { sectionsEditor: sectionsEditorIntl } = useIntlContext();

  const descriptionParagraphs = sectionsEditorIntl.description;

  return (
    <SettingsBlockWrapper>
      <SettingsBlockContent position="left">
        <SettingsBlockTitle text={sectionsEditorIntl.title} />
        {descriptionParagraphs.map((description: string) => (
          <SettingsBlockDescription key={description} text={description} />
        ))}
      </SettingsBlockContent>
      <SettingsBlockContent position="left">
        <SectionsForm />
      </SettingsBlockContent>
    </SettingsBlockWrapper>
  );
};
