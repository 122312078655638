import { createToggler } from 'utils/createToggler';
import { Toggler } from 'types/util';
import { checkDeployedOn } from './checkDeployedOn';

type LoggedReducer<StateType, EventType> = (
  state: StateType,
  event: EventType
) => StateType;

declare global {
  interface Window {
    appLogger: Toggler;
  }
}

window.appLogger = createToggler();

if (process.env.NODE_ENV === 'development' || checkDeployedOn('testing')) {
  window.appLogger.enable();
}

export function createLoggedReducer<StateType, EventType>(
  reducer: (state: StateType, event: EventType) => StateType
): LoggedReducer<StateType, EventType> {
  return (prevState, event) => {
    const nextState = reducer(prevState, event);

    if (window.appLogger.isEnabled()) {
      console.log(event, { prevState, nextState });
    }

    return nextState;
  };
}
