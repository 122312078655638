import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { SportingSMSCount } from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

export const getAvailableSmsCount = async (): Promise<SportingSMSCount> => {
  const endpointURL = getSportingServiceURL('getAvailableSmsCount');

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (response.ok) {
    return response.json();
  } else {
    throw new UnexpectedError();
  }
};
