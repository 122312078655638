import { TAddNewUserParams } from 'types/services';
import { LoginInUseError, UnexpectedError } from 'utils/errors';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';

const IN_USE_ERROR_LOGIN = 'inuse';

const UNEXPECTED_ERROR_LOGIN = 'unexpected';

export const addNewUser = async (params: TAddNewUserParams) => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  if (params.login === IN_USE_ERROR_LOGIN) {
    throw new LoginInUseError();
  }

  if (params.login === UNEXPECTED_ERROR_LOGIN) {
    throw new UnexpectedError();
  }
};
