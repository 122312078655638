import React from 'react';

import { TApplicationsGroup, TApplication } from 'types/state';
import { useAppState } from 'hooks/useAppState';
import { normalizeApplications } from 'utils/normalizeApplciations';
import { UnreachableError } from 'utils/errors';
import { ServiceScope } from 'types/serviceScope';

export const useNewApplicationGroups = (
  domain: ServiceScope
): TApplicationsGroup[] => {
  const {
    newSportApplicationsList,
    newRestaurantApplicationsList,
    applicationsCollection
  } = useAppState();

  const targetList = (() => {
    switch (domain) {
      case 'RESTAURANT':
        return newRestaurantApplicationsList;
      case 'CLUB':
        return newSportApplicationsList;
      default:
        throw new UnreachableError(domain);
    }
  })();

  const newApplicationIds = React.useMemo(() => {
    return targetList.byPage.get(1) || [];
  }, [targetList.byPage]);

  const newApplcations = React.useMemo(() => {
    return newApplicationIds.reduce<TApplication[]>((acc, id) => {
      const application = applicationsCollection.byId.get(id);

      if (application) {
        acc.push(application);
      }

      return acc;
    }, []);
  }, [applicationsCollection.byId, newApplicationIds]);

  const newApplicationGroup = React.useMemo(() => {
    return normalizeApplications.toApplicationGroups(newApplcations, 'NEW');
  }, [newApplcations]);

  return newApplicationGroup;
};
