import { sportingAPI } from '../api';
import { TSubmitPlacesParams } from '../../../types/services';
import { normalizeBookingPlaceFormValues } from '../utils/normalizeBookingPlaceFormValues';

export const submitPlaces = async (params: TSubmitPlacesParams) => {
  const { formValues } = params;

  const payload = formValues.places.map(place => {
    return normalizeBookingPlaceFormValues(place).toSportingSubmitPlacePayload(
      formValues.location
    );
  });

  await sportingAPI.booking.submitPlaces({
    payload
  });
};
