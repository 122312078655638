import React from 'react';

import styles from './ReviewNotes.module.css';

import { testId } from 'utils/testId';

type TProps = {
  text: string;
};

export const ReviewNotesTitle = ({ text }: TProps) => (
  <div
    className={styles.ReviewNotes__Title}
    data-testid={testId.REVIEW_NOTES_TITLE}
  >
    {text}
  </div>
);
