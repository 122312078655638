import { SetUserPhoneNumberParams } from 'types/services';
import { sportingAPI } from '../api';

export const setUserPhoneNumber = async (
  params: SetUserPhoneNumberParams
): Promise<void> => {
  const { userId, phoneNumber } = params;

  await sportingAPI.service.setEmployeeMobilePhone({
    payload: { idUser: userId, phoneNumber: phoneNumber }
  });
};
