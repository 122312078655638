import React from 'react';

import styles from './Checkbox.module.scss';

import { useMediaFlags } from 'hooks/useMediaFlags';
import { CheckLightIcon } from 'svg/check-light';
import { CheckIcon } from 'svg/check';

type TProps = {
  name: string;
  checked: boolean;
  value?: string;
  className?: string;
  testId?: string;
  id?: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

export const Checkbox = (props: TProps) => {
  const { name, id, value, testId, className = '', onChange, checked } = props;

  const { isTablet, isMobile } = useMediaFlags();

  const icon = React.useMemo(() => {
    if (isTablet || isMobile) {
      return <CheckLightIcon className={styles.Checkbox__CheckIcon} />;
    }
    return <CheckIcon className={styles.Checkbox__CheckIcon} />;
  }, [isTablet, isMobile]);

  return (
    <div className={`${styles.Checkbox__Wrapper} ${className}`}>
      <input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        className={styles.Checkbox__Input}
        checked={checked}
        onChange={onChange}
        data-testid={testId}
      />
      <div className={styles.Checkbox__Box}>{icon}</div>
    </div>
  );
};
