import { isToday, isYesterday, createTimeText } from 'utils/dateUtils';
import { TIntl } from 'types/intl';

export const getLocalizedNewsFeedItemPublishDate = (
  publishDate: string,
  intl: TIntl
) => {
  const { predicativeMonthNames } = intl;

  const publishDateObj = new Date(publishDate);

  const time = createTimeText(publishDateObj);

  const wasPublishedToday = isToday(publishDateObj);

  if (wasPublishedToday) {
    return intl.todayAt(time);
  }

  const wasPublishedYesterday = isYesterday(publishDateObj);

  if (wasPublishedYesterday) {
    return intl.yesterdayAt(time);
  }

  return intl.calendarDateAt(
    `${publishDateObj.getDate()} ${
      predicativeMonthNames[publishDateObj.getMonth()]
    }`,
    time
  );
};
