import React from 'react';

import { useSectionsEditorState } from 'hooks/useSectionsEditorState';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { SectionsToSync } from 'types/state';

export const useSectionsToSync = (): SectionsToSync => {
  const cachedEnabledSections = useEnabledSectionsStrict();

  const { formValues } = useSectionsEditorState();

  return React.useMemo(() => {
    const sectionGroups: SectionsToSync = {
      sectionsToEnable: [],
      sectionsToDisable: []
    };

    const editedEntries = Array.from(formValues.entries());

    return editedEntries.reduce((acc, [section, editedEnabled]) => {
      const cachedEnabled = cachedEnabledSections.includes(section);
      if (cachedEnabled === true && editedEnabled === false) {
        acc.sectionsToDisable.push(section);
      }
      if (cachedEnabled === false && editedEnabled === true) {
        acc.sectionsToEnable.push(section);
      }

      return acc;
    }, sectionGroups);
  }, [cachedEnabledSections, formValues]);
};
