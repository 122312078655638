import { format } from 'date-fns';
import { DATE_FNS__24H_TIME_FORMAT } from 'utils/constants';
import { BookingReservationFormValues } from '../../../types/state';
import { convertDateISOToYYYY_MM_DD } from '../../../utils/dateUtils';
import {
  SportingCreateReservationPayload,
  SportingDeleteReservationPayload,
  SportingUpdateReservationPayload
} from '../types';

export const normalizeBookingReservationFormValues = (
  formValues: BookingReservationFormValues
) => ({
  toSportingCreateReservationPayload(): SportingCreateReservationPayload {
    const bookFrom = format(
      new Date(formValues.period.startDateISO),
      DATE_FNS__24H_TIME_FORMAT
    );

    const bookTo = format(
      new Date(formValues.period.endDateISO),
      DATE_FNS__24H_TIME_FORMAT
    );

    return {
      personsCount: formValues.personsCount,
      bookDate: convertDateISOToYYYY_MM_DD(formValues.period.startDateISO),
      bookFrom,
      bookTo,
      placeItemIds: formValues.placeItems.map(item => item.id),
      comment: formValues.comment,
      preBooking: formValues.preBooking,
      userId: formValues.userId > 0 ? formValues.userId : null,
      fio: formValues.fio,
      phoneNumber: formValues.phoneNumber,
      cardNumber: formValues.cardNumber,
      processedBy: formValues.processedBy
    };
  },
  toSportingUpdateReservationPayload(): SportingUpdateReservationPayload {
    const bookFrom = format(
      new Date(formValues.period.startDateISO),
      DATE_FNS__24H_TIME_FORMAT
    );

    const bookTo = format(
      new Date(formValues.period.endDateISO),
      DATE_FNS__24H_TIME_FORMAT
    );

    return {
      id: formValues.id,
      personsCount: formValues.personsCount,
      bookDate: convertDateISOToYYYY_MM_DD(formValues.period.startDateISO),
      bookFrom,
      bookTo,
      placeItemIds: formValues.placeItems.map(item => item.id),
      comment: formValues.comment,
      preBooking: formValues.preBooking,
      userId: formValues.userId > 0 ? formValues.userId : null,
      fio: formValues.fio,
      phoneNumber: formValues.phoneNumber,
      cardNumber: formValues.cardNumber,
      processedBy: formValues.processedBy
    };
  },
  toSportingDeleteReservationPayload(): SportingDeleteReservationPayload {
    return {
      idReservation: formValues.id
    };
  }
});
