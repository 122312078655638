import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineTrackLayout.scss';

type Props = {
  items: React.ReactNode;
};

const bemConstructor = createBEMConstructor('bookingTimelineTrackLayout');

export const BookingTimelineTrackLayout: React.FC<Props> = props => {
  const { items } = props;

  const wrapperClassName = bemConstructor('wrapper');

  const itemsSlotClassName = bemConstructor('itemsSlot');

  return (
    <div className={wrapperClassName}>
      <div className={itemsSlotClassName}>{items}</div>
    </div>
  );
};
