import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { TUpdateApplicationStatusParams } from 'types/services';
import { TApplication } from 'types/state';
import { NotFoundError } from 'utils/errors';
import { restaurantApplicationsStoreMock } from 'services/mock/utils/restaurantApplicationsModel';
import { sportApplicationsStoreMock } from 'services/mock/utils/sportApplicationsModel';

export const updateApplicationStatus = async (
  params: TUpdateApplicationStatusParams
) => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  const handleFind = ({ id }: TApplication) => id === params.id;

  let targetApplication;

  if (params.domain === 'RESTAURANT') {
    targetApplication = restaurantApplicationsStoreMock
      .getAllApplications()
      .find(handleFind);
  }

  if (params.domain === 'CLUB') {
    targetApplication = sportApplicationsStoreMock
      .getAllApplications()
      .find(handleFind);
  }

  if (!targetApplication) {
    throw new NotFoundError();
  }

  targetApplication.status = params.nextStatus;
};
