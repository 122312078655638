import { SportingUser } from 'services/sporting/types';
import { ClientInfo } from 'types/client';
import { toMaskedPhone } from 'utils/toMaskedPhone';
import { normalizeSportingCardType } from './normalizeSportingCardStatus';

export const normalizeSportingUser = (sportingUser: SportingUser) => ({
  toClientInfo(): ClientInfo {
    return {
      id: sportingUser.id,
      firstName: sportingUser.name,
      lastName: sportingUser.lastName || '',
      middleName: sportingUser.patronymic || '',
      status: normalizeSportingCardType(sportingUser.cardType).toClientStatus(),
      cardNumber: sportingUser.cardNumber,
      phoneNumber: sportingUser.mobilePhone
        ? toMaskedPhone(sportingUser.mobilePhone)
        : null,
      blacklisted: sportingUser.personStatus?.name === 'StopLand'
    };
  }
});
