import React from 'react';

import styles from './SignInPage.module.css';

type TProps = {
  children: React.ReactNode;
};

export const SignInPageTemplate = ({ children }: TProps) => (
  <div className={styles.SignInPage__Wrapper}>
    <div className={styles.SignInPage__Content}>{children}</div>
  </div>
);
