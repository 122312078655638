import { MediaFlags, WindowDimensions, MediaQueryType } from 'types/state';

const MEDIA_QUERY_DESKTOP_L_TYPE = 'desktop-l';
const MEDIA_QUERY_DESKTOP_XL_TYPE = 'desktop-xl';
const MEDIA_QUERY_TABLET_TYPE = 'tablet';
const MEDIA_QUERY_MOBILE_TYPE = 'mobile';

export const getMediaFlags = function(mediaDevice: MediaQueryType): MediaFlags {
  const isDesktopL = mediaDevice === MEDIA_QUERY_DESKTOP_L_TYPE;
  const isDesktopXL = mediaDevice === MEDIA_QUERY_DESKTOP_XL_TYPE;
  const isTablet = mediaDevice === MEDIA_QUERY_TABLET_TYPE;
  const isMobile = mediaDevice === MEDIA_QUERY_MOBILE_TYPE;

  return {
    isDesktopXL,
    isDesktopL,
    isTablet,
    isMobile
  };
};

export const getMediaQueryType = (
  dimensions: WindowDimensions
): MediaQueryType => {
  const { innerWidth } = dimensions;

  if (innerWidth < 780) {
    return MEDIA_QUERY_MOBILE_TYPE;
  }
  if (innerWidth < 1024) {
    return MEDIA_QUERY_TABLET_TYPE;
  }
  if (innerWidth < 1480) {
    return MEDIA_QUERY_DESKTOP_L_TYPE;
  }
  return MEDIA_QUERY_DESKTOP_XL_TYPE;
};

export const getDeviceFlag = (): boolean => {
  const { navigator } = window;
  if (
    (navigator && navigator.userAgent.match(/Android/i)) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};
