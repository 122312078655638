import { TReview } from 'types/state';
import { NotFoundError } from 'utils/errors';
import { createReviewMocks } from 'services/mock/utils/mockInstances';

let storeMock: TReview[] = [...createReviewMocks(25)];

const getAllReviews = () => [...storeMock];

const getReviewById = (id: number) => {
  const review = storeMock.find(item => item.id === id);

  if (!review) {
    throw new NotFoundError();
  }

  return review;
};

const updateReview = (review: TReview) => {
  storeMock = storeMock.map(item => {
    if (item.id === review.id) {
      return review;
    }
    return item;
  });
};

export const reviewsStoreMock = {
  getAllReviews,
  getReviewById,
  updateReview
};
