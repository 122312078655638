import { SendTypingStatusService } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const sendTypingStatus: SendTypingStatusService = async params => {
  const { roomId, typing } = params;

  await sportingAPI.chat.typeMessage({
    payload: {
      idChat: roomId,
      typingAction: typing ? 'START_TYPING' : 'FINISH_TYPING'
    }
  });
};
