import { TDeleteCompetitionsArticleParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const deleteCompetitionsArticle = async (
  params: TDeleteCompetitionsArticleParams
): Promise<void> => {
  const { id } = params;

  await sportingAPI.service.deleteCompetition({
    payload: { idCompetition: id }
  });
};
