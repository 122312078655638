import { ServiceScope } from 'types/serviceScope';
import {
  BookingReservationEditorState,
  BookingReservationFormValues
} from '../../types/state';

export const createBookingReservationEditorState = (
  formValues: BookingReservationFormValues | null
): BookingReservationEditorState => ({
  formValues: formValues
    ? formValues
    : createBookingReservationFormValues('RESTAURANT'),
  submited: false,
  submitDidSucceed: false
});

export const createBookingReservationFormValues = (
  serviceScope: ServiceScope
): BookingReservationFormValues => {
  return {
    initialized: false,
    id: 0,
    personsCount: 0,
    period: {
      startDateISO: '',
      endDateISO: ''
    },
    placeItems: [],
    comment: '',
    preBooking: true,
    userId: 0,
    fio: '',
    phoneNumber: '',
    cardNumber: '',
    processedBy: '',
    serviceScope
  };
};
