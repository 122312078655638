import React from 'react';

import { setFavicon } from 'utils/setFavicon';
import { TFaviconType } from 'types/state';
import { setCustomInterval } from 'utils/setCustomInterval';
import { FAVICON_FLICKERING_INTERVAL } from 'utils/constants';

export const useFaviconService = () => {
  const [attentionModeEnabled, setAttentionModeEnabled] = React.useState(false);

  const [faviconType, setFaviconType] = React.useState<TFaviconType>('DEFAULT');

  const startAttentionMode = React.useCallback(() => {
    setAttentionModeEnabled(true);
  }, []);

  const stopAttentionMode = React.useCallback(() => {
    setAttentionModeEnabled(false);
  }, []);

  React.useEffect(() => {
    if (attentionModeEnabled) {
      return setCustomInterval(() => {
        setFaviconType(prevType => {
          if (prevType === 'DEFAULT') {
            return 'ATTENTION';
          }
          return 'DEFAULT';
        });
      }, FAVICON_FLICKERING_INTERVAL);
    }

    setFaviconType('DEFAULT');
  }, [attentionModeEnabled]);

  React.useEffect(() => {
    setFavicon(faviconType);
  }, [faviconType]);

  return { startAttentionMode, stopAttentionMode };
};
