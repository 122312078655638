import { TNewsArticle } from 'types/state';
import { TSportingNewsArticle } from 'services/sporting/types';
import { normalizeSportingPerson } from 'services/sporting/utils/normalizeSportingPersonType';
import {
  getSportingRawImageURL,
  getSportingProcessedNewsImageURL
} from 'services/sporting/utils/getSportingURL';

export const normalizeSportingNewsArticle = {
  toNewsArticle: (item: TSportingNewsArticle): TNewsArticle => ({
    id: item.id,
    title: item.title,
    description: item.body,
    audience: item.destinations.map(normalizeSportingPerson.toAudienceGroup),
    publishDate: item.publishDate || null,
    image:
      item.fullImageName && item.processedImageName
        ? {
            original: getSportingRawImageURL(item.fullImageName),
            cropped: getSportingProcessedNewsImageURL(item.processedImageName)
          }
        : null,
    isDraft: item.contentStatus === 'Draft'
  })
};
