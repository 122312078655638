import { compareAsc } from 'date-fns';
import {
  BookingReservationFormError,
  BookingReservationFormValues
} from '../../types/state';

export const validateBookingReservationFormErrors = (
  formValues: BookingReservationFormValues
): BookingReservationFormError[] => {
  let reservationFormErrors: BookingReservationFormError[] = [];
  if (!formValues.personsCount) {
    reservationFormErrors.push('BOOKING_RESERVATION_FORM_GUEST_COUNT_EMPTY');
  }
  if (
    compareAsc(
      new Date(formValues.period.startDateISO),
      new Date(formValues.period.endDateISO)
    ) >= 0
  ) {
    reservationFormErrors.push('BOOKING_RESERVATION_FORM_INVALID_PERIOD');
  }
  if (!formValues.placeItems || formValues.placeItems.length === 0) {
    reservationFormErrors.push('BOOKING_RESERVATION_FORM_PLACES_EMPTY');
  }
  if (formValues.preBooking && !formValues.fio) {
    reservationFormErrors.push('BOOKING_RESERVATION_FORM_USER_NAME_EMPTY');
  }
  if (formValues.preBooking && !formValues.phoneNumber) {
    reservationFormErrors.push('BOOKING_RESERVATION_FORM_USER_PHONE_EMPTY');
  }
  const processedBy = formValues.processedBy;
  if (!processedBy || processedBy.length === 0) {
    reservationFormErrors.push('BOOKING_RESERVATION_FORM_PROCESSED_EMPTY');
  }
  return reservationFormErrors;
};
