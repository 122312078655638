import React from 'react';

import styles from './CompetitionsArticle.module.css';

type Props = {
  children: React.ReactNode;
};

export const CompetitionsArticleInfo: React.FC<Props> = ({ children }) => (
  <div className={styles.CompetitionsArticle__Info}>{children}</div>
);
