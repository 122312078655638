import React from 'react';

import styles from './SearchClientResultPopup.module.scss';
import { Popup } from '../../shared/Popup';
import { ClientInfo } from '../../../types/client';
import { useIntlContext } from '../../../hooks/useIntlContext';
import { Text } from 'design/text';
import { ClickControlComponent } from 'design/clickControl/clickControlComponents';
import { ClientSearchResultsGroupLayoutComponent } from 'design/clientSearchResultsGroupLayout/clientSearchResultsGroupLayoutComponents';
import { ClientSearchResultsItemLayoutComponent } from 'design/clientSearchResultsItemLayout/clientSearchResultsItemLayoutComponents';

type Props = {
  clientInfoList: ClientInfo[];
  onSelectItem(clientInfo: ClientInfo): void;
};
export const SearchClientResultPopup = (props: Props) => {
  const intl = useIntlContext();

  const { searchClientResultPopupIntl: msgs } = intl;

  const { clientInfoList, onSelectItem } = props;

  const clubMembers = clientInfoList.filter(
    info => info.cardNumber != null && info.cardNumber.length > 0
  );
  const leads = clientInfoList.filter(
    info => info.cardNumber == null || info.cardNumber.length === 0
  );

  return (
    <Popup className={styles.popup}>
      <div className={styles.content}>
        {clientInfoList.length === 0 && (
          <Text color="secondary" size="s">
            {msgs.placeholder}
          </Text>
        )}
        {clubMembers.length > 0 && (
          <ClientSearchResultsGroupLayoutComponent
            labelText={
              <Text color="secondary" size="s">
                {msgs.clubMembersLabel}
              </Text>
            }
            items={clubMembers.map(clientInfo => (
              <ClickControlComponent onClick={() => onSelectItem(clientInfo)}>
                <ClientSearchResultsItemLayoutComponent
                  cardNumber={<Text>{clientInfo.cardNumber}</Text>}
                  phoneNumber={<Text>{clientInfo.phoneNumber}</Text>}
                  fullName={<Text>{intl.personFullName(clientInfo)}</Text>}
                  blacklisted={!!clientInfo.blacklisted}
                />
              </ClickControlComponent>
            ))}
          />
        )}
        {leads.length > 0 && (
          <ClientSearchResultsGroupLayoutComponent
            labelText={
              <Text color="secondary" size="s">
                {msgs.leadsLabel}
              </Text>
            }
            items={leads.map(clientInfo => (
              <ClickControlComponent onClick={() => onSelectItem(clientInfo)}>
                <ClientSearchResultsItemLayoutComponent
                  cardNumber={<Text>{clientInfo.cardNumber}</Text>}
                  phoneNumber={<Text>{clientInfo.phoneNumber}</Text>}
                  fullName={<Text>{intl.personFullName(clientInfo)}</Text>}
                  blacklisted={!!clientInfo.blacklisted}
                />
              </ClickControlComponent>
            ))}
          />
        )}
      </div>
    </Popup>
  );
};
