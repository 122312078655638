import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { CHAT_ROOM_SCROLL_LEFT_TO_LOAD_MORE } from 'utils/constants';
import { useChatsState } from 'hooks/chats/useChatsState';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { useChatMessageSubmissionService } from 'hooks/chats/useChatMessageSubmissionService';
import { ScrollControl, ScrollInfo } from 'design/scrollControl';
import { NewMessagesIndicatorLayout } from 'design/newMessagesIndicatorLayout';
import { Bage } from 'components/shared/Bage';
import { ActionControl } from 'design/actionControl';
import { ChatsPageMessageCandidate } from 'pages/ChatsPage/ChatsPageMessageCandidate';
import { ChatMessage } from 'types/chats';
import { Shadow } from 'design/shadow';
import { scrollToBottom } from 'utils/scrollToBottom';
import { ChatsPageMessagesItem } from 'pages/ChatsPage/ChatsPageMessagesItem';
import { useActiveChatRoom } from 'hooks/chats/useActiveChatRoom';
import { ChatsPageTypingIndicator } from 'pages/ChatsPage/ChatsPageTypingIndicator';
import { createChatsMessageForm } from 'utils/createChatsState';
import { ArrowUpIcon } from 'svg/16x16/arrow-up';

type Props = {
  roomId: number;
  messages: ChatMessage[];
  unreadMessagesCount: number;
  onScrolledToBottom(): void;
};

export const ChatsPageMessages = (props: Props) => {
  const { roomId, messages, unreadMessagesCount, onScrolledToBottom } = props;

  useChatMessageSubmissionService(roomId);

  const scrollControlRef = React.useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const { chatMessageFormById } = useChatsState();

  const chatMessageForm = React.useMemo(() => {
    const form = chatMessageFormById.get(roomId);

    return form || createChatsMessageForm();
  }, [chatMessageFormById, roomId]);

  const { messageIdsList, scrolledToBottom } = useActiveChatRoom();

  const activeChatRoomMessageIdsListSelectors = createListCacheSelectors(
    messageIdsList
  );

  const noInvalidatedPages =
    activeChatRoomMessageIdsListSelectors.selectNextInvalidatedPage() === null;

  const noMorePages = activeChatRoomMessageIdsListSelectors.hasEmptyPage();

  const loadingAllowed = noInvalidatedPages && !noMorePages;

  const latestMessage = React.useMemo(() => {
    return messages[messages.length - 1];
  }, [messages]);

  const hasUnreadMessagesCountIndicator = scrolledToBottom === false;

  const handleScroll = React.useCallback(
    (info: ScrollInfo) => {
      if (info.dimensions.bottom === 0 && !scrolledToBottom) {
        dispatch({
          name: 'CHATS_PAGE_MESSAGES__SCROLLED_TO_BOTTOM'
        });
      }
      if (info.dimensions.bottom > 0 && scrolledToBottom) {
        dispatch({
          name: 'CHATS_PAGE_MESSAGES__SCROLLED_FROM_BOTTOM'
        });
      }

      const withinTreshold =
        info.dimensions.top < CHAT_ROOM_SCROLL_LEFT_TO_LOAD_MORE;

      if (loadingAllowed && withinTreshold && info.direction === 'up') {
        dispatch({
          name: 'CHATS_PAGE_MESSAGES__SCROLL_LEFT_TO_LOAD_MORE_REACHED'
        });
      }
    },
    [dispatch, loadingAllowed, scrolledToBottom]
  );

  const handleNewMessagesCountIndicatorClick = React.useCallback(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (scrollControlNode) {
      scrollToBottom(scrollControlNode);
    }
  }, []);

  React.useEffect(() => {
    if (scrolledToBottom) {
      onScrolledToBottom();
    }
  }, [onScrolledToBottom, scrolledToBottom]);

  // Эффект выявляет при монтировании отсутствие
  // прокрутки у чата (мало сообщений) и устанавливает
  // флаг прокрутки на низ переписки в значение true,
  // чтобы сработал вызов пометки чата как прочитанного.
  React.useLayoutEffect(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (scrollControlNode) {
      const noScroll =
        scrollControlNode.scrollHeight === scrollControlNode.clientHeight;

      if (noScroll) {
        dispatch({
          name: 'CHATS_PAGE_MESSAGES__SCROLLED_TO_BOTTOM'
        });
      }
    }
  }, [dispatch]);

  // Эффект при монтировании скроллит переписку
  // на самый низ, чтобы показать пользователю
  // актуальные сообщения.
  React.useLayoutEffect(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (scrollControlNode) {
      scrollToBottom(scrollControlNode);
    }
  }, []);

  // Эффект отвечает за подскрол ленты сообщений вниз,
  // когда приходят новые сообщения.
  // Должен скроллить:
  // - если на момент получения нового сообщения
  //   лента уже была на низу.
  // - если на момент получения сообщения лента
  //   не была на низу, но сообщение отправлено
  //   пользователем.
  const prevLatestMessageId = React.useRef<number | null>(null);

  React.useLayoutEffect(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (scrollControlNode) {
      const shouldScroll =
        scrolledToBottom ||
        (prevLatestMessageId.current !== latestMessage.id &&
          latestMessage.origin === 'STAFF');

      if (shouldScroll) {
        scrollToBottom(scrollControlNode);
      }
    }

    prevLatestMessageId.current = latestMessage.id;
  }, [latestMessage.id, latestMessage.origin, scrolledToBottom]);

  // При увеличении футера по мере набора сообщения переписка
  // начинает уходить в скролл.
  // Чтобы поддерживать прокрученное до конца положение переписки
  // специальный эффект следит за значением формы сообщения
  // и постоянно подкручивает переписку вниз до конца:
  // Используется useEffect, потому что useLayoutEffect срабатывает
  // слишком рано.

  React.useEffect(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (
      scrollControlNode &&
      chatMessageForm.value.length > 0 &&
      scrolledToBottom
    ) {
      scrollToBottom(scrollControlNode);
    }
  }, [chatMessageForm.value.length, scrolledToBottom]);

  // Эффект скроллит в конец переписки в случае,
  // если отправка сообщения провалилась и нужно
  // показать сообщение с кнопкой повторной отправки.
  React.useLayoutEffect(() => {
    const { current: scrollControlNode } = scrollControlRef;

    if (scrollControlNode && chatMessageForm.status === 'REJECTED') {
      scrollToBottom(scrollControlNode);
    }
  }, [chatMessageForm.status]);

  const messageNodes = React.useMemo(
    () =>
      messages.map((message, index, array) => (
        <ChatsPageMessagesItem
          key={message.id}
          messages={array}
          message={message}
          index={index}
        />
      )),
    [messages]
  );

  return (
    <>
      <ScrollControl ref={scrollControlRef} onScroll={handleScroll}>
        {messageNodes}
        <ChatsPageMessageCandidate roomId={roomId} />
        <ChatsPageTypingIndicator roomId={roomId} />
      </ScrollControl>
      <Shadow direction="up" enable={!scrolledToBottom} />
      {hasUnreadMessagesCountIndicator && (
        <NewMessagesIndicatorLayout
          bage={unreadMessagesCount > 0 && <Bage count={unreadMessagesCount} />}
          action={
            <ActionControl
              theme="round-floating"
              onClick={handleNewMessagesCountIndicatorClick}
            >
              <ArrowUpIcon />
            </ActionControl>
          }
        />
      )}
    </>
  );
};
