import { TNewsArticle } from 'types/state';
import { getUid } from 'utils/getUid';
import { NEWS_FEED_PAGE_LENGTH } from 'utils/constants';
import { getNewsArticleMocks } from 'services/mock/utils/newsInstances';

// В этом файле содержится in-memory база данных,
// которая используется mock-сервисами для имитации
// взаимодействия с сервером при dev-разработке.

// Меняя эту константу можно влиять на количество
// предварительно созданных записей в базе данных.
const INITIAL_STORE_LENGTH = 0;

// База данных.
let storeMock: TNewsArticle[] = [...getNewsArticleMocks(INITIAL_STORE_LENGTH)];

// Методы взаимодействия с базой данных:

const getAllArticles = () => [...storeMock];

const getArticlesByPage = (page: number) => {
  const startIndex = (page - 1) * NEWS_FEED_PAGE_LENGTH;
  const endIndex = startIndex + NEWS_FEED_PAGE_LENGTH;

  return storeMock.slice(startIndex, endIndex);
};

const getArticleById = (targetId: number) =>
  storeMock.find(({ id }) => id === targetId);

const saveArticle = (article: TNewsArticle) => {
  const length = storeMock.push({
    ...article,
    id: getUid()
  });
  return storeMock[length - 1];
};

const updateArticle = (article: TNewsArticle) => {
  const targetIndex = storeMock.findIndex(({ id }) => id === article.id);
  if (targetIndex > -1) {
    storeMock[targetIndex] = {
      ...article
    };
    return storeMock[targetIndex];
  } else {
    throw new TypeError();
  }
};

const deleteArticle = (targetId: number) => {
  storeMock = storeMock.filter(({ id }) => id !== targetId);
};

export const newsStoreMock = {
  getAllArticles,
  getArticlesByPage,
  getArticleById,
  saveArticle,
  updateArticle,
  deleteArticle
};
