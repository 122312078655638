import React from 'react';
import { useIntlContext } from 'hooks/useIntlContext';
import { SettingsBlockWrapper } from 'components/SettingsBlock/SettingsBlockWrapper';
import { SettingsBlockContent } from 'components/SettingsBlock/SettingsBlockContent';
import { SettingsBlockTitle } from 'components/SettingsBlock/SettingsBlockTitle';
import { SettingsBlockDescription } from 'components/SettingsBlock/SettingsBlockDescription';
import { PersonalDataForm } from '../PersonalDataForm';

export const PersonalDataEditor = () => {
  const { personalDataEditor: personalDataEditorIntl } = useIntlContext();

  return (
    <SettingsBlockWrapper>
      <SettingsBlockContent position="left">
        <SettingsBlockTitle text={personalDataEditorIntl.title} />
        <SettingsBlockDescription text={personalDataEditorIntl.description} />
      </SettingsBlockContent>
      <SettingsBlockContent position="left">
        <PersonalDataForm />
      </SettingsBlockContent>
    </SettingsBlockWrapper>
  );
};
