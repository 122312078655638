import { ChatMessage } from 'types/chats';
import { getUid } from 'utils/getUid';
import { createRandomBoolean } from 'utils/createRandomBoolean';

export const createChatMessageMock = (): ChatMessage => {
  const origin = createRandomBoolean() ? 'CLIENT' : 'STAFF';

  const text =
    origin === 'CLIENT'
      ? 'Можно заказать столик на 10 часов'
      : 'Добрый день, чем могу помочь?';

  return {
    id: getUid(),
    createdAt: new Date().toISOString(),
    origin,
    text,
    read: createRandomBoolean(),
    deleted: createRandomBoolean(),
    edited: createRandomBoolean()
  };
};

export const createChatMessageMocks = (count: number): ChatMessage[] => {
  const mocks: ChatMessage[] = [];

  while (count > 0) {
    mocks.push(createChatMessageMock());

    count = count - 1;
  }

  return mocks;
};
