import React from 'react';

import styles from './NewsPreview.module.css';

type TProps = {
  children: React.ReactNode;
  hasEditView: boolean;
};

export const NewsPreviewTemplate = ({ children, hasEditView }: TProps) => (
  <div
    className={`${styles.NewsPreview__Template} ${
      hasEditView ? styles.NewsPreview__Template_edit : ''
    }`}
  >
    {children}
  </div>
);
