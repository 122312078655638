import React from 'react';
import { ArrowDownIcon } from 'svg/16x16/arrow-down';

import styles from './CustomSelect.module.scss';

type TProps = {
  label: string;
  opened: boolean;
  invalid?: boolean;
  disabled?: boolean;
  testId?: string;
  className?: string;
  onClick(): void;
};

export const CustomSelectButton = ({
  label,
  opened,
  invalid,
  disabled,
  testId,
  className = '',
  onClick
}: TProps) => {
  return (
    <button
      type="button"
      className={`${styles.CustomSelect__Button} ${
        opened ? styles.CustomSelect__Button_opened : ''
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      data-invalid={invalid}
      data-testid={testId}
    >
      {label}
      {!disabled && (
        <ArrowDownIcon
          className={`${styles.CustomSelect__Arrow} ${
            opened ? styles.CustomSelect__Arrow_up : ''
          }`}
        />
      )}
    </button>
  );
};
