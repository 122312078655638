import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import {
  SportingSecureParams,
  TSportingNewsArticle
} from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

export const addNews = async (
  params: SportingSecureParams<FormData>
): Promise<TSportingNewsArticle> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL('addNews');

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body: payload,
    headers
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new UnexpectedError();
  }
};
