import { SportingReservation } from '../../services/sporting/types';
import { Reservation } from '../../types/state';
import { DATE_FNS__24H_TIME_FORMAT_WITH_SECOND } from '../constants';
import { parse, startOfDay } from 'date-fns';

export function normalizeSportingReservation(
  sportingReservation: SportingReservation
) {
  return {
    toReservation(): Reservation {
      const bookDate = sportingReservation.bookDate;
      const date = startOfDay(new Date(bookDate));
      const startDateISO = parse(
        sportingReservation.bookFrom,
        DATE_FNS__24H_TIME_FORMAT_WITH_SECOND,
        date
      ).toISOString();

      const endDateISO = parse(
        sportingReservation.bookTo,
        DATE_FNS__24H_TIME_FORMAT_WITH_SECOND,
        date
      ).toISOString();

      const period = {
        startDateISO,
        endDateISO
      };

      return {
        id: sportingReservation.id,
        personsCount: sportingReservation.personsCount,
        period,
        places: sportingReservation.places,
        comment: sportingReservation.comment,
        preBooking: sportingReservation.preBooking,
        userId: sportingReservation.userId,
        fio: sportingReservation.fio,
        phoneNumber: sportingReservation.phoneNumber,
        cardNumber: sportingReservation.cardNumber,
        processedBy: sportingReservation.processedBy
      };
    }
  };
}
