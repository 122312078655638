import React from 'react';

import styles from './SignInPage.module.css';

type TProps = {
  text: string;
  testId: string;
};

export const SignInPageError = ({ text, testId }: TProps) => (
  <div className={styles.SignInPage__Error} data-testid={testId}>
    {text}
  </div>
);
