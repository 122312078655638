import React from 'react';

import styles from './CompetitionsPreview.module.scss';

import { Spinner } from 'components/shared/Spinner';

export const CompetitionsPreviewPreloader = () => {
  return (
    <div className={styles.CompetitionsPreview__Preloader}>
      <Spinner />
    </div>
  );
};
