import { CreateEmployeeParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { UserInfo } from 'types/state';

export const createEmployee = async (
  params: CreateEmployeeParams
): Promise<UserInfo> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return {
    id: 20,
    role: params.role,
    login: params.login,
    firstName: params.firstName,
    lastName: params.lastName,
    phoneNumber: params.phoneNumber,
    blocked: false,
    enabledSections: []
  };
};
