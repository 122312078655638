import { UserRole } from 'types/state';

export const getAvailableRoleForView = (currentRole: UserRole): UserRole[] => {
  switch (currentRole) {
    case 'ADMINISTRATOR':
      return ['ADMINISTRATOR', 'MANAGER', 'CURATOR', 'SECURITY'];
    case 'MANAGER':
      return ['MANAGER', 'CURATOR'];
    case 'CURATOR':
      return ['CURATOR'];
    case 'SECURITY':
      return [];
  }
};

export const getAvailableRoleForEdit = (currentRole: UserRole): UserRole[] => {
  switch (currentRole) {
    case 'ADMINISTRATOR':
      return ['MANAGER', 'CURATOR', 'SECURITY'];
    case 'MANAGER':
      return ['CURATOR'];
    case 'CURATOR':
      return [];
    case 'SECURITY':
      return [];
  }
};
