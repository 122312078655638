import React from 'react';

import { TReview, TReviewStatus } from 'types/state';
import { ReviewsFeed } from 'containers/ReviewsFeed';
import { ReviewPreview } from 'containers/ReviewPreview';
import { ReviewNotes } from 'containers/ReviewNotes';
import { ReviewsPageWrapper } from 'pages/ReviewsPage/styled/ReviewsPageWrapper';
import { ReviewsPageFeed } from 'pages/ReviewsPage/styled/ReviewsPageFeed';
import { ReviewsPagePreloader } from 'pages/ReviewsPage/styled/ReviewsPagePreloader';
import { ReviewsPageFallback } from 'pages/ReviewsPage/styled/ReviewsPageFallback';
import { ReviewsPagePreview } from 'pages/ReviewsPage/styled/ReviewsPagePreview';

type TProps = {
  hasPreloader: boolean;
  hasFallback: boolean;
  pageFallbackText: string;
  previewReview: null | TReview;
  feedItems: TReview[];
  selectedFeedItemId: null | number;
  handleFeedItemClick(id: number): void;
  handleReviewStatusChanged(id: number, nextStatus: TReviewStatus): void;
  handleReviewNotesChanged(id: number, nextNotes: string): void;
  handleFeedScrolledToBottom(): void;
};

export const ReviewsPageView = (props: TProps) => {
  const {
    hasPreloader,
    hasFallback,
    pageFallbackText,
    previewReview,
    feedItems,
    selectedFeedItemId,
    handleFeedItemClick,
    handleReviewStatusChanged,
    handleReviewNotesChanged,
    handleFeedScrolledToBottom
  } = props;

  return (
    <ReviewsPageWrapper>
      <ReviewsPageFeed>
        {feedItems.length > 0 && selectedFeedItemId !== null && (
          <ReviewsFeed
            items={feedItems}
            selectedItemId={selectedFeedItemId}
            hasPreloader={hasPreloader}
            onItemClick={handleFeedItemClick}
            onScrolledToBottom={handleFeedScrolledToBottom}
          />
        )}
        {feedItems.length === 0 && hasPreloader && <ReviewsPagePreloader />}
        {hasFallback && <ReviewsPageFallback text={pageFallbackText} />}
      </ReviewsPageFeed>
      <ReviewsPagePreview>
        {previewReview !== null && (
          <ReviewPreview
            review={previewReview}
            onReviewStatusChanged={handleReviewStatusChanged}
          />
        )}
        {previewReview !== null && (
          <ReviewNotes
            notes={previewReview.note}
            reviewId={previewReview.id}
            onNotesChanged={handleReviewNotesChanged}
          />
        )}
      </ReviewsPagePreview>
    </ReviewsPageWrapper>
  );
};
