import {
  isToday,
  isYesterday,
  createTimeText,
  createDateText,
  isWithinCurrentYear
} from 'utils/dateUtils';
import { TIntl } from 'types/intl';

export const createChatPreviewDateText = (publishDate: string, intl: TIntl) => {
  const publishDateObj = new Date(publishDate);

  const time = createTimeText(publishDateObj);

  const wasPublishedToday = isToday(publishDateObj);

  if (wasPublishedToday) {
    return time;
  }

  const wasPublishedYesterday = isYesterday(publishDateObj);

  if (wasPublishedYesterday) {
    return intl.yesterday;
  }

  const wasPublishedWithinCurrentYear = isWithinCurrentYear(publishDateObj);

  if (wasPublishedWithinCurrentYear) {
    return `${publishDateObj.getDate()} ${
      intl.predicativeShortMonthNames[publishDateObj.getMonth()]
    }`;
  }
  return createDateText(publishDateObj);
};
