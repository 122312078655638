class ExtendableError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

class ExtendableTypeError extends TypeError {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export class RouterParamError extends ExtendableTypeError {
  constructor(value: string | undefined) {
    if (value) {
      super(`Unexpected router param: ${value}`);
    } else {
      super('Missing router param');
    }
  }
}

export class UnexpectedError extends ExtendableError {
  constructor() {
    super('Unexpected error');
  }
}

export class HTTPResponseError extends ExtendableError {
  public status: number;
  public statusText: string;

  constructor(response: Response) {
    super('HTTP response error');
    this.status = response.status;
    this.statusText = response.statusText;
  }
}

export class HTTPConnectionError extends ExtendableError {
  constructor() {
    super('HTTP connection error');
  }
}

export class NoInternetConnectionError extends ExtendableError {
  constructor() {
    super('No internet connection');
  }
}

export class NotFoundError extends ExtendableError {
  constructor() {
    super('Not found');
  }
}

export class ContractViolationError extends ExtendableTypeError {
  constructor() {
    super('Contract violation error');
  }
}

export class UnreachableError extends ExtendableTypeError {
  constructor(value: never) {
    super(`Unexpected value recieved: ${value}`);
  }
}

export class LoginInUseError extends ExtendableError {
  constructor() {
    super('Login in use error');
  }
}

export class InvalidPasswordError extends ExtendableError {
  constructor() {
    super('Invalid password error');
  }
}

export class AuthorizationError extends Error {
  name: string = 'Authorization Error';
}

export class UserIsBlockedError extends AuthorizationError {
  name: string = 'User is blocked Error';
}

export class TimeConflictError extends ExtendableError {
  constructor() {
    super('Day has conflict oncoming reservation');
  }
}

export class PlaceDeleteError extends ExtendableError {
  constructor() {
    super('Place item has oncoming reservations');
  }
}
