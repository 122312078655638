import { SetUserBlockedStateParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const setUserBlockedState = async (
  params: SetUserBlockedStateParams
): Promise<void> => {
  const { userId, toBlocked } = params;

  await sportingAPI.service.setEmployeeBlock({
    payload: { idUser: userId, blocked: toBlocked }
  });
};
