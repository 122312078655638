import { TApplication, AppDispatch } from 'types/state';

export const processedSportApplicationsThunk = (
  promise: Promise<TApplication[]>,
  meta: number
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'PROCESSED_SPORT_APPLICATIONS_SERVICE',
    status: 'PENDING',
    meta
  });

  return promise
    .then(applications => {
      dispatch({
        name: 'PROCESSED_SPORT_APPLICATIONS_SERVICE',
        status: 'RESOLVED',
        payload: applications,
        meta
      });
    })
    .catch(error => {
      dispatch({
        name: 'PROCESSED_SPORT_APPLICATIONS_SERVICE',
        status: 'REJECTED',
        payload: error,
        meta
      });
    });
};
