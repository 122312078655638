import React from 'react';
import { useAppState } from 'hooks/useAppState';
import { usePlacesService } from './usePlacesService';

export function useBookingPlacesLoadingProcess() {
  const { loadPlaces } = usePlacesService();

  const { placesCache } = useAppState();

  React.useEffect(() => {
    if (placesCache.didInvalidate) {
      loadPlaces();
    }
  }, [loadPlaces, placesCache.didInvalidate]);
}
