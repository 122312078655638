import { customFetch } from 'utils/customFetch';
import { UnexpectedError, UserIsBlockedError } from 'utils/errors';
import { getSportingAuthURL } from 'services/sporting/utils/getSportingURL';
import { appendFormDataWithObjectValues } from 'utils/appendFormDataWithObjectValues';
import { SportingSession } from 'services/sporting/types';

type Params = {
  username: string;
  password: string;
};

export const signIn = async (params: Params): Promise<SportingSession> => {
  const endpointURL = getSportingAuthURL('signIn');

  const body = new FormData();
  appendFormDataWithObjectValues(body, params);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw new UserIsBlockedError();
    }
    throw new UnexpectedError();
  }

  const sportingSession = await response.json();

  return sportingSession;
};
