import React from 'react';

import styles from './CustomSelect.module.scss';

import { multiplyClassName } from '../../../utils/className';

type TProps = {
  children: React.ReactNode;
  popupUp?: boolean;
  withoutArrow?: boolean;
  testId?: string;
};

export const CustomSelectDropDown = ({
  children,
  popupUp,
  withoutArrow,
  testId
}: TProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const { current: element } = ref;

    if (element) {
      const selectedItem = element.querySelector(`[data-selected="true"]`);

      if (selectedItem) {
        selectedItem.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, []);

  return (
    <div
      ref={ref}
      className={multiplyClassName([
        withoutArrow
          ? styles.CustomSelect__DropDownWithoutArrow
          : styles.CustomSelect__DropDown,
        popupUp ? styles.CustomSelect__PopupUp : styles.CustomSelect__PopupDown
      ])}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
