import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { TApplication } from 'types/state';
import { TGetSportApplicationsParams } from 'types/services';
import { sportApplicationsStoreMock } from 'services/mock/utils/sportApplicationsModel';

export const getSportNewApplications = async (
  params: TGetSportApplicationsParams
): Promise<TApplication[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return sportApplicationsStoreMock.getAllNewApplications();
};
