import { getDeviceFlag } from 'utils/mediaQueryUtils';
import { AppEvent } from 'types/appEvent';

export const deviceFlagReducer = (
  state: boolean = getDeviceFlag(),
  event: AppEvent
): boolean => {
  switch (event.name) {
    default:
      return state;
  }
};
