import React from 'react';

import styles from './CompetitionsPreview.module.scss';

import { TCompetitionsPreviewTab } from 'types/state';
import { Bage } from 'components/shared/Bage';
import { useIntlContext } from 'hooks/useIntlContext';
import { UnreachableError } from 'utils/errors';

type TProps = {
  activeTab: TCompetitionsPreviewTab;
  requestsCount?: number;
  onChange(nextTab: TCompetitionsPreviewTab): void;
};

const tabs: TCompetitionsPreviewTab[] = [
  'DESCRIPTION',
  'PARTICIPANTS',
  'NOTES'
];

export const CompetitionsPreviewTabs = (props: TProps) => {
  const { activeTab, requestsCount, onChange } = props;

  const {
    competitionsPreviewTabDescription,
    competitionsPreviewTabParticipants,
    competitionsPreviewTabNotes
  } = useIntlContext();

  const getLocalizedTabLabel = (tab: TCompetitionsPreviewTab) => {
    switch (tab) {
      case 'DESCRIPTION':
        return competitionsPreviewTabDescription;
      case 'PARTICIPANTS':
        return competitionsPreviewTabParticipants;
      case 'NOTES':
        return competitionsPreviewTabNotes;
      default:
        throw new UnreachableError(tab);
    }
  };
  return (
    <div className={styles.CompetitionsPreview__Tabs}>
      {tabs.map(tab => {
        const handleClick = () => {
          onChange(tab);
        };
        const isActive = tab === activeTab;
        return (
          <button
            key={tab}
            className={`${styles.CompetitionsPreview__Tab} ${
              isActive ? styles.CompetitionsPreview__Tab_active : ''
            }`}
            onClick={handleClick}
          >
            {getLocalizedTabLabel(tab)}
            {tab === 'PARTICIPANTS' && !!requestsCount && (
              <Bage
                count={requestsCount}
                className={styles.CompetitionsPreview__Bage}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};
