import { GetMessageTemplatesParams } from 'types/services';
import { MessageTemplate } from 'types/messageTemplate';
import { sportingAPI } from 'services/sporting/api';
import { MESSAGE_TEMPLATES_PAGE_LENGTH } from 'utils/constants';
import { normalizeSportingAnswerDraft } from 'services/sporting/utils/normalizeSportingAnswerDraft';

export const getMessageTemplates = async (
  params: GetMessageTemplatesParams
): Promise<MessageTemplate[]> => {
  const { page } = params;

  const answerDraftPage = await sportingAPI.chat.getAnswerDraftPage({
    payload: {
      page: page - 1,
      size: MESSAGE_TEMPLATES_PAGE_LENGTH,
      sortBy: 'text',
      order: 'ASC'
    }
  });

  return answerDraftPage.content.map(answerDraft =>
    normalizeSportingAnswerDraft(answerDraft).toMessageTemplate()
  );
};
