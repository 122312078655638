import { ServerEvent } from 'types/appEvent/serverEvent';
import { asyncTimeout } from 'utils/asyncTimeout';
import { chatRoomsStoreMock } from 'services/mock/utils/chatRoomsModel';

let count = 0;

const inc = () => {
  count = count + 1;
};

export const getServerEvents = async (): Promise<ServerEvent[]> => {
  await asyncTimeout(10000);

  const { id: roomId, clientInfo } = chatRoomsStoreMock.getAllRooms()[0];

  if (count === 0) {
    inc();

    return [
      {
        name: 'CHAT_MESSAGE_RECIEVED',
        domain: 'CLUB',
        roomId
      }
    ];
  }

  if (count === 1) {
    inc();

    return [
      {
        name: 'USER_STARTED_TYPING',
        userId: clientInfo.id,
        roomId
      }
    ];
  }

  if (count === 2) {
    inc();

    return [
      {
        name: 'USER_STOPPED_TYPING',
        userId: clientInfo.id,
        roomId
      }
    ];
  }

  return [
    {
      name: 'UNKNOWN'
    }
  ];
};
