import React from 'react';

import {
  TCompetitionsArticle,
  TArticeImage,
  TCompetitionDuration
} from 'types/state';
import {
  NEWS_ARTICLE_TITLE_INPUT_MAX_SIZE,
  NEWS_ARTICLE_DESCRIPTION_INPUT_MAX_SIZE,
  COMPETITIONS_ARTICLE_IMAGE_RATIO,
  COMPETITIONS_ARTICLE_CROPPED_IMAGE_MIN_WIDTH,
  COMPETITIONS_ARTICLE_CROPPED_IMAGE_MIN_HEIGHT
} from 'utils/constants';
import { CompetitionDurationInput } from 'containers/CompetitionDurationInput';
import { ImageInput } from 'containers/ImageInput';
import { CompetitionsArticleTemplate } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleTemplate';
import { CompetitionsArticleContent } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleContent';
import { CompetitionsArticleInfo } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleInfo';
import { CompetitionsArticleTextInput } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleTextInput';
import { CompetitionsArticleDuration } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleDuration';
import { CompetitionsArticleTitle } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleTitle';
import { CompetitionsArticleImage } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleImage';
import { CompetitionsArticleDescription } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleDescription';
import { CompetitionsArticleActions } from 'containers/CompetitionsArticle/view/styled/CompetitionsArticleActions';
import { testId } from 'utils/testId';

type TProps = {
  hasEditView: boolean;
  titleInputPlaceholder: string;
  descriptionInputPlaceholder: string;
  durationLabel: string;
  data: TCompetitionsArticle;
  handleInputChange(event: React.ChangeEvent<HTMLTextAreaElement>): void;
  handleImageChange(nextImage: TArticeImage): void;
  handleDurationChange(nextValue: TCompetitionDuration): void;
  handleEdit(): void;
  handleDelete(): void;
};

export const CompetitionsArticleView = (props: TProps) => {
  const {
    titleInputPlaceholder,
    descriptionInputPlaceholder,
    hasEditView,
    data,
    durationLabel,
    handleInputChange,
    handleImageChange,
    handleDurationChange,
    handleEdit,
    handleDelete
  } = props;

  return (
    <CompetitionsArticleTemplate hasEditView={hasEditView}>
      {hasEditView ? (
        <CompetitionsArticleContent testId={testId.COMPETITIONS_ARTICLE_EDITOR}>
          <CompetitionsArticleInfo>
            <CompetitionDurationInput
              value={data.duration}
              onChange={handleDurationChange}
            />
            <CompetitionsArticleTextInput
              autoFocus
              name="title"
              placeholder={titleInputPlaceholder}
              value={data.title}
              onChange={handleInputChange}
              maxLength={NEWS_ARTICLE_TITLE_INPUT_MAX_SIZE}
            />
          </CompetitionsArticleInfo>
          <ImageInput
            value={data.image}
            ratio={COMPETITIONS_ARTICLE_IMAGE_RATIO}
            croppedMinWidth={COMPETITIONS_ARTICLE_CROPPED_IMAGE_MIN_WIDTH}
            croppedMinHeight={COMPETITIONS_ARTICLE_CROPPED_IMAGE_MIN_HEIGHT}
            onChange={handleImageChange}
          />
          <CompetitionsArticleInfo>
            <CompetitionsArticleTextInput
              name="description"
              placeholder={descriptionInputPlaceholder}
              value={data.description}
              onChange={handleInputChange}
              maxLength={NEWS_ARTICLE_DESCRIPTION_INPUT_MAX_SIZE}
            />
          </CompetitionsArticleInfo>
        </CompetitionsArticleContent>
      ) : (
        <CompetitionsArticleContent
          testId={testId.COMPETITIONS_ARTICLE_PREVIEW}
        >
          <CompetitionsArticleInfo>
            <CompetitionsArticleDuration label={durationLabel} />
            <CompetitionsArticleTitle
              label={data.title}
              placeholder={titleInputPlaceholder}
            />
          </CompetitionsArticleInfo>
          <CompetitionsArticleImage src={data.image && data.image.cropped} />
          <CompetitionsArticleInfo>
            <CompetitionsArticleDescription
              label={data.description}
              placeholder={descriptionInputPlaceholder}
            />
          </CompetitionsArticleInfo>
        </CompetitionsArticleContent>
      )}
      {!hasEditView && (
        <CompetitionsArticleActions
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </CompetitionsArticleTemplate>
  );
};
