import React from 'react';

import styles from './ReviewsFeed.module.css';
import { ScrollableShadow } from 'components/shared/ScrollableShadow';

type TProps = {
  children: React.ReactNode;
  onScroll(event: React.SyntheticEvent): void;
};

export const ReviewsFeedContent = ({ children, onScroll }: TProps) => (
  <ScrollableShadow
    className={styles.ReviewsFeed__Content}
    onScroll={onScroll}
    threshold={30}
  >
    {children}
  </ScrollableShadow>
);
