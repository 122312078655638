import { TGetNewsFeedParams } from 'types/services';
import { UnreachableError } from 'utils/errors';
import { TNewsArticle } from 'types/state';
import { normalizeSportingNewsArticle } from 'services/sporting/utils/normalizeSportingNewsArticle';
import { sportingAPI } from 'services/sporting/api';

export const getNewsFeed = async (
  params: TGetNewsFeedParams
): Promise<TNewsArticle[]> => {
  const { page, filter } = params;

  const methodParams = {
    payload: { pageNum: page }
  };

  const promise = (() => {
    switch (filter) {
      case 'all':
        return sportingAPI.service.getAllNews(methodParams);
      case 'published':
        return sportingAPI.service.getPublishedNews(methodParams);
      case 'drafts':
        return sportingAPI.service.getDrafts(methodParams);
      default:
        throw new UnreachableError(filter);
    }
  })();

  const news = await promise;

  return news.map(normalizeSportingNewsArticle.toNewsArticle);
};
