import React from 'react';

import { NewsFeedPreloader } from 'components/NewsFeed/NewsFeedPreloader';
import { NewsFeedItems } from 'components/NewsFeed/NewsFeedItems';
import { NewsFeedScrollable } from 'components/NewsFeed/NewsFeedScrollable';
import { NewsFeedFallback } from 'components/NewsFeed/NewsFeedFallback';

type Props = {
  children: React.ReactNode;
  hasPreloader: boolean;
  hasEmptyFallback: boolean;
  emptyFallbackText: string;
  handleScroll(event: React.SyntheticEvent): void;
};

export const NewsFeedContentView: React.FC<Props> = props => {
  const {
    children,
    handleScroll,
    hasPreloader,
    hasEmptyFallback,
    emptyFallbackText
  } = props;

  return (
    <NewsFeedScrollable onScroll={handleScroll}>
      {!hasEmptyFallback && (
        <NewsFeedItems hasEmptyVeiw={children === null}>
          {children}
          {hasPreloader && <NewsFeedPreloader />}
        </NewsFeedItems>
      )}
      {hasEmptyFallback && <NewsFeedFallback text={emptyFallbackText} />}
    </NewsFeedScrollable>
  );
};
