import { GetMessageTemplatesParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { MessageTemplate } from 'types/messageTemplate';
import { messageTemplateStoreMock } from 'services/mock/utils/messageTemplateModel';

export const getMessageTemplates = async (
  params: GetMessageTemplatesParams
): Promise<MessageTemplate[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return messageTemplateStoreMock.getTemplatesByPage(params.page);
};
