import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { useServices } from 'hooks/useServices';
import { useAsyncController } from 'hooks/useAsyncController';
import { TSetReviewNoteParams } from 'types/services';

type TProps = {
  notes: string;
  reviewId: number;
  onNotesChanged(id: number, nextNotes: string): void;
};

export type TReviewNotesProps = TProps;

export const useRevieNotesState = (props: TProps) => {
  const { notes: initialNotes, onNotesChanged, reviewId } = props;

  const [actualNotes, setActualNotes] = React.useState<string>(initialNotes);

  const { reviewNotesTitle, reviewNotesInputPlaceholder } = useIntlContext();

  const { setReviewNote } = useServices();

  const {
    wrapAsync: wrapReviewNoteUpdate,
    asyncProcess: reviewNoteUpdateProcess
  } = useAsyncController();

  const updateReviewNote = React.useCallback(
    (params: TSetReviewNoteParams) => {
      const promise = setReviewNote(params).then(() => {
        onNotesChanged(params.reviewId, params.note);
      });

      wrapReviewNoteUpdate(promise);
    },
    [onNotesChanged, setReviewNote, wrapReviewNoteUpdate]
  );

  React.useEffect(() => {
    setActualNotes(initialNotes);
  }, [initialNotes, reviewId]);

  // Нормализация состояния
  const onInputValueChange = React.useCallback(
    (nextNotes: string) => {
      setActualNotes(nextNotes);
    },
    [setActualNotes]
  );

  const onInputBlur = React.useCallback(() => {
    updateReviewNote({ note: actualNotes || '', reviewId });
  }, [actualNotes, reviewId, updateReviewNote]);

  const title = reviewNotesTitle;

  const inputValue = actualNotes || '';

  const inputPlaceholder = reviewNotesInputPlaceholder;

  const inputDisabled = reviewNoteUpdateProcess.status === 'PENDING';

  return {
    title,
    inputValue,
    inputPlaceholder,
    inputDisabled,
    onInputValueChange,
    onInputBlur
  };
};
