import { LoginFormError } from 'types/state';
import { InvalidPasswordError, LoginInUseError } from 'utils/errors';

export const getLoginFormErrorFromServiceError = (
  error: Error
): LoginFormError => {
  if (error instanceof InvalidPasswordError) {
    return 'PASSWORD_INVALID';
  }
  if (error instanceof LoginInUseError) {
    return 'LOGIN_IN_USE';
  }

  return 'SERVICE_FAILED';
};
