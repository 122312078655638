import React from 'react';

import styles from './PlaceOption.module.scss';
import { Place, PlaceItem } from '../../../../types/state';
import { PlaceItemOption } from '../PlaceItemOption/PlaceItemOption';
import { Checkbox } from '../../../shared/Checkbox';

type Props = {
  place: Place;
  onPlaceSelect(place: Place): void;
  isPlaceSelected(place: Place): boolean;
  onItemSelect(item: PlaceItem): void;
  isItemSelected(item: PlaceItem): boolean;
  isItemDisabled(item: PlaceItem): boolean;
};
export const PlaceOption = (props: Props) => {
  const {
    place,
    onPlaceSelect,
    isPlaceSelected,
    onItemSelect,
    isItemSelected,
    isItemDisabled
  } = props;

  const isPlaceChecked = isPlaceSelected(place);

  const order = React.useMemo(() => place.orderNumber, [place.orderNumber]);

  return (
    <div className={styles.wrapper} style={{ order }}>
      <div className={styles.titlePanel}>
        <div onClick={() => onPlaceSelect(place)}>
          <Checkbox
            className={styles.checkBox}
            name={'place'}
            checked={isPlaceChecked}
            onChange={() => {}}
          />
        </div>
        <div className={styles.title}>{place.title}</div>
      </div>
      <div className={styles.itemContainer}>
        {place.items.map(placeItem => (
          <PlaceItemOption
            key={placeItem.id}
            placeItem={placeItem}
            onClick={onItemSelect}
            isSelected={isItemSelected}
            isDisabled={isItemDisabled}
          />
        ))}
      </div>
    </div>
  );
};
