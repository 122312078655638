import { SportingServiceScope } from 'services/sporting/types';
import { ServiceScope } from 'types/serviceScope';
import { UnreachableError } from 'utils/errors';

export const normalizeServiceScope = (domain: ServiceScope) => ({
  toSportingServiceScope(): SportingServiceScope {
    switch (domain) {
      case 'CLUB':
        return 'CLUB';
      case 'RESTAURANT':
        return 'RESTARAUNT';
      default:
        throw new UnreachableError(domain);
    }
  }
});
