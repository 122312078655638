import { useSMSCountService } from 'hooks/useSMSCountService';
import { useFaviconUpdater } from 'hooks/useFaviconUpdater';
import { useCountsUpdateProcess } from 'hooks/useCountsUpdateProcess';
import { useUpdateEnabledSectionsService } from 'hooks/useUpdateEnabledSectionsService';
import { useUpdateCurrentUserPasswordService } from 'hooks/useUpdateCurrentUserPasswordService';
import { useServerEventSubscription } from 'hooks/useServerEventSubscription';
import { useVisibilityService } from 'hooks/useVisibilityService';

export const useAuthorizedScreenHooks = () => {
  useSMSCountService();

  useFaviconUpdater();

  useCountsUpdateProcess();

  useUpdateEnabledSectionsService();

  useUpdateCurrentUserPasswordService();

  useServerEventSubscription();

  useVisibilityService();
};
