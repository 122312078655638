import React from 'react';

import { ThreeColumnsPageLayout } from 'design/threeColumnsPageLayout';
import { Delimiter } from 'design/delimiter';
import { ChatsPageNavigation } from 'pages/ChatsPage/ChatsPageNavigation';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useChatDomain } from 'hooks/chats/useChatDomain';
import { ChatsPageContent } from 'pages/ChatsPage/ChatsPageContent';
import { useActiveChatRoom } from 'hooks/chats/useActiveChatRoom';
import { MessageTemplates } from 'containers/MessageTemplates';
import { useKeyDown } from 'hooks/useKeyDown';

export const ChatsPage = () => {
  const dispatch = useAppDispatch();

  const domain = useChatDomain();

  const { id: activeRoomId } = useActiveChatRoom();

  const handleEscapeClick = React.useCallback(() => {
    dispatch({
      name: 'CHATS_PAGE__ESCAPE_CLICKED'
    });
  }, [dispatch]);

  React.useLayoutEffect(() => {
    dispatch({
      name: 'CHATS_PAGE__OPENED',
      domain
    });

    return () => {
      dispatch({
        name: 'CHATS_PAGE__CLOSED',
        domain
      });
    };
  }, [dispatch, domain]);

  useKeyDown({
    Escape: handleEscapeClick
  });

  return (
    <ThreeColumnsPageLayout
      delimiter={<Delimiter />}
      firstColumn={<ChatsPageNavigation />}
      secondColumn={
        activeRoomId !== null && (
          <ChatsPageContent serviceScope={domain} roomId={activeRoomId} />
        )
      }
      thirdColumn={<MessageTemplates />}
    />
  );
};
