import { useParams } from 'react-router';

import { TCompetitionsFilter } from 'types/state';
import { RouterParamError } from 'utils/errors';

type RouterParams = {
  filter: string;
};

export const useCompetitionsPageFilter = (): TCompetitionsFilter => {
  const { filter } = useParams<RouterParams>();

  switch (filter) {
    case 'past':
    case 'upcoming':
      return filter;
    default:
      throw new RouterParamError(filter);
  }
};
