import React from 'react';

import { ScrollableShadow } from 'components/shared/ScrollableShadow';
import { ChatsPagePreview } from 'pages/ChatsPage/ChatsPagePreview';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useChatDomain } from 'hooks/chats/useChatDomain';
import {
  useChatRoomsCollection,
  useChatRoomIds
} from 'hooks/chats/useChatsState';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { createCollectionCacheSelectors } from 'utils/collectionCacheUtils';
import { getScrollLeftPx } from 'utils/getScrollLeftPx';
import { CHAT_ROOMS_FEED_SCROLL_LEFT_TO_LOAD_MORE } from 'utils/constants';

export const ChatsPageFeed = () => {
  const dispatch = useAppDispatch();

  const domain = useChatDomain();

  const chatRoomIds = useChatRoomIds();

  const roomsCollection = useChatRoomsCollection();

  const chatRoomIdsSelectors = React.useMemo(
    () => createListCacheSelectors(chatRoomIds),
    [chatRoomIds]
  );

  const rooms = React.useMemo(() => {
    const roomIds = chatRoomIdsSelectors.selectAllItems();

    return createCollectionCacheSelectors(roomsCollection).selectItemsStrict(
      roomIds
    );
  }, [chatRoomIdsSelectors, roomsCollection]);

  const noInvalidatedPages =
    chatRoomIdsSelectors.selectNextInvalidatedPage() === null;

  const noMorePages = chatRoomIdsSelectors.hasEmptyPage();

  const handleScroll = React.useCallback(
    (event: React.SyntheticEvent) => {
      if (noInvalidatedPages && !noMorePages) {
        const { target } = event;

        if (target instanceof Element) {
          const scrollLeftPx = getScrollLeftPx(target);

          if (scrollLeftPx < CHAT_ROOMS_FEED_SCROLL_LEFT_TO_LOAD_MORE) {
            dispatch({
              name: 'CHATS_PAGE_FEED__SCROLLED_TO_BOTTOM',
              domain
            });
          }
        }
      }
    },
    [dispatch, domain, noInvalidatedPages, noMorePages]
  );

  return (
    <ScrollableShadow threshold={30} onScroll={handleScroll}>
      {rooms.map(room => (
        <ChatsPagePreview key={room.id} room={room} />
      ))}
    </ScrollableShadow>
  );
};
