import React from 'react';

import { useContextStrict } from 'hooks/useContextStrict';
import { ServicesContext } from 'contexts/ServicesContext';
import { checkCompetitionsArticleIsReadyForSubmit } from 'utils/articleUtils';
import { TCompetitionsArticle } from 'types/state';
import { ARTICLE_SUBMIT_DELAY } from 'utils/constants';
import { delayedAsyncFunction } from 'utils/delayedAsyncFunction';
import { useAsyncController } from 'hooks/useAsyncController';
import { TSubmitCompetitionsArticleParams } from 'types/services';

type TProps = {
  data: TCompetitionsArticle;
  onCancel: () => void;
  onSumbited: (id: number) => void;
};

export const useCompetitionsPreviewFooterState = (props: TProps) => {
  const submitedAsDraftRef = React.useRef<null | boolean>(null);

  const { data: article, onSumbited, onCancel } = props;

  const { submitCompetitionsArticle: submitSrevice } = useContextStrict(
    ServicesContext
  );

  const submitWithDelay = React.useMemo(
    () => delayedAsyncFunction(submitSrevice, ARTICLE_SUBMIT_DELAY),
    [submitSrevice]
  );

  const {
    wrapAsync: wrapSubmitting,
    asyncProcess: submittingProcess
  } = useAsyncController();

  const submit = React.useCallback(
    (params: TSubmitCompetitionsArticleParams) => {
      const promise = submitWithDelay(params).then(value => {
        onSumbited(value.id);
      });

      wrapSubmitting(promise);
    },
    [onSumbited, submitWithDelay, wrapSubmitting]
  );

  // Normalization

  const handleSubmit = React.useCallback(() => {
    submitedAsDraftRef.current = false;
    submit({ data: article, saveAsDraft: false });
  }, [article, submit]);

  const handleSubmitDraft = React.useCallback(() => {
    submitedAsDraftRef.current = true;
    submit({ data: article, saveAsDraft: true });
  }, [article, submit]);

  const handleCancel = onCancel;

  const isSubmitButtonDisabled = !checkCompetitionsArticleIsReadyForSubmit(
    article
  );

  const hasSubmitButtonPreloader =
    submittingProcess.status === 'PENDING' &&
    submitedAsDraftRef.current === false;

  const hasSubmitDraftButtonPreloader =
    submittingProcess.status === 'PENDING' &&
    submitedAsDraftRef.current === true;

  const hasSubmitDraftButton = React.useMemo(
    () =>
      article.isDraft &&
      checkCompetitionsArticleIsReadyForSubmit(article, { asDraft: true }),
    [article]
  );

  return {
    isSubmitButtonDisabled,
    hasSubmitDraftButton,
    hasSubmitDraftButtonPreloader,
    hasSubmitButtonPreloader,
    handleSubmit,
    handleSubmitDraft,
    handleCancel
  };
};
