import React from 'react';
import { SettingsFormLegend } from 'components/SettingsForm/SettingsFormLegend';
import { SettingsFormFieldset } from 'components/SettingsForm/SettingsFormFieldset';
import { CustomInput } from 'components/shared/CustomInput';
import { SettingsFormFooter } from 'components/SettingsForm/SettingsFormFooter';
import { RectangleButton } from 'components/shared/RectangleButton';
import { SettingsFormWrapper } from 'components/SettingsForm/SettingsFormWrapper';
import { useIntlContext } from 'hooks/useIntlContext';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useUserInfoStrict } from 'hooks/useUserInfoCache';
import {
  useLoginEditorFormError,
  useLoginEditorFormErrorTextForLogin,
  useLoginEditorFormErrorTextForPassword,
  useLoginEditorFormValues,
  useLoginFormBusy,
  useLoginFormSubmitDidSucceed
} from 'hooks/setings/useLoginEditorState';
import { useUpdateCurrentUserLoginService } from 'hooks/setings/useUpdateCurrentUserLoginService';

export const LoginEditorForm = () => {
  useUpdateCurrentUserLoginService();
  const dispatch = useAppDispatch();
  const userInfo = useUserInfoStrict();
  const { loginEditorForm: loginFormIntl } = useIntlContext();

  const loginInputRef = React.useRef<null | HTMLInputElement>(null);
  const passwordInputRef = React.useRef<null | HTMLInputElement>(null);

  const handleSubmit = React.useCallback(() => {
    dispatch({
      name: 'LOGIN_EDITOR_FORM__SUBMIT'
    });
  }, [dispatch]);

  const handleCancel = React.useCallback(() => {
    dispatch({
      name: 'LOGIN_EDITOR_FORM__CANCEL',
      oldValue: userInfo.login
    });
  }, [dispatch, userInfo.login]);

  const handleLoginChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        name: 'LOGIN_EDITOR_FORM__LOGIN_INPUT_CHANGE',
        value: event.target.value
      });
    },
    [dispatch]
  );

  const handlePasswordChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        name: 'LOGIN_EDITOR_FORM__PASSWORD_INPUT_CHANGE',
        value: event.target.value
      });
    },
    [dispatch]
  );

  const values = useLoginEditorFormValues();

  const busy = useLoginFormBusy();

  const submitDidSucceed = useLoginFormSubmitDidSucceed();

  const empty = !values.login && !values.password;

  const loginChanged = React.useMemo(() => {
    return userInfo.login !== values.login;
  }, [userInfo.login, values.login]);

  const submitButtonLabel =
    submitDidSucceed && !loginChanged
      ? loginFormIntl.submitDidSucceedLabel
      : loginFormIntl.submitLabel;

  const hasCancelButton = loginChanged && !busy;

  const hasFooter = (!empty && loginChanged) || submitDidSucceed;

  const submitButtonDisabled = busy || (submitDidSucceed && !loginChanged);

  const submitButtonHasPreloader = busy;

  const fieldsetDisabled = busy;

  const formError = useLoginEditorFormError();

  const loginErrorText = useLoginEditorFormErrorTextForLogin();
  const passwordErrorText = useLoginEditorFormErrorTextForPassword();

  // Устанавливаем начальное значение для логина
  React.useEffect(() => {
    const { current: loginInputElement } = loginInputRef;
    if (loginInputElement && loginInputElement.value !== userInfo.login) {
      dispatch({
        name: 'LOGIN_EDITOR_FORM__LOGIN_INPUT_CHANGE',
        value: userInfo.login
      });
    }
  }, [dispatch, userInfo.login]);

  React.useEffect(() => {
    const { current: loginInputElement } = loginInputRef;
    const { current: passwordInputElement } = passwordInputRef;
    if (formError && loginInputElement && passwordInputElement) {
      if (formError === 'LOGIN_IS_EMPTY' || formError === 'LOGIN_IN_USE') {
        loginInputElement.focus();
      }
      if (
        formError === 'PASSWORD_IS_EMPTY' ||
        formError === 'PASSWORD_INVALID'
      ) {
        passwordInputElement.focus();
      }
    }
  }, [formError]);

  return (
    <SettingsFormWrapper onSubmit={handleSubmit}>
      <SettingsFormFieldset disabled={fieldsetDisabled}>
        <SettingsFormLegend
          titleText={loginFormIntl.loginInputLabel}
          errorText={loginErrorText}
        />
        <CustomInput
          ref={loginInputRef}
          type="text"
          name="login"
          placeholder={loginFormIntl.loginInputPlaceholder}
          value={values.login}
          onChange={handleLoginChange}
        />
        <br />
        {hasFooter && (
          <>
            <SettingsFormLegend
              titleText={loginFormIntl.passwordInputLabel}
              errorText={passwordErrorText}
            />
            <CustomInput
              ref={passwordInputRef}
              type="password"
              name="password"
              placeholder={loginFormIntl.passwordInputPlaceholder}
              value={values.password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
            />
          </>
        )}
      </SettingsFormFieldset>
      {hasFooter && (
        <SettingsFormFooter>
          {hasCancelButton && (
            <RectangleButton
              type="button"
              label={loginFormIntl.cancelLabel}
              styleType="secondary"
              onClick={handleCancel}
            />
          )}
          <RectangleButton
            label={submitButtonLabel}
            hasPreloader={submitButtonHasPreloader}
            styleType="primary"
            isDisabled={submitButtonDisabled}
          />
        </SettingsFormFooter>
      )}
    </SettingsFormWrapper>
  );
};
