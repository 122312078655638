import React from 'react';

import { TReviewStatus } from 'types/state';
import { useReviewsFeed } from 'hooks/useReviewsFeed';
import { useIntlContext } from 'hooks/useIntlContext';
import { useBrowserTabTitleUpdater } from 'hooks/useBrowserTabTitleUpdater';
import { ReviewsPageView } from 'pages/ReviewsPage/ReviewsPageView';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const ReviewsPage = () => {
  // Обноваляет заоголовок таба браузера.
  useBrowserTabTitleUpdater();

  const [selectedFeedItemId, setSelectedFeedItemId] = React.useState<
    null | number
  >(null);

  const { reviewsPageFallback } = useIntlContext();

  const { reviewsFeed, reviewsProcess, loadNextPage } = useReviewsFeed();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!!reviewsFeed && reviewsFeed[0] && !selectedFeedItemId) {
      setSelectedFeedItemId(reviewsFeed[0].id);
    }
  }, [reviewsFeed, setSelectedFeedItemId, selectedFeedItemId]);

  React.useEffect(() => {
    dispatch({
      name: 'ReviewsPageEvent__Mounted'
    });
  }, [dispatch]);

  // Нормализация состояния

  const handleFeedItemClick = React.useCallback(
    (id: number) => {
      setSelectedFeedItemId(id);
    },
    [setSelectedFeedItemId]
  );

  const handleFeedScrolledToBottom = React.useCallback(() => {
    loadNextPage();
  }, [loadNextPage]);

  const hasPreloader =
    reviewsProcess.status === 'IDLE' || reviewsProcess.status === 'PENDING';

  const hasFallback = !hasPreloader && reviewsFeed.length === 0;

  const pageFallbackText = reviewsPageFallback;

  const previewReview = React.useMemo(() => {
    if (reviewsFeed && selectedFeedItemId) {
      return reviewsFeed.find(({ id }) => id === selectedFeedItemId) || null;
    }
    return null;
  }, [selectedFeedItemId, reviewsFeed]);

  const handleReviewStatusChanged = React.useCallback(
    (reviewId: number, newStatus: TReviewStatus) => {
      dispatch({
        name: 'ReviewsPageEvent__ReviewStatusChanged',
        reviewId,
        newStatus
      });
    },
    [dispatch]
  );

  const handleReviewNotesChanged = React.useCallback(
    (reviewId: number, newNotes: string) => {
      dispatch({
        name: 'ReviewsPageEvent__ReviewNotesUpdated',
        reviewId,
        newNotes
      });
    },
    [dispatch]
  );

  const feedItems = reviewsFeed;

  const viewProps = {
    hasPreloader,
    hasFallback,
    pageFallbackText,
    previewReview,
    feedItems,
    selectedFeedItemId,
    handleFeedItemClick,
    handleReviewStatusChanged,
    handleReviewNotesChanged,
    handleFeedScrolledToBottom
  };

  return <ReviewsPageView {...viewProps} />;
};
