import { TAddWaitersParams } from '../../../types/services';
import { Waiter } from '../../../types/state';
import { sportingAPI } from '../api';

export const addWaiters = async (
  params: TAddWaitersParams
): Promise<Waiter[]> => {
  const { fio } = params;

  const waiters = await sportingAPI.booking.addWaiters({
    payload: {
      fio
    }
  });

  return waiters;
};
