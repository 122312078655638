import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { ModalPortal } from 'components/shared/Modal';
import { ModalWindow } from 'components/Modal/ModalWindow';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useDeleteUserModalBusy } from 'hooks/useDeleteUserModalBusy';
import { RectangleButton } from 'components/shared/RectangleButton';

type Props = {
  userId: number;
};

export const DeleteUserModal = (props: Props) => {
  const { userId } = props;

  const dispatch = useAppDispatch();

  const {
    deleteUserModal: {
      title,
      description,
      submitButtonLabel,
      cancelButtonLabel
    }
  } = useIntlContext();

  const busy = useDeleteUserModalBusy(userId);

  const onCancel = React.useCallback(() => {
    dispatch({
      name: 'DELETE_USER_MODAL__CANCEL',
      userId
    });
  }, [dispatch, userId]);

  const onSubmit = React.useCallback(() => {
    dispatch({
      name: 'DELETE_USER_MODAL__SUBMIT',
      userId
    });
  }, [dispatch, userId]);

  return (
    <ModalPortal>
      <ModalWindow>
        <ModalBody title={title} description={description} />
        <ModalFooter>
          <RectangleButton
            styleType={'secondary'}
            isDisabled={busy}
            label={cancelButtonLabel}
            onClick={onCancel}
          />
          <RectangleButton
            styleType={'primary'}
            hasPreloader={busy}
            label={submitButtonLabel}
            onClick={onSubmit}
          />
        </ModalFooter>
      </ModalWindow>
    </ModalPortal>
  );
};
