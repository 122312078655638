import { TApplication } from 'types/state';
import { sportingAPI } from 'services/sporting/api';
import { normalizeSportingClaim } from 'services/sporting/utils/normalizeSportingClaim';

export const getSportNewApplications = async (): Promise<TApplication[]> => {
  const claims = await sportingAPI.service.getNewShootingClaims();

  return claims.map(claim =>
    normalizeSportingClaim.toApplication(claim, 'SPORT_NEW')
  );
};
