import { AppEvent } from 'types/appEvent';

export const phoneNumbersReducer = (
  state: null | string[],
  event: AppEvent
): null | string[] => {
  switch (event.name) {
    case 'PHONE_NUMBERS_SERVICE': {
      if (event.status === 'RESOLVED') {
        return event.payload;
      }

      return state;
    }
    default:
      return state;
  }
};
