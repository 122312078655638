import { EditorState } from 'types/util';

export const patchEditorState = <FormValuesType, FormErrorType>(
  state: EditorState<FormValuesType, FormErrorType>
) => ({
  done() {
    return state;
  },
  setFormValues(nextFormValues: FormValuesType) {
    return patchEditorState({
      ...state,
      formValues: nextFormValues
    });
  },
  setFormError(error: FormErrorType) {
    return patchEditorState({
      ...state,
      formError: error
    });
  },
  setSubmited(nextValue: boolean) {
    return patchEditorState({
      ...state,
      submited: nextValue
    });
  },
  setSubmitDidSucceed(nextValue: boolean) {
    return patchEditorState({
      ...state,
      submitDidSucceed: nextValue
    });
  },
  clearFormError() {
    const nextState = {
      ...state
    };

    delete nextState.formError;

    return patchEditorState(nextState);
  }
});
