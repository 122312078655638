import React from 'react';

import './EmployeeList.scss';
import { testId } from 'utils/testId';
import { EmployeeGroup } from '../EmployeeGroup/EmployeeGroup';
import { useIntlContext } from 'hooks/useIntlContext';
import { useMediaFlags } from 'hooks/useMediaFlags';
import { ApplicationsTableHeader } from 'components/ApplicationsTable/ApplicationsTableHeader';
import { ApplicationsTableBody } from 'components/ApplicationsTable/ApplicationsTableBody';
import { useUserToDeleteId } from 'hooks/useUserToDeleteId';
import { useUserToBlockedState } from 'hooks/employee/useUserToBlockedState';
import { useEmployeeToEdit } from 'hooks/employee/useEmployeeToEdit';
import { EmployeeEditModal } from 'containers/EmployeeEditModal/EmployeeEditModal';
import { DeleteUserModal } from 'containers/DeleteUserModal';
import { EmployeeBlockedModal } from 'containers/EmployeeBlockedModal/EmployeeBlockedModal';
import { getAvailableRoleForView } from 'utils/employee/getAvailableRole';
import { useUserInfoStrict } from 'hooks/useUserInfoCache';
import { UserInfo, UserRole } from 'types/state';

type Props = {
  users: UserInfo[];
  isEditable(role: UserRole): boolean;
  onCreateItem(role: UserRole): void;
  onChangeItemPhoneNumber(userId: number, phoneNumber: string): void;
  onEditItem(user: UserInfo): void;
  onBlockItem(user: UserInfo): void;
  onDeleteItem(userId: number): void;
};

export const EmployeeList = (props: Props) => {
  const { usersTable: label } = useIntlContext();

  const { isDesktopL, isDesktopXL } = useMediaFlags();

  const currentUserInfo = useUserInfoStrict();

  const headerShowCondition = React.useMemo(() => isDesktopL || isDesktopXL, [
    isDesktopL,
    isDesktopXL
  ]);

  const employeeToEdit = useEmployeeToEdit();
  const userToBlockedState = useUserToBlockedState();
  const userToDeleteId = useUserToDeleteId();

  const availableRoleForView = React.useMemo(
    () => getAvailableRoleForView(currentUserInfo.role),
    [currentUserInfo.role]
  );

  const memoizedEmployeeGroupsItems = React.useMemo(
    () =>
      availableRoleForView.map((role, index) => {
        const usersByRole = props.users.filter(user => role === user.role);
        return (
          <EmployeeGroup
            key={index}
            role={role}
            users={usersByRole}
            isEditable={props.isEditable(role)}
            onCreate={() => props.onCreateItem(role)}
            onChangePhoneNumber={props.onChangeItemPhoneNumber}
            onEditItem={props.onEditItem}
            onBlockItem={props.onBlockItem}
            onDeleteItem={props.onDeleteItem}
          />
        );
      }),
    [availableRoleForView, props]
  );

  return (
    <div>
      {headerShowCondition && (
        <ApplicationsTableHeader testId={testId.APPLICATIONS_PAGE_TABLE_HEADER}>
          <div className={'employeeListHeader__wrapper'}>
            <div className={'employeeListHeader__columnName'}>
              {label.usersTableColumnName}
            </div>
            <div className={'employeeListHeader__columnNumber'}>
              {label.usersTableColumnNumber}
            </div>
            <div className={'employeeListHeader__columnLogin'}>
              {label.usersTableColumnLogin}
            </div>
          </div>
        </ApplicationsTableHeader>
      )}
      <ApplicationsTableBody testId={testId.APPLICATIONS_PAGE_TABLE_BODY}>
        <div className={'employeeListBody__wrapper'}>
          {memoizedEmployeeGroupsItems}
        </div>
      </ApplicationsTableBody>
      {employeeToEdit && (
        <EmployeeEditModal key={employeeToEdit.id} user={employeeToEdit} />
      )}
      {userToBlockedState.formValues.userId > 0 && <EmployeeBlockedModal />}
      {userToDeleteId && <DeleteUserModal userId={userToDeleteId} />}
    </div>
  );
};
