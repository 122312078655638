import React from 'react';

import './EmployeeFormLegend.scss';

type Props = {
  children: React.ReactNode;
  titleText: string;
  errorText?: string;
};

export const EmployeeFormLegend: React.FC<Props> = props => {
  const { children, titleText, errorText } = props;

  const errorExist = errorText !== undefined;

  return (
    <div className={'employeeForm__legend'}>
      <div
        className={'employeeForm__legendTitle' + (errorExist ? ' error' : '')}
      >
        {errorExist ? errorText : titleText}
      </div>
      {children}
    </div>
  );
};
