import React from 'react';
import { MessageDeliveredIcon } from 'svg/16x16/message-delivered';
import { MessageReadIcon } from 'svg/16x16/message-read';

type Props = {
  delivered?: boolean;
  read?: boolean;
};

const wrapperStyle: React.CSSProperties = {
  position: 'absolute',
  right: '4px',
  bottom: '4px'
};

export const MessageStatusIndicator = (props: Props) => {
  const { read, delivered } = props;

  if (read) {
    return (
      <div style={wrapperStyle}>
        <MessageReadIcon />
      </div>
    );
  }

  if (delivered) {
    return (
      <div style={wrapperStyle}>
        <MessageDeliveredIcon />
      </div>
    );
  }

  return null;
};
