import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';
import React from 'react';
import { AuthorizationError } from '../../utils/errors';
import { ClientInfo } from '../../types/client';
import { TFindUsersParams } from 'types/services';

export const useFindClientService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const {
    wrapAsync: wrap,
    asyncProcess: pullingProcess,
    reset
  } = useAsyncController<ClientInfo[]>();

  const findUsers = React.useCallback(
    (params: TFindUsersParams) => {
      appDispatch({
        name: 'FIND_CLIENT_SERVICE',
        status: 'PENDING'
      });

      const promise = services.findUsers(params);

      promise
        .then(result => {
          appDispatch({
            name: 'FIND_CLIENT_SERVICE',
            status: 'RESOLVED',
            payload: result
          });
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }

          appDispatch({
            name: 'FIND_CLIENT_SERVICE',
            status: 'REJECTED',
            payload: error
          });
        });

      wrap(promise);
    },
    [appDispatch, services, wrap]
  );
  return { findUsers, pullingProcess, reset };
};
