import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAsyncController } from './useAsyncController';
import { MembersPageData } from 'types/member';

export const useMembersPageDataService = () => {
  const services = useServices();

  const {
    wrapAsync: wrapPulling,
    asyncProcess: pullingProcess,
    reset
  } = useAsyncController<MembersPageData>();

  const pull = React.useCallback(
    (cardNumber: string) => {
      const promise = services.getMembersPageData(cardNumber);

      wrapPulling(promise);
    },
    [services, wrapPulling]
  );

  return { pullingProcess, pull, reset };
};
