import { AppEvent } from 'types/appEvent';
import { UserToBlockedState } from 'types/state';

export const userToBlockedStateReducer = (
  state: UserToBlockedState,
  event: AppEvent
): UserToBlockedState => {
  switch (event.name) {
    case 'EMPLOYEE_ITEM__BLOCKED': {
      return {
        formError: undefined,
        formValues: {
          userId: event.userId,
          toBlocked: !event.blockedState
        },
        submited: false,
        submitDidSucceed: false
      };
    }
    case 'EMPLOYEE_BLOCKED_MODAL__CANCEL': {
      return {
        formError: undefined,
        formValues: {
          userId: -1,
          toBlocked: false
        },
        submited: false,
        submitDidSucceed: false
      };
    }
    case 'EMPLOYEE_BLOCKED_MODAL__SUBMIT': {
      return {
        ...state,
        submited: true
      };
    }
    case 'USER_TO_BLOCKED_SERVICE': {
      if (event.status === 'PENDING') {
        return state;
      }
      if (event.status === 'RESOLVED') {
        return {
          ...state,
          formValues: {
            userId: -1,
            toBlocked: false
          },
          submitDidSucceed: true
        };
      }
      if (event.status === 'REJECTED') {
        return {
          ...state,
          formError: 'SERVICE_FAILED',
          submited: false,
          submitDidSucceed: false
        };
      }

      return state;
    }
    default:
      return state;
  }
};
