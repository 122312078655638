import React from 'react';

import { useToggler } from 'hooks/useToggler';
import { TCompetitionsArticle, TCompetitionsPreviewTab } from 'types/state';
import { useCompetitionRequestsCount } from 'hooks/useCompetitionRequestsCount';

type TProps = {
  article: TCompetitionsArticle;
  activeTab: TCompetitionsPreviewTab;
  hasEditView: boolean;
  onChange: (nextData: TCompetitionsArticle) => void;
  onDeleted: () => void;
  onSumbited: (id: number) => void;
  onEdit: () => void;
  onCancelEdit: () => void;
  onTabClick: (nextTab: TCompetitionsPreviewTab) => void;
};

export const useCompetitionsPreviewState = (props: TProps) => {
  const {
    article,
    activeTab,
    hasEditView,
    onChange,
    onSumbited,
    onDeleted,
    onEdit,
    onCancelEdit,
    onTabClick
  } = props;

  const requestsCount = useCompetitionRequestsCount(article.id) || undefined;

  // Логика открытия / скрытия модалки удаления
  const [hasDeleteView, toggleHasDeleteView] = useToggler(false);

  // Normalization

  const handleActiveTabChange = onTabClick;

  const handleChange = onChange;

  const handleDelete = toggleHasDeleteView;

  const handleCancelDelete = toggleHasDeleteView;

  const handleDeleted = React.useCallback(() => {
    onDeleted();
    toggleHasDeleteView();
  }, [onDeleted, toggleHasDeleteView]);

  const handleCancelEdit = onCancelEdit;

  const handleEdit = onEdit;

  const handleSubmited = onSumbited;

  const hasDescriptionView = activeTab === 'DESCRIPTION';

  const hasParticipantsView = activeTab === 'PARTICIPANTS';

  const hasNotesView = activeTab === 'NOTES';

  return {
    hasEditView,
    hasDeleteView,
    hasDescriptionView,
    hasParticipantsView,
    hasNotesView,
    article,
    activeTab,
    requestsCount,
    handleEdit,
    handleCancelEdit,
    handleDelete,
    handleCancelDelete,
    handleDeleted,
    handleChange,
    handleSubmited,
    handleActiveTabChange
  };
};
