import { GetPersistedSessionService } from 'types/services';
import { createSessionUnauthorized } from 'utils/createSession';
import { sessionModel } from '../utils/sessonModel';

export const getPersistedSession: GetPersistedSessionService = async () => {
  const sportingSession = sessionModel.get();

  if (sportingSession === null) {
    return createSessionUnauthorized();
  }

  return sportingSession;
};
