import React from 'react';

import styles from './membersPageContent.module.css';

type Props = {
  label: React.ReactNode;
  body: React.ReactNode;
};

export const MembersPageContentLayout: React.FC<Props> = ({ label, body }) => (
  <div className={styles.MembersPageContent__Wrapper}>
    <div className={styles.MembersPageContent__LabelSlot}>{label}</div>
    <div className={styles.MembersPageContent__BodySlot}>{body}</div>
  </div>
);
