import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { authorizedRoutes } from 'utils/authorizedRoutes';
import { ApplicationsPage } from 'pages/ApplicationsPage';
import { NewsPage } from 'pages/NewsPage';
import { CompetitionsPage } from 'pages/CompetitionsPage';
import { ReviewsPage } from 'pages/ReviewsPage';
import { testId } from 'utils/testId';
import { Navigation } from 'containers/Navigation';
import { AuthorizedScreenTemplate } from 'containers/AuthorizedScreen/styled/AuthorizedScreenTemplate';
import { AuthorizedScreenSidebar } from 'containers/AuthorizedScreen/styled/AuthorizedScreenSidebar';
import { AuthorizedScreenContent } from 'containers/AuthorizedScreen/styled/AuthorizedScreenContent';
import { MobileHeader } from 'components/MobileHeader';
import { useMediaFlags } from 'hooks/useMediaFlags';
import { useAuthorizedScreenHooks } from 'hooks/useAuthorizedScreenHooks';
import { SettingsPage } from 'pages/SettingsPage';
import { useDefaultRoute } from 'hooks/useDefaultRoute';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ChatsPage } from 'pages/ChatsPage';
import { MembersPage } from 'pages/MembersPage';
import { useUserRole } from 'hooks/useUserRole';
import { UsersPage } from 'pages/UsersPage';
import { NoActiveSectionsPage } from 'pages/NoActiveSectionsPage';
import { BookingPage } from '../../pages/BookingPage';
import { ToastsManager } from 'containers/ToastsManager';
import { BookingReservationModal } from 'components/BookingReservationModal/BookingReservationModal';

export const AuthorizedScreen = () => {
  useAuthorizedScreenHooks();

  const dispatch = useAppDispatch();

  const handleContentScrolledToBottom = React.useCallback(() => {
    dispatch({
      name: 'AUTHORIZED_SCREEN__CONTENT_SCROLLED_TO_BOTTOM'
    });
  }, [dispatch]);

  const { isMobile } = useMediaFlags();

  const enabledSections = useEnabledSectionsStrict();

  const defaultRoute = useDefaultRoute();

  const userRole = useUserRole();

  const hasApplicationsRestaurantRoute = enabledSections.includes(
    'applicationsRestaurant'
  );

  const hasChatsRestaurantRoute = enabledSections.includes('chatsRestaurant');

  const hasBookingRestaurantRoute = enabledSections.includes(
    'bookingRestaurant'
  );

  const hasApplicationsSportRoute = enabledSections.includes(
    'applicationsSport'
  );

  const hasChatsSportRoute = enabledSections.includes('chatsSport');

  const hasBookingClubRoute = enabledSections.includes('bookingClub');

  const hasCompetitionsRoute = enabledSections.includes('competitions');

  const hasMembersRoute = enabledSections.includes('members');

  const hasUsersRoute = enabledSections.includes('users');

  const hasNewsRoute =
    (userRole === 'ADMINISTRATOR' || userRole === 'MANAGER') &&
    enabledSections.includes('news');

  const hasFeedbackRoute =
    (userRole === 'ADMINISTRATOR' || userRole === 'MANAGER') &&
    enabledSections.includes('feedback');

  const hasSettingsRoute = userRole !== 'SECURITY';

  const hasNoActiveSectionsRoute = userRole === 'SECURITY';

  return React.useMemo(
    () => (
      <AuthorizedScreenTemplate testId={testId.AUTHORIZED_SCREEN}>
        {isMobile && <MobileHeader />}
        <AuthorizedScreenSidebar>
          <Navigation />
        </AuthorizedScreenSidebar>
        <AuthorizedScreenContent
          onScrolledToBottom={handleContentScrolledToBottom}
        >
          <Switch>
            {hasApplicationsRestaurantRoute && (
              <Route path={authorizedRoutes.applicationsRestaurantPattern()}>
                <ApplicationsPage />
              </Route>
            )}

            {hasChatsRestaurantRoute && (
              <Route path={authorizedRoutes.chatsRestaurantPattern()}>
                <ChatsPage />
              </Route>
            )}

            {hasBookingRestaurantRoute && (
              <Route path={authorizedRoutes.bookingRestaurant()}>
                <BookingPage location="RESTAURANT" />
              </Route>
            )}

            {hasApplicationsSportRoute && (
              <Route path={authorizedRoutes.applicationsSportPattern()}>
                <ApplicationsPage />
              </Route>
            )}

            {hasChatsSportRoute && (
              <Route path={authorizedRoutes.chatsSportPattern()}>
                <ChatsPage />
              </Route>
            )}

            {hasBookingClubRoute && (
              <Route path={authorizedRoutes.bookingClub()}>
                <BookingPage location="CLUB" />
              </Route>
            )}

            {hasNewsRoute && (
              <Route path={authorizedRoutes.newsPattern()}>
                <NewsPage />
              </Route>
            )}

            {hasCompetitionsRoute && (
              <Route path={authorizedRoutes.competitionsPattern()}>
                <CompetitionsPage />
              </Route>
            )}

            {hasFeedbackRoute && (
              <Route path={authorizedRoutes.feedbackPattern()}>
                <ReviewsPage />
              </Route>
            )}

            {hasMembersRoute && (
              <Route path={authorizedRoutes.membersPattern()}>
                <MembersPage />
              </Route>
            )}

            {hasNewsRoute && (
              <Route path={authorizedRoutes.newsRoot()}>
                <Redirect to={authorizedRoutes.news()} />
              </Route>
            )}

            {hasCompetitionsRoute && (
              <Route path={authorizedRoutes.competitionsRoot()}>
                <Redirect to={authorizedRoutes.competitions()} />
              </Route>
            )}

            {hasUsersRoute && (
              <Route path={authorizedRoutes.usersPattern()}>
                <UsersPage />
              </Route>
            )}

            {hasSettingsRoute && (
              <Route path={authorizedRoutes.settingsPattern()}>
                <SettingsPage />
              </Route>
            )}

            {hasNoActiveSectionsRoute && (
              <Route path={authorizedRoutes.noActiveSections()}>
                <NoActiveSectionsPage />
              </Route>
            )}

            <Route path="/">
              <Redirect to={defaultRoute} />
            </Route>
          </Switch>
        </AuthorizedScreenContent>
        <ToastsManager />
        <BookingReservationModal />
      </AuthorizedScreenTemplate>
    ),
    [
      defaultRoute,
      handleContentScrolledToBottom,
      hasApplicationsRestaurantRoute,
      hasApplicationsSportRoute,
      hasChatsRestaurantRoute,
      hasBookingRestaurantRoute,
      hasChatsSportRoute,
      hasBookingClubRoute,
      hasCompetitionsRoute,
      hasFeedbackRoute,
      hasMembersRoute,
      hasUsersRoute,
      hasNewsRoute,
      hasSettingsRoute,
      hasNoActiveSectionsRoute,
      isMobile
    ]
  );
};
