import React from 'react';

import styles from './ArticlePreviewContent.module.css';

type TProps = {
  children: React.ReactNode;
  onScroll(e: React.SyntheticEvent): void;
};

export const ArticlePreviewContentScrollable = ({
  children,
  onScroll
}: TProps) => {
  return (
    <div
      className={styles.ArticlePreviewContent__Scrollable}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
};
