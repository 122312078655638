import React from 'react';

import './toastStyles.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';
import { CloseIcon } from 'svg/16x16/close';

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
  onBodyAreaClick(): void;
  onCloseAreaClick(): void;
};

export const ToastComponent = (props: Props) => {
  const { title, description, onBodyAreaClick, onCloseAreaClick } = props;

  const createClassName = createBEMConstructor('toast');

  const wrapperClassName = createClassName('wrapper');

  const bodyAreaClassName = createClassName('bodyArea');

  const titleClassName = createClassName('titleSlot');

  const descriptionClassName = createClassName('descriptionSlot');

  const closeAreaClassName = createClassName('closeArea');

  return (
    <div className={wrapperClassName}>
      <div className={bodyAreaClassName} onClick={onBodyAreaClick}>
        <div className={titleClassName}>{title}</div>
        <div className={descriptionClassName}>{description}</div>
      </div>
      <div className={closeAreaClassName} onClick={onCloseAreaClick}>
        <CloseIcon />
      </div>
    </div>
  );
};
