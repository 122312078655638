import React from 'react';

import {
  TCompetitionParticipant,
  TCompetitionParticipantStatus
} from 'types/state';
import { CompetitionParticipantsRow } from 'components/CompetitionParticipants/CompetitionParticipantsRow';
import { CompetitionParticipantsDataCell } from 'components/CompetitionParticipants/CompetitionParticipantsDataCell';
import {
  CompetitionParticipantsStatusSelect,
  TCompetitionParticipantStatusSelectOption
} from 'components/CompetitionParticipants/CompetitionParticipantsStatusSelect';
import { CompetitionParticipantsLogButton } from 'components/CompetitionParticipants/CompetitionParticipantsLogButton';
import { useToggler } from 'hooks/useToggler';
import { COMPETITION_PARTICIPANT_STATUSES_SET } from 'utils/constants';
import { getCompetitionParticipantStatusIntl } from 'utils/getCompetitionParticipantStatusIntl';
import { CompetitionParticipantLog } from 'containers/CompetitionParticipantLog';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  data: TCompetitionParticipant;
  competitionId: number;
  onChangeStatus(id: number, nextStatus: TCompetitionParticipantStatus): void;
};

export const CompetitionParticipant = ({
  data,
  onChangeStatus,
  competitionId
}: TProps) => {
  const [hasLogModal, togglehasLogModal] = useToggler();

  const intl = useIntlContext();

  const statusSelectOptions = React.useMemo<
    TCompetitionParticipantStatusSelectOption[]
  >(
    () =>
      COMPETITION_PARTICIPANT_STATUSES_SET.map(status => ({
        label: getCompetitionParticipantStatusIntl(status, intl),
        value: status
      })),
    [intl]
  );

  const handleChangeStatus = React.useCallback(
    (nextStatus: TCompetitionParticipantStatus) => {
      onChangeStatus(data.id, nextStatus);
    },
    [data.id, onChangeStatus]
  );

  const handleLogButtonClick = togglehasLogModal;

  const handleCloseLogModal = togglehasLogModal;

  return (
    <CompetitionParticipantsRow>
      <CompetitionParticipantsDataCell>
        {data.phone}
      </CompetitionParticipantsDataCell>
      <CompetitionParticipantsDataCell>
        {data.name}
      </CompetitionParticipantsDataCell>
      <CompetitionParticipantsDataCell>
        <CompetitionParticipantsStatusSelect
          selected={data.status}
          options={statusSelectOptions}
          onChange={handleChangeStatus}
        />
        <CompetitionParticipantsLogButton onClick={handleLogButtonClick} />
      </CompetitionParticipantsDataCell>
      {hasLogModal && (
        <CompetitionParticipantLog
          participantId={data.id}
          competitionId={competitionId}
          onClose={handleCloseLogModal}
        />
      )}
    </CompetitionParticipantsRow>
  );
};
