import React from 'react';

import styles from './CompetitionParticipants.module.css';

type TProps = {
  children: React.ReactNode;
};

export const CompetitionParticipantsDataCell = ({ children }: TProps) => {
  return (
    <td className={styles.CompetitionParticipants__DataCell}>{children}</td>
  );
};
