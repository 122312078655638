import { AppEvent } from 'types/appEvent';
import { PersonalDataEditorState } from 'types/state';
import { createPersonalDataEditorState } from 'utils/settings/personalDataEditor/createPersonalDataEditorState';
import { patchPersonalDataFormValues } from 'utils/settings/personalDataEditor/patchPersonalDataFormValues';
import { patchEditorState } from 'utils/patchEditorState';
import { validatePersonalDataFormValues } from 'utils/settings/personalDataEditor/validatePersonalDataFormValues';
import { authorizedRoutes } from '../../utils/authorizedRoutes';

export const personalDataEditorStateReducer = (
  state: PersonalDataEditorState,
  event: AppEvent
): PersonalDataEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      if (event.nextLocation === authorizedRoutes.settings()) {
        return state;
      } else {
        return createPersonalDataEditorState();
      }
    }
    case 'PERSONAL_DATA_FORM__LAST_NAME_INPUT_CHANGE':
    case 'PERSONAL_DATA_FORM__FIRST_NAME_INPUT_CHANGE':
    case 'PERSONAL_DATA_FORM__PHONE_NUMBER_SELECT_CHANGE': {
      let nextFormValues = patchPersonalDataFormValues(state.formValues);

      if (event.name === 'PERSONAL_DATA_FORM__LAST_NAME_INPUT_CHANGE') {
        nextFormValues = nextFormValues.setLastName(event.value);
      }

      if (event.name === 'PERSONAL_DATA_FORM__FIRST_NAME_INPUT_CHANGE') {
        nextFormValues = nextFormValues.setFirstName(event.value);
      }

      if (event.name === 'PERSONAL_DATA_FORM__PHONE_NUMBER_SELECT_CHANGE') {
        nextFormValues = nextFormValues.setPhoneNumber(event.value);
      }

      const nextEditorState = patchEditorState(state)
        .setSubmitDidSucceed(false)
        .setFormValues(nextFormValues.done())
        .clearFormError();

      return nextEditorState.done();
    }
    case 'PERSONAL_DATA_FORM__CANCEL': {
      return patchEditorState(state)
        .clearFormError()
        .setFormValues(event.oldValue)
        .done();
    }
    case 'PERSONAL_DATA_FORM__SUBMIT': {
      const { formValues } = state;

      const formError = validatePersonalDataFormValues(formValues);

      let nextFormValues = patchPersonalDataFormValues(formValues);

      let nextEditorState = patchEditorState(state);

      if (formError !== undefined) {
        nextEditorState = nextEditorState
          .setFormError(formError)
          .setFormValues(nextFormValues.done());
      } else {
        nextEditorState = nextEditorState.setSubmited(true);
      }

      return nextEditorState.done();
    }
    case 'UPDATE_CURRENT_USER_PERSONAL_DATA_SERVICE': {
      let nextEditorState = patchEditorState(state);

      if (event.status === 'PENDING') {
        nextEditorState = nextEditorState
          .clearFormError()
          .setSubmitDidSucceed(false);
      }

      if (event.status === 'RESOLVED') {
        nextEditorState = nextEditorState
          .clearFormError()
          .setSubmited(false)
          .setSubmitDidSucceed(true);
      }

      if (event.status === 'REJECTED') {
        nextEditorState = nextEditorState
          .setFormError('SERVICE_FAILED')
          .setSubmited(false);
      }

      return nextEditorState.done();
    }

    default:
      return state;
  }
};
