import React from 'react';

import styles from './CompetitionParticipants.module.css';

type TProps = {
  children: React.ReactNode;
};

export const CompetitionParticipantsRow = ({ children }: TProps) => {
  return (
    <tr className={styles.CompetitionParticipants__BodyRow}>{children}</tr>
  );
};
