import { TCreateReservationParams } from '../../../types/services';
import { sportingAPI } from '../api';
import { normalizeBookingReservationFormValues } from '../utils/normalizeBookingReservationFormValues';

export const createReservation = async (
  params: TCreateReservationParams
): Promise<void> => {
  const { formValues } = params;
  const payload = normalizeBookingReservationFormValues(
    formValues
  ).toSportingCreateReservationPayload();
  await sportingAPI.booking.createReservation({ payload });
};
