import { UpdateSheduleInfoService } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const updateSheduleInfo: UpdateSheduleInfoService = async params => {
  const sportingData = await sportingAPI.service.setSportingData({
    payload: {
      restarauntTime: params.restaurantShedule,
      clubTime: params.huntingShedule
    }
  });

  return {
    hunting: sportingData.clubTime,
    restaurant: sportingData.restarauntTime
  };
};
