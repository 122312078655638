import { getSportingChatURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { createQueryString } from 'utils/createQueryString';
import {
  SportingMessage,
  SportingPagedResponse,
  SportingSecureParams
} from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  page: number;
  size: number;
  idChat: number;
};

export const getMessages = async (
  params: SportingSecureParams<Payload>
): Promise<SportingPagedResponse<SportingMessage>> => {
  const { payload } = params;

  const endpointURL = getSportingChatURL(
    `getMessages?${createQueryString(payload)}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  if (response.status === 200) {
    return response.json();
  }

  return {
    content: [],
    page: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
    last: true
  };
};
