import React from 'react';

import { useContextStrict } from 'hooks/useContextStrict';
import { ServicesContext } from 'contexts/ServicesContext';
import { TGetCompetitionsFeedParams } from 'types/services';
import { useAsyncController } from 'hooks/useAsyncController';
import { TCompetitionsArticle } from 'types/state';

export const useCompetitionsService = () => {
  // Подключается сервис на получение статьей.
  const { getCompetitionsFeed } = useContextStrict(ServicesContext);

  // Состояние и метод вызова сервиса на получение статьей.

  const {
    wrapAsync: wrapCompetitionsFeedPulling,
    asyncProcess: competitionsFeedPullingProcess
  } = useAsyncController<TCompetitionsArticle[]>();

  // Метод вызова сервиса на получение статьей c проброшенной
  // авторизацией.
  const pullCompetitionsFeed = React.useCallback(
    (restProps: Omit<TGetCompetitionsFeedParams, 'authorization'>) => {
      const promise = getCompetitionsFeed(restProps);

      wrapCompetitionsFeedPulling(promise);
    },
    [getCompetitionsFeed, wrapCompetitionsFeedPulling]
  );

  return { competitionsFeedPullingProcess, pullCompetitionsFeed };
};
