import { AppDispatch } from 'types/state';
import { SubmitChatMessageParams } from 'types/services';
import { AuthorizationError } from 'utils/errors';

export const chatMessageSubmissionThunk = (
  promise: Promise<void>,
  meta: SubmitChatMessageParams
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'CHAT_MESSAGE_SUBMISSION_SERVICE',
    status: 'PENDING',
    meta
  });

  return promise
    .then(() => {
      dispatch({
        name: 'CHAT_MESSAGE_SUBMISSION_SERVICE',
        status: 'RESOLVED',
        payload: undefined,
        meta
      });
    })
    .catch(error => {
      if (error instanceof AuthorizationError) {
        throw error;
      }

      dispatch({
        name: 'CHAT_MESSAGE_SUBMISSION_SERVICE',
        status: 'REJECTED',
        payload: error,
        meta
      });
    });
};
