import React from 'react';

import { useBrowserTabTitleUpdater } from 'hooks/useBrowserTabTitleUpdater';
import { useApplicationsPageDomain } from 'hooks/useApplicationsPageDomain';
import { ApplicationsPageView } from 'pages/ApplicationsPage/ApplicationsPageView';
import { useNewApplicationsService } from 'hooks/useNewApplicationsService';
import { useNewApplicationGroups } from 'hooks/useNewApplicationGroups';
import { useProcessedApplicationGroups } from 'hooks/useProcessedApplicationGroups';
import { useProcessedApplicationsService } from 'hooks/useProcessedApplicationsService';
import { useApplicationsPagePreloaderFlag } from 'hooks/useApplicationsPagePreloaderFlag';
import { useNewApplciationsCountWatcher } from 'hooks/useNewApplciationsCountWatcher';

export const ApplicationsPage = () => {
  useBrowserTabTitleUpdater();

  const domain = useApplicationsPageDomain();

  useNewApplciationsCountWatcher(domain);

  useNewApplicationsService(domain);

  useProcessedApplicationsService(domain);

  const hasPreloader = useApplicationsPagePreloaderFlag(domain);

  const applicationNewGroups = useNewApplicationGroups(domain);

  const applicationProcessedGroups = useProcessedApplicationGroups(domain);

  const viewProps = {
    domain,
    hasPreloader,
    applicationNewGroups,
    applicationProcessedGroups
  };

  return <ApplicationsPageView {...viewProps} />;
};
