import React from 'react';

import { TApplicationsGroup } from 'types/state';
import { testId } from 'utils/testId';
import { ApplicationsTableGroup } from 'components/ApplicationsTable/ApplicationsTableGroup';
import { useIntlContext } from 'hooks/useIntlContext';
import { ApplicationsTableRow } from 'containers/ApplicationsTableRow';
import {
  getProcessedApplicationsGroupDateText,
  getNewApplicationsGroupDateText
} from 'utils/getApplicationsGroupDateText';
import { ServiceScope } from 'types/serviceScope';

type TProps = {
  group: TApplicationsGroup;
  domain: ServiceScope;
};

export const ApplicationsTableGroupContainer = (props: TProps) => {
  const {
    group: { applications, dayDateISO, isNew },
    domain
  } = props;

  const intl = useIntlContext();

  const processedGroupTitle = React.useMemo(() => {
    if (isNew) {
      return getNewApplicationsGroupDateText(dayDateISO, intl);
    }

    return getProcessedApplicationsGroupDateText(dayDateISO, intl);
  }, [isNew, dayDateISO, intl]);

  const rows = React.useMemo(
    () =>
      applications.length > 0
        ? applications.map(application => (
            <ApplicationsTableRow
              key={application.id}
              application={application}
              domain={domain}
            />
          ))
        : null,
    [applications, domain]
  );

  return (
    <ApplicationsTableGroup
      testId={
        isNew
          ? testId.APPLICATIONS_PAGE_TABLE_GROUP_NEW
          : testId.APPLICATIONS_PAGE_TABLE_GROUP_PROCESSED
      }
      titleTestId={testId.APPLICATIONS_PAGE_TABLE_GROUP_TITLE}
      title={processedGroupTitle}
      fallbackText={intl.applicationsGroupEmptyFallback}
      fallbackTestId={testId.APPLICATIONS_PAGE_TABLE_GROUP_FALLBACK}
      groupNew={isNew}
    >
      {rows}
    </ApplicationsTableGroup>
  );
};
