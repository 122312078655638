import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { DeleteArticleModalView } from 'containers/DeleteArticleModal/DeleteArticleModalView';
import { useAsyncController } from 'hooks/useAsyncController';
import { useServices } from 'hooks/useServices';

type Props = {
  id: number;
  domain: 'NEWS' | 'COMPETITONS';
  onDeleted(): void;
  onCancel(): void;
};

export const DeleteArticleModal = (props: Props) => {
  const { id, onDeleted, domain, onCancel } = props;

  const {
    deleteNewsArticleModalTitle,
    deleteNewsArticleModalDescription,
    deleteCompetitionsArticleModalTitle,
    deleteCompetitionsArticleModalDescription,
    deleteArticleModalCancel: cancelButtonLabel,
    deleteArticleModalConfirm: confirmButtonLabel
  } = useIntlContext();

  const { deleteNewsArticle, deleteCompetitionsArticle } = useServices();

  const {
    asyncProcess: deletionProcess,
    wrapAsync: wrapDeletion
  } = useAsyncController();

  const deleteArtice = React.useMemo(() => {
    if (domain === 'NEWS') {
      return deleteNewsArticle;
    }
    if (domain === 'COMPETITONS') {
      return deleteCompetitionsArticle;
    }

    throw new TypeError();
  }, [deleteCompetitionsArticle, deleteNewsArticle, domain]);

  // Effects

  React.useEffect(() => {
    if (deletionProcess.status === 'RESOLVED') {
      onDeleted();
    }
  }, [deletionProcess.status, onDeleted]);

  // Normalization

  const handleCancel = onCancel;

  const handleConfirm = React.useCallback(() => {
    const promise = deleteArtice({ id });

    wrapDeletion(promise);
  }, [deleteArtice, id, wrapDeletion]);

  const hasPendingView = deletionProcess.status === 'PENDING';

  const title =
    (domain === 'NEWS' && deleteNewsArticleModalTitle) ||
    (domain === 'COMPETITONS' && deleteCompetitionsArticleModalTitle) ||
    '';

  const description =
    (domain === 'NEWS' && deleteNewsArticleModalDescription) ||
    (domain === 'COMPETITONS' && deleteCompetitionsArticleModalDescription) ||
    '';

  const viewProps = {
    title,
    description,
    cancelButtonLabel,
    confirmButtonLabel,
    hasPendingView,
    handleCancel,
    handleConfirm
  };

  return <DeleteArticleModalView {...viewProps} />;
};
