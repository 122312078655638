import { ClientInfosCollection } from 'types/client';
import { AppEvent } from 'types/appEvent';
import { modifyCollectionCache } from 'utils/collectionCacheUtils';

export const clientInfosCollectionReducer = (
  state: ClientInfosCollection,
  event: AppEvent
): ClientInfosCollection => {
  switch (event.name) {
    case 'CHAT_ROOMS_SERVICE': {
      if (event.status === 'RESOLVED') {
        return event.payload
          .reduce(
            (acc, [room]) => acc.putItem(room.clientInfo.id, room.clientInfo),
            modifyCollectionCache(state)
          )
          .return();
      }

      return state;
    }
    default:
      return state;
  }
};
