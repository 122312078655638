import React from 'react';

import styles from './membersPage.module.css';

type Props = {
  input: React.ReactNode;
  content: React.ReactNode;
};

export const MembersPageLayout: React.FC<Props> = ({ input, content }) => (
  <div className={styles.MembersPage__Wrapper}>
    <div className={styles.MembersPage__InputSlot}>{input}</div>
    <div className={styles.MembersPage__ContentSlot}>{content}</div>
  </div>
);
