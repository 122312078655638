import React from 'react';

import styles from './centeredLayout.module.css';

type Props = {
  children: React.ReactNode;
};

export const CenteredLayout = (props: Props) => {
  const { children } = props;

  const className = styles.CenteredLayout;

  return <div className={className}>{children}</div>;
};
