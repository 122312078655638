import React from 'react';

import styles from './CompetitionsPage.module.css';

import { CreateButton } from 'components/shared/CreateButton';
import { testId } from 'utils/testId';

type TProps = {
  text: string;
  onCreateItem(): void;
};

export const CompetitionsPageRightSectionFallback = ({
  text,
  onCreateItem
}: TProps) => (
  <div className={styles.CompetitionsPage__RightSectionFallback}>
    <button
      onClick={onCreateItem}
      className={styles.CompetitionsPage__RightSectionFallbackClickableArea}
      data-testid={testId.COMPETITIONS_PREVIEW_CREATE_ARTICLE_BUTTON}
    >
      <CreateButton
        className={styles.CompetitionsPage__RightSectionFallbackButton}
        useDiv
      />
      <div className={styles.CompetitionsPage__RightSectionFallbackText}>
        {text}
      </div>
    </button>
  </div>
);
