import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';

export const useVisibilityService = () => {
  const dispatch = useAppDispatch();

  const handleVisibilityChange = React.useCallback(() => {
    dispatch({
      name: 'DOCUMENT__VISIBILITY_CHANGE',
      visible: !document.hidden
    });
  }, [dispatch]);

  React.useEffect(() => {
    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    );

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false
      );
    };
  }, [handleVisibilityChange]);

  // Нужно получить начальное значение видимости.
  React.useEffect(() => {
    dispatch({
      name: 'DOCUMENT__VISIBILITY_CHANGE',
      visible: !document.hidden
    });
  }, [dispatch]);
};
