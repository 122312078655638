import React from 'react';

import styles from './ReviewPreview.module.css';

import { testId } from 'utils/testId';

type TProps = {
  label: string;
  text: string;
};

export const ReviewPreviewMessage = ({ label, text }: TProps) => (
  <div className={styles.ReviewPreview__Message}>
    <div className={styles.ReviewPreview__MessageLabel}>{label}:</div>
    <div
      className={styles.ReviewPreview__MessageText}
      data-testid={testId.REVIEW_PREVIEW_MESSAGE}
    >
      {text}
    </div>
  </div>
);
