import { ChatRoom } from 'types/chats';
import { createChatRoomMock } from 'services/mock/utils/chatRoomInstances';
import { chatMessagesStoreMock } from 'services/mock/utils/chatMessagesModel';
import { CHAT_ROOMS_PAGE_LENGTH } from 'utils/constants';

// В этом файле содержится in-memory база данных,
// которая используется mock-сервисами для имитации
// взаимодействия с сервером при dev-разработке.

// База данных.
let storeMock = (() => {
  const messages = chatMessagesStoreMock.getAllMessages();

  return messages.map(message => {
    const room = createChatRoomMock();

    room.lastMessageId = message.id;

    return room;
  });
})();

// Методы взаимодействия с базой данных:
const getRoomsByPage = (page: number): ChatRoom[] => {
  const startIndex = (page - 1) * CHAT_ROOMS_PAGE_LENGTH;
  const endIndex = startIndex + CHAT_ROOMS_PAGE_LENGTH;

  return storeMock.slice(startIndex, endIndex);
};

const getAllRooms = (): ChatRoom[] => [...storeMock];

const getRoomById = (roomId: number): null | ChatRoom => {
  const room = storeMock.find(room => room.id === roomId);

  return room || null;
};

const replaceRoomById = (roomId: number, nextRoom: ChatRoom) => {
  storeMock = storeMock.map(room => {
    if (room.id === roomId) {
      return nextRoom;
    }

    return room;
  });
};

export const chatRoomsStoreMock = {
  getRoomsByPage,
  getAllRooms,
  getRoomById,
  replaceRoomById
};
