import { BookingTimelineReservation } from 'types/bookingTimeline';
import { ServiceScope } from 'types/serviceScope';
import { BookingReservationFormValues, Reservation } from 'types/state';
import { createBookingReservationFormValues } from './createBookingReservationEditorState';

export function normalizeReservation(reservation: Reservation) {
  return {
    toBookingTimelineReservation(): BookingTimelineReservation {
      return {
        id: reservation.id,
        name: reservation.preBooking ? reservation.fio : null,
        comment: reservation.comment,
        participantsCount: reservation.personsCount,
        period: reservation.period,
        itemIds: reservation.places.map(placeItem => placeItem.id)
      };
    },
    toBookingReservationFormValues(
      scope: ServiceScope
    ): BookingReservationFormValues {
      return {
        ...createBookingReservationFormValues(scope),
        id: reservation.id,
        personsCount: reservation.personsCount,
        period: reservation.period,
        placeItems: reservation.places,
        comment: reservation.comment,
        preBooking: reservation.preBooking,
        userId: reservation.userId,
        fio: reservation.fio,
        phoneNumber: reservation.phoneNumber,
        cardNumber: reservation.cardNumber || '',
        processedBy: reservation.processedBy,
        serviceScope: scope
      };
    }
  };
}
