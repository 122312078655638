import { sportingAPI } from '../api';
import { normalizeSportingUser } from '../utils/normalizeSportingUser';
import { ClientInfo } from '../../../types/client';
import { TFindUsersParams } from '../../../types/services';

export const findUsers = async (
  params: TFindUsersParams
): Promise<ClientInfo[]> => {
  const users = await sportingAPI.booking.findUsers(params);

  return users.map(sportingUser =>
    normalizeSportingUser(sportingUser).toClientInfo()
  );
};
