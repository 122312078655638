import { ServerEvent } from 'types/appEvent/serverEvent';
import { SportingEvent } from 'services/sporting/types';
import { normalizeSportingServiceScope } from 'services/sporting/utils/normalizeSportingServiceScope';
import { normalizeSportingRoleName } from 'services/sporting/utils/normalizeSportingRoleName';
import { Section } from 'types/state';
import { UnreachableError } from 'utils/errors';
import { normalizeSportingPlaceDestinationType } from './normalizeSportingPlaceDestinationType';
import { parseISO } from 'date-fns';

export const normalizeSportingEvent = (event: SportingEvent) => ({
  toServerEvent(): ServerEvent {
    switch (event.type) {
      case 'ChatHaveSettedAsUnreaded': {
        return {
          name: 'CHAT_MARKED_AS_UNREAD',
          roomId: event.idChat
        };
      }

      case 'ChatIsReaded': {
        return {
          name: 'CHAT_MARKED_AS_READ',
          roomId: event.idChat,
          origin: normalizeSportingRoleName(event.readedBy).toChatRole()
        };
      }

      case 'IncomingMessage': {
        return {
          name: 'CHAT_MESSAGE_RECIEVED',
          roomId: event.idChat,
          domain: normalizeSportingServiceScope(
            event.destinactionType
          ).toServiceScope()
        };
      }

      case 'EditedMessage': {
        if (event.deleted) {
          return {
            name: 'CHAT_MESSAGE_DELETED',
            roomId: event.idChat,
            messageId: event.idMessage
          };
        }

        return {
          name: 'CHAT_MESSAGE_EDITED',
          roomId: event.idChat,
          messageId: event.idMessage,
          text: event.text || ''
        };
      }

      case 'TypingEvent': {
        if (event.typingAction === 'START_TYPING') {
          return {
            name: 'USER_STARTED_TYPING',
            roomId: event.idChat,
            userId: event.idUser
          };
        }

        return {
          name: 'USER_STOPPED_TYPING',
          roomId: event.idChat,
          userId: event.idUser
        };
      }

      case 'BadgeNotification': {
        const section: Section = (() => {
          switch (event.kind) {
            case 'COMMENT':
              return 'feedback';
            case 'PARTICIPATION':
              return 'competitions';
            case 'RESTAURANT_CLAIM':
              return 'applicationsRestaurant';
            case 'SHOOTING_CLAIM':
              return 'applicationsSport';
            default:
              throw new UnreachableError(event.kind);
          }
        })();

        return {
          name: 'BADGE_UPDATE_REQUESTED',
          section,
          shouldIncrease: event.eventType === 'INCREASE'
        };
      }

      case 'ReservationNotification': {
        if (event.reservationDataChangeType === 'RESERVATIONS') {
          const [prevDateText, nextDateText] = event.reservationDates;

          if (nextDateText) {
            const prevDateISO = parseISO(prevDateText).toISOString();
            const nextDateISO = parseISO(nextDateText).toISOString();

            return {
              name: 'BOOKING_RESERVATION_DATE_UPDATED',
              area: normalizeSportingPlaceDestinationType(
                event.location
              ).toServiceScope(),
              nextDateISO,
              prevDateISO
            };
          }

          const dateISO = parseISO(prevDateText).toISOString();

          return {
            name: 'BOOKING_RESERVATION_UPDATED',
            area: normalizeSportingPlaceDestinationType(
              event.location
            ).toServiceScope(),
            dateISO
          };
        }

        return {
          name: 'BOOKING_AREA_UPDATED',
          area: normalizeSportingPlaceDestinationType(
            event.location
          ).toServiceScope()
        };
      }

      default: {
        return {
          name: 'UNKNOWN'
        };
      }
    }
  }
});
