import { TGetCompetitionParticipantsParams } from 'types/services';
import { TCompetitionParticipant } from 'types/state';
import { normalizeSportingCompetitionParticipant } from 'services/sporting/utils/normalizeSportingCompetitionParticipant';
import { sportingAPI } from 'services/sporting/api';

export const getCompetitionParticipants = async (
  params: TGetCompetitionParticipantsParams
): Promise<TCompetitionParticipant[]> => {
  const { id } = params;

  const participants = await sportingAPI.service.getCompetitionParticipants({
    payload: { idCompetition: id }
  });

  return participants.map(
    normalizeSportingCompetitionParticipant.toCompetitionParticipant
  );
};
