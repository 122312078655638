import { DayOfWeek } from 'types/state';

export function createDaysOfWeekList(): DayOfWeek[] {
  const list: DayOfWeek[] = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY'
  ];

  return list;
}
