import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { restaurantApplicationsStoreMock } from 'services/mock/utils/restaurantApplicationsModel';
import { TApplication } from 'types/state';

export const getRestaurantNewApplications = async (): Promise<TApplication[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return restaurantApplicationsStoreMock.getAllNewApplications();
};
