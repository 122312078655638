import { AppEvent } from 'types/appEvent';
import { ActiveChatRoom } from 'types/chats';
import { AppState } from 'types/appState';
import { activeChatRoomMessageIdsListReducer } from 'reducers/chatsStateReducer/activeChatRoomMessageIdsListReducer';
import { activeChatRoomIdReducer } from 'reducers/chatsStateReducer/activeChatRoomIdReducer';
import { activeChatRoomScrolledToBottomReducer } from 'reducers/chatsStateReducer/activeChatRoomScrolledToBottomReducer';

export const activeChatRoomReducer = (
  state: ActiveChatRoom,
  event: AppEvent,
  appState: AppState
): ActiveChatRoom => {
  return {
    id: activeChatRoomIdReducer(state.id, event, appState),
    messageIdsList: activeChatRoomMessageIdsListReducer(
      state.messageIdsList,
      event,
      appState
    ),
    scrolledToBottom: activeChatRoomScrolledToBottomReducer(
      state.scrolledToBottom,
      event
    )
  };
};
