import { AppEvent } from 'types/appEvent';

export const typingMapReducer = (
  state: Map<number, number>,
  event: AppEvent
): Map<number, number> => {
  switch (event.name) {
    case 'USER_STARTED_TYPING': {
      const nextState = new Map(state);

      nextState.set(event.userId, event.roomId);

      return nextState;
    }
    case 'USER_STOPPED_TYPING': {
      const nextState = new Map(state);

      if (nextState.get(event.userId) === event.roomId) {
        nextState.delete(event.userId);
      }

      return nextState;
    }
    default:
      return state;
  }
};
