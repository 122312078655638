import React from 'react';

import { TNewsAudienceGroup, TArticeImage, TNewsArticle } from 'types/state';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  hasEditView: boolean;
  data: TNewsArticle;
  onChange(nextData: Partial<TNewsArticle>): void;
  onEdit(): void;
  onDelete(): void;
};

export type TNewsArticleProps = TProps;

export const useNewsArticleState = (props: TProps) => {
  const { data, hasEditView, onChange, onDelete, onEdit } = props;

  const {
    newsArticleTitleIputPlaceholder: titleInputPlaceholder,
    newsArticleDescriptionIputPlaceholder: descriptionInputPlaceholder
  } = useIntlContext();

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.currentTarget;
      onChange({
        [name]: value
      });
    },
    [onChange]
  );
  const handleImageChange = React.useCallback(
    (nextImage: TArticeImage) => {
      onChange({
        image: nextImage
      });
    },
    [onChange]
  );
  const handleAudienceChange = React.useCallback(
    (nextAudience: TNewsAudienceGroup[]) => {
      onChange({
        audience: nextAudience
      });
    },
    [onChange]
  );
  const handleDelete = onDelete;
  const handleEdit = onEdit;

  return {
    titleInputPlaceholder,
    descriptionInputPlaceholder,
    hasEditView,
    data,
    handleInputChange,
    handleImageChange,
    handleAudienceChange,
    handleEdit,
    handleDelete
  };
};
