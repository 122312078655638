import React from 'react';

import { checkDate } from 'utils/checkDate';
import { CustomDatePickerDateButton } from 'components/CustomDatePicker/styled/CustomDatePickerDateButton';

type TProps = {
  date: number;
  currentValue: string;
  disableBefore?: string;
  disableAfter?: string;
  onDateSelect(nextDate: string): void;
};

export const CustomDatePickerDateItem = ({
  date,
  currentValue,
  disableBefore,
  disableAfter,
  onDateSelect
}: TProps) => {
  const itemDate = React.useMemo(() => {
    return new Date(date);
  }, [date]);

  const dateNumber = itemDate.getDate();

  const isCurrent = checkDate(itemDate).equalTo(new Date(currentValue));

  const isDisabled = React.useMemo(() => {
    const outOfBefore =
      !!disableBefore && checkDate(itemDate).lessThen(new Date(disableBefore));

    const outOfAfter =
      !!disableAfter && checkDate(itemDate).moreThen(new Date(disableAfter));

    return outOfAfter || outOfBefore;
  }, [disableAfter, disableBefore, itemDate]);

  const handleClick = () => {
    const dateCopy = new Date(date);
    dateCopy.setHours(0, 0, 0, 0);
    onDateSelect(dateCopy.toISOString());
  };

  return (
    <CustomDatePickerDateButton
      key={date}
      isCurrent={isCurrent}
      isDisabled={isDisabled}
      onClick={handleClick}
    >
      {dateNumber}
    </CustomDatePickerDateButton>
  );
};
