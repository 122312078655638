import React from 'react';
import { ExitIcon } from 'svg/25x25/exit';

import styles from './Navigation.module.scss';

type TProps = {
  text: string;
  testId: string;
  onClick(): void;
};

export const NavigationSignOutButton = ({ testId, text, onClick }: TProps) => (
  <button
    onClick={onClick}
    className={styles.Navigation__SignOutButton}
    data-testid={testId}
  >
    <span className={styles.Navigation__SignOutLabel}>{text}</span>
    <ExitIcon className={styles.Navigation__Icon} />
  </button>
);
