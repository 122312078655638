import { MembersPageDataService } from 'types/services';
import { sportingAPI } from '../api';
import { normalizeSportingUser } from '../utils/normalizeSportingUser';

export const getMembersPageData: MembersPageDataService = async cardNumber => {
  const sportingUsers = await sportingAPI.service.findRegisteredUsersByCardNumberPart(
    {
      cardNumberPart: cardNumber
    }
  );

  return {
    clientInfos: sportingUsers.map(sportingUser =>
      normalizeSportingUser(sportingUser).toClientInfo()
    ),
    verifiedClientIds: sportingUsers
      .filter(sportingUser => sportingUser.dataStatus === 'VERIFIED')
      .map(sportingUser => sportingUser.id)
  };
};
