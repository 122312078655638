import { useBookingReservationEditorState } from 'hooks/booking/useBookingReservationEditorState';
import React from 'react';
import { BookingReservationModalContent } from './BookingReservationModalContent';

export const BookingReservationModal = () => {
  const { formValues } = useBookingReservationEditorState();

  if (formValues.initialized) {
    return (
      <BookingReservationModalContent location={formValues.serviceScope} />
    );
  }

  return null;
};
