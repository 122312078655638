import React from 'react';

import { useContextStrict } from 'hooks/useContextStrict';
import { ServicesContext } from 'contexts/ServicesContext';
import { useIntlContext } from 'hooks/useIntlContext';
import { CompetitionNotesView } from 'containers/CompetitionNotes/CompetitionNotesView';
import { useAsyncController } from 'hooks/useAsyncController';
import {
  TGetCompetitionNoteParams,
  TSetCompetitionNoteParams
} from 'types/services';

type Props = {
  competitionId: number;
};

export const CompetitionNotes = (props: Props) => {
  const { competitionId } = props;

  const { setCompetitionNote, getCompetitionNote } = useContextStrict(
    ServicesContext
  );

  // Логика изменения заметки
  const [actualNote, setActualNote] = React.useState('');

  // Логика подгрузки заметки
  const {
    wrapAsync: wrapCompetitionNotePulling,
    asyncProcess: competitionNotePullingProcess
  } = useAsyncController<string>();

  const getNote = React.useCallback(
    (params: TGetCompetitionNoteParams) => {
      const promise = getCompetitionNote(params);

      wrapCompetitionNotePulling(promise);
    },
    [getCompetitionNote, wrapCompetitionNotePulling]
  );

  React.useEffect(() => {
    getNote({ id: competitionId });
  }, [competitionId, getNote]);

  React.useEffect(() => {
    if (competitionNotePullingProcess.status === 'RESOLVED') {
      setActualNote(competitionNotePullingProcess.value);
    }
  }, [competitionNotePullingProcess]);

  // Логика сохранения заметки
  const {
    wrapAsync: wrapCompetitionNoteUpdate,
    asyncProcess: competitionNoteUpdateProcess
  } = useAsyncController();

  const updateCompetitionNote = React.useCallback(
    (params: TSetCompetitionNoteParams) => {
      const promise = setCompetitionNote(params);

      wrapCompetitionNoteUpdate(promise);
    },
    [setCompetitionNote, wrapCompetitionNoteUpdate]
  );

  // Нормализация
  const handleChange = setActualNote;

  const handleBlur = React.useCallback(() => {
    updateCompetitionNote({ note: actualNote, id: competitionId });
  }, [actualNote, competitionId, updateCompetitionNote]);

  const hasPreloader =
    competitionNotePullingProcess.status === 'IDLE' ||
    competitionNotePullingProcess.status === 'PENDING';

  const inputDisabled = competitionNoteUpdateProcess.status === 'PENDING';

  const inputValue = actualNote;

  const { competitionNotesPlaceholder } = useIntlContext();

  const viewProps = {
    hasPreloader,
    inputValue,
    competitionNotesPlaceholder,
    inputDisabled,
    handleChange,
    handleBlur
  };

  return <CompetitionNotesView {...viewProps} />;
};
