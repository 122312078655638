import { useAppState } from 'hooks/useAppState';

export const useUserInfoCache = () => {
  const { userInfoCache } = useAppState();

  return userInfoCache;
};

export const useUserInfo = () => {
  const { value } = useUserInfoCache();

  return value;
};

export const useUserInfoStrict = () => {
  const userInfo = useUserInfo();

  if (!userInfo) {
    throw new TypeError();
  }

  return userInfo;
};
