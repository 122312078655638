import React from 'react';

import { TReview, TReviewStatusSelectOption, TReviewStatus } from 'types/state';
import { testId } from 'utils/testId';
import { TIntl } from 'types/intl';
import { addZ, setISOToDate } from 'utils/dateUtils';
import { useIntlContext } from 'hooks/useIntlContext';
import { useServices } from 'hooks/useServices';
import { ReviewPreviewView } from 'containers/ReviewPreview/ReviewPreviewView';
import { createReviewTitle } from 'utils/createReviewTitle';
import { useAsyncController } from 'hooks/useAsyncController';
import { TSetReviewStatusParams } from 'types/services';

type TProps = {
  review: TReview;
  onReviewStatusChanged(id: number, nextStatus: TReviewStatus): void;
};

export type TReviewPreviewProps = TProps;

const normalizeCreatedAdDate = (dateISO: string, intl: TIntl) => {
  const date = setISOToDate(dateISO);
  return `${date.getDate()} ${
    intl.predicativeMonthNames[date.getMonth()]
  }, ${addZ(date.getHours())}:${addZ(date.getMinutes())}`;
};

export const ReviewPreview = (props: TProps) => {
  const { review, onReviewStatusChanged } = props;

  const intl = useIntlContext();

  const { setReviewStatus } = useServices();

  const { wrapAsync: wrapReviewsStatusUpdate } = useAsyncController();

  const updateReviewStatus = React.useCallback(
    (params: TSetReviewStatusParams) => {
      const promise = setReviewStatus(params).then(() => {
        onReviewStatusChanged(params.reviewId, params.status);
      });

      wrapReviewsStatusUpdate(promise);
    },
    [onReviewStatusChanged, setReviewStatus, wrapReviewsStatusUpdate]
  );

  // Флаг предотвращает автоматическую смену статуса отзыва.
  const preventAutoStatusUpdate = React.useRef(false);

  // При открытии нового отзыва разрешается автоматическая
  // смена статуса.
  React.useEffect(() => {
    preventAutoStatusUpdate.current = false;
  }, [review.id]);

  // Эффект меняет статус нового отзыва на "Прочитан" при открытии.
  React.useEffect(() => {
    if (review.status === 'NEW' && !preventAutoStatusUpdate.current) {
      updateReviewStatus({
        status: 'PROCESSED',
        reviewId: review.id
      });
    }
  }, [review.id, review.status, updateReviewStatus]);

  // Нормализация состояния
  const onSelectChange = React.useCallback(
    (nextStatus: TReviewStatus) => {
      // При ручной смене статуса отзыва предотвращается
      // автоматическая сменая статуса на "Прочитан".
      preventAutoStatusUpdate.current = true;

      updateReviewStatus({
        status: nextStatus,
        reviewId: review.id
      });
    },
    [review.id, updateReviewStatus]
  );

  const title = createReviewTitle(intl, review);

  const date = normalizeCreatedAdDate(review.createdAtDate, intl);
  const dateLabel = intl.reviewPreviewDateLabel;

  const orderNumber =
    review.type === 'RESTAURANT' ? undefined : review.orderNumber;
  const orderNumberLabel = intl.reviewPreviewOrderNumberLabel;

  const foodScore = review.foodScore ? `${review.foodScore}` : undefined;
  const foodScoreLabel = intl.reviewPreviewFoodScoreLabel;

  const serviceScore = review.serviceScore
    ? `${review.serviceScore}`
    : undefined;
  const serviceScoreLabel = intl.reviewPreviewServiceScoreLabel;

  const name = review.clientName;
  const nameLabel = intl.reviewPreviewNameLabel;

  const email = review.clientEmail;
  const emailLabel = intl.reviewPreviewEmailLabel;

  const phone = review.clientPhone;
  const phoneLabel = intl.reveiwPreviewPhoneLabel;

  const comment = review.clientComment;
  const commentLabel = intl.reviewPreviewMessageLabel;

  const selectOptions = React.useMemo<TReviewStatusSelectOption[]>(() => {
    return [
      {
        label: intl.reviewStatusNew,
        value: 'NEW',
        testId: testId.REVIEW_PREVIEW_SELECT_OPTION_NEW
      },
      {
        label: intl.reviewStatusProcessed,
        value: 'PROCESSED',
        testId: testId.REVIEW_PREVIEW_SELECT_OPTION_PROCESSED
      }
    ];
  }, [intl]);

  const selectedValue = review.status;

  const hasAccentStatusTheme = selectedValue === 'NEW';

  const hasSecondaryStatusTheme = selectedValue === 'PROCESSED';

  const viewProps = {
    title,
    date,
    dateLabel,
    orderNumber,
    orderNumberLabel,
    name,
    nameLabel,
    email,
    emailLabel,
    phone,
    phoneLabel,
    foodScore,
    foodScoreLabel,
    serviceScore,
    serviceScoreLabel,
    comment,
    commentLabel,
    selectOptions,
    selectedValue,
    hasAccentStatusTheme,
    hasSecondaryStatusTheme,
    onSelectChange
  };

  return <ReviewPreviewView {...viewProps} />;
};
