import React from 'react';

import { TextInputControl } from 'design/textInputControl';
import { digitsOnlyRegExp } from 'utils/stringUtils';

import styles from './guestCountInputStyles.module.scss';
import { multiplyClassName } from 'utils/className';

type Props = {
  value: number;
  onValueChange(nextValue: number): void;
};

const predefinedValues = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const GuestCountInputComponent = (props: Props) => {
  const { value: selectedValue, onValueChange } = props;

  const [customValueMode, setCustomValueMode] = React.useState(false);

  React.useEffect(() => {
    if (!predefinedValues.includes(selectedValue)) {
      setCustomValueMode(true);
    }
  }, [selectedValue]);

  const customValueInputClassName = React.useMemo(() => {
    const customValueInputClassNames = [styles.customValueInput];

    if (customValueMode) {
      customValueInputClassNames.push(styles.selected);
    }

    return multiplyClassName(customValueInputClassNames);
  }, [customValueMode]);

  const handleCustomValueChange = React.useCallback(
    (nextValue: string) => {
      if (!nextValue || digitsOnlyRegExp.test(nextValue)) {
        setCustomValueMode(true);

        onValueChange(Number(nextValue));
      }
    },
    [onValueChange]
  );

  const createPredefinedValueSelectHandler = React.useCallback(
    (nextValue: number) => {
      return () => {
        setCustomValueMode(false);
        onValueChange(nextValue);
      };
    },
    [onValueChange]
  );

  const customValueInputValue =
    customValueMode && selectedValue ? `${selectedValue}` : '';

  return (
    <div className={styles.wrapper}>
      {predefinedValues.map(predefinedValue => {
        const classNames = [styles.predefinedValueOption];

        if (!customValueMode && predefinedValue === selectedValue) {
          classNames.push(styles.selected);
        }

        const className = multiplyClassName(classNames);

        return (
          <button
            key={predefinedValue}
            className={className}
            onClick={createPredefinedValueSelectHandler(predefinedValue)}
          >
            {predefinedValue}
          </button>
        );
      })}
      <div className={customValueInputClassName}>
        <TextInputControl
          placeholder="10"
          name="guestCount"
          maxLength={2}
          value={customValueInputValue}
          onValueChange={handleCustomValueChange}
        />
      </div>
    </div>
  );
};
