import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError, LoginInUseError } from 'utils/errors';
import {
  SportingAdminInfo,
  SportingSecureParams
} from 'services/sporting/types';
import { appendFormDataWithObjectValues } from 'utils/appendFormDataWithObjectValues';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  username: string;
  password: string;
};

export const addNewAdmin = async (
  params: SportingSecureParams<Payload>
): Promise<SportingAdminInfo> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL('addNewAdmin');

  const headers = createSportingHeaders();

  const body = new FormData();

  appendFormDataWithObjectValues(body, payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    headers,
    body
  });

  if (!response.ok) {
    if (response.status === 400) {
      throw new LoginInUseError();
    }

    throw new UnexpectedError();
  }

  return response.json();
};
