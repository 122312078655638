import addMinutes from 'date-fns/addMinutes';
import add from 'date-fns/add';

import { TApplication } from 'types/state';
import { getUid } from 'utils/getUid';

export const getNewApplicationMocks = (): TApplication[] => [
  {
    id: getUid(),
    fullName: 'Игорь Егорович Горохов',
    phone: '+375447545400',
    status: 'NEW',
    cardNumber: '50017',
    createdAtDate: add(new Date(), { minutes: -10 }).toISOString(),
    processedAtDate: null,
    origin: 'CALL_BACK',
    clientId: null
  },
  {
    id: getUid(),
    fullName: null,
    phone: '+375447554433',
    status: 'NEW',
    cardNumber: '4567',
    createdAtDate: add(new Date(), { days: -1, minutes: 35 }).toISOString(),
    processedAtDate: null,
    origin: 'MISSED_CALL',
    clientId: null
  },
  {
    id: getUid(),
    fullName: 'Овчинник Петр Иванович',
    phone: '+375447524643',
    status: 'NEW',
    cardNumber: '23948',
    createdAtDate: add(new Date(), { days: -2, minutes: 52 }).toISOString(),
    processedAtDate: null,
    origin: 'CALL_BACK',
    clientId: null
  }
];

export const getProcessedApplicationMocks = (): TApplication[] => [
  {
    id: getUid(),
    fullName: 'Розов Александр Петрович',
    phone: '+375174324622',
    status: 'DONE',
    cardNumber: '523',
    createdAtDate: add(new Date(), { days: -2 }).toISOString(),
    processedAtDate: addMinutes(new Date(), 1).toISOString(),
    origin: 'CALL_BACK',
    clientId: null
  },
  {
    id: getUid(),
    fullName: 'Степаненео Кирилл Генадьевич',
    phone: '+375301124652',
    status: 'DONE',
    cardNumber: '23448',
    createdAtDate: add(new Date(), { days: -6 }).toISOString(),
    processedAtDate: addMinutes(new Date(), 10).toISOString(),
    origin: 'MISSED_CALL',
    clientId: null
  },
  {
    id: getUid(),
    fullName: 'Семашко Евгений Михайлович',
    phone: '+375225523321',
    status: 'DONE',
    cardNumber: '2354',
    createdAtDate: add(new Date(), { days: -10 }).toISOString(),
    processedAtDate: addMinutes(new Date(), 200).toISOString(),
    origin: 'CALL_BACK',
    clientId: null
  }
];
