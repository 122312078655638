import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { useAsyncController } from './useAsyncController';
import { useNewRestaurantApplicationsCount } from './useNewRestaurantApplicationsCount';

export const useNewRestaurantApplicationsCountUpdateProcess = () => {
  const count = useNewRestaurantApplicationsCount();

  const dispatch = useAppDispatch();

  const sections = useEnabledSectionsStrict();

  const {
    getNewRestaurantApplicationsCount: getNewRestaurantApplicationsCountService
  } = useServices();

  const { asyncProcess, wrapAsync } = useAsyncController<number>();

  const shouldUpdate =
    sections.includes('applicationsRestaurant') && count.didInvalidate;

  React.useEffect(() => {
    if (shouldUpdate) {
      wrapAsync(getNewRestaurantApplicationsCountService());
    }
  }, [getNewRestaurantApplicationsCountService, shouldUpdate, wrapAsync]);

  React.useEffect(() => {
    if (asyncProcess.status === 'PENDING') {
      dispatch({
        name: 'NEW_APPLICATIONS_COUNT_SERVICE',
        status: 'PENDING',
        meta: 'RESTAURANT'
      });
    }
    if (asyncProcess.status === 'RESOLVED') {
      dispatch({
        name: 'NEW_APPLICATIONS_COUNT_SERVICE',
        status: 'RESOLVED',
        payload: asyncProcess.value,
        meta: 'RESTAURANT'
      });
    }
    if (asyncProcess.status === 'REJECTED') {
      dispatch({
        name: 'NEW_APPLICATIONS_COUNT_SERVICE',
        status: 'REJECTED',
        payload: asyncProcess.error,
        meta: 'RESTAURANT'
      });
    }
  }, [asyncProcess, dispatch]);
};
