import {
  SportingSecureParams,
  SportingUpdateReservationPayload
} from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { appendHeadersWithJSON } from '../../../../utils/headers';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';

export const updateReservation = async (
  params: SportingSecureParams<SportingUpdateReservationPayload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingBookingURL(`updateReservation/${payload.id}`);

  const headers = createSportingHeaders();
  appendHeadersWithJSON(headers);

  const body = JSON.stringify(payload);

  const response = await customFetch(endpointURL, {
    method: 'PUT',
    headers,
    body
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
