import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import React from 'react';

export const useBookingWaitersCreateService = () => {
  const services = useServices();

  const dispatch = useAppDispatch();

  const createWaiters = React.useCallback(
    (fullNames: string[]): Promise<void> => {
      return services.addWaiters({ fio: fullNames }).then(createdWaiters => {
        dispatch({
          name: 'BOOKING_WAITERS_CREATED',
          createdWaiters
        });
      });
    },
    [dispatch, services]
  );

  return { createWaiters };
};
