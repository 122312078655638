import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useFileURL } from 'hooks/useFileURL';
import { useToggler } from 'hooks/useToggler';
import { cropImageToFile } from 'utils/cropImageToFile';
import { TCropperParams } from 'types/state';
import { useIntlContext } from 'hooks/useIntlContext';
import { useAsyncController } from 'hooks/useAsyncController';

type TProps = {
  file: File;
  aspectRatio: number;
  minWidth: number;
  minHeight: number;
  onCropped(file: File): void;
  onCancel(): void;
};

export type TCropImageModalProps = TProps;

export const useCropImageModalState = (props: TCropImageModalProps) => {
  const {
    onCropped,
    onCancel,
    aspectRatio,
    file,
    minWidth: naturalMinWidth,
    minHeight: naturalMinHeight
  } = props;

  const {
    cropImageModalConfirm: confirmButtonLabel,
    cropImageModalCancel: cancelButtonLabel
  } = useIntlContext();

  const [
    cropperImage,
    setCropperImage
  ] = React.useState<null | HTMLImageElement>(null);

  const [isHidden, toggleIsHidden] = useToggler(true);

  const [cropperParams, setCropperParams] = React.useState<TCropperParams>({
    crop: {
      width: 0,
      height: 0,
      x: 1,
      y: 1
    },
    minWidth: 0,
    minHeight: 0
  });

  const {
    wrapAsync: wrapImageCropping,
    asyncProcess: imageCroppingProcess
  } = useAsyncController<File>();

  const src = useFileURL(file);

  // Effects

  React.useEffect(() => {
    if (imageCroppingProcess.status === 'RESOLVED') {
      onCropped(imageCroppingProcess.value);
    }
  }, [imageCroppingProcess, onCropped]);

  React.useEffect(() => {
    if (cropperImage) {
      const { naturalWidth, naturalHeight, width, height } = cropperImage;
      const scaleWidth = naturalMinWidth / naturalWidth;
      const scaleHeight = naturalMinHeight / naturalHeight;
      const scaledMinWidth = width * scaleWidth;
      const scaledMinHeight = height * scaleHeight;
      const positionX = width / 2 - scaledMinWidth / 2;
      const positionY = height / 2 - scaledMinHeight / 2;
      setCropperParams({
        crop: {
          x: positionX,
          y: positionY,
          aspect: aspectRatio,
          width: scaledMinWidth,
          height: scaledMinHeight
        },
        minWidth: scaledMinWidth,
        minHeight: scaledMinHeight
      });
      toggleIsHidden();
    }
  }, [
    cropperImage,
    naturalMinWidth,
    aspectRatio,
    naturalMinHeight,
    toggleIsHidden
  ]);

  // Normalization

  const handleConfirm = () => {
    if (cropperImage) {
      const promise = cropImageToFile({
        image: cropperImage,
        crop: cropperParams.crop,
        fileName: file.name,
        fileType: file.type
      });

      wrapImageCropping(promise);
    } else {
      throw new TypeError();
    }
  };

  const handleImageLoaded = (image: HTMLImageElement) => {
    setCropperImage(image);
  };

  const handleCancel = onCancel;

  const handleChange = (nextCrop: ReactCrop.Crop) => {
    setCropperParams(prevState => ({
      ...prevState,
      crop: nextCrop
    }));
  };

  const hasPendingView =
    imageCroppingProcess.status === 'PENDING' ||
    imageCroppingProcess.status === 'RESOLVED';

  return {
    src,
    cropperParams,
    confirmButtonLabel,
    cancelButtonLabel,
    isHidden,
    hasPendingView,
    handleConfirm,
    handleChange,
    handleCancel,
    handleImageLoaded
  };
};
