import { useBodyMediaClassNames } from 'hooks/useBodyMediaClassNames';
import { useConnectionStatusWatcher } from 'hooks/useConnectionStatusWatcher';
import { useWindowResizeWatcher } from 'hooks/useWindowResizeWatcher';
import { useRouterWatcher } from 'hooks/useRouterWatcher';
import { useUserInfoService } from 'hooks/useUserInfoService';
import { useSessionService } from 'hooks/useSessionService';

export const useAppHooks = () => {
  useWindowResizeWatcher();

  useBodyMediaClassNames();

  useConnectionStatusWatcher();

  useRouterWatcher();

  useSessionService();

  useUserInfoService();
};
