import React from 'react';

import styles from './CustomSelect.module.scss';

type TProps = {
  label: string;
  selected: boolean;
  testId?: string;
  onClick(): void;
};

export const CustomSelectOption = ({
  selected,
  onClick,
  label,
  testId
}: TProps) => {
  return (
    <button
      className={`${styles.CustomSelect__Option} ${
        selected ? styles.CustomSelect__Option_selected : ''
      }`}
      onClick={onClick}
      data-testid={testId}
      data-selected={selected}
    >
      {label}
    </button>
  );
};
