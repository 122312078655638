import React from 'react';

import { createAppState } from 'utils/createAppState';
import { AppStateContext } from 'contexts/AppStateContext';
import { AppDispatchContext } from 'contexts/AppDispatchContext';
import { appStateReducer } from 'reducers/appStateReducer';

type Props = {
  children: React.ReactNode;
};

export const AppStoreProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    appStateReducer,
    null,
    createAppState
  );

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};
