import React from 'react';

import styles from './SettingsBlock.module.css';

type Props = {
  text: string;
};

export const SettingsBlockDescription = ({ text }: Props) => (
  <p className={styles.SettingsBlock__Description}>{text}</p>
);
