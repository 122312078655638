import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineHeaderLayout.scss';

type Props = {
  title: React.ReactNode;
  icon: React.ReactNode;
  collapsed: boolean;
};

const bemConstructor = createBEMConstructor('bookingTimelineHeaderLayout');

export const BookingTimelineHeaderLayout: React.FC<Props> = props => {
  const { title, icon, collapsed } = props;

  const wrapperClassName = bemConstructor('wrapper', { collapsed });

  const iconSlotClassName = bemConstructor('iconSlot', { collapsed });

  const titleSlotClassName = bemConstructor('titleSlot', { collapsed });

  return (
    <div className={wrapperClassName}>
      <div className={iconSlotClassName}>{icon}</div>
      <div className={titleSlotClassName}>{title}</div>
    </div>
  );
};
