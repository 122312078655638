import { UpdateEmployeeParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { UserInfo } from 'types/state';

export const updateEmployee = async (
  params: UpdateEmployeeParams
): Promise<UserInfo> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return {
    id: params.id,
    role: params.role,
    phoneNumber: params.phoneNumber,
    firstName: params.firstName,
    lastName: params.lastName,
    login: params.login,
    blocked: false,
    enabledSections: []
  };
};
