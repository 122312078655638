import React from 'react';

import { RectangleButton } from 'components/shared/RectangleButton';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useIntlContext } from 'hooks/useIntlContext';
import { CustomInput } from 'components/shared/CustomInput';
import { SettingsFormWrapper } from 'components/SettingsForm/SettingsFormWrapper';
import { SettingsFormLegend } from 'components/SettingsForm/SettingsFormLegend';
import { SettingsFormFieldset } from 'components/SettingsForm/SettingsFormFieldset';
import { SettingsFormFooter } from 'components/SettingsForm/SettingsFormFooter';
import { useSheduleEditorState } from 'hooks/useSheduleEditorState';
import { useAppState } from 'hooks/useAppState';

export const SheduleEditorForm = () => {
  const {
    sheduleEditor: { form: ownIntl }
  } = useIntlContext();

  const dispatch = useAppDispatch();
  const { sheduleInfoCache } = useAppState();

  const handleSubmit = React.useCallback(() => {
    dispatch({
      name: 'SheduduleEditorForm__Submit'
    });
  }, [dispatch]);

  const handleCancel = React.useCallback(() => {
    dispatch({
      name: 'SheduduleEditorForm__Cancel',
      oldValue: sheduleInfoCache.value
    });
  }, [dispatch, sheduleInfoCache.value]);

  const handleRestaurantChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        name: 'SheduduleEditorForm__RestaurantChange',
        value: event.target.value
      });
    },
    [dispatch]
  );

  const handleHuntingChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        name: 'SheduduleEditorForm__HuntingChange',
        value: event.target.value
      });
    },
    [dispatch]
  );

  const {
    formValues: values,
    submitDidSucceed,
    submited,
    formError
  } = useSheduleEditorState();

  const pending = submited && !submitDidSucceed && !formError;

  const someFieldsAreEmpty =
    !values.huntingShedule || !values.restaurantShedule;

  const hasChanges = React.useMemo(() => {
    if (sheduleInfoCache.didInvalidate === false) {
      const { value: sheduleInfo } = sheduleInfoCache;

      if (values.restaurantShedule !== sheduleInfo.restaurant) {
        return true;
      }

      if (values.huntingShedule !== sheduleInfo.hunting) {
        return true;
      }
    }

    return false;
  }, [sheduleInfoCache, values.huntingShedule, values.restaurantShedule]);

  const submitButtonLabel = submitDidSucceed
    ? ownIntl.submitDidSucceedLabel
    : ownIntl.submitLabel;

  const submitButtonDisabled =
    pending || someFieldsAreEmpty || submitDidSucceed;

  const hasCancelButton = !pending && hasChanges;

  const hasFooter = hasChanges || submitDidSucceed;

  return (
    <SettingsFormWrapper onSubmit={handleSubmit}>
      <SettingsFormFieldset disabled={pending}>
        <SettingsFormLegend titleText={ownIntl.restaurantSheduleLabel} />
        <CustomInput
          type="text"
          name="restaurant-shedule"
          placeholder={ownIntl.placeholder}
          value={values.restaurantShedule}
          onChange={handleRestaurantChange}
        />
        <br />
        <SettingsFormLegend titleText={ownIntl.huntingSheduleLabel} />
        <CustomInput
          type="text"
          name="hunting-shedule"
          placeholder={ownIntl.placeholder}
          value={values.huntingShedule}
          onChange={handleHuntingChange}
        />
      </SettingsFormFieldset>
      {hasFooter && (
        <SettingsFormFooter>
          {hasCancelButton && (
            <RectangleButton
              type="button"
              label={ownIntl.cancelLabel}
              styleType="secondary"
              onClick={handleCancel}
            />
          )}
          <RectangleButton
            label={submitButtonLabel}
            hasPreloader={pending}
            styleType="primary"
            isDisabled={submitButtonDisabled}
          />
        </SettingsFormFooter>
      )}
    </SettingsFormWrapper>
  );
};
