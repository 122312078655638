import React from 'react';

import { ChatRoom } from 'types/chats';
import { ChatPreviewLayout } from 'design/chatPreviewLayout';
import { Text } from 'design/text';
import { BooleanInputControl } from 'design/booleanInputControl';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  useChatMessageForm,
  useChatMessagesCollection
} from 'hooks/chats/useChatsState';
import { createCollectionCacheSelectors } from 'utils/collectionCacheUtils';
import { useIntlContext } from 'hooks/useIntlContext';
import { Bage } from 'components/shared/Bage';
import { FeedItem } from 'design/feedItem';
import { useActiveChatRoom } from 'hooks/chats/useActiveChatRoom';
import { useChangeChatReadStatusCallback } from 'hooks/chats/useChangeChatReadStatusCallback';
import { createChatPreviewDateText } from 'utils/createChatPreviewDateText';
import { setCustomTimeout } from 'utils/setCustomTimeout';
import { CHATS_PAGE_BAGES_DELAY } from 'utils/constants';

type Props = {
  room: ChatRoom;
};

export const ChatsPagePreview = (props: Props) => {
  const { room } = props;

  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState(0);

  const [readStatus, setReadStatus] = React.useState(true);

  const { id: activeChatRoomId } = useActiveChatRoom();

  const roomIsActive = activeChatRoomId === room.id;

  const { value: chatMessageFormValue } = useChatMessageForm(room.id);

  const intl = useIntlContext();

  const dispatch = useAppDispatch();

  const clientInfo = room.clientInfo;

  const messagesCollection = useChatMessagesCollection();

  const message = createCollectionCacheSelectors(
    messagesCollection
  ).selectItemStrict(room.lastMessageId);

  const fullNameText = intl.personFullName({
    firstName: clientInfo.firstName,
    middleName: clientInfo.middleName,
    lastName: `\n${clientInfo.lastName}`
  });

  const dateText = createChatPreviewDateText(message.createdAt, intl);

  const active = room.id === activeChatRoomId;

  const messageIsDraft = chatMessageFormValue && !active;

  const messageText = React.useMemo(() => {
    if (messageIsDraft) {
      return chatMessageFormValue;
    }

    if (message.deleted) {
      return intl.chatsPage.deletedMessageLabelText;
    }

    if (message.origin === 'CLIENT') {
      return message.text;
    }

    return intl.chatsPage.ourMessageText(message.text);
  }, [
    chatMessageFormValue,
    intl.chatsPage,
    message.deleted,
    message.origin,
    message.text,
    messageIsDraft
  ]);

  const changeReadStatusCallback = useChangeChatReadStatusCallback();

  const handleValueChange = React.useCallback(() => {
    changeReadStatusCallback(room.id, !room.read);
  }, [changeReadStatusCallback, room.id, room.read]);

  const activate = React.useCallback(() => {
    if (!room.read) {
      changeReadStatusCallback(room.id, true);
    }

    dispatch({
      name: 'CHATS_PAGE_PREVIEW__CLICKED',
      payload: room.id
    });
  }, [changeReadStatusCallback, dispatch, room.id, room.read]);

  React.useEffect(() => {
    // Бейдж непрочитанных сообщений отображается с задержкой,
    // чтобы избежать мерцания при получении сообщений в открытую
    // переписку.
    if (room.unreadMessagesCount > 0) {
      return setCustomTimeout(() => {
        setUnreadMessagesCount(room.unreadMessagesCount);
        setReadStatus(room.read);
      }, CHATS_PAGE_BAGES_DELAY);
    } else {
      setUnreadMessagesCount(room.unreadMessagesCount);
      setReadStatus(room.read);
    }
  }, [active, room.read, room.unreadMessagesCount, roomIsActive]);

  const showBage = unreadMessagesCount > 0;

  const showControl = unreadMessagesCount === 0;

  const showControlOnHoverOnly = unreadMessagesCount === 0 && readStatus;

  const controlValue = !readStatus;

  return (
    <FeedItem active={active}>
      <ChatPreviewLayout
        title={<Text>{fullNameText}</Text>}
        date={
          <Text bold size="s">
            {dateText}
          </Text>
        }
        message={
          <Text>
            {messageIsDraft && (
              <Text color="critical" size="s">
                Черновик:{' '}
              </Text>
            )}
            <Text color="secondary" size="s">
              {messageText}
            </Text>
          </Text>
        }
        showControlOnHoverOnly={showControlOnHoverOnly}
        bage={showBage && <Bage count={unreadMessagesCount} />}
        control={
          showControl && (
            // label необходим, чтобы работал клик по скрытому инпуту.
            <label>
              <BooleanInputControl
                value={controlValue}
                onValueChange={handleValueChange}
              />
            </label>
          )
        }
        onClick={activate}
      />
    </FeedItem>
  );
};
