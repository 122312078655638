import { TGetReviewsParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY, REVIEWS_FEED_PAGE_LENGTH } from 'utils/constants';
import { reviewsStoreMock } from 'services/mock/utils/reviewsModel';

const ITEMS_COUNT_PER_PAGE = REVIEWS_FEED_PAGE_LENGTH;

export const getReviews = async (params: TGetReviewsParams) => {
  const { pageNumber } = params;
  let SLICE_START_INDEX = (pageNumber - 1) * ITEMS_COUNT_PER_PAGE;
  await asyncTimeout(SERVICES_MOCK_DELAY);
  return reviewsStoreMock
    .getAllReviews()
    .slice(SLICE_START_INDEX, SLICE_START_INDEX + ITEMS_COUNT_PER_PAGE);
};
