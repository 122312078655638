import React from 'react';

import styles from './CompetitionsArticle.module.css';
import { RoundButton } from 'components/shared/RoundButton';
import { TrashIcon } from 'svg/20x20/trash';
import { PencilIcon } from 'svg/24x24/pencil';

type TProps = {
  onEdit(): void;
  onDelete(): void;
};

export const CompetitionsArticleActions = ({ onEdit, onDelete }: TProps) => (
  <div className={styles.CompetitionsArticle__ActionsWrapper}>
    <RoundButton
      className={styles.CompetitionsArticle__ActionsButton}
      onClick={onEdit}
    >
      <PencilIcon />
    </RoundButton>
    <RoundButton
      className={styles.CompetitionsArticle__ActionsButton}
      onClick={onDelete}
    >
      <TrashIcon />
    </RoundButton>
  </div>
);
