import React from 'react';

import './actionControl.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type ActionControlTheme =
  | 'round-primary'
  | 'round-primary-alt'
  | 'round-secondary'
  | 'round-secondary-alt'
  | 'round-floating'
  | 'round-cancel'
  | 'round-small'
  | 'square-delete'
  | 'invisible';

type Props = {
  theme: ActionControlTheme;
  children: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  type?: 'submit' | 'button';
  onClick?(): void;
  onDoubleClick?(): void;
};

export const ActionControl: React.FC<Props> = props => {
  const {
    children,
    type = 'button',
    theme,
    disabled,
    hidden,
    onClick,
    onDoubleClick
  } = props;

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const handleDoubleClick = React.useCallback(() => {
    if (onDoubleClick) {
      onDoubleClick();
    }
  }, [onDoubleClick]);

  const className = createBEMConstructor('action-control')({ theme, hidden });

  return (
    <button
      type={type}
      disabled={disabled}
      className={className}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {children}
    </button>
  );
};
