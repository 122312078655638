import React from 'react';

import styles from './ArticleEditorFooter.module.css';

import { RectangleButton } from 'components/shared/RectangleButton';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  isDisabled: boolean;
  hasPreloader: boolean;
  testId?: string;
  onClick(): void;
};

export const ArticleEditorFooterSubmitButton = (props: TProps) => {
  const { isDisabled, hasPreloader, testId, onClick } = props;

  const { articleEditorFooterSubmitButtonLabel: label } = useIntlContext();

  return (
    <RectangleButton
      testIdButton={testId}
      styleType={'primary'}
      className={styles.ArticleEditorFooter__SubmitButton}
      label={label}
      isDisabled={isDisabled}
      hasPreloader={hasPreloader}
      onClick={onClick}
    />
  );
};
