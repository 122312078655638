import React from 'react';

import styles from './CustomTextarea.module.css';

interface TProps {
  value: string;
  placeholder: string;
  name?: string;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  wrapperClassName?: string;
  textareaClassName?: string;
  hiddenValueClassName?: string;
  testId?: string;
  onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void;
  onBlur?(event: React.SyntheticEvent<HTMLTextAreaElement>): void;
}

export type TCustomTextareaProps = TProps;

export const CustomTextarea = (props: TProps) => {
  const {
    value,
    name,
    placeholder,
    autoFocus,
    maxLength,
    disabled,
    wrapperClassName = '',
    textareaClassName = '',
    hiddenValueClassName = '',
    onChange,
    onBlur,
    testId
  } = props;

  // Пустой пробел на конце помогает избежать схлопывания всего
  // компонента и появления полосы прокрутки при пустой новой строке
  const hiddenValue = value + ' ';

  return (
    <div className={`${styles.CustomTextarea__Wrapper} ${wrapperClassName}`}>
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={maxLength}
        autoFocus={autoFocus}
        className={`${styles.CustomTextarea__Textarea} ${textareaClassName}`}
        data-testid={testId}
      />
      <div
        className={`${styles.CustomTextarea__HiddenValue} ${hiddenValueClassName}`}
      >
        {hiddenValue}
      </div>
    </div>
  );
};
