import React from 'react';

import styles from './CustomDatePicker.module.css';

type TProps = {
  isCurrent: boolean;
  isDisabled: boolean;
  children: number;
  onClick(): void;
};

export const CustomDatePickerDateButton = ({
  isCurrent,
  isDisabled,
  ...nativeProps
}: TProps) => (
  <button
    type="button"
    disabled={isDisabled}
    className={`${styles.CustomDatePicker__DateButton} ${
      isCurrent ? styles.CustomDatePicker__DateButton_current : ''
    }`}
    {...nativeProps}
  />
);
