import React from 'react';

import styles from './ReviewsFeed.module.css';

import { Spinner } from 'components/shared/Spinner';
import { testId } from 'utils/testId';

export const ReviewsFeedPreloader = () => (
  <div
    className={styles.ReviewsFeed__Preloader}
    data-testid={testId.REVIEWS_FEED_PRELOADER}
  >
    <Spinner className={styles.ReviewsFeed__Spinner} />
  </div>
);
