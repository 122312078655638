import React from 'react';

import { TNewsFilter } from 'types/state';
import { useNewsFeed } from 'pages/NewsPage/hooks/useNewsFeed';
import { useNewsArticle } from 'pages/NewsPage/hooks/useNewsArticle';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';

export const useNewsPage = (activeFilter: TNewsFilter) => {
  // Состояние м методы взаимодействия с лентой статьей.
  const { load: loadFeed, itemsById: feedItemsById, loading } = useNewsFeed(
    activeFilter
  );

  // Состояние и методы взаимодействия с выбранной статьей.
  const {
    editedArticle,
    setEditedArticle,
    patchEditedArticle,
    selectArticleById,
    selectedArticleId
  } = useNewsArticle();

  // Эффект следит за лентой статей и устанавливает
  // id первого элемента ленты в качестве id для превью.
  React.useEffect(() => {
    if (feedItemsById.size > 0 && selectedArticleId === null) {
      // В случае если лента статей не пустая, но никакая
      // статья не выбрана, выбирается первая статья из ленты.
      const {
        value: { id: firstItemId }
      } = feedItemsById.values().next();

      selectArticleById(firstItemId);
    }
  }, [feedItemsById, selectedArticleId, selectArticleById]);

  // Для дальнейшей работы кэшированные статьи приводится к массиву.
  const cachedFeedItems = React.useMemo(() => {
    return Array.from(feedItemsById.values());
  }, [feedItemsById]);

  // В случае, если производится редактирование нового черновика
  // редактируемый объект подмешивается в ленту статьей.
  const feedItems = React.useMemo(() => {
    if (editedArticle && editedArticle.id === NEWS_ARTICLE_CANDIDATE_ID) {
      return [editedArticle, ...cachedFeedItems];
    }

    return cachedFeedItems;
  }, [cachedFeedItems, editedArticle]);

  // Мемоизированное значение выбранной статьи.
  // Может быть либо существующей статьей из ленты,
  // либо временным объектом статьи при редактировании,
  // лобо null.
  const selectedArticle = React.useMemo(() => {
    if (selectedArticleId && feedItemsById) {
      return feedItemsById.get(selectedArticleId) || null;
    }

    return null;
  }, [selectedArticleId, feedItemsById]);

  // Эффект отвечает за подгрузку ленты статьей до тех пор,
  // пока в ленте не появится выбранная статья. Позволяет после
  // редактирования статьи перезагрузить ленту и установить фокус
  // на отредактированную статью.
  React.useEffect(() => {
    if (
      selectedArticleId &&
      // Проверка на id кандидата позволяет предотвратить
      // загрузку ленты в режиме создания статьи.
      selectedArticleId !== NEWS_ARTICLE_CANDIDATE_ID &&
      !feedItemsById.has(selectedArticleId)
    ) {
      loadFeed();
    }
  }, [loadFeed, selectedArticleId, feedItemsById]);

  // Метод полной перезагрузки содержимого страницы.
  // Опционально принимает id статьи, которую необходимо сфокусирвать
  // после получение новых данных.
  const reloadFeed = React.useCallback(
    (targetId?: number) => {
      selectArticleById(targetId || null);
      loadFeed({ reload: true });
    },
    [selectArticleById, loadFeed]
  );

  return {
    editedArticle,
    patchEditedArticle,
    setEditedArticle,
    selectedArticle,
    selectArticleById,
    feedItems,
    loadFeed,
    reloadFeed,
    loading
  };
};
