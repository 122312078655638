import React from 'react';

import { TReview } from 'types/state';
import { normalizeReview } from 'utils/normalizeReview';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  review: TReview;
  selected: boolean;
  onClick(id: number): void;
};

export const useReviewItemState = (props: TProps) => {
  const { review, selected, onClick } = props;

  const handleClick = React.useCallback(() => {
    onClick(review.id);
  }, [review.id, onClick]);

  const intl = useIntlContext();

  const item = React.useMemo(() => {
    return normalizeReview.toReviewsFeedItem(review, intl);
  }, [review, intl]);

  const hasAccentTheme = review.status === 'NEW';

  const hasSecondaryTheme = review.status === 'PROCESSED';

  return {
    item,
    onClick: handleClick,
    selected,
    hasAccentTheme,
    hasSecondaryTheme
  };
};
