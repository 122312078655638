import { AppEvent } from 'types/appEvent';

export const competitionRequestsCountsByIdReducer = (
  state: Map<number, number>,
  event: AppEvent
): Map<number, number> => {
  switch (event.name) {
    case 'COMPETITION_REQUESTS_COUNT_SERVICE': {
      if (event.status === 'RESOLVED') {
        const nextState = new Map(state);

        nextState.set(event.meta, event.payload);

        return nextState;
      }

      return state;
    }
    default:
      return state;
  }
};
