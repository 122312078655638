import { TNewsAudienceGroup } from 'types/state';
import { TSportingPerson } from 'services/sporting/types';
import { UnreachableError } from 'utils/errors';

export const normalizeSportingPerson = {
  toAudienceGroup: (group: TSportingPerson): TNewsAudienceGroup => {
    switch (group.id) {
      case 1:
        return 'usual';
      case 2:
        return 'gman';
      case 3:
        return 'force';
      case 4:
        return 'sportsmen';
      case 5:
        return 'hunter';
      default:
        throw new UnreachableError(group.id);
    }
  }
};
