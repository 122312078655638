import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from './useAsyncController';
import { useSession } from './useSession';

export const usePhoneNumbersService = () => {
  const { getPhoneNumbers: getPhoneNumbersService } = useServices();

  const dispatch = useAppDispatch();

  const {
    wrapAsync: wrapPhoneNumbersPulling,
    asyncProcess: phoneNumberPullingProcess
  } = useAsyncController<string[]>();

  const session = useSession();

  React.useEffect(() => {
    if (session?.type === 'AUTHORIZED') {
      const promise = getPhoneNumbersService();

      wrapPhoneNumbersPulling(promise);
    }
  }, [session, getPhoneNumbersService, wrapPhoneNumbersPulling]);

  React.useEffect(() => {
    if (phoneNumberPullingProcess.status === 'PENDING') {
      dispatch({
        name: 'PHONE_NUMBERS_SERVICE',
        status: 'PENDING'
      });
    }

    if (phoneNumberPullingProcess.status === 'RESOLVED') {
      dispatch({
        name: 'PHONE_NUMBERS_SERVICE',
        status: 'RESOLVED',
        payload: phoneNumberPullingProcess.value
      });
    }
  }, [dispatch, phoneNumberPullingProcess]);
};
