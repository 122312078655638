import React from 'react';
import { multiplyClassName } from 'utils/className';

import styles from './applicationLayoutStyles.module.scss';

type Props = {
  theme: 'mobile' | 'desktop';
  icon: React.ReactNode;
  number: React.ReactNode;
  time: React.ReactNode;
  name: React.ReactNode;
  phoneCallControl: React.ReactNode;
  phoneNumberCopyingControl: React.ReactNode;
  bookingControl: React.ReactNode;
  statusControl: React.ReactNode;
};

export const ApplicationLayoutComponent = (props: Props) => {
  const {
    theme,
    icon,
    number,
    time,
    name,
    phoneCallControl,
    phoneNumberCopyingControl,
    bookingControl,
    statusControl
  } = props;

  return (
    <button
      className={multiplyClassName([
        styles.wrapper,
        theme === 'mobile'
          ? styles.wrapper__theme_mobile
          : styles.wrapper__theme_desktop
      ])}
    >
      <div className={styles.iconSlot}>{icon}</div>
      <div className={styles.numberSlot}>{number}</div>
      <div className={styles.timeSlot}>{time}</div>
      <div className={styles.nameSlot}>{name}</div>
      <div className={styles.phoneCallControlSlot}>{phoneCallControl}</div>
      <div className={styles.phoneNumberCopyingControlSlot}>
        {phoneNumberCopyingControl}
      </div>
      <div className={styles.bookingControlSlot}>{bookingControl}</div>
      <div className={styles.statusControlSlot}>{statusControl}</div>
    </button>
  );
};
