import React from 'react';
import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';
import { TGetReservationsParams } from 'types/services';

export const useReservationsService = () => {
  const dispatch = useAppDispatch();
  const services = useServices();

  const { wrapAsync, asyncProcess } = useAsyncController();

  const loadReservations = React.useCallback(
    (params: TGetReservationsParams) => {
      const promise = services.getReservations(params).then(reservations => {
        dispatch({
          name: 'ReservationsRecieved',
          reservations
        });
      });

      wrapAsync(promise);
    },
    [dispatch, services, wrapAsync]
  );

  return { loadReservations, asyncProcess };
};
