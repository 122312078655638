import React from 'react';

import styles from './NewsFeed.module.css';

import { Spinner } from 'components/shared/Spinner';
import { testId } from 'utils/testId';

export const NewsFeedPreloader = () => (
  <div
    className={styles.NewsFeed__Preloader}
    data-testid={testId.NEWS_FEED_PRELOADER}
  >
    <Spinner />
  </div>
);
