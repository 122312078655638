import { getUid } from 'utils/getUid';
import { ClientInfo } from 'types/client';
import { createRandomBoolean } from 'utils/createRandomBoolean';

export const createClientInfo = (): ClientInfo => ({
  id: getUid(),
  firstName: 'Иван',
  lastName: 'Иванов',
  middleName: 'Иванович',
  status: 'STANDARD',
  cardNumber: '100298',
  phoneNumber: '+375 44 112-22-32',
  blacklisted: createRandomBoolean()
});
