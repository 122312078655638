import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useIntlContext } from 'hooks/useIntlContext';
import { ModalPortal } from 'components/shared/Modal';
import { ModalWindow } from 'components/Modal/ModalWindow';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { RectangleButton } from 'components/shared/RectangleButton';
import { useUserToBlockedState } from 'hooks/employee/useUserToBlockedState';

export const EmployeeBlockedModal = () => {
  const dispatch = useAppDispatch();

  const userToBlockedState = useUserToBlockedState();
  const { userId, toBlocked } = userToBlockedState.formValues;

  const {
    blockedEmployeeModalLabels: {
      titleLock,
      titleUnlock,
      descriptionLock,
      descriptionUnlock,
      submitButtonLabelLock,
      submitButtonLabelUnlock,
      cancelButtonLabel
    }
  } = useIntlContext();

  const title = toBlocked ? titleLock : titleUnlock;
  const description = toBlocked ? descriptionLock : descriptionUnlock;
  const submitButtonLabel = toBlocked
    ? submitButtonLabelLock
    : submitButtonLabelUnlock;

  const onCancel = React.useCallback(() => {
    dispatch({
      name: 'EMPLOYEE_BLOCKED_MODAL__CANCEL',
      userId: userId,
      blockedState: toBlocked
    });
  }, [dispatch, userId, toBlocked]);

  const onSubmit = React.useCallback(() => {
    dispatch({
      name: 'EMPLOYEE_BLOCKED_MODAL__SUBMIT',
      userId: userId,
      blockedState: toBlocked
    });
  }, [dispatch, userId, toBlocked]);

  const busy =
    userToBlockedState.submited && !userToBlockedState.submitDidSucceed;

  return (
    <ModalPortal>
      <ModalWindow>
        <ModalBody title={title} description={description} />
        <ModalFooter>
          <RectangleButton
            styleType={'secondary'}
            label={cancelButtonLabel}
            isDisabled={busy}
            onClick={onCancel}
          />
          <RectangleButton
            styleType={'primary'}
            label={submitButtonLabel}
            hasPreloader={busy}
            onClick={onSubmit}
          />
        </ModalFooter>
      </ModalWindow>
    </ModalPortal>
  );
};
