import { PersonalDataFormError, PersonalDataFormValues } from 'types/state';

export const validatePersonalDataFormValues = (
  values: PersonalDataFormValues
): PersonalDataFormError | undefined => {
  if (!values.lastName) {
    return 'LASTNAME_IS_EMPTY';
  }
  if (!values.firstName) {
    return 'FIRSTNAME_IS_EMPTY';
  }
};
