import { TCompetitionDurationCandidate } from 'types/state';

export const getDefaultDuration = (): TCompetitionDurationCandidate => ({
  startDate: null,
  endDate: null
});

type TGetNextDurationValueParams = {
  nextStartDate?: string;
  nextEndDate?: string;
};

export const getNextDurationValue = ({
  nextStartDate,
  nextEndDate
}: TGetNextDurationValueParams) => {
  return ({
    startDate,
    endDate
  }: TCompetitionDurationCandidate): TCompetitionDurationCandidate => {
    if (nextStartDate && endDate) {
      if (new Date(nextStartDate) > new Date(endDate)) {
        return {
          startDate: nextStartDate,
          endDate: null
        };
      }
    }
    if (nextEndDate && startDate) {
      if (new Date(nextEndDate) < new Date(startDate)) {
        return {
          endDate: nextEndDate,
          startDate: null
        };
      }
    }
    return {
      startDate: nextStartDate || startDate,
      endDate: nextEndDate || endDate
    };
  };
};

export const formatISODateToDurationInputDate = (
  ISODate: string,
  intlMonthMap: string[]
) => {
  const date = new Date(ISODate);
  return `${date.getDate()} ${intlMonthMap[date.getMonth()]}`;
};
