import { stripToDigits } from 'utils/stringUtils';

export const toMaskedPhone = (phone: string) => {
  const phoneDigits = stripToDigits(phone);

  const countryCode = phoneDigits.slice(0, 3);
  const networkCode = phoneDigits.slice(3, 5);
  const phonePart1 = phoneDigits.slice(5, 8);
  const phonePart2 = phoneDigits.slice(8, 10);
  const phonePart3 = phoneDigits.slice(10, 12);

  return `+${countryCode} ${networkCode} ${phonePart1}-${phonePart2}-${phonePart3}`;
};
