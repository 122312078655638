import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { useServices } from 'hooks/useServices';
import React from 'react';
import { AuthorizationError } from 'utils/errors';

type Props = {
  onSignedOut(): void;
};

export const AppAuthorizationFallbackComponent: React.FC<Props> = props => {
  const { onSignedOut } = props;

  const { signOut } = useServices();

  const dispatch = useAppDispatch();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    const promise = signOut()
      .then(() => {
        onSignedOut();
      })
      .catch(error => {
        if (error instanceof AuthorizationError) {
          onSignedOut();
        }

        throw error;
      });

    wrapAsyncSafely(promise);
  }, [dispatch, onSignedOut, signOut, wrapAsyncSafely]);

  return null;
};
