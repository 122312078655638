import React from 'react';

import styles from './SettingsForm.module.css';

type Props = {
  children: React.ReactNode;
  onSubmit(): void;
};

export const SettingsFormWrapper: React.FC<Props> = props => {
  const { children, onSubmit } = props;

  const handleSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <form className={styles.SettingsForm__Wrapper} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};
