import { useCompetitionsRequestsCount } from 'hooks/useCompetitionsRequestsCount';
import { useNewRestaurantApplicationsCount } from 'hooks/useNewRestaurantApplicationsCount';
import { useNewSportApplicationsCount } from 'hooks/useNewSportApplicationsCount';
import { useNewReviewsCount } from 'hooks/useNewReviewsCount';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { useUnreadChatIdLists } from 'hooks/chats/useChatsState';
import { createListCacheSelectors } from 'utils/listCacheUtils';

export const useShouldUpdateFaviconFlag = () => {
  const enabledSections = useEnabledSectionsStrict();

  const restaurantCount = useNewRestaurantApplicationsCount();

  const unreadChatIdsLists = useUnreadChatIdLists();

  const sportCount = useNewSportApplicationsCount();

  const competitionsRequestsCount = useCompetitionsRequestsCount();

  const newReviewsCount = useNewReviewsCount();

  const hasRestaurantApplications =
    enabledSections.includes('applicationsRestaurant') &&
    restaurantCount.value > 0;

  const hasRestaurantUnreadChats =
    enabledSections.includes('chatsRestaurant') &&
    createListCacheSelectors(unreadChatIdsLists.restaurantIds).selectAllItems()
      .length > 0;

  const hasSportApplications =
    enabledSections.includes('applicationsSport') && sportCount.value > 0;

  const hasSportUnreadChats =
    enabledSections.includes('chatsSport') &&
    createListCacheSelectors(unreadChatIdsLists.sportIds).selectAllItems()
      .length > 0;

  const hasCompetitionsRequests =
    enabledSections.includes('competitions') &&
    competitionsRequestsCount.value > 0;

  const hasNewFeedback =
    enabledSections.includes('feedback') && newReviewsCount.value > 0;

  return (
    hasRestaurantApplications ||
    hasRestaurantUnreadChats ||
    hasSportApplications ||
    hasSportUnreadChats ||
    hasCompetitionsRequests ||
    hasNewFeedback
  );
};
