import {
  BlockedEmployeeModalIntl,
  ChatsPageIntl,
  DeleteUserModalIntl,
  EmployeeEditModalIntl,
  LoginEditorFormIntl,
  LoginEditorIntl,
  MembersPageIntl,
  MemberTableItemIntl,
  MessageTemplatesIntl,
  NavigationIntl,
  NewUserFormIntl,
  NoActiveSectionsPageIntl,
  PasswordEditorIntl,
  PasswordFormIntl,
  PersonalDataEditorIntl,
  PersonalDataFormIntl,
  SectionsEditorIntl,
  SectionsFormIntl,
  SettingsPageIntl,
  SheduleEditorIntl,
  TIntl,
  UserFormIntl,
  UsersEditorIntl,
  UsersTableIntl
} from 'types/intl';
import { HTTPConnectionError, HTTPResponseError } from 'utils/errors';
import { DayOfWeek, UserRole } from '../types/state';

const predicativeMonthNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

const predicativeShortMonthNames = [
  'янв',
  'фев',
  'мар',
  'апр',
  'мая',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек'
];

const nominativeMonthNames = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь'
];

const dayNames = [
  'воскресенье',
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'суббота'
];

const shortDayNames = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'cб'];

const settingsPageIntl: SettingsPageIntl = {
  title: 'Настройки'
};

const navigationIntl: NavigationIntl = {
  applocationsRestarauntLabel: 'Заявки ресторан',
  chatsRestaurantLabel: 'Чаты ресторан',
  bookingRestaurantLabel: 'Бронь ресторан',
  applicationsSportLabel: 'Заявки клуб',
  chatsSportLabel: 'Чаты клуб',
  bookingClubLabel: 'Бронь клуб',
  newsLabel: 'Новости',
  competitionsLabel: 'Соревнования',
  feedbackLabel: 'Отзывы',
  membersLabel: 'Члены клуба',
  usersLabel: 'Пользователи',
  settingsLabel: 'Настройки',
  signOutLabel: 'Выйти'
};

const sectionsEditorIntl: SectionsEditorIntl = {
  title: 'Отображение разделов',
  description: [
    `Вы можете скрыть разделы, которые\nне нужны для вашей работы.`,
    `В любой момент вы сможете снова отобразить раздел и получать по нему\nсоответствующие уведомления.`
  ]
};

const sectionsFormIntl: SectionsFormIntl = {
  applicationsRestaurantLabel: 'Заявки ресторан',
  chatsRestaurantLabel: 'Чаты ресторан',
  bookingRestaurantLabel: 'Бронь ресторан',
  applicationsSportLabel: 'Заявки клуб',
  chatsSportLabel: 'Чаты клуб',
  bookingClubLabel: 'Бронь клуб',
  newsLabel: 'Новости',
  competitionsLabel: 'Соревнования',
  feedbackLabel: 'Отзывы',
  membersLabel: 'Члены клуба',
  usersLabel: 'Пользователи',
  submitLabel: 'Сохранить',
  submitedLabel: 'Сохранено',
  cancelLabel: 'Отмена'
};

const personalDataEditorIntl: PersonalDataEditorIntl = {
  title: 'Личные данные',
  description: 'Измените ваши личные данные\nпри необходимости.'
};

const loginEditorIntl: LoginEditorIntl = {
  title: 'Логин',
  description: 'Измените логин вашей учетной записи\nпри необходимости.'
};

const passwordEditorIntl: PasswordEditorIntl = {
  title: 'Пароль',
  description: 'Измените пароль вашей учетной\nзаписи при необходимости.'
};

const sheduleEditorIntl: SheduleEditorIntl = {
  title: 'Время работы',
  description:
    'Указанное время работы отображается\nдля всех клиентов в клубном\nмобильном приложении.',
  form: {
    restaurantSheduleLabel: 'Ресторан',
    huntingSheduleLabel: 'Клубный дом',
    placeholder: 'Укажите время работы',
    submitLabel: 'Сохранить',
    cancelLabel: 'Отмена',
    submitDidSucceedLabel: 'Сохранено'
  }
};

const usersTableIntl: UsersTableIntl = {
  usersTableColumnName: 'Имя',
  usersTableColumnNumber: 'Номер',
  usersTableColumnLogin: 'Логин'
};

const userFormIntl: UserFormIntl = {
  loginLabel(login: string) {
    return `Логин: ${login}`;
  },
  phoneNumberLabel(phone: string) {
    return `Номер ${phone}`;
  },
  phoneNumberFallback: 'Нет',
  passwordInputPlaceholder: 'Новый пароль',
  passwordConfirmInputPlaceholder: 'Подтвердите новый пароль',
  submitDidSucceedLabel: 'Сохранено',
  submitLabel: 'Сохранить',
  cancelLabel: 'Отмена'
};

const personalDataFormIntl: PersonalDataFormIntl = {
  lastNameInputLabel: 'Ваша фамилия',
  lastNameInputPlaceholder: 'Укажите фамилию',
  lastNameEmptyError: 'Не указана фамилия',
  firstNameInputLabel: 'Ваше имя',
  firstNameInputPlaceholder: 'Укажите имя',
  firstNameEmptyError: 'Не указано имя',
  phoneNumberInputLabel: 'Номер телефона',
  submitLabel: 'Сохранить',
  submitDidSucceedLabel: 'Сохранено',
  cancelLabel: 'Отмена'
};

const loginFormIntl: LoginEditorFormIntl = {
  loginInputLabel: 'Ваш логин',
  loginInputPlaceholder: 'Придумайте логин',
  passwordInputLabel: 'Текущий пароль',
  passwordInputPlaceholder: 'Введите пароль',
  submitLabel: 'Сохранить',
  submitDidSucceedLabel: 'Сохранено',
  cancelLabel: 'Отмена',
  loginEmptyError: 'Не указан логин',
  loginInUseError: 'Такой логин уже существует',
  passwordEmptyError: 'Не указан пароль',
  passwordInvalidError: 'Неверный пароль'
};

const passwordFormIntl: PasswordFormIntl = {
  legendTitleLabel: 'Изменить пароль',
  passwordInputLabel: 'Новый пароль',
  passwordConfirmInputLabel: 'Подтвердите новый пароль',
  submitLabel: 'Сохранить',
  submitDidSucceedLabel: 'Сохранено',
  cancelLabel: 'Отмена',
  passwordsDidNotMatchError: 'Пароли не совпадают'
};

const usersEditorIntl: UsersEditorIntl = {
  title: 'Сотрудники',
  description: [
    `Измените пароль учетной записи\nсотрудника при необходимости.`,
    `Вы также можете добавить новую\nучетную запись сотрудника.`
  ],
  newUserButtonLabel: 'Добавить сотрудника'
};

const newUserFormIntl: NewUserFormIntl = {
  title: 'Новый сотрудник',
  loginInputLabel: 'Укажите логин',
  passwordInputLabel: 'Укажите пароль',
  passwordConfirmInputLabel: 'Подтвердите пароль',
  submitLabel: 'Сохранить',
  cancelLabel: 'Отмена',
  passwordsDidNotMatchError: 'Пароли не совпадают',
  passwordsIsMissingError: 'Вы не указали пароль',
  loginIsMissingError: 'Вы не указали логин',
  loginInUseError: 'Такой логин уже занят'
};

const deleteUserModalIntl: DeleteUserModalIntl = {
  title: 'Удалить учетную запись?',
  description:
    'Учетная запись сотрудника будет удалена без возможности ее восстановления.',
  submitButtonLabel: 'Удалить',
  cancelButtonLabel: 'Отмена'
};

const blockedEmployeeModalIntl: BlockedEmployeeModalIntl = {
  titleLock: 'Заблокировать доступ?',
  titleUnlock: 'Разблокировать доступ?',
  descriptionLock:
    'Сотруднику будет ограничен доступ в программу, вы сможете разблокировать его в любое время.',
  descriptionUnlock: 'Сотруднику будет открыт доступ в программу.',
  submitButtonLabelLock: 'Заблокировать',
  submitButtonLabelUnlock: 'Разблокировать',
  cancelButtonLabel: 'Отмена'
};

const employeeEditModalIntl: EmployeeEditModalIntl = {
  roleSelectLabel: 'Роль пользователя',
  numberSelectLabel: 'Номер',
  lastNameInputLabel: 'Фамилия',
  lastNameInputPlaceholder: 'Введите фамилию',
  lastNameInputEmpty: 'Не указана фамилия',
  firstNameInputLabel: 'Имя',
  firstNameInputPlaceholder: 'Введите имя',
  firstNameInputEmpty: 'Не указано имя',
  loginInputLabel: 'Логин',
  loginInputPlaceholder: 'Введите логин',
  loginInputEmpty: 'Не указан логин',
  loginInputExist: 'Такой логин уже существует',
  passwordInputLabel: 'Пароль',
  passwordInputPlaceholder: 'Новый пароль',
  passwordConfirmInputPlaceholder: 'Подтвердите новый пароль',
  passwordInputEmpty: 'Не указан пароль',
  passwordNotEqualPasswordConfirm: 'Пароли не совпадают',
  submitBtnLabel: 'Сохранить',
  cancelBtnLabel: 'Отмена'
};

const chatsPageIntl: ChatsPageIntl = {
  navigationFallbackText: 'Нет чатов',
  navigationTitle: 'Чаты',
  messageInputPlaceholder: 'Напишите сообщение…',
  messageCandidateRetry: 'Сообщение не отправлено',
  deletedMessageLabelText: 'Сообщение удалено',
  editedMessageLabelText: 'изменено',
  ourMessageText(text) {
    return `Вы: ${text}`;
  },
  typingText(names) {
    const namesText = names.reduce((acc, name) => {
      if (acc) {
        return `${acc} и ${name}`;
      }

      return name;
    }, '');

    return `${namesText} печатает`;
  }
};

const messageTemplatesIntl: MessageTemplatesIntl = {
  addTemplateLabel: 'Добавить шаблон ответа',
  inputPlaceholder: 'Текст'
};

const membersPageIntl: MembersPageIntl = {
  cardNumberInputPlaceholder: 'Введите номер карты',
  contentFallbackLabel: 'Карта не найдена',
  contentLabel: 'Члены клуба'
};

const memberTableItemIntl: MemberTableItemIntl = {
  verificationValueText(verified) {
    if (verified) {
      return 'проверен';
    }

    return 'надо проверить';
  }
};

const noActiveSectionsPageIntl: NoActiveSectionsPageIntl = {
  title: 'Для вас нет активных разделов'
};

export const ruIntl: TIntl = {
  yesterday: 'Вчера',
  today: 'Сегодня',

  personFullName({ lastName, firstName, middleName }) {
    return [lastName, firstName, middleName].reduce((acc, item) => {
      if (item) {
        return `${acc} ${item}`.trim();
      }

      return acc;
    }, '');
  },

  todayAt: (time: string) => `Сегодня в ${time}`,
  yesterdayAt: (time: string) => `Вчера в ${time}`,
  calendarDateAt: (date: string, time: string) => `${date} в ${time}`,

  smsCountWarning:
    'Осталось меньше 100 SMS для рассылки. Пожалуйста, свяжитесь с разработчиками: +375 29 860-76-61 (Михаил)',

  copyToClipboartDidSucceed: 'Скопировано в буфер',

  dayNames,
  shortDayNames,
  predicativeMonthNames,
  predicativeShortMonthNames,
  nominativeMonthNames,

  unexpectedError: 'Возникла непредвиденная ошибка',
  serverConnectionError:
    'Связь с сервером потеряна, данные не будут сохранены…',
  noInternetConnectionError: 'Подключение к интернету отсутствует…',

  imageInputPlaceholder: 'Добавить изображение',
  imageInputSizeMessage: 'Минимум 1600х1600 px',

  deleteArticleModalCancel: 'Отмена',
  deleteArticleModalConfirm: 'Удалить',

  articleEditorFooterSubmitButtonLabel: 'Опубликовать',
  articleEditorFooterSubmitDraftButtonLabel: 'Сохранить черновик',
  articleEditorFooterCancelButtonLabel: 'Отмена',
  articleEditorFooterNotifyCheckboxLabel: 'Уведомить адресатов об изменениях',

  signInPasswordInputPlaceholder: 'Пароль',
  signInUsernameInputPlaceholder: 'Логин',
  signInSubmitButtonLabel: 'Войти',
  signInInvalidCredentials: 'Неверный логин или пароль',
  signInUserIsBlocked: 'Доступ в программу ограничен',

  newsPageEmpty: 'Нет новостей',
  newsPageCreateItem: 'Добавить новость',

  newsFeedItemDraftSubtitle: 'Черновик',
  newsFeedItemTitlePlaceholder: 'Название новости',

  newsFeedFilterAll: 'Все новости',
  newsFeedFilterDrafts: 'Черновики',
  newsFeedFilterPublished: 'Опубликованные',

  audienceInputTitle: 'Выберите адресатов',
  audienceGroupAll: 'Все клиенты',
  audienceGroupForce: 'Силовики',
  audienceGroupGMan: 'Чиновники',
  audienceGroupHunter: 'Охотники',
  audienceGroupSportsmen: 'Спортсмены',
  audienceGroupUsual: 'Обычные',

  newsArticleCategoryRadioGroupTitle: 'Выберите категорию',
  newsArticleCategoryRadioClub: 'Клуб',
  newsArticleCategoryRadioRestaurant: 'Ресторан',

  newsArticleTitleIputPlaceholder: 'Название новости',
  newsArticleDescriptionIputPlaceholder: 'Описание...',

  newsArticleImageSizeError: 'Максимальный размер файла — 15 Мб',
  newsArticleImageTypeError: 'Выберите файл c форматом jpg или png',
  newsArticleImageDimensionsError: 'Минимум 1600х1600 px',

  cropImageModalConfirm: 'Готово',
  cropImageModalCancel: 'Отмена',

  deleteNewsArticleModalTitle: 'Удалить новость?',
  deleteNewsArticleModalDescription:
    'Новость будет удалена для всех пользователей мобильного приложения',

  competitionsPageEmpty: 'Нет соревнований',
  competitionsPageCreateItem: 'Добавить соревнование',
  competitionsFeedFilterUpcoming: 'Ближайшие',
  competitionsFeedFilterPast: 'Прошедшие',
  competitionsFeedItemDraftPretitle: 'Черновик',
  competitionsFeedItemTitlePlaceholder: 'Название соревнования',

  competitionsArticleTitleIputPlaceholder: 'Название соревнования',
  competitionsArticleDescriptionIputPlaceholder: 'Описание...',

  competitionStartDatePlaceholder: 'Дата начала',
  competitionEndDatePlaceholder: 'Дата окончания',

  competitionsPreviewTabDescription: 'Описание события',
  competitionsPreviewTabParticipants: 'Список участников',
  competitionsPreviewTabNotes: 'Заметки по соревнованиям',

  competitionParticipantStatusSelectParticipationRequested: 'Запрос на участие',
  competitionParticipantStatusSelectParticipationConfirmed:
    'Участие подтверждено',
  competitionParticipantStatusSelectCancelationRequested:
    'Запрос на отмену участия',
  competitionParticipantStatusSelectCancelationConfirmed: 'Участие отменено',

  competitionParticipantsTableColumnName: 'Имя участника',
  competitionParticipantsTableColumnPhone: 'Телефон',
  competitionParticipantsTableColumnStatus: 'Статус',

  competitionParticipantsStatusHistoryDate(date: string, time: string) {
    return `${date} в ${time}`;
  },

  competitionNotesPlaceholder:
    'Укажите любые важные обстоятельства о соревновании...',

  deleteCompetitionsArticleModalTitle: 'Удалить соревнование?',
  deleteCompetitionsArticleModalDescription:
    'Соревнование будет удалено для всех пользователей мобильного приложения',

  applicationsTableColumnCardNumber: '№ карты',
  applicationsTableColumnStatus: 'статус',
  applicationsTableColumnPhone: 'телефон',
  applicationsTableColumnName: 'имя',
  applicationsTableColumnCreatedAtDate: 'время обращения',
  applicationsTableColumnTimer: 'таймер',
  applicationsTableColumnDone: 'выполнена',

  applicationsGroupTodayPrefix: 'Сегодня',
  applicationsGroupYesterdayPrefix: 'Вчера',
  applicationsGroupEmptyFallback: 'Нет заявок',
  applicationsGroupNewTitle: 'Новые заявки',

  toastNewRestaurantApplicaitionTitle: 'Ресторан. Новая заявка',
  toastNewRestaurantApplicaitionBody: 'Нажмите, чтобы перейти',
  toastNewSportApplicaitionTitle: 'Клуб. Новая заявка',
  toastNewSportApplicaitionBody: 'Нажмите, чтобы перейти',
  toastNewRestaurantChatMessageTitle: 'Ресторан. Новое сообщение',
  toastNewRestaurantChatMessageBody: 'Нажмите, чтобы перейти',
  toastNewSportChatMessageTitle: 'Клуб. Новое сообщение',
  toastNewSportChatMessageBody: 'Нажмите, чтобы перейти',

  reviewsFeedTitle: 'Все отзывы',
  reviewsPageFallback: 'Нет отзывов',
  reviewNotesTitle: 'Внутренние заметки',
  reviewNotesInputPlaceholder: 'Текст заметки...',
  reviewStatusNew: 'Новый',
  reviewStatusProcessed: 'Прочитан',
  reviewItemTitlePrefix: 'Отзыв',
  reviewPreviewTitlePrefix: 'Отзыв',
  reviewPreviewDateLabel: 'Дата и время',
  reviewPreviewNameLabel: 'Имя клиента',
  reviewPreviewEmailLabel: 'Email',
  reveiwPreviewPhoneLabel: 'Телефон',
  reviewPreviewMessageLabel: 'Отзыв',
  reviewPreviewOrderNumberLabel: 'Номер заказа',
  reviewPreviewFoodScoreLabel: 'Кухня',
  reviewPreviewServiceScoreLabel: 'Персонал',

  processedInLessThanMinuteText: '1 мин',

  processedInLessThanHour: (minutes: number) => `${minutes} мин`,

  processedInMoreThanHour: (hours: number, minutes: number) =>
    `${hours} ч ${minutes} м`,
  phoneNumberFallback: 'Нет',

  settingsPage: settingsPageIntl,
  navigation: navigationIntl,
  sectionsEditor: sectionsEditorIntl,
  sectionsForm: sectionsFormIntl,
  personalDataEditor: personalDataEditorIntl,
  personalDataForm: personalDataFormIntl,
  loginEditor: loginEditorIntl,
  loginEditorForm: loginFormIntl,
  passwordEditor: passwordEditorIntl,
  passwordForm: passwordFormIntl,
  sheduleEditor: sheduleEditorIntl,
  usersEditor: usersEditorIntl,
  newUserForm: newUserFormIntl,
  userForm: userFormIntl,
  deleteUserModal: deleteUserModalIntl,
  chatsPage: chatsPageIntl,
  messageTemplates: messageTemplatesIntl,

  membersPage: membersPageIntl,

  usersTable: usersTableIntl,
  memberTableItem: memberTableItemIntl,
  blockedEmployeeModalLabels: blockedEmployeeModalIntl,
  employeeEditModalLabels: employeeEditModalIntl,

  noActiveSectionsPage: noActiveSectionsPageIntl,

  roleIntl: {
    toLabel(role: UserRole): string {
      switch (role) {
        case 'ADMINISTRATOR':
          return 'Администратор';
        case 'MANAGER':
          return 'Менеджер';
        case 'CURATOR':
          return 'Куратор';
        case 'SECURITY':
          return 'Охранник';
        default:
          return 'Роль не определена';
      }
    }
  },

  appFallback: {
    titleText: 'Возникла ошибка',
    detailsText(error) {
      let baseText = '';

      if (error instanceof HTTPConnectionError) {
        baseText = 'Невозможно установить соединение с сервером.';
      }

      if (error instanceof HTTPResponseError) {
        baseText = `Получен ответ сервера со статусом ${error.status}.`;
      }

      return `${baseText}${baseText && '\n'}Попробуйте обновить страницу`;
    },
    refreshText: 'Обновить',
    signOutText: 'Выйти'
  },

  bookingHeaderIntl: {
    addBookingBtnLabel: 'Бронь'
  },

  bookingTimeWorkSettingsIntl: {
    title: 'Время работы',
    startDate: 'Начало',
    endDate: 'Конец',
    saveBtnLabel: 'Сохранить',
    cancelBtnLabel: 'Отмена',
    weeklySheduleButtonText: 'На каждый день',
    dailySheduleButtonText: 'На все дни',
    dayLabel(day: DayOfWeek): string {
      switch (day) {
        case 'MONDAY':
          return 'Понедельник';
        case 'TUESDAY':
          return 'Вторник';
        case 'WEDNESDAY':
          return 'Среда';
        case 'THURSDAY':
          return 'Четверг';
        case 'FRIDAY':
          return 'Пятница';
        case 'SATURDAY':
          return 'Суббота';
        case 'SUNDAY':
          return 'Воскресенье';
        default:
          return '';
      }
    },
    notificationMessageTitle: 'Вы не можете сохранить изменения',
    notificationMessageText:
      'На установленный ранее промежуток времени есть\n запланированные брони. Перенесите или удалите их.',
    notificationMessageSubmitLabel: 'Понятно, закрыть'
  },

  bookingTableSettingsIntl: {
    title: 'Настройка таблицы',
    submitBtnLabel: 'Сохранить',
    cancelBtnLabel: 'Отмена',
    notificationMessageTitle: 'Вы не можете удалить объект',
    notificationMessageText:
      'На выбранный объект есть запланированные брони.\n Перенесите или удалите их.',
    notificationMessageSubmitLabel: 'Понятно, закрыть'
  },

  bookingPlaceIntl: {
    namePlaceholder: 'Введите название',
    nameLabel: 'Название',
    countGuestLabel: 'Кол-во гостей',
    addPlaceRestaurantBtn: 'Добавить столик',
    addPlaceClubBtn: 'Добавить площадку'
  },

  bookingPlaceItemIntl: {
    nameLabel: 'Введите название',
    namePlaceholder: 'Название',
    countGuestLabel: 'Кол-во гостей',
    minCountGuestPlaceholder: 'От',
    maxCountGuestPlaceholder: 'До'
  },

  bookingReservationIntl: {
    newReservationLabel: 'Новая бронь',
    editReservationLabel: 'Редактировать бронь',
    countGuestLabel: 'Количествово гостей',
    dateAndTimeLabel: 'Дата и время',
    dateStartLabel: 'Начало',
    dateEndLabel: 'Конец',
    placeLabel(location) {
      if (location === 'RESTAURANT') {
        return 'Столик';
      }

      if (location === 'CLUB') {
        return 'Площадка';
      }

      throw new TypeError(`Unknown place location type: ${location}`);
    },
    placePlaceholder: 'Выберите столик',
    commentLabel: 'Комментарий к бронированию',
    commentPlaceholder: 'Оставьте комментарий',
    guestWithoutReservationLabel: 'Гость без предварительной брони',
    waitersSelectPlaceholder: 'Создал бронь',
    submitBtnLabel: 'Сохранить',
    cancelBtnLabel: 'Отмена'
  },

  placeOptionFallback: 'Выберите стол',

  bookingClientDataIntl: {
    searchInputPlaceholder: 'Введите имя, телефон или номер карты',
    nameLabel: 'Имя',
    namePlaceholder: 'Введите имя',
    phoneLabel: 'Телефон',
    phonePlaceholder: 'Введите номер',
    cardLabel: 'Клубная карта',
    cardPlaceholder: '(если есть)'
  },

  bookingWaitersEditorIntl: {
    title: 'Редактирование сотрудников',
    addBtnLabel: 'Добавить сотрудника',
    submitBtnLabel: 'Сохранить',
    cancelBtnLabel: 'Отмена'
  },

  bookingWaiterItemIntl: {
    placeholder: 'Введите имя'
  },

  searchClientResultPopupIntl: {
    placeholder: 'Нет совпадений. Проверьте запрос на опечатки.',
    clubMembersLabel: 'Члены клуба',
    leadsLabel: 'Лиды'
  },
  bookingTimelineReservation: {
    nameFallback: 'Гость без брони'
  },

  dateLine: {
    resetActionText: 'Сегодня'
  },
  bookingSelectControl: {
    restaurantLabel: 'Бронь ресторан',
    clubLabel: 'Бронь клуб'
  }
};
