export function delayedAsyncFunction<TParams, TReturn>(
  asyncFunction: (params: TParams) => Promise<TReturn>,
  delay: number
): (params: TParams) => Promise<TReturn> {
  return (params: TParams) => {
    const promise = asyncFunction(params);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        promise.then(resolve).catch(reject);
      }, delay);
    });
  };
}
