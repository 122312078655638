import React from 'react';

import { CalendarPeriod } from 'types/state';
import { splitCalendarPeriod } from 'utils/calendarPeriodUtils';
import { MINUTE } from 'utils/constants';

const PERIOD = MINUTE * 30;

export const useWorkingHoursPeriods = (workingHours: CalendarPeriod) => {
  const timelineLegendPeriods = React.useMemo(() => {
    const periods: CalendarPeriod[] = splitCalendarPeriod(workingHours, PERIOD);

    return periods;
  }, [workingHours]);

  return timelineLegendPeriods;
};
