import { EditMessageTemplatesParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { messageTemplateStoreMock } from 'services/mock/utils/messageTemplateModel';

export const editMessageTemplates = async (
  params: EditMessageTemplatesParams
): Promise<void> => {
  const { changedTemplates, deletedTemplateIds } = params;

  await asyncTimeout(SERVICES_MOCK_DELAY);

  changedTemplates.forEach(item => {
    if (item.text.trim()) {
      const nextItem = {
        ...item,
        text: item.text.trim()
      };

      messageTemplateStoreMock.replaceTemplate(item.id, nextItem);
    } else {
      messageTemplateStoreMock.deleteTemplateById(item.id);
    }
  });

  deletedTemplateIds.forEach(id => {
    messageTemplateStoreMock.deleteTemplateById(id);
  });
};
