import React from 'react';
import { isAfter } from 'date-fns';
import { useReservationsCache } from './useReservationsCache';
import { CalendarPeriod, PlaceItem } from 'types/state';
import { areTwoCalendarPeriodsOverlapping } from 'utils/calendarPeriodUtils';

export const useBookingPlaceItemReservationUtils = () => {
  const reservationsCache = useReservationsCache();

  const isPlaceItemReservedInFuture = React.useCallback(
    (itemId: number) => {
      const withPlaceItemReservations = Array.from(
        reservationsCache.value.values()
      ).filter(reservation =>
        reservation.places.some(item => item.id === itemId)
      );

      const hasFutureReservations = withPlaceItemReservations.some(value => {
        const endDate = new Date(value.period.endDateISO);

        return isAfter(endDate, new Date());
      });

      return hasFutureReservations;
    },
    [reservationsCache]
  );

  const isPlaceItemReservedOnPeriod = React.useCallback(
    (reservationId: number, placeItem: PlaceItem, period: CalendarPeriod) => {
      const res = Array.from(
        reservationsCache.value.values()
      ).filter(reservation =>
        reservation.places.some(item => item.id === placeItem.id)
      );

      try {
        return res.some(currentReservation => {
          return (
            reservationId !== currentReservation.id &&
            areTwoCalendarPeriodsOverlapping(period, currentReservation.period)
          );
        });
      } catch {
        return false;
      }
    },
    [reservationsCache.value]
  );

  return { isPlaceItemReservedInFuture, isPlaceItemReservedOnPeriod };
};
