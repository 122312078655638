import React from 'react';

import { getScrollLeftPx } from 'utils/getScrollLeftPx';
import { NEWS_FEED_SCROLL_LEFT_TO_LOAD_MORE } from 'utils/constants';
import { useIntlContext } from 'hooks/useIntlContext';
import { NewsFeedContentView } from 'containers/NewsFeedContent/NewsFeedContentView';

type Props = {
  children: React.ReactNode;
  hasPreloader: boolean;
  hasEmptyFallback: boolean;
  onLoadMore(): void;
};

export const NewsFeedContent: React.FC<Props> = props => {
  const { children, hasPreloader, hasEmptyFallback, onLoadMore } = props;

  const { newsPageEmpty: emptyFallbackText } = useIntlContext();

  const handleScroll = (event: React.SyntheticEvent) => {
    const { target } = event;
    if (target instanceof Element) {
      const scrollLeftPx = getScrollLeftPx(target);
      if (scrollLeftPx < NEWS_FEED_SCROLL_LEFT_TO_LOAD_MORE) {
        onLoadMore();
      }
    }
  };

  const viewProps = {
    children,
    emptyFallbackText,
    hasEmptyFallback,
    hasPreloader,
    handleScroll
  };

  return <NewsFeedContentView {...viewProps} />;
};
