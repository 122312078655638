import differenceInMinutes from 'date-fns/differenceInMinutes';

import { TIntl } from 'types/intl';

export const createProcessedInText = (
  createdAtISODate: string,
  processedAtISODate: string
) => (intl: TIntl) => {
  const minutesDiff = Math.floor(
    differenceInMinutes(
      new Date(processedAtISODate),
      new Date(createdAtISODate)
    )
  );

  if (minutesDiff < 1) {
    return intl.processedInLessThanMinuteText;
  }

  if (minutesDiff < 60) {
    return intl.processedInLessThanHour(minutesDiff);
  }

  const hours = Math.floor(minutesDiff / 60);

  const extraMinutes = Math.floor(minutesDiff - hours * 60);

  return intl.processedInMoreThanHour(hours, extraMinutes);
};
