import React from 'react';
import { useDrop } from 'react-dnd';
import {
  BookingReservationDragItem,
  BookingReservationDropInfo
} from 'types/booking';
import { BOOKING_RESERVATION_DROP_TYPE } from 'utils/constants';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineTrackItemLayout.scss';

type Props = {
  borderStyling: 'solid' | 'dashed';
  disabled: boolean;
  onClick(): void;
  onDrop(reservationId: number): void;
};

const bemConstructor = createBEMConstructor('bookingTimelineTrackItemLayout');

export const BookingTimelineTrackItemLayout: React.FC<Props> = props => {
  const { disabled, borderStyling, onClick, onDrop } = props;

  const [collectedProps, drop] = useDrop<
    BookingReservationDragItem,
    void,
    BookingReservationDropInfo
  >(
    () => ({
      accept: BOOKING_RESERVATION_DROP_TYPE,
      collect: monitor => ({
        isOver: !!monitor.isOver()
      }),
      drop(item) {
        if (!disabled) {
          onDrop(item.id);
        }
      }
    }),
    [onDrop, disabled]
  );

  const wrapperClassName = bemConstructor('wrapper', {
    disabled,
    over: collectedProps.isOver,
    borderStyling
  });

  return (
    <button
      ref={drop}
      disabled={disabled}
      className={wrapperClassName}
      onClick={onClick}
    />
  );
};
