import React from 'react';

import styles from './threeColumnsPageLayout.module.css';

import { createClassNameConstructor } from 'utils/craeteClassNameConstructor';

type Props = {
  delimiter: React.ReactNode;
  firstColumn: React.ReactNode;
  secondColumn: React.ReactNode;
  thirdColumn: React.ReactNode;
};

export const ThreeColumnsPageLayout = (props: Props) => {
  const { firstColumn, secondColumn, thirdColumn, delimiter } = props;

  const classNameConstructor = createClassNameConstructor(
    styles.ThreeColumnsPageLayout
  );

  const wrapperClassName = classNameConstructor
    .addClass(styles.ThreeColumnsPageLayout__Wrapper)
    .return();

  const firstColumnSlotClassName = classNameConstructor
    .addClass(styles.ThreeColumnsPageLayout__FirstColumnSlot)
    .return();

  const secondColumnSlotClassName = classNameConstructor
    .addClass(styles.ThreeColumnsPageLayout__SecondColumnSlot)
    .return();

  const thirdColumnSlotClassName = classNameConstructor
    .addClass(styles.ThreeColumnsPageLayout__ThirdColumnSlot)
    .return();

  const delimiterSlotClassName = classNameConstructor
    .addClass(styles.ThreeColumnsPageLayout__DelimiterSlot)
    .return();

  return (
    <div className={wrapperClassName}>
      <div className={firstColumnSlotClassName}>{firstColumn}</div>
      <div className={delimiterSlotClassName}>{delimiter}</div>
      <div className={secondColumnSlotClassName}>{secondColumn}</div>
      <div className={delimiterSlotClassName}>{delimiter}</div>
      <div className={thirdColumnSlotClassName}>{thirdColumn}</div>
    </div>
  );
};
