import React from 'react';

import { CustomDatePickerWrapper } from 'components/CustomDatePicker/styled/CustomDatePickerWrapper';
import { CustomDatePickerMonthItem } from 'components/CustomDatePicker/styled/CustomDatePickerMonthItem';
import { useCalendar } from 'components/CustomDatePicker/hooks/useCalendar';

type TProps = {
  value: string;
  disableBefore?: string;
  disableAfter?: string;
  className?: string;
  onDateSelect(date: string): void;
};

export const CustomDatePicker = (props: TProps) => {
  const { value, className, ...restProps } = props;

  const { calendar, onMoveBack, onMoveForward } = useCalendar(value);

  return (
    <CustomDatePickerWrapper
      className={className}
      onMoveBack={onMoveBack}
      onMoveForward={onMoveForward}
    >
      {calendar.map(({ monthOrder, monthInfo }) => (
        <CustomDatePickerMonthItem
          key={monthOrder}
          currentValue={value}
          monthOrder={monthOrder}
          {...monthInfo}
          {...restProps}
        />
      ))}
    </CustomDatePickerWrapper>
  );
};
