import React from 'react';
import styles from './BookingTimeWorkForm.module.scss';
import {
  BookingTimeWorkFormValues,
  CalendarPeriod,
  DayOfWeek
} from '../../../../types/state';
import { useIntlContext } from '../../../../hooks/useIntlContext';
import { CalendarPeriodDayTimeInputComponent } from 'containers/CalendarPeriodDayTimeInput';
import { addDays, startOfDay } from 'date-fns';
import { Text } from 'design/text';
import { getDayOfWeekIndex } from 'utils/dateUtils';
import { BookingTimeWorkFormTogglerComponent } from './BookingTimeWorkFormTogglerComponent';
import { BookingTimeWorkFormDailyFieldLayoutComponent } from './BookingTimeWorkFormDailyFieldLayoutComponent';

type Props = {
  formValues: BookingTimeWorkFormValues;
  onSwitchToWeeklyShedule(): void;
  onSwitchToDailyShedule(): void;
  onDailyPeriodChange(nextValue: CalendarPeriod): void;
  onWeeklyPeriodChange(dayOfWeek: DayOfWeek, nextValue: CalendarPeriod): void;
};

export const BookingTimeWorkForm = (props: Props) => {
  const {
    formValues,
    onSwitchToWeeklyShedule,
    onSwitchToDailyShedule,
    onDailyPeriodChange,
    onWeeklyPeriodChange
  } = props;

  const { bookingTimeWorkSettingsIntl: msgs } = useIntlContext();

  const { weekly } = formValues;

  const sortedPeriodsPerDay = React.useMemo<
    [DayOfWeek, CalendarPeriod][]
  >(() => {
    return Array.from(formValues.periodsPerDayOfWeek.entries())
      .sort((day1, day2) => {
        const day1Index = getDayOfWeekIndex(day1[0]);

        const day2Index = getDayOfWeekIndex(day2[0]);

        return day1Index - day2Index;
      })
      .sort((day1, day2) => {
        // Повторная сортировка выносит воскресенье в конец списка
        const day1Index = getDayOfWeekIndex(day1[0]);

        const day2Index = getDayOfWeekIndex(day2[0]);

        if (day1Index === 0) {
          return 1;
        }

        if (day2Index === 0) {
          return -1;
        }

        return 0;
      });
  }, [formValues.periodsPerDayOfWeek]);

  const targetCalendarPeriod = React.useMemo<CalendarPeriod>(
    () => ({
      startDateISO: startOfDay(new Date()).toISOString(),
      endDateISO: startOfDay(addDays(new Date(), 1)).toISOString()
    }),
    []
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.toggleAllOrEveryDaysPanel}>
        <BookingTimeWorkFormTogglerComponent
          text={<Text>{msgs.dailySheduleButtonText}</Text>}
          active={!weekly}
          onClick={onSwitchToDailyShedule}
        />
        <BookingTimeWorkFormTogglerComponent
          text={<Text>{msgs.weeklySheduleButtonText}</Text>}
          active={weekly}
          onClick={onSwitchToWeeklyShedule}
        />
      </div>
      <div className={styles.header}>
        <Text weight="semi-bold">{msgs.startDate}</Text>
        <Text weight="semi-bold">{msgs.endDate}</Text>
      </div>
      <div className={styles.content}>
        {!weekly ? (
          <CalendarPeriodDayTimeInputComponent
            basePeriod={targetCalendarPeriod}
            selectedPeriod={formValues.dailyPreiod}
            onSelectedPeriodChange={onDailyPeriodChange}
          />
        ) : (
          sortedPeriodsPerDay.map(([day, period]) => (
            <BookingTimeWorkFormDailyFieldLayoutComponent
              input={
                <CalendarPeriodDayTimeInputComponent
                  key={day}
                  basePeriod={targetCalendarPeriod}
                  selectedPeriod={period}
                  onSelectedPeriodChange={period =>
                    onWeeklyPeriodChange(day, period)
                  }
                />
              }
              dayLabel={<Text>{msgs.dayLabel(day)}</Text>}
            />
          ))
        )}
      </div>
    </div>
  );
};
