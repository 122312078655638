import { Section, SectionsFormValues } from 'types/state';

export const patchSectionsFormValues = (state: SectionsFormValues) => ({
  done() {
    return state;
  },
  setSectionEnabledStatus(section: Section, enabled: boolean) {
    const nextFormValues = new Map(state);

    nextFormValues.set(section, enabled);

    return patchSectionsFormValues(nextFormValues);
  },
  clear() {
    const nextFormValues = new Map();

    return patchSectionsFormValues(nextFormValues);
  }
});
