import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SignInPage } from '../pages/SignInPage';
import { GuestScreenTemplate } from 'components/GuestScreen/GuestScreenTemplate';
import { testId } from 'utils/testId';

export const GuestScreen = () => {
  return (
    <GuestScreenTemplate testId={testId.GUEST_SCREEN}>
      <Switch>
        <Route path="/" exact component={SignInPage} />
        <Route path="/">
          <Redirect to="/" />
        </Route>
      </Switch>
    </GuestScreenTemplate>
  );
};
