import { Text } from 'design/text';
import React from 'react';
import { PictureIcon } from 'svg/72x72/picture';

import styles from './NewsFeedItem.module.css';

type TProps = {
  imageSrc: null | string;
  title: string;
  titlePlaceholder: string;
  subtitle: string;
  hasSelectedView: boolean;
  onClick(): void;
};

export const NewsFeedItemTemplate = ({
  imageSrc,
  title,
  titlePlaceholder,
  subtitle,
  hasSelectedView,
  onClick
}: TProps) => (
  <button
    className={`${styles.NewsFeedItem__Wrapper} ${
      hasSelectedView ? styles.NewsFeedItem__Wrapper_selected : ''
    }`}
    onClick={onClick}
  >
    {imageSrc ? (
      <img src={imageSrc} alt="" className={styles.NewsFeedItem__Image} />
    ) : (
      <div className={styles.NewsFeedItem__ImagePlaceholder}>
        <Text color="secondary">
          <PictureIcon />
        </Text>
      </div>
    )}
    <div className={styles.NewsFeedItem__Info}>
      {title ? (
        <div className={styles.NewsFeedItem__Title}>{title}</div>
      ) : (
        <div className={styles.NewsFeedItem__TitlePlaceholder}>
          {titlePlaceholder}
        </div>
      )}
      <div className={styles.NewsFeedItem__Subtitle}>{subtitle}</div>
    </div>
  </button>
);
