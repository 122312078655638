import { AdministratorInfo } from 'types/state';
import { SportingAdminInfo } from 'services/sporting/types';

export const normalizeSportingAdminInfo = {
  toAdministratorInfo(sportingAdminInfo: SportingAdminInfo): AdministratorInfo {
    return {
      id: sportingAdminInfo.id,
      login: sportingAdminInfo.username,
      phoneNumber: `${sportingAdminInfo.phoneNumber}`
    };
  }
};
