import {
  EmployeeEditorState,
  EmployeeFormError,
  UserRole
} from '../../types/state';
import { createNewEmployeeFormValues } from './createNewEmployeeFormValues';

export const patchEmployeeEditorState = (state: EmployeeEditorState) => ({
  done() {
    return state;
  },
  setNextSurname(surname: string) {
    return patchEmployeeEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        lastName: surname
      }
    });
  },
  setNextName(name: string) {
    return patchEmployeeEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        firstName: name
      }
    });
  },
  setNextRole(role: UserRole) {
    return patchEmployeeEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        role: role
      }
    });
  },
  setNextPhoneNumber(phoneNumber: string) {
    return patchEmployeeEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        phoneNumber: phoneNumber
      }
    });
  },
  setNextLogin(login: string) {
    return patchEmployeeEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        login: login
      }
    });
  },
  setNextPassword(nextValue: string) {
    return patchEmployeeEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        password: nextValue
      }
    });
  },
  setNextPasswordConfirm(nextValue: string) {
    return patchEmployeeEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        passwordConfirm: nextValue
      }
    });
  },
  clearFormValues() {
    return patchEmployeeEditorState({
      ...state,
      formValues: createNewEmployeeFormValues()
    });
  },
  setFormError(nextValue: EmployeeFormError[]) {
    return patchEmployeeEditorState({
      ...state,
      formError: nextValue
    });
  },
  clearFormError() {
    const nextState = {
      ...state
    };

    delete nextState.formError;

    return patchEmployeeEditorState(nextState);
  },
  setSubmited(nextValue: boolean) {
    return patchEmployeeEditorState({
      ...state,
      submited: nextValue
    });
  },
  setSubmitDidSucceed(nextValue: boolean) {
    return patchEmployeeEditorState({
      ...state,
      submitDidSucceed: nextValue
    });
  }
});
