import React from 'react';

import styles from './SettingsPage.module.css';

type Props = {
  children: React.ReactNode;
};

export const SettingsPageContent: React.FC<Props> = ({ children }) => {
  return <div className={styles.SettingsPage__Content}>{children}</div>;
};
