import { Text } from 'design/text';
import React from 'react';
import { PictureIcon } from 'svg/72x72/picture';

import styles from './ImageInput.module.css';

type TProps = {
  infoMessage: string;
  errorMessage: null | string;
  placeholder: string;
};

export const ImageInputFallback = ({
  errorMessage,
  infoMessage,
  placeholder
}: TProps) => (
  <div className={styles.ImageInput__FallbackWrapper}>
    <Text color="secondary">
      <PictureIcon />
    </Text>
    <div className={styles.ImageInput__Placeholder}>{placeholder}</div>
    {errorMessage ? (
      <div className={styles.ImageInput__ErrorMessage}>{errorMessage}</div>
    ) : (
      <div className={styles.ImageInput__InfoMessage}>{infoMessage}</div>
    )}
  </div>
);
