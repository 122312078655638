import React from 'react';

import { TSignInFormValues } from 'types/state';
import { useFormValues } from 'hooks/useFormValues';
import { useIntlContext } from 'hooks/useIntlContext';
import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AuthorizationError, UserIsBlockedError } from 'utils/errors';
import { useAsyncController } from 'hooks/useAsyncController';

export const useSignInPageLogic = () => {
  const {
    unexpectedError: unexpectedErrorMessage,
    signInSubmitButtonLabel: submitButtonLabel,
    signInPasswordInputPlaceholder: passwordInputPlaceholder,
    signInUsernameInputPlaceholder: usernameInputPlaceholder,
    signInInvalidCredentials: invalidCredentialsErrorMessage,
    signInUserIsBlocked: userIsBlockedErrorMessage
  } = useIntlContext();

  const { signIn } = useServices();

  const dispatch = useAppDispatch();

  // Sign in

  const {
    wrapAsync: wrapSignIn,
    asyncProcess: signInProcess
  } = useAsyncController();

  // Form

  const { values, onChange: handleChange } = useFormValues<TSignInFormValues>({
    username: '',
    password: ''
  });

  const [errorMessage, setErrorMessage] = React.useState<null | string>(null);

  // Effects

  React.useEffect(() => {
    if (signInProcess.status === 'RESOLVED') {
      dispatch({
        name: 'SIGN_IN_SERVICE',
        status: 'RESOLVED',
        payload: undefined
      });
    }
  }, [dispatch, signInProcess.status]);

  React.useEffect(() => {
    if (signInProcess.status === 'REJECTED') {
      if (signInProcess.error instanceof UserIsBlockedError) {
        setErrorMessage(userIsBlockedErrorMessage);
      } else if (signInProcess.error instanceof AuthorizationError) {
        setErrorMessage(invalidCredentialsErrorMessage);
      } else {
        setErrorMessage(unexpectedErrorMessage);
      }
    } else {
      setErrorMessage(null);
    }
  }, [
    userIsBlockedErrorMessage,
    invalidCredentialsErrorMessage,
    signInProcess,
    unexpectedErrorMessage
  ]);

  React.useEffect(() => {
    setErrorMessage(null);
  }, [values]); // WARNING: неявная зависимость хука

  // Normalization

  const hasPreloader =
    signInProcess.status === 'PENDING' || signInProcess.status === 'RESOLVED';

  const hasDisabledSubmitButton = !values.password || !values.username;

  const hasDisabledInputs = hasPreloader;

  const handleFormSubmit = React.useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
  }, []);

  const handleSubmitButtonClick = React.useCallback(() => {
    const promise = signIn(values).catch(error => {
      return error;
    });

    wrapSignIn(promise);
  }, [signIn, values, wrapSignIn]);

  return {
    submitButtonLabel,
    passwordInputPlaceholder,
    usernameInputPlaceholder,
    values,
    errorMessage,
    hasPreloader,
    hasDisabledSubmitButton,
    hasDisabledInputs,
    handleChange,
    handleFormSubmit,
    handleSubmitButtonClick
  };
};
