import React from 'react';

import styles from './NewsFeed.module.css';

type TProps = {
  children: React.ReactNode;
};

export const NewsFeedWrapper = ({ children }: TProps) => (
  <div className={styles.NewsFeed__Wrapper}>{children}</div>
);
