import { UnreadChatIdLists } from 'types/chats';
import { AppEvent } from 'types/appEvent';
import { modifyListCache } from 'utils/listCacheUtils';

export const unreadChatIdListsReducer = (
  state: UnreadChatIdLists,
  event: AppEvent
): UnreadChatIdLists => {
  switch (event.name) {
    case 'UNREAD_CHAT_IDS_SERVICE': {
      if (event.status === 'RESOLVED') {
        if (event.meta.domain === 'CLUB') {
          const nextIds = modifyListCache(state.sportIds)
            .putItems(1, event.payload)
            .return();

          return {
            ...state,
            sportIds: nextIds
          };
        }

        if (event.meta.domain === 'RESTAURANT') {
          const nextIds = modifyListCache(state.sportIds)
            .putItems(1, event.payload)
            .return();

          return {
            ...state,
            restaurantIds: nextIds
          };
        }
      }

      return state;
    }
    case 'CHAT_ROOM_READ_STATUS_SERVICE': {
      if (event.status === 'RESOLVED') {
        const restaurantIds = modifyListCache(state.restaurantIds)
          .invalidateAllPages()
          .return();

        const sportIds = modifyListCache(state.sportIds)
          .invalidateAllPages()
          .return();

        return {
          restaurantIds,
          sportIds
        };
      }

      return state;
    }
    case 'CHAT_MARKED_AS_UNREAD':
    case 'CHAT_MARKED_AS_READ': {
      const restaurantIds = modifyListCache(state.restaurantIds)
        .invalidateAllPages()
        .return();

      const sportIds = modifyListCache(state.sportIds)
        .invalidateAllPages()
        .return();

      return {
        restaurantIds,
        sportIds
      };
    }
    case 'CHAT_MESSAGE_RECIEVED': {
      if (event.domain === 'RESTAURANT') {
        const restaurantIds = modifyListCache(state.restaurantIds)
          .invalidateAllPages()
          .return();

        return {
          ...state,
          restaurantIds
        };
      }

      if (event.domain === 'CLUB') {
        const sportIds = modifyListCache(state.sportIds)
          .invalidateAllPages()
          .return();

        return {
          ...state,
          sportIds
        };
      }

      return state;
    }
    case 'BADGE_UPDATE_REQUESTED': {
      if (event.section === 'chatsRestaurant') {
        const restaurantIds = modifyListCache(state.restaurantIds)
          .invalidateAllPages()
          .return();

        return {
          ...state,
          restaurantIds
        };
      }

      if (event.section === 'chatsSport') {
        const sportIds = modifyListCache(state.sportIds)
          .invalidateAllPages()
          .return();

        return {
          ...state,
          sportIds
        };
      }

      return state;
    }
    default:
      return state;
  }
};
