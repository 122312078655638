import React from 'react';
import { CommentIcon } from 'svg/12x12/comment';
import { HumanIcon } from 'svg/12x12/human';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineReservationPopupLayout.scss';

type Props = {
  title: React.ReactNode;
  time: React.ReactNode;
  participantsCount: React.ReactNode;
  comment?: string;
};

const bemConstructor = createBEMConstructor(
  'bookingTimelineReservationPopupLayout'
);

export const BookingTimelineReservationPopupLayout: React.FC<Props> = props => {
  const { title, time, participantsCount, comment } = props;

  const wrapperClassName = bemConstructor('wrapper');

  const titleClassName = bemConstructor('title');

  const detailsClassName = bemConstructor('details');

  const timeClassName = bemConstructor('time');

  const participantsIconClassName = bemConstructor('participantsIcon');

  const participantsCountClassName = bemConstructor('participantsCount');

  const commentClassName = bemConstructor('comment');

  return (
    <div className={wrapperClassName}>
      <div className={titleClassName}>{title}</div>
      <div className={detailsClassName}>
        <div className={timeClassName}>{time}</div>
        <div className={participantsIconClassName}>
          <HumanIcon />
        </div>
        <div className={participantsCountClassName}>{participantsCount}</div>
        {comment && (
          <div title={comment} className={commentClassName}>
            <CommentIcon />
          </div>
        )}
      </div>
    </div>
  );
};
