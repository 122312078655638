import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { getCalendarMonthInfo } from 'components/CustomDatePicker/hooks/calendarUtils';

export const useCalendar = (startDateISO: string) => {
  // Переданное значение даты, с которой начинается календарь,
  // приводится к мемоизированному Date-объекту.
  const startDate = React.useMemo(() => new Date(startDateISO), [startDateISO]);

  // Подключается i18n-словарь месяцев в именительном падеже.
  const { nominativeMonthNames } = useIntlContext();

  // Количество отмотанных назад месяцев.
  const backCountRef = React.useRef<number>(1);

  // Функция создает объект следующего месяца.
  const getBackMonthItem = React.useCallback(() => {
    const { current: count } = backCountRef;

    backCountRef.current += 1;

    const date = new Date(startDate);
    date.setDate(1);
    const nextMonthNumber = date.getMonth() - count;
    date.setMonth(nextMonthNumber);

    return {
      monthOrder: 0 - count,
      monthInfo: getCalendarMonthInfo(date, nominativeMonthNames)
    };
  }, [nominativeMonthNames, startDate]);

  // Количество отмотанных вперед месяцев.
  const forwardCountRef = React.useRef<number>(0);

  // Функция создает объект следующего месяца.
  const getForwardMonthItem = React.useCallback(() => {
    const { current: count } = forwardCountRef;

    forwardCountRef.current += 1;

    const date = new Date(startDate);
    date.setDate(1);
    const nextMonthNumber = date.getMonth() + count;
    date.setMonth(nextMonthNumber);

    return {
      monthOrder: count,
      monthInfo: getCalendarMonthInfo(date, nominativeMonthNames)
    };
  }, [nominativeMonthNames, startDate]);

  // Мемоизированное начальное значение календаря.
  // Включает прошлый, текущий и два следующих месяца.
  // Мемоизация предовращает постоянную переинициализацию
  // состояния при любой перерисовке, из-за которой каленадарь
  // превращается в непоследовательную кашу.
  const initialCalendar = React.useMemo(() => {
    return [
      getBackMonthItem(),
      getForwardMonthItem(),
      getForwardMonthItem(),
      getForwardMonthItem()
    ];
  }, [getBackMonthItem, getForwardMonthItem]);

  // Состояние календаря.
  const [calendar, setCalendar] = React.useState(initialCalendar);

  // Метод перемотки календаря на месяц назад.
  const onMoveBack = React.useCallback(() => {
    // Важно создавать следующий месяц заранее.
    // Если делать это в сеттере то значение будет
    // не соответствовать ожидаемому из-за асинхронного
    // выполнения сеттеров.
    const backMonth = getBackMonthItem();

    setCalendar(previousCalendar => [...previousCalendar, backMonth]);
  }, [getBackMonthItem]);

  // Метод перемотки календаря на месяц вперед.
  const onMoveForward = React.useCallback(() => {
    // Важно создавать следующий месяц заранее.
    // Если делать это в сеттере то значение будет
    // не соответствовать ожидаемому из-за асинхронного
    // выполнения сеттеров.
    const forwardMonth = getForwardMonthItem();

    setCalendar(previousCalendar => [...previousCalendar, forwardMonth]);
  }, [getForwardMonthItem]);

  return { calendar, onMoveBack, onMoveForward };
};
