import { AppDispatch } from 'types/state';
import { MoveMessageTemplateParams } from 'types/services';

export const messageTemplateMoveThunk = (
  promise: Promise<void>,
  meta: MoveMessageTemplateParams
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'MESSAGE_TEMPLATE_MOVE_SERVICE',
    status: 'PENDING',
    meta
  });

  return promise.then(rooms => {
    dispatch({
      name: 'MESSAGE_TEMPLATE_MOVE_SERVICE',
      status: 'RESOLVED',
      payload: rooms,
      meta
    });
  });
};
