import { Section } from 'types/state';
import { useSectionsEditorState } from 'hooks/useSectionsEditorState';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';

export const useSectionsFormInputChecked = (section: Section): boolean => {
  const { formValues } = useSectionsEditorState();

  const cachedEnabledSections = useEnabledSectionsStrict();

  const editorValue = formValues.get(section);

  if (editorValue !== undefined) {
    return editorValue;
  }

  return cachedEnabledSections.includes(section);
};
