import { AppEvent } from 'types/appEvent';
import { getMediaQueryType } from 'utils/mediaQueryUtils';

export const mobileDrawerOpenedReducer = (
  state: null | boolean,
  event: AppEvent
): null | boolean => {
  switch (event.name) {
    case 'MOBILE_HEADER__DRAWER_BUTTON_CLICK': {
      return !state;
    }
    case 'WINDOW__RESIZE': {
      const { payload } = event;

      const nextMediaQueryType = getMediaQueryType(payload);

      const nextMobileDrawerOpened =
        nextMediaQueryType !== 'mobile' ? null : state;

      return nextMobileDrawerOpened;
    }
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      if (state === true) {
        return false;
      }

      return state;
    }
    default:
      return state;
  }
};
