import React from 'react';

import styles from './membersTableItemLabel.module.css';

type Props = {
  children: React.ReactNode;
  checked: boolean;
};

export const MembersTableItemLabel: React.FC<Props> = ({
  checked,
  children
}) => (
  <label data-checked={checked} className={styles.MemberTableItemLabel}>
    {children}
  </label>
);
