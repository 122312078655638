import React from 'react';

import { ChatsPageMessage } from 'pages/ChatsPage/ChatsPageMessage';
import {
  isMessagesWithinSameDateGroup,
  isMessagesWithinSameTimeGroup
} from 'utils/chatUtils';
import { ChatMessage } from 'types/chats';

type Props = {
  message: ChatMessage;
  messages: ChatMessage[];
  index: number;
};

export const ChatsPageMessagesItem = (props: Props) => {
  const { messages, index, message } = props;

  const prevMessage = messages.find(
    (item, itemIndex) => itemIndex === index - 1
  );

  const sameOrigin = prevMessage
    ? message.origin === prevMessage.origin
    : false;

  const withinSameDateGroup = prevMessage
    ? isMessagesWithinSameDateGroup(message, prevMessage)
    : false;

  const withinSameTimeGroup = prevMessage
    ? isMessagesWithinSameTimeGroup(message, prevMessage)
    : false;

  const hasDateLabel = !withinSameDateGroup;

  const hasTimeLabel = !sameOrigin || !withinSameTimeGroup;

  return React.useMemo(
    () => (
      <ChatsPageMessage
        key={message.id}
        message={message}
        hasDateLabel={hasDateLabel}
        hasTimeLabel={hasTimeLabel}
      />
    ),
    [hasDateLabel, hasTimeLabel, message]
  );
};
