import { SportingServiceScope } from 'services/sporting/types';
import { ServiceScope } from 'types/serviceScope';
import { UnreachableError } from 'utils/errors';

export const normalizeSportingServiceScope = (
  destinaction: SportingServiceScope
) => ({
  toServiceScope(): ServiceScope {
    switch (destinaction) {
      case 'CLUB':
        return 'CLUB';
      case 'RESTARAUNT':
        return 'RESTAURANT';
      default:
        throw new UnreachableError(destinaction);
    }
  }
});
