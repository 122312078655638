import React from 'react';

export const useToggler = (
  initialValue: boolean = false
): [boolean, () => void] => {
  const [value, setValue] = React.useState<boolean>(initialValue);

  const toggleValue = React.useCallback(() => {
    setValue(prevValue => !prevValue);
  }, [setValue]);

  return [value, toggleValue];
};
