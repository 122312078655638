import { sportingAPI } from '../api';
import { DeleteEmployeeParams } from 'types/services';

export const deleteEmployee = async (
  params: DeleteEmployeeParams
): Promise<void> => {
  const { userId } = params;

  await sportingAPI.service.deleteEmployee({
    payload: { idUser: userId }
  });
};
