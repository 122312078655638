import React from 'react';

export const useOutsideClick = <TTarget extends HTMLElement | null>(
  targetNodeRef: React.MutableRefObject<TTarget>,
  callback: (event: Event) => void
) => {
  React.useEffect(() => {
    const callbackProxy = (event: Event) => {
      const { current: targetNode } = targetNodeRef;
      if (
        targetNode instanceof HTMLElement &&
        event.target instanceof HTMLElement &&
        !targetNode.contains(event.target)
      ) {
        callback(event);
      }
    };

    window.addEventListener('mouseup', callbackProxy);

    return () => {
      window.removeEventListener('mouseup', callbackProxy);
    };
  }, [callback, targetNodeRef]);
};
