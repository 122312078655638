import { ClickBoundaryComponent } from 'design/clickBoundary/clickBoundaryComponents';
import React from 'react';
import { multiplyClassName } from 'utils/className';

import styles from './popupGroupLayoutStyles.module.scss';

type Props = {
  up?: boolean;
  trigger: React.ReactNode;
  popup: React.ReactNode;
  onOutsideClick(): void;
};

export const PopupGroupLayoutComponent = (props: Props) => {
  const { up, trigger, popup, onOutsideClick } = props;

  const popupClassName = multiplyClassName([
    styles.popupSlot,
    up ? styles.popupSlot__up : ''
  ]);

  return (
    <div className={styles.wrapper}>
      <ClickBoundaryComponent onOutsideClick={onOutsideClick}>
        <div className={styles.triggerSlot}>{trigger}</div>
        <div className={popupClassName}>{popup}</div>
      </ClickBoundaryComponent>
    </div>
  );
};
