import { TCompetitionsArticle } from 'types/state';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';

export const getRawCompetitionsArticle = (): TCompetitionsArticle => ({
  id: NEWS_ARTICLE_CANDIDATE_ID,
  title: '',
  description: '',
  image: null,
  duration: null,
  isDraft: true
});
