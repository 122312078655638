import React from 'react';

import styles from './NotificationMessageModal.module.css';
import { RectangleButton } from '../shared/RectangleButton';
import { ModalPortal } from '../shared/Modal';
import { ModalWindow } from '../Modal/ModalWindow';
import { ModalFooter } from '../Modal/ModalFooter';

type Props = {
  title: string;
  content: string;
  submitBtnLabel: string;
  onSubmit(): void;
};
export const NotificationMessageModal = (props: Props) => {
  const { title, content, submitBtnLabel, onSubmit } = props;

  return (
    <ModalPortal>
      <ModalWindow additionalClassName={styles.window}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{content}</div>
        <ModalFooter additionalClassName={styles.footer}>
          <RectangleButton
            styleType={'secondary'}
            label={submitBtnLabel}
            onClick={onSubmit}
          />
        </ModalFooter>
      </ModalWindow>
    </ModalPortal>
  );
};
