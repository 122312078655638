import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import styles from './EmployeeItem.module.scss';
import { UserInfo } from 'types/state';
import { EmployeeItemBtn } from '../EmployeeItemBtn/EmployeeItemBtn';
import { CustomSelect } from 'containers/CustomSelect';
import { usePhoneNumberSelectOptions } from 'hooks/usePhoneNumberSelectOptions';
import { TrashIcon } from 'svg/20x20/trash';
import { DNDIcon } from 'svg/24x24/dnd';
import { EditIcon } from 'svg/24x24/edit';
import { LockIcon } from 'svg/24x24/lock';
import { UnlockIcon } from 'svg/24x24/unlock';
import { useIntlContext } from 'hooks/useIntlContext';

type Props = {
  user: UserInfo;
  index: number;
  isEditable: boolean;
  onChangePhoneNumber(value: string): void;
  onEdit(): void;
  onBlock(): void;
  onDelete(): void;
};

// Представление сотрудника в таблице
export const EmployeeItem = (props: Props) => {
  const user = props.user;

  const intl = useIntlContext();

  const phoneNumberSelectOptions = usePhoneNumberSelectOptions();

  const phoneNumbersLoaded = phoneNumberSelectOptions.length > 1;

  return (
    <Draggable key={user.id} draggableId={`${user.id}`} index={props.index}>
      {provided => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={
            user.blocked
              ? styles.EmployeeItem + ' ' + styles.EmployeeItem__Blocked
              : styles.EmployeeItem
          }
        >
          <div className={styles.EmployeeItem__Name}>
            {intl.personFullName({
              lastName: user.lastName,
              firstName: user.firstName,
              middleName: ''
            })}
          </div>
          <CustomSelect
            className={styles.EmployeeItem__Number}
            options={phoneNumberSelectOptions}
            selected={
              phoneNumbersLoaded && user.phoneNumber != null
                ? user.phoneNumber
                : ''
            }
            onChange={props.onChangePhoneNumber}
          />
          <div className={styles.EmployeeItem__Login}>{user.login}</div>
          {props.isEditable && (
            <div className={styles.EmployeeItem__ButtonsContainer}>
              <EmployeeItemBtn
                icon={<DNDIcon />}
                dragHandleProps={provided.dragHandleProps}
              />
              <EmployeeItemBtn icon={<EditIcon />} onClick={props.onEdit} />
              <EmployeeItemBtn
                icon={user.blocked ? <LockIcon /> : <UnlockIcon />}
                accent={user.blocked ? 'critical' : 'default'}
                hoverAccent={user.blocked ? 'critical' : 'default'}
                onClick={props.onBlock}
              />
              <EmployeeItemBtn
                icon={<TrashIcon />}
                hoverAccent="critical"
                onClick={props.onDelete}
              />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
