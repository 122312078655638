import { ClientInfo } from '../../../types/client';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';
import { TFindUsersParams } from '../../../types/services';
import { createRuntimeUniqueId } from 'utils/createRuntimeUniqueId';

export const findUsers = async (
  params: TFindUsersParams
): Promise<ClientInfo[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return [
    {
      id: createRuntimeUniqueId(),
      lastName: 'LastName1',
      firstName: 'FirstName1',
      middleName: 'MiddleName1',
      status: 'STANDARD',
      cardNumber: '111',
      phoneNumber: '+375331111111'
    },
    {
      id: createRuntimeUniqueId(),
      lastName: 'LastName2',
      firstName: 'FirstName2',
      middleName: 'MiddleName2',
      status: 'STANDARD',
      cardNumber: '222',
      phoneNumber: '+375332222222',
      blacklisted: true
    },
    {
      id: createRuntimeUniqueId(),
      lastName: 'LastName3',
      firstName: 'FirstName3',
      middleName: 'MiddleName3',
      status: 'STANDARD',
      cardNumber: null,
      phoneNumber: '+375332222222',
      blacklisted: true
    },
    {
      id: createRuntimeUniqueId(),
      lastName: 'LastName4',
      firstName: 'FirstName4',
      middleName: 'MiddleName4',
      status: 'STANDARD',
      cardNumber: null,
      phoneNumber: '+375332222222'
    },
    {
      id: createRuntimeUniqueId(),
      lastName: 'LastName5',
      firstName: 'FirstName5',
      middleName: 'MiddleName5',
      status: 'STANDARD',
      cardNumber: null,
      phoneNumber: '+375332222222'
    },
    {
      id: createRuntimeUniqueId(),
      lastName: 'LastName6',
      firstName: 'FirstName6',
      middleName: 'MiddleName6',
      status: 'EMPLOYEE',
      cardNumber: null,
      phoneNumber: '+375332222222'
    },
    {
      id: createRuntimeUniqueId(),
      lastName: 'LastName6',
      firstName: 'FirstName6',
      middleName: 'MiddleName6',
      status: 'EMPLOYEE',
      cardNumber: null,
      phoneNumber: '+375332222222'
    }
  ];
};
