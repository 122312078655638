import React from 'react';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import { TApplication } from 'types/state';
import { setISOToDate, createTimeText } from 'utils/dateUtils';
import { toMaskedPhone } from 'utils/toMaskedPhone';
import { useServices } from 'hooks/useServices';
import { useMediaFlags } from 'hooks/useMediaFlags';
import { APPLICATIONS_TABLE_TIMER_OFFSET } from 'utils/constants';
import { createProcessedInText } from 'utils/createProcessedInText';
import { useIntlContext } from 'hooks/useIntlContext';
import { useAsyncController } from 'hooks/useAsyncController';
import { ApplicationsTableCheckbox } from 'components/ApplicationsTable/ApplicationsTableCheckbox';
import { ApplicationsTableClipboard } from 'components/ApplicationsTable/ApplicationsTableClipboard';
import { PhoneButtonMobile } from 'design/phoneButton/phoneButtonMobile';
import { ApplicationLayoutComponent } from 'design/applicationLayout/applicationLayoutComponents';
import { Text } from 'design/text';
import { MissedCallIcon } from 'svg/24x24/missed-call';
import { CallBackIcon } from 'svg/24x24/call-back';
import { ApplicationsTableRoundButton } from 'design/applicationsTableRoundButton/applicationsTableRoundButtonComponents';
import { PhoneIcon } from 'svg/24x24/phone';
import { ServiceScope } from 'types/serviceScope';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { BookingSelectControlComponent } from 'containers/BookingSelectControlComponents';

type TProps = {
  application: TApplication;
  domain: ServiceScope;
};

export const ApplicationsTableRow = (props: TProps) => {
  const { application, domain } = props;

  const {
    status: initialStatus,
    phone: phoneOriginal,
    origin,
    fullName,
    cardNumber: cardNumberOriginal,
    createdAtDate,
    processedAtDate,
    id
  } = application;

  const dispatch = useAppDispatch();

  // Записывает статус заявки в локальное состояние
  const [actualStatus, setActualStatus] = React.useState(initialStatus);

  const {
    updateApplicationStatus,
    makePhoneCall: makePhoneCallService
  } = useServices();

  const { isTablet, isMobile } = useMediaFlags();

  const mobileView = isTablet || isMobile;

  const intlDictionary = useIntlContext();

  const {
    asyncProcess: phoneCallProcess,
    wrapAsync: wrapPhoneCall
  } = useAsyncController();

  const { wrapAsync: wrapStatusUpdate } = useAsyncController();

  React.useEffect(() => {
    if (actualStatus !== initialStatus) {
      const promise = updateApplicationStatus({
        domain,
        nextStatus: actualStatus,
        id
      });

      wrapStatusUpdate(promise);
    }
  }, [
    domain,
    actualStatus,
    id,
    initialStatus,
    updateApplicationStatus,
    wrapStatusUpdate
  ]);

  // Эффект меняет статус заявки на выполненный после
  // успешного звонка клиенту.
  React.useEffect(() => {
    if (phoneCallProcess.status === 'RESOLVED') {
      setActualStatus('DONE');
    }
  }, [phoneCallProcess.status]);

  // Нормализация состояния
  const createdAtTime = React.useMemo(
    () => createTimeText(setISOToDate(createdAtDate)),
    [createdAtDate]
  );

  const doneCheckboxChecked = actualStatus === 'DONE';

  const onCheckboxChange = React.useCallback(() => {
    if (doneCheckboxChecked) {
      setActualStatus('NEW');
    } else {
      setActualStatus('DONE');
    }
  }, [doneCheckboxChecked]);

  const onPhoneButtonClick = React.useCallback(() => {
    const promise = makePhoneCallService({ applicationId: id, userId: null });

    wrapPhoneCall(promise);

    setActualStatus('DONE');
  }, [id, makePhoneCallService, wrapPhoneCall]);

  const createBooking = React.useCallback(
    (serviceScope: ServiceScope) => {
      dispatch({
        name: 'APPLICATIONS_PAGE__BOOKING_BUTTON_CLICKED',
        serviceScope,
        application,
        initialDateISO: new Date().toISOString()
      });
    },
    [application, dispatch]
  );

  const processedInTime = React.useMemo(() => {
    if (processedAtDate) {
      return createProcessedInText(
        createdAtDate,
        processedAtDate
      )(intlDictionary);
    }
  }, [createdAtDate, intlDictionary, processedAtDate]);

  const processedTooLate = React.useMemo(() => {
    if (processedAtDate) {
      const msDiff = differenceInMilliseconds(
        new Date(processedAtDate),
        new Date(createdAtDate)
      );

      return msDiff > APPLICATIONS_TABLE_TIMER_OFFSET;
    }

    return false;
  }, [createdAtDate, processedAtDate]);

  const phoneClipboard = phoneOriginal || '';

  const phone = phoneOriginal ? toMaskedPhone(phoneOriginal) : '';

  const cardNumber = cardNumberOriginal || '';

  const hasMissedCallIcon = origin === 'MISSED_CALL';

  const timeNode = React.useMemo(
    () => (
      <>
        <Text>{createdAtTime}</Text>
        {processedInTime && (
          <Text size="xs" color={processedTooLate ? 'critical' : 'secondary'}>
            {processedInTime}
          </Text>
        )}
      </>
    ),
    [createdAtTime, processedInTime, processedTooLate]
  );

  if (mobileView) {
    return (
      <ApplicationLayoutComponent
        theme="mobile"
        icon={
          hasMissedCallIcon ? (
            <Text color="critical">
              <MissedCallIcon />
            </Text>
          ) : (
            <Text color="secondary">
              <CallBackIcon />
            </Text>
          )
        }
        number={<Text>{cardNumber}</Text>}
        time={timeNode}
        name={
          fullName && (
            <ApplicationsTableClipboard
              mod="text-mobile"
              clipboardValue={fullName}
            />
          )
        }
        phoneCallControl={
          <PhoneButtonMobile phone={phone} onClick={onPhoneButtonClick} />
        }
        phoneNumberCopyingControl={
          <ApplicationsTableClipboard
            mod="button"
            clipboardValue={phoneClipboard}
          />
        }
        bookingControl={null}
        statusControl={
          <ApplicationsTableCheckbox
            checked={doneCheckboxChecked}
            onChange={onCheckboxChange}
          />
        }
      />
    );
  }

  return (
    <ApplicationLayoutComponent
      theme="desktop"
      icon={
        hasMissedCallIcon ? (
          <Text color="critical">
            <MissedCallIcon />
          </Text>
        ) : (
          <Text color="secondary">
            <CallBackIcon />
          </Text>
        )
      }
      number={<Text>{cardNumber}</Text>}
      time={timeNode}
      name={
        fullName && (
          <ApplicationsTableClipboard
            visibleValue={fullName}
            clipboardValue={fullName}
          />
        )
      }
      phoneCallControl={
        <ApplicationsTableRoundButton
          theme={actualStatus === 'NEW' ? 'accent' : 'primary'}
          icon={<PhoneIcon />}
          onClick={onPhoneButtonClick}
        />
      }
      phoneNumberCopyingControl={
        <ApplicationsTableClipboard
          visibleValue={phone}
          clipboardValue={phoneClipboard}
        />
      }
      bookingControl={
        <BookingSelectControlComponent
          chat={false}
          onOptionClick={createBooking}
        />
      }
      statusControl={
        <ApplicationsTableCheckbox
          checked={doneCheckboxChecked}
          onChange={onCheckboxChange}
        />
      }
    />
  );
};
