import { UserEditorState, UserFormError } from 'types/state';
import { createUserFormValues } from 'utils/createUserFormValues';

export const patchUserEditorState = (state: UserEditorState) => ({
  done() {
    return state;
  },
  setNextPassword(nextValue: string) {
    return patchUserEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        password: nextValue
      }
    });
  },
  setNextPasswordConfirm(nextValue: string) {
    return patchUserEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        passwordConfirm: nextValue
      }
    });
  },
  setNextPhoneNumber(phoneNumber: string) {
    return patchUserEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        phoneNumber: phoneNumber
      }
    });
  },
  setDeleted(nextValue: boolean) {
    return patchUserEditorState({
      ...state,
      formValues: {
        ...state.formValues,
        deleted: nextValue
      }
    });
  },
  clearFormValues() {
    return patchUserEditorState({
      ...state,
      formValues: createUserFormValues()
    });
  },
  setFormError(nextValue: UserFormError) {
    return patchUserEditorState({
      ...state,
      formError: nextValue
    });
  },
  clearFormError() {
    const nextState = {
      ...state
    };

    delete nextState.formError;

    return patchUserEditorState(nextState);
  },
  setSubmited(nextValue: boolean) {
    return patchUserEditorState({
      ...state,
      submited: nextValue
    });
  },
  setSubmitDidSucceed(nextValue: boolean) {
    return patchUserEditorState({
      ...state,
      submitDidSucceed: nextValue
    });
  }
});
