import { TReviewStatus, TReview, TReviewType } from 'types/state';

let uid = 0;

let number = 200;

let orderNumber = 3000;

const createRandomReviewStatus = (): TReviewStatus => {
  const randomValue = Math.random();

  if (randomValue < 0.5) {
    return 'NEW';
  }

  return 'PROCESSED';
};

const createRandomReviewType = (): TReviewType => {
  const randomValue = Math.random();

  if (randomValue < 0.5) {
    return 'MOBILE';
  }

  return 'RESTAURANT';
};

export const createReviewMock = (): TReview => ({
  id: uid += 1,
  number: number += 1,
  orderNumber: `${(orderNumber += 1)}`,
  status: createRandomReviewStatus(),
  type: createRandomReviewType(),
  clientName: 'Котов Александр',
  clientPhone: '+375 33 300-00-00',
  clientEmail: 'kotov@gmail.com',
  createdAtDate: new Date().toISOString(),
  note: '',
  clientComment:
    'Ребята, спасибо Вам! Вы сделали не только наш день, но и казалось отчаянно испорченный праздник личными обстоятельствами... Ваша команда не только профессиональна, но и по-человечески невероятная и понимающая!!! Тысячи раз спасибо!'
});

export const createReviewMocks = (count: number): TReview[] => {
  const mocks = [];
  while (mocks.length < count) {
    mocks.push(createReviewMock());
  }
  return mocks;
};
