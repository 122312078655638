import { addDays, compareAsc, parse, startOfDay } from 'date-fns';
import { CalendarPeriod, TimeLineDay } from 'types/state';
import { DATE_FNS__24H_TIME_FORMAT } from './constants';

export function normalizeTimeLineDay(date: Date, timeLineDay: TimeLineDay) {
  const { startAt, endAt } = timeLineDay;
  return {
    toCalendarPeriod(): CalendarPeriod {
      const startDateISO = (() => {
        const targetDate = new Date(date);
        const startOfTargetDate = startOfDay(targetDate);

        return parse(
          startAt,
          DATE_FNS__24H_TIME_FORMAT,
          startOfTargetDate
        ).toISOString();
      })();

      let endDateISO = (() => {
        const targetDate = new Date(date);
        const startOfTargetDate = startOfDay(targetDate);

        return parse(
          endAt,
          DATE_FNS__24H_TIME_FORMAT,
          startOfTargetDate
        ).toISOString();
      })();

      const compareResult = compareAsc(
        new Date(startDateISO),
        new Date(endDateISO)
      );

      // Выставляется завтрашний день для даты окончания периода в случае,
      // если дата окончания меньше даты начала.
      if (compareResult !== -1) {
        endDateISO = (() => {
          const targetDate = addDays(new Date(date), 1);
          const startOfTargetDate = startOfDay(targetDate);

          return parse(
            endAt,
            DATE_FNS__24H_TIME_FORMAT,
            startOfTargetDate
          ).toISOString();
        })();
      }

      return { startDateISO, endDateISO };
    }
  };
}
