import React from 'react';

import { SwitchInputWrapper } from 'components/SwitchInput/components/SwitchInputWrapper';
import { SwitchInputCheckbox } from 'components/SwitchInput/components/SwitchInputCheckbox';
import { SwitchInputSlider } from 'components/SwitchInput/components/SwitchInputSlider';

type Props<ValueType extends string> = {
  value: ValueType;
  name: string;
  checked: boolean;
  disabled?: boolean;
  testId?: string;
  onChange(): void;
};

export function SwitchInput<ValueType extends string>(props: Props<ValueType>) {
  return (
    <SwitchInputWrapper>
      <SwitchInputCheckbox {...props} />
      <SwitchInputSlider />
    </SwitchInputWrapper>
  );
}
