import { UsersEditorState } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { createUsersEditorState } from 'utils/createUsersEditorState';
import { patchUsersEditorState } from 'utils/patchUsersEditorState';
import { createNewUserEditorState } from 'utils/createNewUserEditorState';
import { patchNewUserFormValues } from 'utils/patchCreateUserFormValues';
import { patchEditorState } from 'utils/patchEditorState';
import { validateNewUserFormValues } from 'utils/validateNewUserFormValues';
import { patchCache } from 'utils/patchCache';
import { getNewUserFormErrorFromServiceError } from 'utils/getNewUserFormErrorFromServiceError';
import { createCache } from 'utils/createCache';
import { patchUserEditorStatesByUserId } from 'utils/patchUserEditorStatesByUserId';
import { patchUserEditorState } from 'utils/patchUserEditorState';
import { getUserEditorStateByUserIdOrFallback } from 'utils/getUserEditorState';
import { validatePasswordFormValues } from 'utils/validatePasswordFormValues';

export const usersEditorStateReducer = (
  state: UsersEditorState,
  event: AppEvent
): UsersEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return createUsersEditorState();
    }
    case 'USERS_EDITOR__NEW_USER_BUTTON_CLICK': {
      return patchUsersEditorState(state)
        .setNewUserEditorState(createNewUserEditorState())
        .done();
    }
    case 'NEW_USER_FORM__CANCEL': {
      return patchUsersEditorState(state)
        .setNewUserEditorState(null)
        .done();
    }
    case 'NEW_USER_FORM__LOGIN_INPUT_CHANGE':
    case 'NEW_USER_FORM__PASSWORD_INPUT_CHANGE':
    case 'NEW_USER_FORM__PASSWORD_CONFIRM_INPUT_CHANGE':
    case 'NEW_USER_FORM__PHONE_NUMBER_CHANGE': {
      if (state.newUserEditorState) {
        let nextFormValues = patchNewUserFormValues(
          state.newUserEditorState.formValues
        );

        if (event.name === 'NEW_USER_FORM__LOGIN_INPUT_CHANGE') {
          nextFormValues = nextFormValues.setLogin(event.value);
        }
        if (event.name === 'NEW_USER_FORM__PASSWORD_INPUT_CHANGE') {
          nextFormValues = nextFormValues.setPassword(event.value);
        }
        if (event.name === 'NEW_USER_FORM__PASSWORD_CONFIRM_INPUT_CHANGE') {
          nextFormValues = nextFormValues.setPasswordConfirm(event.value);
        }
        if (event.name === 'NEW_USER_FORM__PHONE_NUMBER_CHANGE') {
          nextFormValues = nextFormValues.setPhoneNumber(event.value);
        }

        const nextNewUserEditorState = patchEditorState(
          state.newUserEditorState
        )
          .setSubmitDidSucceed(false)
          .setFormValues(nextFormValues.done())
          .clearFormError();

        return patchUsersEditorState(state)
          .setNewUserEditorState(nextNewUserEditorState.done())
          .done();
      }

      return state;
    }
    case 'NEW_USER_FORM__SUBMIT': {
      if (state.newUserEditorState) {
        const { formValues } = state.newUserEditorState;

        const formError = validateNewUserFormValues(formValues);

        let nextFormValues = patchNewUserFormValues(formValues);

        let nextNewUserEditorState = patchEditorState(state.newUserEditorState);

        if (formError) {
          nextFormValues = nextFormValues
            .setPassword('')
            .setPasswordConfirm('');

          nextNewUserEditorState = nextNewUserEditorState
            .setFormError(formError)
            .setFormValues(nextFormValues.done());
        } else {
          nextNewUserEditorState = nextNewUserEditorState.setSubmited(true);
        }

        return patchUsersEditorState(state)
          .setNewUserEditorState(nextNewUserEditorState.done())
          .done();
      }

      return state;
    }
    case 'ADD_NEW_USER_SERVICE': {
      if (state.newUserEditorState) {
        let nextNewUserEditorState = patchEditorState(state.newUserEditorState);

        let nextFormValues = patchNewUserFormValues(
          state.newUserEditorState.formValues
        );

        let nextUsersEditorState = patchUsersEditorState(state);

        if (event.status === 'PENDING') {
          nextNewUserEditorState = nextNewUserEditorState
            .clearFormError()
            .setSubmitDidSucceed(false);

          nextUsersEditorState = nextUsersEditorState.setNewUserEditorState(
            nextNewUserEditorState.done()
          );
        }

        if (event.status === 'RESOLVED') {
          const nextAdministratorInfosCache = patchCache(
            state.administratorInfosCache
          ).invalidate();

          nextUsersEditorState = nextUsersEditorState
            .setNewUserEditorState(null)
            .setAdministratorInfosCache(nextAdministratorInfosCache.done());
        }

        if (event.status === 'REJECTED') {
          const formError = getNewUserFormErrorFromServiceError(event.payload);

          nextFormValues = nextFormValues
            .setPassword('')
            .setPasswordConfirm('');

          nextNewUserEditorState = nextNewUserEditorState
            .setFormValues(nextFormValues.done())
            .setFormError(formError)
            .setSubmited(false);

          nextUsersEditorState = nextUsersEditorState.setNewUserEditorState(
            nextNewUserEditorState.done()
          );
        }

        return nextUsersEditorState.done();
      }

      return state;
    }
    case 'ADMINISTRATOR_INFOS_SERVICE': {
      let nextUsersEditorState = patchUsersEditorState(state);

      if (event.status === 'RESOLVED') {
        nextUsersEditorState = nextUsersEditorState.setAdministratorInfosCache(
          createCache(new Set(event.payload))
        );
      }

      if (event.status === 'REJECTED') {
        nextUsersEditorState = nextUsersEditorState.setAdministratorInfosCache(
          createCache(new Set())
        );
      }

      return nextUsersEditorState.done();
    }
    case 'USER_FORM__PASSWORD_INPUT_CHANGE':
    case 'USER_FORM__PASSWORD_CONFIRM_INPUT_CHANGE':
    case 'USER_FORM__PHONE_NUMBER_CHANGE': {
      let nextUsersEditorState = patchUsersEditorState(state);

      let nextUserEditorStatesByUserId = patchUserEditorStatesByUserId(
        state.userEditorStatesByUserId
      );

      let nextUserEditorState = patchUserEditorState(
        getUserEditorStateByUserIdOrFallback(
          state.userEditorStatesByUserId,
          event.userId
        )
      ).setSubmitDidSucceed(false);

      if (event.name === 'USER_FORM__PASSWORD_INPUT_CHANGE') {
        nextUserEditorState = nextUserEditorState.setNextPassword(event.value);
      }

      if (event.name === 'USER_FORM__PASSWORD_CONFIRM_INPUT_CHANGE') {
        nextUserEditorState = nextUserEditorState.setNextPasswordConfirm(
          event.value
        );
      }

      if (event.name === 'USER_FORM__PHONE_NUMBER_CHANGE') {
        nextUserEditorState = nextUserEditorState.setNextPhoneNumber(
          event.value
        );
      }

      nextUserEditorState = nextUserEditorState.clearFormError();

      nextUserEditorStatesByUserId = nextUserEditorStatesByUserId.setUserEditorState(
        event.userId,
        nextUserEditorState.done()
      );

      nextUsersEditorState = nextUsersEditorState.setUserEditorStatesByUserId(
        nextUserEditorStatesByUserId.done()
      );

      return nextUsersEditorState.done();
    }
    case 'USER_FORM__CANCEL': {
      let nextUsersEditorState = patchUsersEditorState(state);

      let nextUserEditorStatesByUserId = patchUserEditorStatesByUserId(
        state.userEditorStatesByUserId
      );

      let nextUserEditorState = patchUserEditorState(
        getUserEditorStateByUserIdOrFallback(
          state.userEditorStatesByUserId,
          event.userId
        )
      )
        .clearFormValues()
        .clearFormError();

      nextUserEditorStatesByUserId = nextUserEditorStatesByUserId.setUserEditorState(
        event.userId,
        nextUserEditorState.done()
      );

      nextUsersEditorState = nextUsersEditorState.setUserEditorStatesByUserId(
        nextUserEditorStatesByUserId.done()
      );

      return nextUsersEditorState.done();
    }
    case 'USER_FORM__SUBMIT': {
      let nextUsersEditorState = patchUsersEditorState(state);

      let nextUserEditorStatesByUserId = patchUserEditorStatesByUserId(
        state.userEditorStatesByUserId
      );

      const targetUserEditorState = getUserEditorStateByUserIdOrFallback(
        state.userEditorStatesByUserId,
        event.userId
      );

      let nextUserEditorState = patchUserEditorState(targetUserEditorState);

      const formError = validatePasswordFormValues(
        targetUserEditorState.formValues
      );

      if (formError) {
        nextUserEditorState = nextUserEditorState.setFormError(formError);

        if (formError === 'PASSWORDS_DID_NOT_MATCH') {
          nextUserEditorState = nextUserEditorState
            .setNextPassword('')
            .setNextPasswordConfirm('');
        }
      } else {
        nextUserEditorState = nextUserEditorState.setSubmited(true);
      }

      nextUserEditorStatesByUserId = nextUserEditorStatesByUserId.setUserEditorState(
        event.userId,
        nextUserEditorState.done()
      );

      nextUsersEditorState = nextUsersEditorState.setUserEditorStatesByUserId(
        nextUserEditorStatesByUserId.done()
      );

      return nextUsersEditorState.done();
    }

    case 'DELETE_USER_MODAL__SUBMIT': {
      let nextUsersEditorState = patchUsersEditorState(state);

      let nextUserEditorStatesByUserId = patchUserEditorStatesByUserId(
        state.userEditorStatesByUserId
      );

      const targetUserEditorState = getUserEditorStateByUserIdOrFallback(
        state.userEditorStatesByUserId,
        event.userId
      );

      let nextUserEditorState = patchUserEditorState(targetUserEditorState);

      nextUserEditorState = nextUserEditorState.setDeleted(true);

      nextUserEditorStatesByUserId = nextUserEditorStatesByUserId.setUserEditorState(
        event.userId,
        nextUserEditorState.done()
      );

      nextUsersEditorState = nextUsersEditorState.setUserEditorStatesByUserId(
        nextUserEditorStatesByUserId.done()
      );

      return nextUsersEditorState.done();
    }
    case 'UPDATE_ADMINISTRATOR_INFO_SERVICE': {
      if (event.status === 'PENDING') {
        return state;
      }

      let nextUsersEditorState = patchUsersEditorState(state);

      let nextUserEditorStatesByUserId = patchUserEditorStatesByUserId(
        state.userEditorStatesByUserId
      );

      let nextUserEditorState = patchUserEditorState(
        getUserEditorStateByUserIdOrFallback(
          state.userEditorStatesByUserId,
          event.meta
        )
      );

      if (event.status === 'RESOLVED') {
        nextUserEditorState = nextUserEditorState
          .clearFormValues()
          .setSubmitDidSucceed(true)
          .setSubmited(false);

        nextUsersEditorState = nextUsersEditorState.setAdministratorInfosCache(
          patchCache(state.administratorInfosCache)
            .invalidate()
            .done()
        );
      }

      if (event.status === 'REJECTED') {
        nextUserEditorState = nextUserEditorState
          .setSubmited(false)
          .setFormError('SERVICE_FAILED');
      }

      nextUserEditorStatesByUserId = nextUserEditorStatesByUserId.setUserEditorState(
        event.meta,
        nextUserEditorState.done()
      );

      nextUsersEditorState = nextUsersEditorState.setUserEditorStatesByUserId(
        nextUserEditorStatesByUserId.done()
      );

      return nextUsersEditorState.done();
    }

    case 'DELETE_USER_SERVICE': {
      if (event.status === 'PENDING') {
        return state;
      }

      let nextUsersEditorState = patchUsersEditorState(state);

      let nextUserEditorStatesByUserId = patchUserEditorStatesByUserId(
        state.userEditorStatesByUserId
      );

      let nextUserEditorState = patchUserEditorState(
        getUserEditorStateByUserIdOrFallback(
          state.userEditorStatesByUserId,
          event.meta
        )
      );

      if (event.status === 'RESOLVED') {
        nextUsersEditorState = nextUsersEditorState.setAdministratorInfosCache(
          patchCache(state.administratorInfosCache)
            .invalidate()
            .done()
        );

        nextUserEditorStatesByUserId = nextUserEditorStatesByUserId.removeUserEditorState(
          event.meta
        );
      }

      if (event.status === 'REJECTED') {
        nextUserEditorState = nextUserEditorState
          .setSubmited(false)
          .setFormError('SERVICE_FAILED');

        nextUserEditorStatesByUserId = nextUserEditorStatesByUserId.setUserEditorState(
          event.meta,
          nextUserEditorState.done()
        );
      }

      nextUsersEditorState = nextUsersEditorState.setUserEditorStatesByUserId(
        nextUserEditorStatesByUserId.done()
      );

      return nextUsersEditorState.done();
    }

    default:
      return state;
  }
};
