import { SportingMessage } from 'services/sporting/types';
import { ChatMessage } from 'types/chats';
import { normalizeSportingRoleName } from 'services/sporting/utils/normalizeSportingRoleName';

export const normalizeSportingMessage = (chatMessage: SportingMessage) => ({
  toChatMessage(): ChatMessage {
    return {
      id: chatMessage.idMessage,
      createdAt: chatMessage.createdAt,
      origin: normalizeSportingRoleName(chatMessage.senderRole).toChatRole(),
      text: chatMessage.text || '',
      read: chatMessage.messageStatus === 'READED',
      edited: chatMessage.edited,
      deleted: chatMessage.deleted
    };
  }
});
