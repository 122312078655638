import React from 'react';

import { CustomDatePickerDateItem } from 'components/CustomDatePicker/styled/CustomDatePickerDateItem';
import { CustomDatePickerMonthTemplate } from 'components/CustomDatePicker/styled/CustomDatePickerMonthTemplate';

type TProps = {
  currentValue: string;
  monthName: string;
  datesList: number[];
  monthOrder: number;
  disableBefore?: string;
  disableAfter?: string;
  onDateSelect(date: string): void;
};

export const CustomDatePickerMonthItem = ({
  currentValue,
  monthName,
  datesList,
  monthOrder,
  disableAfter,
  disableBefore,
  onDateSelect
}: TProps) => {
  const renderedDates = React.useMemo(
    () =>
      datesList.map(date => {
        // Делается проверка на несуществующий день месяца.
        // В случае пройденной проверки отрисовывается пустой
        // элемент, который смещает начало настоящих дней масяца
        // вправо.
        if (date < 8) {
          return <div key={date} />;
        }

        return (
          <CustomDatePickerDateItem
            key={date}
            date={date}
            currentValue={currentValue}
            disableBefore={disableBefore}
            disableAfter={disableAfter}
            onDateSelect={onDateSelect}
          />
        );
      }),
    [datesList, currentValue, disableBefore, disableAfter, onDateSelect]
  );

  /**
   * На средней и быстрой скорости прокрутки быстро
   * появляются лаги. Мемоизация помогает.
   */
  const memoizedMonthGroup = React.useMemo(
    () => (
      <CustomDatePickerMonthTemplate
        key={monthName}
        monthName={monthName}
        order={monthOrder}
      >
        {renderedDates}
      </CustomDatePickerMonthTemplate>
    ),
    [monthName, monthOrder, renderedDates]
  );

  return memoizedMonthGroup;
};
