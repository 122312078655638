import React from 'react';

import styles from './ApplicationsTable.module.scss';

type TProps = {
  children: React.ReactNode;
  testId: string;
};

export const ApplicationsTableBody = ({ children, testId }: TProps) => {
  return (
    <div className={styles.ApplicationsTable__Body} data-testid={testId}>
      {children}
    </div>
  );
};
