import React from 'react';

import styles from './SettingsBlock.module.css';

type Props = {
  text: string;
};

export const SettingsBlockTitle = ({ text }: Props) => (
  <h2 className={styles.SettingsBlock__Title}>{text}</h2>
);
