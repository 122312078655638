import React from 'react';
import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from '../useAsyncController';
import { AuthorizationError } from 'utils/errors';
import { useLoginEditorState } from './useLoginEditorState';

export const useUpdateCurrentUserLoginService = () => {
  const services = useServices();
  const dispatch = useAppDispatch();

  const { formValues, submited } = useLoginEditorState();

  const {
    wrapAsync: wrapUpdate,
    asyncProcess: updateProcess
  } = useAsyncController<void>();

  React.useEffect(() => {
    if (submited) {
      const promise = services
        .updateCurrentUserLogin({
          login: formValues.login,
          password: formValues.password
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }
          return error;
        });

      wrapUpdate(promise);
    }
  }, [services, submited, wrapUpdate, formValues.login, formValues.password]);

  React.useEffect(() => {
    if (updateProcess.status === 'PENDING') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_LOGIN_SERVICE',
        status: 'PENDING'
      });
    }
    if (updateProcess.status === 'RESOLVED') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_LOGIN_SERVICE',
        status: 'RESOLVED',
        payload: updateProcess.value
      });
    }
    if (updateProcess.status === 'REJECTED') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_LOGIN_SERVICE',
        status: 'REJECTED',
        payload: updateProcess.error
      });
    }
  }, [dispatch, updateProcess]);
};
