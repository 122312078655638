import React from 'react';

import styles from 'components/CompetitionParticipants/CompetitionParticipants.module.css';
import { FileIcon } from 'svg/24x24/file';

type TProps = {
  onClick(): void;
};

export const CompetitionParticipantsLogButton = ({ onClick }: TProps) => {
  return (
    <button
      className={styles.CompetitionParticipants__LogButton}
      onClick={onClick}
    >
      <FileIcon />
    </button>
  );
};
