import React from 'react';

import './shadow.css';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  direction: 'up' | 'down';
  enable?: boolean;
};

export const Shadow = ({ direction, enable }: Props) => {
  const className = createBEMConstructor('shadow')({ direction, enable });

  return <div className={className} />;
};
