export const createQueryString = (params: any) => {
  const queryConstructor = new URLSearchParams();

  for (let key in params) {
    const value = params[key];

    if (value !== undefined) {
      queryConstructor.append(key, `${value}`);
    }
  }

  return queryConstructor.toString();
};
