import { authorizedRoutes } from 'utils/authorizedRoutes';
import { UnexpectedError } from 'utils/errors';
import { useRouteMatchStrict } from 'hooks/useRouteMatchStrict';
import { ServiceScope } from 'types/serviceScope';

export const useApplicationsPageDomain = (): ServiceScope => {
  const { path } = useRouteMatchStrict();

  if (path === authorizedRoutes.applicationsRestaurantPattern()) {
    return 'RESTAURANT';
  }

  if (path === authorizedRoutes.applicationsSportPattern()) {
    return 'CLUB';
  }

  throw new UnexpectedError();
};
