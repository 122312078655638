import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { reviewsStoreMock } from 'services/mock/utils/reviewsModel';

export const getNewReviewsCount = async () => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  const review = reviewsStoreMock
    .getAllReviews()
    .filter(({ status }) => status === 'NEW');

  return review.length;
};
