import { SportingPlaceDestinationType } from '../types';
import { UnreachableError } from '../../../utils/errors';
import { ServiceScope } from 'types/serviceScope';

export const normalizeSportingPlaceDestinationType = (
  location: SportingPlaceDestinationType
) => ({
  toServiceScope(): ServiceScope {
    switch (location) {
      case 'RESTARAUNT':
        return 'RESTAURANT';
      case 'CLUB':
        return 'CLUB';
      default:
        throw new UnreachableError(location);
    }
  }
});
