import React from 'react';

import { MessageBox } from 'design/messageBox';
import { MessageTemplatesListItemLayout } from 'design/messageTemplatesListItemLayout';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useMessageTemplatesState } from 'hooks/messageTemplates/useMessageTemplatesState';
import { useIntlContext } from 'hooks/useIntlContext';
import { MESSAGE_TEMPLATE_MAX_LENGTH, KEY_ENTER } from 'utils/constants';
import { ContentEditable } from 'design/contentEditable';
import { useAddMessageTemplateService } from 'hooks/messageTemplates/useAddMessageTemplateService';

export const MessageTemplatesCandidate = () => {
  const dispatch = useAppDispatch();

  const { messageTemplates } = useIntlContext();

  const { templateCandidateText } = useMessageTemplatesState();

  const addMessageTemplate = useAddMessageTemplateService();

  const handleTextChange = React.useCallback(
    (nextValue: string) => {
      dispatch({
        name: 'MESSAGE_TEMPLATES__CANDIDATE_TEXT_CHANGED',
        nextValue
      });
    },
    [dispatch]
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === KEY_ENTER) {
        if (event.metaKey || event.ctrlKey) {
          addMessageTemplate();
        }
      }
    },
    [addMessageTemplate]
  );

  return (
    <MessageTemplatesListItemLayout
      primaryAction={null}
      message={
        <MessageBox theme="template-input">
          <ContentEditable
            autoFocus
            value={templateCandidateText}
            maxLength={MESSAGE_TEMPLATE_MAX_LENGTH}
            placeholder={messageTemplates.inputPlaceholder}
            onValueChange={handleTextChange}
            onKeyDown={handleKeyDown}
          />
        </MessageBox>
      }
      secondaryAction={null}
    />
  );
};
