import { TSportingUserRole } from 'services/sporting/types';
import { UserRole } from 'types/state';
import { UnexpectedError } from 'utils/errors';

export const normalizeSportingUserRole = {
  toUserRole(sportingUserRole: TSportingUserRole): UserRole {
    switch (sportingUserRole) {
      case 'ROLE_ADMIN':
        return 'ADMINISTRATOR';
      case 'ROLE_MANAGER':
        return 'MANAGER';
      case 'ROLE_EMPLOYEE':
        return 'CURATOR';
      case 'ROLE_SECURITY':
        return 'SECURITY';
      default:
        throw new UnexpectedError();
    }
  },
  toSportingUserRole(userRole: UserRole): TSportingUserRole {
    switch (userRole) {
      case 'ADMINISTRATOR':
        return 'ROLE_ADMIN';
      case 'MANAGER':
        return 'ROLE_MANAGER';
      case 'CURATOR':
        return 'ROLE_EMPLOYEE';
      case 'SECURITY':
        return 'ROLE_SECURITY';
      default:
        throw new UnexpectedError();
    }
  }
};
