import { GetUnreadChatIdsParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';
import { normalizeServiceScope } from '../utils/normalizeServiceScope';

export const getUnreadChatIds = async (
  params: GetUnreadChatIdsParams
): Promise<number[]> => {
  const { domain } = params;

  const destinactionType = normalizeServiceScope(
    domain
  ).toSportingServiceScope();

  const ids = await sportingAPI.chat.getUnreadedChats({
    payload: { destinactionType }
  });

  return ids;
};
