import { TGetCompetitionParticipantLogParams } from 'types/services';
import { TCompetitionParticipantLogRecord } from 'types/state';
import { normalizeSportingStatusHistoryItem } from 'services/sporting/utils/normalizeSportingStatusHistoryItem';
import { sportingAPI } from 'services/sporting/api';

export const getCompetitionParticipantLog = async (
  params: TGetCompetitionParticipantLogParams
): Promise<TCompetitionParticipantLogRecord[]> => {
  const { participantId } = params;

  const items = await sportingAPI.service.getParticipationStatusHistory({
    payload: {
      idParticipation: participantId
    }
  });

  return items.map(
    normalizeSportingStatusHistoryItem.toCompetitionParticipantLogRecord
  );
};
