import React from 'react';

import styles from './booleanInputControl.module.css';

import {
  createClassNameConstructor,
  ClassNameConstructor
} from 'utils/craeteClassNameConstructor';

type Theme = 'dot';

type Props = {
  value: boolean;
  theme?: Theme;
  testId?: string;
  onValueChange(nextValue: boolean): void;
};

const applyThemeModification = (theme?: Theme) => (
  constructor: ClassNameConstructor
) => {
  switch (theme) {
    case 'dot':
      return constructor.addClass(styles.BooleanInputControl_themeDot);
    default:
      return constructor;
  }
};

export const BooleanInputControl = (props: Props) => {
  const { value, theme, onValueChange, testId } = props;

  const handleChange = React.useCallback(() => {
    onValueChange(!value);
  }, [onValueChange, value]);

  const classNameConstructor = createClassNameConstructor(
    styles.BooleanInputControl
  );

  const wrapperClassName = classNameConstructor
    .addClass(styles.BooleanInputControl__Wrapper)
    .withConstructor(applyThemeModification(theme))
    .return();

  const inputClassName = classNameConstructor
    .addClass(styles.BooleanInputControl__Input)
    .return();

  const labelClassName = classNameConstructor
    .addClass(styles.BooleanInputControl__Label)
    .return();

  return (
    <div className={wrapperClassName}>
      <input
        type="checkbox"
        checked={value}
        className={inputClassName}
        onChange={handleChange}
        data-testid={testId}
      />
      <div className={labelClassName}></div>
    </div>
  );
};
