import React from 'react';

import styles from './CompetitionParticipants.module.css';

type Props = {
  children: React.ReactNode;
};

export const CompetitionParticipantsHeader: React.FC<Props> = ({
  children
}) => {
  return (
    <thead className={styles.CompetitionParticipants__Header}>
      <tr>{children}</tr>
    </thead>
  );
};
