import { Place } from '../../../types/state';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';
import { placesStoreMock } from '../utils/placesModel';

export const getPlaces = async (): Promise<Place[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return placesStoreMock.getAll();
};
