import React from 'react';

import { CropImageModal } from 'containers/CropImageModal';
import { ImageInputTemplate } from 'containers/ImageInput/view/styled/ImageInputTemplate';
import { ImageInputPreview } from 'containers/ImageInput/view/styled/ImageInputPreview';
import { ImageInputFallback } from 'containers/ImageInput/view/styled/ImageInputFallback';

type TProps = {
  ratio: number;
  croppedMinWidth: number;
  croppedMinHeight: number;
  imageInputPlaceholder: string;
  errorMessage: null | string;
  infoMessage: string;
  imagePreviewSrc: null | string;
  nextOriginal: null | File;
  hasPreloader: boolean;
  handleEdit(): void;
  handleChange(params: File): void;
  handleDelete(): void;
  handleCropped(file: File): void;
  handleCropCancel(): void;
};

export const ImageInputView = (props: TProps) => {
  const {
    ratio,
    croppedMinWidth,
    croppedMinHeight,
    imageInputPlaceholder,
    errorMessage,
    infoMessage,
    imagePreviewSrc,
    nextOriginal,
    hasPreloader,
    handleEdit,
    handleChange,
    handleDelete,
    handleCropped,
    handleCropCancel
  } = props;

  return (
    <ImageInputTemplate
      onChange={handleChange}
      disabled={!!imagePreviewSrc}
      ratio={ratio}
    >
      {!!imagePreviewSrc && !errorMessage ? (
        <ImageInputPreview
          hasPreloader={hasPreloader}
          src={imagePreviewSrc}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ) : (
        <ImageInputFallback
          placeholder={imageInputPlaceholder}
          errorMessage={errorMessage}
          infoMessage={infoMessage}
        />
      )}
      {nextOriginal && (
        <CropImageModal
          file={nextOriginal}
          onCropped={handleCropped}
          onCancel={handleCropCancel}
          aspectRatio={ratio}
          minWidth={croppedMinWidth}
          minHeight={croppedMinHeight}
        />
      )}
    </ImageInputTemplate>
  );
};
