import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TDeleteCompetitionsArticleParams } from 'types/services';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const DELETE_COMPETITIONS_ARTICLE_ID_MOCK__CONNECTION_ERROR = -2;

export const deleteCompetitionsArticle = async (
  params: TDeleteCompetitionsArticleParams
): Promise<void> => {
  const { id } = params;
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (id === DELETE_COMPETITIONS_ARTICLE_ID_MOCK__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }
  competitionsStoreMock.deleteArticle(id);
};
