import React from 'react';

import styles from './RoundButton.module.scss';

type TProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const RoundButton = ({ children, onClick, className = '' }: TProps) => (
  <button className={`${styles.RoundButton} ${className}`} onClick={onClick}>
    {children}
  </button>
);
