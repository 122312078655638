import { EditChatMessageParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const editChatMessage = async (
  params: EditChatMessageParams
): Promise<void> => {
  const { messageId, text } = params;

  const trimmedText = text.trim();

  const shouldDelete = trimmedText === '';

  if (shouldDelete) {
    await sportingAPI.chat.deleteMessage({
      payload: { idMessage: messageId }
    });
  } else {
    await sportingAPI.chat.editMessage({
      payload: { idMessage: messageId, message: trimmedText }
    });
  }
};
