import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { SportingEvent } from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';
import { createQueryString } from 'utils/createQueryString';

type Params = {
  tabId: string;
};

export const subscribeToEvents = async (
  params: Params
): Promise<null | SportingEvent> => {
  const queryString = createQueryString(params);

  const endpointURL = getSportingServiceURL(`subscribeToEvents?${queryString}`);

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    headers
  });

  if (!response.ok) {
    if (response.status === 408 || response.status === 401) {
      return null;
    }

    throw new UnexpectedError();
  }

  return response.json();
};
