import { SportingChat } from 'services/sporting/types';
import { ChatRoom } from 'types/chats';
import { ClientInfo } from 'types/client';
import { normalizeSportingServiceScope } from 'services/sporting/utils/normalizeSportingServiceScope';
import { normalizeSportingCardType } from 'services/sporting/utils/normalizeSportingCardStatus';
import { toMaskedPhone } from 'utils/toMaskedPhone';

export const normalizeSportingChat = (chat: SportingChat) => ({
  toChatRoom(): ChatRoom {
    return {
      id: chat.idChat,
      lastMessageId: chat.lastMessage.idMessage,
      read: chat.readed,
      unreadMessagesCount: chat.countUnreadedMessage,
      domain: normalizeSportingServiceScope(
        chat.destinactionType
      ).toServiceScope(),
      clientInfo: normalizeSportingChat(chat).toClientInfo()
    };
  },
  toClientInfo(): ClientInfo {
    return {
      id: chat.idUser,
      firstName: chat.userName || '',
      lastName: chat.userLastName || '',
      middleName: chat.userPatronymic || '',
      status: chat.cardType
        ? normalizeSportingCardType(chat.cardType).toClientStatus()
        : 'EMPLOYEE',
      phoneNumber: chat.phoneNumber ? toMaskedPhone(chat.phoneNumber) : '',
      cardNumber: chat.cardNumber || ''
    };
  }
});
