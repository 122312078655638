import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TDeleteNewsArticleParams } from 'types/services';
import { newsStoreMock } from 'services/mock/utils/newsModel';

export const DELETE_NEWS_ARTICLE_ID_MOCK__CONNECTION_ERROR = -2;

export const deleteNewsArticle = async (
  params: TDeleteNewsArticleParams
): Promise<void> => {
  const { id } = params;
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (id === DELETE_NEWS_ARTICLE_ID_MOCK__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }
  newsStoreMock.deleteArticle(id);
};
