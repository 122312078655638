import { SportingCardType } from 'services/sporting/types';
import { ClientStatus } from 'types/client';

export const normalizeSportingCardType = (type: SportingCardType) => ({
  toClientStatus(): ClientStatus {
    if (type === null) {
      return 'EMPLOYEE';
    } else {
      switch (type.id) {
        case '26':
          return 'VIP';
        case '25':
          return 'PREMIUM';
        case '4':
          return 'STANDARD';
        default:
          return 'EMPLOYEE';
      }
    }
  }
});
