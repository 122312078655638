import { useOutsideClick } from 'hooks/useOutsideClick';
import React from 'react';

import './clickBoundaryStyles.css';

type Props = {
  onOutsideClick(): void;
  children: React.ReactNode;
};

export const ClickBoundaryComponent: React.FC<Props> = props => {
  const { onOutsideClick, children } = props;

  const elementRef = React.useRef<HTMLDivElement | null>(null);

  useOutsideClick(elementRef, onOutsideClick);

  return (
    <div className="clickBoundary" ref={elementRef}>
      {children}
    </div>
  );
};
