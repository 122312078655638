import React from 'react';
import { ReviewNotesWrapper } from 'containers/ReviewNotes/view/styled/ReviewNotesWrapper';
import { ReviewNotesTitle } from 'containers/ReviewNotes/view/styled/ReviewNotesTitle';
import { ReviewNotesInput } from 'containers/ReviewNotes/view/styled/ReviewNotesInput';

type TProps = {
  title: string;
  inputPlaceholder: string;
  inputValue: string;
  inputDisabled: boolean;
  onInputValueChange(nextNotes: string): void;
  onInputBlur(): void;
};

export type TReviewNotesViewProps = TProps;

export const ReviewNotesView = (props: TProps) => {
  const {
    title,
    inputPlaceholder,
    inputValue,
    inputDisabled,
    onInputValueChange,
    onInputBlur
  } = props;

  return (
    <ReviewNotesWrapper>
      <ReviewNotesTitle text={title} />
      <ReviewNotesInput
        placeholder={inputPlaceholder}
        value={inputValue}
        disabled={inputDisabled}
        onChange={onInputValueChange}
        onBlur={onInputBlur}
      />
    </ReviewNotesWrapper>
  );
};
