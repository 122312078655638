import React from 'react';
import { useIntlContext } from '../../hooks/useIntlContext';
import { SettingsBlockWrapper } from '../../components/SettingsBlock/SettingsBlockWrapper';
import { SettingsBlockContent } from '../../components/SettingsBlock/SettingsBlockContent';
import { SettingsBlockTitle } from '../../components/SettingsBlock/SettingsBlockTitle';
import { SettingsBlockDescription } from '../../components/SettingsBlock/SettingsBlockDescription';
import { LoginEditorForm } from '../LoginForm';

export const LoginEditor = () => {
  const { loginEditor: loginEditorIntl } = useIntlContext();

  return (
    <SettingsBlockWrapper>
      <SettingsBlockContent position="left">
        <SettingsBlockTitle text={loginEditorIntl.title} />
        <SettingsBlockDescription text={loginEditorIntl.description} />
      </SettingsBlockContent>
      <SettingsBlockContent position="left">
        <LoginEditorForm />
      </SettingsBlockContent>
    </SettingsBlockWrapper>
  );
};
