type TParams = {
  image: HTMLImageElement;
  crop: ReactCrop.Crop;
  fileName: string;
  fileType: string;
};

export const cropImageToFile = async ({
  image,
  crop,
  fileName,
  fileType
}: TParams): Promise<File> => {
  if (typeof crop.width !== 'number' || typeof crop.height !== 'number') {
    throw new TypeError();
  }

  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const checkCanvasToBlob = function(canvas: HTMLCanvasElement) {
    let toBlob = (uri: string): Blob => {
      const mime = uri
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
      const bytes = atob(uri.split(',')[1]);
      const len = bytes.length;
      const buffer = new ArrayBuffer(len);
      const arr = new Uint8Array(buffer);

      for (let i = 0; i < len; i++) {
        arr[i] = bytes.charCodeAt(i);
      }

      return new Blob([arr], { type: mime });
    };

    let blobToValue = function(
      callback: Function,
      type: string,
      quality: number
    ) {
      callback(toBlob(canvas.toDataURL(type, quality)));
    };

    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value: blobToValue
    });

    return canvas;
  };

  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new TypeError();
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    checkCanvasToBlob(canvas).toBlob(blob => {
      if (!blob) {
        reject(new TypeError());
      } else {
        const file = new File([blob], fileName, {
          type: fileType
        });
        resolve(file);
      }
    }, fileType);
  });
};
