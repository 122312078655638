import React from 'react';

import styles from './SectionsForm.module.css';

type Props = {
  children: React.ReactNode;
  text: string;
};

export const SectionsFormLabel: React.FC<Props> = props => {
  const { children, text } = props;

  return (
    <label className={styles.SectionsForm__Label}>
      {text}
      {children}
    </label>
  );
};
