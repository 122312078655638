import { AppEvent } from 'types/appEvent';
import { ChatMessageForm } from 'types/chats';
import { createChatsMessageForm } from 'utils/createChatsState';

export const chatMessageFormByIdReducer = (
  state: Map<number, ChatMessageForm>,
  event: AppEvent
): Map<number, ChatMessageForm> => {
  switch (event.name) {
    case 'CHATS_PAGE__MESSAGE_CHANGE': {
      const form = state.get(event.roomId) || createChatsMessageForm();

      const nextForm: ChatMessageForm = {
        ...form,
        value: event.nextValue
      };

      const nextState = new Map(state);

      nextState.set(event.roomId, nextForm);

      return nextState;
    }
    case 'CHATS_PAGE_MESSAGES__RETRY_BUTTON_CLICK': {
      const form = state.get(event.roomId) || createChatsMessageForm();

      const nextForm: ChatMessageForm = {
        ...form,
        status: 'SUBMITTED'
      };

      const nextState = new Map(state);

      nextState.set(event.roomId, nextForm);

      return nextState;
    }
    case 'CHATS_PAGE__MESSAGE_SUBMIT': {
      const form = state.get(event.roomId) || createChatsMessageForm();

      const nextForm: ChatMessageForm = {
        ...form,
        submittedValue: form.value.trim(),
        value: '',
        status: 'SUBMITTED'
      };

      const nextState = new Map(state);

      nextState.set(event.roomId, nextForm);

      return nextState;
    }
    case 'CHAT_MESSAGE_SUBMISSION_SERVICE': {
      const form = state.get(event.meta.roomId) || createChatsMessageForm();

      let nextForm: ChatMessageForm = form;

      if (event.status === 'RESOLVED') {
        nextForm = createChatsMessageForm();
      }

      if (event.status === 'REJECTED') {
        nextForm = {
          ...form,
          status: 'REJECTED'
        };
      }

      const nextState = new Map(state);

      nextState.set(event.meta.roomId, nextForm);

      return nextState;
    }
    case 'MESSAGE_TEMPLATES__TEMPLATE_CLICKED': {
      const form = state.get(event.roomId) || createChatsMessageForm();

      if (form.status === 'IDLE') {
        let nextValue;

        if (form.value) {
          nextValue = `${form.value} ${event.template.text}`;
        } else {
          nextValue = event.template.text;
        }

        const nextForm: ChatMessageForm = {
          ...form,
          value: nextValue
        };

        const nextState = new Map(state);

        nextState.set(event.roomId, nextForm);

        return nextState;
      }

      return state;
    }
    default:
      return state;
  }
};
