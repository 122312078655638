import { UpdateSheduleInfoService } from 'types/services';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { asyncTimeout } from 'utils/asyncTimeout';

export const updateSheduleInfo: UpdateSheduleInfoService = async params => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return {
    restaurant: '10:01 - 18:01',
    hunting: '12:02 - 20:02'
  };
};
