import React from 'react';

import styles from './SettingsForm.module.css';

type Props = {
  children: React.ReactNode;
  scrollIntoView?: boolean;
};

export const SettingsFormFooter: React.FC<Props> = props => {
  const { children, scrollIntoView } = props;

  const elementRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    const { current: element } = elementRef;

    if (element instanceof HTMLDivElement && scrollIntoView) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollIntoView]);

  return (
    <div ref={elementRef} className={styles.SettingsForm__Footer}>
      {children}
    </div>
  );
};
