import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './typingIndicatorLayout.scss';

type Props = {
  text: React.ReactNode;
};

export const TypingIndicatorLayout = (props: Props) => {
  const { text } = props;

  const bem = createBEMConstructor('typing-indicator-layout');

  const wrapperClassName = bem('wrapper');

  const textClassName = bem('text');

  return (
    <div className={wrapperClassName}>
      <div className={textClassName}>{text}</div>
    </div>
  );
};
