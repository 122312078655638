import React from 'react';

import styles from './CompetitionsFeed.module.css';

import { Spinner } from 'components/shared/Spinner';
import { testId } from 'utils/testId';

export const CompetitionsFeedPreloader = () => (
  <div
    data-testid={testId.COMPETITIONS_FEED_PRELOADER}
    className={styles.CompetitionsFeed__Preloader}
  >
    <Spinner />
  </div>
);
