import React from 'react';

import styles from './CropImageModal.module.css';

import { RectangleButton, TStyleType } from 'components/shared/RectangleButton';

type TProps = {
  label: string;
  styleType?: TStyleType;
  isDisabled?: boolean;
  hasPreloader?: boolean;
  onClick(): void;
};

export const CropImageModalButton = (props: TProps) => {
  return (
    <RectangleButton {...props} className={styles.CropImageModal__Button} />
  );
};
