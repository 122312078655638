import { Person } from 'types/person';

export function normalizePerson(personLike: Person) {
  const { lastName, firstName, middleName } = personLike;

  return {
    toFullName(): string {
      return [lastName, firstName, middleName].reduce((acc, item) => {
        if (item) {
          return `${acc} ${item}`.trim();
        }

        return acc;
      }, '');
    }
  };
}
