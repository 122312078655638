import { customFetch } from 'utils/customFetch';
import { LoginInUseError, UnexpectedError } from 'utils/errors';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import {
  SportingSecureParams,
  TSportingUserInfo,
  TSportingUserRole
} from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';
import { appendHeadersWithJSON } from 'utils/headers';

type EmployeePayload = {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  phoneNumber: string;
  role: TSportingUserRole;
};

export const updateEmployee = async (
  params: SportingSecureParams<EmployeePayload>
): Promise<TSportingUserInfo> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL(`updateEmployee/${payload.id}`);

  const headers = createSportingHeaders();
  appendHeadersWithJSON(headers);

  const body = JSON.stringify(payload);

  const response = await customFetch(endpointURL, {
    method: 'PUT',
    headers,
    body
  });

  if (!response.ok) {
    if (response.status === 400) {
      throw new LoginInUseError();
    }
    throw new UnexpectedError();
  }
  return response.json();
};
