import { TUpdateApplicationStatusParams } from 'types/services';
import { normalizeApplicationStatus } from 'services/sporting/utils/normalizeApplicationStatus';
import { sportingAPI } from 'services/sporting/api';

export const updateApplicationStatus = async (
  params: TUpdateApplicationStatusParams
): Promise<void> => {
  const { id, nextStatus } = params;

  const idStatus = normalizeApplicationStatus.toSportingClaimStatusId(
    nextStatus
  );

  await sportingAPI.service.changeClaimStatus({
    payload: { idClaim: id, idStatus }
  });
};
