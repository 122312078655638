import React from 'react';

function getItemKey(id: number): string {
  return `bookingTimlineGroup_${id}_collapsed`;
}

export function useBookingTimelineGroupCollapsing(id: number) {
  const initialValue = React.useMemo(() => {
    const collapsed = localStorage.getItem(getItemKey(id));

    return collapsed === 'true';
  }, [id]);

  const [collapsed, setCollapsed] = React.useState(initialValue);

  React.useEffect(() => {
    localStorage.setItem(getItemKey(id), `${collapsed}`);
  }, [collapsed, id]);

  const toggleCollapsed = React.useCallback(() => {
    setCollapsed(prev => !prev);
  }, []);

  return { collapsed, toggleCollapsed };
}
