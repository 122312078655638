import { AppEvent } from 'types/appEvent';
import { AppState } from 'types/appState';

export const activeChatRoomIdReducer = (
  state: null | number,
  event: AppEvent,
  appState: AppState
): null | number => {
  switch (event.name) {
    case 'CHAT_ROOMS_SERVICE': {
      if (state === null && event.status === 'RESOLVED') {
        if (event.meta.page === 1) {
          const target = event.payload.find((item, index) => index === 0);

          if (target) {
            const [room] = target;

            return room.id;
          }

          return state;
        }
      }

      return state;
    }
    case 'CHATS_PAGE_PREVIEW__CLICKED': {
      return event.payload;
    }
    case 'CHATS_PAGE__CLOSED': {
      return null;
    }
    case 'TOAST__CLICK': {
      const { candidate } = event;

      const {
        userInfoCache: { value: userInfo }
      } = appState;

      const sportChatEnabled = !!userInfo?.enabledSections.includes(
        'chatsSport'
      );

      if (candidate.name === 'SPORT_NEW_CHAT_MESSAGE' && sportChatEnabled) {
        return candidate.roomId;
      }

      const restaurantChatEnabled = !!userInfo?.enabledSections.includes(
        'chatsRestaurant'
      );

      if (
        candidate.name === 'RESTAURANT_NEW_CHAT_MESSAGE' &&
        restaurantChatEnabled
      ) {
        return candidate.roomId;
      }

      return state;
    }
    default:
      return state;
  }
};
