import React from 'react';
import styles from './BookingWaitersEditorModal.module.scss';
import { ModalWindow } from '../Modal/ModalWindow';
import { RectangleButton } from '../shared/RectangleButton';
import { ModalFooter } from '../Modal/ModalFooter';
import { ModalPortal } from '../shared/Modal';
import { useIntlContext } from '../../hooks/useIntlContext';
import { CreateButton } from '../shared/CreateButton';
import { BookingWaiterItem } from './components/BookingWaiterItem';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useWaitersCache } from '../../hooks/booking/useWaitersCache';
import { CloseIcon } from '../../svg/24x24/close';
import { useKeyDown } from 'hooks/useKeyDown';
import { validateBookingWaitersFormValues } from 'utils/validateBookingWaitersFormValues';
import {
  BookingWaiterFormValues,
  BookingWaitersFormViolation
} from 'types/state';
import { useBookingWaitersDeleteService } from 'hooks/booking/useBookingWaitersDeleteService';
import { useBookingWaitersCreateService } from 'hooks/booking/useBookingWaitersCreateService';
import { useAsyncController } from 'hooks/useAsyncController';
import { createUniqueId } from 'utils/createUniqueId';

type Params = {
  onClose(): void;
};

export const BookingWaitersEditorModal = (props: Params) => {
  const { onClose } = props;

  const { wrapAsync, asyncProcess } = useAsyncController();

  const { deleteWaiters } = useBookingWaitersDeleteService();

  const { createWaiters } = useBookingWaitersCreateService();

  const { bookingWaitersEditorIntl: msgs } = useIntlContext();

  const appDispatch = useAppDispatch();

  const waitersCache = useWaitersCache();
  const waiters = React.useMemo(() => Array.from(waitersCache.value.values()), [
    waitersCache
  ]);

  const [formValues, setFormValues] = React.useState<BookingWaiterFormValues[]>(
    []
  );

  const [violations, setViolations] = React.useState<
    BookingWaitersFormViolation[]
  >([]);

  React.useEffect(() => {
    if (violations.length > 0) {
      setViolations(validateBookingWaitersFormValues(formValues));
    }
  }, [formValues, violations.length]);

  // Устанавливаем начальные значения для формы
  React.useEffect(() => {
    setFormValues(
      waiters.map<BookingWaiterFormValues>(waiter => ({
        fio: waiter.fio,
        id: `${waiter.id}`,
        dataAction: 'NOTHING'
      }))
    );
  }, [appDispatch, waiters]);

  const onAddWaiter = React.useCallback(() => {
    setFormValues(prev => [
      {
        id: createUniqueId(),
        fio: '',
        dataAction: 'CREATE_NEW'
      },
      ...prev
    ]);
  }, []);

  const onChangeWaiterName = React.useCallback((id: string, value: string) => {
    setFormValues(prev =>
      prev.map(waiterValues => ({
        ...waiterValues,
        fio: waiterValues.id === id ? value : waiterValues.fio
      }))
    );
  }, []);

  const onDeleteWaiter = React.useCallback((id: string, fio: string) => {
    setFormValues(prev =>
      prev.map(waiterValues => ({
        ...waiterValues,
        dataAction: waiterValues.id === id ? 'DELETE' : waiterValues.dataAction
      }))
    );
  }, []);

  const onSubmit = React.useCallback(() => {
    const violations = validateBookingWaitersFormValues(formValues);

    if (violations.length === 0) {
      // Запросы изменяющие состяоние бэка лушче выстраивать в очередь чтобы избежать конфликтов. Бывало и такое.
      const promise = Promise.resolve()
        .then(() => {
          const waitersToCreateFullNames = formValues
            .filter(waiter => waiter.dataAction === 'CREATE_NEW')
            .map(waiter => waiter.fio);

          if (waitersToCreateFullNames.length > 0) {
            return createWaiters(waitersToCreateFullNames);
          }
        })
        .then(() => {
          const waitersToDeleteIds = formValues
            .filter(waiter => waiter.dataAction === 'DELETE')
            .map(waiter => Number(waiter.id));

          if (waitersToDeleteIds.length > 0) {
            return deleteWaiters(waitersToDeleteIds);
          }
        })
        .then(onClose);

      wrapAsync(promise);
    } else {
      setViolations(violations);
    }
  }, [createWaiters, deleteWaiters, formValues, onClose, wrapAsync]);

  const busy =
    asyncProcess.status === 'PENDING' || asyncProcess.status === 'RESOLVED';

  useKeyDown({
    Escape: onClose
  });

  return (
    <ModalPortal noPadding={true} toRight={true}>
      <ModalWindow additionalClassName={styles.modalWindow}>
        <div className={styles.header}>
          <div className={styles.closeBtn} onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.titleLabel}>{msgs.title}</div>
          <div className={styles.addWaiterBtnPanelWrapper}>
            <div className={styles.addWaiterBtnPanel} onClick={onAddWaiter}>
              <CreateButton className={styles.addWaiterBtn} useDiv={false} />
              <div className={styles.addWaiterLabel}>{msgs.addBtnLabel}</div>
            </div>
          </div>
          <div className={styles.waitersContainer}>
            {formValues
              .filter(waiterValues => waiterValues.dataAction !== 'DELETE')
              .map(waiterValues => (
                <BookingWaiterItem
                  key={waiterValues.id}
                  violations={violations}
                  waiter={waiterValues}
                  isEditable={() => waiterValues.dataAction === 'CREATE_NEW'}
                  onChangeName={value =>
                    onChangeWaiterName(waiterValues.id, value)
                  }
                  onDelete={onDeleteWaiter}
                />
              ))}
          </div>
        </div>
        <ModalFooter additionalClassName={styles.modalFooter}>
          <RectangleButton
            styleType={'secondary'}
            label={msgs.cancelBtnLabel}
            isDisabled={busy}
            onClick={onClose}
          />
          <RectangleButton
            styleType={'primary'}
            label={msgs.submitBtnLabel}
            hasPreloader={busy}
            onClick={onSubmit}
          />
        </ModalFooter>
      </ModalWindow>
    </ModalPortal>
  );
};
