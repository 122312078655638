import { addDays, startOfDay, startOfYear, startOfYesterday } from 'date-fns';
import { DayOfWeek } from '../types/state';
import { UnreachableError } from './errors';

export function addZ(n: number) {
  return n < 10 ? '0' + n : '' + n;
}

/**
 * @deprecated
 * Используйте утилиты из date-fns напрямую
 */
export const createStartOfDate = (date: Date) => {
  return startOfDay(date);
};

// все ISO форматы нуждаются в проверке на валидность в мобильных версиях браузера
// текущаий формат 2019-07-16T10:13:39.230+0000 - валидный на десктопе но не валидный в мобильных версиях
// поэтому преобразовываем в валидный вид для всех дивайсов
// 2019-07-16T10:13:39.230+0000 -> 2019-07-16T10:13:39.230+00:00
export const setISOToDate = (date: string) => {
  const matchReg = new RegExp('[+][0-9]{4}$', 'gm');
  const match = date.match(matchReg);
  if (match && match.length > 0) {
    const split = match[0].split('');
    split.splice(3, 0, ':');
    return new Date(date.replace(matchReg, split.join('')));
  }
  return new Date(date);
};

/**
 * @deprecated
 * Используйте утилиты из date-fns напрямую
 */
export const createTimeText = (moment: Date) => {
  const dateCopy = new Date(moment);

  return `${addZ(dateCopy.getHours())}:${addZ(dateCopy.getMinutes())}`;
};

/**
 * @deprecated
 * Используйте утилиты из date-fns напрямую
 */
export const createDateText = (moment: Date) => {
  const momentCopy = new Date(moment);

  const date = addZ(momentCopy.getDate());

  const month = addZ(momentCopy.getMonth() + 1);

  const year = momentCopy
    .getFullYear()
    .toString()
    .substr(-2);

  return `${date}/${month}/${year}`;
};

/**
 * @deprecated
 * Используйте утилиты из date-fns напрямую
 */
export const isToday = (date: Date) => {
  return (
    createStartOfDate(date).getTime() ===
    createStartOfDate(new Date()).getTime()
  );
};

/**
 * @deprecated
 * Используйте утилиты из date-fns напрямую
 */
export const isYesterday = (date: Date) => {
  return startOfYesterday().getTime() === createStartOfDate(date).getTime();
};

/**
 * @deprecated
 * Используйте утилиты из date-fns напрямую
 */
export const isWithinCurrentYear = (date: Date) => {
  return startOfYear(new Date()).getTime() <= date.getTime();
};

/**
 * @deprecated
 * Используйте утилиты из date-fns напрямую
 */
export const getDateWithAddDays = (date: Date, daysCount: number) => {
  return addDays(createStartOfDate(date), daysCount);
};

export const convertDateISOToDDMMYYYY = (dateISO: string) => {
  const date = new Date(dateISO);
  const day = addZ(date.getDate());
  const month = addZ(date.getMonth() + 1);
  return day + '.' + month + '.' + date.getFullYear();
};

export const convertDateISOToYYYY_MM_DD = (dateISO: string) => {
  const date = new Date(dateISO);
  const day = addZ(date.getDate());
  const month = addZ(date.getMonth() + 1);
  return date.getFullYear() + '-' + month + '-' + day;
};

export const getDayOfWeekIndex = (dayOfWeek: DayOfWeek): number => {
  switch (dayOfWeek) {
    case 'MONDAY':
      return 1;
    case 'TUESDAY':
      return 2;
    case 'WEDNESDAY':
      return 3;
    case 'THURSDAY':
      return 4;
    case 'FRIDAY':
      return 5;
    case 'SATURDAY':
      return 6;
    case 'SUNDAY':
      return 0;
    default:
      throw new UnreachableError(dayOfWeek);
  }
};

export const equalsDateWithDateOfWeek = (date: Date, dayOfWeek: DayOfWeek) => {
  const index = getDayOfWeekIndex(dayOfWeek);

  const day = date.getDay();

  return day === index;
};

export const getTimeOptions = (
  emptyLabel: string,
  from: number,
  to: number,
  step: number
) => {
  const times = [];
  for (let hour = from; hour < to; hour++) {
    for (let min = 0; min < 60; min += step) {
      const time = ('0' + hour).slice(-2) + ':' + ('0' + min).slice(-2);
      times.push(time);
    }
  }

  const emptyOption = {
    value: '',
    label: emptyLabel
  };
  const timesOptions = times.map(value => ({
    value,
    label: value
  }));
  return [emptyOption, ...timesOptions];
};
