import React from 'react';

import { RATIO_1_1, RATIO_2_1 } from 'utils/constants';

import styles from './ImageInput.module.css';

type TProps = {
  onChange(file: File): void;
  children: React.ReactNode;
  ratio: number;
  disabled: boolean;
};

export const ImageInputTemplate: React.FC<TProps> = ({
  onChange,
  ratio,
  children,
  disabled
}: TProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    if (files && files[0]) {
      onChange(files[0]);
    }
    event.currentTarget.value = '';
  };
  return (
    <div
      className={`${styles.ImageInput__Wrapper} ${
        ratio === RATIO_1_1 ? styles.ImageInput__Wrapper_1_1 : ''
      } ${ratio === RATIO_2_1 ? styles.ImageInput__Wrapper_2_1 : ''}`}
    >
      <input
        type="file"
        className={styles.ImageInput__Input}
        onChange={handleChange}
        disabled={disabled}
      />
      {children}
    </div>
  );
};
