import { AppEvent } from 'types/appEvent';

export const activeChatRoomScrolledToBottomReducer = (
  state: boolean,
  event: AppEvent
): boolean => {
  switch (event.name) {
    case 'CHATS_PAGE__CONTENT_DID_MOUNT':
    case 'CHATS_PAGE__CLOSED': {
      return false;
    }
    case 'CHATS_PAGE_MESSAGES__SCROLLED_TO_BOTTOM':
      return true;
    case 'CHATS_PAGE_MESSAGES__SCROLLED_FROM_BOTTOM':
      return false;
    default:
      return state;
  }
};
