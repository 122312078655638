import React from 'react';
import styles from '../BookingPlace/BookingPlaceLayout.module.scss';

type Props = {
  nameInputControl: React.ReactNode;
  downControl: React.ReactNode;
  upControl: React.ReactNode;
  deleteControl: React.ReactNode;
  body: React.ReactNode;
  addControl: React.ReactNode;
};

export const BookingPlaceLayout = (props: Props) => {
  const {
    nameInputControl,
    downControl,
    upControl,
    deleteControl,
    addControl,
    body
  } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.nameInputSlot}>{nameInputControl}</div>
        <div className={styles.headerControls}>
          <div className={styles.downControlSlot}>{downControl}</div>
          <div className={styles.upControlSlot}>{upControl}</div>
          <div className={styles.deleteControlSlot}>{deleteControl}</div>
        </div>
      </div>
      <div className={styles.bodySlot}>{body}</div>
      <div className={styles.footer}>
        <div className={styles.addControlSlot}>{addControl}</div>
      </div>
    </div>
  );
};
