import { SetChatRoomReadStatusParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const setChatRoomReadStatus = async (
  params: SetChatRoomReadStatusParams
) => {
  const { roomId, nextValue } = params;

  if (nextValue === true) {
    await sportingAPI.chat.setChatAsReaded({
      payload: { idChat: roomId }
    });
  } else {
    await sportingAPI.chat.setChatAsUnreaded({
      payload: { idChat: roomId }
    });
  }
};
