import { EditMessageTemplatesParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const editMessageTemplates = async (
  params: EditMessageTemplatesParams
): Promise<void> => {
  const { changedTemplates, deletedTemplateIds } = params;

  const changePromises = changedTemplates.map(item => {
    const trimmedText = item.text.trim();
    if (trimmedText) {
      return sportingAPI.chat.editAnswerDraft({
        payload: { idDraft: item.id, text: trimmedText }
      });
    } else {
      return sportingAPI.chat.deleteAnswerDraft({
        payload: { idDraft: item.id }
      });
    }
  });

  const deletePromises = deletedTemplateIds.map(id => {
    return sportingAPI.chat.deleteAnswerDraft({
      payload: { idDraft: id }
    });
  });

  await Promise.all([...changePromises, ...deletePromises]);
};
