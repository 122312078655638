type TDimensions = {
  width: number;
  height: number;
};

type TParams = {
  greaterThan?: TDimensions;
  lessThan?: TDimensions;
  equalTo?: TDimensions;
};

export const checkImageDimensions = async (
  file: File,
  params: TParams
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.src = window.URL.createObjectURL(file);

    img.onload = function() {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      const { greaterThan, lessThan, equalTo } = params;
      if (greaterThan) {
        if (width < greaterThan.width || height < greaterThan.height) {
          resolve(false);
        }
      }
      if (lessThan) {
        if (width < lessThan.width || height < lessThan.height) {
          resolve(false);
        }
      }
      if (equalTo) {
        if (width !== equalTo.width || height !== equalTo.height) {
          resolve(false);
        }
      }
      resolve(true);
    };

    img.onerror = reject;
  });
};
