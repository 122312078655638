import React, { ChangeEvent } from 'react';

import { TNewsArticle, TArticeImage, TNewsAudienceGroup } from 'types/state';
import { AudienceInput } from 'containers/AudienceInput';
import { ImageInput } from 'containers/ImageInput';
import {
  NEWS_ARTICLE_TITLE_INPUT_MAX_SIZE,
  NEWS_ARTICLE_DESCRIPTION_INPUT_MAX_SIZE,
  NEWS_ARTICLE_IMAGE_RATIO,
  NEWS_ARTICLE_CROPPED_IMAGE_MIN_WIDTH,
  NEWS_ARTICLE_CROPPED_IMAGE_MIN_HEIGHT
} from 'utils/constants';
import { testId } from 'utils/testId';
import { NewsArticleTemplate } from 'containers/NewsArticle/view/styled/NewsArticleTemplate';
import { NewsArticleContent } from 'containers/NewsArticle/view/styled/NewsArticleContent';
import { NewsArticleInfo } from 'containers/NewsArticle/view/styled/NewsArticleInfo';
import { NewsArticleTextInput } from 'containers/NewsArticle/view/styled/NewsArticleTextInput';
import { NewsArticleImage } from 'containers/NewsArticle/view/styled/NewsArticleImage';
import { NewsArticleTitle } from 'containers/NewsArticle/view/styled/NewsArticleTitle';
import { NewsArticleDescription } from 'containers/NewsArticle/view/styled/NewsArticleDescription';
import { NewsArticleActions } from 'containers/NewsArticle/view/styled/NewsArticleActions';

type TProps = {
  titleInputPlaceholder: string;
  descriptionInputPlaceholder: string;
  hasEditView: boolean;
  data: TNewsArticle;
  handleInputChange(event: ChangeEvent<HTMLTextAreaElement>): void;
  handleImageChange(nextImage: TArticeImage): void;
  handleAudienceChange(nextAudience: TNewsAudienceGroup[]): void;
  handleEdit(): void;
  handleDelete(): void;
};

export const NewsArticleView = (props: TProps) => {
  const {
    titleInputPlaceholder,
    descriptionInputPlaceholder,
    hasEditView,
    data,
    handleInputChange,
    handleImageChange,
    handleAudienceChange,
    handleEdit,
    handleDelete
  } = props;

  return (
    <NewsArticleTemplate hasEditView={hasEditView}>
      {hasEditView ? (
        <NewsArticleContent testId={testId.NEWS_ARTICLE_EDITOR}>
          <ImageInput
            value={data.image}
            ratio={NEWS_ARTICLE_IMAGE_RATIO}
            croppedMinWidth={NEWS_ARTICLE_CROPPED_IMAGE_MIN_WIDTH}
            croppedMinHeight={NEWS_ARTICLE_CROPPED_IMAGE_MIN_HEIGHT}
            onChange={handleImageChange}
          />
          <NewsArticleInfo>
            <NewsArticleTextInput
              autoFocus
              name="title"
              placeholder={titleInputPlaceholder}
              value={data.title}
              onChange={handleInputChange}
              maxLength={NEWS_ARTICLE_TITLE_INPUT_MAX_SIZE}
            />
            <NewsArticleTextInput
              name="description"
              placeholder={descriptionInputPlaceholder}
              value={data.description}
              onChange={handleInputChange}
              maxLength={NEWS_ARTICLE_DESCRIPTION_INPUT_MAX_SIZE}
            />
          </NewsArticleInfo>
        </NewsArticleContent>
      ) : (
        <NewsArticleContent testId={testId.NEWS_ARTICLE_PREVIEW}>
          <NewsArticleImage src={data.image && data.image.cropped} />
          <NewsArticleInfo>
            <NewsArticleTitle
              label={data.title}
              placeholder={titleInputPlaceholder}
            />
            <NewsArticleDescription
              label={data.description}
              placeholder={descriptionInputPlaceholder}
            />
          </NewsArticleInfo>
        </NewsArticleContent>
      )}
      {hasEditView ? (
        <AudienceInput value={data.audience} onChange={handleAudienceChange} />
      ) : (
        <NewsArticleActions onEdit={handleEdit} onDelete={handleDelete} />
      )}
    </NewsArticleTemplate>
  );
};
