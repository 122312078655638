import React from 'react';

import styles from './ClipboardData.module.scss';

type TProps = {
  text: string;
};

export const ClipboardDataPopup = ({ text }: TProps) => (
  <div className={styles.ClipboardData__Popup}>{text}</div>
);
