import React from 'react';

import styles from './ClipboardData.module.scss';
import { windowNavigator } from 'utils/windowNavigator';

type TProps = {
  value: string;
  onCopied(): void;
};

export const ClipboardDataInput = ({ value, onCopied }: TProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [readOnlyState, setReadOnlyState] = React.useState(true);

  const handleClick = React.useCallback(() => {
    const { current: inputNode } = inputRef;

    if (inputNode) {
      if (windowNavigator.userAgentMatch(/ipad|iphone/i)) {
        setReadOnlyState(false);

        const range = document.createRange();
        range.selectNodeContents(inputNode);

        const selection = window.getSelection();

        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }

        inputNode.setSelectionRange(0, value.length);
        setReadOnlyState(true);
      } else {
        inputNode.select();
      }
      document.execCommand('copy');
      onCopied();
    }
  }, [onCopied, value.length]);

  return (
    <input
      ref={inputRef}
      className={styles.ClipboardData__Input}
      onClick={handleClick}
      value={value}
      readOnly={readOnlyState}
    />
  );
};
