import React from 'react';

type HanldersByKey = {
  [x: string]: () => void;
};

export const useKeyDown = (handlersByKey: HanldersByKey) => {
  const handleKeydown = React.useCallback(
    (event: KeyboardEvent) => {
      const handler = handlersByKey[event.key];

      if (handler) {
        handler();
      }
    },
    [handlersByKey]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);
};
