import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

export const getNewCommentsCount = async (): Promise<number[]> => {
  const endpointURL = getSportingServiceURL(`getNewCommentsCount`);

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  if (response.status === 200) {
    return response.json();
  }

  return [];
};
