import React from 'react';

import { CalendarPeriod } from 'types/state';
import { splitCalendarPeriod } from 'utils/calendarPeriodUtils';
import { MINUTE } from 'utils/constants';

const UNIT_PERIOD_IN_MS = MINUTE * 30;

export const useBookablePeriods = (workingHours: CalendarPeriod) => {
  const bookablePeriods = React.useMemo(() => {
    const periods = splitCalendarPeriod(workingHours, UNIT_PERIOD_IN_MS);

    return periods;
  }, [workingHours]);

  return bookablePeriods;
};
