import { TNewsArticle, TCompetitionsArticle } from 'types/state';

type TParams = {
  asDraft: boolean;
};

const defaultParams: TParams = {
  asDraft: false
};

export const checkNewsArticleIsReadyForSubmit = (
  article: TNewsArticle,
  { asDraft }: TParams = defaultParams
) => {
  const titleIsReady = article.title.length > 0;
  const descriptionIsReady = article.description.length > 0;
  const imageIsReady = article.image !== null;
  const audienceIsReady = article.audience.length > 0;
  if (asDraft) {
    return (
      titleIsReady || descriptionIsReady || imageIsReady || audienceIsReady
    );
  }
  return titleIsReady && descriptionIsReady && imageIsReady && audienceIsReady;
};

export const checkCompetitionsArticleIsReadyForSubmit = (
  article: TCompetitionsArticle,
  { asDraft }: TParams = defaultParams
) => {
  const titleIsReady = article.title.length > 0;
  const descriptionIsReady = article.description.length > 0;
  const imageIsReady = article.image !== null;
  const durationIsReady = article.duration !== null;
  if (asDraft) {
    return (
      titleIsReady || descriptionIsReady || imageIsReady || durationIsReady
    );
  }
  return titleIsReady && descriptionIsReady && imageIsReady && durationIsReady;
};
