import React from 'react';

import styles from './CompetitionParticipantLog.module.css';

type TProps = {
  title: string;
  body: string;
};

export const CompetitionParticipantLogItem = (props: TProps) => {
  const { title, body } = props;
  return (
    <div className={styles.CompetitionParticipantLog__ItemWrapper}>
      <div className={styles.CompetitionParticipantLog__ItemTitle}>{title}</div>
      <div className={styles.CompetitionParticipantLog__ItemBody}>{body}</div>
    </div>
  );
};
