import React from 'react';

import { Text } from 'design/text';
import { Bage } from 'design/bage';
import { ContactInfoLayout } from 'design/contactInfoLayout';
import { ActionControl } from 'design/actionControl';
import { ClientInfo } from 'types/client';
import { useServices } from 'hooks/useServices';
import { UnreachableError } from 'utils/errors';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { PhoneIcon } from 'svg/24x24/phone';
import { useIntlContext } from 'hooks/useIntlContext';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ServiceScope } from 'types/serviceScope';
import { BookingSelectControlComponent } from 'containers/BookingSelectControlComponents';

type Props = {
  serviceScope: ServiceScope;
  clientInfo: ClientInfo;
};

export const ChatsPageContacts = (props: Props) => {
  const { clientInfo } = props;

  const dispatch = useAppDispatch();

  const intl = useIntlContext();

  const clientFullName = intl.personFullName(clientInfo);

  const { makePhoneCall } = useServices();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const makeCall = React.useCallback(() => {
    const promise = makePhoneCall({
      userId: clientInfo.id,
      applicationId: null
    });

    wrapAsyncSafely(promise);
  }, [clientInfo.id, makePhoneCall, wrapAsyncSafely]);

  const createBooking = React.useCallback(
    (serviceScope: ServiceScope) => {
      dispatch({
        name: 'CHATS_PAGE__BOOKING_BUTTON_CLICKED',
        initialDateISO: new Date().toISOString(),
        serviceScope,
        clientInfo
      });
    },
    [clientInfo, dispatch]
  );

  const bage = React.useMemo<React.ReactNode>(() => {
    switch (clientInfo.status) {
      case 'VIP':
        return <Bage theme="black" label={`${clientInfo.cardNumber}`} />;
      case 'PREMIUM':
        return <Bage theme="gold" label={`${clientInfo.cardNumber}`} />;
      case 'STANDARD':
      case 'EMPLOYEE':
        return <Bage theme="silver" label={`${clientInfo.cardNumber}`} />;
      default:
        throw new UnreachableError(clientInfo.status);
    }
  }, [clientInfo.cardNumber, clientInfo.status]);

  return (
    <ContactInfoLayout
      fullName={
        <>
          <Text bold>{clientFullName}</Text> {bage}
        </>
      }
      phone={<Text>{clientInfo.phoneNumber}</Text>}
      bookingAction={
        <BookingSelectControlComponent chat onOptionClick={createBooking} />
      }
      callAction={
        <ActionControl theme="round-secondary" onClick={makeCall}>
          <PhoneIcon />
        </ActionControl>
      }
    />
  );
};
