import React from 'react';

import styles from './CompetitionsArticle.module.css';

type TProps = {
  label: React.ReactNode;
  placeholder: string;
};

export const CompetitionsArticleDescription = ({
  label,
  placeholder
}: TProps) => {
  if (label) {
    return (
      <div className={styles.CompetitionsArticle__Description}>{label}</div>
    );
  }
  return (
    <div className={styles.CompetitionsArticle__DescriptionPlaceholder}>
      {placeholder}
    </div>
  );
};
