import { MoveEmployeeParams } from 'types/services';
import { sportingAPI } from '../api';
import { UserInfo } from 'types/state';
import { normalizeSportingUserInfo } from '../utils/normalizeSportingUserInfo';

export const moveEmployee = async (
  params: MoveEmployeeParams
): Promise<UserInfo[]> => {
  const { userId, refUserId } = params;

  const employees = await sportingAPI.service.changeEmployeeOrder({
    payload: { from: userId, to: refUserId }
  });

  return employees.map(normalizeSportingUserInfo.toUserInfo);
};
