import {
  BookingPlacesFormValues,
  BookingPlacesFormViolation
} from 'types/state';

export function validateBookingPlacesFormValues(
  values: BookingPlacesFormValues
): BookingPlacesFormViolation[] {
  const violations: BookingPlacesFormViolation[] = [];

  for (let place of values.places) {
    if (place.title.trim().length === 0) {
      violations.push({
        name: 'PLACE_NAME_REQUIRED',
        placeId: place.id
      });
    }

    for (let item of place.items) {
      if (item.title.trim().length === 0) {
        violations.push({
          name: 'PLACE_ITEM_NAME_REQUIRED',
          placeId: place.id,
          itemId: item.id
        });
      }

      if (item.minVisitorsCount.trim().length === 0) {
        violations.push({
          name: 'PLACE_ITEM_MIN_VISITORS_COUNT_REQUIRED',
          placeId: place.id,
          itemId: item.id
        });
      }

      if (item.maxVisitorsCount.trim().length === 0) {
        violations.push({
          name: 'PLACE_ITEM_MAX_VISITORS_COUNT_REQUIRED',
          placeId: place.id,
          itemId: item.id
        });
      }
    }
  }

  return violations;
}
