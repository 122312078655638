import { TSetReviewStatusParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { reviewsStoreMock } from 'services/mock/utils/reviewsModel';

export const setReviewStatus = async (params: TSetReviewStatusParams) => {
  const { reviewId, status } = params;

  await asyncTimeout(SERVICES_MOCK_DELAY);

  const review = reviewsStoreMock.getReviewById(reviewId);

  reviewsStoreMock.updateReview({
    ...review,
    status
  });
};
