import React from 'react';

import styles from './BookingReservationModalBodyLayoutStyles.module.scss';

type Props = {
  title: React.ReactNode;
  guestCount: React.ReactNode;
  dateAndTime: React.ReactNode;
  place: React.ReactNode;
  comment: React.ReactNode;
  guest: React.ReactNode;
  client: React.ReactNode;
};

export const BookingReservationModalBodyLayoutComponent = (props: Props) => {
  const {
    title,
    guestCount,
    dateAndTime,
    place,
    comment,
    guest,
    client
  } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleLabel}>{title}</div>
      <div className={styles.dateAndTimePanel}>{dateAndTime}</div>
      <div className={styles.guestCountPanel}>{guestCount}</div>
      <div className={styles.placePanel}>{place}</div>
      <div className={styles.commentPanel}>{comment}</div>
      <div className={styles.guestWithoutReservationPanel}>{guest}</div>
      <div className={styles.clientPanel}>{client}</div>
    </div>
  );
};
