import { TIntl } from 'types/intl';
import { isToday, isYesterday } from 'utils/dateUtils';

export const getProcessedApplicationsGroupDateText = (
  ISODate: string,
  intl: TIntl
) => {
  const {
    shortDayNames,
    predicativeMonthNames,
    applicationsGroupTodayPrefix,
    applicationsGroupYesterdayPrefix
  } = intl;

  const dateCopy = new Date(ISODate);

  const prefix = (() => {
    if (isToday(dateCopy)) {
      return applicationsGroupTodayPrefix;
    }
    if (isYesterday(dateCopy)) {
      return applicationsGroupYesterdayPrefix;
    }
  })();

  const currentYear = new Date().getFullYear();
  const dateYear =
    currentYear > dateCopy.getFullYear() ? dateCopy.getFullYear() : '';

  const dayOfWeek = (() => {
    let day = shortDayNames[dateCopy.getDay()];
    if (day) {
      return day.replace(/^\w/, chr => chr.toUpperCase());
    }
    return '';
  })();

  return `${prefix ? prefix : dayOfWeek}, ${dateCopy.getDate()} ${
    predicativeMonthNames[dateCopy.getMonth()]
  } ${dateYear}`;
};

export const getNewApplicationsGroupDateText = (
  ISODate: string,
  intl: TIntl
) => {
  const { predicativeMonthNames } = intl;

  const dateCopy = new Date(ISODate);

  if (isToday(dateCopy)) {
    return intl.applicationsGroupNewTitle;
  }

  const currentYear = new Date().getFullYear();
  const dateYear =
    currentYear > dateCopy.getFullYear() ? dateCopy.getFullYear() : '';

  return `${intl.applicationsGroupNewTitle}, ${dateCopy.getDate()} ${
    predicativeMonthNames[dateCopy.getMonth()]
  } ${dateYear}`;
};
