import React from 'react';
import styles from './BookingClientData.module.scss';
import { CustomInput } from '../shared/CustomInput';
import { useIntlContext } from '../../hooks/useIntlContext';
import {
  BookingClientDataFormValues,
  BookingReservationFormError
} from '../../types/state';
import { useFindClientService } from '../../hooks/booking/useFindClientService';
import { ClientInfo } from '../../types/client';
import { SearchClientResultPopup } from './components/SearchClientResultPopup';
import { multiplyClassName } from '../../utils/className';
import { digitsOnlyRegExp } from '../../utils/stringUtils';
import { PopupGroupLayoutComponent } from 'design/popupGroupLayout/popupGroupLayoutComponents';

const MIN_TEXT_LENGTH_TO_SEARCH = 3;

type Props = {
  formValues: BookingClientDataFormValues;
  formError: BookingReservationFormError[] | undefined;
  onSelectSearchResultItem(clientInfo: ClientInfo): void;
  onChangeName(value: string): void;
  onChangePhoneNumber(value: string): void;
  onChangeCardNumber(value: string): void;
};
export const BookingClientData = (props: Props) => {
  const { bookingClientDataIntl: msgs } = useIntlContext();

  const {
    formValues,
    formError,
    onSelectSearchResultItem,
    onChangeName,
    onChangePhoneNumber,
    onChangeCardNumber
  } = props;

  const [searchText, setSearchText] = React.useState('');

  const onChangeSearch = React.useCallback((value: string) => {
    setSearchText(value);
  }, []);

  const { findUsers, pullingProcess, reset } = useFindClientService();

  React.useEffect(() => {
    if (searchText.trim().length >= MIN_TEXT_LENGTH_TO_SEARCH) {
      findUsers({
        matcher: searchText
      });
    } else {
      reset();
    }
  }, [searchText, findUsers, reset]);

  const [
    isSearchResultPopupOpened,
    setSearchResultPopupOpened
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (pullingProcess.status === 'RESOLVED') {
      setSearchResultPopupOpened(true);
    } else {
      setSearchResultPopupOpened(false);
    }
  }, [pullingProcess.status]);

  const closeSearchResultPopup = React.useCallback(() => {
    setSearchResultPopupOpened(false);
  }, []);

  const memoizedSearchResult = React.useMemo<ClientInfo[]>((): ClientInfo[] => {
    return pullingProcess.status === 'RESOLVED' ? pullingProcess.value : [];
  }, [pullingProcess]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchBoxPanel}>
        <PopupGroupLayoutComponent
          onOutsideClick={closeSearchResultPopup}
          trigger={
            <CustomInput
              placeholder={msgs.searchInputPlaceholder}
              name={'search'}
              type={'text'}
              value={searchText}
              onChange={e => onChangeSearch(e.target.value)}
            />
          }
          popup={
            isSearchResultPopupOpened && (
              <SearchClientResultPopup
                clientInfoList={memoizedSearchResult}
                onSelectItem={clientInfo => {
                  setSearchResultPopupOpened(false);
                  onSelectSearchResultItem(clientInfo);
                }}
              />
            )
          }
        />
      </div>
      <div className={styles.clientInfoPanel}>
        <div className={styles.namePanel}>
          <div
            className={multiplyClassName([
              styles.label,
              formError &&
              formError.includes('BOOKING_RESERVATION_FORM_USER_NAME_EMPTY')
                ? styles.error
                : ''
            ])}
          >
            {msgs.nameLabel}
          </div>
          <CustomInput
            placeholder={msgs.namePlaceholder}
            name={'client-name'}
            type={'text'}
            value={formValues.fio}
            onChange={e => onChangeName(e.target.value)}
          />
        </div>
        <div className={styles.phoneAndCardPanel}>
          <div className={styles.phonePanel}>
            <div
              className={multiplyClassName([
                styles.label,
                formError &&
                formError.includes('BOOKING_RESERVATION_FORM_USER_PHONE_EMPTY')
                  ? styles.error
                  : ''
              ])}
            >
              {msgs.phoneLabel}
            </div>
            <CustomInput
              placeholder={msgs.phonePlaceholder}
              name={'client-phone-number'}
              type={'text'}
              value={formValues.phoneNumber}
              onChange={e => {
                const value = e.target.value;
                const withoutPlusStr = value.replace('+', '');
                if (
                  withoutPlusStr.length === 0 ||
                  digitsOnlyRegExp.test(withoutPlusStr)
                ) {
                  onChangePhoneNumber(value);
                }
              }}
            />
          </div>
          <div className={styles.cardPanel}>
            <div className={styles.label}>{msgs.cardLabel}</div>
            <CustomInput
              placeholder={msgs.cardPlaceholder}
              name={'client-card-number'}
              type={'text'}
              value={formValues.cardNumber}
              onChange={e => onChangeCardNumber(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
