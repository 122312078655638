import { TSubmitCompetitionsArticleParams } from 'types/services';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';
import { TCompetitionsArticle } from 'types/state';
import { normalizeCompetitionsArticle } from 'services/sporting/utils/normalizeCompetitionsArticle';
import { normalizeSportingCompetitionsArticle } from 'services/sporting/utils/normalizeSportingCompetitionsArticle';
import { sportingAPI } from 'services/sporting/api';

export const submitCompetitionsArticle = async (
  params: TSubmitCompetitionsArticleParams
): Promise<TCompetitionsArticle> => {
  const { data, saveAsDraft } = params;

  const body = normalizeCompetitionsArticle.toSportingFormData(
    data,
    saveAsDraft
  );

  const methodParams = {
    payload: { body }
  };

  let promise;

  if (data.id !== NEWS_ARTICLE_CANDIDATE_ID) {
    promise = sportingAPI.service.updateCompetition(methodParams);
  } else {
    promise = sportingAPI.service.addCompetition(methodParams);
  }

  const sportingArticle = await promise;

  return normalizeSportingCompetitionsArticle.toCompetitionsArticle(
    sportingArticle
  );
};
