import { AppDispatch } from 'types/state';
import { GetChatRoomMessagesParams } from 'types/services';
import { ChatMessage } from 'types/chats';

export const chatRoomMessagesThunk = (
  promise: Promise<ChatMessage[]>,
  meta: GetChatRoomMessagesParams
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'CHAT_ROOM_MESSAGES_SERVICE',
    status: 'PENDING',
    meta
  });

  return promise.then(rooms => {
    dispatch({
      name: 'CHAT_ROOM_MESSAGES_SERVICE',
      status: 'RESOLVED',
      payload: rooms,
      meta
    });
  });
};
