import React from 'react';
import { EN_DASH } from 'utils/constants';

import styles from './dayTimePeriodInputLayoutStyles.module.scss';

type Props = {
  startTimeInput: React.ReactNode;
  endTimeInput: React.ReactNode;
};

export const DayTimePeriodInputLayoutComponent = (props: Props) => {
  const { startTimeInput, endTimeInput } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.startTimeInputSlot}>{startTimeInput}</div>
      <div className={styles.dashSlot}>{EN_DASH}</div>
      <div className={styles.endTimeInputSlot}>{endTimeInput}</div>
    </div>
  );
};
