import React from 'react';
import { useHistory } from 'react-router';

import { authorizedRoutes } from 'utils/authorizedRoutes';
import { useIntlContext } from 'hooks/useIntlContext';
import { UnreachableError } from 'utils/errors';
import { useAppState } from 'hooks/useAppState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Toast } from 'types/toast';

export const useActualToast = () => {
  const { toastCandidates } = useAppState();

  const intl = useIntlContext();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const nextToastCandidate = toastCandidates[0];

  const actualToast = React.useMemo<null | Toast>(() => {
    if (nextToastCandidate) {
      const dispatchClickEvent = () => {
        dispatch({
          name: 'TOAST__CLICK',
          candidate: nextToastCandidate
        });
      };

      const onClose = () => {
        dispatch({
          name: 'TOAST__CLOSE'
        });
      };

      switch (nextToastCandidate.name) {
        case 'RESTAURANT_NEW_APPLICATIONS':
          return {
            title: intl.toastNewRestaurantApplicaitionTitle,
            description: intl.toastNewRestaurantApplicaitionBody,
            onClick() {
              dispatchClickEvent();

              history.push(authorizedRoutes.applicationsRestaurant());
            },
            onClose
          };
        case 'SPORT_NEW_APPLICATIONS':
          return {
            title: intl.toastNewSportApplicaitionTitle,
            description: intl.toastNewSportApplicaitionBody,
            onClick() {
              dispatchClickEvent();

              history.push(authorizedRoutes.applicationsSport());
            },
            onClose
          };
        case 'RESTAURANT_NEW_CHAT_MESSAGE':
          return {
            title: intl.toastNewRestaurantChatMessageTitle,
            description: intl.toastNewRestaurantChatMessageBody,
            onClick() {
              dispatchClickEvent();

              history.push(authorizedRoutes.chatsRestaurant());
            },
            onClose
          };
        case 'SPORT_NEW_CHAT_MESSAGE':
          return {
            title: intl.toastNewSportChatMessageTitle,
            description: intl.toastNewSportChatMessageBody,
            onClick() {
              dispatchClickEvent();

              history.push(authorizedRoutes.chatsSport());
            },
            onClose
          };
        default:
          throw new UnreachableError(nextToastCandidate);
      }
    }

    return null;
  }, [
    nextToastCandidate,
    dispatch,
    intl.toastNewRestaurantApplicaitionTitle,
    intl.toastNewRestaurantApplicaitionBody,
    intl.toastNewSportApplicaitionTitle,
    intl.toastNewSportApplicaitionBody,
    intl.toastNewSportChatMessageTitle,
    intl.toastNewSportChatMessageBody,
    intl.toastNewRestaurantChatMessageTitle,
    intl.toastNewRestaurantChatMessageBody,
    history
  ]);

  return actualToast;
};
