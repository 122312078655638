import React from 'react';
import { Redirect } from 'react-router';

import { NewsFeedCreateButton } from 'components/NewsFeed/NewsFeedCreateButton';
import { NewsFeedHeader } from 'components/NewsFeed/NewsFeedHeader';
import { CustomSelect } from 'containers/CustomSelect';
import { TNewsFeedFilterOption, TNewsFilter } from 'types/state';

type Props = {
  activeFilter: TNewsFilter;
  filterOptions: TNewsFeedFilterOption[];
  isFilterDisabled: boolean;
  redirectPath: null | string;
  hasCreateArticleButton: boolean;
  handleCreateArticle(): void;
  handleFilterChange(nextFilter: TNewsFilter): void;
};

export const NewsFeedControlsView = (props: Props) => {
  const {
    activeFilter,
    redirectPath,
    filterOptions,
    isFilterDisabled,
    handleCreateArticle,
    handleFilterChange,
    hasCreateArticleButton
  } = props;

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <NewsFeedHeader>
      <CustomSelect
        options={filterOptions}
        selected={activeFilter}
        disabled={isFilterDisabled}
        onChange={handleFilterChange}
      />
      {hasCreateArticleButton && (
        <NewsFeedCreateButton onClick={handleCreateArticle} />
      )}
    </NewsFeedHeader>
  );
};
