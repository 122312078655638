import React from 'react';

import styles from './ApplicationsTable.module.scss';
import { Checkbox } from 'components/shared/Checkbox';

type TProps = {
  checked: boolean;
  onChange(): void;
};

export const ApplicationsTableCheckbox: React.FC<TProps> = ({
  checked,
  onChange
}) => (
  <label className={styles.ApplicationsTable__CheckboxLabel}>
    <Checkbox name="done" checked={checked} onChange={onChange} />
  </label>
);
