import { UserEditorState } from 'types/state';
import { useUsersEditorState } from 'hooks/useUsersEditorState';
import { createUserEditorState } from 'utils/createUserEditorState';

export const useUserEditorState = (id: number): null | UserEditorState => {
  const { userEditorStatesByUserId } = useUsersEditorState();

  return userEditorStatesByUserId.get(id) || null;
};

export const useUserEditorStateStrict = (id: number): UserEditorState => {
  const userEditorState = useUserEditorState(id);

  if (!userEditorState) {
    throw new TypeError();
  }

  return userEditorState;
};

export const useUserEditorStateOrFallback = (id: number): UserEditorState => {
  return useUserEditorState(id) || createUserEditorState();
};
