import { PersonalDataFormValues } from 'types/state';

export const patchPersonalDataFormValues = (
  values: PersonalDataFormValues
) => ({
  done() {
    return values;
  },
  setLastName(nextValue: string) {
    return patchPersonalDataFormValues({
      ...values,
      lastName: nextValue
    });
  },
  setFirstName(nextValue: string) {
    return patchPersonalDataFormValues({
      ...values,
      firstName: nextValue
    });
  },
  setPhoneNumber(nextValue: string) {
    return patchPersonalDataFormValues({
      ...values,
      phoneNumber: nextValue
    });
  }
});
