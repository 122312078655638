import { MoveMessageTemplateParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const moveMessageTemplate = async (
  params: MoveMessageTemplateParams
) => {
  const { templateId, newPosition } = params;

  await sportingAPI.chat.changeDraftOrderNumber({
    payload: { idDraft: templateId, newOrderNumber: newPosition }
  });
};
