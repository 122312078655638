import { TNewsAudienceGroup, TNewsArticle } from 'types/state';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';
import { normalizeAudienceGroup } from 'services/sporting/utils/normalizeAudienceGroup';

const appendDestination = (body: FormData, audience: TNewsAudienceGroup[]) => {
  audience.forEach(audienceGroup => {
    body.append(
      'destination',
      `${normalizeAudienceGroup.toSportingPersonType(audienceGroup)}`
    );
  });
};

export const normalizeNewsArticle = {
  toSportingFormData(
    data: TNewsArticle,
    saveAsDraft: boolean,
    sendNotification: boolean
  ) {
    const formData = new FormData();

    formData.append('title', data.title);
    formData.append('body', data.description);
    if (data.image) {
      if (data.image.original instanceof File) {
        formData.append('fullFile', data.image.original);
      }
      if (data.image.cropped instanceof File) {
        formData.append('processedFile', data.image.cropped);
      }
    } else {
      formData.append('fullFile', 'null');
      formData.append('processedFile', 'null');
    }
    formData.append('sendNotification', `${sendNotification}`);
    formData.append('draft', `${saveAsDraft}`);

    // После KLUB-429 принимается только значение CLUB, по идее поле не нужно
    formData.append('newsCategory', 'CLUB');

    appendDestination(formData, data.audience);

    if (data.id !== NEWS_ARTICLE_CANDIDATE_ID) {
      formData.append('idNewsItem', `${data.id}`);
    }

    return formData;
  }
};
