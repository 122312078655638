import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { processedRestaurantApplicationsThunk } from 'thunks/processedRestaurantApplicationsThunk';
import { processedSportApplicationsThunk } from 'thunks/processedSportApplicationsThunk';
import { useProcessedApplicationsListsInvalidatedPage } from 'hooks/useApplicationsListsInvalidatedPages';
import { useApplicationsPageDomain } from 'hooks/useApplicationsPageDomain';
import { useSafeAsyncWrapper } from './useSafeAsyncWrapper';
import { ServiceScope } from 'types/serviceScope';

export const useProcessedApplicationsService = (pageType: ServiceScope) => {
  const dispatch = useAppDispatch();

  const {
    getRestaurantProcessedApplications,
    getSportProcessedApplications
  } = useServices();

  const domain = useApplicationsPageDomain();

  const invalidatedPage = useProcessedApplicationsListsInvalidatedPage(domain);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    if (pageType === 'RESTAURANT' && invalidatedPage) {
      const promise = processedRestaurantApplicationsThunk(
        getRestaurantProcessedApplications({
          pageNumber: invalidatedPage
        }),
        invalidatedPage
      )(dispatch);

      wrapAsyncSafely(promise);
    }
    if (pageType === 'CLUB' && invalidatedPage) {
      const promise = processedSportApplicationsThunk(
        getSportProcessedApplications({
          pageNumber: invalidatedPage
        }),
        invalidatedPage
      )(dispatch);

      wrapAsyncSafely(promise);
    }
  }, [
    dispatch,
    getRestaurantProcessedApplications,
    getSportProcessedApplications,
    invalidatedPage,
    pageType,
    wrapAsyncSafely
  ]);
};
