type ValueType = string | number | boolean | undefined | File;

export const appendFormDataWithObjectValues = (
  formData: FormData,
  object: { [key: string]: ValueType | ValueType[] }
): void => {
  const appendValue = (field: string, value: ValueType) => {
    if (value instanceof File) {
      formData.append(field, value, value.name);
    } else if (typeof value === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.append(field, value);
    } else {
      formData.append(field, `${value}`);
    }
  };
  for (const field in object) {
    const value = object[field];

    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach(value => {
          appendValue(field, value);
        });
      } else {
        appendValue(field, value);
      }
    }
  }
};
