import { TNewsFilter, TCompetitionsFilter } from 'types/state';
import {
  NEWS_FEED_DEFAULT_FILTER,
  COMPETITIONS_FEED_DEFAULT_FILTER
} from 'utils/constants';

export const authorizedRoutes = {
  applicationsRestaurantPattern() {
    return '/restaurant/calls';
  },
  applicationsRestaurant() {
    return '/restaurant/calls';
  },

  chatsRestaurantPattern() {
    return '/restaurant/chats';
  },
  chatsRestaurant() {
    return '/restaurant/chats';
  },

  applicationsSportPattern() {
    return '/sport/calls';
  },
  applicationsSport() {
    return '/sport/calls';
  },

  chatsSportPattern() {
    return '/sport/chats';
  },
  chatsSport() {
    return '/sport/chats';
  },

  newsRoot() {
    return '/news';
  },
  newsPattern() {
    return '/news/:filter';
  },
  news(filter: TNewsFilter = NEWS_FEED_DEFAULT_FILTER) {
    return `/news/${filter}`;
  },

  competitionsRoot() {
    return '/competitions';
  },
  competitionsPattern() {
    return '/competitions/:filter';
  },
  competitions(filter: TCompetitionsFilter = COMPETITIONS_FEED_DEFAULT_FILTER) {
    return `/competitions/${filter}`;
  },

  membersPattern() {
    return '/members';
  },

  members() {
    return '/members';
  },

  users() {
    return '/users';
  },

  usersPattern() {
    return '/users';
  },

  bookingRestaurant() {
    return '/booking/restaurant';
  },

  bookingClub() {
    return '/booking/club';
  },

  feedbackPattern() {
    return '/feedback';
  },

  feedback() {
    return '/feedback';
  },

  settingsPattern() {
    return '/settings';
  },

  settings() {
    return '/settings';
  },

  noActiveSections() {
    return '/noActiveSections';
  }
};
