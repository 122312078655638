import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { SportingData, SportingSecureParams } from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';
import { appendFormDataWithObjectValues } from 'utils/appendFormDataWithObjectValues';

export const setSportingData = async (
  params: SportingSecureParams<SportingData>
): Promise<SportingData> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL('setSportingData');

  const headers = createSportingHeaders();

  const body = new FormData();

  appendFormDataWithObjectValues(body, payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body,
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  return response.json();
};
