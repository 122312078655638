import { useUserInfo } from 'hooks/useUserInfoCache';

export const useUserRole = () => {
  const userInfo = useUserInfo();

  return userInfo?.role;
};

export const useUserRoleStrict = () => {
  const role = useUserRole();

  if (!role) {
    throw new TypeError();
  }

  return role;
};
