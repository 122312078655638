import { ServiceScope } from 'types/serviceScope';
import {
  BookingPlaceFormValues,
  BookingPlaceItemFormValues,
  BookingPlacesEditorState,
  BookingPlacesFormValues
} from '../../types/state';
import { createUniqueId } from '../createUniqueId';

export const createBookingPlacesEditorState = (
  formValues: BookingPlacesFormValues | null,
  location: ServiceScope
): BookingPlacesEditorState => ({
  formValues: formValues ? formValues : createBookingPlacesFormValues(location),
  submited: false,
  submitDidSucceed: false
});

export const createBookingPlacesFormValues = (
  location: ServiceScope
): BookingPlacesFormValues => ({
  initialized: false,
  location,
  places: []
});

export const createNewBookingPlaceFormValues = (
  orderNumber?: number
): BookingPlaceFormValues => ({
  dataAction: 'CREATE_NEW',
  id: createUniqueId(),
  title: '',
  orderNumber: orderNumber ? orderNumber : 0,
  items: []
});

export const createNewPlaceItemFormValues = (
  orderNumber?: number
): BookingPlaceItemFormValues => ({
  dataAction: 'CREATE_NEW',
  id: createUniqueId(),
  title: '',
  minVisitorsCount: '',
  maxVisitorsCount: '',
  orderNumber: orderNumber ? orderNumber : 0
});
