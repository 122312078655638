import { ListCache } from 'types/util';

export const createListCache = <ItemType>(): ListCache<ItemType> => ({
  byPage: new Map<number, ItemType[]>(),
  invalidatedPages: new Set<number>()
});

export const modifyListCache = <ItemType>(list: ListCache<ItemType>) => ({
  return() {
    return list;
  },
  putItems(page: number, items: ItemType[]) {
    const nextByPage = new Map(list.byPage);

    nextByPage.set(page, items);

    const nextInvalidatedPages = new Set(list.invalidatedPages);

    nextInvalidatedPages.delete(page);

    return modifyListCache({
      ...list,
      byPage: nextByPage,
      invalidatedPages: nextInvalidatedPages
    });
  },
  invalidatePage(page: number) {
    const nextInvalidatedPages = new Set(list.invalidatedPages);

    nextInvalidatedPages.add(page);

    return modifyListCache({
      ...list,
      invalidatedPages: nextInvalidatedPages
    });
  },
  invalidateNextPage() {
    const nextInvalidatedPages = new Set(list.invalidatedPages);

    const lastPage = Array.from(list.byPage.keys()).pop();

    const nextPage = lastPage !== undefined ? lastPage + 1 : 1;

    nextInvalidatedPages.add(nextPage);

    return modifyListCache({
      ...list,
      invalidatedPages: nextInvalidatedPages
    });
  },
  invalidateAllPages() {
    const nextInvalidatedPages = new Set<number>();

    const pageNumbers = Array.from(list.byPage.keys());

    pageNumbers.forEach((pageNumber: number) => {
      nextInvalidatedPages.add(pageNumber);
    });

    return modifyListCache({
      ...list,
      invalidatedPages: nextInvalidatedPages
    });
  }
});

export const createListCacheSelectors = <ItemType>(
  list: ListCache<ItemType>
) => ({
  selectPageItems(pageNumber: number): ItemType[] | null {
    const items = list.byPage.get(pageNumber);

    return items || null;
  },
  selectPageItemsStrict(pageNumber: number): ItemType[] {
    const items = list.byPage.get(pageNumber);

    if (items) {
      return items;
    }

    throw new TypeError();
  },
  selectAllItems(): ItemType[] {
    const allItems: ItemType[] = [];

    list.byPage.forEach(items => {
      allItems.push(...items);
    });

    return allItems;
  },
  selectAllPages(): number[] {
    return Array.from(list.byPage.keys());
  },
  selectInvalidatedPages(): number[] {
    return Array.from(list.invalidatedPages.values());
  },
  selectNextInvalidatedPage(): null | number {
    const nextInvalidatedPage = list.invalidatedPages.values().next();

    if (nextInvalidatedPage.done) {
      return null;
    }

    return nextInvalidatedPage.value;
  },
  hasEmptyPage(): boolean {
    return Array.from(list.byPage.values()).some(items => items.length === 0);
  }
});
