import { TSetCompetitionParticipantStatusParams } from 'types/services';
import { normalizeCompetitionParticipantStatus } from 'services/sporting/utils/normalizeCompetitionParticipantStatus';
import { sportingAPI } from 'services/sporting/api';

export const setCompetitionParticipantStatus = async (
  params: TSetCompetitionParticipantStatusParams
): Promise<void> => {
  const { participantId, nextStatus } = params;

  const idStatus = normalizeCompetitionParticipantStatus.toSportingParticipantStatusId(
    nextStatus
  );

  await sportingAPI.service.changeParticipationStatus({
    payload: { idParticipation: participantId, idStatus }
  });
};
