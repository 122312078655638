import React from 'react';

import { useCompetitionDurationLabel } from 'hooks/useCompetitionDurationLabel';
import {
  COMPETITIONS_ARTICLE_CANDIDATE_ID,
  COMPETITIONS_REQUESTS_COUNT_UPDATE_INTERVAL
} from 'utils/constants';
import { useCompetitionRequestsCountService } from 'hooks/useCompetitionRequestsCountService';
import { TCompetitionsArticle } from 'types/state';
import { setCustomInterval } from 'utils/setCustomInterval';
import { useIntlContext } from 'hooks/useIntlContext';
import { useCompetitionRequestsCount } from 'hooks/useCompetitionRequestsCount';

type TProps = {
  data: TCompetitionsArticle;
  hasSelectedView: boolean;
  onClick(id: number): void;
};

export const useCompetitionsFeedItemState = ({
  data,
  hasSelectedView,
  onClick
}: TProps) => {
  // Достает состояние счетчиков по заявкам.

  const requestsCount = useCompetitionRequestsCount(data.id) || undefined;

  // Метод обновления количества заявок на участие / отмену
  // участия для конкретного соревнования.
  const updateCountById = useCompetitionRequestsCountService();

  // Эффект обновляет количество заявок на участие / отмену участия
  // для текущего соревнования и создает интервал, который периодически
  // повторят это действие.
  React.useEffect(() => {
    if (data.id !== COMPETITIONS_ARTICLE_CANDIDATE_ID) {
      updateCountById(data.id);

      return setCustomInterval(() => {
        updateCountById(data.id);
      }, COMPETITIONS_REQUESTS_COUNT_UPDATE_INTERVAL);
    }
  }, [data.id, updateCountById]);

  // Обработчик события клика по соревнованию.
  const handleClick = React.useCallback(() => {
    onClick(data.id);
  }, [data.id, onClick]);

  const title = data.title;

  const durationLabel = useCompetitionDurationLabel(data.duration);

  const {
    competitionsFeedItemDraftPretitle,
    competitionsFeedItemTitlePlaceholder
  } = useIntlContext();

  const pretitle = data.isDraft
    ? competitionsFeedItemDraftPretitle
    : durationLabel;

  return {
    competitionsFeedItemTitlePlaceholder,
    hasSelectedView,
    title,
    pretitle,
    requestsCount,
    handleClick
  };
};
