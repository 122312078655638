import React from 'react';

import { TNewsArticle } from 'types/state';
import { getLocalizedNewsFeedItemPublishDate } from 'utils/getLocalizedNewsFeedItemPublishDate';
import { normalizeNewsArticleToFeedItem } from 'utils/normalizeNewsArticleToFeedItem';
import { NewsFeedItemTemplate } from 'containers/NewsFeedItem/styled/NewsFeedItemTemplate';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  data: TNewsArticle;
  hasSelectedView: boolean;
  onClick(id: number): void;
};

export const NewsFeedItem = ({ data, hasSelectedView, onClick }: TProps) => {
  const feedItem = React.useMemo(() => normalizeNewsArticleToFeedItem(data), [
    data
  ]);

  const { id, imageSrc, title, publishDate } = feedItem;

  const intl = useIntlContext();

  const handleClick = React.useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const subtitle = React.useMemo(() => {
    if (publishDate) {
      return getLocalizedNewsFeedItemPublishDate(publishDate, intl);
    }
    return intl.newsFeedItemDraftSubtitle;
  }, [publishDate, intl]);

  const itemNode = React.useMemo(
    () => (
      <NewsFeedItemTemplate
        imageSrc={imageSrc}
        title={title}
        titlePlaceholder={intl.newsFeedItemTitlePlaceholder}
        subtitle={subtitle}
        hasSelectedView={hasSelectedView}
        onClick={handleClick}
      />
    ),
    [
      subtitle,
      hasSelectedView,
      handleClick,
      imageSrc,
      intl.newsFeedItemTitlePlaceholder,
      title
    ]
  );

  return itemNode;
};
