import { useRouteMatch } from 'react-router';

import { UnexpectedError } from 'utils/errors';

export const useRouteMatchStrict = () => {
  const match = useRouteMatch();

  if (match) {
    return match;
  }

  throw new UnexpectedError();
};
