import { AdministratorInfo } from 'types/state';
import { createAdministratorInfoMock } from 'utils/createAdministratorInfoMock';

const mockData = [
  createAdministratorInfoMock(),
  createAdministratorInfoMock(),
  createAdministratorInfoMock()
];

export const getAdministratorInfos = async (): Promise<AdministratorInfo[]> => {
  return mockData.map(item => ({ ...item }));
};
