import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useServices } from 'hooks/useServices';
import { useAsyncController } from '../useAsyncController';
import { AuthorizationError } from 'utils/errors';
import { useUserToBlockedState } from './useUserToBlockedState';
import { SetUserBlockedStateParams } from 'types/services';

export const useUserToBlockedStateService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const userToBlockedState = useUserToBlockedState();

  const { wrapAsync: wrapBlocked } = useAsyncController();

  const setUserBlockedState = React.useCallback(
    (params: SetUserBlockedStateParams) => {
      appDispatch({
        name: 'USER_TO_BLOCKED_SERVICE',
        status: 'PENDING',
        meta: params
      });

      const promise = services
        .setUserBlockedState(params)
        .then(() => {
          appDispatch({
            name: 'USER_TO_BLOCKED_SERVICE',
            status: 'RESOLVED',
            payload: undefined,
            meta: params
          });
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }

          appDispatch({
            name: 'USER_TO_BLOCKED_SERVICE',
            status: 'REJECTED',
            payload: error,
            meta: params
          });
        });

      wrapBlocked(promise);
    },
    [appDispatch, services, wrapBlocked]
  );

  React.useEffect(() => {
    const formValues = userToBlockedState.formValues;
    if (formValues && userToBlockedState.submited) {
      setUserBlockedState({
        userId: formValues.userId,
        toBlocked: formValues.toBlocked
      });
    }
  }, [
    userToBlockedState.submited,
    setUserBlockedState,
    userToBlockedState.formValues
  ]);
};
