import React from 'react';

import { TCompetitionDuration } from 'types/state';
import { useIntlContext } from 'hooks/useIntlContext';

export const useCompetitionDurationLabel = (
  value: null | TCompetitionDuration
) => {
  const {
    predicativeMonthNames,
    competitionEndDatePlaceholder,
    competitionStartDatePlaceholder
  } = useIntlContext();

  const durationLabel = React.useMemo(() => {
    if (value) {
      const { startDate: startDateISO, endDate: endDateISO } = value;
      const startDate = new Date(startDateISO);
      const endDate = new Date(endDateISO);

      const startDateNumber = startDate.getDate();
      const endDateNumber = endDate.getDate();

      const startDateMonthName = predicativeMonthNames[startDate.getMonth()];
      const endDateMonthName = predicativeMonthNames[endDate.getMonth()];

      const startDateYear = startDate.getFullYear();
      const endDateYear = endDate.getFullYear();

      if (startDateYear === endDateYear) {
        if (startDateMonthName === endDateMonthName) {
          if (startDateNumber === endDateNumber) {
            return `${startDateNumber} ${startDateMonthName} ${startDateYear}`;
          }
          return `${startDateNumber}-${endDateNumber} ${startDateMonthName} ${startDateYear}`;
        }
        return `${startDateNumber} ${startDateMonthName} - ${endDateNumber} ${endDateMonthName} ${startDateYear}`;
      }
      return `${startDateNumber} ${startDateMonthName} ${startDateYear} - ${endDateNumber} ${endDateMonthName} ${endDateYear}`;
    } else {
      return `${competitionStartDatePlaceholder} - ${competitionEndDatePlaceholder}`;
    }
  }, [
    value,
    predicativeMonthNames,
    competitionEndDatePlaceholder,
    competitionStartDatePlaceholder
  ]);

  return durationLabel;
};
