import React from 'react';

import { useAppState } from 'hooks/useAppState';
import { useIntlContext } from 'hooks/useIntlContext';
import { MIN_SMS_COUNT_TO_NOT_SHOW_NOTIFICATION } from 'utils/constants';
import { CriticalNotificationName, CriticalNotification } from 'types/state';

export const useCriticalNotification = () => {
  const { onlineFlag, remainingSMSCount } = useAppState();

  const intl = useIntlContext();

  const [notificationNames, setNotificationNames] = React.useState<
    CriticalNotificationName[]
  >([]);

  const pushNotificationName = React.useCallback(
    (name: CriticalNotificationName) => {
      setNotificationNames(prev => {
        if (prev.includes(name)) {
          return prev;
        }

        return [...prev, name];
      });
    },
    []
  );

  const removeNotificationName = React.useCallback(
    (name: CriticalNotificationName) => {
      setNotificationNames(prev => {
        if (prev.includes(name)) {
          return prev.filter(item => item !== name);
        }

        return prev;
      });
    },
    []
  );

  const close = React.useCallback(() => {
    setNotificationNames(prev => prev.filter((item, index) => index !== 0));
  }, []);

  const nextName = notificationNames.find((name, index) => index === 0);

  const notification = React.useMemo<null | CriticalNotification>(() => {
    if (nextName) {
      switch (nextName) {
        case 'SMS_LIMIT_WARNING':
          return {
            message: intl.smsCountWarning,
            blocker: false
          };
        case 'CONNECTION_PROBLEMS':
          return {
            message: intl.noInternetConnectionError,
            blocker: true
          };
        default:
          return null;
      }
    }

    return null;
  }, [intl.noInternetConnectionError, intl.smsCountWarning, nextName]);

  React.useEffect(() => {
    if (!onlineFlag) {
      pushNotificationName('CONNECTION_PROBLEMS');
    } else {
      removeNotificationName('CONNECTION_PROBLEMS');
    }
  }, [
    intl.noInternetConnectionError,
    onlineFlag,
    pushNotificationName,
    removeNotificationName
  ]);

  React.useEffect(() => {
    if (
      remainingSMSCount !== null &&
      remainingSMSCount <= MIN_SMS_COUNT_TO_NOT_SHOW_NOTIFICATION
    ) {
      pushNotificationName('SMS_LIMIT_WARNING');
    }
  }, [intl.smsCountWarning, pushNotificationName, remainingSMSCount]);

  return { notification, close };
};
