import React from 'react';

import { ClipboardDataWrapper } from 'components/ClipboardData/ClipboardDataWrapper';
import { ClipboardDataPopup } from 'components/ClipboardData/ClipboardDataPopup';
import { SECOND } from 'utils/constants';
import { ClipboardDataInput } from 'components/ClipboardData/ClipboardDataInput';
import { ClipboardDataText } from 'components/ClipboardData/ClipboardDataText';
import { setCustomTimeout } from 'utils/setCustomTimeout';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  clipboardValue: string;
  visibleValue: string | React.ReactNode;
  className?: string;
};

const POPUP_VISIBILITY_TIMEOT = SECOND * 2;

export const ClipboardData = (props: TProps) => {
  const { clipboardValue, visibleValue, className } = props;

  const [hasPopup, setHasPopup] = React.useState(false);

  const { copyToClipboartDidSucceed: popupText } = useIntlContext();

  const handleClick = React.useCallback(() => {
    setHasPopup(true);
  }, []);

  React.useEffect(() => {
    if (hasPopup) {
      return setCustomTimeout(() => {
        setHasPopup(false);
      }, POPUP_VISIBILITY_TIMEOT);
    }
  }, [hasPopup, setHasPopup]);

  return (
    <ClipboardDataWrapper className={className}>
      <ClipboardDataInput onCopied={handleClick} value={clipboardValue} />
      <ClipboardDataText value={visibleValue} />
      {hasPopup && <ClipboardDataPopup text={popupText} />}
    </ClipboardDataWrapper>
  );
};
