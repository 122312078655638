import React from 'react';

import styles from './ArticleEditorFooter.module.css';

import { Checkbox } from 'components/shared/Checkbox';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  isChecked: boolean;
  testId?: string;
  onChange(nextValue: boolean): void;
};

export const ArticleEditorFooterNotifyCheckbox = (props: TProps) => {
  const { isChecked, onChange, testId } = props;

  const { articleEditorFooterNotifyCheckboxLabel: label } = useIntlContext();

  const handleChange = () => {
    onChange(!isChecked);
  };

  return (
    <label className={styles.ArticleEditorFooter__NotifyLabel}>
      <Checkbox
        testId={testId}
        name="notify"
        checked={isChecked}
        onChange={handleChange}
      />
      <span className={styles.ArticleEditorFooter__NotifyText}>{label}</span>
    </label>
  );
};
