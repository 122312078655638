import React from 'react';

import styles from './BookingReservationModalFooterLayoutStyles.module.scss';

import { ModalFooter } from '../Modal/ModalFooter';

type Props = {
  waitersSelectControl: React.ReactNode;
  editWaitersControl: React.ReactNode;
  deleteControl: React.ReactNode;
  cancelControl: React.ReactNode;
  submitControl: React.ReactNode;
};

export const BookingReservationModalFooterLayoutComponent = (props: Props) => {
  const {
    waitersSelectControl,
    editWaitersControl,
    deleteControl,
    cancelControl,
    submitControl
  } = props;

  return (
    <ModalFooter additionalClassName={styles.wrapper}>
      <div className={styles.secondaryControls}>
        <div className={styles.waitersSelectControlSlot}>
          {waitersSelectControl}
        </div>
        <div className={styles.editWaitersControlSlot}>
          {editWaitersControl}
        </div>
      </div>
      <div className={styles.primaryControls}>
        <div className={styles.deleteControlSlot}>{deleteControl}</div>
        <div className={styles.cancelControlSlot}>{cancelControl}</div>
        <div className={styles.submitControlSlot}>{submitControl}</div>
      </div>
    </ModalFooter>
  );
};
