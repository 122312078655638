import React from 'react';

import {
  TCompetitionsPreviewTab,
  TCompetitionsPageScreenMap
} from 'types/state';
import {
  COMPETITIONS_PREVIEW_TAB_DEFAULT,
  COMPETITIONS_ARTICLE_CANDIDATE_ID
} from 'utils/constants';
import { useIntlContext } from 'hooks/useIntlContext';
import { useBrowserTabTitleUpdater } from 'hooks/useBrowserTabTitleUpdater';
import { CompetitionsPageView } from 'pages/CompetitionsPage/CompetitionsPageView';
import { useCompetitionsPage } from 'pages/CompetitionsPage/hooks/useCompetitionsPage';
import { useCompetitionsPageFilter } from 'hooks/useCompetitionsPageFilter';

export const CompetitionsPage = () => {
  // Подключается активный фильтр.
  const activeFilter = useCompetitionsPageFilter();

  // Нормализация состояния
  const {
    competitionsPageEmpty: feedFallbackText,
    competitionsPageCreateItem: previewFallbackText
  } = useIntlContext();

  // Обноваляет заоголовок таба браузера.
  useBrowserTabTitleUpdater();

  // Создает состояние выбранного таба в превью соревнования
  const [activeTab, setActiveTab] = React.useState<TCompetitionsPreviewTab>(
    COMPETITIONS_PREVIEW_TAB_DEFAULT
  );

  const {
    editedArticle,
    patchEditedArticle,
    setEditedArticle,
    selectedArticle,
    selectArticleById,
    feedItems,
    loadFeed,
    reloadFeed,
    loading
  } = useCompetitionsPage(activeFilter);

  // Эффект вызывает первоначальную загрузку данных.
  React.useEffect(() => {
    reloadFeed();
  }, [reloadFeed]);

  // Обработчк успешного удаления новости.
  // Полностью перезагружает страницу.
  const handleArticleDeleted = reloadFeed;

  // Обработчк успешной публикации новости.
  // Если новость была сохранена в качестве черновика,
  // но пользователь находится на странице опубликованных
  // производится редирект на страницу со всеми новостями.
  // Если новость была опубликована, но пользователь
  // находится на странице черновиков, производится редирект
  // на страницу со всеми новостями.
  // Иначе обновляет ленту
  const handleArticleSubmited = reloadFeed;

  // Функция-обаботчик клика по элементу ленты новостей.
  // Устанавливает id новости-кандидата на id новости, по
  // которой был произведен клик.
  const handleFeedItemClick = selectArticleById;

  // Обаботчик намерения создать новость.
  // Заполняет состояние новости-кандидата шаблоном новости
  // и устанавливает значение id новости-кандидата.
  const handleCreateArticle = React.useCallback(() => {
    setActiveTab(COMPETITIONS_PREVIEW_TAB_DEFAULT);
    selectArticleById(COMPETITIONS_ARTICLE_CANDIDATE_ID);
  }, [selectArticleById]);

  // Обработчик изменений новости-кандидата в ходе редактирования.
  const handleArticleChange = patchEditedArticle;

  // Обработчик намерения отменить редактирование новости.
  const handleCancelEditArticle = React.useCallback(() => {
    if (
      editedArticle &&
      editedArticle.id === COMPETITIONS_ARTICLE_CANDIDATE_ID
    ) {
      selectArticleById(null);
    } else {
      setEditedArticle(null);
    }
  }, [editedArticle, setEditedArticle, selectArticleById]);

  // Обработчик намерения отредактировать статью.
  // При наличии выбранной статьи устанавливает ее в качестве
  // статьи-кандидата.
  const handleEditArticle = React.useCallback(() => {
    setEditedArticle(selectedArticle);
  }, [setEditedArticle, selectedArticle]);

  // Обработчик намерения загрузить следующую страницу
  // новостей.
  const handleLoadMore = React.useCallback(() => {
    loadFeed();
  }, [loadFeed]);

  // При редактировании статьи вместо выбранной статьи
  // подставляется объект редактируемой статьи.
  const article = editedArticle || selectedArticle;

  // Id актульной статьи, если такая есть.
  const articleId = article && article.id;

  // Обработчик нажатия на вкладки соревнования.
  const handlePreviewTabClick = setActiveTab;

  // Объект определяет актуальное состояние экрана.
  const screenMap = React.useMemo<TCompetitionsPageScreenMap>(() => {
    return {
      NO_CONTENT_SCEEN: loading === false && feedItems.length === 0,
      CONTENT_PRELOADING_SCREEN:
        (loading === null || loading === true) && feedItems.length === 0,
      CONTENT_LOADING_SCREEN: loading === true && feedItems.length > 0,
      CONTENT_PREVIEW_SCREEN:
        loading === false && feedItems.length > 0 && !editedArticle,
      CONTENT_EDITING_SCREEN: !!editedArticle
    };
  }, [feedItems, loading, editedArticle]);

  const viewProps = {
    screenMap,
    feedFallbackText,
    previewFallbackText,
    article,
    articleId,
    activeFilter,
    activeTab,
    feedItems,
    handlePreviewTabClick,
    handleLoadMore,
    handleFeedItemClick,
    handleCreateArticle,
    handleArticleChange,
    handleArticleDeleted,
    handleArticleSubmited,
    handleEditArticle,
    handleCancelEditArticle
  };

  return <CompetitionsPageView {...viewProps} />;
};
