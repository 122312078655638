import React from 'react';

import { useBrowserTabTitleUpdater } from 'hooks/useBrowserTabTitleUpdater';
import { MembersPageLayout } from '../../design/membersPage/membersPage';
import { TextInputControl } from 'design/textInputControl';
import { useIntlContext } from 'hooks/useIntlContext';
import { MessageBox } from 'design/messageBox';
import { MembersPageContentLayout } from 'design/membersPageContent/membersPageContent';
import { Text } from 'design/text';
import { useMembersPageDataService } from 'hooks/useMembersPageDataService';
import { MembersTableItem } from 'containers/MembersTableItem';
import { digitsOnlyRegExp } from 'utils/stringUtils';
import { useMediaFlags } from 'hooks/useMediaFlags';

const MIN_CARD_NUMBER_LENGTH_TO_SEARCH = 4;

export const MembersPage = () => {
  useBrowserTabTitleUpdater();

  const [cardNumber, setCardNumber] = React.useState('');

  const handleCardNumberInputValueChange = React.useCallback(
    (nextValue: string) => {
      if (!nextValue || digitsOnlyRegExp.test(nextValue)) {
        setCardNumber(nextValue);
      }
    },
    []
  );

  const { isTablet, isMobile } = useMediaFlags();

  const isDevice = isTablet || isMobile;

  const { membersPage: ownDictionary } = useIntlContext();

  const { pull, pullingProcess, reset } = useMembersPageDataService();

  React.useEffect(() => {
    if (cardNumber.trim().length >= MIN_CARD_NUMBER_LENGTH_TO_SEARCH) {
      pull(cardNumber);
    } else {
      reset();
    }
  }, [cardNumber, pull, reset]);

  const handleVerificationStatusChanged = React.useCallback(() => {
    pull(cardNumber);
  }, [cardNumber, pull]);

  const hasFallbackLabel =
    pullingProcess.status === 'RESOLVED' &&
    pullingProcess.value.clientInfos.length === 0;

  const hasLabel =
    pullingProcess.status === 'RESOLVED' &&
    pullingProcess.value.clientInfos.length > 0;

  return (
    <MembersPageLayout
      input={
        <MessageBox theme="input-large">
          <TextInputControl
            inputMode="numeric"
            placeholder={ownDictionary.cardNumberInputPlaceholder}
            value={cardNumber}
            onValueChange={handleCardNumberInputValueChange}
          />
        </MessageBox>
      }
      content={
        <MembersPageContentLayout
          label={
            hasLabel || hasFallbackLabel ? (
              <Text size={isDevice ? 'xl' : 'm'} bold>
                {hasLabel
                  ? ownDictionary.contentLabel
                  : ownDictionary.contentFallbackLabel}
              </Text>
            ) : null
          }
          body={
            pullingProcess.status === 'RESOLVED' &&
            pullingProcess.value.clientInfos.map(clientInfo => (
              <MembersTableItem
                key={clientInfo.id}
                clientInfo={clientInfo}
                verified={pullingProcess.value.verifiedClientIds.includes(
                  clientInfo.id
                )}
                onVerificationStatusChanged={handleVerificationStatusChanged}
              />
            ))
          }
        />
      }
    />
  );
};
