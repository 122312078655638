import React from 'react';

import styles from './ArticleEditorFooter.module.css';

import { RectangleButton } from 'components/shared/RectangleButton';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  testId?: string;
  onClick(): void;
};

export const ArticleEditorFooterCancelButton = (props: TProps) => {
  const { onClick, testId } = props;

  const { articleEditorFooterCancelButtonLabel: label } = useIntlContext();

  return (
    <RectangleButton
      testIdButton={testId}
      className={styles.ArticleEditorFooter__CancelButton}
      label={label}
      onClick={onClick}
      styleType={'secondary'}
    />
  );
};
