import React from 'react';

import { useContextStrict } from 'hooks/useContextStrict';
import { ServicesContext } from 'contexts/ServicesContext';
import { TGetNewsFeedParams } from 'types/services';
import { useAsyncController } from 'hooks/useAsyncController';
import { TNewsArticle } from 'types/state';

export const useNewsService = () => {
  // Подключается сервис на получение новостей.
  const { getNewsFeed: newsFeedPullingService } = useContextStrict(
    ServicesContext
  );

  // Состояние и метод вызова сервиса на получение новостей.
  const {
    wrapAsync: wrapNewsFeedPulling,
    asyncProcess: newsFeedPullingProcess
  } = useAsyncController<TNewsArticle[]>();

  // Метод вызова сервиса на получение новостей c проброшенной
  // авторизацией.
  const pullNewsFeed = React.useCallback(
    (restProps: Omit<TGetNewsFeedParams, 'authorization'>) => {
      const promise = newsFeedPullingService(restProps);

      wrapNewsFeedPulling(promise);
    },
    [newsFeedPullingService, wrapNewsFeedPulling]
  );

  return { newsFeedPullingProcess, pullNewsFeed };
};
