import { AppEvent } from 'types/appEvent';
import { UserInfo } from 'types/state';

export const employeeToEditReducer = (
  state: null | UserInfo,
  event: AppEvent
): null | UserInfo => {
  switch (event.name) {
    case 'EMPLOYEE_ITEM__EDIT': {
      return event.user;
    }
    case 'EMPLOYEE_EDIT_MODAL__CANCEL': {
      return null;
    }
    case 'EMPLOYEE_EDIT_SERVICE': {
      if (event.status === 'PENDING') {
        return state;
      }
      if (event.status === 'REJECTED') {
        return state;
      }

      return null;
    }
    default:
      return state;
  }
};
