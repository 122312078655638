import React from 'react';

import './messageTemplatesActionsLayout.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  children: React.ReactNode;
};

export const MessageTemplatesActionsLayout = (props: Props) => {
  const { children } = props;

  const bem = createBEMConstructor('messageTemplatesActionsLayout');

  const wrapperClassName = bem('wrapper');

  return <div className={wrapperClassName}>{children}</div>;
};
