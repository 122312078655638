import { TReviewStatus } from 'types/state';
import { TSportingCommentStatusId } from '../types';
import { UnreachableError } from 'utils/errors';

export const normalizeReviewStatus = {
  toSportingCommentStatusId(status: TReviewStatus): TSportingCommentStatusId {
    switch (status) {
      case 'NEW':
        return 1;
      case 'PROCESSED':
        return 2;
      default:
        throw new UnreachableError(status);
    }
  }
};
