import React from 'react';

import { StandardLayout } from 'design/standardLayout';
import { useChatRoom } from 'hooks/chats/useChatRoom';
import { ChatsPageContacts } from 'pages/ChatsPage/ChatsPageContacts';
import { ChatsPageMessages } from 'pages/ChatsPage/ChatsPageMessages';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ChatsPageForm } from 'pages/ChatsPage/ChatsPageForm';
import { useActiveChatRoomMessages } from 'hooks/chats/useActiveChatRoomMessages';
import { useActiveChatRoomMessagesService } from 'hooks/chats/useChatRoomMessagesService';
import { useChangeChatReadStatusCallback } from 'hooks/chats/useChangeChatReadStatusCallback';
import { useChatsPageTypingService } from 'hooks/chats/useChatsPageTypingService';
import { Shadow } from 'design/shadow';
import { useEditChatMessageService } from 'hooks/chats/useEditChatMessageService';
import { ChatMessage } from 'types/chats';
import { focusChatMessageInput } from 'utils/chatUtils';
import { ServiceScope } from 'types/serviceScope';

type Props = {
  serviceScope: ServiceScope;
  roomId: number;
};

export const ChatsPageContent = (props: Props) => {
  const { roomId, serviceScope } = props;

  useChatsPageTypingService(roomId);

  useActiveChatRoomMessagesService();

  useEditChatMessageService();

  const [messagesCache, setMessagesCache] = React.useState<ChatMessage[]>([]);

  const dispatch = useAppDispatch();

  const changeReadStatus = useChangeChatReadStatusCallback();

  const room = useChatRoom(roomId);

  const messages = useActiveChatRoomMessages();

  const handleMessagesScrolledToBottom = React.useCallback(() => {
    if (room?.unreadMessagesCount && room.unreadMessagesCount > 0) {
      changeReadStatus(roomId, true);
    }
  }, [changeReadStatus, room, roomId]);

  React.useEffect(() => {
    dispatch({
      name: 'CHATS_PAGE__CONTENT_DID_MOUNT',
      roomId
    });
  }, [dispatch, roomId]);

  React.useLayoutEffect(() => {
    focusChatMessageInput();
  }, [roomId]);

  React.useLayoutEffect(() => {
    setMessagesCache([]);
  }, [roomId]);

  React.useLayoutEffect(() => {
    if (messages.length > 0) {
      setMessagesCache(messages);
    }
  }, [messages]);

  if (!room) {
    return null;
  }

  return (
    <StandardLayout
      block="chats-content"
      header={
        <>
          <ChatsPageContacts
            serviceScope={serviceScope}
            clientInfo={room.clientInfo}
          />
          <Shadow direction="down" enable />
        </>
      }
      body={
        messagesCache.length > 0 && (
          <ChatsPageMessages
            roomId={roomId}
            messages={messagesCache}
            unreadMessagesCount={room.unreadMessagesCount}
            onScrolledToBottom={handleMessagesScrolledToBottom}
          />
        )
      }
      footer={<ChatsPageForm roomId={roomId} />}
    />
  );
};
