import {
  BookingWaiterFormValues,
  BookingWaitersFormViolation
} from 'types/state';

export function validateBookingWaitersFormValues(
  values: BookingWaiterFormValues[]
): BookingWaitersFormViolation[] {
  const violations: BookingWaitersFormViolation[] = [];

  for (let waiter of values) {
    if (waiter.fio.trim().length === 0) {
      violations.push({
        name: 'MISSING_FULL_NAME_VALUE',
        waiterId: waiter.id
      });
    }
  }

  return violations;
}
