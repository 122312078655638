import React from 'react';

import { Text } from 'design/text';
import { ChatMessage } from 'types/chats';
import { MessageBox } from 'design/messageBox';
import { ChatMessageLayout } from 'design/chatMessageLayout';
import { createTimeText } from 'utils/dateUtils';
import { createChatMessageDateText } from 'utils/createChatMessageDateText';
import { useIntlContext } from 'hooks/useIntlContext';
import { useEditChatMessageForm } from 'hooks/chats/useEditChatMessageForm';
import { ContentEditable } from 'design/contentEditable';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ActionControl } from 'design/actionControl';
import { KEY_ENTER } from 'utils/constants';
import { focusChatMessageInput } from 'utils/chatUtils';
import { MessageStatusIndicator } from 'design/messageStatusIndicator';

type Props = {
  message: ChatMessage;
  hasDateLabel: boolean;
  hasTimeLabel: boolean;
};

export const ChatsPageMessage = (props: Props) => {
  const { message, hasDateLabel, hasTimeLabel } = props;

  const dispatch = useAppDispatch();

  const editChatMessageForm = useEditChatMessageForm();

  const editing = editChatMessageForm?.messageId === message.id;

  const intl = useIntlContext();

  const align = message.origin === 'CLIENT' ? 'left' : 'right';

  const theme = message.origin === 'CLIENT' ? 'primary' : 'secondary';

  const delivered = message.origin === 'STAFF';

  const read = message.origin === 'STAFF' && message.read;

  const handleMessageChange = React.useCallback(
    (nextText: string) => {
      dispatch({
        name: 'CHATS_PAGE__EDITING_MESSAGE_CHANGE',
        nextText
      });
    },
    [dispatch]
  );

  const handleMessageClick = React.useCallback(() => {
    dispatch({
      name: 'CHATS_PAGE__MESSAGE_CLICK',
      message: message
    });
  }, [dispatch, message]);

  const handleMessageEditorBlur = React.useCallback(() => {
    dispatch({
      name: 'CHATS_PAGE__MESSAGE_EDITOR_BLUR',
      message: message
    });
  }, [dispatch, message]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === KEY_ENTER) {
        if (event.metaKey || event.ctrlKey) {
          dispatch({
            name: 'CHATS_PAGE__MESSAGE_EDITOR_SUBMIT',
            message: message
          });
        }
      }
    },
    [dispatch, message]
  );

  React.useEffect(() => {
    if (editChatMessageForm?.status === 'SUBMITTED') {
      focusChatMessageInput();
    }
  }, [editChatMessageForm]);

  const primaryLabelNode = React.useMemo(() => {
    if (hasDateLabel) {
      const dateText = createChatMessageDateText(message.createdAt, intl);

      return (
        <Text size="s" color="secondary">
          {dateText}
        </Text>
      );
    }

    return null;
  }, [hasDateLabel, intl, message.createdAt]);

  const secondaryLabelNode = React.useMemo(() => {
    if (hasTimeLabel) {
      const timeText = createTimeText(new Date(message.createdAt));

      if (message.edited && !message.deleted) {
        return (
          <Text size="s" color="secondary">
            {`${timeText}, ${intl.chatsPage.editedMessageLabelText}`}
          </Text>
        );
      }

      return (
        <Text size="s" color="secondary">
          {timeText}
        </Text>
      );
    }

    if (message.edited && !message.deleted) {
      return (
        <Text size="s" color="secondary">
          {intl.chatsPage.editedMessageLabelText}
        </Text>
      );
    }

    return null;
  }, [
    hasTimeLabel,
    intl.chatsPage.editedMessageLabelText,
    message.createdAt,
    message.deleted,
    message.edited
  ]);

  return (
    <ChatMessageLayout
      align={align}
      primaryLabel={primaryLabelNode}
      secondaryLabel={secondaryLabelNode}
      message={
        <>
          {editing && (
            <MessageBox theme="editing">
              <ContentEditable
                theme="chat-message"
                disabled={editChatMessageForm?.status !== 'IDLE'}
                autoFocus
                value={editChatMessageForm?.text}
                onValueChange={handleMessageChange}
                onBlur={handleMessageEditorBlur}
                onKeyDown={handleKeyDown}
              />
            </MessageBox>
          )}
          {!editing && !message.deleted && (
            <ActionControl theme="invisible" onDoubleClick={handleMessageClick}>
              <MessageBox theme={theme}>
                <Text>{message.text}</Text>
                <Text color="secondary">
                  <MessageStatusIndicator delivered={delivered} read={read} />
                </Text>
              </MessageBox>
            </ActionControl>
          )}
          {!editing && message.deleted && (
            <MessageBox theme="deleted">
              <Text color="secondary">
                {intl.chatsPage.deletedMessageLabelText}
              </Text>
            </MessageBox>
          )}
        </>
      }
      action={null}
    />
  );
};
