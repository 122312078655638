import { NewUserFormValues } from 'types/state';

export const patchNewUserFormValues = (prevValues: NewUserFormValues) => ({
  done() {
    return prevValues;
  },
  setLogin(nextValue: string) {
    return patchNewUserFormValues({
      ...prevValues,
      login: nextValue
    });
  },
  setPassword(nextValue: string) {
    return patchNewUserFormValues({
      ...prevValues,
      password: nextValue
    });
  },
  setPasswordConfirm(nextValue: string) {
    return patchNewUserFormValues({
      ...prevValues,
      passwordConfirm: nextValue
    });
  },
  setPhoneNumber(nextValue: string) {
    return patchNewUserFormValues({
      ...prevValues,
      phoneNumber: nextValue
    });
  }
});
