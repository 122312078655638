import React from 'react';
import styles from '../BookingPlace/BookingPlaceBodyLayout.module.scss';

type Props = {
  nameLabel: React.ReactNode;
  visitorsCountLabel: React.ReactNode;
  items: React.ReactNode;
};

export const BookingPlaceBodyLayout = (props: Props) => {
  const { nameLabel, visitorsCountLabel, items } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.legend}>
        <div className={styles.nameLabelSlot}>{nameLabel}</div>
        <div className={styles.visitorsCountLabelSlot}>
          {visitorsCountLabel}
        </div>
      </div>
      <div className={styles.itemsSlot}>{items}</div>
    </div>
  );
};
