import {
  ChatsState,
  ChatMessagesCollection,
  ChatRoomsCollection,
  ChatMessageForm,
  UnreadChatIdLists
} from 'types/chats';
import { useAppState } from 'hooks/useAppState';
import { ListCache } from 'types/util';
import { createChatsMessageForm } from 'utils/createChatsState';

export const useChatsState = (): ChatsState => {
  const { chatsState } = useAppState();

  return chatsState;
};

export const useChatMessagesCollection = (): ChatMessagesCollection => {
  const chatsState = useChatsState();

  return chatsState.chatMessagesCollection;
};

export const useChatRoomsCollection = (): ChatRoomsCollection => {
  const chatsState = useChatsState();

  return chatsState.chatRoomsCollection;
};

export const useChatRoomIds = (): ListCache<number> => {
  const { chatRoomIds } = useChatsState();

  return chatRoomIds;
};

export const useUnreadChatIdLists = (): UnreadChatIdLists => {
  const { unreadChatIdLists } = useChatsState();

  return unreadChatIdLists;
};

export const useChatMessageForm = (roomId: number): ChatMessageForm => {
  const { chatMessageFormById } = useChatsState();

  return chatMessageFormById.get(roomId) || createChatsMessageForm();
};

export const useTypingMap = (): Map<number, number> => {
  const { typingMap } = useChatsState();

  return typingMap;
};
