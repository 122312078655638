import { TDeleteReservationParams } from '../../../types/services';
import { sportingAPI } from '../api';
import { normalizeBookingReservationFormValues } from '../utils/normalizeBookingReservationFormValues';

export const deleteReservation = async (params: TDeleteReservationParams) => {
  const { formValues } = params;
  const payload = normalizeBookingReservationFormValues(
    formValues
  ).toSportingDeleteReservationPayload();
  await sportingAPI.booking.deleteReservation({ payload });
};
