import { TGetCompetitionsFeedParams } from 'types/services';
import { UnreachableError } from 'utils/errors';
import { TCompetitionsArticle } from 'types/state';
import { normalizeSportingCompetitionsArticle } from 'services/sporting/utils/normalizeSportingCompetitionsArticle';
import { sportingAPI } from 'services/sporting/api';

export const getCompetitionsFeed = async (
  params: TGetCompetitionsFeedParams
): Promise<TCompetitionsArticle[]> => {
  const { page, filter } = params;

  const methodParams = {
    payload: {
      pageNum: page
    }
  };

  const promise = (() => {
    switch (filter) {
      case 'past':
        return sportingAPI.service.getPrevCompetitions(methodParams);
      case 'upcoming':
        return sportingAPI.service.getNearCompetitions(methodParams);
      default:
        throw new UnreachableError(filter);
    }
  })();

  const articles = await promise;

  return articles.map(
    normalizeSportingCompetitionsArticle.toCompetitionsArticle
  );
};
