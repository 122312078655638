import React from 'react';
import ReactDOM from 'react-dom';

import { ruIntl } from 'intl/ru';
import { App } from 'containers/App';
import { servicesMock } from 'services/mock';
import { servicesSporting } from 'services/sporting';

const { NODE_ENV, REACT_APP_SERVICES_TYPE } = process.env;

export const renderApp = () => {
  const mountNode = document.getElementById('root');
  if (NODE_ENV === 'test') {
    ReactDOM.render(<App services={servicesMock} intl={ruIntl} />, mountNode);
  } else if (NODE_ENV === 'production') {
    ReactDOM.render(
      <App services={servicesSporting} intl={ruIntl} />,
      mountNode
    );
  } else {
    if (REACT_APP_SERVICES_TYPE === 'sporting') {
      ReactDOM.render(
        <App services={servicesSporting} intl={ruIntl} />,
        mountNode
      );
    } else {
      ReactDOM.render(<App services={servicesMock} intl={ruIntl} />, mountNode);
    }
  }
};
