import { MembersPageDataService } from 'types/services';
import { createRandomBoolean } from 'utils/createRandomBoolean';
import { createClientInfo } from '../utils/clientInfoInstances';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';

export const getMembersPageData: MembersPageDataService = async cardNumber => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  if (cardNumber.startsWith('000')) {
    return {
      clientInfos: [],
      verifiedClientIds: []
    };
  }

  const clientInfos = [
    createClientInfo(),
    createClientInfo(),
    createClientInfo(),
    createClientInfo(),
    createClientInfo(),
    createClientInfo(),
    createClientInfo(),
    createClientInfo(),
    createClientInfo()
  ];

  return {
    clientInfos,
    verifiedClientIds: clientInfos
      .filter(() => {
        return createRandomBoolean();
      })
      .map(clientInfo => clientInfo.id)
  };
};
