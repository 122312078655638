import React from 'react';

import { ModalWindow } from 'components/Modal/ModalWindow';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalPortal } from 'components/shared/Modal';
import { RectangleButton } from 'components/shared/RectangleButton';

type Props = {
  title: string;
  description: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  hasPendingView: boolean;
  handleCancel(): void;
  handleConfirm(): void;
};

export const DeleteArticleModalView = (props: Props) => {
  const {
    title,
    description,
    cancelButtonLabel,
    confirmButtonLabel,
    hasPendingView,
    handleCancel,
    handleConfirm
  } = props;

  return (
    <ModalPortal toRight>
      <ModalWindow>
        <ModalBody title={title} description={description} />
        <ModalFooter>
          <RectangleButton
            styleType={'secondary'}
            isDisabled={hasPendingView}
            label={cancelButtonLabel}
            onClick={handleCancel}
          />
          <RectangleButton
            styleType={'primary'}
            hasPreloader={hasPendingView}
            label={confirmButtonLabel}
            onClick={handleConfirm}
          />
        </ModalFooter>
      </ModalWindow>
    </ModalPortal>
  );
};
