import React from 'react';

import { useBrowserTabTitleUpdater } from 'hooks/useBrowserTabTitleUpdater';
import { BookingHeader } from 'components/BookingHeader/BookingHeader';
import { useReservationsService } from '../../hooks/booking/useReservationsService';
import { useReservationsCache } from '../../hooks/booking/useReservationsCache';
import { BookingTimelineGroupComponent } from 'containers/BookingTimelineGroup';
import {
  BookingTimelineGroup,
  BookingTimelineReservation
} from 'types/bookingTimeline';
import { BookingPageLayout } from 'design/bookingPageLayout/bookingPageLayout';
import { BookingTimelineLegend } from 'containers/BookingTimelineLegend';
import { normalizeReservation } from 'utils/booking/normalizeReservation';
import { normalizePlace } from 'utils/normalizePlace';
import { convertDateISOToYYYY_MM_DD } from '../../utils/dateUtils';
import { useWorkingHours } from 'hooks/useWorkingHours';
import { useBookingPage } from 'hooks/booking/useBookingPage';
import { useBookingPlaces } from 'hooks/booking/useBookingPlaces';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ServiceScope } from 'types/serviceScope';
import { useAppState } from 'hooks/useAppState';
import { useBookingTimeLinesLoadingProcess } from 'hooks/booking/useBookingTimeLinesLoadingProcess';
import { useBookingPlacesLoadingProcess } from 'hooks/booking/useBookingPlacesLoadingProcess';

type Props = {
  location: ServiceScope;
};

export const BookingPage = (props: Props) => {
  const { location } = props;

  useBrowserTabTitleUpdater();

  useBookingTimeLinesLoadingProcess();

  useBookingPlacesLoadingProcess();

  const { selectedDateISO } = useBookingPage(location);

  // загрузка мест

  const places = useBookingPlaces(location);

  const { timeLinesCache } = useAppState();

  const timeLine = React.useMemo(() => {
    for (let timeLine of timeLinesCache.value) {
      if (timeLine.location === location) {
        return timeLine;
      }
    }

    return null;
  }, [location, timeLinesCache.value]);

  const reservationsCache = useReservationsCache();

  // загрузка броней
  const { loadReservations } = useReservationsService();

  React.useEffect(() => {
    if (reservationsCache.didInvalidate) {
      loadReservations({
        day: convertDateISOToYYYY_MM_DD(selectedDateISO)
      });
    }
  }, [loadReservations, reservationsCache.didInvalidate, selectedDateISO]);

  const workingHours = useWorkingHours(selectedDateISO, location);

  const timelineGroups = React.useMemo(() => {
    const groups: BookingTimelineGroup[] = places.map(place => {
      return normalizePlace(place).toBookingTimelineGroup();
    });

    return groups;
  }, [places]);

  const timelineReservations = React.useMemo(() => {
    let bookingTimeLineReservations: BookingTimelineReservation[] = [];

    for (let reservation of Array.from(reservationsCache.value.values())) {
      const bookingTimelineReservation = normalizeReservation(
        reservation
      ).toBookingTimelineReservation();

      bookingTimeLineReservations.push(bookingTimelineReservation);
    }

    return bookingTimeLineReservations;
  }, [reservationsCache.value]);

  const timelineReservationsByGroupId = React.useMemo(() => {
    const map: Map<number, BookingTimelineReservation[]> = new Map();

    for (let group of timelineGroups) {
      const currentGroupReservations = timelineReservations.filter(
        currentTimelineReservation => {
          return group.items.some(item =>
            currentTimelineReservation.itemIds.includes(item.id)
          );
        }
      );

      map.set(group.id, currentGroupReservations);
    }

    return map;
  }, [timelineGroups, timelineReservations]);

  const dispatch = useAppDispatch();

  const onCreateReservationButtonClick = React.useCallback(() => {
    dispatch({
      name: 'BookingPage__CreateReservationButtonClicked',
      serviceScope: location
    });
  }, [dispatch, location]);

  const onReservationClick = React.useCallback(
    (id: number) => {
      const reservation = reservationsCache.value.get(id);

      if (reservation) {
        dispatch({
          name: 'BookingPage__ReservationClicked',
          reservation,
          serviceScope: location
        });
      }
    },
    [dispatch, location, reservationsCache.value]
  );

  return (
    <BookingPageLayout
      header={
        <BookingHeader
          location={location}
          places={places}
          timeLine={timeLine}
          onShowReservationModal={onCreateReservationButtonClick}
        />
      }
      timelineLegend={
        workingHours && <BookingTimelineLegend workingHours={workingHours} />
      }
      timelineGroups={
        workingHours &&
        timelineGroups.map(timelineGroup => (
          <BookingTimelineGroupComponent
            scope={location}
            key={timelineGroup.id}
            group={timelineGroup}
            reservations={
              timelineReservationsByGroupId.get(timelineGroup.id) || []
            }
            workingHours={workingHours}
            onReservationClick={onReservationClick}
          />
        ))
      }
    />
  );
};
