import React from 'react';

import styles from './ReviewPreview.module.css';

type TProps = {
  children: React.ReactNode;
  hasAccentTheme?: boolean;
  hasSecondaryTheme?: boolean;
};

export const ReviewPreviewStatus = ({
  children,
  hasAccentTheme,
  hasSecondaryTheme
}: TProps) => (
  <div
    className={`${styles.ReviewPreview__Status} ${
      hasAccentTheme ? styles.ReviewPreview__Status_accent : ''
    } ${hasSecondaryTheme ? styles.ReviewPreview__Status_secondary : ''}`}
  >
    {children}
  </div>
);
