import React from 'react';

import styles from './NewsFeed.module.css';

import { ScrollableShadow } from 'components/shared/ScrollableShadow';

type TProps = {
  children: React.ReactNode;
  onScroll(e: React.SyntheticEvent): void;
};

export const NewsFeedScrollable = ({ children, onScroll }: TProps) => {
  return (
    <ScrollableShadow
      threshold={30}
      className={styles.NewsFeed__Scrollable}
      onScroll={onScroll}
    >
      {children}
    </ScrollableShadow>
  );
};
