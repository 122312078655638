import { AppEvent } from 'types/appEvent';
import { ListCache } from 'types/util';
import { TReview } from 'types/state';
import { createListCache, modifyListCache } from 'utils/listCacheUtils';

export const reviewsCacheReducer = (
  state: ListCache<TReview>,
  event: AppEvent
): ListCache<TReview> => {
  switch (event.name) {
    case 'BADGE_UPDATE_REQUESTED': {
      if (event.section === 'feedback') {
        return modifyListCache(state)
          .invalidateAllPages()
          .return();
      }

      return state;
    }
    case 'ReviewsPageEvent__Mounted': {
      return modifyListCache(createListCache<TReview>())
        .invalidateNextPage()
        .return();
    }
    case 'ReviewsPageEvent__NextPageRequested': {
      return modifyListCache(state)
        .invalidateNextPage()
        .return();
    }
    case 'ReviewsPageEvent__ReviewsPageRecieved': {
      return modifyListCache(state)
        .putItems(event.page, event.reviews)
        .return();
    }
    case 'ReviewsPageEvent__ReviewStatusChanged': {
      for (let [page, reviews] of Array.from(state.byPage)) {
        let matchFound = false;

        const nextReviews = reviews.map(review => {
          if (review.id === event.reviewId) {
            matchFound = true;

            const nextReview: TReview = {
              ...review,
              status: event.newStatus
            };

            return nextReview;
          }

          return review;
        });

        if (matchFound) {
          return modifyListCache(state)
            .putItems(page, nextReviews)
            .return();
        }
      }

      return state;
    }
    case 'ReviewsPageEvent__ReviewNotesUpdated': {
      for (let [page, reviews] of Array.from(state.byPage)) {
        let matchFound = false;

        const nextReviews = reviews.map(review => {
          if (review.id === event.reviewId) {
            matchFound = true;

            const nextReview: TReview = {
              ...review,
              note: event.newNotes
            };

            return nextReview;
          }

          return review;
        });

        if (matchFound) {
          return modifyListCache(state)
            .putItems(page, nextReviews)
            .return();
        }
      }

      return state;
    }
    default:
      return state;
  }
};
