import { AppState } from 'types/appState';
import { getDeviceFlag, getMediaQueryType } from 'utils/mediaQueryUtils';
import { getWindowDimensions } from 'utils/getWindowDimensions';
import { getOnlineFlag } from 'utils/getOnlineFlag';
import { createCache, modifyCache } from 'utils/createCache';
import { patchCache } from 'utils/patchCache';
import { createSectionsEditorState } from 'utils/createSectionsEditorState';
import { createPasswordEditorState } from 'utils/createPasswordEditorState';
import { createUsersEditorState } from 'utils/createUsersEditorState';
import { createCollectionCache } from 'utils/collectionCacheUtils';
import { createListCache, modifyListCache } from 'utils/listCacheUtils';
import { createChatsState } from 'utils/createChatsState';
import { createMessageTemplatesState } from 'utils/messageTemplateUtils';
import { TReview, Waiter } from 'types/state';
import { createSheduleEditorState } from './createSheduleEditorState';
import { createSheduleInfo } from './sheduleInfoUtils';
import { createPersonalDataEditorState } from './settings/personalDataEditor/createPersonalDataEditorState';
import { createLoginEditorState } from './settings/loginEditor/createLoginEditorState';
import { createUserToBlockedState } from './employee/createUserToBlockedState';
import { createEmployeeEditorState } from './employee/createEmployeeEditorState';
import { createBookingPlacesEditorState } from './booking/createBookingPlacesEditorState';
import { createBookingPage } from './bookingPageUtils';
import { createBookingReservationEditorState } from './booking/createBookingReservationEditorState';

export const createAppState = (): AppState => ({
  visible: true,
  deviceFlag: getDeviceFlag(),
  mediaQueryType: getMediaQueryType(getWindowDimensions()),
  mobileDrawerOpened: null,
  onlineFlag: getOnlineFlag(),
  remainingSMSCount: null,
  connectionErrorFlag: false,
  session: null,
  newSportApplicationsCount: patchCache(createCache(0))
    .invalidate()
    .done(),
  newRestaurantApplicationsCount: patchCache(createCache(0))
    .invalidate()
    .done(),
  competitionsRequestsCount: patchCache(createCache(0))
    .invalidate()
    .done(),
  competitionRequestsCountsById: new Map<number, number>(),
  newReviewsCount: patchCache(createCache(0))
    .invalidate()
    .done(),
  toastCandidates: [],
  userInfoCache: patchCache(createCache(null))
    .invalidate()
    .done(),
  sectionsEditorState: createSectionsEditorState(),
  personalDataEditorState: createPersonalDataEditorState(),
  loginEditorState: createLoginEditorState(),
  passwordEditorState: createPasswordEditorState(),
  sheduleEditorState: createSheduleEditorState(),
  usersEditorState: createUsersEditorState(),
  userToDeleteId: null,
  phoneNumbers: null,
  applicationsCollection: createCollectionCache(),
  newSportApplicationsList: createListCache(),
  newRestaurantApplicationsList: createListCache(),
  processedSportApplicationsList: createListCache(),
  processedRestaurantApplicationsList: createListCache(),
  chatsState: createChatsState(),
  clientInfosCollection: createCollectionCache(),
  messageTemplatesState: createMessageTemplatesState(),
  reviewsCache: modifyListCache(createListCache<TReview>()).return(),
  sheduleInfoCache: patchCache(createCache(createSheduleInfo()))
    .invalidate()
    .done(),
  employeeToEdit: null,
  employeeEditorState: createEmployeeEditorState(null),
  usersCache: createCollectionCache(),
  userToBlockedState: createUserToBlockedState(),
  waitersCache: modifyCache(createCache(new Map<number, Waiter>()))
    .invalidate()
    .return(),
  placesCache: createCache(new Map()),
  timeLinesCache: modifyCache(createCache([]))
    .invalidate()
    .return(),
  reservationsCache: modifyCache(createCache(new Map()))
    .invalidate()
    .return(),
  bookingPlacesEditorState: createBookingPlacesEditorState(null, 'RESTAURANT'),
  restaurantBookingPage: createBookingPage(),
  clubBookingPage: createBookingPage(),
  bookingReservationEditorState: createBookingReservationEditorState(null)
});
