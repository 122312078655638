import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useCompetitionsRequestsCount } from 'hooks/useCompetitionsRequestsCount';
import { useNewRestaurantApplicationsCount } from 'hooks/useNewRestaurantApplicationsCount';
import { useNewSportApplicationsCount } from 'hooks/useNewSportApplicationsCount';
import { useNewReviewsCount } from 'hooks/useNewReviewsCount';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { useUserRoleStrict } from 'hooks/useUserRole';
import { useUnreadChatsCount } from 'hooks/chats/useUnreadChatsCount';
import { useServices } from 'hooks/useServices';
import { authorizedRoutes } from 'utils/authorizedRoutes';
import { testId } from 'utils/testId';
import { NavigationItem } from 'containers/Navigation/styled/NavigationItem';
import { NavigationWrapper } from 'containers/Navigation/styled/NavigationWrapper';
import { NavigationItems } from 'containers/Navigation/styled/NavigationItems';
import { NavigationSignOutButton } from 'containers/Navigation/styled/NavigationSignOutButton';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { RestaurantIcon } from 'svg/25x25/restaurant';
import { MessageIcon } from 'svg/25x25/message';
import { BookingIcon } from 'svg/25x25/booking';
import { ShootingIcon } from 'svg/25x25/shooting';
import { NewsIcon } from 'svg/25x25/news';
import { CupIcon } from 'svg/25x25/cup';
import { ResponseIcon } from 'svg/25x25/response';
import { MembersIcon } from 'svg/25x25/members';
import { UsersIcon } from 'svg/25x25/users';
import { GearIcon } from 'svg/25x25/gear';

export const Navigation = () => {
  const intl = useIntlContext();

  const { signOut } = useServices();

  const wrapaSyncSafely = useSafeAsyncWrapper();

  const dispatch = useAppDispatch();

  const enabledSections = useEnabledSectionsStrict();

  const userRole = useUserRoleStrict();

  // Достает значения бейджей из глобального состояния

  const restaurantChatsBageCount = useUnreadChatsCount('RESTAURANT');

  const sportChatsBageCount = useUnreadChatsCount('CLUB');

  // Нормализация состояния.

  const competitionsBageCount =
    useCompetitionsRequestsCount().value || undefined;

  const restaurantApplicationsBageCount =
    useNewRestaurantApplicationsCount().value || undefined;

  const sportApplicationsBageCount =
    useNewSportApplicationsCount().value || undefined;

  const reviewsBageCount = useNewReviewsCount().value || undefined;

  const handleSignOutButtonClick = React.useCallback(() => {
    const promise = signOut().then(() => {
      dispatch({
        name: 'NAVIGATION__SIGNED_OUT'
      });
    });

    wrapaSyncSafely(promise);
  }, [dispatch, signOut, wrapaSyncSafely]);

  const hasApplicationsRestaurantItem = enabledSections.includes(
    'applicationsRestaurant'
  );

  const hasApplicationsSportItem = enabledSections.includes(
    'applicationsSport'
  );

  const hasCompetitionsItem = enabledSections.includes('competitions');

  const hasNewsItem =
    (userRole === 'ADMINISTRATOR' || userRole === 'MANAGER') &&
    enabledSections.includes('news');

  const hasFeedbackItem =
    (userRole === 'ADMINISTRATOR' || userRole === 'MANAGER') &&
    enabledSections.includes('feedback');

  const hasMembersItem = enabledSections.includes('members');

  const hasChatsRestaurantItem = enabledSections.includes('chatsRestaurant');

  const hasBookingRestaurantItem = enabledSections.includes(
    'bookingRestaurant'
  );

  const hasChatsSportItem = enabledSections.includes('chatsSport');

  const hasBookingClubItem = enabledSections.includes('bookingClub');

  const hasUsersItem = enabledSections.includes('users');

  const hasSettingsItem = userRole !== 'SECURITY';

  return (
    <NavigationWrapper testId={testId.NAVIGATION_WRAPPER}>
      <NavigationItems position="top">
        {hasApplicationsRestaurantItem && (
          <NavigationItem
            icon={<RestaurantIcon />}
            route={authorizedRoutes.applicationsRestaurant()}
            label={intl.navigation.applocationsRestarauntLabel}
            testId={testId.NAVIGATION_ITEM_APPLICATIONS_RESTAURANT}
            bageTestId={testId.NAVIGATION_BAGE_APPLICATIONS_RESTAURANT}
            count={restaurantApplicationsBageCount}
          />
        )}
        {hasChatsRestaurantItem && (
          <NavigationItem
            icon={<MessageIcon />}
            route={authorizedRoutes.chatsRestaurant()}
            label={intl.navigation.chatsRestaurantLabel}
            testId={testId.NAVIGATION_ITEM_CHATS_RESTAURANT}
            bageTestId={testId.NAVIGATION_BAGE_CHATS_RESTAURANT}
            count={restaurantChatsBageCount}
          />
        )}
        {hasBookingRestaurantItem && (
          <NavigationItem
            icon={<BookingIcon />}
            route={authorizedRoutes.bookingRestaurant()}
            label={intl.navigation.bookingRestaurantLabel}
            testId={testId.NAVIGATION_ITEM_BOOKING_RESTAURANT}
            bageTestId={testId.NAVIGATION_BAGE_BOOKING_RESTAURANT}
          />
        )}
        {hasApplicationsSportItem && (
          <NavigationItem
            icon={<ShootingIcon />}
            route={authorizedRoutes.applicationsSport()}
            label={intl.navigation.applicationsSportLabel}
            testId={testId.NAVIGATION_ITEM_APPLICATIONS_SPORT}
            bageTestId={testId.NAVIGATION_BAGE_APPLICATIONS_SPORT}
            count={sportApplicationsBageCount}
          />
        )}
        {hasChatsSportItem && (
          <NavigationItem
            icon={<MessageIcon />}
            route={authorizedRoutes.chatsSport()}
            label={intl.navigation.chatsSportLabel}
            testId={testId.NAVIGATION_ITEM_CHATS_SPORT}
            bageTestId={testId.NAVIGATION_BAGE_CHATS_SPORT}
            count={sportChatsBageCount}
          />
        )}
        {hasBookingClubItem && (
          <NavigationItem
            icon={<BookingIcon />}
            route={authorizedRoutes.bookingClub()}
            label={intl.navigation.bookingClubLabel}
            testId={testId.NAVIGATION_ITEM_BOOKING_CLUB}
            bageTestId={testId.NAVIGATION_BAGE_BOOKING_CLUB}
          />
        )}
        {hasNewsItem && (
          <NavigationItem
            icon={<NewsIcon />}
            route={authorizedRoutes.newsRoot()}
            label={intl.navigation.newsLabel}
            testId={testId.NAVIGATION_ITEM_NEWS}
          />
        )}
        {hasCompetitionsItem && (
          <NavigationItem
            icon={<CupIcon />}
            route={authorizedRoutes.competitions()}
            label={intl.navigation.competitionsLabel}
            testId={testId.NAVIGATION_ITEM_COMPETITIONS}
            bageTestId={testId.NAVIGATION_BAGE_COMPETITIONS}
            count={competitionsBageCount}
          />
        )}
        {hasFeedbackItem && (
          <NavigationItem
            icon={<ResponseIcon />}
            route={authorizedRoutes.feedback()}
            label={intl.navigation.feedbackLabel}
            testId={testId.NAVIGATION_ITEM_FEEDBACK}
            bageTestId={testId.NAVIGATION_BAGE_FEEDBACK}
            count={reviewsBageCount}
          />
        )}
      </NavigationItems>
      <NavigationItems position="bottom">
        {hasMembersItem && (
          <NavigationItem
            icon={<MembersIcon />}
            route={authorizedRoutes.members()}
            label={intl.navigation.membersLabel}
            testId={testId.NAVIGATION_ITEM_MEMBERS}
          />
        )}
        {hasUsersItem && (
          <NavigationItem
            icon={<UsersIcon />}
            route={authorizedRoutes.users()}
            label={intl.navigation.usersLabel}
            testId={testId.NAVIGATION_ITEM_USERS}
          />
        )}
        {hasSettingsItem && (
          <NavigationItem
            icon={<GearIcon />}
            route={authorizedRoutes.settings()}
            label={intl.navigation.settingsLabel}
            testId={testId.NAVIGATION_ITEM_SETTINGS}
          />
        )}
        <NavigationSignOutButton
          testId={testId.NAVIGATION_SIGN_OUT_BUTTON}
          text={intl.navigation.signOutLabel}
          onClick={handleSignOutButtonClick}
        />
      </NavigationItems>
    </NavigationWrapper>
  );
};
