import React from 'react';

import { AuthorizedScreen } from 'containers/AuthorizedScreen';
import { GuestScreen } from 'containers/GuestScreen';
import { useSession } from 'hooks/useSession';
import { useUserInfo } from 'hooks/useUserInfoCache';

export const Root = () => {
  const session = useSession();

  const userInfo = useUserInfo();

  if (session) {
    const hasAuthorizedScreen =
      session.type === 'AUTHORIZED' && userInfo !== null;

    const hasGuestScreen = session.type === 'UNAUTHORIZED';

    if (hasAuthorizedScreen) {
      return <AuthorizedScreen />;
    }

    if (hasGuestScreen) {
      return <GuestScreen />;
    }
  }

  return null;
};
