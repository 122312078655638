import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';
import { createQueryString } from 'utils/createQueryString';
import { SportingUser } from 'services/sporting/types';

type Params = {
  cardNumberPart: string;
};

export const findRegisteredUsersByCardNumberPart = async (
  params: Params
): Promise<SportingUser[]> => {
  const endpointURL = getSportingServiceURL(
    `findRegisteredUsersByCardNumberPart?${createQueryString(params)}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (response.ok) {
    return response.json();
  } else {
    throw new UnexpectedError();
  }
};
