import React from 'react';

import {
  TCompetitionDuration,
  TCompetitionDurationCandidate,
  TCompetitionDurationPopup
} from 'types/state';
import { CompetitionDurationInputWrapper } from 'components/CompetitionDurationInput/CompetitionDurationInputWrapper';
import { CompetitionDurationInputPicker } from 'components/CompetitionDurationInput/CompetitionDurationInputPicker';
import { CompetitionDurationInputDash } from 'components/CompetitionDurationInput/CompetitionDurationInputDash';
import { useIntlContext } from 'hooks/useIntlContext';
import {
  getDefaultDuration,
  formatISODateToDurationInputDate,
  getNextDurationValue
} from 'containers/CompetitionDurationInput/logic/utils';

type TProps = {
  value: null | TCompetitionDuration;
  onChange(nextValue: TCompetitionDuration): void;
};

export const CompetitionDurationInput = (props: TProps) => {
  const { value: propsValue, onChange } = props;

  const {
    competitionStartDatePlaceholder,
    competitionEndDatePlaceholder,
    predicativeMonthNames
  } = useIntlContext();

  // Состояние отвечает за хранение временного значения
  // длительности соревнования. Устанавливает переданное
  // родителем значение в качестве значения по-умолчанию.
  const [durationValue, setDurationValue] = React.useState<
    TCompetitionDurationCandidate
  >(propsValue || getDefaultDuration);

  // Состояние отвечает за открытие / скрытие попапов выбора даты.
  const [popupType, setPopupType] = React.useState<TCompetitionDurationPopup>(
    'NONE'
  );

  // Эффект проверяет новые значения длительности соревнования
  // и в случае успеха передает значения родителю.
  React.useEffect(() => {
    const { endDate, startDate } = durationValue;
    // Передавать объект длительности родителю можно только,
    // если заполнены оба поля.
    if (endDate && startDate) {
      // WARNING: проверка на несоответствие изначальным значениям
      // позволяет избежать зацикливания.
      const check1 = !propsValue || propsValue.startDate !== startDate;
      const check2 = !propsValue || propsValue.endDate !== endDate;
      if (check1 || check2) {
        onChange({ endDate, startDate });
      }
    }
  }, [durationValue, propsValue, onChange]);

  // Эффект автоматически переключает попапы выбора дат,
  // если одно из значений длительности не установлено.
  React.useEffect(() => {
    const { endDate, startDate } = durationValue;
    if (endDate && !startDate) {
      setPopupType('START');
    }
    if (startDate && !endDate) {
      setPopupType('END');
    }
  }, [durationValue]);

  // Форматирование даты начала сорвервнования для визуального отображения.
  const startDatePickerLabel = durationValue.startDate
    ? formatISODateToDurationInputDate(
        durationValue.startDate,
        predicativeMonthNames
      )
    : competitionStartDatePlaceholder;

  // Форматирование даты окончания сорвервнования для визуального отображения.
  const endDatePickerLabel = durationValue.endDate
    ? formatISODateToDurationInputDate(
        durationValue.endDate,
        predicativeMonthNames
      )
    : competitionEndDatePlaceholder;

  // Обработчик выбора даты начала соревнования.
  const handleStartDateChange = React.useCallback((nextValue: string) => {
    setDurationValue(getNextDurationValue({ nextStartDate: nextValue }));
    setPopupType('NONE');
  }, []);

  // Обработчик выбора даты окончания соревнования.
  const handleEndDateChange = React.useCallback((nextValue: string) => {
    setDurationValue(getNextDurationValue({ nextEndDate: nextValue }));
    setPopupType('NONE');
  }, []);

  // Обработчик открытия попапа выбора даты окончания соревнования.
  const handleEndDatePickerClick = React.useCallback(() => {
    setPopupType('END');
  }, []);

  // Обработчик открытия попапа выбора даты начала соревнования.
  const handleStartDatePickerClick = React.useCallback(() => {
    setPopupType('START');
  }, []);

  // Обработчик клика вне попапа. Закрывает любой открытый попап
  // выбора даты.
  const handleOutsideClick = React.useCallback(() => {
    setPopupType('NONE');
    if (!durationValue.endDate || !durationValue.startDate) {
      setDurationValue({ startDate: null, endDate: null });
    }
  }, [durationValue.endDate, durationValue.startDate]);

  // Мемоизированное значение длительности соревнования.
  const value: TCompetitionDuration = React.useMemo(
    () => ({
      startDate:
        durationValue.startDate ||
        // В случае, если начало длительности соревнования не установлено,
        // а окончание - да, в качестве значения начала устанавливается
        // значение окончания.
        durationValue.endDate ||
        // Иначе, в качестве начала длительности устанавливается
        // текущая дата.
        new Date().toISOString(),
      endDate:
        durationValue.endDate ||
        // В случае, если окончание длительности соревнования не установлено,
        // а начало - да, в качестве значения окончания устанавливается
        // значение начала.
        durationValue.startDate ||
        // Иначе, в качестве окончания длительности устанавливается
        // текущая дата.
        new Date().toISOString()
    }),
    [durationValue]
  );

  const hasStartDatePickerPopup = popupType === 'START';

  const hasEndDatePickerPopup = popupType === 'END';

  // Значение даты, раньше которой устанавливать начало
  // длительности соревнования нельзя.
  const disableStartDateBefore = React.useMemo(() => {
    return new Date().toISOString();
  }, []);

  // Значение даты, раньше которой устанавливать окончание
  // длительности соревнования нельзя.
  const disableEndDateBefore = React.useMemo(() => {
    return new Date().toISOString();
  }, []);

  return (
    <CompetitionDurationInputWrapper onOutsideClick={handleOutsideClick}>
      <CompetitionDurationInputPicker
        label={startDatePickerLabel}
        value={value.startDate}
        onChange={handleStartDateChange}
        onClick={handleStartDatePickerClick}
        isOpened={hasStartDatePickerPopup}
        disableBefore={disableStartDateBefore}
      />
      <CompetitionDurationInputDash />
      <CompetitionDurationInputPicker
        label={endDatePickerLabel}
        value={value.endDate}
        onChange={handleEndDateChange}
        onClick={handleEndDatePickerClick}
        isOpened={hasEndDatePickerPopup}
        disableBefore={disableEndDateBefore}
      />
    </CompetitionDurationInputWrapper>
  );
};
