import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingPageLayout.scss';

type Props = {
  header: React.ReactNode;
  timelineLegend: React.ReactNode;
  timelineGroups: React.ReactNode;
};

const bemConstructor = createBEMConstructor('bookingPageLayout');

export const BookingPageLayout: React.FC<Props> = props => {
  const { header, timelineLegend, timelineGroups } = props;

  const wrapperClassName = bemConstructor('wrapper');

  const headerSlotClassName = bemConstructor('headerSlot');

  const timelineLegendSlotClassName = bemConstructor('timelineLegendSlot');

  const timelineGroupsWrapperClassName = bemConstructor(
    'timelineGroupsWrapper'
  );

  const timelineGroupsSlotClassName = bemConstructor('timelineGroupsSlot');

  return (
    <div className={wrapperClassName}>
      <div className={headerSlotClassName}>{header}</div>
      <div className={timelineLegendSlotClassName}>{timelineLegend}</div>
      <div className={timelineGroupsWrapperClassName}>
        <div className={timelineGroupsSlotClassName}>{timelineGroups}</div>
      </div>
    </div>
  );
};
