import { TGetCompetitionRequestsCountParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const getCompetitionRequestsCount = async (
  params: TGetCompetitionRequestsCountParams
): Promise<number> => {
  const { id } = params;

  const { count } = await sportingAPI.service.getParticipationsCount({
    payload: {
      idCompetition: id
    }
  });

  return count;
};
