import { TUpdateCurrentUserPersonalDataParams } from 'types/services';
import { sportingAPI } from '../api';

export const updateCurrentUserPersonalData = async (
  params: TUpdateCurrentUserPersonalDataParams
): Promise<void> => {
  await sportingAPI.service.changePersonalDataForCurrentUser({
    payload: {
      lastName: params.lastName,
      firstName: params.firstName,
      phoneNumber: params.phoneNumber
    }
  });
};
