import React from 'react';

import styles from './CompetitionsArticle.module.css';

type TProps = {
  children: React.ReactNode;
  hasEditView: boolean;
};

export const CompetitionsArticleTemplate: React.FC<TProps> = ({
  children,
  hasEditView
}) => (
  <div
    className={`${styles.CompetitionsArticle__Template} ${
      hasEditView ? styles.CompetitionsArticle__Template_edit : ''
    }`}
  >
    {children}
  </div>
);
