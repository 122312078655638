import React from 'react';

import styles from './NewsArticle.module.css';

type TProps = {
  label: React.ReactNode;
  placeholder: string;
};

export const NewsArticleDescription = ({ label, placeholder }: TProps) => {
  if (label) {
    return <div className={styles.NewsArticle__Description}>{label}</div>;
  }
  return (
    <div className={styles.NewsArticle__DescriptionPlaceholder}>
      {placeholder}
    </div>
  );
};
