import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import {
  TSportingClaimStatusId,
  SportingSecureParams
} from 'services/sporting/types';
import { appendFormDataWithObjectValues } from 'utils/appendFormDataWithObjectValues';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  idClaim: number;
  idStatus: TSportingClaimStatusId;
};

export const changeClaimStatus = async (
  params: SportingSecureParams<Payload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL('changeClaimStatus');

  const headers = createSportingHeaders();

  const body = new FormData();
  appendFormDataWithObjectValues(body, payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body,
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
