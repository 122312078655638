import React, { SyntheticEvent } from 'react';
import { Redirect } from 'react-router';

import {
  TCompetitionsFilter,
  TCompetitionsFeedFilterOption
} from 'types/state';
import { CustomSelect } from 'containers/CustomSelect';
import { CompetitionsFeedWrapper } from 'containers/CompetitionsFeed/view/styled/CompetitionsFeedWrapper';
import { CompetitionsFeedHeader } from 'containers/CompetitionsFeed/view/styled/CompetitionsFeedHeader';
import { CompetitionsFeedCreateButton } from 'containers/CompetitionsFeed/view/styled/CompetitionsFeedCreateButton';
import { CompetitionsFeedScrollable } from 'containers/CompetitionsFeed/view/styled/CompetitionsFeedScrollable';
import { CompetitionsFeedItems } from 'containers/CompetitionsFeed/view/styled/CompetitionsFeedItems';
import { CompetitionsFeedPreloader } from 'containers/CompetitionsFeed/view/styled/CompetitionsFeedPreloader';
import { CompetitionsFeedFallback } from 'containers/CompetitionsFeed/view/styled/CompetitionsFeedFallback';

type TProps = {
  children: React.ReactNode;
  hasCreateArticleButton: boolean;
  hasPreloader: boolean;
  hasEmptyFallback: boolean;
  isFilterDisabled: boolean;
  activeFilter: TCompetitionsFilter;
  filterOptions: TCompetitionsFeedFilterOption[];
  emptyFallbackText: string;
  redirectPath?: string;
  handleCreateArticle(): void;
  handleScroll(event: SyntheticEvent): void;
  handleFilterChange(nextFilter: TCompetitionsFilter): void;
};

export const CompetitionsFeedView: React.FC<TProps> = props => {
  const {
    children,
    activeFilter,
    redirectPath,
    filterOptions,
    isFilterDisabled,
    emptyFallbackText,
    handleCreateArticle,
    handleFilterChange,
    handleScroll,
    hasCreateArticleButton,
    hasPreloader,
    hasEmptyFallback
  } = props;

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <CompetitionsFeedWrapper>
      <CompetitionsFeedHeader>
        <CustomSelect
          options={filterOptions}
          selected={activeFilter}
          disabled={isFilterDisabled}
          onChange={handleFilterChange}
        />
        {hasCreateArticleButton && (
          <CompetitionsFeedCreateButton onClick={handleCreateArticle} />
        )}
      </CompetitionsFeedHeader>
      <CompetitionsFeedScrollable onScroll={handleScroll}>
        <CompetitionsFeedItems>
          {children}
          {hasPreloader && <CompetitionsFeedPreloader />}
          {hasEmptyFallback && (
            <CompetitionsFeedFallback text={emptyFallbackText} />
          )}
        </CompetitionsFeedItems>
      </CompetitionsFeedScrollable>
    </CompetitionsFeedWrapper>
  );
};
