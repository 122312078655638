import React from 'react';
import { useIntlContext } from '../../hooks/useIntlContext';
import './NoActiveSectionsPage.scss';

export const NoActiveSectionsPage = () => {
  const { noActiveSectionsPage: noActiveSectionsPageIntl } = useIntlContext();

  return (
    <div className={'noActiveSectionsContainer'}>
      <div className={'noActiveSectionsTitleLabel'}>
        {noActiveSectionsPageIntl.title}
      </div>
    </div>
  );
};
