import React from 'react';

import { useChatsState } from 'hooks/chats/useChatsState';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { ChatMessage } from 'types/chats';
import { createCollectionCacheSelectors } from 'utils/collectionCacheUtils';
import { useActiveChatRoom } from 'hooks/chats/useActiveChatRoom';

export const useActiveChatRoomMessages = (): ChatMessage[] => {
  const { chatMessagesCollection } = useChatsState();

  const { messageIdsList } = useActiveChatRoom();

  const messageIds = React.useMemo(() => {
    return createListCacheSelectors(messageIdsList).selectAllItems();
  }, [messageIdsList]);

  return React.useMemo(() => {
    return createCollectionCacheSelectors(chatMessagesCollection)
      .selectItemsStrict(messageIds)
      .sort((messageA, messageB) => {
        return (
          new Date(messageA.createdAt).getTime() -
          new Date(messageB.createdAt).getTime()
        );
      });
  }, [chatMessagesCollection, messageIds]);
};
