import React from 'react';

import styles from './AudienceInput.module.css';

import { Checkbox } from 'components/shared/Checkbox';
import { testId } from 'utils/testId';

type TProps = {
  label: string;
  value: string;
  isChecked: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

export const AudienceInputOption = ({
  label,
  value,
  isChecked,
  onChange
}: TProps) => (
  <label className={styles.AudienceInput__OptionLabel}>
    <div className={styles.AudienceInput__OptionText}>{label}</div>
    <Checkbox
      name="audience"
      value={value}
      className={styles.AudienceInput__OptionCheckbox}
      onChange={onChange}
      checked={isChecked}
      testId={testId.NEWS_AUDIENCE_INPUT_OPTION}
    />
  </label>
);
