import React from 'react';

import styles from './ArticleEditorFooter.module.css';

type Props = {
  children: React.ReactNode;
};

export const ArticleEditorFooterWrapper: React.FC<Props> = ({ children }) => {
  return <div className={styles.ArticleEditorFooter__Wrapper}>{children}</div>;
};
