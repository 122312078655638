import { TGetRestaurantApplicationsParams } from 'types/services';
import { TApplication } from 'types/state';
import { sportingAPI } from 'services/sporting/api';
import { normalizeSportingClaim } from 'services/sporting/utils/normalizeSportingClaim';

export const getRestaurantProcessedApplications = async (
  params: TGetRestaurantApplicationsParams
): Promise<TApplication[]> => {
  const { pageNumber } = params;

  const claims = await sportingAPI.service.getProcessedRestarauntClaims({
    payload: { pageNum: pageNumber }
  });

  return claims.map(claim =>
    normalizeSportingClaim.toApplication(claim, 'RESTAURANT_PROCESSED')
  );
};
