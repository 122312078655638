import React, { ClipboardEventHandler } from 'react';

import { moveCursorToTheEnd } from 'utils/moveCursorToTheEnd';
import { createBEMConstructor } from 'utils/createBEMConstructor';

import './contentEditable.scss';

type ContentEditableTheme = 'chat-message';

type Props = {
  theme?: ContentEditableTheme;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  onBlur?(): void;
  onKeyDown?(event: React.SyntheticEvent): void;
  onValueChange(nextValue: string): void;
};

export const ContentEditable = (props: Props) => {
  const {
    theme,
    value,
    placeholder,
    maxLength,
    autoFocus,
    disabled = false,
    onValueChange,
    onKeyDown,
    onBlur
  } = props;

  const bem = createBEMConstructor('contentEditable');

  const wrapperClassName = bem('wrapper');

  const inputClassName = bem('input', { theme, disabled });

  const contentEditableElementRef = React.useRef<HTMLDivElement>(null);

  const initialValueRef = React.useRef(value || '');

  const isNextValueValid = React.useCallback(
    (nextValue: string) => {
      if (maxLength) {
        return nextValue.length <= maxLength;
      }

      return true;
    },
    [maxLength]
  );

  const formatValue = React.useCallback(
    (value: string) => {
      if (maxLength) {
        return value.slice(0, maxLength);
      }

      return value;
    },
    [maxLength]
  );

  const handleInput = React.useCallback(
    (event: React.SyntheticEvent) => {
      if (event.currentTarget instanceof HTMLElement) {
        const nextValue = event.currentTarget.innerText;

        const valid = isNextValueValid(nextValue);

        if (valid) {
          onValueChange(nextValue);
        } else {
          event.currentTarget.innerText = value || '';
        }
      }
    },
    [isNextValueValid, onValueChange, value]
  );

  const handleBlur = React.useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = React.useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      const plaintext = event.clipboardData.getData('text/plain');

      document.execCommand('inserttext', false, plaintext);
    },
    []
  );

  React.useLayoutEffect(() => {
    const { current: contentEditableElement } = contentEditableElementRef;

    const { current: initalValue } = initialValueRef;

    if (contentEditableElement && initalValue) {
      contentEditableElement.innerText = formatValue(initalValue);
    }
  }, [formatValue]);

  // При useLayoutEffect курсор не ставится в конец
  // инпута в некоторых ситуациях.
  React.useEffect(() => {
    const { current: contentEditableElement } = contentEditableElementRef;

    if (contentEditableElement && autoFocus) {
      contentEditableElement.focus();

      moveCursorToTheEnd(contentEditableElement);
    }
  }, [autoFocus]);

  return (
    <div className={wrapperClassName}>
      <div
        ref={contentEditableElementRef}
        contentEditable={disabled === false}
        onInput={handleInput}
        onBlur={handleBlur}
        onPaste={handlePaste}
        onKeyDown={onKeyDown}
        data-placeholder={placeholder}
        className={inputClassName}
      />
    </div>
  );
};
