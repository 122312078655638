import { useAppState } from 'hooks/useAppState';

export const usePhoneNumbers = () => {
  const { phoneNumbers } = useAppState();

  return phoneNumbers;
};

export const usePhoneNumbersStrict = () => {
  const phoneNumbers = usePhoneNumbers();

  if (!phoneNumbers) {
    throw new TypeError();
  }

  return phoneNumbers;
};
