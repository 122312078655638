import React from 'react';

import styles from './ShadowContainer.module.css';

type TProps = {
  hasShadow: boolean;
  children: React.ReactNode;
  className?: string;
};

export const ShadowContainer = ({
  hasShadow,
  children,
  className = ''
}: TProps) => {
  return (
    <div
      className={`${styles.ShadowContainer__Wrapper} ${
        hasShadow ? styles.ShadowContainer__Wrapper_scrolled : ''
      } ${className}`}
    >
      {children}
    </div>
  );
};
