import React from 'react';

import styles from './CompetitionsArticle.module.css';

import {
  CustomTextarea,
  TCustomTextareaProps
} from 'components/shared/CustomTextarea';

interface TProps extends TCustomTextareaProps {
  name: 'description' | 'title';
}

export const CompetitionsArticleTextInput = (props: TProps) => (
  <CustomTextarea
    {...props}
    wrapperClassName={`${styles.CompetitionsArticle__TextInput} ${
      props.name === 'description'
        ? styles.CompetitionsArticle__TextInput_description
        : ''
    } ${
      props.name === 'title' ? styles.CompetitionsArticle__TextInput_title : ''
    }`}
  />
);
