import React from 'react';

import './animatedDots.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

export const AnimatedDots = () => {
  const className = createBEMConstructor('animated-dots')();

  return <span className={className} />;
};
