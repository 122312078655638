import { EmployeeEditorState, EmployeeFormValues } from '../../types/state';
import { createNewEmployeeFormValues } from './createNewEmployeeFormValues';

export const createEmployeeEditorState = (
  formValues: EmployeeFormValues | null
): EmployeeEditorState => ({
  formValues: formValues ? formValues : createNewEmployeeFormValues(),
  submitDidSucceed: false,
  submited: false
});
