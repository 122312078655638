import React from 'react';

import styles from './CompetitionsFeed.module.css';

import { ScrollableShadow } from 'components/shared/ScrollableShadow';

type TProps = {
  children: React.ReactNode;
  onScroll(e: React.SyntheticEvent): void;
};

export const CompetitionsFeedScrollable: React.FC<TProps> = ({
  children,
  onScroll
}) => {
  return (
    <ScrollableShadow
      threshold={30}
      className={styles.CompetitionsFeed__Scrollable}
      onScroll={onScroll}
    >
      {children}
    </ScrollableShadow>
  );
};
