import { TSubmitTimeLineParams } from '../../../types/services';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';
import { timeLinesStoreMock } from '../utils/timeLinesModel';

export const submitTimeline = async (
  params: TSubmitTimeLineParams
): Promise<void> => {
  const timeLine = timeLinesStoreMock
    .getAll()
    .find(timeLine => timeLine.location === params.location);

  if (timeLine) {
    timeLinesStoreMock.replaceById(timeLine.id, {
      ...params,
      id: timeLine.id
    });
  }

  await asyncTimeout(SERVICES_MOCK_DELAY);
};
