import React from 'react';

import { TNewsFilter, TNewsFeedFilterOption } from 'types/state';
import { authorizedRoutes } from 'utils/authorizedRoutes';
import { useIntlContext } from 'hooks/useIntlContext';
import { NewsFeedControlsView } from 'containers/NewsFeedControls/NewsFeedControlsView';

type Props = {
  activeFilter: TNewsFilter;
  isFilterDisabled: boolean;
  hasCreateArticleButton: boolean;
  onCreateArticle(): void;
};

export const NewsFeedControls = (props: Props) => {
  const {
    activeFilter,
    isFilterDisabled,
    hasCreateArticleButton,
    onCreateArticle
  } = props;

  const {
    newsFeedFilterAll,
    newsFeedFilterDrafts,
    newsFeedFilterPublished
  } = useIntlContext();

  const [selectedFilter, setSelectedFilter] = React.useState<TNewsFilter>(
    activeFilter
  );

  const handleCreateArticle = onCreateArticle;

  const handleFilterChange = React.useCallback((nextFilter: TNewsFilter) => {
    setSelectedFilter(nextFilter);
  }, []);

  const filterOptions = React.useMemo<TNewsFeedFilterOption[]>(
    () => [
      {
        value: 'all',
        label: newsFeedFilterAll
      },
      {
        value: 'published',
        label: newsFeedFilterPublished
      },
      {
        value: 'drafts',
        label: newsFeedFilterDrafts
      }
    ],
    [newsFeedFilterAll, newsFeedFilterPublished, newsFeedFilterDrafts]
  );

  const redirectPath = React.useMemo(() => {
    if (selectedFilter !== activeFilter) {
      return authorizedRoutes.news(selectedFilter);
    }
    return null;
  }, [selectedFilter, activeFilter]);

  const viewProps = {
    activeFilter,
    isFilterDisabled,
    redirectPath,
    filterOptions,
    handleCreateArticle,
    handleFilterChange,
    hasCreateArticleButton
  };

  return <NewsFeedControlsView {...viewProps} />;
};
