import React from 'react';

import { testId } from 'utils/testId';
import { ApplicationsTableWrapper } from 'components/ApplicationsTable/ApplicationsTableWrapper';
import { ApplicationsTableHeader } from 'components/ApplicationsTable/ApplicationsTableHeader';
import { ApplicationsTableBody } from 'components/ApplicationsTable/ApplicationsTableBody';
import { TApplicationsGroup } from 'types/state';
import { ApplicationsTableGroupContainer } from 'containers/ApplicationsTableGroup';
import { ApplicationsTableCell } from 'components/ApplicationsTable/ApplicationsTableCell';
import { ApplicationsPageWrapper } from 'pages/ApplicationsPage/styled/ApplicationsPageWrapper';
import { ApplicationsPagePreloader } from 'pages/ApplicationsPage/styled/ApplicationsPagePreloader';
import { useIntlContext } from 'hooks/useIntlContext';
import { useMediaFlags } from 'hooks/useMediaFlags';
import { ServiceScope } from 'types/serviceScope';

// TODO: перевести все используемые styled-компоненты ApplicationsTable*
// в домен ApplicationsPage* и перенести в папку ./styled

type TProps = {
  applicationNewGroups: TApplicationsGroup[];
  applicationProcessedGroups: TApplicationsGroup[];
  hasPreloader: boolean;
  domain: ServiceScope;
};

export const ApplicationsPageView = (props: TProps) => {
  const {
    applicationNewGroups,
    applicationProcessedGroups,
    hasPreloader,
    domain
  } = props;

  const {
    applicationsTableColumnCardNumber,
    applicationsTableColumnPhone,
    applicationsTableColumnName,
    applicationsTableColumnCreatedAtDate
  } = useIntlContext();

  const { isDesktopL, isDesktopXL } = useMediaFlags();

  const headerShowCondition = React.useMemo(() => isDesktopL || isDesktopXL, [
    isDesktopL,
    isDesktopXL
  ]);

  const handleGroupMap = React.useCallback(
    (group: TApplicationsGroup) => {
      if (group.applications && group.applications.length > 0) {
        return (
          <ApplicationsTableGroupContainer
            key={group.dayDateISO}
            group={group}
            domain={domain}
          />
        );
      }
      return null;
    },
    [domain]
  );

  const bodyContentNewNode = React.useMemo(() => {
    return applicationNewGroups.map(handleGroupMap);
  }, [applicationNewGroups, handleGroupMap]);

  const bodyContentProcessedNode = React.useMemo(() => {
    return applicationProcessedGroups.map(handleGroupMap);
  }, [applicationProcessedGroups, handleGroupMap]);

  return (
    <ApplicationsPageWrapper>
      <ApplicationsTableWrapper>
        {headerShowCondition && (
          <ApplicationsTableHeader
            testId={testId.APPLICATIONS_PAGE_TABLE_HEADER}
          >
            <ApplicationsTableCell>{''}</ApplicationsTableCell>
            <ApplicationsTableCell>
              {applicationsTableColumnCardNumber}
            </ApplicationsTableCell>
            <ApplicationsTableCell>
              {applicationsTableColumnPhone}
            </ApplicationsTableCell>
            <ApplicationsTableCell>
              {applicationsTableColumnName}
            </ApplicationsTableCell>
            <ApplicationsTableCell>
              {applicationsTableColumnCreatedAtDate}
            </ApplicationsTableCell>
          </ApplicationsTableHeader>
        )}
        <ApplicationsTableBody testId={testId.APPLICATIONS_PAGE_TABLE_BODY}>
          {bodyContentNewNode}
          {bodyContentProcessedNode}
          {hasPreloader && (
            <ApplicationsPagePreloader
              testId={testId.APPLICATIONS_PAGE_PRELOADER}
            />
          )}
        </ApplicationsTableBody>
      </ApplicationsTableWrapper>
    </ApplicationsPageWrapper>
  );
};
