import React from 'react';

import { useAppDispatch } from '../useAppDispatch';
import { useServices } from 'hooks/useServices';
import { useAsyncController } from '../useAsyncController';
import { AuthorizationError } from 'utils/errors';
import { UserInfo } from 'types/state';

export const useUsersPageDataService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const { wrapAsync: wrap, asyncProcess: process } = useAsyncController<
    UserInfo[]
  >();

  const loadEmployee = React.useCallback(() => {
    const promise = services.getEmployees().catch(error => {
      if (error instanceof AuthorizationError) {
        throw error;
      }

      return error;
    });

    wrap(promise);
  }, [services, wrap]);

  React.useEffect(() => {
    if (process.status === 'PENDING') {
      appDispatch({
        name: 'USERS_PAGE_DATA_SERVICE',
        status: 'PENDING'
      });
    }
    if (process.status === 'RESOLVED') {
      appDispatch({
        name: 'USERS_PAGE_DATA_SERVICE',
        status: 'RESOLVED',
        payload: process.value
      });
    }
    if (process.status === 'REJECTED') {
      appDispatch({
        name: 'USERS_PAGE_DATA_SERVICE',
        status: 'REJECTED',
        payload: process.error
      });
    }
  }, [appDispatch, process]);

  return { process, loadEmployee };
};
