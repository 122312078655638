import React from 'react';

import './BookingHeader.scss';
import { DateLine } from '../DateLine/DateLine';
import { RectangleButton } from '../shared/RectangleButton';
import { useIntlContext } from 'hooks/useIntlContext';
import { PlusIcon } from 'svg/20x20/plus';
import { BookingTimeWorkSettingsModal } from '../BookingTimeWorkSettingsModal/BookingTimeWorkSettingsModal';
import { BookingTableSettingsModal } from '../BookingTableSettingsModal/BookingTableSettingsModal';
import { Place, TimeLine } from '../../types/state';
import { GearIcon } from 'svg/22x22/gear';
import { TimeIcon } from 'svg/20x20/time';
import { ServiceScope } from 'types/serviceScope';

type Props = {
  location: ServiceScope;
  places: Place[];
  timeLine: null | TimeLine;
  onShowReservationModal(): void;
};
export const BookingHeader = (props: Props) => {
  const { bookingHeaderIntl: label } = useIntlContext();

  const { location, places, timeLine, onShowReservationModal } = props;

  const [isOpenedTableSettings, setOpenedTableSettings] = React.useState<
    boolean
  >(false);
  const [isOpenedTimeWorkSettings, setOpenedTimeWorkSettings] = React.useState<
    boolean
  >(false);

  const onShowTableSettings = React.useCallback((show: boolean) => {
    setOpenedTableSettings(show);
  }, []);

  const onShowTimeWorkSettings = React.useCallback((show: boolean) => {
    setOpenedTimeWorkSettings(show);
  }, []);

  return (
    <div className={'booking-header'}>
      <DateLine location={location} />
      <div className={'booking-header__btn-panel'}>
        <RectangleButton
          styleType={'primary'}
          className={'booking-header-btn add-booking-btn'}
          label={label.addBookingBtnLabel}
          onClick={onShowReservationModal}
          icon={<PlusIcon />}
        />
        <RectangleButton
          styleType={'secondary'}
          className={'booking-header-btn show-timeline-settings-btn'}
          label={''}
          onClick={() => onShowTableSettings(true)}
          icon={<GearIcon />}
        />
        <RectangleButton
          styleType={'secondary'}
          className={'booking-header-btn show-time-work-settings-btn'}
          label={''}
          onClick={() => onShowTimeWorkSettings(true)}
          icon={<TimeIcon />}
        />
      </div>
      {isOpenedTableSettings && (
        <BookingTableSettingsModal
          location={location}
          places={places}
          onClose={() => onShowTableSettings(false)}
        />
      )}
      {isOpenedTimeWorkSettings && (
        <BookingTimeWorkSettingsModal
          location={location}
          timeLine={timeLine}
          onClose={() => onShowTimeWorkSettings(false)}
        />
      )}
    </div>
  );
};
