import React from 'react';

import styles from './CompetitionParticipants.module.css';

type Props = {
  children: React.ReactNode;
};

export const CompetitionParticipantsWrapper: React.FC<Props> = ({
  children
}) => <div className={styles.CompetitionParticipants__Wrapper}>{children}</div>;
