import { AppEvent } from 'types/appEvent';

export const visibleReducer = (
  state: null | boolean,
  event: AppEvent
): null | boolean => {
  switch (event.name) {
    case 'DOCUMENT__VISIBILITY_CHANGE':
      return event.visible;
    default:
      return state;
  }
};
