import { SportingPlacesHaveOncomingResevationsResult } from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';
import { appendFormDataWithObjectValues } from 'utils/appendFormDataWithObjectValues';

type Params = {
  placeIds: number[];
};

export const haveOncomingReservations = async (
  params: Params
): Promise<SportingPlacesHaveOncomingResevationsResult> => {
  const endpointURL = getSportingBookingURL('haveOncomingReservations');

  const body = new FormData();

  appendFormDataWithObjectValues(body, params);

  const response = await customFetch(endpointURL, { method: 'POST', body });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  return response.json();
};
