export const testId = {
  GUEST_SCREEN: 'GUEST_SCREEN',
  AUTHORIZED_SCREEN: 'AUTHORIZED_SCREEN',

  SIGN_IN_USERNAME_INPUT: 'SIGN_IN_USERNAME_INPUT',
  SIGN_IN_PASSWORD_INPUT: 'SIGN_IN_PASSWORD_INPUT',
  SIGN_IN_SUBMIT_BUTTON: 'SIGN_IN_SUBMIT_BUTTON',
  SIGN_IN_SPINNER: 'SIGN_IN_SPINNER',
  SIGN_IN_ERROR: 'SIGN_IN_ERROR',

  NOTIFICATION_WRAPPER: 'NOTIFICATION_WRAPPER',
  NOTIFICATION_CLOSE_BUTTON: 'NOTIFICATION_CLOSE_BUTTON',
  NOTIFICATION_MESSAGE: 'NOTIFICATION_MESSAGE',

  NAVIGATION_SIGN_OUT_BUTTON: 'NAVIGATION_SIGN_OUT_BUTTON',
  NAVIGATION_WRAPPER: 'NOTIFICATION_WRAPPER',
  NAVIGATION_ITEM_NEWS: 'NAVIGATION_ITEM_NEWS',
  NAVIGATION_ITEM_COMPETITIONS: 'NAVIGATION_ITEM_COMPETITIONS',
  NAVIGATION_ITEM_FEEDBACK: 'NAVIGATION_ITEM_FEEDBACK',
  NAVIGATION_ITEM_SETTINGS: 'NAVIGATION_ITEM_SETTINGS',
  NAVIGATION_ITEM_MEMBERS: 'NAVIGATION_ITEM_MEMBERS',
  NAVIGATION_ITEM_USERS: 'NAVIGATION_ITEM_USERS',
  NAVIGATION_ITEM_APPLICATIONS_RESTAURANT:
    'NAVIGATION_ITEM_APPLICATIONS_RESTAURANT',
  NAVIGATION_ITEM_CHATS_RESTAURANT: 'NAVIGATION_ITEM_CHATS_RESTAURANT',
  NAVIGATION_ITEM_BOOKING_RESTAURANT: 'NAVIGATION_ITEM_BOOKING_RESTAURANT',
  NAVIGATION_ITEM_APPLICATIONS_SPORT: 'NAVIGATION_ITEM_APPLICATIONS_SPORT',
  NAVIGATION_ITEM_CHATS_SPORT: 'NAVIGATION_ITEM_CHATS_SPORT',
  NAVIGATION_ITEM_BOOKING_CLUB: 'NAVIGATION_ITEM_BOOKING_CLUB',
  NAVIGATION_BAGE_COMPETITIONS: 'NAVIGATION_BAGE_COMPETITIONS',
  NAVIGATION_BAGE_FEEDBACK: 'NAVIGATION_BAGE_FEEDBACK',
  NAVIGATION_BAGE_APPLICATIONS_RESTAURANT:
    'NAVIGATION_BAGE_APPLICATIONS_RESTAURANT',
  NAVIGATION_BAGE_CHATS_RESTAURANT: 'NAVIGATION_BAGE_CHATS_RESTAURANT',
  NAVIGATION_BAGE_BOOKING_RESTAURANT: 'NAVIGATION_BAGE_BOOKING_RESTAURANT',
  NAVIGATION_BAGE_APPLICATIONS_SPORT: 'NAVIGATION_BAGE_APPLICATIONS_SPORT',
  NAVIGATION_BAGE_CHATS_SPORT: 'NAVIGATION_BAGE_CHATS_SPORT',
  NAVIGATION_BAGE_BOOKING_CLUB: 'NAVIGATION_BAGE_BOOKING_CLUB',

  NEWS_FEED_PRELOADER: 'NEWS_FEED_PRELOADER',
  NEWS_FEED_CREATE_ARTICLE_BUTTON: 'NEWS_FEED_CREATE_ARTICLE_BUTTON',
  NEWS_PREVIEW_CREATE_ARTICLE_BUTTON: 'NEWS_PREVIEW_CREATE_ARTICLE_BUTTON',
  NEWS_ARTICLE_EDITOR: 'NEWS_ARTICLE_EDITOR',
  NEWS_ARTICLE_EDITOR_CANCEL_BUTTON: 'NEWS_ARTICLE_EDITOR_CANCEL_BUTTON',
  NEWS_ARTICLE_PREVIEW: 'NEWS_ARTICLE_PREVIEW',
  NEWS_AUDIENCE_INPUT_OPTION: 'NEWS_AUDIENCE_INPUT_OPTION',

  COMPETITIONS_FEED_PRELOADER: 'COMPETITIONS_FEED_PRELOADER',
  COMPETITIONS_FEED_FALLBACK: 'COMPETITIONS_FEED_FALLBACK',
  COMPETITIONS_FEED_CREATE_ARTICLE_BUTTON:
    'COMPETITIONS_FEED_CREATE_ARTICLE_BUTTON',
  COMPETITIONS_PREVIEW_CREATE_ARTICLE_BUTTON:
    'COMPETITIONS_PREVIEW_CREATE_ARTICLE_BUTTON',
  COMPETITIONS_ARTICLE_EDITOR: 'COMPETITIONS_ARTICLE_EDITOR',
  COMPETITIONS_ARTICLE_EDITOR_CANCEL_BUTTON:
    'COMPETITIONS_ARTICLE_EDITOR_CANCEL_BUTTON',
  COMPETITIONS_ARTICLE_PREVIEW: 'COMPETITIONS_ARTICLE_PREVIEW',

  APPLICATIONS_PAGE_TABLE_HEADER: 'APPLICATIONS_PAGE_TABLE_HEADER',
  APPLICATIONS_PAGE_TABLE_BODY: 'APPLICATIONS_PAGE_TABLE_BODY',
  APPLICATIONS_PAGE_PRELOADER: 'APPLICATIONS_PAGE_PRELOADER',
  APPLICATIONS_PAGE_TABLE_GROUP: 'APPLICATIONS_PAGE_TABLE_GROUP',
  APPLICATIONS_PAGE_TABLE_GROUP_PROCESSED:
    'APPLICATIONS_PAGE_TABLE_GROUP_PROCESSED',
  APPLICATIONS_PAGE_TABLE_GROUP_NEW: 'APPLICATIONS_PAGE_TABLE_GROUP_NEW',
  APPLICATIONS_PAGE_TABLE_GROUP_TITLE: 'APPLICATIONS_PAGE_TABLE_GROUP_TITLE',
  APPLICATIONS_PAGE_TABLE_GROUP_FALLBACK:
    'APPLICATIONS_PAGE_TABLE_GROUP_FALLBACK',

  REVIEWS_PAGE_PRELOADER: 'REVIEWS_PAGE_PRELOADER',
  REVIEWS_PAGE_FALLBACK: 'REVIEWS_PAGE_FALLBACK',
  REVIEWS_FEED_TITLE: 'REVIEWS_FEED_TITLE',
  REVIEWS_FEED_PRELOADER: 'REVIEWS_FEED_PRELOADER',
  REVIEW_ITEM: 'REVIEW_ITEM',
  REVIEW_NOTES_INPUT: 'REVIEW_NOTES_INPUT',
  REVIEW_NOTES_TITLE: 'REVIEW_NOTES_TITLE',
  REVIEW_PREVIEW: 'REVIEW_PREVIEW',
  REVIEW_PREVIEW_TITLE: 'REVIEW_PREVIEW_TITLE',
  REVIEW_PREVIEW_DATE: 'REVIEW_PREVIEW_DATE',
  REVIEW_PREVIEW_ORDER_NUMBER: 'REVIEW_PREVIEW_ORDER_NUMBER',
  REVIEW_PREVIEW_NAME: 'REVIEW_PREVIEW_NAME',
  REVIEW_PREVIEW_PHONE: 'REVIEW_PREVIEW_PHONE',
  REVIEW_PREVIEW_EMAIL: 'REVIEW_PREVIEW_EMAIL',
  REVIEW_PREVIEW_MESSAGE: 'REVIEW_PREVIEW_MESSAGE',
  REVIEW_PREVIEW_FOOD_SCORE: 'REVIEW_PREVIEW_FOOD_SCORE',
  REVIEW_PREVIEW_SERVICE_SCORE: 'REVIEW_PREVIEW_SERVICE_SCORE',

  REVIEW_PREVIEW_SELECT: 'REVIEW_PREVIEW_SELECT',
  REVIEW_PREVIEW_SELECT_OPTION_NEW: 'REVIEW_PREVIEW_SELECT_OPTION_NEW',
  REVIEW_PREVIEW_SELECT_OPTION_PROCESSED:
    'REVIEW_PREVIEW_SELECT_OPTION_PROCESSED',

  CHATS_PAGE__ROOM_WRAPPER: 'CHATS_PAGE__ROOM_WRAPPER',
  CHATS_PAGE_NAVIGATION__PRELOADER: 'CHATS_PAGE_NAVIGATION__PRELOADER',
  CHATS_PAGE_NAVIGATION__FALLBACK_TEXT: 'CHATS_PAGE_NAVIGATION__FALLBACK_TEXT'
};
