import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useDeletedUserId } from 'hooks/useDeletedUserId';
import { useAsyncController } from './useAsyncController';
import { AuthorizationError } from 'utils/errors';
import { DeleteEmployeeParams } from 'types/services';

export const useDeleteUserService = () => {
  const appDispatch = useAppDispatch();

  const deletedUserId = useDeletedUserId();

  const services = useServices();

  const { wrapAsync: wrapDeletion } = useAsyncController();

  const deleteUser = React.useCallback(
    (params: DeleteEmployeeParams) => {
      appDispatch({
        name: 'DELETE_USER_SERVICE',
        status: 'PENDING',
        meta: params.userId
      });

      const promise = services
        .deleteEmployee(params)
        .then(() => {
          appDispatch({
            name: 'DELETE_USER_SERVICE',
            status: 'RESOLVED',
            payload: undefined,
            meta: params.userId
          });
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }

          appDispatch({
            name: 'DELETE_USER_SERVICE',
            status: 'REJECTED',
            payload: error,
            meta: params.userId
          });
        });

      wrapDeletion(promise);
    },
    [appDispatch, services, wrapDeletion]
  );

  React.useEffect(() => {
    if (deletedUserId !== undefined) {
      deleteUser({
        userId: deletedUserId
      });
    }
  }, [deleteUser, deletedUserId]);
};
