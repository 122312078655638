import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { UserInfo } from 'types/state';

export const getEmployees = async (): Promise<UserInfo[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return [
    {
      id: 1,
      lastName: 'Овчинник',
      firstName: 'Иван',
      phoneNumber: 'test1',
      login: 'superadmin',
      role: 'ADMINISTRATOR',
      blocked: false,
      enabledSections: []
    },

    {
      id: 2,
      lastName: 'Крутов',
      firstName: 'Михаил',
      phoneNumber: 'test1',
      login: 'admin',
      role: 'MANAGER',
      blocked: false,
      enabledSections: []
    },
    {
      id: 3,
      lastName: 'Ершов',
      firstName: 'Владислав',
      phoneNumber: '',
      login: 'admin2',
      role: 'MANAGER',
      blocked: true,
      enabledSections: []
    },
    {
      id: 4,
      lastName: 'Смирнова',
      firstName: 'Екатерина',
      phoneNumber: 'test2',
      login: 'admin3',
      role: 'MANAGER',
      blocked: false,
      enabledSections: []
    },

    {
      id: 5,
      lastName: 'Ерохов',
      firstName: 'Александр',
      phoneNumber: '',
      login: 'curator',
      role: 'CURATOR',
      blocked: false,
      enabledSections: []
    },
    {
      id: 6,
      lastName: 'Курочкина',
      firstName: 'Мария',
      phoneNumber: '',
      login: 'curator2',
      role: 'CURATOR',
      blocked: false,
      enabledSections: []
    },
    {
      id: 7,
      lastName: 'Самойлова',
      firstName: 'Елизовета',
      phoneNumber: '',
      login: 'curator3',
      role: 'CURATOR',
      blocked: true,
      enabledSections: []
    },
    {
      id: 8,
      lastName: 'Смирнов',
      firstName: 'Алексей',
      phoneNumber: '',
      login: 'curator4',
      role: 'CURATOR',
      blocked: false,
      enabledSections: []
    }
  ];
};
