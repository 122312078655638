import { TUpdateReservationParams } from '../../../types/services';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';
import { reservationsStoreMock } from '../utils/reservationsModel';

export const updateReservation = async (
  params: TUpdateReservationParams
): Promise<void> => {
  const { formValues } = params;

  reservationsStoreMock.replaceById(formValues.id, {
    ...formValues,
    places: formValues.placeItems
  });

  await asyncTimeout(SERVICES_MOCK_DELAY);
};
