import React from 'react';

import { EVENT_ON_LINE, EVENT_OFF_LINE } from 'utils/constants';
import { useWindowEvent } from 'hooks/useWindowEvent';
import { useAppDispatch } from 'hooks/useAppDispatch';

export const useConnectionStatusWatcher = () => {
  const dispatch = useAppDispatch();

  const handleOnLine = React.useCallback(() => {
    dispatch({
      name: 'WINDOW__ONLINE'
    });
  }, [dispatch]);

  const handleOffLine = () => {
    dispatch({
      name: 'WINDOW__OFFLINE'
    });
  };

  useWindowEvent(EVENT_ON_LINE, handleOnLine);
  useWindowEvent(EVENT_OFF_LINE, handleOffLine);
};
