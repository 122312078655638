import React from 'react';

import { useBrowserNotificationCreator } from 'hooks/useBrowserNotificationCreator';
import { useActualToast } from 'hooks/useActualToast';
import { Toast as ToastType } from 'types/toast';
import { useToastNotificationSound } from 'hooks/useToastNotificationSound';
import { useAppState } from 'hooks/useAppState';
import { ToastComponent } from 'design/toast/toastComponents';
import { Text } from 'design/text';

export const ToastsManager = () => {
  useToastNotificationSound();

  const { visible } = useAppState();

  // Ссылка на последнее отработанное уведомление.
  // Используется для того, чтобы не показывать одно
  // и то же уведомление дважды при фокусе страницы.
  const latestToastRef = React.useRef<null | ToastType>(null);

  // Состояние уведомления, которое нужно показать при
  // открытой странице.
  const [pageToast, setPageToast] = React.useState<null | ToastType>(null);

  // Подключает сервис браузерныx уведомлений.
  const createBrowserNotification = useBrowserNotificationCreator();

  // Подключает очередь уведомлений.
  const nextToast = useActualToast();

  // Эффект определяет будет показано браузерное уведомление
  // или уведомление на странице.
  React.useEffect(() => {
    if (latestToastRef.current !== nextToast) {
      latestToastRef.current = nextToast;

      if (nextToast && !visible) {
        createBrowserNotification(nextToast);
      }

      if (visible) {
        setPageToast(nextToast);
      }
    }
  }, [nextToast, createBrowserNotification, visible]);

  if (pageToast) {
    return (
      <ToastComponent
        onBodyAreaClick={pageToast.onClick}
        onCloseAreaClick={pageToast.onClose}
        title={<Text weight="semi-bold">{pageToast.title}</Text>}
        description={<Text size="s">{pageToast.description}</Text>}
      />
    );
  }

  return null;
};
