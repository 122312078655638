import { TUpdateUserPasswordParams } from 'types/services';
import { UnexpectedError } from 'utils/errors';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';

const UNEXPECTED_ERROR_PASSWORD = 'unexpected';

export const updateUserPassword = async (params: TUpdateUserPasswordParams) => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  if (params.nextPassword === UNEXPECTED_ERROR_PASSWORD) {
    throw new UnexpectedError();
  }
};
