import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { useUnreadChatIdLists } from 'hooks/chats/useChatsState';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { useAsyncController } from 'hooks/useAsyncController';

export const useUnreadRestaurantChatIdsUpdateProcess = () => {
  const { restaurantIds: targetList } = useUnreadChatIdLists();

  const dispatch = useAppDispatch();

  const sections = useEnabledSectionsStrict();

  const nextInvalidatedPage = createListCacheSelectors(
    targetList
  ).selectNextInvalidatedPage();

  const serviceCallAllowed = sections.includes('chatsRestaurant');

  const { asyncProcess, wrapAsync } = useAsyncController<number[]>();

  const { getUnreadChatIds: getUnreadChatIdsService } = useServices();

  React.useEffect(() => {
    if (asyncProcess.status === 'RESOLVED') {
      dispatch({
        name: 'UNREAD_CHAT_IDS_SERVICE',
        status: 'RESOLVED',
        payload: asyncProcess.value,
        meta: { domain: 'RESTAURANT' }
      });
    }
  }, [asyncProcess, dispatch]);

  React.useEffect(() => {
    if (serviceCallAllowed && nextInvalidatedPage !== null) {
      const promise = getUnreadChatIdsService({ domain: 'RESTAURANT' });

      wrapAsync(promise);
    }
  }, [
    getUnreadChatIdsService,
    nextInvalidatedPage,
    serviceCallAllowed,
    wrapAsync
  ]);
};
