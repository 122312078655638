import React from 'react';

import { useBrowserTabTitleUpdater } from 'hooks/useBrowserTabTitleUpdater';
import { UsersPageWrapper } from './components/UsersPageWrapper';
import { EmployeeList } from 'components/EmployeeList/EmployeeList';
import { useUsersPageDataService } from 'hooks/employee/useUsersPageDataService';
import { UserInfo, UserRole } from 'types/state';
import { createUserForCreate } from 'utils/employee/createUserForCreate';
import { getAvailableRoleForEdit } from 'utils/employee/getAvailableRole';
import { useEmployeeEditorService } from 'hooks/employee/useEmployeeEditorService';
import { useUserToBlockedStateService } from 'hooks/employee/useUserToBlockedStateService';
import { useDeleteUserService } from 'hooks/useDeleteUserService';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useUserInfoStrict } from 'hooks/useUserInfoCache';
import { useUpdateUserPhoneNumberService } from 'hooks/employee/useUpdateUserPhoneNumberService';
import { usePhoneNumbersService } from 'hooks/usePhoneNumbersService';
import { useUsersCache } from '../../hooks/employee/useUsersCache';

/**
 * Страница "Пользователи"
 */
export const UsersPage = () => {
  useBrowserTabTitleUpdater();
  usePhoneNumbersService();
  useEmployeeEditorService();
  useUserToBlockedStateService();
  useDeleteUserService();

  const appDispatch = useAppDispatch();

  const currentUserInfo = useUserInfoStrict();

  const { loadEmployee, process } = useUsersPageDataService();

  // загрузка пользователей
  React.useEffect(() => {
    loadEmployee();
  }, [loadEmployee]);

  // Обработка создания пользователя
  const onShowCreateEmployeeModal = React.useCallback(
    (role: UserRole) => {
      const userForCreate = createUserForCreate(role);
      appDispatch({
        name: 'EMPLOYEE_ITEM__EDIT',
        user: userForCreate
      });
    },
    [appDispatch]
  );

  // Обработка изменения номера телефона пользователя
  const { setUserPhoneNumber } = useUpdateUserPhoneNumberService();
  const onChangeItemPhoneNumber = React.useCallback(
    (userId: number, phoneNumber: string) => {
      setUserPhoneNumber({ userId: userId, phoneNumber: phoneNumber });
    },
    [setUserPhoneNumber]
  );

  // Обработка редактирования пользователя
  const onShowEditEmployeeModal = React.useCallback(
    (user: UserInfo) => {
      appDispatch({
        name: 'EMPLOYEE_ITEM__EDIT',
        user: user
      });
    },
    [appDispatch]
  );

  // Обработка блокировки пользователя
  const onShowBlockedEmployeeModal = React.useCallback(
    (user: UserInfo) => {
      appDispatch({
        name: 'EMPLOYEE_ITEM__BLOCKED',
        userId: user.id,
        blockedState: user.blocked
      });
    },
    [appDispatch]
  );

  // Обработка удаления пользователя
  const onShowDeleteEmployeeModal = React.useCallback(
    (userId: number) => {
      appDispatch({
        name: 'USER_FORM__DELETE',
        userId: userId
      });
    },
    [appDispatch]
  );

  const usersCache = useUsersCache();
  const users = React.useMemo(() => Array.from(usersCache.byId.values()), [
    usersCache
  ]);

  const availableRoleForEdit = React.useMemo(
    () => getAvailableRoleForEdit(currentUserInfo.role),
    [currentUserInfo.role]
  );

  const isEditAllowed = React.useCallback(
    (role: UserRole) => availableRoleForEdit.includes(role),
    [availableRoleForEdit]
  );

  const usersLoadedAndNotEmpty =
    process.status === 'RESOLVED' && process.value.length > 0;

  return (
    <UsersPageWrapper>
      {usersLoadedAndNotEmpty && (
        <EmployeeList
          users={users}
          isEditable={isEditAllowed}
          onCreateItem={onShowCreateEmployeeModal}
          onChangeItemPhoneNumber={onChangeItemPhoneNumber}
          onEditItem={onShowEditEmployeeModal}
          onBlockItem={onShowBlockedEmployeeModal}
          onDeleteItem={onShowDeleteEmployeeModal}
        />
      )}
    </UsersPageWrapper>
  );
};
