import { TUpdateCurrentUserLogin } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { UnexpectedError } from 'utils/errors';

const UNEXPECTED_ERROR_PASSWORD = 'unexpected';

export const updateCurrentUserLogin = async (
  params: TUpdateCurrentUserLogin
) => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  if (params.password === UNEXPECTED_ERROR_PASSWORD) {
    throw new UnexpectedError();
  }
};
