type AsyncProcessIdle = {
  status: 'IDLE';
};

type AsyncProcessPending = {
  status: 'PENDING';
};

type AsyncProcessRejected = {
  status: 'REJECTED';
  error: Error;
};

type AsyncProcessResolved<ValueType> = {
  status: 'RESOLVED';
  value: ValueType;
};

export type AsyncProcess<ValueType> =
  | AsyncProcessIdle
  | AsyncProcessPending
  | AsyncProcessRejected
  | AsyncProcessResolved<ValueType>;

export type AsyncProcessConstructor<ValueType> = {
  toPending(): AsyncProcess<ValueType>;
  toRejected(error: Error): AsyncProcess<ValueType>;
  toResolved(value: ValueType): AsyncProcess<ValueType>;
};

export const createAsyncProcess = <ValueType>(): AsyncProcess<ValueType> => ({
  status: 'IDLE'
});

export const createAsyncProcessConstructor = <ValueType>(
  asyncProcess: AsyncProcess<ValueType>
): AsyncProcessConstructor<ValueType> => ({
  toPending() {
    if (asyncProcess.status === 'IDLE' || asyncProcess.status === 'REJECTED') {
      return {
        status: 'PENDING'
      };
    }

    throw new Error(`Invalid async process status: ${asyncProcess.status}.`);
  },
  toRejected(error) {
    if (asyncProcess.status === 'PENDING') {
      return {
        status: 'REJECTED',
        error
      };
    }

    throw new Error(`Invalid async process status: ${asyncProcess.status}.`);
  },
  toResolved(value) {
    if (asyncProcess.status === 'PENDING') {
      return {
        status: 'RESOLVED',
        value
      };
    }

    throw new Error(`Invalid async process status: ${asyncProcess.status}.`);
  }
});
