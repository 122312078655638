import React from 'react';

import './employeeItemBtn.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

type Accent = 'critical' | 'default';

type Props = {
  accent?: Accent;
  hoverAccent?: Accent;
  icon: React.ReactNode;
  onClick?: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

export const EmployeeItemBtn = (props: Props) => {
  const { icon, accent = 'default', hoverAccent = 'default' } = props;

  const bem = createBEMConstructor('employeeItemBtn');

  const wrapperClassName = bem('wrapper', { accent, hoverAccent });
  const iconClassName = bem('icon', { accent, hoverAccent });

  return (
    <div
      className={wrapperClassName}
      onClick={props.onClick}
      {...props.dragHandleProps}
    >
      <span className={iconClassName}>{icon}</span>
    </div>
  );
};
