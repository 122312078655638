import React from 'react';
import { useAppState } from 'hooks/useAppState';
import { useTimeLinesService } from './useTimeLinesService';

export function useBookingTimeLinesLoadingProcess() {
  const { loadTimeLines } = useTimeLinesService();

  const { timeLinesCache } = useAppState();

  React.useEffect(() => {
    if (timeLinesCache.didInvalidate) {
      loadTimeLines();
    }
  }, [loadTimeLines, timeLinesCache.didInvalidate]);
}
