import { EmployeeEditorState } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { createEmployeeEditorState } from 'utils/employee/createEmployeeEditorState';
import { patchEmployeeEditorState } from 'utils/employee/pathEmployeeEditorState';
import { validateEmployeeFormValues } from 'utils/employee/validateEmployeeFormValues';
import { getEmployeeEditorFormErrorFromServiceError } from './getEmployeeEditorFormErrorFromServiceError';

export const employeeEditorStateReducer = (
  state: EmployeeEditorState,
  event: AppEvent
): EmployeeEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return createEmployeeEditorState(null);
    }
    case 'EMPLOYEE_EDIT_MODAL__SUBMIT': {
      const employeeEditorState = createEmployeeEditorState(event.value);

      let nextEmployeeEditorState = patchEmployeeEditorState(
        employeeEditorState
      );

      const formError = validateEmployeeFormValues(
        employeeEditorState.formValues
      );

      if (formError) {
        nextEmployeeEditorState = nextEmployeeEditorState.setFormError(
          formError
        );

        if (formError.includes('LOGIN_IN_USE')) {
          nextEmployeeEditorState = nextEmployeeEditorState.setNextLogin('');
        }
        if (formError.includes('PASSWORDS_DID_NOT_MATCH')) {
          nextEmployeeEditorState = nextEmployeeEditorState
            .setNextPassword('')
            .setNextPasswordConfirm('');
        }
      } else {
        nextEmployeeEditorState = nextEmployeeEditorState.setSubmited(true);
      }

      return nextEmployeeEditorState.done();
    }
    case 'EMPLOYEE_EDIT_MODAL__CANCEL': {
      return createEmployeeEditorState(null);
    }
    case 'EMPLOYEE_EDIT_SERVICE': {
      if (event.status === 'PENDING') {
        return state;
      }
      if (event.status === 'RESOLVED') {
        return {
          ...state,
          submited: false,
          submitDidSucceed: true
        };
      }
      if (event.status === 'REJECTED') {
        const error = getEmployeeEditorFormErrorFromServiceError(event.payload);
        return {
          ...state,
          formError: [error],
          submited: false,
          submitDidSucceed: false
        };
      }
      return state;
    }

    default:
      return state;
  }
};
