import React from 'react';

import styles from './CompetitionsFeed.module.css';

import { testId } from 'utils/testId';

type TProps = {
  text: string;
};

export const CompetitionsFeedFallback = ({ text }: TProps) => (
  <div
    data-testid={testId.COMPETITIONS_FEED_FALLBACK}
    className={styles.CompetitionsFeed__Fallback}
  >
    {text}
  </div>
);
