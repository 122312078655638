import React from 'react';

import styles from './SignInPage.module.css';

import { TSignInFormValues } from 'types/state';
import { CustomInput } from 'components/shared/CustomInput';

type TProps = {
  name: keyof TSignInFormValues;
  type: 'text' | 'password';
  placeholder: string;
  value: string;
  testId: string;
  isDisabled: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

export const SignInPageInput = (props: TProps) => (
  <CustomInput {...props} className={styles.SignInPage__Input} />
);
