import React from 'react';

import styles from './ArticlePreviewContent.module.css';

type TProps = {
  children: React.ReactNode;
  active: boolean;
};

export const ArticlePreviewContentShadow = ({ children, active }: TProps) => {
  return (
    <div
      className={`${styles.ArticlePreviewContent__Shadow} ${
        active ? styles.ArticlePreviewContent__Shadow_active : ''
      }`}
    >
      {children}
    </div>
  );
};
