import { Cache } from '../../types/util';
import { TimeLine } from '../../types/state';
import { AppEvent } from '../../types/appEvent';
import { createCache, modifyCache } from 'utils/createCache';

export const timeLinesCacheReducer = (
  state: Cache<TimeLine[]>,
  event: AppEvent
): Cache<TimeLine[]> => {
  switch (event.name) {
    case 'TimeLinesRecieved':
      return createCache(event.timeLines);
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return modifyCache(state)
        .invalidate()
        .return();
    }
    case 'BOOKING_TIME_WORK_FORM_SUBMITTED': {
      return modifyCache(state)
        .invalidate()
        .return();
    }
    default:
      return state;
  }
};
