import { MediaQueryType } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { getMediaQueryType } from 'utils/mediaQueryUtils';

export const mediaQueryTypeReducer = (
  state: MediaQueryType,
  event: AppEvent
): MediaQueryType => {
  switch (event.name) {
    case 'WINDOW__RESIZE': {
      const { payload } = event;

      const nextMediaQueryType = getMediaQueryType(payload);

      return nextMediaQueryType;
    }
    default:
      return state;
  }
};
