import React from 'react';

import styles from './CompetitionParticipants.module.css';

type TProps = {
  label: string;
};

export const CompetitionParticipantsHeaderCell = ({ label }: TProps) => {
  return (
    <th className={styles.CompetitionParticipants__HeaderCell}>{label}</th>
  );
};
