import { AppEvent } from 'types/appEvent';
import { ToastCandidate } from 'types/toast';
import { AppState } from 'types/appState';
import { createToastCandidate } from 'utils/createToastCandidate';

export const toastCandidatesReducer = (
  state: ToastCandidate[],
  event: AppEvent,
  appState: AppState
): ToastCandidate[] => {
  switch (event.name) {
    case 'TOAST__CLOSE':
    case 'TOAST__CLICK': {
      return state.filter((toast, index) => index !== 0);
    }
    case 'NEW_APPLICATIONS_COUNT_SERVICE': {
      if (event.status === 'RESOLVED') {
        if (event.meta === 'RESTAURANT') {
          const { newRestaurantApplicationsCount: prevCount } = appState;

          if (prevCount.value < event.payload) {
            const newToast = createToastCandidate({
              name: 'RESTAURANT_NEW_APPLICATIONS'
            });

            return [...state, newToast];
          }
        }

        if (event.meta === 'CLUB') {
          const { newSportApplicationsCount: prevCount } = appState;

          if (prevCount.value < event.payload) {
            const newToast = createToastCandidate({
              name: 'SPORT_NEW_APPLICATIONS'
            });

            return [...state, newToast];
          }
        }
      }

      return state;
    }

    case 'CHAT_MESSAGE_RECIEVED': {
      const {
        visible,
        chatsState: { activeChatRoom },
        userInfoCache: { value: userInfo }
      } = appState;

      const activeRoom = event.roomId === activeChatRoom.id;

      const restaurantChatEnabled = !!userInfo?.enabledSections.includes(
        'chatsRestaurant'
      );

      const sportChatEnabled = !!userInfo?.enabledSections.includes(
        'chatsSport'
      );

      if (!activeRoom || !visible) {
        if (event.domain === 'RESTAURANT' && restaurantChatEnabled) {
          const newToast = createToastCandidate({
            name: 'RESTAURANT_NEW_CHAT_MESSAGE',
            roomId: event.roomId
          });

          return [...state, newToast];
        }

        if (event.domain === 'CLUB' && sportChatEnabled) {
          const newToast = createToastCandidate({
            name: 'SPORT_NEW_CHAT_MESSAGE',
            roomId: event.roomId
          });

          return [...state, newToast];
        }
      }

      return state;
    }

    case 'CHATS_PAGE__CONTENT_DID_MOUNT': {
      const nextState = state.filter(toast => {
        if (
          toast.name === 'RESTAURANT_NEW_CHAT_MESSAGE' ||
          toast.name === 'SPORT_NEW_CHAT_MESSAGE'
        ) {
          return toast.roomId !== event.roomId;
        }

        return false;
      });

      if (nextState.length === state.length) {
        return state;
      }

      return nextState;
    }
    default:
      return state;
  }
};
