import React, { SyntheticEvent } from 'react';

import styles from './CustomDatePicker.module.css';

const MINIMAL_HEIGHT_TO_PRELOAD = 100;

type TProps = {
  children: React.ReactNode;
  className?: string;
  onMoveBack(): void;
  onMoveForward(): void;
};

export const CustomDatePickerWrapper: React.FC<TProps> = ({
  onMoveBack,
  onMoveForward,
  children,
  className = ''
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const handleScroll = React.useCallback(
    (event: SyntheticEvent) => {
      const { currentTarget, target } = event;
      if (currentTarget === target) {
        const { scrollTop, scrollHeight, clientHeight } = currentTarget;

        /**
         * При быстром скроллинге вверх scrollTop доходит до ноля и стопорится,
         * поддержание scrollTop на 1 помогает.
         */
        if (scrollTop === 0) {
          currentTarget.scrollTop = 1;
        }

        // Количество пикселей, которое осталось прокрутить
        // чтобы достичь верхней границы содержимого.
        const leftTillTop = scrollTop;

        if (leftTillTop < MINIMAL_HEIGHT_TO_PRELOAD) {
          onMoveBack();
        }

        // Количество пикселей, которое осталось прокрутить
        // чтобы достичь нижней границы содержимого.
        const leftTillBottom = scrollHeight - (clientHeight + scrollTop);

        if (leftTillBottom < MINIMAL_HEIGHT_TO_PRELOAD) {
          onMoveForward();
        }
      }
    },
    [onMoveBack, onMoveForward]
  );

  // Производится прокрутка на один месяц вперед, чтобы сразу
  // отображался текущий месяц.
  React.useLayoutEffect(() => {
    const { current: wrapperNode } = wrapperRef;
    if (wrapperNode) {
      wrapperNode.scrollTop = wrapperNode.clientHeight / 2;
    }
  }, []);

  return (
    <div
      onScroll={handleScroll}
      ref={wrapperRef}
      className={`${styles.CustomDatePicker__Wrapper} ${className}`}
    >
      {children}
    </div>
  );
};
