import React from 'react';

import { useEditChatMessageForm } from 'hooks/chats/useEditChatMessageForm';
import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

export const useEditChatMessageService = () => {
  const dispatch = useAppDispatch();

  const editChatMessageForm = useEditChatMessageForm();

  const { editChatMessage } = useServices();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    if (editChatMessageForm && editChatMessageForm.status === 'SUBMITTED') {
      const params = {
        messageId: editChatMessageForm.messageId,
        text: editChatMessageForm.text
      };

      const promise = editChatMessage(params).then(() => {
        dispatch({
          name: 'CHAT_MESSAGE_EDIT_SERVICE',
          status: 'RESOLVED',
          payload: undefined,
          meta: params
        });
      });

      wrapAsyncSafely(promise);
    }
  }, [dispatch, editChatMessage, editChatMessageForm, wrapAsyncSafely]);
};
