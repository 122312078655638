import React from 'react';

import { useServices } from 'hooks/useServices';
import { usePasswordEditorState } from 'hooks/usePasswordEditorState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from './useAsyncController';
import { AuthorizationError } from 'utils/errors';

export const useUpdateCurrentUserPasswordService = () => {
  const services = useServices();

  const dispatch = useAppDispatch();

  const { formValues, submited } = usePasswordEditorState();

  const {
    wrapAsync: wrapPasswordUpdate,
    asyncProcess: passwordUpdateProcess
  } = useAsyncController<void>();

  React.useEffect(() => {
    if (submited) {
      const promise = services
        .updateCurrentUserPassword({
          nextPassword: formValues.password
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }

          return error;
        });

      wrapPasswordUpdate(promise);
    }
  }, [formValues.password, services, submited, wrapPasswordUpdate]);

  React.useEffect(() => {
    if (passwordUpdateProcess.status === 'PENDING') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_PASSWORD_SERVICE',
        status: 'PENDING'
      });
    }
    if (passwordUpdateProcess.status === 'RESOLVED') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_PASSWORD_SERVICE',
        status: 'RESOLVED',
        payload: passwordUpdateProcess.value
      });
    }
    if (passwordUpdateProcess.status === 'REJECTED') {
      dispatch({
        name: 'UPDATE_CURRENT_USER_PASSWORD_SERVICE',
        status: 'REJECTED',
        payload: passwordUpdateProcess.error
      });
    }
  }, [dispatch, passwordUpdateProcess]);
};
