import React from 'react';

import './text.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type TextColor = 'primary' | 'accent' | 'secondary' | 'critical';

type TextSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';

type Props = {
  children: React.ReactNode;
  color?: TextColor;
  size?: TextSize;
  /**
   * @deprecated Используйте weight.
   */
  bold?: boolean;
  weight?: 'regular' | 'semi-bold' | 'bold';
  uppercase?: boolean;
  testId?: string;
};

export const Text = (props: Props) => {
  const {
    children,
    color = 'primary',
    size = 'm',
    bold = false,
    weight,
    uppercase = false,
    testId
  } = props;

  const className = createBEMConstructor('text')({
    color,
    size,
    bold,
    uppercase,
    weight
  });

  return (
    <span data-testid={testId} className={className}>
      {children}
    </span>
  );
};
