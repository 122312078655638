import React from 'react';

import styles from './calendarPeriodInputLayout.module.scss';

type Props = {
  dateInput: React.ReactNode;
  timeInput: React.ReactNode;
};

export const CalendarPeriodInputLayoutComponent = (props: Props) => {
  const { dateInput, timeInput } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.dateInputSlot}>{dateInput}</div>
      <div className={styles.timeInputSlot}>{timeInput}</div>
    </div>
  );
};
