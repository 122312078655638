import { TApplicationStatus } from 'types/state';
import { TSportingClaimStatusId } from 'services/sporting/types';
import { UnreachableError } from 'utils/errors';

export const normalizeApplicationStatus = {
  toSportingClaimStatusId(status: TApplicationStatus): TSportingClaimStatusId {
    switch (status) {
      case 'NEW':
        return 1;
      case 'DONE':
        return 2;
      default:
        throw new UnreachableError(status);
    }
  }
};
