import React from 'react';

import styles from './ReviewPreview.module.css';

type TProps = {
  label: string;
  text: string;
  testId: string;
};

export const ReviewPreviewInfoRow = ({ label, text, testId }: TProps) => (
  <div className={styles.ReviewPreview__InfoRow}>
    <div className={styles.ReviewPreview__InfoLabel}>{label}:</div>
    <div className={styles.ReviewPreview__InfoText} data-testid={testId}>
      {text}
    </div>
  </div>
);
