import React from 'react';

import styles from './delimiter.module.css';

import { createClassNameConstructor } from 'utils/craeteClassNameConstructor';

type Props = {
  testId?: string;
};

export const Delimiter = (props: Props) => {
  const { testId } = props;

  const className = createClassNameConstructor(styles.Delimiter).return();

  return <div className={className} data-testid={testId} />;
};
