import React from 'react';

import styles from './CompetitionsPreview.module.scss';

type TProps = {
  children: React.ReactNode;
};

export const CompetitionsPreviewTemplate = ({ children }: TProps) => (
  <div className={styles.CompetitionsPreview__Template}>{children}</div>
);
