import {
  TApplication,
  TApplicationsGroup,
  TApplicationStatus
} from 'types/state';
import { setISOToDate, createStartOfDate } from 'utils/dateUtils';

export const normalizeApplications = {
  toApplicationGroups(
    applications: TApplication[],
    status: TApplicationStatus
  ): TApplicationsGroup[] {
    const isNew = status === 'NEW';

    const todaysGroup: TApplicationsGroup = {
      isNew,
      dayDateISO: createStartOfDate(new Date()).toISOString(),
      applications: []
    };

    const handleReduceStep = (
      acc: TApplicationsGroup[],
      item: TApplication
    ) => {
      const date = createStartOfDate(setISOToDate(item.createdAtDate));
      const processedGroup = acc.find(({ dayDateISO }) => {
        return dayDateISO === date.toISOString();
      });

      if (processedGroup && processedGroup.applications) {
        processedGroup.applications = [...processedGroup.applications, item];
      } else {
        acc.push({
          isNew,
          dayDateISO: date.toISOString(),
          applications: [item]
        });
      }
      return acc;
    };

    const resultUnsoretd = applications.reduce(handleReduceStep, [todaysGroup]);

    return resultUnsoretd.sort((left, right) => {
      return (
        new Date(right.dayDateISO).getTime() -
        new Date(left.dayDateISO).getTime()
      );
    });
  }
};
