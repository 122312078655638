import { UserToBlockedFormValues, UserToBlockedState } from '../../types/state';

export const createUserToBlockedState = (): UserToBlockedState => ({
  formValues: createUserToBlockedFormValues(),
  formError: undefined,
  submited: false,
  submitDidSucceed: false
});

export const createUserToBlockedFormValues = (): UserToBlockedFormValues => ({
  userId: -1,
  toBlocked: false
});
