import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { getSportingAuthURL } from 'services/sporting/utils/getSportingURL';

export const logout = async (): Promise<void> => {
  const endpointURL = getSportingAuthURL('logout');

  const response = await customFetch(endpointURL, {
    method: 'POST'
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
