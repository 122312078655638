import React from 'react';

import { Toast } from 'types/toast';
import { useAsyncController } from './useAsyncController';

const NotificationMock = async () => 'denied';

const NotificationPermission = () => {
  if (process.env.NODE_ENV !== 'test' && 'Notification' in window) {
    return Notification.requestPermission;
  }
  return NotificationMock;
};

export const useBrowserNotificationCreator = () => {
  const requestPermission = NotificationPermission();

  const {
    wrapAsync: wrapPermissionsRequest,
    asyncProcess: permissionsRequestProcess
  } = useAsyncController();

  const createBrowserNotification = React.useCallback(
    ({ title, description, onClick, onClose }: Toast) => {
      if (
        permissionsRequestProcess.status === 'RESOLVED' &&
        permissionsRequestProcess.value === 'granted'
      ) {
        const notification = new Notification(title, { body: description });

        notification.onclick = event => {
          event.preventDefault();
          window.focus();
          onClick();
        };

        notification.onclose = () => {
          onClose();
        };
      }
    },
    [permissionsRequestProcess]
  );

  React.useEffect(() => {
    const promise = requestPermission(undefined);

    wrapPermissionsRequest(promise);
  }, [requestPermission, wrapPermissionsRequest]);

  return createBrowserNotification;
};
