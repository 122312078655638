import React from 'react';

import './chatMessageLayout.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  align: 'left' | 'right';
  primaryLabel: React.ReactNode;
  secondaryLabel: React.ReactNode;
  action: React.ReactNode;
  message: React.ReactNode;
};

export const ChatMessageLayout = (props: Props) => {
  const { message, primaryLabel, secondaryLabel, action, align } = props;

  const bemConstructor = createBEMConstructor('chatMessageLayout');

  const wrapperClassName = bemConstructor('wrapper', { align });

  const primaryLabelSlotClassName = bemConstructor('dateLabelSlot', {
    align
  });

  const secondaryLabelSlotClassName = bemConstructor('timeLabelSlot', {
    align
  });

  const actionSlotClassName = bemConstructor('action-slot', { align });

  const messageSlotClassName = bemConstructor('message-slot', { align });

  const bodyClassName = bemConstructor('body', { align });

  return (
    <div className={wrapperClassName}>
      {primaryLabel && (
        <div className={primaryLabelSlotClassName}>{primaryLabel}</div>
      )}
      {secondaryLabel && (
        <div className={secondaryLabelSlotClassName}>{secondaryLabel}</div>
      )}
      <div className={bodyClassName}>
        {action && <div className={actionSlotClassName}>{action}</div>}
        <div className={messageSlotClassName}>{message}</div>
      </div>
    </div>
  );
};
