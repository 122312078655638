import { TUpdateCurrentUserPasswordParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const updateCurrentUserPassword = async (
  params: TUpdateCurrentUserPasswordParams
): Promise<void> => {
  const { nextPassword } = params;

  await sportingAPI.service.changePassword({
    payload: { newPassword: nextPassword }
  });
};
