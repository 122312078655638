import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from 'hooks/useAsyncController';
import { useServices } from 'hooks/useServices';
import React from 'react';

export function useBookingWaitersService() {
  const dispatch = useAppDispatch();

  const services = useServices();

  const { wrapAsync, asyncProcess } = useAsyncController();

  const loadWaiters = React.useCallback(() => {
    const promise = services.getWaiters().then(waiters => {
      dispatch({
        name: 'BOOKING_WAITERS_RECIEVED',
        waiters
      });
    });

    wrapAsync(promise);
  }, [dispatch, services, wrapAsync]);

  return { loadWaiters, asyncProcess };
}
