import { TIntl } from 'types/intl';
import { checkImageDimensions } from 'utils/checkImageDimensions';
import {
  NEWS_ARTICLE_IMAGE_MAX_SIZE,
  NEWS_ARTICLE_IMAGE_MIN_WIDTH,
  NEWS_ARTICLE_IMAGE_MIN_HEIGHT
} from 'utils/constants';

const alowedImageMIMETypes = ['image/jpeg', 'image/png'];

type TParams = {
  file: File;
  intl: TIntl;
};

export const validateNewsArticleImage = async ({ file, intl }: TParams) => {
  if (file.size > NEWS_ARTICLE_IMAGE_MAX_SIZE) {
    return intl.newsArticleImageSizeError;
  }
  if (!alowedImageMIMETypes.includes(file.type)) {
    return intl.newsArticleImageTypeError;
  }
  try {
    const validDimensionsFlag = await checkImageDimensions(file, {
      greaterThan: {
        width: NEWS_ARTICLE_IMAGE_MIN_WIDTH,
        height: NEWS_ARTICLE_IMAGE_MIN_HEIGHT
      }
    });
    if (!validDimensionsFlag) {
      return intl.newsArticleImageDimensionsError;
    }
  } catch (error) {
    return intl.unexpectedError;
  }
};
