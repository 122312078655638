import { TSportingUserInfo } from 'services/sporting/types';
import { UserInfo } from 'types/state';
import { normalizeSportingUserRole } from 'services/sporting/utils/normalizeSportingUserRole';
import { normalizeSportingMenuItem } from 'services/sporting/utils/normalizeSportingMenuItem';

export const normalizeSportingUserInfo = {
  toUserInfo(sportingUserInfo: TSportingUserInfo): UserInfo {
    return {
      id: sportingUserInfo.id,
      role: normalizeSportingUserRole.toUserRole(sportingUserInfo.role),
      login: sportingUserInfo.login,
      firstName: sportingUserInfo.firstName || '',
      lastName: sportingUserInfo.lastName || '',
      phoneNumber: sportingUserInfo.phoneNumber,
      blocked: sportingUserInfo.blocked,
      enabledSections: sportingUserInfo.menuItems.map(
        normalizeSportingMenuItem.toSection
      ),
      roles: sportingUserInfo.roles.map(normalizeSportingUserRole.toUserRole)
    };
  }
};
