import React from 'react';

import { useServices } from 'hooks/useServices';
import { newRestaurantApplicationsThunk } from 'thunks/newRestaurantApplicationsThunk.1';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { newSportApplicationsThunk } from 'thunks/newSportApplicationsThunk';
import { useNewApplicationsListsInvalidatedPage } from 'hooks/useApplicationsListsInvalidatedPages';
import { useApplicationsPageDomain } from 'hooks/useApplicationsPageDomain';
import { useSafeAsyncWrapper } from './useSafeAsyncWrapper';
import { ServiceScope } from 'types/serviceScope';

export const useNewApplicationsService = (pageType: ServiceScope) => {
  const dispatch = useAppDispatch();

  const {
    getRestaurantNewApplications,
    getSportNewApplications
  } = useServices();

  const domain = useApplicationsPageDomain();

  const invalidatedPage = useNewApplicationsListsInvalidatedPage(domain);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    if (pageType === 'RESTAURANT' && invalidatedPage) {
      const promise = newRestaurantApplicationsThunk(
        getRestaurantNewApplications({
          pageNumber: invalidatedPage
        })
      )(dispatch);

      wrapAsyncSafely(promise);
    }

    if (pageType === 'CLUB' && invalidatedPage) {
      const promise = newSportApplicationsThunk(
        getSportNewApplications({
          pageNumber: invalidatedPage
        })
      )(dispatch);

      wrapAsyncSafely(promise);
    }
  }, [
    dispatch,
    getRestaurantNewApplications,
    getSportNewApplications,
    invalidatedPage,
    pageType,
    wrapAsyncSafely
  ]);
};
