import { GetUnreadChatIdsParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { chatRoomsStoreMock } from 'services/mock/utils/chatRoomsModel';

export const getUnreadChatIds = async (
  params: GetUnreadChatIdsParams
): Promise<number[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  let rooms = chatRoomsStoreMock.getAllRooms();

  if (params.domain === 'RESTAURANT') {
    rooms.filter(room => room.domain === 'RESTAURANT');
  } else {
    rooms.filter(room => room.domain === 'CLUB');
  }

  return rooms.map(room => room.id);
};
