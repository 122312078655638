import { TDeleteNewsArticleParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const deleteNewsArticle = async (
  params: TDeleteNewsArticleParams
): Promise<void> => {
  const { id } = params;

  await sportingAPI.service.deleteNews({
    payload: { idNewsItem: id }
  });
};
