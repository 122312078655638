import React from 'react';

import styles from './CompetitionsFeedItem.module.css';
import { Bage } from 'components/shared/Bage';

type TProps = {
  title: string;
  titlePlaceholder: string;
  pretitle: string;
  hasSelectedView: boolean;
  count?: number;
  onClick(): void;
};

export const CompetitionsFeedItemTemplate = ({
  title,
  titlePlaceholder,
  pretitle,
  count,
  hasSelectedView,
  onClick
}: TProps) => (
  <button
    className={`${styles.CompetitionsFeedItem__Wrapper} ${
      hasSelectedView ? styles.CompetitionsFeedItem__Wrapper_selected : ''
    }`}
    onClick={onClick}
  >
    <div className={styles.CompetitionsFeedItem__Pretitle}>
      {pretitle}
      {count && (
        <Bage count={count} className={styles.CompetitionsFeedItem__Bage} />
      )}
    </div>
    {title ? (
      <div className={styles.CompetitionsFeedItem__Title}>{title}</div>
    ) : (
      <div className={styles.CompetitionsFeedItem__TitlePlaceholder}>
        {titlePlaceholder}
      </div>
    )}
  </button>
);
