import { Text } from 'design/text';
import React from 'react';
import { PictureIcon } from 'svg/72x72/picture';

import styles from './CompetitionsArticle.module.css';

type TProps = {
  src: null | File | string;
};

export const CompetitionsArticleImage = ({ src }: TProps) => {
  const imageSrc = React.useMemo(() => {
    if (src instanceof File) {
      return URL.createObjectURL(src);
    }
    return src;
  }, [src]);

  return (
    <div className={styles.CompetitionsArticle__ImageWrapper}>
      {imageSrc ? (
        <img
          src={imageSrc}
          alt=""
          className={styles.CompetitionsArticle__Image}
        />
      ) : (
        <div className={styles.CompetitionsArticle__ImagePlaceholder}>
          <Text color="secondary">
            <PictureIcon />
          </Text>
        </div>
      )}
    </div>
  );
};
