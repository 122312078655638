import { PersonalDataEditorState, PersonalDataFormValues } from 'types/state';

export const createPersonalDataEditorState = (): PersonalDataEditorState => ({
  formValues: createPersonalDataFormValues(),
  submited: false,
  submitDidSucceed: false
});

export const createPersonalDataFormValues = (): PersonalDataFormValues => ({
  lastName: '',
  firstName: '',
  phoneNumber: ''
});
