import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Navigation.module.scss';

import { Bage } from 'components/shared/Bage';

type TProps = {
  label: string;
  route: string;
  count?: number;
  testId?: string;
  bageTestId?: string;
  icon?: React.ReactNode;
};

export const NavigationItem = ({
  label,
  route,
  count,
  testId,
  bageTestId,
  icon
}: TProps) => (
  <NavLink
    to={route}
    className={styles.Navigation__Item}
    activeClassName={styles.Navigation__Item_active}
    data-testid={testId}
  >
    <span className={styles.Navigation__ItemWrapper}>
      {!!icon && <span className={styles.Navigation__Icon}>{icon}</span>}
      <span className={styles.Navigation__Label}> {label} </span>
      {!!count && (
        <Bage
          count={count}
          className={styles.Navigation__Bage}
          testId={bageTestId}
        />
      )}
    </span>
  </NavLink>
);
