import React from 'react';
import { setCustomInterval } from 'utils/setCustomInterval';

export function useNowISODate(): string {
  const [nowISODate, setNowISODate] = React.useState(new Date().toISOString());

  React.useEffect(() => {
    return setCustomInterval(() => {
      setNowISODate(new Date().toISOString());
    }, 1000);
  }, []);

  return nowISODate;
}
