import React from 'react';
import { ServiceScope } from 'types/serviceScope';
import { Place } from 'types/state';
import { useAppState } from '../useAppState';

export const useBookingPlaces = (location: ServiceScope): Place[] => {
  const { placesCache } = useAppState();

  return React.useMemo(() => {
    return Array.from(placesCache.value.values())
      .filter(place => place.location === location)
      .sort((nextPlace, prevPlace) => {
        return nextPlace.orderNumber - prevPlace.orderNumber;
      });
  }, [location, placesCache.value]);
};
