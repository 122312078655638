import React from 'react';
import { useIntlContext } from '../../../../hooks/useIntlContext';
import { BookingPlaceFormValues } from '../../../../types/state';
import { BookingPlaceItem } from '../BookingPlaceItem/BookingPlaceItem';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { ArrowLoaderDown } from 'svg/24x24/arrow-loader-down';
import { ArrowLoaderUp } from 'svg/24x24/arrow-loader-up';
import { PlusInCircleFigureComponent } from 'design/plusInCircleFigure/plusInCircleFigureComponents';
import { Text } from 'design/text';
import { TrashIcon } from 'svg/24x24/trash';
import { BOOKING_PLACE_NAME_INPUT_MAX_LENGTH } from 'utils/constants';
import { digitsOnlyRegExp } from 'utils/stringUtils';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useBookingPlacesEditorState } from 'hooks/booking/useBookingPlacesEditorState';
import { TextInputControl } from 'design/textInputControl';
import { BookingPlaceLayout } from './BookingPlaceLayout';
import { ClickControlComponent } from 'design/clickControl/clickControlComponents';
import { LabeledIconLayout } from 'design/labeledIconLayout/labeledIconLayoutComponents';
import { BookingPlaceBodyLayout } from './BookingPlaceBodyLayout';
import { EmployeeItemBtn } from 'components/EmployeeItemBtn/EmployeeItemBtn';
import { ServiceScope } from 'types/serviceScope';
import { useServices } from 'hooks/useServices';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

type Props = {
  location: ServiceScope;
  place: BookingPlaceFormValues;
  onChangeTitle(value: string): void;
  onChangeOrder(type: PlaceChangeOrderType): void;
  onForbiddenDeleteAttempt(): void;
};

export enum PlaceChangeOrderType {
  UP,
  DOWN
}

export const BookingPlace = (props: Props) => {
  const {
    location,
    place,
    onChangeTitle,
    onChangeOrder,
    onForbiddenDeleteAttempt
  } = props;

  const { bookingPlaceIntl: msgs } = useIntlContext();
  const appDispatch = useAppDispatch();

  const { formError } = useBookingPlacesEditorState();

  const placeId = place.id;

  const services = useServices();

  const onAddPlaceItem = React.useCallback(() => {
    appDispatch({
      name: 'BOOKING_PLACE_ITEM_ADD',
      placeId
    });
  }, [appDispatch, placeId]);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const onDeletePlace = React.useCallback(() => {
    const placeItemIds = place.items
      .map(placeItem => {
        return Number(placeItem.id);
      })
      .filter(placeItemId => !Number.isNaN(placeItemId));

    const promise = Promise.resolve(placeItemIds)
      .then(placeItemIds => {
        if (placeItemIds.length > 0) {
          return services.doPlacesHaveForthcomingReservations(placeItemIds);
        }

        return false;
      })
      .then(placesHaveForthComingReservations => {
        if (!placesHaveForthComingReservations) {
          appDispatch({
            name: 'BOOKING_PLACE_DELETE',
            id: placeId
          });
        } else {
          onForbiddenDeleteAttempt();
        }
      });

    wrapAsyncSafely(promise);
  }, [
    appDispatch,
    onForbiddenDeleteAttempt,
    place.items,
    placeId,
    services,
    wrapAsyncSafely
  ]);

  const onChangePlaceItemTitle = React.useCallback(
    (itemId: string, value: string) => {
      appDispatch({
        name: 'BOOKING_PLACE_ITEM_CHANGE_TITLE',
        placeId,
        placeItemId: itemId,
        value
      });
    },
    [appDispatch, placeId]
  );

  const onChangePlaceItemMinCount = React.useCallback(
    (itemId: string, value: string) => {
      if (!value || digitsOnlyRegExp.test(value)) {
        appDispatch({
          name: 'BOOKING_PLACE_ITEM_CHANGE_MIN_COUNT',
          placeId,
          placeItemId: itemId,
          value
        });
      }
    },
    [appDispatch, placeId]
  );

  const onChangePlaceItemMaxCount = React.useCallback(
    (itemId: string, value: string) => {
      if (!value || digitsOnlyRegExp.test(value)) {
        appDispatch({
          name: 'BOOKING_PLACE_ITEM_CHANGE_MAX_COUNT',
          placeId,
          placeItemId: itemId,
          value
        });
      }
    },
    [appDispatch, placeId]
  );

  const handleDragEnd = React.useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const startIndex = result.source.index;
      const endIndex = result.destination.index;

      appDispatch({
        name: 'BOOKING_PLACE_ITEM_CHANGE_ORDER',
        placeId,
        startIndex,
        endIndex
      });
    },
    [appDispatch, placeId]
  );

  const isExistPlaceItems = place.items.length > 0;

  const violations = formError || [];

  const nameIsInvalid = violations.some(violation => {
    if (violation.name === 'PLACE_NAME_REQUIRED') {
      return violation.placeId === place.id;
    }

    return false;
  });

  return (
    <BookingPlaceLayout
      nameInputControl={
        <TextInputControl
          placeholder={msgs.namePlaceholder}
          name={`bookingPlaceName__${place.id}`}
          invalid={nameIsInvalid}
          value={place.title}
          maxLength={BOOKING_PLACE_NAME_INPUT_MAX_LENGTH}
          onValueChange={value => onChangeTitle(value)}
          autoFocus={!place.title && place.dataAction === 'CREATE_NEW'}
        />
      }
      downControl={
        <EmployeeItemBtn
          icon={<ArrowLoaderDown />}
          onClick={() => onChangeOrder(PlaceChangeOrderType.DOWN)}
        />
      }
      upControl={
        <EmployeeItemBtn
          icon={<ArrowLoaderUp />}
          onClick={() => onChangeOrder(PlaceChangeOrderType.UP)}
        />
      }
      deleteControl={
        <EmployeeItemBtn
          hoverAccent="critical"
          icon={<TrashIcon />}
          onClick={onDeletePlace}
        />
      }
      body={
        isExistPlaceItems && (
          <BookingPlaceBodyLayout
            nameLabel={<Text weight="semi-bold">{msgs.nameLabel}</Text>}
            visitorsCountLabel={
              <Text weight="semi-bold">{msgs.countGuestLabel}</Text>
            }
            items={
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="bookingPlaceItems">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {place.items
                        .filter(placeItem => placeItem.dataAction !== 'DELETE')
                        .map((placeItem, index) => (
                          <BookingPlaceItem
                            key={placeItem.id}
                            index={index}
                            placeId={placeId}
                            placeItem={placeItem}
                            onChangeTitle={value =>
                              onChangePlaceItemTitle(placeItem.id, value)
                            }
                            onChangeMinCount={value =>
                              onChangePlaceItemMinCount(placeItem.id, value)
                            }
                            onChangeMaxCount={value =>
                              onChangePlaceItemMaxCount(placeItem.id, value)
                            }
                            onForbiddenDeleteAttempt={onForbiddenDeleteAttempt}
                          />
                        ))}
                      <div style={{ display: 'none' }}>
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            }
          />
        )
      }
      addControl={
        <ClickControlComponent onClick={onAddPlaceItem}>
          <LabeledIconLayout
            icon={<PlusInCircleFigureComponent size="m" />}
            text={
              <Text color="accent">
                {location === 'RESTAURANT' && msgs.addPlaceRestaurantBtn}
                {location === 'CLUB' && msgs.addPlaceClubBtn}
              </Text>
            }
          />
        </ClickControlComponent>
      }
    />
  );
};
