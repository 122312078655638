import React from 'react';

import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineGroupLayout.scss';

type Props = {
  header: React.ReactNode;
  body: React.ReactNode;
  collapsed: boolean;
};

const bemConstructor = createBEMConstructor('bookingTimelineGroupLayout');

export const BookingTimelineGroupLayout: React.FC<Props> = props => {
  const { header, body, collapsed } = props;

  const wrapperClassName = bemConstructor('wrapper', { collapsed });

  const headerSlotClassName = bemConstructor('headerSlot', { collapsed });

  const bodySlotClassName = bemConstructor('bodySlot', { collapsed });

  return (
    <div className={wrapperClassName}>
      <div className={headerSlotClassName}>{header}</div>
      <div className={bodySlotClassName}>{body}</div>
    </div>
  );
};
