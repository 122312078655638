import { Cache } from '../../types/util';
import { Waiter } from '../../types/state';
import { AppEvent } from '../../types/appEvent';
import { createCache, modifyCache } from 'utils/createCache';

export const waitersCacheReducer = (
  state: Cache<Map<number, Waiter>>,
  event: AppEvent
): Cache<Map<number, Waiter>> => {
  switch (event.name) {
    case 'BOOKING_WAITERS_RECIEVED':
      const map = new Map<number, Waiter>();

      event.waiters.forEach(waiter => {
        map.set(waiter.id, waiter);
      });

      return createCache(map);

    case 'BOOKING_WAITERS_CREATED':
    case 'BOOKING_WAITERS_DELETED':
      return modifyCache(state)
        .invalidate()
        .return();
    default:
      return state;
  }
};
