import * as React from 'react';

interface ErrorConstructor {
  new (): Error;
}

type FallbackComponentProps = {
  error: Error;
  recover(): void;
};

type Props = {
  children: React.ReactNode;
  fallbackComponent: React.FC<FallbackComponentProps>;
  errorConstructorsToCatch: ErrorConstructor[];
};

type State = {
  error: null | Error;
};

export class ErrorBoundaryComponent extends React.Component<Props, State> {
  state: State = {
    error: null
  };

  static getDerivedStateFromError(error: Error): State {
    return {
      error
    };
  }

  recover = (): void => {
    this.setState({
      error: null
    });
  };

  render(): React.ReactNode {
    const {
      children,
      fallbackComponent: FallbackComponent,
      errorConstructorsToCatch
    } = this.props;

    const { error } = this.state;

    const matchFound = errorConstructorsToCatch.some(
      Constructor => error instanceof Constructor
    );

    if (error !== null && matchFound) {
      return <FallbackComponent error={error} recover={this.recover} />;
    }

    if (error && !matchFound) {
      throw error;
    }

    return children;
  }
}
