import React from 'react';

import { useIntlContext } from '../../hooks/useIntlContext';
import { RectangleButton } from '../shared/RectangleButton';
import { ModalPortal } from '../shared/Modal';
import {
  BookingReservationFormError,
  CalendarPeriod,
  PlaceItem,
  TimeLineDay
} from '../../types/state';
import { TextInputControl } from '../../design/textInputControl';
import { Checkbox } from '../shared/Checkbox';
import { CustomSelect } from '../../containers/CustomSelect';
import { useBookingReservationEditorState } from '../../hooks/booking/useBookingReservationEditorState';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { CustomDatePicker } from '../CustomDatePicker';
import {
  convertDateISOToDDMMYYYY,
  equalsDateWithDateOfWeek
} from '../../utils/dateUtils';
import { BookingClientData } from '../BookingClientData/BookingClientData';
import { BookingWaitersEditorModal } from '../BookingWaitersEditorModal/BookingWaitersEditorModal';
import { useWaitersSelectOptions } from '../../hooks/useWaitersSelectOptions';
import { ClientInfo } from '../../types/client';
import { BookingPlacesSelect } from '../BookingPlacesSelect/BookingPlacesSelect';
import { CloseIcon } from '../../svg/24x24/close';
import { DayEventsIcon } from '../../svg/20x20/day-events';
import { EditIcon } from '../../svg/24x24/edit';
import { normalizeTimeLineDay } from 'utils/normalizeTimeLineDay';
import { useKeyDown } from 'hooks/useKeyDown';
import { TrashIcon } from '../../svg/24x24/trash';
import { BookingModalLayout } from '../BookingModalLayout/BookingModalLayoutComponents';
import { ActionControl } from '../../design/actionControl';
import { useBookingPlaces } from 'hooks/booking/useBookingPlaces';
import { BookingReservationModalFooterLayoutComponent } from './BookingReservationModalFooterLayoutComponents';
import { Text } from 'design/text';
import { BookingReservationModalBodyLayoutComponent } from './BookingReservationModalBodyLayoutComponents';
import { InputFieldLayoutComponent } from 'design/inputFieldLayout/inputFieldLayoutComponents';
import { GuestCountInputComponent } from 'design/guestCountInput/guestCountInputComponents';
import { CalendarPeriodInputLayoutComponent } from 'design/calendarPeriodInputLayout/calendarPeriodInputLayoutComponents';
import { CalendarPeriodDayTimeInputComponent } from 'containers/CalendarPeriodDayTimeInput';
import { format, parse } from 'date-fns';
import { DATE_FNS__24H_TIME_FORMAT } from 'utils/constants';
import { useBookingReservationCreateService } from 'hooks/booking/useBookingReservationCreateService';
import { useBookingReservationDeleteService } from 'hooks/booking/useBookingReservationDeleteService';
import { useBookingReservationUpdateService } from 'hooks/booking/useBookingReservationUpdateService';
import { validateBookingReservationFormErrors } from 'utils/booking/validateBookingReservationFormErrors';
import { PopupGroupLayoutComponent } from 'design/popupGroupLayout/popupGroupLayoutComponents';
import { ServiceScope } from 'types/serviceScope';
import { useBookingWaitersService } from 'hooks/booking/useBookingWaitersService';
import { useAppState } from 'hooks/useAppState';
import { useBookingPlaceItemReservationUtils } from 'hooks/booking/useBookingPlaceItemReservationUtils';
import { useBookingTimeLinesLoadingProcess } from 'hooks/booking/useBookingTimeLinesLoadingProcess';
import { useBookingPlacesLoadingProcess } from 'hooks/booking/useBookingPlacesLoadingProcess';

const COMMENT_INPUT_MAX_LENGTH = 200;

const NO_BOOKING_GUEST_CHECKBOX_INPUT_ID = 'noBookingGuestInput';

type Props = {
  location: ServiceScope;
};

export const BookingReservationModalContent = (props: Props) => {
  useBookingTimeLinesLoadingProcess();

  useBookingPlacesLoadingProcess();

  const {
    createReservation,
    asyncProcess: creationProcess
  } = useBookingReservationCreateService();

  const {
    updateReservation,
    asyncProcess: updationProcess
  } = useBookingReservationUpdateService();

  const {
    deleteReservation,
    asyncProcess: deletionProcess
  } = useBookingReservationDeleteService();

  const {
    loadWaiters,
    asyncProcess: waitersLoadingProcess
  } = useBookingWaitersService();

  const { waitersCache } = useAppState();

  React.useEffect(() => {
    if (waitersCache.didInvalidate) {
      loadWaiters();
    }
  }, [loadWaiters, waitersCache.didInvalidate]);

  const { formValues } = useBookingReservationEditorState();

  const [formErrors, setFormErrors] = React.useState<
    BookingReservationFormError[]
  >([]);

  const { bookingReservationIntl: msgs } = useIntlContext();
  const appDispatch = useAppDispatch();
  const { location } = props;

  const intl = useIntlContext();

  const waitersSelectOptions = useWaitersSelectOptions();

  const places = useBookingPlaces(location);

  const onChangeDate = React.useCallback(
    (value: string) => {
      setShowedDatePicker(false);

      const startDateISO = parse(
        format(
          new Date(formValues.period.startDateISO),
          DATE_FNS__24H_TIME_FORMAT
        ),
        DATE_FNS__24H_TIME_FORMAT,
        new Date(value)
      ).toISOString();

      const endDateISO = parse(
        format(
          new Date(formValues.period.endDateISO),
          DATE_FNS__24H_TIME_FORMAT
        ),
        DATE_FNS__24H_TIME_FORMAT,
        new Date(value)
      ).toISOString();

      const nextPeriod = {
        startDateISO,
        endDateISO
      };

      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_PERIOD',
        value: nextPeriod
      });
    },
    [appDispatch, formValues.period]
  );

  const onChangeGuestCount = React.useCallback(
    (count: number) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_GUEST_COUNT',
        value: count
      });
    },
    [appDispatch]
  );

  const [isShowedDatePicker, setShowedDatePicker] = React.useState<boolean>(
    false
  );
  const closeDatePicker = React.useCallback(() => {
    setShowedDatePicker(false);
  }, []);

  const toggleDatePicker = React.useCallback(() => {
    setShowedDatePicker(prev => !prev);
  }, [setShowedDatePicker]);

  const getDateForLabel = React.useCallback(() => {
    return convertDateISOToDDMMYYYY(formValues.period.startDateISO);
  }, [formValues.period.startDateISO]);

  const { timeLinesCache } = useAppState();

  const targetDate = React.useMemo(() => {
    return new Date(formValues.period.startDateISO);
  }, [formValues.period.startDateISO]);

  const targetTimeLineDay = React.useMemo<TimeLineDay | void>(() => {
    for (let timeLine of timeLinesCache.value) {
      if (timeLine.location === location) {
        return timeLine.days.find(day => {
          return equalsDateWithDateOfWeek(targetDate, day.day);
        });
      }
    }
  }, [location, targetDate, timeLinesCache.value]);

  const targetCalendarPeriod = React.useMemo(() => {
    if (targetTimeLineDay) {
      return normalizeTimeLineDay(
        targetDate,
        targetTimeLineDay
      ).toCalendarPeriod();
    }

    return null;
  }, [targetDate, targetTimeLineDay]);

  const handlePeriodChange = React.useCallback(
    (nextPeriod: CalendarPeriod) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_PERIOD',
        value: nextPeriod
      });
    },
    [appDispatch]
  );

  const onChangePlaceItems = React.useCallback(
    (value: PlaceItem[]) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_PLACE_ITEMS',
        value
      });
    },
    [appDispatch]
  );

  const onChangeComment = React.useCallback(
    (value: string) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_COMMENT',
        value
      });
    },
    [appDispatch]
  );

  const toggleGuestWithoutReservationCheckbox = React.useCallback(() => {
    appDispatch({
      name: 'BOOKING_RESERVATION_CHANGE_GUEST_WITHOUT_PRE_BOOKING',
      value: !formValues.preBooking
    });
  }, [appDispatch, formValues.preBooking]);

  const onChangeClientName = React.useCallback(
    (value: string) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_CLIENT_NAME',
        value
      });
    },
    [appDispatch]
  );

  const onChangeClientPhoneNumber = React.useCallback(
    (value: string) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_CLIENT_PHONE_NUMBER',
        value
      });
    },
    [appDispatch]
  );

  const onChangeClientCardNumber = React.useCallback(
    (value: string) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_CLIENT_CARD_NUMBER',
        value
      });
    },
    [appDispatch]
  );

  const onSelectSearchResultItem = React.useCallback(
    (clientInfo: ClientInfo) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_CLIENT_ID',
        value: clientInfo.id
      });
      onChangeClientName(intl.personFullName(clientInfo));
      onChangeClientPhoneNumber(
        clientInfo.phoneNumber != null ? clientInfo.phoneNumber : ''
      );
      onChangeClientCardNumber(clientInfo.cardNumber || '');
    },
    [
      appDispatch,
      onChangeClientName,
      intl,
      onChangeClientPhoneNumber,
      onChangeClientCardNumber
    ]
  );

  const onChangeWaiter = React.useCallback(
    (value: string) => {
      appDispatch({
        name: 'BOOKING_RESERVATION_CHANGE_WAITER',
        value
      });
    },
    [appDispatch]
  );

  const [
    isShowedWaitersEditorModal,
    setShowedWaitersEditorModal
  ] = React.useState<boolean>(false);

  const onEditWaiters = React.useCallback(() => {
    setShowedWaitersEditorModal(true);
  }, []);

  const onDelete = React.useCallback(() => {
    deleteReservation({ formValues });
  }, [deleteReservation, formValues]);

  const busy =
    creationProcess.status === 'PENDING' ||
    creationProcess.status === 'RESOLVED' ||
    updationProcess.status === 'PENDING' ||
    updationProcess.status === 'RESOLVED' ||
    deletionProcess.status === 'PENDING' ||
    deletionProcess.status === 'RESOLVED' ||
    waitersLoadingProcess.status === 'PENDING';

  const onCancel = React.useCallback(() => {
    appDispatch({
      name: 'BOOKING_RESERVATION_CANCEL'
    });
  }, [appDispatch]);

  const onSubmit = React.useCallback(() => {
    const nextFormErrors = validateBookingReservationFormErrors(formValues);

    setFormErrors(nextFormErrors);

    if (nextFormErrors.length === 0) {
      if (formValues.id > 0) {
        updateReservation({ formValues });
      } else {
        createReservation({ formValues });
      }
    }
  }, [createReservation, formValues, updateReservation]);

  const { isPlaceItemReservedOnPeriod } = useBookingPlaceItemReservationUtils();

  React.useEffect(() => {
    const placeItemIdsToDrop: number[] = [];

    for (let place of places) {
      for (let placeItem of place.items) {
        if (
          isPlaceItemReservedOnPeriod(
            formValues.id,
            placeItem,
            formValues.period
          )
        ) {
          placeItemIdsToDrop.push(placeItem.id);
        }
      }
    }

    appDispatch({
      name: 'BOOKING_RESERVATION_PLACE_ITEMS_DROPPED',
      placeItemIds: placeItemIdsToDrop
    });
  }, [
    appDispatch,
    formValues.id,
    formValues.period,
    isPlaceItemReservedOnPeriod,
    places
  ]);

  useKeyDown({
    Escape: onCancel
  });

  const guestsCountValueInvalid = formErrors.includes(
    'BOOKING_RESERVATION_FORM_GUEST_COUNT_EMPTY'
  );

  const dateAndTimeValueInvalid = formErrors.includes(
    'BOOKING_RESERVATION_FORM_INVALID_PERIOD'
  );

  const placesValueInvalid = formErrors.includes(
    'BOOKING_RESERVATION_FORM_PLACES_EMPTY'
  );

  const waiterValueInvalid = formErrors.includes(
    'BOOKING_RESERVATION_FORM_PROCESSED_EMPTY'
  );

  return (
    <ModalPortal noPadding={true} toRight={true}>
      <BookingModalLayout
        closeButton={
          <ActionControl theme="invisible" onClick={onCancel}>
            <CloseIcon />
          </ActionControl>
        }
        body={
          <BookingReservationModalBodyLayoutComponent
            title={
              <Text size="l" weight="semi-bold">
                {formValues.id === 0
                  ? msgs.newReservationLabel
                  : msgs.editReservationLabel}
              </Text>
            }
            guestCount={
              <InputFieldLayoutComponent
                label={
                  <Text
                    size="s"
                    weight="semi-bold"
                    color={guestsCountValueInvalid ? 'critical' : 'primary'}
                  >
                    {msgs.countGuestLabel}
                  </Text>
                }
                input={
                  <GuestCountInputComponent
                    value={formValues.personsCount}
                    onValueChange={onChangeGuestCount}
                  />
                }
              />
            }
            dateAndTime={
              <InputFieldLayoutComponent
                label={
                  <Text
                    size="s"
                    weight="semi-bold"
                    color={dateAndTimeValueInvalid ? 'critical' : 'primary'}
                  >
                    {msgs.dateAndTimeLabel}
                  </Text>
                }
                input={
                  <CalendarPeriodInputLayoutComponent
                    dateInput={
                      <PopupGroupLayoutComponent
                        onOutsideClick={closeDatePicker}
                        trigger={
                          <RectangleButton
                            styleType="secondary"
                            label={getDateForLabel()}
                            onClick={toggleDatePicker}
                            icon={<DayEventsIcon />}
                          />
                        }
                        popup={
                          isShowedDatePicker && (
                            <CustomDatePicker
                              value={formValues.period.startDateISO}
                              onDateSelect={onChangeDate}
                            />
                          )
                        }
                      />
                    }
                    timeInput={
                      targetCalendarPeriod && (
                        <CalendarPeriodDayTimeInputComponent
                          basePeriod={targetCalendarPeriod}
                          selectedPeriod={formValues.period}
                          onSelectedPeriodChange={handlePeriodChange}
                        />
                      )
                    }
                  />
                }
              />
            }
            place={
              <InputFieldLayoutComponent
                label={
                  <Text
                    size="s"
                    weight="semi-bold"
                    color={placesValueInvalid ? 'critical' : 'primary'}
                  >
                    {msgs.placeLabel(location)}
                  </Text>
                }
                input={
                  <BookingPlacesSelect
                    places={places}
                    value={formValues.placeItems}
                    onChange={onChangePlaceItems}
                    isItemDisabled={item =>
                      isPlaceItemReservedOnPeriod(
                        formValues.id,
                        item,
                        formValues.period
                      )
                    }
                  />
                }
              />
            }
            comment={
              <InputFieldLayoutComponent
                label={
                  <Text size="s" weight="semi-bold" color="primary">
                    {msgs.commentLabel}
                  </Text>
                }
                input={
                  <TextInputControl
                    multiline={true}
                    maxLength={COMMENT_INPUT_MAX_LENGTH}
                    placeholder={msgs.commentPlaceholder}
                    value={formValues.comment}
                    onValueChange={onChangeComment}
                  />
                }
              />
            }
            guest={
              <label htmlFor={NO_BOOKING_GUEST_CHECKBOX_INPUT_ID}>
                <InputFieldLayoutComponent
                  theme="labelOnTheRight"
                  label={
                    <Text size="s">{msgs.guestWithoutReservationLabel}</Text>
                  }
                  input={
                    <Checkbox
                      id={NO_BOOKING_GUEST_CHECKBOX_INPUT_ID}
                      name="guestWithoutReservationCheckbox"
                      checked={!formValues.preBooking}
                      onChange={toggleGuestWithoutReservationCheckbox}
                    />
                  }
                />
              </label>
            }
            client={
              formValues.preBooking && (
                <BookingClientData
                  formValues={{
                    userId: formValues.userId,
                    fio: formValues.fio,
                    phoneNumber: formValues.phoneNumber,
                    cardNumber: formValues.cardNumber
                  }}
                  formError={formErrors}
                  onSelectSearchResultItem={onSelectSearchResultItem}
                  onChangeName={onChangeClientName}
                  onChangePhoneNumber={onChangeClientPhoneNumber}
                  onChangeCardNumber={onChangeClientCardNumber}
                />
              )
            }
          />
        }
        footer={
          <BookingReservationModalFooterLayoutComponent
            waitersSelectControl={
              <CustomSelect
                invalid={waiterValueInvalid}
                popupUp={true}
                formFieldBordered={true}
                placeholder={msgs.waitersSelectPlaceholder}
                selected={formValues.processedBy}
                options={waitersSelectOptions}
                onChange={onChangeWaiter}
              />
            }
            editWaitersControl={
              <RectangleButton
                styleType={'secondary'}
                label={''}
                icon={<EditIcon />}
                onClick={onEditWaiters}
              />
            }
            deleteControl={
              formValues.id !== 0 && (
                <RectangleButton
                  styleType="destructive"
                  label={''}
                  icon={<TrashIcon />}
                  onClick={onDelete}
                />
              )
            }
            cancelControl={
              <RectangleButton
                styleType={'secondary'}
                label={msgs.cancelBtnLabel}
                isDisabled={busy}
                onClick={onCancel}
              />
            }
            submitControl={
              <RectangleButton
                styleType={'primary'}
                label={msgs.submitBtnLabel}
                hasPreloader={busy}
                onClick={onSubmit}
              />
            }
          />
        }
      />
      {isShowedWaitersEditorModal && (
        <BookingWaitersEditorModal
          onClose={() => setShowedWaitersEditorModal(false)}
        />
      )}
    </ModalPortal>
  );
};
