import { TSetReviewNoteParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { reviewsStoreMock } from 'services/mock/utils/reviewsModel';

export const setReviewNote = async (params: TSetReviewNoteParams) => {
  const { reviewId, note } = params;

  await asyncTimeout(SERVICES_MOCK_DELAY);

  const review = reviewsStoreMock.getReviewById(reviewId);

  reviewsStoreMock.updateReview({
    ...review,
    note
  });
};
