import { SportingTimeLine } from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';

export const getTimeLines = async (): Promise<SportingTimeLine[]> => {
  const endpointURL = getSportingBookingURL('getTimeLines');

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  return response.json();
};
