import React from 'react';

import { RoundButton } from 'components/shared/RoundButton';
import { TrashIcon } from 'svg/20x20/trash';

import styles from './NewsArticle.module.css';
import { PencilIcon } from 'svg/24x24/pencil';

type TProps = {
  onEdit(): void;
  onDelete(): void;
};

export const NewsArticleActions = ({ onEdit, onDelete }: TProps) => (
  <div className={styles.NewsArticle__ActionsWrapper}>
    <RoundButton className={styles.NewsArticle__ActionsButton} onClick={onEdit}>
      <PencilIcon />
    </RoundButton>
    <RoundButton
      className={styles.NewsArticle__ActionsButton}
      onClick={onDelete}
    >
      <TrashIcon />
    </RoundButton>
  </div>
);
