import { TDeleteWaitersParams } from '../../../types/services';
import { sportingAPI } from '../api';

export const deleteWaiters = async (params: TDeleteWaitersParams) => {
  const { ids } = params;

  await sportingAPI.booking.deleteWaiters({
    payload: {
      ids
    }
  });
};
