import { SportingReservation, SportingSecureParams } from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';
import { createQueryString } from '../../../../utils/createQueryString';

type Payload = {
  day: string; //in format 'yyy-MM-dd'
};
export const getReservations = async (
  params: SportingSecureParams<Payload>
): Promise<SportingReservation[]> => {
  const { payload } = params;
  const endpointURL = getSportingBookingURL(
    `getReservations?${createQueryString(payload)}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  if (response.status === 204) {
    return [];
  }

  return response.json();
};
