import React from 'react';

import {
  TArticeImage,
  TCompetitionsArticle,
  TCompetitionDuration
} from 'types/state';
import { useCompetitionDurationLabel } from 'hooks/useCompetitionDurationLabel';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  hasEditView: boolean;
  data: TCompetitionsArticle;
  onChange(nextData: TCompetitionsArticle): void;
  onEdit(): void;
  onDelete(): void;
};

export const useCompetitionsArticleState = (props: TProps) => {
  const { data, hasEditView, onChange, onDelete, onEdit } = props;

  const {
    competitionsArticleTitleIputPlaceholder: titleInputPlaceholder,
    competitionsArticleDescriptionIputPlaceholder: descriptionInputPlaceholder
  } = useIntlContext();

  const durationLabel = useCompetitionDurationLabel(data.duration);

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.currentTarget;
      onChange({
        ...data,
        [name]: value
      });
    },
    [data, onChange]
  );
  const handleImageChange = React.useCallback(
    (nextImage: TArticeImage) => {
      onChange({
        ...data,
        image: nextImage
      });
    },
    [data, onChange]
  );
  const handleDurationChange = React.useCallback(
    (nextValue: TCompetitionDuration) => {
      onChange({
        ...data,
        duration: nextValue
      });
    },
    [data, onChange]
  );

  const handleDelete = onDelete;
  const handleEdit = onEdit;

  return {
    titleInputPlaceholder,
    descriptionInputPlaceholder,
    hasEditView,
    data,
    durationLabel,
    handleInputChange,
    handleImageChange,
    handleDurationChange,
    handleEdit,
    handleDelete
  };
};
