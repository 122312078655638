import React from 'react';
import { CalendarPeriod, TimeLineDay } from 'types/state';
import { normalizeTimeLineDay } from 'utils/normalizeTimeLineDay';
import { equalsDateWithDateOfWeek } from '../utils/dateUtils';
import { ServiceScope } from 'types/serviceScope';
import { useAppState } from './useAppState';

export function useWorkingHours(
  targetDateISO: string,
  targetLocation: ServiceScope
): null | CalendarPeriod {
  const { timeLinesCache } = useAppState();

  const targetDate = React.useMemo(() => {
    return new Date(targetDateISO);
  }, [targetDateISO]);

  const targetTimeLineDay = React.useMemo<TimeLineDay | void>(() => {
    for (let timeLine of timeLinesCache.value) {
      const { location, days } = timeLine;

      if (location === targetLocation) {
        return days.find(day => {
          return equalsDateWithDateOfWeek(targetDate, day.day);
        });
      }
    }
  }, [targetDate, targetLocation, timeLinesCache.value]);

  const calendarPeriod = React.useMemo(() => {
    if (targetTimeLineDay) {
      return normalizeTimeLineDay(
        targetDate,
        targetTimeLineDay
      ).toCalendarPeriod();
    }
  }, [targetDate, targetTimeLineDay]);

  return calendarPeriod || null;
}
