import { TSportingMenuItem } from 'services/sporting/types';
import { Section } from 'types/state';
import { UnreachableError } from 'utils/errors';

export const normalizeSection = {
  toSportingMenuItem(section: Section): TSportingMenuItem {
    switch (section) {
      case 'applicationsRestaurant':
        return 'RESTARAUNT_CLAIMS';
      case 'chatsRestaurant':
        return 'RESTARAUNT_CHATS';
      case 'bookingRestaurant':
        return 'BOOKING_RESTAURANT';
      case 'applicationsSport':
        return 'SHOOTING_CLAIMS';
      case 'chatsSport':
        return 'SHOOTING_CHATS';
      case 'bookingClub':
        return 'BOOKING_CLUB';
      case 'news':
        return 'NEWS';
      case 'competitions':
        return 'COMPETITIONS';
      case 'feedback':
        return 'COMMENTS';
      case 'members':
        return 'VERIFICATION';
      case 'users':
        return 'USERS';
      default:
        throw new UnreachableError(section);
    }
  }
};
