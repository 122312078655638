import { AdministratorInfo } from 'types/state';
import { getUid } from 'utils/getUid';

export const createAdministratorInfoMock = (): AdministratorInfo => {
  const id = getUid();

  return {
    id,
    login: `user ${id}`,
    phoneNumber: 'null'
  };
};
