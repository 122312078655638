import React from 'react';

export const useFileURL = (file: File) => {
  const url = React.useMemo(() => URL.createObjectURL(file), [file]);

  React.useEffect(() => {
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [url]);

  return url;
};
