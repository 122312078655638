import React from 'react';

import styles from './ApplicationsTable.module.scss';

type TProps = {
  children: React.ReactNode;
};

export const ApplicationsTableCell = ({ children }: TProps) => (
  <div className={styles.ApplicationsTable__Cell}>{children}</div>
);
