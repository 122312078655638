import { SheduleInfoService } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';

export const getSheduleInfo: SheduleInfoService = async () => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  return {
    restaurant: '10:00 - 18:00',
    hunting: '12:00 - 20:00'
  };
};
