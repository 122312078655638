import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { MobileHeaderWrapper } from 'components/MobileHeader/components/MobileHeaderWrapper';
import { MobileHeaderLogoImage } from 'components/MobileHeader/components/MobileHeaderLogo';
import { MobileHeaderDrawerButton } from 'components/MobileHeader/components/MobileHeaderDrawerButton';
import { useAppState } from 'hooks/useAppState';

export const MobileHeader = () => {
  const dispatch = useAppDispatch();

  const { mobileDrawerOpened } = useAppState();

  const handleDrawerButtonClick = React.useCallback(() => {
    dispatch({
      name: 'MOBILE_HEADER__DRAWER_BUTTON_CLICK'
    });
  }, [dispatch]);

  return (
    <MobileHeaderWrapper>
      <MobileHeaderLogoImage />
      <MobileHeaderDrawerButton
        collapsed={!!mobileDrawerOpened}
        onClick={handleDrawerButtonClick}
      />
    </MobileHeaderWrapper>
  );
};
