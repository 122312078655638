import React from 'react';

import { useContextStrict } from 'hooks/useContextStrict';
import { ServicesContext } from 'contexts/ServicesContext';
import { useToggler } from 'hooks/useToggler';
import { checkNewsArticleIsReadyForSubmit } from 'utils/articleUtils';
import { TNewsArticle } from 'types/state';
import { delayedAsyncFunction } from 'utils/delayedAsyncFunction';
import { ARTICLE_SUBMIT_DELAY } from 'utils/constants';
import { useAsyncController } from 'hooks/useAsyncController';
import { TSubmitNewsArticleParams } from 'types/services';

type TProps = {
  data: TNewsArticle;
  onCancel: () => void;
  onSumbited: (id: number, asDraft: boolean) => void;
};

export const useNewsPreviewFooterState = (props: TProps) => {
  const submitedAsDraftRef = React.useRef<null | boolean>(null);

  const { data: article, onSumbited, onCancel } = props;

  const [notifyFlag, setNotyfyFlag] = useToggler(false);

  // Effects
  const { submitNewsArticle: submitService } = useContextStrict(
    ServicesContext
  );

  const submitWithDelay = React.useMemo(
    () => delayedAsyncFunction(submitService, ARTICLE_SUBMIT_DELAY),
    [submitService]
  );

  const {
    wrapAsync: wrapSubmitting,
    asyncProcess: submittingProcess
  } = useAsyncController();

  const submit = React.useCallback(
    (params: TSubmitNewsArticleParams) => {
      const promise = submitWithDelay(params).then(value => {
        onSumbited(value.id, params.saveAsDraft);
      });

      wrapSubmitting(promise);
    },
    [onSumbited, submitWithDelay, wrapSubmitting]
  );

  // Normalization

  // реализация чекбокса нужна, но сам чекбокс временно скрыли.
  const hasNotifyCheckbox = false;

  const handleSubmit = React.useCallback(() => {
    submitedAsDraftRef.current = false;

    submit({
      data: article,
      saveAsDraft: false,
      sendNotification: article.isDraft
    });
  }, [article, submit]);

  const handleSubmitDraft = React.useCallback(() => {
    submitedAsDraftRef.current = true;

    submit({
      data: article,
      saveAsDraft: true,
      sendNotification: false
    });
  }, [article, submit]);

  const handleCancel = onCancel;

  const handleNotifyCheckboxChange = setNotyfyFlag;

  const isSubmitButtonDisabled = !checkNewsArticleIsReadyForSubmit(article);

  const isNotifyCheckboxChecked = notifyFlag;

  const hasSubmitButtonPreloader =
    submittingProcess.status === 'PENDING' &&
    submitedAsDraftRef.current === false;

  const hasSubmitDraftButtonPreloader =
    submittingProcess.status === 'PENDING' &&
    submitedAsDraftRef.current === true;

  const hasSubmitDraftButton = React.useMemo(
    () =>
      article.isDraft &&
      checkNewsArticleIsReadyForSubmit(article, { asDraft: true }),
    [article]
  );

  return {
    isSubmitButtonDisabled,
    isNotifyCheckboxChecked,
    hasSubmitDraftButton,
    hasSubmitButtonPreloader,
    hasSubmitDraftButtonPreloader,
    hasNotifyCheckbox,
    handleSubmit,
    handleSubmitDraft,
    handleCancel,
    handleNotifyCheckboxChange
  };
};
