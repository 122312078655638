import mimeTypes from 'mime';

import { HEADER_NAME_CONTENT_TYPE, FALLBACK_FILENAME } from 'utils/constants';

export const normalizeURLToFile = async (url: string): Promise<File> => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const mime = response.headers.get(HEADER_NAME_CONTENT_TYPE);
  if (mime) {
    const extension = mimeTypes.getExtension(mime);
    if (extension) {
      return new File([buffer], `${FALLBACK_FILENAME}.${extension}`, {
        type: mime
      });
    }
    throw new Error();
  }
  throw new Error();
};
