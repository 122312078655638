import React from 'react';
import { multiplyClassName } from 'utils/className';

import styles from './BookingTimeWorkFormTogglerStyles.module.scss';

type Props = {
  text: React.ReactNode;
  active: boolean;
  onClick(): void;
};

export const BookingTimeWorkFormTogglerComponent = (props: Props) => {
  const { text, active, onClick } = props;

  return (
    <button
      className={multiplyClassName([
        styles.toggler,
        active ? styles.active : ''
      ])}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
