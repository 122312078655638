import React from 'react';

import styles from './labeledIconLayoutStyles.module.scss';

type Props = {
  icon: React.ReactNode;
  text: React.ReactNode;
};

export const LabeledIconLayout = (props: Props) => {
  const { icon, text } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconSlot}>{icon}</div>
      <div className={styles.textSlot}>{text}</div>
    </div>
  );
};
