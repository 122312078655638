import React from 'react';

import styles from './CompetitionsArticle.module.css';

type TProps = {
  label: React.ReactNode;
  placeholder: string;
};

export const CompetitionsArticleTitle = ({ label, placeholder }: TProps) => {
  if (label) {
    return <div className={styles.CompetitionsArticle__Title}>{label}</div>;
  }
  return (
    <div className={styles.CompetitionsArticle__TitlePlaceholder}>
      {placeholder}
    </div>
  );
};
