import { TCompetitionsArticle } from 'types/state';
import { TSportingCompetitionsArticle } from 'services/sporting/types';
import {
  getSportingRawImageURL,
  getSportingProcessedCompetitionsImageURL
} from 'services/sporting/utils/getSportingURL';

export const normalizeSportingCompetitionsArticle = {
  toCompetitionsArticle: (
    item: TSportingCompetitionsArticle
  ): TCompetitionsArticle => ({
    id: item.id,
    title: item.title,
    description: item.body,
    image:
      item.fullImageName && item.processedImageName
        ? {
            original: getSportingRawImageURL(item.fullImageName),
            cropped: getSportingProcessedCompetitionsImageURL(
              item.processedImageName
            )
          }
        : null,
    duration: (() => {
      if (item.beginDate && item.endDate) {
        return {
          startDate: new Date(item.beginDate).toISOString(),
          endDate: new Date(item.endDate).toISOString()
        };
      }
      return null;
    })(),
    isDraft: item.contentStatus === 'Draft'
  })
};
