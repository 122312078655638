import { MemberVerificationStatusService } from 'types/services';
import { sportingAPI } from '../api';

export const toggleMemberVerivicationStatus: MemberVerificationStatusService = async params => {
  await sportingAPI.service.setUserDataStatus({
    payload: {
      idUser: params.memberId,
      status: params.verified ? 'VERIFIED' : 'NOT_VERIFIED'
    }
  });
};
