import React from 'react';
import { multiplyClassName } from 'utils/className';

import styles from './applicationsTableRoundButtonStyles.module.scss';

type Props = {
  theme: 'accent' | 'primary' | 'secondary';
  icon: React.ReactNode;
  onClick(): void;
};

export const ApplicationsTableRoundButton = (props: Props) => {
  const { onClick, icon, theme } = props;

  return (
    <button
      className={multiplyClassName([
        styles.wrapper,
        styles[`wrapper__theme_${theme}`]
      ])}
      onClick={onClick}
    >
      <div className={styles.iconSlot}>{icon}</div>
    </button>
  );
};
