import { AddMessageTemplateParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { messageTemplateStoreMock } from 'services/mock/utils/messageTemplateModel';
import { getUid } from 'utils/getUid';

export const addMessageTemplate = async (
  params: AddMessageTemplateParams
): Promise<void> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  messageTemplateStoreMock.addTemplate({
    id: getUid(),
    text: params.text,
    position: 0
  });
};
