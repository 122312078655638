import { MessageTemplatesState, MessageTemplate } from 'types/messageTemplate';
import { AppEvent } from 'types/appEvent';
import { modifyListCache, createListCache } from 'utils/listCacheUtils';
import { createMessageTemplatesState } from 'utils/messageTemplateUtils';

export const messageTemplatesStateReducer = (
  state: MessageTemplatesState,
  event: AppEvent
): MessageTemplatesState => {
  switch (event.name) {
    case 'MESSAGE_TEMPLATES__ADD_CLICKED':
      return {
        ...state,
        mode: 'ADD'
      };
    case 'MESSAGE_TEMPLATES__EDIT_CLICKED':
      return {
        ...state,
        mode: 'EDIT'
      };
    case 'CHATS_PAGE__ESCAPE_CLICKED':
    case 'MESSAGE_TEMPLATES__CANCEL_CLICKED': {
      return {
        ...createMessageTemplatesState(),
        messageTemplatesList: state.messageTemplatesList
      };
    }
    case 'MESSAGE_TEMPLATES__TEMPLATES_RECIEVED': {
      return {
        ...state,
        messageTemplatesList: modifyListCache(state.messageTemplatesList)
          .putItems(event.page, event.templates)
          .return()
      };
    }
    case 'CHATS_PAGE__OPENED': {
      return {
        ...state,
        messageTemplatesList: modifyListCache(
          createListCache<MessageTemplate>()
        )
          .invalidatePage(1)
          .return()
      };
    }
    case 'MESSAGE_TEMPLATES__CANDIDATE_TEXT_CHANGED': {
      return {
        ...state,
        templateCandidateText: event.nextValue
      };
    }
    case 'MESSAGE_TEMPLATE_MOVE_SERVICE': {
      if (event.status === 'RESOLVED') {
        return {
          ...createMessageTemplatesState(),
          messageTemplatesList: modifyListCache(state.messageTemplatesList)
            .invalidateAllPages()
            .return()
        };
      }

      return state;
    }
    case 'CHATS_PAGE__CLOSED':
    case 'MESSAGE_TEMPLATES__TEMPLATE_ADDED':
    case 'MESSAGE_TEMPLATES__TEMPLATES_EDITED':
    case 'MESSAGE_TEMPLATES__TEMPLATE_DELETED': {
      return {
        ...createMessageTemplatesState(),
        messageTemplatesList: modifyListCache(
          createListCache<MessageTemplate>()
        )
          .invalidatePage(1)
          .return()
      };
    }
    case 'MESSAGE_TEMPLATES__SCROLL_LEFT_TO_LOAD_MORE_REACHED': {
      return {
        ...state,
        messageTemplatesList: modifyListCache(state.messageTemplatesList)
          .invalidateNextPage()
          .return()
      };
    }
    case 'MESSAGE_TEMPLATES__TEMPLATE_TEXT_CHANGED': {
      let nextChangedTemplates: MessageTemplate[];

      let templateFound = false;

      nextChangedTemplates = state.changedTemplates.map(template => {
        if (template.id === event.template.id) {
          templateFound = true;

          return {
            ...template,
            text: event.nextValue
          };
        }

        return template;
      });

      if (!templateFound) {
        nextChangedTemplates = [
          ...state.changedTemplates,
          {
            ...event.template,
            text: event.nextValue
          }
        ];
      }

      return {
        ...state,
        changedTemplates: nextChangedTemplates
      };
    }
    case 'MESSAGE_TEMPLATES__DELETE_CLICKED': {
      return {
        ...state,
        deletedTemplateIds: [...state.deletedTemplateIds, event.templateId]
      };
    }
    default:
      return state;
  }
};
