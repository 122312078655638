import React from 'react';

import { TReviewFeedItem } from 'types/state';
import { ReviewItemWrapper } from 'containers/ReviewItem/view/styled/ReviewItemWrapper';
import { ReviewItemTitle } from 'containers/ReviewItem/view/styled/ReviewItemTitle';
import { ReviewItemDate } from 'containers/ReviewItem/view/styled/ReviewItemDate';
import { ReviewItemStatus } from 'containers/ReviewItem/view/styled/ReviewItemStatus';

type TProps = {
  item: TReviewFeedItem;
  selected: boolean;
  hasAccentTheme: boolean;
  hasSecondaryTheme: boolean;
  onClick(): void;
};

export type TReviewItemViewProps = TProps;

export const ReviewItemView = (props: TProps) => {
  const { item, selected, hasAccentTheme, hasSecondaryTheme, onClick } = props;

  const reviewNode = React.useMemo(
    () => (
      <ReviewItemWrapper
        hasAccentTheme={hasAccentTheme}
        hasSecondaryTheme={hasSecondaryTheme}
        selected={selected}
        onClick={onClick}
      >
        <ReviewItemTitle text={item.title} />
        <ReviewItemDate text={item.date} />
        <ReviewItemStatus text={item.status} />
      </ReviewItemWrapper>
    ),
    [item, selected, hasAccentTheme, hasSecondaryTheme, onClick]
  );

  return reviewNode;
};
