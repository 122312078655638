import React from 'react';

import { SignInPageTemplate } from 'components/SignInPage/SignInPageTemplate';
import { SignInPageForm } from 'components/SignInPage/SignInPageForm';
import { SignInPageLogo } from 'components/SignInPage/SignInPageLogo';
import { SignInPageFieldset } from 'components/SignInPage/SignInPageFieldset';
import { SignInPageInput } from 'components/SignInPage/SignInPageInput';
import { SignInPageError } from 'components/SignInPage/SignInPageError';
import { SignInPageSubmitButton } from 'components/SignInPage/SingInPageSubmitButton';
import { testId } from 'utils/testId';
import { TSignInFormValues } from 'types/state';

type TProps = {
  usernameInputPlaceholder: string;
  passwordInputPlaceholder: string;
  submitButtonLabel: string;
  values: TSignInFormValues;
  errorMessage: null | string;
  hasDisabledSubmitButton: boolean;
  hasDisabledInputs: boolean;
  hasPreloader: boolean;
  handleFormSubmit(event: React.SyntheticEvent): void;
  handleSubmitButtonClick(): void;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

export const SignInPageView = (props: TProps) => {
  const {
    hasDisabledSubmitButton,
    hasDisabledInputs,
    hasPreloader,
    usernameInputPlaceholder,
    passwordInputPlaceholder,
    submitButtonLabel,
    values,
    errorMessage,
    handleFormSubmit,
    handleSubmitButtonClick,
    handleChange
  } = props;

  return (
    <SignInPageTemplate>
      <SignInPageForm onSubmit={handleFormSubmit}>
        <SignInPageLogo />
        <SignInPageFieldset>
          <SignInPageInput
            name="username"
            type="text"
            placeholder={usernameInputPlaceholder}
            value={values.username}
            onChange={handleChange}
            testId={testId.SIGN_IN_USERNAME_INPUT}
            isDisabled={hasDisabledInputs}
          />
          <SignInPageInput
            name="password"
            type="password"
            placeholder={passwordInputPlaceholder}
            value={values.password}
            onChange={handleChange}
            testId={testId.SIGN_IN_PASSWORD_INPUT}
            isDisabled={hasDisabledInputs}
          />
        </SignInPageFieldset>
        {errorMessage && (
          <SignInPageError text={errorMessage} testId={testId.SIGN_IN_ERROR} />
        )}
        <SignInPageSubmitButton
          hasPreloader={hasPreloader}
          isDisabled={hasDisabledSubmitButton}
          label={submitButtonLabel}
          onClick={handleSubmitButtonClick}
          testIdButton={testId.SIGN_IN_SUBMIT_BUTTON}
          testIdSpinner={testId.SIGN_IN_SPINNER}
        />
      </SignInPageForm>
    </SignInPageTemplate>
  );
};
