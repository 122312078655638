import React from 'react';
import anime from 'animejs';

import styles from './AuthorizedScreen.module.scss';

import { multiplyClassName } from 'utils/className';
import { useAppState } from 'hooks/useAppState';

type Props = {
  children: React.ReactNode;
};

export const AuthorizedScreenSidebar: React.FC<Props> = props => {
  const { mobileDrawerOpened } = useAppState();

  const sidebarRef = React.useRef<HTMLDivElement>(null);

  const [openedStyle, setOpenedStyle] = React.useState('');

  React.useEffect(() => {
    const targets = sidebarRef.current;
    if (targets) {
      if (mobileDrawerOpened === null) {
        setOpenedStyle('');
      } else {
        if (mobileDrawerOpened) {
          anime({
            targets,
            translateX: [window.innerWidth, 0],
            easing: 'easeOutQuart',
            duration: 450,
            complete: () => {
              targets.style.transform = '';
              setOpenedStyle(styles.AuthorizedScreen__MobileSidebarOpened);
            }
          });
        } else {
          setOpenedStyle('');
          anime({
            targets,
            translateX: [0, window.innerWidth],
            easing: 'easeOutQuart',
            duration: 450,
            complete: () => {
              targets.style.transform = '';
            }
          });
        }
      }
    }
  }, [mobileDrawerOpened, sidebarRef]);

  return (
    <div
      ref={sidebarRef}
      {...props}
      className={multiplyClassName([
        styles.AuthorizedScreen__Sidebar,
        openedStyle
      ])}
    />
  );
};
