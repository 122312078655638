import React from 'react';

import styles from './ReviewsFeed.module.css';

import { testId } from 'utils/testId';

type TProps = {
  text: string;
};

export const ReviewsFeedTitle = ({ text }: TProps) => (
  <div
    className={styles.ReviewsFeed__Title}
    data-testid={testId.REVIEWS_FEED_TITLE}
  >
    {text}
  </div>
);
