import { LoginFormError, LoginFormValues } from 'types/state';

export const validateLoginFormValues = (
  values: LoginFormValues
): LoginFormError | undefined => {
  if (!values.login) {
    return 'LOGIN_IS_EMPTY';
  }
  if (!values.password) {
    return 'PASSWORD_IS_EMPTY';
  }
};
