import React from 'react';

export function useWindowEvent(event: string, handler: EventListener) {
  React.useEffect(() => {
    window.addEventListener(event, handler);
    return () => {
      window.removeEventListener(event, handler);
    };
  });
}
