import React from 'react';

import { RectangleButton } from 'components/shared/RectangleButton';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useIntlContext } from 'hooks/useIntlContext';
import { SectionsFormInput } from 'containers/SectionsForm/SectionsFormInput';
import { SectionsFormWrapper } from 'containers/SectionsForm/components/SectionsFormWrapper';
import { useAvailableSections } from 'hooks/useAvailableSections';
import { useSectionsFormDidSucceed } from 'hooks/sectionsForm/useSectionsFormDidSucceed';
import { useSectionsFormSynced } from 'hooks/sectionsForm/useSectionsFormSynced';
import { useSessionFormBusy } from 'hooks/sectionsForm/useSectionsFormBusy';
import { SettingsFormFooter } from 'components/SettingsForm/SettingsFormFooter';
import { SettingsFormFieldset } from 'components/SettingsForm/SettingsFormFieldset';

export const SectionsForm = () => {
  const { sectionsForm: sectionsFormIntl } = useIntlContext();

  const dispatch = useAppDispatch();

  const handleSubmit = React.useCallback(() => {
    dispatch({
      name: 'SECTIONS_FORM__SUBMIT'
    });
  }, [dispatch]);

  const handleCancel = React.useCallback(() => {
    dispatch({
      name: 'SECTIONS_FORM__CANCEL'
    });
  }, [dispatch]);

  const didSucceed = useSectionsFormDidSucceed();

  const synced = useSectionsFormSynced();

  const busy = useSessionFormBusy();

  const submitButtonLabel =
    didSucceed && synced
      ? sectionsFormIntl.submitedLabel
      : sectionsFormIntl.submitLabel;

  const sections = useAvailableSections();

  const hasFooter = didSucceed || !synced;

  const hasCancelButton = !synced && !didSucceed && !busy;

  const submitButtonDisabled = synced || busy;

  const submitButtonHasLoader = busy;

  return (
    <SectionsFormWrapper onSubmit={handleSubmit}>
      <SettingsFormFieldset disabled={busy}>
        {sections.map(section => (
          <SectionsFormInput key={section} section={section} />
        ))}
      </SettingsFormFieldset>
      {hasFooter && (
        <SettingsFormFooter>
          {hasCancelButton && (
            <RectangleButton
              type="button"
              label={sectionsFormIntl.cancelLabel}
              styleType="secondary"
              onClick={handleCancel}
            />
          )}
          <RectangleButton
            label={submitButtonLabel}
            hasPreloader={submitButtonHasLoader}
            styleType="primary"
            isDisabled={submitButtonDisabled}
          />
        </SettingsFormFooter>
      )}
    </SectionsFormWrapper>
  );
};
