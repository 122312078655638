import React from 'react';

import styles from './ApplicationsTable.module.scss';

type TProps = {
  children: React.ReactNode;
  testId: string;
};

export const ApplicationsTableHeader = ({ children, testId }: TProps) => (
  <div className={styles.ApplicationsTable__Header} data-testid={testId}>
    <div className={styles.ApplicationsTable__HeaderRow}>{children}</div>
  </div>
);
