import { TSportingUserInfo } from 'services/sporting/types';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';

export const getEmployees = async (): Promise<TSportingUserInfo[]> => {
  const endpointURL = getSportingServiceURL('getEmployers');

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (!response.ok) {
    throw new UnexpectedError();
  }
  return response.json();
};
