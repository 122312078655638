import { SportingSecureParams } from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { appendFormDataWithObjectValues } from '../../../../utils/appendFormDataWithObjectValues';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';

type Payload = {
  ids: number[];
};

export const deleteWaiters = async (
  params: SportingSecureParams<Payload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingBookingURL('deleteWaiters');

  const headers = createSportingHeaders();

  const body = new FormData();
  appendFormDataWithObjectValues(body, payload);

  const response = await customFetch(endpointURL, {
    method: 'DELETE',
    body,
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
