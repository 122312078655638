import React from 'react';

import { SelectOption } from 'types/util';
import { useIntlContext } from 'hooks/useIntlContext';
import { usePhoneNumbers } from 'hooks/usePhoneNumbers';

export const usePhoneNumberSelectOptions = () => {
  const { phoneNumberFallback } = useIntlContext();

  const phoneNumbers = usePhoneNumbers();

  return React.useMemo<SelectOption<string>[]>(() => {
    const fallbackOption = {
      value: '',
      label: phoneNumberFallback
    };

    if (phoneNumbers) {
      const options = phoneNumbers.map((option: string) => ({
        value: option,
        label: option
      }));
      return [fallbackOption, ...options];
    } else {
      return [fallbackOption];
    }
  }, [phoneNumberFallback, phoneNumbers]);
};
