import React from 'react';

import './DateLineLayout.scss';

type Props = {
  dateInput: React.ReactNode;
  dateLineNavigationLeft: React.ReactNode;
  dateLineNavigationRight: React.ReactNode;
  dateLineOptions: React.ReactNode;
  resetAction: React.ReactNode;
};

export const DateLineLayout = (props: Props) => {
  const {
    dateInput,
    dateLineNavigationLeft,
    dateLineNavigationRight,
    dateLineOptions,
    resetAction
  } = props;

  return (
    <div className={'date-line'}>
      <div className={'date-input-slot'}>{dateInput}</div>
      <div className={'date-line-panel'}>
        <div className={'date-line-nav-slot'}>{dateLineNavigationLeft}</div>
        <div className={'date-line-container'}>{dateLineOptions}</div>
        <div className={'date-line-nav-slot'}>{dateLineNavigationRight}</div>
      </div>
      <div className={'date-line-reset-action-slot'}>{resetAction}</div>
    </div>
  );
};
