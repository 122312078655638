import { AppDispatch } from 'types/state';
import { SetChatRoomReadStatusParams } from 'types/services';

export const chatRoomReadStatusThunk = (
  promise: Promise<void>,
  meta: SetChatRoomReadStatusParams
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'CHAT_ROOM_READ_STATUS_SERVICE',
    status: 'PENDING',
    meta
  });

  return promise.then(() => {
    dispatch({
      name: 'CHAT_ROOM_READ_STATUS_SERVICE',
      status: 'RESOLVED',
      payload: undefined,
      meta
    });
  });
};
