import React from 'react';

import styles from './membersTableItemLayout.module.css';

type Props = {
  icon: React.ReactNode;
  cardNumber: React.ReactNode;
  phoneNumber: React.ReactNode;
  fullName: React.ReactNode;
  statusLabel: React.ReactNode;
  statusInput: React.ReactNode;
};

export const MembersTableItemLayout: React.FC<Props> = ({
  icon,
  cardNumber,
  phoneNumber,
  fullName,
  statusLabel,
  statusInput
}) => (
  <div className={styles.MembersTableItemLayout__Wrapper}>
    <div className={styles.MembersTableItemLayout__IconSlot}>{icon}</div>
    <div className={styles.MembersTableItemLayout__CardNumberSlot}>
      {cardNumber}
    </div>
    <div className={styles.MembersTableItemLayout__PhoneNumberSlot}>
      {phoneNumber}
    </div>
    <div className={styles.MembersTableItemLayout__FullNameSlot}>
      {fullName}
    </div>
    <div className={styles.MembersTableItemLayout__StatusLabelSlot}>
      {statusLabel}
    </div>
    <div className={styles.MembersTableItemLayout__StatusInputSlot}>
      {statusInput}
    </div>
  </div>
);
