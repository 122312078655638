import { TApplicationsRestType, TApplicationStatus } from 'types/state';
import { UnreachableError } from 'utils/errors';

export const normalizeApplicationsRestType = {
  toApplicationStatus(status: TApplicationsRestType): TApplicationStatus {
    switch (status) {
      case 'RESTAURANT_NEW':
      case 'SPORT_NEW':
        return 'NEW';
      case 'RESTAURANT_PROCESSED':
      case 'SPORT_PROCESSED':
        return 'DONE';
      default:
        throw new UnreachableError(status);
    }
  }
};
