import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';
import React from 'react';
import { TUpdateReservationParams } from '../../types/services';

export const useBookingReservationUpdateService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const { wrapAsync: wrap, asyncProcess } = useAsyncController();

  const updateReservation = React.useCallback(
    (params: TUpdateReservationParams) => {
      const promise = services.updateReservation(params).then(() => {
        appDispatch({
          name: 'BOOKING_RESERVATION_UPDATED',
          reservationId: params.formValues.id
        });
      });

      wrap(promise);
    },
    [appDispatch, services, wrap]
  );

  return { updateReservation, asyncProcess };
};
