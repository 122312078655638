import React from 'react';

import styles from './CropImageModal.module.css';

type Props = {
  children: React.ReactNode;
};

export const CropImageModalFooter: React.FC<Props> = ({ children }) => (
  <div className={styles.CropImageModal__Footer}>{children}</div>
);
