import React from 'react';

import styles from './AuthorizedScreen.module.scss';

type TProps = {
  children: React.ReactNode;
  testId?: string;
};

export const AuthorizedScreenTemplate: React.FC<TProps> = ({
  testId,
  ...nativeProps
}: TProps) => (
  <div
    {...nativeProps}
    className={styles.AuthorizedScreen__Template}
    data-testid={testId}
  />
);
