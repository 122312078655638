import { TCompetitionsArticle } from 'types/state';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';
import { normalizeISODate } from 'services/sporting/utils/normalizeISODate';

export const normalizeCompetitionsArticle = {
  toSportingFormData(data: TCompetitionsArticle, saveAsDraft: boolean) {
    const formData = new FormData();

    formData.append('title', data.title);
    formData.append('body', data.description);
    if (data.image) {
      if (data.image.original instanceof File) {
        formData.append('fullFile', data.image.original);
      }
      if (data.image.cropped instanceof File) {
        formData.append('processedFile', data.image.cropped);
      }
    } else {
      formData.append('fullFile', 'null');
      formData.append('processedFile', 'null');
    }
    if (data.duration) {
      const { startDate, endDate } = data.duration;
      formData.append('beginDate', normalizeISODate.toSportingDate(startDate));
      formData.append('endDate', normalizeISODate.toSportingDate(endDate));
    }
    formData.append('draft', `${saveAsDraft}`);

    if (data.id !== NEWS_ARTICLE_CANDIDATE_ID) {
      formData.append('idCompetition', `${data.id}`);
    }

    return formData;
  }
};
