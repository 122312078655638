import { TNewsArticle, TNewsFeedItem } from 'types/state';

export const normalizeNewsArticleToFeedItem = (
  article: TNewsArticle
): TNewsFeedItem => {
  const { id, title, image, publishDate } = article;
  const imageSrc = (() => {
    if (image) {
      const { cropped: imageCropped } = image;
      if (typeof imageCropped === 'string') {
        return imageCropped;
      }
      if (imageCropped instanceof File) {
        return URL.createObjectURL(imageCropped);
      }
      return null;
    }
    return null;
  })();
  return {
    id,
    title,
    imageSrc,
    publishDate
  };
};
