import { customFetch } from 'utils/customFetch';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { UnexpectedError } from 'utils/errors';
import { TSportingClaim } from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

export const getNewRestarauntClaims = async (): Promise<TSportingClaim[]> => {
  const endpointURL = getSportingServiceURL(`getNewRestarauntClaims`);

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  return response.json();
};
