import React from 'react';

import styles from './AuthorizedScreen.module.scss';

import { getScrollLeftPx } from 'utils/getScrollLeftPx';

type Props = {
  children: React.ReactNode;
  onScrolledToBottom(): void;
};

export const AuthorizedScreenContent: React.FC<Props> = props => {
  const { children, onScrolledToBottom } = props;

  const handleScroll = React.useCallback(
    (event: React.SyntheticEvent) => {
      const { target } = event;
      if (target instanceof Element) {
        const scrollLeftPx = getScrollLeftPx(target);

        if (scrollLeftPx === 0) {
          onScrolledToBottom();
        }
      }
    },
    [onScrolledToBottom]
  );

  return (
    <div onScroll={handleScroll} className={styles.AuthorizedScreen__Content}>
      {children}
    </div>
  );
};
