import React from 'react';

import styles from './CustomDatePicker.module.css';

type TProps = {
  monthName: string;
  children: React.ReactNode;
  order: number;
};

export const CustomDatePickerMonthTemplate = ({
  monthName,
  children,
  order
}: TProps) => (
  <div className={styles.CustomDatePicker__MonthGroup} style={{ order }}>
    <div className={styles.CustomDatePicker__MonthName}>{monthName}</div>
    <div className={styles.CustomDatePicker__MonthDates}>{children}</div>
  </div>
);
