import { Cache } from 'types/util';

export const createCache = <ValueType>(
  initialValue: ValueType
): Cache<ValueType> => ({ value: initialValue, didInvalidate: false });

export const modifyCache = <ValueType>(cache: Cache<ValueType>) => {
  return {
    invalidate() {
      return modifyCache({
        ...cache,
        didInvalidate: true
      });
    },
    setValue(value: ValueType) {
      return modifyCache({
        value,
        didInvalidate: false
      });
    },
    return() {
      return cache;
    }
  };
};
