import { PasswordFormValues } from 'types/state';

export const patchPasswordFormValues = (values: PasswordFormValues) => ({
  done() {
    return values;
  },
  setPassword(nextValue: string) {
    return patchPasswordFormValues({
      ...values,
      password: nextValue
    });
  },
  setPasswordConfirm(nextValue: string) {
    return patchPasswordFormValues({
      ...values,
      passwordConfirm: nextValue
    });
  }
});
