import { PasswordEditorState } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { createPasswordEditorState } from 'utils/createPasswordEditorState';
import { patchEditorState } from 'utils/patchEditorState';
import { patchPasswordFormValues } from 'utils/patchPasswordFormValues';
import { createPasswordFormValues } from 'utils/createPasswordFormValues';
import { validatePasswordFormValues } from 'utils/validatePasswordFormValues';

export const passwordEditorStateReducer = (
  state: PasswordEditorState,
  event: AppEvent
): PasswordEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return createPasswordEditorState();
    }
    case 'PASSWORD_FORM__PASSWORD_INPUT_CHANGE':
    case 'PASSWORD_FORM__PASSWORD_CONFIRM_INPUT_CHANGE': {
      let nextFormValues = patchPasswordFormValues(state.formValues);

      if (event.name === 'PASSWORD_FORM__PASSWORD_INPUT_CHANGE') {
        nextFormValues = nextFormValues.setPassword(event.value);
      }

      if (event.name === 'PASSWORD_FORM__PASSWORD_CONFIRM_INPUT_CHANGE') {
        nextFormValues = nextFormValues.setPasswordConfirm(event.value);
      }

      const nextPasswordEditorState = patchEditorState(state)
        .setSubmitDidSucceed(false)
        .setFormValues(nextFormValues.done())
        .clearFormError();

      return nextPasswordEditorState.done();
    }

    case 'PASSWORD_FORM__CANCEL': {
      const nextPasswordEditorState = patchEditorState(state)
        .clearFormError()
        .setFormValues(createPasswordFormValues())
        .done();

      return nextPasswordEditorState;
    }

    case 'PASSWORD_FORM__SUBMIT': {
      const { formValues } = state;

      const formError = validatePasswordFormValues(formValues);

      let nextFormValues = patchPasswordFormValues(formValues);

      let nextPasswordEditorState = patchEditorState(state);

      if (formError !== undefined) {
        nextFormValues = nextFormValues.setPassword('').setPasswordConfirm('');

        nextPasswordEditorState = nextPasswordEditorState
          .setFormError(formError)
          .setFormValues(nextFormValues.done());
      } else {
        nextPasswordEditorState = nextPasswordEditorState.setSubmited(true);
      }

      return nextPasswordEditorState.done();
    }

    case 'UPDATE_CURRENT_USER_PASSWORD_SERVICE': {
      let nextPasswordEditorState = patchEditorState(state);

      if (event.status === 'PENDING') {
        nextPasswordEditorState = nextPasswordEditorState
          .clearFormError()
          .setSubmitDidSucceed(false);
      }

      if (event.status === 'RESOLVED') {
        nextPasswordEditorState = nextPasswordEditorState
          .setFormValues(createPasswordFormValues())
          .clearFormError()
          .setSubmited(false)
          .setSubmitDidSucceed(true);
      }

      if (event.status === 'REJECTED') {
        nextPasswordEditorState = nextPasswordEditorState
          .setFormError('SERVICE_FAILED')
          .setSubmited(false);
      }

      return nextPasswordEditorState.done();
    }
    default:
      return state;
  }
};
