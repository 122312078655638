import React from 'react';
import styles from './BookingPlaceItemLayout.module.scss';
import { EN_DASH } from 'utils/constants';

type Props = {
  nameInput: React.ReactNode;
  minCountInput: React.ReactNode;
  maxCountInput: React.ReactNode;
  dndControl: React.ReactNode;
  deleteControl: React.ReactNode;
};

export const BookingPlaceItemLayout = (props: Props) => {
  const {
    nameInput,
    minCountInput,
    maxCountInput,
    dndControl,
    deleteControl
  } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.nameInputSlot}>{nameInput}</div>
      <div className={styles.countInputGroup}>
        <div className={styles.minCountInputSlot}>{minCountInput}</div>
        {EN_DASH}
        <div className={styles.maxCountInputSlot}>{maxCountInput}</div>
      </div>
      <div className={styles.controlsGroup}>
        <div className={styles.dndControlSlot}>{dndControl}</div>
        <div className={styles.deleteControlSlot}>{deleteControl}</div>
      </div>
    </div>
  );
};
