import { SportingSecureParams, SportingSession } from 'services/sporting/types';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';
import { appendFormDataWithObjectValues } from 'utils/appendFormDataWithObjectValues';
import { customFetch } from 'utils/customFetch';
import {
  InvalidPasswordError,
  LoginInUseError,
  UnexpectedError
} from 'utils/errors';

type Payload = {
  newLogin: string;
  password: string;
};

export const changeLoginForCurrentUser = async (
  params: SportingSecureParams<Payload>
): Promise<SportingSession> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL('changeLogin');

  const headers = createSportingHeaders();

  const body = new FormData();
  appendFormDataWithObjectValues(body, payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    headers,
    body
  });

  if (!response.ok) {
    if (response.status === 400) {
      const responseBody = await response.json();
      if (responseBody.message === 'Username already in use') {
        throw new LoginInUseError();
      } else if (responseBody.message === 'Invalid password') {
        throw new InvalidPasswordError();
      }
    }
    throw new UnexpectedError();
  }

  return response.json();
};
