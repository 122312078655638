import { BookingTimelineGroup } from 'types/bookingTimeline';
import { Place } from 'types/state';

export function normalizePlace(place: Place) {
  const { id, title, items } = place;

  return {
    toBookingTimelineGroup(): BookingTimelineGroup {
      return {
        id,
        name: title,
        items: items.sort((item1, item2) => {
          return item1.orderNumber - item2.orderNumber;
        })
      };
    }
  };
}
