import React from 'react';

import { TNewsArticle } from 'types/state';
import { useNewsService } from 'pages/NewsPage/hooks/useNewsService';
import { TGetNewsFeedParams } from 'types/services';

type TNewsArticlesById = Map<number, TNewsArticle>;

type TLoadParams = Omit<TGetNewsFeedParams, 'authorization'> & {
  shouldInvalidate?: boolean;
};

// TODO: на всякий случай надо упомянуть, что при загрузке каждой
// последующей страницы может произойти ситуация, когда содержимое
// одной из предыдущих страниц поменялось.
//
// В случае если одну статью добавили - следующая страница будет
// содержать второй экземпляр одной из записей с предыдущей страницы
// из-за смещения вперед.
//
// В случае если одну статью удалили - одна из статей пропадет со
// следующей страницы из-за смещения назад и таким образом не попадет
// в кэш вовсе. Это уже серьезный повод задуматься над тем, чтобы
// запрашивать все страницы с ноля даже при подгрузке.

export const useNewsCache = () => {
  // Флаг, предотвращающий дальнейший вызов сервиса.
  const preventServiceCallRef = React.useRef(false);

  // Состояние и метод вызова сервиса на получение статей.
  const { pullNewsFeed, newsFeedPullingProcess } = useNewsService();

  // Флаг указывает на продолжающееся выполнение сервиса.
  const [loading, setLoading] = React.useState<null | boolean>(null);

  // Состояние ленты статей. Используется Map для удобного хранения
  // статей в формате id: article с сохранением той последовательности,
  // в которой эти статьи возвращались сервисом.
  const [itemsById, setItemsById] = React.useState<TNewsArticlesById>(
    new Map()
  );

  // Метод отвечает за вызов сервиса на получение статей.
  const load = React.useCallback(
    (params: TLoadParams) => {
      const { shouldInvalidate, ...serviceParams } = params;

      // В случае переданного флага инвалиадации кэша
      // необходимо сбросить состояние со статьями.
      if (shouldInvalidate) {
        setItemsById(new Map());
        preventServiceCallRef.current = false;
      }

      // Вызов сервиса должен происходить только если
      // не выставлен соответствующий запрещающий флаг.
      // По задумке запрещающий флаг должен быть выставлен
      // в ситуации, когда была загружена пустая страница.
      if (!preventServiceCallRef.current) {
        pullNewsFeed(serviceParams);
      }
    },
    [pullNewsFeed]
  );

  // Эффект следит за состоянием запроса на получение
  // статей для конкретной страницы и обрабатывает
  // статьи в случае успеха.
  React.useEffect(() => {
    if (newsFeedPullingProcess.status === 'RESOLVED') {
      const { value: newItems } = newsFeedPullingProcess;

      if (newItems.length > 0) {
        // Новые статьи подмешиваются в состояние ленты статей.
        setItemsById(prevValue => {
          // React определяет изменения состояния при помощи ===,
          // так что с самого начала должен создаваться новый
          // экземпляр Map на основе старого.
          const nextValue = new Map(prevValue);

          return newItems.reduce((acc, item) => {
            // Перед тем как добавить в кэш новую статью можно
            // удалить ее старую версию, если такая есть.
            // Таким образом обновленная версия статьи обновит и свое
            // положение в порядке следования cтатей.
            // if (nextValue.has(item.id)) {
            //   nextValue.delete(item.id);
            // }

            return acc.set(item.id, item);
          }, nextValue);
        });
      } else {
        // В случае, если сервис вернул пустой массив,
        // выставляется флаг запрещающий дальнейший вызов
        // сервиса.
        preventServiceCallRef.current = true;
      }
    }
  }, [newsFeedPullingProcess, newsFeedPullingProcess.status]);

  // Эффект следит за состоянием сервиса и переключает
  // состояние индикатора загрузки.
  // Используется эффект, чтобы состояние загрузки
  // изменялось в том же цикле отрисовки, что и коллекция
  // статей.
  React.useEffect(() => {
    setLoading(newsFeedPullingProcess.status === 'PENDING');
  }, [newsFeedPullingProcess.status]);

  return {
    itemsById,
    load,
    loading
  };
};
