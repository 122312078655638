import { SheduleInfoService } from 'types/services';
import { sportingAPI } from '../api';

export const getSheduleInfo: SheduleInfoService = async () => {
  const sportingData = await sportingAPI.user.getSportingData();

  return {
    restaurant: sportingData.restarauntTime,
    hunting: sportingData.clubTime
  };
};
