import React from 'react';

import {
  TCompetitionParticipant,
  TCompetitionParticipantStatus
} from 'types/state';
import { useCompetitionRequestsCountService } from 'hooks/useCompetitionRequestsCountService';
import { useIntlContext } from 'hooks/useIntlContext';
import { useServices } from 'hooks/useServices';
import { CompetitionParticipantsView } from 'containers/CompetitionParticipants/CompetitionParticipantsView';
import { useAsyncController } from 'hooks/useAsyncController';
import { TSetCompetitionParticipantStatusParams } from 'types/services';

type Props = {
  competitionId: number;
};

export const CompetitionParticipants = (props: Props) => {
  const { competitionId } = props;

  const {
    getCompetitionParticipants,
    setCompetitionParticipantStatus
  } = useServices();

  const updateCountById = useCompetitionRequestsCountService();

  // Логика подгрузки данных таблицы
  const {
    wrapAsync: wrapParticipantsPulling,
    asyncProcess: participantsPullingProcess
  } = useAsyncController<TCompetitionParticipant[]>();

  const updateData = React.useCallback(() => {
    const promise = getCompetitionParticipants({ id: competitionId });

    wrapParticipantsPulling(promise);
  }, [competitionId, getCompetitionParticipants, wrapParticipantsPulling]);

  React.useEffect(() => updateData(), [updateData]);

  // Логика обновления статуса участника соревнования
  const {
    wrapAsync: wrapParticipantStatusUpdate,
    asyncProcess: participantStatusUpdateProcess
  } = useAsyncController();

  const updateParticipantStatus = React.useCallback(
    (params: TSetCompetitionParticipantStatusParams) => {
      const promise = setCompetitionParticipantStatus(params).then(() => {
        updateData();
        updateCountById(params.competitionId);
      });

      wrapParticipantStatusUpdate(promise);
    },
    [
      setCompetitionParticipantStatus,
      updateCountById,
      updateData,
      wrapParticipantStatusUpdate
    ]
  );

  // Приведение данных

  const handleParticipantStatusChange = React.useCallback(
    (id: number, nextStatus: TCompetitionParticipantStatus) => {
      updateParticipantStatus({
        competitionId,
        participantId: id,
        nextStatus
      });
    },
    [competitionId, updateParticipantStatus]
  );

  const {
    competitionParticipantsTableColumnName: nameColumnLabel,
    competitionParticipantsTableColumnPhone: phoneColumnLabel,
    competitionParticipantsTableColumnStatus: statusColumnLabel
  } = useIntlContext();

  const data =
    participantsPullingProcess.status === 'RESOLVED'
      ? participantsPullingProcess.value
      : null;

  const hasPreloader =
    participantsPullingProcess.status === 'PENDING' ||
    participantStatusUpdateProcess.status === 'PENDING';

  const viewProps = {
    data,
    competitionId,
    hasPreloader,
    phoneColumnLabel,
    nameColumnLabel,
    statusColumnLabel,
    handleParticipantStatusChange
  };

  return <CompetitionParticipantsView {...viewProps} />;
};
