import React from 'react';

import styles from './NewsArticle.module.css';

type TProps = {
  children: React.ReactNode;
  hasEditView: boolean;
};

export const NewsArticleTemplate: React.FC<TProps> = ({
  children,
  hasEditView
}) => (
  <div
    className={`${styles.NewsArticle__Template} ${
      hasEditView ? styles.NewsArticle__Template_edit : ''
    }`}
  >
    {children}
  </div>
);
