import { TNewsArticle } from 'types/state';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';

export const getRawNewsArticle = (): TNewsArticle => ({
  id: NEWS_ARTICLE_CANDIDATE_ID,
  title: '',
  description: '',
  image: null,
  audience: [],
  publishDate: null,
  isDraft: true
});
