import React from 'react';

import styles from './NewsFeed.module.css';

type TProps = {
  children: React.ReactNode;
  hasEmptyVeiw: boolean;
};

export const NewsFeedItems: React.FC<TProps> = ({ children, hasEmptyVeiw }) => (
  <div
    className={`${styles.NewsFeed__Items} ${
      hasEmptyVeiw ? styles.NewsFeed__Items_empty : ''
    }`}
  >
    {children}
  </div>
);
