import { TGetReservationsParams } from '../../../types/services';
import { Reservation } from '../../../types/state';
import { sportingAPI } from '../api';
import { normalizeSportingReservation } from '../../../utils/booking/normalizeSportingReservation';

export const getReservations = async (
  params: TGetReservationsParams
): Promise<Reservation[]> => {
  const { day } = params;

  const reservations = await sportingAPI.booking.getReservations({
    payload: {
      day
    }
  });

  return reservations.map(sReservation =>
    normalizeSportingReservation(sReservation).toReservation()
  );
};
