import { UserInfo, UserRole } from 'types/state';

export const createUserForCreate = (role: UserRole): UserInfo => ({
  id: 0,
  lastName: '',
  firstName: '',
  role: role,
  phoneNumber: '',
  login: '',
  blocked: false,
  enabledSections: []
});
