import {
  startOfDay,
  differenceInMinutes,
  isWithinInterval,
  subMinutes
} from 'date-fns';

import { ChatMessage } from 'types/chats';
import {
  CHAT_MESSAGES_TIME_GROUP_MINUTES_RANGE,
  CHAT_MESSAGE_INPUT_ID,
  CHAT_MESSAGE_EDIT_PERIOD_MINUTES
} from 'utils/constants';

export const isMessagesWithinSameDateGroup = (
  messageA: ChatMessage,
  messageB: ChatMessage
) => {
  return (
    startOfDay(new Date(messageA.createdAt)).toISOString() ===
    startOfDay(new Date(messageB.createdAt)).toISOString()
  );
};

export const isMessagesWithinSameTimeGroup = (
  messageA: ChatMessage,
  messageB: ChatMessage
) => {
  return (
    differenceInMinutes(
      new Date(messageA.createdAt),
      new Date(messageB.createdAt)
    ) <= CHAT_MESSAGES_TIME_GROUP_MINUTES_RANGE
  );
};

export const focusChatMessageInput = () => {
  const input = document.getElementById(CHAT_MESSAGE_INPUT_ID);

  if (input instanceof HTMLTextAreaElement) {
    input.focus();
  }
};

export const checkMessageIsAllowedToEdit = (message: ChatMessage) => {
  const createdAtDate = new Date(message.createdAt);

  const intervalStart = subMinutes(
    new Date(),
    CHAT_MESSAGE_EDIT_PERIOD_MINUTES
  );

  const intervalEnd = new Date();

  const withinAllowedPeriod = isWithinInterval(createdAtDate, {
    start: intervalStart,
    end: intervalEnd
  });

  return message.origin === 'STAFF' && !message.deleted && withinAllowedPeriod;
};

export const selectClosestMessageToEdit = (
  messages: ChatMessage[]
): null | ChatMessage => {
  const message = messages
    .sort((messageA, messageB) => {
      return (
        new Date(messageB.createdAt).getTime() -
        new Date(messageA.createdAt).getTime()
      );
    })
    .find(message => checkMessageIsAllowedToEdit(message));

  return message || null;
};
