export const appendHeadersWithJSON = (headers: Headers) => {
  headers.append('Content-Type', 'application/json');
};

export const appendHeadersWithAuthorization = (
  headers: Headers,
  value: string
) => {
  headers.append('Authorization', value);
};
