import React from 'react';

import './newMessagesIndicatorLayout.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  bage: React.ReactNode;
  action: React.ReactNode;
};

export const NewMessagesIndicatorLayout = (props: Props) => {
  const { bage, action } = props;

  const bem = createBEMConstructor('new-messages-indicator-layout');

  const wrapperClassName = bem('wrapper');

  const bageSlotClassName = bem('bage-slot');

  const actionSlotClassName = bem('action-slot');

  return (
    <div className={wrapperClassName}>
      <div className={actionSlotClassName}>{action}</div>
      <div className={bageSlotClassName}>{bage}</div>
    </div>
  );
};
