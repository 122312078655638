import {
  useNewApplicationsListsInvalidatedPage,
  useProcessedApplicationsListsInvalidatedPage
} from 'hooks/useApplicationsListsInvalidatedPages';
import { ServiceScope } from 'types/serviceScope';

export const useApplicationsPagePreloaderFlag = (domain: ServiceScope) => {
  const newApplicationsPageDidInvalidate =
    useNewApplicationsListsInvalidatedPage(domain) !== null;

  const processedApplicationsPageDidInvalidate =
    useProcessedApplicationsListsInvalidatedPage(domain) !== null;

  return (
    newApplicationsPageDidInvalidate || processedApplicationsPageDidInvalidate
  );
};
