import { TimeLine } from 'types/state';
import { createRuntimeUniqueId } from 'utils/createRuntimeUniqueId';

let storeMock: TimeLine[] = [];

storeMock.push({
  id: createRuntimeUniqueId(),
  location: 'RESTAURANT',
  days: [
    {
      day: 'MONDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'TUESDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'WEDNESDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'THURSDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'FRIDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'SATURDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'SUNDAY',
      startAt: '01:00',
      endAt: '23:00'
    }
  ],
  forAllWeek: true
});

storeMock.push({
  id: createRuntimeUniqueId(),
  location: 'CLUB',
  days: [
    {
      day: 'MONDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'TUESDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'WEDNESDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'THURSDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'FRIDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'SATURDAY',
      startAt: '01:00',
      endAt: '23:00'
    },
    {
      day: 'SUNDAY',
      startAt: '01:00',
      endAt: '23:00'
    }
  ],
  forAllWeek: true
});

const getAll = (): TimeLine[] => [...storeMock];

const add = (item: TimeLine) => {
  storeMock.push(item);
};

const replaceById = (id: number, nextItem: TimeLine) => {
  storeMock = storeMock.map(place => {
    if (place.id === id) {
      return nextItem;
    }

    return place;
  });
};

const deleteById = (id: number) => {
  storeMock = storeMock.filter(item => {
    return item.id !== id;
  });
};

export const timeLinesStoreMock = {
  getAll,
  add,
  replaceById,
  deleteById
};
