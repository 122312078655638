import React from 'react';

import styles from 'components/Notification/Notification.module.css';
import { CloseIcon } from 'svg/16x16/close';

type TProps = {
  message: string;
  testIdMessage?: string;
  testIdPopup?: string;
  testIdCloseButton?: string;
  onClose?: () => void;
};

export const NotificationPopup = ({
  message,
  onClose,
  testIdPopup,
  testIdMessage,
  testIdCloseButton
}: TProps) => (
  <div className={styles.Notification__Popup} data-testid={testIdPopup}>
    <span data-testid={testIdMessage}>{message}</span>
    {onClose && (
      <button
        className={styles.Notification__CloseButton}
        onClick={onClose}
        data-testid={testIdCloseButton}
      >
        <CloseIcon />
      </button>
    )}
  </div>
);
