import { TApplication, AppDispatch } from 'types/state';

export const newSportApplicationsThunk = (promise: Promise<TApplication[]>) => (
  dispatch: AppDispatch
): Promise<void> => {
  dispatch({
    name: 'NEW_SPORT_APPLICATIONS_SERVICE',
    status: 'PENDING'
  });

  return promise.then(applications => {
    dispatch({
      name: 'NEW_SPORT_APPLICATIONS_SERVICE',
      status: 'RESOLVED',
      payload: applications
    });
  });
};
