import React from 'react';

import { useTypingMap } from 'hooks/chats/useChatsState';
import { useAppState } from 'hooks/useAppState';

export const useTypingUserNames = (targetRoomId: number): null | string[] => {
  const typingMap = useTypingMap();

  const { clientInfosCollection } = useAppState();

  return React.useMemo(() => {
    const entries = Array.from(typingMap).filter(
      ([, roomId]) => roomId === targetRoomId
    );

    if (entries.length > 0) {
      return entries.map(([userId]) => {
        const user = clientInfosCollection.byId.get(userId);

        if (user) {
          return user.firstName;
        }

        return `${userId}`;
      });
    }

    return null;
  }, [clientInfosCollection.byId, targetRoomId, typingMap]);
};
