import { AppEvent } from 'types/appEvent';

export const remainingSMSCountReducer = (
  state: null | number,
  event: AppEvent
): null | number => {
  switch (event.name) {
    case 'SMS_COUNT_SERVICE': {
      if (event.status === 'RESOLVED') {
        return event.payload;
      }

      return state;
    }
    default:
      return state;
  }
};
