import React from 'react';

import './dndAnchor.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';
import { DNDIcon } from 'svg/16x16/dnd';

export const DNDAnchor = () => {
  const bem = createBEMConstructor('dndAnchor');

  const wrapperClassName = bem('wrapper');

  const iconClassName = bem('icon');

  return (
    <div className={wrapperClassName}>
      <DNDIcon className={iconClassName} />
    </div>
  );
};
