import { AppEvent } from 'types/appEvent';
import { modifyListCache, createListCache } from 'utils/listCacheUtils';
import { ListCache } from 'types/util';

export const chatRoomIdsReducer = (
  state: ListCache<number>,
  event: AppEvent
): ListCache<number> => {
  switch (event.name) {
    case 'CHAT_ROOMS_SERVICE': {
      if (event.status === 'RESOLVED') {
        const roomIds = event.payload.map(([room]) => room.id);

        return modifyListCache(state)
          .putItems(event.meta.page, roomIds)
          .return();
      }

      return state;
    }
    case 'CHAT_ROOM_READ_STATUS_SERVICE': {
      if (event.status === 'RESOLVED') {
        return modifyListCache(state)
          .invalidateAllPages()
          .return();
      }

      return state;
    }
    case 'CHATS_PAGE__OPENED': {
      return modifyListCache(createListCache<number>())
        .invalidatePage(1)
        .return();
    }
    case 'CHATS_PAGE_FEED__SCROLLED_TO_BOTTOM': {
      return modifyListCache(state)
        .invalidateNextPage()
        .return();
    }
    case 'CHAT_MESSAGE_RECIEVED': {
      return modifyListCache(state)
        .invalidateAllPages()
        .return();
    }
    case 'CHAT_MARKED_AS_UNREAD':
    case 'CHAT_MARKED_AS_READ': {
      return modifyListCache(state)
        .invalidateAllPages()
        .return();
    }
    case 'CHAT_MESSAGE_SUBMISSION_SERVICE': {
      if (event.status === 'RESOLVED') {
        return modifyListCache(state)
          .invalidateAllPages()
          .return();
      }

      return state;
    }
    default:
      return state;
  }
};
