import React from 'react';

import styles from './NewsFeed.module.css';

import { CreateButton } from 'components/shared/CreateButton';
import { testId } from 'utils/testId';

type TProps = {
  onClick(): void;
};

export const NewsFeedCreateButton = ({ onClick }: TProps) => (
  <CreateButton
    className={styles.NewsFeed__CreateItemButton}
    onClick={onClick}
    testId={testId.NEWS_FEED_CREATE_ARTICLE_BUTTON}
  />
);
