import {
  TCompetitionParticipant,
  TCompetitionsArticle,
  TArticeImage,
  TCompetitionDuration
} from 'types/state';
import { getUid } from 'utils/getUid';

export type TCompetitionsStoreMockRecord = {
  id: number;
  title: string;
  description: string;
  image: null | TArticeImage;
  duration: null | TCompetitionDuration;
  isDraft: boolean;
  participants: TCompetitionParticipant[];
  note: string;
};

export const competitionParticipantsMock: TCompetitionParticipant[] = [
  {
    id: 1,
    name: 'Scandroid',
    phone: '+375 22 555 44 55',
    status: 'PARTICIPATION_REQUESTED'
  },
  {
    id: 2,
    name: 'Bon Jovi',
    phone: '+375 22 333 55 03',
    status: 'PARTICIPATION_CONFIRMED'
  },
  {
    id: 3,
    name: 'Slipknot',
    phone: '+375 22 422 66 33',
    status: 'CANCELATION_REQUESTED'
  },
  {
    id: 4,
    name: 'Best brit-pop-indi-rock band ever',
    phone: '+375 22 434 99 21',
    status: 'CANCELATION_CONFIRMED'
  }
];

const getCompetitionsArticleMock = (): TCompetitionsArticle => {
  const id = getUid();

  return {
    id,
    title: `Competition ${id}`,
    description: `Description ${id}`,
    duration: null,
    image: null,
    isDraft: false
  };
};

export const getCompetitionsArticleMocks = (
  count: number
): TCompetitionsArticle[] => {
  const mocks = [];
  while (mocks.length < count) {
    mocks.push(getCompetitionsArticleMock());
  }
  return mocks;
};

// Функция помогает быстро создать определенное
// количество записей базы данных.
export const getCompetitionsStoreRecordMocks = (count: number = 1) => {
  const mocks: TCompetitionsStoreMockRecord[] = [];
  while (count > 0) {
    const id = getUid();

    mocks.push({
      id,
      title: `Title ${id}`,
      description: `Description ${id}`,
      image: null,
      duration: null,
      isDraft: true,
      participants: [],
      note: `Notes ${id}`
    });

    count -= 1;
  }

  return mocks;
};
