import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TSetCompetitionParticipantStatusParams } from 'types/services';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const SET_COMPETITION_PARTICIPANT_STATUS_MOCK_ID__CONNECTION_ERROR = -1;

export const setCompetitionParticipantStatus = async (
  params: TSetCompetitionParticipantStatusParams
): Promise<void> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (
    params.competitionId ===
    SET_COMPETITION_PARTICIPANT_STATUS_MOCK_ID__CONNECTION_ERROR
  ) {
    throw new HTTPConnectionError();
  }
  competitionsStoreMock.updateParticipantStatus(
    params.competitionId,
    params.participantId,
    params.nextStatus
  );
};
