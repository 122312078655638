import React from 'react';

import styles from './Modal.module.css';

type TProps = {
  children: React.ReactNode;
  additionalClassName?: string;
};

export const ModalFooter = (props: TProps) => {
  const { children, additionalClassName } = props;

  return (
    <div
      className={`${styles.Modal__Footer} ${
        additionalClassName ? additionalClassName : ''
      }`}
    >
      {children}
    </div>
  );
};
