import React from 'react';

import { TCompetitionsArticle, TCompetitionsPreviewTab } from 'types/state';
import { CompetitionsPreviewTemplate } from 'components/CompetitionsPreview/CompetitionsPreviewTemplate';
import { CompetitionsPreviewTabs } from 'components/CompetitionsPreview/CompetitionsPreviewTabs';
import { ArticlePreviewContent } from 'containers/ArticlePreviewContent';
import { CompetitionsArticle } from 'containers/CompetitionsArticle';
import { CompetitionParticipants } from 'containers/CompetitionParticipants';
import { CompetitionNotes } from 'containers/CompetitionNotes';
import { CompetitionsPreviewFooter } from 'containers/CompetitionsPreviewFooter';
import { DeleteArticleModal } from 'containers/DeleteArticleModal';

type TProps = {
  hasEditView: boolean;
  hasDeleteView: boolean;
  hasDescriptionView: boolean;
  hasParticipantsView: boolean;
  hasNotesView: boolean;
  article: TCompetitionsArticle;
  activeTab: TCompetitionsPreviewTab;
  requestsCount?: number;
  handleEdit(): void;
  handleCancelEdit(): void;
  handleDelete(): void;
  handleDeleted(): void;
  handleCancelDelete(): void;
  handleSubmited(id: number): void;
  handleChange(nextData: TCompetitionsArticle): void;
  handleActiveTabChange(nextTab: TCompetitionsPreviewTab): void;
};

export const CompetitionsPreviewView = (props: TProps) => {
  const {
    hasEditView,
    hasDeleteView,
    hasDescriptionView,
    hasParticipantsView,
    hasNotesView,
    article,
    activeTab,
    requestsCount,
    handleEdit,
    handleCancelEdit,
    handleDelete,
    handleDeleted,
    handleCancelDelete,
    handleChange,
    handleSubmited,
    handleActiveTabChange
  } = props;

  return (
    <CompetitionsPreviewTemplate>
      {!hasEditView && (
        <CompetitionsPreviewTabs
          activeTab={activeTab}
          requestsCount={requestsCount}
          onChange={handleActiveTabChange}
        />
      )}
      <ArticlePreviewContent hasScrollShadow={hasEditView}>
        {hasDescriptionView && (
          <CompetitionsArticle
            hasEditView={hasEditView}
            data={article}
            onChange={handleChange}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        )}
        {hasParticipantsView && (
          <CompetitionParticipants competitionId={article.id} />
        )}
        {hasNotesView && <CompetitionNotes competitionId={article.id} />}
      </ArticlePreviewContent>
      {hasEditView && (
        <CompetitionsPreviewFooter
          data={article}
          onCancel={handleCancelEdit}
          onSumbited={handleSubmited}
        />
      )}
      {hasDeleteView && (
        <DeleteArticleModal
          domain="COMPETITONS"
          id={article.id}
          onDeleted={handleDeleted}
          onCancel={handleCancelDelete}
        />
      )}
    </CompetitionsPreviewTemplate>
  );
};
