import React from 'react';

import styles from './FallbackScreen.module.scss';

type Props = {
  title: React.ReactNode;
  details: React.ReactNode;
  action: React.ReactNode;
};

export function FallbackScreenComponent(props: Props) {
  const { title, details, action } = props;

  return (
    <div className={styles.FallbackScreen__Wrapper}>
      <div className={styles.FallbackScreen__Content}>
        <div className={styles.FallbackScreen__TitleSlot}>{title}</div>
        <div className={styles.FallbackScreen__DetailsSlot}>{details}</div>
        <div className={styles.FallbackScreen__ActionSlot}>{action}</div>
      </div>
    </div>
  );
}
