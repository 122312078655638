import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import styles from './CropImageModal.module.css';

type TProps = {
  src: string;
  crop: ReactCrop.Crop;
  minWidth?: number;
  minHeight?: number;
  isDisabled?: boolean;
  onChange(crop: ReactCrop.Crop): void;
  onImageLoaded(image: HTMLImageElement): void;
};

export const CropImageModalCropper = ({
  isDisabled,
  ...nativeCropProps
}: TProps) => {
  return (
    <div className={styles.CropImageModal__Body}>
      <ReactCrop
        {...nativeCropProps}
        disabled={isDisabled}
        className={styles.CropImageModal__Cropper}
        keepSelection
      />
    </div>
  );
};
