import React from 'react';

import { MembersTableItemLayout } from 'design/membersTableItemLayout/membersTableItemLayout';
import { ClientInfo } from 'types/client';
import { Text } from 'design/text';
import { useIntlContext } from 'hooks/useIntlContext';
import { Checkbox } from 'components/shared/Checkbox';
import { useServices } from 'hooks/useServices';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { MembersTableItemLabel } from 'design/membersTableItemLabel/membersTableItemLabel';
import { useMediaFlags } from 'hooks/useMediaFlags';
import { ClipboardData } from './ClipboardData';
import { UserIcon } from 'svg/24x24/user';

type Props = {
  clientInfo: ClientInfo;
  verified: boolean;
  onVerificationStatusChanged(): void;
};

export const MembersTableItem = (props: Props) => {
  const { clientInfo, verified, onVerificationStatusChanged } = props;

  const { isTablet, isMobile } = useMediaFlags();

  const { memberTableItem: ownDictionary, personFullName } = useIntlContext();

  const { toggleMemberVerivicationStatus } = useServices();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const toggleVerificationStatus = React.useCallback(() => {
    wrapAsyncSafely(
      toggleMemberVerivicationStatus({
        memberId: clientInfo.id,
        verified: !verified
      }).then(onVerificationStatusChanged)
    );
  }, [
    clientInfo.id,
    onVerificationStatusChanged,
    toggleMemberVerivicationStatus,
    verified,
    wrapAsyncSafely
  ]);

  const isDevice = isMobile || isTablet;

  return (
    <MembersTableItemLayout
      icon={<UserIcon />}
      cardNumber={<Text>{clientInfo.cardNumber}</Text>}
      phoneNumber={
        clientInfo.phoneNumber ? (
          <ClipboardData
            visibleValue={clientInfo.phoneNumber}
            clipboardValue={clientInfo.phoneNumber}
          />
        ) : null
      }
      fullName={
        <Text size={isDevice ? 'xl' : 'm'} bold={isDevice}>
          {personFullName(clientInfo)}
        </Text>
      }
      statusLabel={
        <Text color={verified ? 'secondary' : 'critical'}>
          {ownDictionary.verificationValueText(verified)}
        </Text>
      }
      statusInput={
        <MembersTableItemLabel checked={verified}>
          <Checkbox
            name="verified"
            checked={verified}
            onChange={toggleVerificationStatus}
          />
        </MembersTableItemLabel>
      }
    />
  );
};
