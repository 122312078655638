import { Section } from 'types/state';
import { useIntlContext } from 'hooks/useIntlContext';
import { UnreachableError } from 'utils/errors';

export const useSectionsFormInputLabel = (section: Section): string => {
  const { sectionsForm } = useIntlContext();

  switch (section) {
    case 'applicationsRestaurant':
      return sectionsForm.applicationsRestaurantLabel;
    case 'chatsRestaurant':
      return sectionsForm.chatsRestaurantLabel;
    case 'bookingRestaurant':
      return sectionsForm.bookingRestaurantLabel;
    case 'applicationsSport':
      return sectionsForm.applicationsSportLabel;
    case 'chatsSport':
      return sectionsForm.chatsSportLabel;
    case 'bookingClub':
      return sectionsForm.bookingClubLabel;
    case 'competitions':
      return sectionsForm.competitionsLabel;
    case 'news':
      return sectionsForm.newsLabel;
    case 'feedback':
      return sectionsForm.feedbackLabel;
    case 'members':
      return sectionsForm.membersLabel;
    case 'users':
      return sectionsForm.usersLabel;
    default:
      throw new UnreachableError(section);
  }
};
