import { asyncTimeout } from 'utils/asyncTimeout';
import { TSignInFormValues } from 'types/state';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { AuthorizationError, HTTPConnectionError } from 'utils/errors';
import { TSignInParams } from 'types/services';
import { sessionModel } from '../utils/sessonModel';
import { createSessionAuthorized } from 'utils/createSession';

export const validSignInMockValues: TSignInFormValues = {
  username: 'username',
  password: 'password'
};

export const invalidSignInMockValues: TSignInFormValues = {
  username: 'user',
  password: 'pass'
};

export const serverErrorSignInMockValues: TSignInFormValues = {
  username: 'server',
  password: 'error'
};

export const signIn = async (params: TSignInParams) => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (
    params.username === serverErrorSignInMockValues.username ||
    params.password === serverErrorSignInMockValues.password
  ) {
    throw new HTTPConnectionError();
  }
  if (
    params.username !== validSignInMockValues.username ||
    params.password !== validSignInMockValues.password
  ) {
    throw new AuthorizationError();
  }

  sessionModel.set(createSessionAuthorized());
};
