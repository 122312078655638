import { ListCache } from 'types/util';
import { AppEvent } from 'types/appEvent';
import { modifyListCache, createListCache } from 'utils/listCacheUtils';
import { authorizedRoutes } from 'utils/authorizedRoutes';

export const processedSportApplicationsListReducer = (
  state: ListCache<number>,
  event: AppEvent
): ListCache<number> => {
  switch (event.name) {
    case 'PROCESSED_SPORT_APPLICATIONS_SERVICE':
      if (event.status === 'RESOLVED') {
        const ids = event.payload.map(item => item.id);

        return modifyListCache(state)
          .putItems(event.meta, ids)
          .return();
      }

      return state;
    case 'ROUTER__LOCATION_PATHNAME_CHANGE':
      const shouldDrop =
        event.prevLocation === authorizedRoutes.applicationsSport();

      const shouldInvalidateFirstPage =
        event.nextLocation === authorizedRoutes.applicationsSport();

      if (shouldDrop) {
        return modifyListCache(createListCache<number>()).return();
      }

      if (shouldInvalidateFirstPage) {
        return modifyListCache(state)
          .invalidatePage(1)
          .return();
      }

      return state;
    case 'APPLICATIONS_PAGE__NEW_APPLICATIONS_COUNT_CHANGED': {
      return modifyListCache(state)
        .invalidateAllPages()
        .return();
    }
    case 'AUTHORIZED_SCREEN__CONTENT_SCROLLED_TO_BOTTOM':
      const lastPageItems = Array.from(state.byPage.values()).pop();

      const noMoreData = lastPageItems && lastPageItems.length === 0;

      if (state.invalidatedPages.size === 0 && !noMoreData) {
        return modifyListCache(state)
          .invalidateNextPage()
          .return();
      }

      return state;
    default:
      return state;
  }
};
