import {
  SportingDeleteReservationPayload,
  SportingSecureParams
} from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';

export const deleteReservation = async (
  params: SportingSecureParams<SportingDeleteReservationPayload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingBookingURL(
    `deleteReservation/${payload.idReservation}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    method: 'DELETE',
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
