import { UsersEditorState, AdministratorInfo } from 'types/state';
import { createCache } from 'utils/createCache';
import { patchCache } from 'utils/patchCache';

export const createUsersEditorState = (): UsersEditorState => ({
  userEditorStatesByUserId: new Map(),
  newUserEditorState: null,
  administratorInfosCache: patchCache(createCache(new Set<AdministratorInfo>()))
    .invalidate()
    .done()
});
