import React from 'react';

import { TReview } from 'types/state';
import { getScrollLeftPx } from 'utils/getScrollLeftPx';
import { NEWS_FEED_SCROLL_LEFT_TO_LOAD_MORE } from 'utils/constants';
import { useIntlContext } from 'hooks/useIntlContext';

type TProps = {
  items: TReview[];
  selectedItemId: number;
  hasPreloader: boolean;
  onItemClick(id: number): void;
  onScrolledToBottom(): void;
};

export const useReviewsFeedState = (props: TProps) => {
  const {
    onScrolledToBottom,
    selectedItemId,
    hasPreloader,
    onItemClick,
    items
  } = props;

  const { reviewsFeedTitle: title } = useIntlContext();

  const onContentScroll = React.useCallback(
    (event: React.SyntheticEvent) => {
      const { target } = event;
      if (target instanceof Element) {
        const scrollLeftPx = getScrollLeftPx(target);
        if (scrollLeftPx < NEWS_FEED_SCROLL_LEFT_TO_LOAD_MORE) {
          onScrolledToBottom();
        }
      }
    },
    [onScrolledToBottom]
  );

  return {
    items,
    title,
    selectedItemId,
    hasPreloader,
    onItemClick,
    onContentScroll
  };
};
