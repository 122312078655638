import { TCompetitionParticipantStatus } from 'types/state';
import { TSportingCompetitionParticipantStatus } from 'services/sporting/types';
import { UnreachableError } from 'utils/errors';

export const normalizeSportingCompetitionParticipantStatus = {
  toParticipantStatus(
    sportingStatus: TSportingCompetitionParticipantStatus
  ): TCompetitionParticipantStatus {
    switch (sportingStatus.id) {
      case 1:
        return 'PARTICIPATION_REQUESTED';
      case 2:
        return 'CANCELATION_REQUESTED';
      case 3:
        return 'PARTICIPATION_CONFIRMED';
      case 4:
        return 'CANCELATION_CONFIRMED';
      default:
        throw new UnreachableError(sportingStatus.id);
    }
  }
};
