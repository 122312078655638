import React from 'react';

import { ClipboardData } from 'containers/ClipboardData';

import styles from './ApplicationsTable.module.scss';
import { CopyIcon } from 'svg/25x25/copy';

type TProps = {
  mod?: 'button' | 'text-mobile';
  visibleValue?: string;
  clipboardValue: string;
};

export const ApplicationsTableClipboard = ({
  mod,
  visibleValue,
  clipboardValue
}: TProps) => {
  const className =
    mod === 'text-mobile'
      ? styles.ApplicationsTable__CardAuthorName
      : mod === 'button'
      ? styles.ApplicationsTable__CardClipboardButton
      : undefined;

  const visibleValueNode = React.useMemo(
    () =>
      mod === 'button' ? (
        <CopyIcon />
      ) : visibleValue ? (
        visibleValue
      ) : (
        clipboardValue
      ),
    [mod, visibleValue, clipboardValue]
  );

  return (
    <ClipboardData
      className={className}
      visibleValue={visibleValueNode}
      clipboardValue={clipboardValue}
    />
  );
};
