import { format, parse } from 'date-fns';
import { DayTimePeriodInputLayoutComponent } from 'design/dayTimePeriodInputLayout/dayTimePeriodInputLayoutComponents';
import { useIntlContext } from 'hooks/useIntlContext';
import React from 'react';
import { CalendarPeriod } from 'types/state';
import { SelectOption } from 'types/util';
import { splitCalendarPeriod } from 'utils/calendarPeriodUtils';
import { DATE_FNS__24H_TIME_FORMAT, MINUTE } from 'utils/constants';
import { CustomSelect, CustomSelectControl } from './CustomSelect';

type Props = {
  basePeriod: CalendarPeriod;
  selectedPeriod: CalendarPeriod;
  onSelectedPeriodChange(nextValue: CalendarPeriod): void;
};

export const CalendarPeriodDayTimeInputComponent = (props: Props) => {
  const { basePeriod, selectedPeriod, onSelectedPeriodChange } = props;

  const { bookingReservationIntl: msgs } = useIntlContext();

  const timeStartInputControlRef = React.useRef<CustomSelectControl>(null);

  const timeEndInputControlRef = React.useRef<CustomSelectControl>(null);

  const { startTimeOptions, endTimeOptions } = React.useMemo(() => {
    const startTimeOptions: SelectOption<string>[] = [];
    const endTimeOptions: SelectOption<string>[] = [];

    const step = MINUTE * 30;

    const calendarPeriods = splitCalendarPeriod(basePeriod, step);

    calendarPeriods.forEach(({ startDateISO, endDateISO }) => {
      const startTimeItem = format(
        new Date(startDateISO),
        DATE_FNS__24H_TIME_FORMAT
      );
      startTimeOptions.push({
        value: startTimeItem,
        label: startTimeItem
      });
      const endTimeItem = format(
        new Date(endDateISO),
        DATE_FNS__24H_TIME_FORMAT
      );
      endTimeOptions.push({
        value: endTimeItem,
        label: endTimeItem
      });
    });

    return { startTimeOptions, endTimeOptions };
  }, [basePeriod]);

  const handleStartTimeChange = React.useCallback(
    (nextTime: string) => {
      const nextPeriod = {
        ...selectedPeriod
      };

      nextPeriod.startDateISO = parse(
        nextTime,
        DATE_FNS__24H_TIME_FORMAT,
        new Date(selectedPeriod.startDateISO)
      ).toISOString();

      onSelectedPeriodChange(nextPeriod);

      timeEndInputControlRef.current?.toggle();
    },
    [onSelectedPeriodChange, selectedPeriod]
  );

  const handleEndTimeChange = React.useCallback(
    (nextTime: string) => {
      const nextPeriod = {
        ...selectedPeriod
      };

      nextPeriod.endDateISO = parse(
        nextTime,
        DATE_FNS__24H_TIME_FORMAT,
        new Date(selectedPeriod.endDateISO)
      ).toISOString();

      onSelectedPeriodChange(nextPeriod);
    },
    [onSelectedPeriodChange, selectedPeriod]
  );

  const startTimeValue = React.useMemo(() => {
    return format(
      new Date(selectedPeriod.startDateISO),
      DATE_FNS__24H_TIME_FORMAT
    );
  }, [selectedPeriod.startDateISO]);

  const endTimeValue = React.useMemo(() => {
    return format(
      new Date(selectedPeriod.endDateISO),
      DATE_FNS__24H_TIME_FORMAT
    );
  }, [selectedPeriod.endDateISO]);

  return (
    <DayTimePeriodInputLayoutComponent
      startTimeInput={
        <CustomSelect
          ref={timeStartInputControlRef}
          formFieldBordered={true}
          placeholder={msgs.dateStartLabel}
          selected={startTimeValue}
          options={startTimeOptions}
          onChange={handleStartTimeChange}
        />
      }
      endTimeInput={
        <CustomSelect
          ref={timeEndInputControlRef}
          formFieldBordered={true}
          placeholder={msgs.dateEndLabel}
          selected={endTimeValue}
          options={endTimeOptions}
          onChange={handleEndTimeChange}
        />
      }
    />
  );
};
