import { AppEvent } from 'types/appEvent';
import { LoginEditorState } from 'types/state';
import { createLoginEditorState } from 'utils/settings/loginEditor/createLoginEditorState';
import { patchEditorState } from 'utils/patchEditorState';
import { patchLoginFormValues } from 'utils/settings/loginEditor/patchLoginFormValues';
import { validateLoginFormValues } from 'utils/settings/loginEditor/validateLoginFormValues';
import { getLoginFormErrorFromServiceError } from 'utils/settings/loginEditor/getLoginFormErrorFromServiceError';
import { authorizedRoutes } from 'utils/authorizedRoutes';

export const loginEditorStateReducer = (
  state: LoginEditorState,
  event: AppEvent
): LoginEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      if (event.nextLocation === authorizedRoutes.settings()) {
        return state;
      } else {
        return createLoginEditorState();
      }
    }
    case 'LOGIN_EDITOR_FORM__LOGIN_INPUT_CHANGE':
    case 'LOGIN_EDITOR_FORM__PASSWORD_INPUT_CHANGE': {
      let nextFormValues = patchLoginFormValues(state.formValues);

      if (event.name === 'LOGIN_EDITOR_FORM__LOGIN_INPUT_CHANGE') {
        nextFormValues = nextFormValues.setLogin(event.value);
      }

      if (event.name === 'LOGIN_EDITOR_FORM__PASSWORD_INPUT_CHANGE') {
        nextFormValues = nextFormValues.setPassword(event.value);
      }

      const nextEditorState = patchEditorState(state)
        .setSubmitDidSucceed(false)
        .setFormValues(nextFormValues.done())
        .clearFormError();

      return nextEditorState.done();
    }
    case 'LOGIN_EDITOR_FORM__CANCEL': {
      return patchEditorState(state)
        .clearFormError()
        .setFormValues({
          login: event.oldValue,
          password: ''
        })
        .done();
    }
    case 'LOGIN_EDITOR_FORM__SUBMIT': {
      const { formValues } = state;

      const formError = validateLoginFormValues(formValues);

      let nextFormValues = patchLoginFormValues(formValues);

      let nextEditorState = patchEditorState(state);

      if (formError !== undefined) {
        nextEditorState = nextEditorState
          .setFormError(formError)
          .setFormValues(nextFormValues.done());
      } else {
        nextEditorState = nextEditorState.setSubmited(true);
      }

      return nextEditorState.done();
    }
    case 'UPDATE_CURRENT_USER_LOGIN_SERVICE': {
      let nextEditorState = patchEditorState(state);

      if (event.status === 'PENDING') {
        nextEditorState = nextEditorState
          .clearFormError()
          .setSubmitDidSucceed(false);
      }

      if (event.status === 'RESOLVED') {
        let nextFormValues = patchLoginFormValues(state.formValues)
          .setPassword('')
          .done();
        nextEditorState = nextEditorState
          .setFormValues(nextFormValues)
          .clearFormError()
          .setSubmited(false)
          .setSubmitDidSucceed(true);
      }

      if (event.status === 'REJECTED') {
        const formError = getLoginFormErrorFromServiceError(event.payload);

        nextEditorState = nextEditorState
          .setFormError(formError)
          .setSubmited(false);
      }

      return nextEditorState.done();
    }

    default:
      return state;
  }
};
