import { SportingTimeLine } from '../types';
import { TimeLine, TimeLineDay } from '../../../types/state';
import { normalizeSportingPlaceDestinationType } from './normalizeSportingPlaceDestinationType';

// TODO: отрефакторить на фабрику
export const normalizeSportingTimeLine = {
  toTimeLine(sportingTimeLine: SportingTimeLine): TimeLine {
    return {
      id: sportingTimeLine.id,
      location: normalizeSportingPlaceDestinationType(
        sportingTimeLine.location
      ).toServiceScope(),
      days: sportingTimeLine.days.map(normalizeSportingTimeLineDay.toDay),
      forAllWeek: sportingTimeLine.forAllWeek
    };
  }
};

// TODO: отрефакторить на фабрику
export const normalizeSportingTimeLineDay = {
  toDay(sportingTimeLineDay: TimeLineDay): TimeLineDay {
    const endAt = sportingTimeLineDay.endAt.slice(0, 5);

    return {
      day: sportingTimeLineDay.day,
      startAt: sportingTimeLineDay.startAt.slice(0, 5),
      endAt: endAt === '23:59' ? '00:00' : endAt
    };
  }
};
