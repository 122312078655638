import { EditChatMessageParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { chatMessagesStoreMock } from 'services/mock/utils/chatMessagesModel';

export const editChatMessage = async (
  params: EditChatMessageParams
): Promise<void> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  const { messageId, text } = params;

  const trimmedText = text.trim();

  const shouldDelete = trimmedText === '';

  const message = chatMessagesStoreMock.getMessageById(messageId);

  if (message) {
    const nextMessage = {
      ...message,
      text: trimmedText,
      deleted: shouldDelete,
      edited: !shouldDelete
    };

    chatMessagesStoreMock.replaceMessageById(messageId, nextMessage);
  }
};
