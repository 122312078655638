import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { authorizedRoutes } from 'utils/authorizedRoutes';
import { useUserRoleStrict } from './useUserRole';

export const useDefaultRoute = () => {
  const enabledSections = useEnabledSectionsStrict();
  const userRoleStrict = useUserRoleStrict();

  if (enabledSections.includes('applicationsRestaurant')) {
    return authorizedRoutes.applicationsRestaurant();
  }

  if (enabledSections.includes('applicationsSport')) {
    return authorizedRoutes.applicationsSport();
  }

  if (enabledSections.includes('news')) {
    return authorizedRoutes.news();
  }

  if (enabledSections.includes('competitions')) {
    return authorizedRoutes.competitions();
  }
  if (enabledSections.includes('feedback')) {
    return authorizedRoutes.feedback();
  }

  if (userRoleStrict === 'SECURITY') {
    return authorizedRoutes.noActiveSections();
  }

  return authorizedRoutes.settings();
};
