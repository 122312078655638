import {
  TCompetitionParticipantStatus,
  TCompetitionsFilter,
  TCompetitionsPreviewTab,
  TNewsFilter
} from 'types/state';

export const MAX_TIMEOUT_DELAY = 2147483647;

export const SECOND = 1000;

export const MINUTE = SECOND * 60;

export const HOUR = MINUTE * 60;

export const FALLBACK_FILENAME = 'file';

export const SERVICES_MOCK_DELAY = 200;

export const SERVICES_MOCK_LONG_DELAY = SECOND * 10;

export const ARTICLE_SUBMIT_DELAY = 2000;

export const HIDE_DONE_APPLICATION_DELAY = SECOND * 3;

export const LOCAL_STORAGE_SESSION_KEY = 'session';

export const MIN_SMS_COUNT_TO_NOT_SHOW_NOTIFICATION = 100;

export const CHECK_SMS_COUNT_INTERVAL = HOUR * 24; // 24h

export const RATIO_1_1 = 1 / 1;

export const RATIO_2_1 = 2 / 1;

export const NOTIFICATION_SOUND_URL = `${process.env.PUBLIC_URL}/static/notif-sound.mp3`;

export const FAVICON_FLICKERING_INTERVAL = 500;

// News

export const NEWS_FEED_PAGE_LENGTH = 20;
export const NEWS_FEED_DEFAULT_FILTER: TNewsFilter = 'all';

export const NEWS_FEED_SCROLL_LEFT_TO_LOAD_MORE = 900;

export const NEWS_ARTICLE_CANDIDATE_ID = -1;

export const NEWS_ARTICLE_IMAGE_MIN_WIDTH = 1600;
export const NEWS_ARTICLE_IMAGE_MIN_HEIGHT = 1600;
export const NEWS_ARTICLE_IMAGE_MAX_SIZE = 1024 * 1024 * 15; // 15mb
export const NEWS_ARTICLE_IMAGE_RATIO = RATIO_1_1;
export const NEWS_ARTICLE_CROPPED_IMAGE_MIN_WIDTH = 1242;
export const NEWS_ARTICLE_CROPPED_IMAGE_MIN_HEIGHT = 1242;

export const NEWS_ARTICLE_TITLE_INPUT_MAX_SIZE = 400;
export const NEWS_ARTICLE_DESCRIPTION_INPUT_MAX_SIZE = 2000;

// Competitions

export const COMPETITIONS_FEED_PAGE_LENGTH = 20;
export const COMPETITIONS_FEED_DEFAULT_FILTER: TCompetitionsFilter = 'upcoming';

export const COMPETITIONS_ARTICLE_CANDIDATE_ID = -1;

export const COMPETITIONS_ARTICLE_IMAGE_MIN_WIDTH = 1600;
export const COMPETITIONS_ARTICLE_IMAGE_MIN_HEIGHT = 1600;
export const COMPETITIONS_ARTICLE_IMAGE_MAX_SIZE = 1024 * 1024 * 15; // 15mb
export const COMPETITIONS_ARTICLE_IMAGE_RATIO = RATIO_2_1;
export const COMPETITIONS_ARTICLE_CROPPED_IMAGE_MIN_WIDTH = 1242;
export const COMPETITIONS_ARTICLE_CROPPED_IMAGE_MIN_HEIGHT = 621;

export const COMPETITIONS_FEED_SCROLL_LEFT_TO_LOAD_MORE = 900;

export const COMPETITION_NOTES_MAX_LENGTH = 2000;

export const COMPETITIONS_REQUESTS_COUNT_UPDATE_INTERVAL = SECOND * 10;

export const COMPETITIONS_PREVIEW_TAB_DEFAULT: TCompetitionsPreviewTab =
  'DESCRIPTION';

export const COMPETITION_PARTICIPANT_STATUSES_SET: TCompetitionParticipantStatus[] = [
  'PARTICIPATION_REQUESTED',
  'PARTICIPATION_CONFIRMED',
  'CANCELATION_REQUESTED',
  'CANCELATION_CONFIRMED'
];

// Applications

export const APPLICATIONS_PAGE_UPDATE_INTERVAL = SECOND * 10;

export const APPLICATIONS_TABLE_TIMER_OFFSET = MINUTE * 5;

// Reviews

export const REVIEWS_FEED_PAGE_LENGTH = 20;
export const REVIEWS_FEED_SCROLL_LEFT_TO_LOAD_MORE = 900;
export const REVIEW_NOTES_INPUT_MAX_LENGTH = 2000;

// Events

export const EVENT_MOUSE_DOWN = 'mousedown';
export const EVENT_MOUSE_OVER = 'mouseover';
export const EVENT_MOUSE_OUT = 'mouseout';
export const EVENT_MOUSE_ENTER = 'mouseenter';
export const EVENT_MOUSE_LEAVE = 'mouseleave';
export const EVENT_KEY_DOWN = 'keydown';
export const EVENT_CLICK = 'click';
export const EVENT_VISIBILITY_CHANGE = 'visibilitychange';
export const EVENT_ON_LINE = 'online';
export const EVENT_OFF_LINE = 'offline';
export const EVENT_WHEEL = 'wheel';
export const EVENT_SCROLL = 'scroll';
export const EVENT_INPUT = 'input';
export const EVENT_BLUR = 'blur';

// Headers

export const HEADER_NAME_CONTENT_TYPE = 'Content-Type';
export const HEADER_NAME_AUTHORIZATION = 'Authorization';

// Chats

export const CHAT_ROOMS_PAGE_LENGTH = 20;
export const CHAT_ROOMS_FEED_SCROLL_LEFT_TO_LOAD_MORE = 300;
export const CHAT_ROOM_SCROLL_LEFT_TO_LOAD_MORE = 300;
export const CHAT_MESSAGES_TIME_GROUP_MINUTES_RANGE = 5;
export const CHAT_MESSAGES_PAGE_LENGTH = 50;
export const CHAT_MESSAGE_INPUT_MAX_LENGTH = 2000;
export const CHAT_MESSAGE_INPUT_ID = 'chat-message-input';
export const CHAT_MESSAGE_EDIT_PERIOD_MINUTES = 120;
export const CHATS_PAGE_BAGES_DELAY = 1000;

// Booking

export const BOOKING_PLACE_NAME_INPUT_MAX_LENGTH = 20;
export const BOOKING_PLACE_ITEM_NAME_INPUT_MAX_LENGTH = 25;
export const BOOKING_PLACE_ITEM_VISITORS_COUNT_INPUT_MAX_LENGTH = 4;

// Message templates

export const MESSAGE_TEMPLATES_PAGE_LENGTH = 50;
export const MESSAGE_TEMPLATE_MAX_LENGTH = 2000;
export const MESSAGE_TEMPLATES_LIST_SCROLL_LEFT_TO_LOAD_MORE = 300;

// Keys

export const KEY_ENTER = 'Enter';

export const KEY_ARROW_UP = 'ArrowUp';

// date-fns

export const DATE_FNS__24H_TIME_FORMAT = 'HH:mm';
export const DATE_FNS__24H_TIME_FORMAT_WITH_SECOND = 'HH:mm:ss';

// Signs

export const HYPHEN = '-'; // Дефис

export const EN_DASH = '–'; // Короткое тире

export const EM_DASH = '—'; // Длинное тире

// Booking

export const BOOKING_RESERVATION_DROP_TYPE = 'RESERVATION';
