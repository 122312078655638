import React from 'react';
import { useDrag } from 'react-dnd';

import { CommentIcon } from 'svg/12x12/comment';
import { HumanIcon } from 'svg/12x12/human';
import {
  BookingReservationDragInfo,
  BookingReservationDragItem
} from 'types/booking';
import { BOOKING_RESERVATION_DROP_TYPE } from 'utils/constants';
import { createBEMConstructor } from 'utils/createBEMConstructor';

import './bookingTimelineReservationLayout.scss';

type Status = 'past' | 'current' | 'future' | 'urgent';

type Props = {
  id: number;
  leftPercent: number;
  topPercent: number;
  widthPercent: number;
  heightPercent: number;
  status: Status;
  title: React.ReactNode;
  time: React.ReactNode;
  participantsCount: React.ReactNode;
  popup: React.ReactNode;
  comment?: string;
  onClick(): void;
};

const bemConstructor = createBEMConstructor('bookingTimelineReservationLayout');

export const BookingTimelineReservationLayout: React.FC<Props> = props => {
  const {
    id,
    leftPercent,
    topPercent,
    widthPercent,
    heightPercent,
    title,
    time,
    participantsCount,
    popup,
    comment,
    status,
    onClick
  } = props;

  const [collected, drag] = useDrag<
    BookingReservationDragItem,
    void,
    BookingReservationDragInfo
  >(() => ({
    type: BOOKING_RESERVATION_DROP_TYPE,
    item: { id },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const wrapperClassName = bemConstructor('wrapper', { status });

  const cardClassName = bemConstructor('card');

  const titleClassName = bemConstructor('title');

  const detailsClassName = bemConstructor('details');

  const timeClassName = bemConstructor('time');

  const participantsIconClassName = bemConstructor('participantsIcon');

  const participantsCountClassName = bemConstructor('participantsCount');

  const commentClassName = bemConstructor('comment');

  const popupClassName = bemConstructor('popup');

  return (
    <div
      className={wrapperClassName}
      style={{
        opacity: collected.isDragging ? '0.5' : '1',
        left: `${leftPercent}%`,
        top: `${topPercent}%`,
        width: `${widthPercent}%`,
        height: `${heightPercent}%`
      }}
      onClick={onClick}
    >
      <div className={cardClassName} ref={drag}>
        <div className={titleClassName}>{title}</div>
        <div className={detailsClassName}>
          <div className={timeClassName}>{time}</div>
          <div className={participantsIconClassName}>
            <HumanIcon />
          </div>
          <div className={participantsCountClassName}>{participantsCount}</div>
          {comment && (
            <div title={comment} className={commentClassName}>
              <CommentIcon />
            </div>
          )}
        </div>
      </div>
      <div className={popupClassName}>{popup}</div>
    </div>
  );
};
