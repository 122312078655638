import { TReview } from 'types/state';
import { TSportingComment } from 'services/sporting/types';
import { normalizeSportingCommentStatusId } from 'services/sporting/utils/normalizeSportingCommentStatusId';
import { ContractViolationError } from 'utils/errors';

export const normalizeSportingComment = {
  toReview(comment: TSportingComment): TReview {
    try {
      if (comment.type === 'MobileComment') {
        return {
          id: comment.id,
          number: comment.commentNumber,
          type: 'MOBILE',
          status: normalizeSportingCommentStatusId.toReviewStatus(
            comment.commentStatus.id
          ),
          createdAtDate: comment.commentDate,
          clientComment: comment.commentText || undefined,
          clientEmail: comment.user.email,
          clientName: `${comment.user.lastName} ${comment.user.name}`,
          clientPhone: comment.user.mobilePhone,
          note: comment.note || ''
        };
      }

      if (comment.type === 'RestarauntComment') {
        return {
          id: comment.id,
          number: comment.commentNumber,
          type: 'RESTAURANT',
          status: normalizeSportingCommentStatusId.toReviewStatus(
            comment.commentStatus.id
          ),
          createdAtDate: comment.commentDate,
          orderNumber: comment.orderNumber,
          clientComment: comment.commentText || undefined,
          clientName: comment.name || undefined,
          clientPhone: comment.mobilePhone || undefined,
          foodScore: comment.kitchenRate,
          serviceScore: comment.serviceRate,
          note: comment.note || ''
        };
      }

      throw new TypeError();
    } catch (error) {
      throw new ContractViolationError();
    }
  }
};
