import React from 'react';

import styles from './SwitchInput.module.css';

type Props = {
  value: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  testId?: string;
  onChange(): void;
};

export function SwitchInputCheckbox(props: Props) {
  const { name, value, disabled, checked, testId, onChange } = props;

  const handleChange = React.useCallback(() => {
    onChange();
  }, [onChange]);

  return (
    <input
      type="checkbox"
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      data-testid={testId}
      onChange={handleChange}
      className={styles.SwitchInput__Checkbox}
    />
  );
}
