import { SportingRoleName } from 'services/sporting/types';
import { ChatRole } from 'types/chats';
import { UnreachableError } from 'utils/errors';

export const normalizeSportingRoleName = (role: SportingRoleName) => ({
  toChatRole(): ChatRole {
    switch (role) {
      case 'ROLE_EMPLOYEE':
      case 'ROLE_MANAGER':
      case 'ROLE_SECURITY':
      case 'ROLE_APP_STORE_TEST_USER':
      case 'ROLE_ADMIN':
        return 'STAFF';
      case 'ROLE_MOBILE_USER':
        return 'CLIENT';
      default:
        throw new UnreachableError(role);
    }
  }
});
