import React from 'react';

import styles from './Modal.module.css';

type TProps = {
  title: string;
  description: string;
};

export const ModalBody = (props: TProps) => {
  const { title, description } = props;
  return (
    <div className={styles.Modal__Body}>
      <div className={styles.Modal__Title}>{title}</div>
      <div className={styles.Modal__Description}>{description}</div>
    </div>
  );
};
