import {
  TApplication,
  TApplicationsRestType,
  TApplicationOrigin
} from 'types/state';
import { TSportingClaim, TSportingClaimOrigin } from 'services/sporting/types';
import { normalizeApplicationsRestType } from 'services/sporting/utils/normalizeApplicationsRestType';
import { UnreachableError } from 'utils/errors';

const normalizeSportingClaimOrigin = {
  toApplicationOrigin(
    sportingOrigin: TSportingClaimOrigin
  ): TApplicationOrigin {
    switch (sportingOrigin) {
      case 'CallBack':
        return 'CALL_BACK';
      case 'MissedCall':
        return 'MISSED_CALL';
      default:
        throw new UnreachableError(sportingOrigin);
    }
  }
};

export const normalizeSportingClaim = {
  toApplication(
    claim: TSportingClaim,
    status: TApplicationsRestType
  ): TApplication {
    return {
      id: claim.id,
      fullName: normalizeSportingClaim.toFullName(claim),
      createdAtDate: claim.date,
      cardNumber: claim.cardNumber,
      processedAtDate: claim.processDate,
      phone: claim.phoneNumber,
      clientId: claim.userId,
      status: normalizeApplicationsRestType.toApplicationStatus(status),
      origin: normalizeSportingClaimOrigin.toApplicationOrigin(claim.origin)
    };
  },
  toFullName(claim: TSportingClaim): null | string {
    let name = null;

    if (claim.name) {
      name = `${claim.name}`;
    }

    if (name && claim.patronymic) {
      name += ` ${claim.patronymic}`;
    }

    if (name && claim.lastName) {
      name += ` ${claim.lastName}`;
    }

    return name;
  }
};
