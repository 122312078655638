import React from 'react';

import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';
import { AuthorizationError } from 'utils/errors';
import { SetUserPhoneNumberParams } from 'types/services';

export const useUpdateUserPhoneNumberService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const { wrapAsync: wrap } = useAsyncController<void>();

  const setUserPhoneNumber = React.useCallback(
    (params: SetUserPhoneNumberParams) => {
      appDispatch({
        name: 'UPDATE_USER_PHONE_NUMBER_SERVICE',
        status: 'PENDING',
        meta: params
      });

      const promise = services
        .setUserPhoneNumber(params)
        .then(() => {
          appDispatch({
            name: 'UPDATE_USER_PHONE_NUMBER_SERVICE',
            status: 'RESOLVED',
            payload: undefined,
            meta: params
          });
        })
        .catch(error => {
          if (error instanceof AuthorizationError) {
            throw error;
          }

          appDispatch({
            name: 'UPDATE_USER_PHONE_NUMBER_SERVICE',
            status: 'REJECTED',
            payload: error,
            meta: params
          });

          return error;
        });

      wrap(promise);
    },
    [appDispatch, services, wrap]
  );

  return { setUserPhoneNumber };
};
