import React from 'react';

import styles from './NewsArticle.module.css';

type TProps = {
  label: React.ReactNode;
  placeholder: string;
};

export const NewsArticleTitle = ({ label, placeholder }: TProps) => {
  if (label) {
    return <div className={styles.NewsArticle__Title}>{label}</div>;
  }
  return (
    <div className={styles.NewsArticle__TitlePlaceholder}>{placeholder}</div>
  );
};
