import React from 'react';

import { useIntlContext } from '../../hooks/useIntlContext';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { Place } from '../../types/state';
import { ModalPortal } from '../shared/Modal';
import { ModalFooter } from '../Modal/ModalFooter';
import { RectangleButton } from '../shared/RectangleButton';
import {
  BookingPlace,
  PlaceChangeOrderType
} from './components/BookingPlace/BookingPlace';
import { useBookingPlacesEditorState } from '../../hooks/booking/useBookingPlacesEditorState';
import { useBookingPlaceEditorService } from '../../hooks/booking/useBookingPlaceEditorService';
import { CloseIcon } from 'svg/24x24/close';
import { PlusInCircleFigureComponent } from 'design/plusInCircleFigure/plusInCircleFigureComponents';
import { ActionControl } from 'design/actionControl';
import { useKeyDown } from 'hooks/useKeyDown';
import { BookingModalLayout } from 'components/BookingModalLayout/BookingModalLayoutComponents';
import { Text } from 'design/text';
import { BookingTableSettingsContentLayout } from './BookingTableSettingsContentLayoutComponents';
import { NotificationMessageModal } from '../NotificationMessageModal/NotificationMessageModal';
import { useBookingPlaceEditorImmediateValidation } from '../../hooks/booking/useBookingPlaceEditorImmediateValidation';
import { useToggler } from 'hooks/useToggler';
import { ServiceScope } from 'types/serviceScope';
import { PlaceDeleteError } from 'utils/errors';

type Props = {
  location: ServiceScope;
  places: Place[];
  onClose(): void;
};

export const BookingTableSettingsModal = (props: Props) => {
  useBookingPlaceEditorImmediateValidation();

  const { submitPlaces, asyncProcess } = useBookingPlaceEditorService();

  const { bookingTableSettingsIntl: msgs } = useIntlContext();
  const appDispatch = useAppDispatch();
  const { location, places, onClose } = props;

  const {
    formValues,
    submited,
    submitDidSucceed
  } = useBookingPlacesEditorState();

  const [hasNotificationMessage, toggleNotificationMessage] = useToggler();

  React.useEffect(() => {
    if (submited) {
      submitPlaces({
        formValues
      });
    }
  }, [formValues, submitPlaces, submited]);

  // Устанавливаем начальные значения для формы
  React.useEffect(() => {
    if (!formValues.initialized && places.length > 0) {
      appDispatch({
        name: 'BOOKING_PLACE_FORM_INIT',
        value: places,
        location
      });
    }
  }, [appDispatch, formValues.initialized, location, places]);

  const onAddPlace = React.useCallback(() => {
    appDispatch({
      name: 'BOOKING_PLACE_ADD'
    });
  }, [appDispatch]);

  const onChangePlaceTitle = React.useCallback(
    (id: string, value: string) => {
      appDispatch({
        name: 'BOOKING_PLACE_CHANGE_TITLE',
        id,
        value
      });
    },
    [appDispatch]
  );

  const onChangePlaceOrder = React.useCallback(
    (id: string, type: PlaceChangeOrderType) => {
      appDispatch({
        name: 'BOOKING_PLACE_CHANGE_ORDER',
        id,
        type
      });
    },
    [appDispatch]
  );

  const busy =
    asyncProcess.status === 'PENDING' || asyncProcess.status === 'RESOLVED';

  const onSubmit = React.useCallback(() => {
    appDispatch({
      name: 'BOOKING_PLACE_SUBMIT',
      location: location
    });
  }, [appDispatch, location]);

  const onCancel = React.useCallback(() => {
    appDispatch({
      name: 'BOOKING_PLACE_CANCEL',
      location
    });
    onClose();
  }, [appDispatch, location, onClose]);

  React.useEffect(() => {
    if (
      asyncProcess.status === 'REJECTED' &&
      asyncProcess.error instanceof PlaceDeleteError
    ) {
      toggleNotificationMessage();
    }
  }, [asyncProcess, toggleNotificationMessage]);

  React.useEffect(() => {
    if (submitDidSucceed) {
      onCancel();
    }
  }, [submitDidSucceed, onCancel]);

  useKeyDown({
    Escape: onCancel
  });

  return (
    <>
      <ModalPortal toRight={true} noPadding={true}>
        <BookingModalLayout
          closeButton={
            <ActionControl theme="invisible" onClick={onCancel}>
              <CloseIcon />
            </ActionControl>
          }
          body={
            <BookingTableSettingsContentLayout
              title={
                <Text size="xl" weight="semi-bold">
                  {msgs.title}
                </Text>
              }
              addButton={
                <ActionControl theme="invisible" onClick={onAddPlace}>
                  <PlusInCircleFigureComponent size="m" />
                </ActionControl>
              }
              form={formValues.places
                .filter(place => place.dataAction !== 'DELETE')
                .map(place => (
                  <BookingPlace
                    key={place.id}
                    location={props.location}
                    place={place}
                    onChangeTitle={value => onChangePlaceTitle(place.id, value)}
                    onChangeOrder={type => onChangePlaceOrder(place.id, type)}
                    onForbiddenDeleteAttempt={toggleNotificationMessage}
                  />
                ))}
            />
          }
          footer={
            <ModalFooter>
              <RectangleButton
                styleType={'secondary'}
                label={msgs.cancelBtnLabel}
                isDisabled={busy}
                onClick={onCancel}
              />
              <RectangleButton
                styleType={'primary'}
                label={msgs.submitBtnLabel}
                hasPreloader={busy}
                onClick={onSubmit}
              />
            </ModalFooter>
          }
        />
      </ModalPortal>
      {hasNotificationMessage && (
        <NotificationMessageModal
          title={msgs.notificationMessageTitle}
          content={msgs.notificationMessageText}
          submitBtnLabel={msgs.notificationMessageSubmitLabel}
          onSubmit={toggleNotificationMessage}
        />
      )}
    </>
  );
};
