import { TGetReviewsParams } from 'types/services';
import { normalizeSportingComment } from '../utils/normalizeSportingComment';
import { TReview } from 'types/state';
import { sportingAPI } from 'services/sporting/api';

export const getReviews = async (
  params: TGetReviewsParams
): Promise<TReview[]> => {
  const { pageNumber } = params;

  const comments = await sportingAPI.service.getComments({
    payload: { pageNum: pageNumber }
  });

  return comments.map(normalizeSportingComment.toReview);
};
