import React from 'react';

export const useAudio = (src: string) => {
  const audioRef = React.useRef(new Audio(src));

  const play = React.useCallback(() => {
    audioRef.current.play().catch(error => {
      console.log(error);
    });
  }, []);

  const handlePlay = React.useCallback(() => {
    // отключает проигрывание звука во время тестирования
    if (process.env.NODE_ENV !== 'test') {
      play();
    }
  }, [play]);

  return handlePlay;
};
