import { ChatsState } from 'types/chats';
import { AppEvent } from 'types/appEvent';
import { chatMessagesCollectionReducer } from 'reducers/chatsStateReducer/chatMessagesCollectionReducer';
import { chatRoomsCollectionReducer } from 'reducers/chatsStateReducer/chatRoomsCollectionReducer';
import { chatMessageFormByIdReducer } from 'reducers/chatsStateReducer/chatMessageFormByIdReducer';
import { chatRoomIdsReducer } from 'reducers/chatsStateReducer/chatRoomIdsReducer';
import { unreadChatIdListsReducer } from 'reducers/chatsStateReducer/unreadChatIdListsReducer';
import { editChatMessageFormReducer } from 'reducers/chatsStateReducer/editChatMessageFormReducer';
import { AppState } from 'types/appState';
import { activeChatRoomReducer } from 'reducers/chatsStateReducer/activeChatRoomReducer';
import { typingMapReducer } from 'reducers/chatsStateReducer/typingMapReducer';

export const chatsStateReducer = (
  state: ChatsState,
  event: AppEvent,
  appState: AppState
): ChatsState => {
  return {
    chatMessagesCollection: chatMessagesCollectionReducer(
      state.chatMessagesCollection,
      event
    ),
    chatRoomsCollection: chatRoomsCollectionReducer(
      state.chatRoomsCollection,
      event
    ),
    chatRoomIds: chatRoomIdsReducer(state.chatRoomIds, event),
    unreadChatIdLists: unreadChatIdListsReducer(state.unreadChatIdLists, event),
    activeChatRoom: activeChatRoomReducer(
      state.activeChatRoom,
      event,
      appState
    ),
    chatMessageFormById: chatMessageFormByIdReducer(
      state.chatMessageFormById,
      event
    ),
    editChatMessageForm: editChatMessageFormReducer(
      state.editChatMessageForm,
      event,
      appState
    ),
    typingMap: typingMapReducer(state.typingMap, event)
  };
};
