import React from 'react';

import styles from './CropImageModal.module.css';

type TProps = {
  children: React.ReactNode;
  isHidden: boolean;
};

export const CropImageModalWindow: React.FC<TProps> = props => {
  const { children, isHidden } = props;
  return (
    <div
      className={`${styles.CropImageModal__Window} ${
        isHidden ? styles.CropImageModal__Window_hidden : ''
      }`}
    >
      {children}
    </div>
  );
};
