import React from 'react';

import { CenteredLayout } from 'design/centeredLayout';
import { testId } from 'utils/testId';
import { Text } from 'design/text';
import { Section } from 'design/section';
import { Spinner } from 'components/shared/Spinner';
import { StandardLayout } from 'design/standardLayout';
import { useIntlContext } from 'hooks/useIntlContext';
import { ChatsPageFeed } from 'pages/ChatsPage/ChatsPageFeed';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { useChatRoomsService } from 'hooks/chats/usetChatRoomsService';
import { useChatRoomIds } from 'hooks/chats/useChatsState';

export const ChatsPageNavigation = () => {
  useChatRoomsService();

  const { chatsPage: chatsPageIntl } = useIntlContext();

  const chatRoomIds = useChatRoomIds();

  const chatRoomIdsSelectors = createListCacheSelectors(chatRoomIds);

  const initialLoading = React.useMemo(() => {
    const pagesCount = chatRoomIdsSelectors.selectAllPages().length;

    const invalidatedPagesCount = chatRoomIdsSelectors.selectInvalidatedPages()
      .length;

    return pagesCount === 0 && invalidatedPagesCount > 0;
  }, [chatRoomIdsSelectors]);

  const noChats = React.useMemo(() => {
    const itemsCount = chatRoomIdsSelectors.selectAllItems().length;

    const invalidatedPagesCount = chatRoomIdsSelectors.selectInvalidatedPages()
      .length;

    return itemsCount === 0 && invalidatedPagesCount === 0;
  }, [chatRoomIdsSelectors]);

  if (initialLoading) {
    return (
      <Section>
        <CenteredLayout>
          <Spinner testId={testId.CHATS_PAGE_NAVIGATION__PRELOADER} />
        </CenteredLayout>
      </Section>
    );
  }

  if (noChats) {
    return (
      <Section>
        <CenteredLayout>
          <Text
            testId={testId.CHATS_PAGE_NAVIGATION__FALLBACK_TEXT}
            color="secondary"
          >
            {chatsPageIntl.navigationFallbackText}
          </Text>
        </CenteredLayout>
      </Section>
    );
  }

  return (
    <Section>
      <StandardLayout
        block="chats-navigation"
        header={<Text bold>{chatsPageIntl.navigationTitle}</Text>}
        body={<ChatsPageFeed />}
        footer={null}
      />
    </Section>
  );
};
