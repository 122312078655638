import { LoginFormValues } from 'types/state';

export const patchLoginFormValues = (values: LoginFormValues) => ({
  done() {
    return values;
  },
  setLogin(nextValue: string) {
    return patchLoginFormValues({
      ...values,
      login: nextValue
    });
  },
  setPassword(nextValue: string) {
    return patchLoginFormValues({
      ...values,
      password: nextValue
    });
  }
});
