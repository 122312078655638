import { BookingPlaceItemFormValues } from 'types/state';
import { SportingSubmitPlaceItemPayload } from '../types';

export const normalizeBookingPlaceItemFormValues = (
  itemFormValues: BookingPlaceItemFormValues
) => ({
  toSportingSubmitPlaceItemPayload(): SportingSubmitPlaceItemPayload {
    return {
      dataAction: itemFormValues.dataAction,
      id: Number(itemFormValues.id),
      title: itemFormValues.title,
      minVisitorsCount: Number(itemFormValues.minVisitorsCount),
      maxVisitorsCount: Number(itemFormValues.maxVisitorsCount),
      orderNumber: itemFormValues.orderNumber
    };
  }
});
