import React from 'react';
import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';

export const useTimeLinesService = () => {
  const dispatch = useAppDispatch();

  const services = useServices();

  const { wrapAsync, asyncProcess } = useAsyncController();

  const loadTimeLines = React.useCallback(() => {
    const promise = services.getTimeLines().then(timeLines => {
      dispatch({
        name: 'TimeLinesRecieved',
        timeLines
      });
    });

    wrapAsync(promise);
  }, [dispatch, services, wrapAsync]);

  return { loadTimeLines, asyncProcess };
};
