import { SectionsEditorState } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { createSectionsEditorState } from 'utils/createSectionsEditorState';
import { patchSectionsFormValues } from 'utils/patchSectionsFormValues';
import { patchEditorState } from 'utils/patchEditorState';

export const sectionsEditorStateReducer = (
  state: SectionsEditorState,
  event: AppEvent
): SectionsEditorState => {
  switch (event.name) {
    case 'ROUTER__LOCATION_PATHNAME_CHANGE': {
      return createSectionsEditorState();
    }
    case 'SECTIONS_FORM__INPUT_CHANGE': {
      const nextFormValues = patchSectionsFormValues(
        state.formValues
      ).setSectionEnabledStatus(event.section, event.enabled);

      const nextSectionsEditorState = patchEditorState(state)
        .setFormValues(nextFormValues.done())
        .setSubmitDidSucceed(false)
        .done();

      return nextSectionsEditorState;
    }

    case 'SECTIONS_FORM__CANCEL': {
      const nextSectionsEditorState = patchEditorState(state)
        .setFormValues(new Map())
        .done();

      return nextSectionsEditorState;
    }

    case 'SECTIONS_FORM__SUBMIT': {
      const nextSectionsEditorState = patchEditorState(state)
        .setSubmited(true)
        .done();

      return nextSectionsEditorState;
    }

    case 'UPDATE_ENABLED_SECTIONS_SERVICE': {
      if (event.status === 'RESOLVED') {
        const nextSectionsEditorState = patchEditorState(state)
          .setSubmited(false)
          .setSubmitDidSucceed(true)
          .done();

        return nextSectionsEditorState;
      }

      if (event.status === 'REJECTED') {
        const nextSectionsEditorState = patchEditorState(state)
          .setFormValues(new Map())
          .done();

        return nextSectionsEditorState;
      }

      return state;
    }
    default:
      return state;
  }
};
