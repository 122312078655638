import React from 'react';

import styles from './CompetitionsArticle.module.css';

type TProps = {
  children: React.ReactNode;
  testId?: string;
};

export const CompetitionsArticleContent: React.FC<TProps> = ({
  children,
  testId
}) => (
  <div className={styles.CompetitionsArticle__Content} data-testid={testId}>
    {children}
  </div>
);
