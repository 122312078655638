import { ServerEvent } from 'types/appEvent/serverEvent';
import { sportingAPI } from 'services/sporting/api';
import { normalizeSportingEvent } from 'services/sporting/utils/normalizeSportingEvent';
import { createUniqueId } from 'utils/createUniqueId';

const tabId = createUniqueId();

export const getServerEvents = async (): Promise<null | ServerEvent[]> => {
  const sportingEventList = await sportingAPI.service.subscribeToEventList({
    tabId
  });

  if (sportingEventList) {
    return sportingEventList.map(sportingEvent =>
      normalizeSportingEvent(sportingEvent).toServerEvent()
    );
  }

  return null;
};
