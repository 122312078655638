import { useParams } from 'react-router';

import { TNewsFilter } from 'types/state';
import { RouterParamError } from 'utils/errors';

type RouterParams = {
  filter: string;
};

export const useNewsPageFilter = (): TNewsFilter => {
  const { filter } = useParams<RouterParams>();

  switch (filter) {
    case 'all':
    case 'drafts':
    case 'published':
      return filter;
    default:
      throw new RouterParamError(filter);
  }
};
