import React from 'react';

import styles from './SignInPage.module.css';

type TProps = {
  children: React.ReactNode;
  onSubmit(e: React.SyntheticEvent): void;
};

export const SignInPageForm = ({ children, onSubmit }: TProps) => (
  <form className={styles.SignInPage__Form} onSubmit={onSubmit}>
    {children}
  </form>
);
