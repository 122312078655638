import { ChatRoomsCollection } from 'types/chats';
import { AppEvent } from 'types/appEvent';
import { modifyCollectionCache } from 'utils/collectionCacheUtils';

export const chatRoomsCollectionReducer = (
  state: ChatRoomsCollection,
  event: AppEvent
) => {
  switch (event.name) {
    case 'CHAT_ROOMS_SERVICE': {
      if (event.status === 'RESOLVED') {
        return event.payload
          .reduce(
            (acc, [room]) => acc.putItem(room.id, room),
            modifyCollectionCache(state)
          )
          .return();
      }

      return state;
    }
    default:
      return state;
  }
};
