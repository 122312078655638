import { EmployeeFormError, EmployeeFormValues } from 'types/state';

export const validateEmployeeFormValues = (
  values: EmployeeFormValues
): EmployeeFormError[] | undefined => {
  let employeeFormErrors: EmployeeFormError[] = [];
  if (!values.lastName) {
    employeeFormErrors.push('LASTNAME_IS_EMPTY');
  }
  if (!values.firstName) {
    employeeFormErrors.push('FIRSTNAME_IS_EMPTY');
  }
  if (!values.login) {
    employeeFormErrors.push('LOGIN_IS_EMPTY');
  }
  if (values.id === 0 && !values.password) {
    employeeFormErrors.push('PASSWORD_IS_EMPTY');
  }
  if (values.password !== values.passwordConfirm) {
    employeeFormErrors.push('PASSWORDS_DID_NOT_MATCH');
  }
  return employeeFormErrors.length > 0 ? employeeFormErrors : undefined;
};
