import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { SportingSecureParams, SportingCount } from 'services/sporting/types';
import { createQueryString } from 'utils/createQueryString';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  idCompetition: number;
};

export const getParticipationsCount = async (
  params: SportingSecureParams<Payload>
): Promise<SportingCount> => {
  const { payload } = params;

  const endpointURL = getSportingServiceURL(
    `getParticipationsCount?${createQueryString(payload)}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (response.ok) {
    return response.json();
  } else {
    throw new UnexpectedError();
  }
};
