import { TCompetitionParticipantLogRecord } from 'types/state';
import { TIntl } from 'types/intl';
import { getCompetitionParticipantStatusIntl } from 'utils/getCompetitionParticipantStatusIntl';

export const normalizeLogRecordToLogItem = (intl: TIntl) => (
  record: TCompetitionParticipantLogRecord
) => {
  const title = getCompetitionParticipantStatusIntl(record.status, intl);

  const bodyDate = new Date(record.date).toLocaleString(['ru'], {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit'
  });

  const bodyTime = new Date(record.date).toLocaleString(['ru'], {
    hour: 'numeric',
    minute: 'numeric'
  });

  const body = `${intl.competitionParticipantsStatusHistoryDate(
    bodyDate,
    bodyTime
  )}, ${record.changedBy}`;

  const key = record.id;

  return {
    title,
    body,
    key
  };
};
