import React from 'react';
import { PlusIcon as PlusIcon16x16 } from 'svg/16x16/plus';
import { PlusIcon as PlusIcon20x20 } from 'svg/20x20/plus';
import { createBEMConstructor } from 'utils/createBEMConstructor';

import './plusInCircleFigureStyles.scss';

type Props = {
  size: 'm' | 'l';
};

export const PlusInCircleFigureComponent = ({ size }: Props) => {
  const createClassName = createBEMConstructor('plusInCircleFigure');

  const wrapper = createClassName('wrapper', { size });

  return (
    <div className={wrapper}>
      {size === 'm' && <PlusIcon16x16 />}
      {size === 'l' && <PlusIcon20x20 />}
    </div>
  );
};
