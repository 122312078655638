import React from 'react';

import styles from './CompetitionNotes.module.css';

import { COMPETITION_NOTES_MAX_LENGTH } from 'utils/constants';

type TProps = {
  value: string;
  placeholder: string;
  disabled: boolean;
  onChange(nextValue: string): void;
  onBlur(): void;
};

export const CompetitionNotesInput = ({
  value,
  placeholder,
  disabled,
  onChange,
  onBlur
}: TProps) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  const handleBlur = onBlur;

  return (
    <textarea
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      className={styles.CompetitionNotes__TextArea}
      placeholder={placeholder}
      maxLength={COMPETITION_NOTES_MAX_LENGTH}
    />
  );
};
