import React from 'react';

import { BookingTimelineGroupLayout } from 'design/bookingTimelineGroupLayout/bookingTimelineGroupLayout';
import { ActionControl } from 'design/actionControl';
import { Text } from 'design/text';
import { BookingTimelineHeaderLayout } from 'design/bookingTimelineHeaderLayout/bookingTimelineHeaderLayout';
import { BookingTimelineGridLayout } from 'design/bookingTimelineGridLayout/bookingTimelineGridLayout';
import {
  BookingTimelineGroup,
  BookingTimelineReservation
} from 'types/bookingTimeline';
import { CalendarPeriod, PlaceItem } from 'types/state';
import { BookingTimelineReservationComponent } from './BookingTimelineReservation';
import { BookingTimelineTrackLabelLayout } from 'design/bookingTimelineTrackLabelLayout/bookingTimelineTrackLabelLayout';
import { BookingTimelineTrackLayout } from 'design/bookingTimelineTrackLayout/bookingTimelineTrackLayout';
import { BookingTimelineTrackItemLayout } from 'design/bookingTimelineTrackItemLayout/bookingTimelineTrackItemLayout';
import { useBookablePeriods } from 'hooks/booking/useBookablePeriods';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { areTwoCalendarPeriodsOverlapping } from 'utils/calendarPeriodUtils';
import { useBookingTimelineGroupCollapsing } from 'hooks/useBookingTimelineGroupCollapsing';
import { ArrowUpIcon } from 'svg/16x16/arrow-up';
import { ServiceScope } from 'types/serviceScope';
import { useAppState } from 'hooks/useAppState';
import { useBookingReservationUpdateService } from 'hooks/booking/useBookingReservationUpdateService';
import { normalizeReservation } from 'utils/booking/normalizeReservation';
import { addMinutes, differenceInMinutes } from 'date-fns';

type Props = {
  scope: ServiceScope;
  group: BookingTimelineGroup;
  reservations: BookingTimelineReservation[];
  workingHours: CalendarPeriod;
  onReservationClick(id: number): void;
};

export const BookingTimelineGroupComponent = (props: Props) => {
  const {
    scope,
    group: { name, items, id },
    reservations: bookingReservations,
    workingHours,
    onReservationClick
  } = props;

  const itemIds = React.useMemo(() => {
    return items.map(item => item.id);
  }, [items]);

  const { collapsed, toggleCollapsed } = useBookingTimelineGroupCollapsing(id);

  const bookablePeriods = useBookablePeriods(workingHours);

  const dispatch = useAppDispatch();

  const createTrackClickHandler = React.useCallback(
    (placeItem: PlaceItem, period: CalendarPeriod) => {
      return () => {
        dispatch({
          name: 'BookingPage__TrackItemClicked',
          groupId: id,
          placeItem,
          period,
          lastWorkingTimeISO: workingHours.endDateISO,
          serviceScope: scope
        });
      };
    },
    [dispatch, id, scope, workingHours.endDateISO]
  );

  const { reservationsCache } = useAppState();

  const { updateReservation } = useBookingReservationUpdateService();

  const checkPeriodForReservation = React.useCallback(
    (itemId: number, period: CalendarPeriod) => {
      const slotReservations = bookingReservations.filter(reservation =>
        reservation.itemIds.includes(itemId)
      );

      for (let slotReservation of slotReservations) {
        const hasOverlap = areTwoCalendarPeriodsOverlapping(
          slotReservation.period,
          period
        );

        if (hasOverlap) {
          return true;
        }
      }
      return false;
    },
    [bookingReservations]
  );

  const createDropOnTrackHandler = React.useCallback(
    (placeItem: PlaceItem, period: CalendarPeriod) => {
      return (reservationId: number) => {
        const reservation = reservationsCache.value.get(reservationId);

        if (reservation) {
          const formValues = normalizeReservation(
            reservation
          ).toBookingReservationFormValues(scope);

          const diff = differenceInMinutes(
            new Date(reservation.period.endDateISO),
            new Date(reservation.period.startDateISO)
          );

          const nextEndDateISO = addMinutes(
            new Date(period.startDateISO),
            diff
          ).toISOString();

          formValues.period = {
            startDateISO: period.startDateISO,
            endDateISO: nextEndDateISO
          };

          const indexOfFirstNextPlaceItem = itemIds.indexOf(placeItem.id);

          const indexOfLastNextPlaceItem =
            indexOfFirstNextPlaceItem + reservation.places.length;

          formValues.placeItems = items.slice(
            indexOfFirstNextPlaceItem,
            indexOfLastNextPlaceItem
          );

          // Проверка новых мест на наличие броней в указанный период
          for (let placeItem of formValues.placeItems) {
            const reserved = checkPeriodForReservation(
              placeItem.id,
              formValues.period
            );

            if (reserved) {
              return;
            }
          }

          updateReservation({
            formValues
          });
        }
      };
    },
    [
      checkPeriodForReservation,
      itemIds,
      items,
      reservationsCache.value,
      scope,
      updateReservation
    ]
  );

  const patchedBookingReservations = React.useMemo(() => {
    const placeItemIdsText = itemIds.join(',');

    const patchedReservations = bookingReservations.reduce<
      BookingTimelineReservation[]
    >((acc, reservation) => {
      // Хитрая логика по объединению броней на соседних треках
      const reservationPlaceItemIdsText = reservation.itemIds
        .sort((nextId, prevId) => {
          return itemIds.indexOf(nextId) - itemIds.indexOf(prevId);
        })
        .join(',');

      if (placeItemIdsText.includes(reservationPlaceItemIdsText)) {
        acc.push(reservation);
      } else {
        for (let itemId of reservation.itemIds) {
          if (itemIds.includes(itemId)) {
            acc.push({
              ...reservation,
              itemIds: [itemId]
            });
          }
        }
      }

      return acc;
    }, []);

    return patchedReservations;
  }, [bookingReservations, itemIds]);

  return (
    <BookingTimelineGroupLayout
      collapsed={collapsed}
      header={
        <ActionControl
          type="button"
          theme="invisible"
          onClick={toggleCollapsed}
        >
          <BookingTimelineHeaderLayout
            collapsed={collapsed}
            title={
              <Text size="m" color="primary">
                {name}
              </Text>
            }
            icon={<ArrowUpIcon />}
          />
        </ActionControl>
      }
      body={
        <BookingTimelineGridLayout
          labels={items.map(item => (
            <BookingTimelineTrackLabelLayout
              key={item.id}
              title={
                <Text color="primary" size="xs">
                  {item.title}
                </Text>
              }
              subtitle={
                <Text color="accent" size="xxs">
                  {item.minVisitorsCount + '-' + item.maxVisitorsCount}
                </Text>
              }
            />
          ))}
          tracks={items.map(item => (
            <BookingTimelineTrackLayout
              key={item.id}
              items={bookablePeriods.map(
                bookablePeriod => (
                  <BookingTimelineTrackItemLayout
                    key={bookablePeriod.startDateISO}
                    borderStyling={
                      new Date(bookablePeriod.startDateISO).getMinutes() === 0
                        ? 'solid'
                        : 'dashed'
                    }
                    onClick={createTrackClickHandler(item, bookablePeriod)}
                    onDrop={createDropOnTrackHandler(item, bookablePeriod)}
                    disabled={checkPeriodForReservation(
                      item.id,
                      bookablePeriod
                    )}
                  />
                ),
                []
              )}
            />
          ))}
          reservations={patchedBookingReservations.map(
            (bookingReservation, index) => (
              <BookingTimelineReservationComponent
                key={
                  bookingReservation.id + bookingReservation.itemIds.join(',')
                }
                reservation={bookingReservation}
                placeItems={items}
                workingHours={workingHours}
                onReservationClick={onReservationClick}
              />
            )
          )}
        />
      }
    />
  );
};
