import React from 'react';

import { TNewsFilter } from 'types/state';
import { useNewsCache } from 'pages/NewsPage/hooks/useNewsCache';

const INITIAL_PAGE_NUMBER = 1;

type TLoadParams = {
  reload?: boolean;
};

export const useNewsFeed = (filter: TNewsFilter) => {
  // Счетчик страниц.
  const nextPageRef = React.useRef(INITIAL_PAGE_NUMBER);

  // Флаг предовращает загрузку последующих страниц.
  // Обеспечивает постепенную загрузку страниц при скроллинге
  // ленты.
  const preventLoadMoreRef = React.useRef(false);

  // Подключается кэш статей.
  const { itemsById, load: loadCache, loading } = useNewsCache();

  // Эффект следит за флагом продолжающейся загрузки кэша
  // и разрешает дальнейшую загрузку статей, как только
  // текущая загрузка закончится.
  React.useEffect(() => {
    if (!loading) {
      preventLoadMoreRef.current = false;
    }
  }, [loading]);

  // Метод загрузки статей для актуальной страницы.
  const loadCurrentPageNews = React.useCallback(() => {
    const { current: page } = nextPageRef;

    // Инвалидация кэша производится в случае, если
    // необходимо загрузить данные для начальной страницы.
    const shouldInvalidate = page === INITIAL_PAGE_NUMBER;

    loadCache({
      page,
      filter,
      shouldInvalidate
    });
  }, [loadCache, filter]);

  // Внешний метод загрузки ленты статей. Производит
  // либо загрузку следующей страницы, либо полную перезагрузку
  // ленты.
  const load = React.useCallback(
    (params: TLoadParams = {}) => {
      // Загрузка производится только в случае, если не
      // установлен запрещающий флаг.
      if (!preventLoadMoreRef.current || params.reload) {
        // Необходимо сразу предотвратить последующие попытки
        // вызвать загрузку ленты.
        preventLoadMoreRef.current = true;

        if (params.reload) {
          // Если был передан флаг перезагрузки сбрасывается
          // счетчик страниц.
          nextPageRef.current = INITIAL_PAGE_NUMBER;
        }

        loadCurrentPageNews();

        // Значение следующей страницы для загрузки необходимо
        // увеличить ПОСЛЕ того, как была вызвана загрузка для текущей
        // страницы.
        nextPageRef.current += 1;
      }
    },
    [loadCurrentPageNews]
  );

  return {
    itemsById,
    load,
    loading
  };
};
