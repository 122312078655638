import React from 'react';

import styles from 'components/shared/Spinner.module.css';
import { SpinnerIcon } from 'svg/24x24/spinner';

type TProps = {
  className?: string;
  testId?: string;
};

export const Spinner = ({ className = '', testId }: TProps) => (
  <SpinnerIcon
    data-testid={testId}
    className={`${styles.Spinner} ${className ? className : ''}`}
  />
);
