import React from 'react';

import { TNewsAudienceGroup } from 'types/state';
import { TIntl } from 'types/intl';
import { AudienceInputTemplate } from 'containers/AudienceInput/styled/AudienceInputTemplate';
import { AudienceInputOption } from 'containers/AudienceInput/styled/AudienceInputOption';
import { useIntlContext } from 'hooks/useIntlContext';
import { UnreachableError } from 'utils/errors';

type TProps = {
  value: TNewsAudienceGroup[];
  onChange(nextAudience: TNewsAudienceGroup[]): void;
};

export type TAudienceInputProps = TProps;

interface TCustomInput extends HTMLInputElement {
  value: TNewsAudienceGroup;
}

const inputValues: TNewsAudienceGroup[] = [
  'usual',
  'hunter',
  'sportsmen',
  'force',
  'gman'
];

const getAudienceInputLabel = (intl: TIntl, value: TNewsAudienceGroup) => {
  switch (value) {
    case 'force':
      return intl.audienceGroupForce;
    case 'gman':
      return intl.audienceGroupGMan;
    case 'hunter':
      return intl.audienceGroupHunter;
    case 'sportsmen':
      return intl.audienceGroupSportsmen;
    case 'usual':
      return intl.audienceGroupUsual;
    default:
      throw new UnreachableError(value);
  }
};

export const AudienceInput = (props: TProps) => {
  const { value: prevValue, onChange } = props;

  const intl = useIntlContext();

  const { audienceInputTitle } = intl;

  const handleChange = (event: React.ChangeEvent<TCustomInput>) => {
    const { value, checked } = event.currentTarget;
    let nextAudience;
    if (checked) {
      nextAudience = [...prevValue, value];
    } else {
      nextAudience = prevValue.filter(group => group !== value);
    }
    onChange(nextAudience);
  };

  const handleSelectAll = () => {
    if (prevValue.length === inputValues.length) {
      onChange([]);
    } else {
      onChange([...inputValues]);
    }
  };

  return (
    <AudienceInputTemplate title={audienceInputTitle}>
      <AudienceInputOption
        value="all"
        isChecked={prevValue.length === inputValues.length}
        label={intl.audienceGroupAll}
        onChange={handleSelectAll}
      />
      {inputValues.map(inputValue => (
        <AudienceInputOption
          key={inputValue}
          value={inputValue}
          isChecked={prevValue.includes(inputValue)}
          label={getAudienceInputLabel(intl, inputValue)}
          onChange={handleChange}
        />
      ))}
    </AudienceInputTemplate>
  );
};
