import React from 'react';

import { createListCacheSelectors } from 'utils/listCacheUtils';
import { useUnreadChatIdLists } from 'hooks/chats/useChatsState';
import { setCustomTimeout } from 'utils/setCustomTimeout';
import { CHATS_PAGE_BAGES_DELAY } from 'utils/constants';
import { ServiceScope } from 'types/serviceScope';

export const useUnreadChatsCount = (domain: ServiceScope): number => {
  const { restaurantIds, sportIds } = useUnreadChatIdLists();

  const [count, setCount] = React.useState(0);

  const target = domain === 'RESTAURANT' ? restaurantIds : sportIds;

  React.useEffect(() => {
    // Бейдж непрочитанных сообщений отображается с задержкой,
    // чтобы избежать мерцания при получении сообщений в открытую
    // переписку.
    const count = createListCacheSelectors(target).selectAllItems().length;

    return setCustomTimeout(() => {
      setCount(count);
    }, CHATS_PAGE_BAGES_DELAY);
  }, [target]);

  return count;
};
