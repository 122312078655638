import React from 'react';

import { TApplicationsGroup, TApplication } from 'types/state';
import { useAppState } from 'hooks/useAppState';
import { normalizeApplications } from 'utils/normalizeApplciations';
import { UnreachableError } from 'utils/errors';
import { ServiceScope } from 'types/serviceScope';

export const useProcessedApplicationGroups = (
  domain: ServiceScope
): TApplicationsGroup[] => {
  const {
    processedSportApplicationsList,
    processedRestaurantApplicationsList,
    applicationsCollection
  } = useAppState();

  const targetList = (() => {
    switch (domain) {
      case 'RESTAURANT':
        return processedRestaurantApplicationsList;
      case 'CLUB':
        return processedSportApplicationsList;
      default:
        throw new UnreachableError(domain);
    }
  })();

  const processedApplicationIds = React.useMemo(() => {
    return Array.from(targetList.byPage.values()).reduce<number[]>(
      (acc, ids) => [...acc, ...ids],
      []
    );
  }, [targetList.byPage]);

  const processedApplcations = React.useMemo(() => {
    return processedApplicationIds.reduce<TApplication[]>((acc, id) => {
      const application = applicationsCollection.byId.get(id);

      if (application) {
        acc.push(application);
      }

      return acc;
    }, []);
  }, [applicationsCollection.byId, processedApplicationIds]);

  const processedApplicationGroup = React.useMemo(() => {
    return normalizeApplications.toApplicationGroups(
      processedApplcations,
      'DONE'
    );
  }, [processedApplcations]);

  return processedApplicationGroup;
};
