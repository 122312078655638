import { SportingUser } from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';
import { createQueryString } from '../../../../utils/createQueryString';
import { TFindUsersParams } from '../../../../types/services';

export const findUsers = async (
  params: TFindUsersParams
): Promise<SportingUser[]> => {
  const endpointURL = getSportingBookingURL(
    `findUsers?${createQueryString(params)}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, { headers });

  if (!response.ok) {
    throw new UnexpectedError();
  }
  return response.json();
};
