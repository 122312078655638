import React from 'react';

import styles from './ScrollableShadow.module.css';

import { useToggler } from 'hooks/useToggler';
import { ShadowContainer } from 'components/shared/ShadowContainer';

type TProps = {
  children: React.ReactNode;
  threshold: number;
  className?: string;
  onScroll?: (e: React.SyntheticEvent) => void;
};

export const ScrollableShadow = ({
  children,
  threshold,
  className = '',
  onScroll
}: TProps) => {
  const [hasScrolledView, toggleHasScrolledView] = useToggler();

  const handleScroll = React.useCallback(
    (e: React.SyntheticEvent) => {
      const node = e.currentTarget;
      if (
        (node.scrollTop >= threshold && !hasScrolledView) ||
        (node.scrollTop < threshold && hasScrolledView)
      ) {
        toggleHasScrolledView();
      }
      if (onScroll) {
        onScroll(e);
      }
    },
    [onScroll, hasScrolledView, toggleHasScrolledView, threshold]
  );

  return (
    <ShadowContainer className={className} hasShadow={hasScrolledView}>
      <div
        className={`${styles.ScrollableShadow__Scroll} ${
          hasScrolledView ? styles.ScrollableShadow__Scroll_scrolled : ''
        }`}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </ShadowContainer>
  );
};
