import { UpdateEmployeeParams } from 'types/services';
import { sportingAPI } from '../api';
import { normalizeSportingUserRole } from '../utils/normalizeSportingUserRole';
import { normalizeSportingUserInfo } from '../utils/normalizeSportingUserInfo';
import { UserInfo } from 'types/state';

export const updateEmployee = async (
  params: UpdateEmployeeParams
): Promise<UserInfo> => {
  const {
    id,
    role,
    phoneNumber,
    firstName,
    lastName,
    login,
    password
  } = params;

  const data = await sportingAPI.service.updateEmployee({
    payload: {
      id: id,
      role: normalizeSportingUserRole.toSportingUserRole(role),
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      username: login,
      password: password
    }
  });

  return normalizeSportingUserInfo.toUserInfo(data);
};
