import { ChatRoom } from 'types/chats';
import { useChatRoomsCollection } from 'hooks/chats/useChatsState';
import { createCollectionCacheSelectors } from 'utils/collectionCacheUtils';

export const useChatRoom = (roomId: number): null | ChatRoom => {
  const collection = useChatRoomsCollection();

  return createCollectionCacheSelectors(collection).selectItem(roomId);
};

export const useChatRoomStrict = (roomId: number): ChatRoom => {
  const chatRoom = useChatRoom(roomId);

  if (!chatRoom) {
    throw new TypeError();
  }

  return chatRoom;
};
