import React from 'react';
import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';
import { UserInfo } from 'types/state';
import { AuthorizationError } from 'utils/errors';
import { useEmployeeEditorState } from './useEmployeeEditorState';
import { CreateEmployeeParams, UpdateEmployeeParams } from 'types/services';

export const useEmployeeEditorService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const employeeEditorState = useEmployeeEditorState();

  const { wrapAsync: wrap, asyncProcess: process } = useAsyncController<
    UserInfo
  >();

  // Обновление пользователя
  const updateEmployee = React.useCallback(
    (params: UpdateEmployeeParams) => {
      const promise = services.updateEmployee(params).catch(error => {
        if (error instanceof AuthorizationError) {
          throw error;
        }
        return error;
      });

      wrap(promise);
    },
    [services, wrap]
  );

  // Создание пользователя
  const createEmployee = React.useCallback(
    (params: CreateEmployeeParams) => {
      const promise = services.createEmployee(params).catch(error => {
        if (error instanceof AuthorizationError) {
          throw error;
        }
        return error;
      });

      wrap(promise);
    },
    [services, wrap]
  );

  React.useEffect(() => {
    if (employeeEditorState.submited) {
      const formValues = employeeEditorState.formValues;
      if (formValues.id === 0) {
        const createEmployeeParams = {
          role: formValues.role,
          phoneNumber: formValues.phoneNumber,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          login: formValues.login,
          password: formValues.password
        };
        createEmployee(createEmployeeParams);
      } else {
        const updateEmployeeParams = {
          id: formValues.id,
          role: formValues.role,
          phoneNumber: formValues.phoneNumber,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          login: formValues.login,
          password: formValues.password
        };
        updateEmployee(updateEmployeeParams);
      }
    }
  }, [
    employeeEditorState.submited,
    createEmployee,
    updateEmployee,
    employeeEditorState.formValues
  ]);

  React.useEffect(() => {
    if (process.status === 'PENDING') {
      appDispatch({
        name: 'EMPLOYEE_EDIT_SERVICE',
        status: 'PENDING'
      });
    }
    if (process.status === 'RESOLVED') {
      appDispatch({
        name: 'EMPLOYEE_EDIT_SERVICE',
        status: 'RESOLVED',
        payload: process.value
      });
    }
    if (process.status === 'REJECTED') {
      appDispatch({
        name: 'EMPLOYEE_EDIT_SERVICE',
        status: 'REJECTED',
        payload: process.error
      });
    }
  }, [appDispatch, process]);
};
