import React from 'react';

import { useServices } from 'hooks/useServices';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { useMessageTemplatesState } from 'hooks/messageTemplates/useMessageTemplatesState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

export const useMessageTemplatesService = () => {
  const {
    messageTemplatesList,
    deletedTemplateIds
  } = useMessageTemplatesState();

  const dispatch = useAppDispatch();

  const { getMessageTemplates, editMessageTemplates } = useServices();

  const messageTemplatesListSelectors = createListCacheSelectors(
    messageTemplatesList
  );

  const nextInvalidatedPage = messageTemplatesListSelectors.selectNextInvalidatedPage();

  const messageTemplatesListSize = messageTemplatesListSelectors.selectAllItems()
    .length;

  const allTemplatesShouldBeDeleted =
    deletedTemplateIds.length > 0 &&
    deletedTemplateIds.length === messageTemplatesListSize;

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    if (nextInvalidatedPage) {
      const promise = getMessageTemplates({
        page: nextInvalidatedPage
      }).then(templates => {
        dispatch({
          name: 'MESSAGE_TEMPLATES__TEMPLATES_RECIEVED',
          templates,
          page: nextInvalidatedPage
        });
      });

      wrapAsyncSafely(promise);
    }
  }, [dispatch, getMessageTemplates, nextInvalidatedPage, wrapAsyncSafely]);

  React.useEffect(() => {
    if (allTemplatesShouldBeDeleted) {
      const promise = editMessageTemplates({
        deletedTemplateIds,
        changedTemplates: []
      }).then(() => {
        dispatch({
          name: 'MESSAGE_TEMPLATES__TEMPLATES_EDITED'
        });
      });

      wrapAsyncSafely(promise);
    }
  }, [
    allTemplatesShouldBeDeleted,
    deletedTemplateIds,
    dispatch,
    editMessageTemplates,
    nextInvalidatedPage,
    wrapAsyncSafely
  ]);
};
