import React from 'react';
import styles from './BookingWaiterItem.module.scss';
import { EmployeeItemBtn } from '../../EmployeeItemBtn/EmployeeItemBtn';
import {
  BookingWaiterFormValues,
  BookingWaitersFormViolation
} from '../../../types/state';
import { useIntlContext } from '../../../hooks/useIntlContext';
import { TrashIcon } from '../../../svg/24x24/trash';
import { TextInputControl } from 'design/textInputControl';
import { multiplyClassName } from 'utils/className';

type Props = {
  violations: BookingWaitersFormViolation[];
  waiter: BookingWaiterFormValues;
  isEditable(): boolean;
  onChangeName(value: string): void;
  onDelete(id: string, fio: string): void;
};

export const BookingWaiterItem = (props: Props) => {
  const { violations, waiter, isEditable, onChangeName, onDelete } = props;

  const invalid = React.useMemo(() => {
    for (let violation of violations) {
      if (violation.name === 'MISSING_FULL_NAME_VALUE') {
        return violation.waiterId === waiter.id;
      }
    }
  }, [violations, waiter.id]);

  const { bookingWaiterItemIntl: msgs } = useIntlContext();

  return (
    <div
      className={multiplyClassName([
        styles.bookingWaiterItem,
        invalid ? styles.bookingWaiterItem__invalid : ''
      ])}
    >
      {isEditable() ? (
        <TextInputControl
          invalid={invalid}
          autoFocus
          placeholder={msgs.placeholder}
          value={waiter.fio}
          onValueChange={onChangeName}
        />
      ) : (
        <div className={styles.fioLabel}>{waiter.fio}</div>
      )}
      <div className={styles.btnPanel}>
        <EmployeeItemBtn
          hoverAccent="critical"
          icon={<TrashIcon />}
          onClick={() => onDelete(waiter.id, waiter.fio)}
        />
      </div>
    </div>
  );
};
