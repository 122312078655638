import React from 'react';

import styles from './RectangleButton.module.scss';

import { Spinner } from 'components/shared/Spinner';
import { multiplyClassName } from 'utils/className';

export type TStyleType = 'primary' | 'secondary' | 'destructive';

type Props = {
  label: React.ReactNode;
  type?: 'button' | 'submit';
  isDisabled?: boolean;
  className?: string;
  testIdButton?: string;
  testIdSpinner?: string;
  icon?: React.ReactNode;
  href?: string;
  styleType?: TStyleType;
  hasPreloader?: boolean;
  onClick?: () => void;
};

export const RectangleButton: React.FC<Props> = ({
  type,
  label,
  onClick = () => {},
  styleType,
  hasPreloader,
  isDisabled,
  className = '',
  testIdButton,
  testIdSpinner,
  icon,
  href
}: Props) => {
  const localClassName = multiplyClassName([
    styles.RectangleButton,
    styleType === 'secondary' ? styles.RectangleButton_secondary : '',
    styleType === 'primary' ? styles.RectangleButton_primary : '',
    styleType === 'destructive' ? styles.RectangleButton_destructive : '',
    icon && !!label ? styles.iconWithLabel : '',
    hasPreloader ? styles.RectangleButton_preloader : '',
    isDisabled ? styles.RectangleButton_disabled : '',
    className
  ]);

  if (href) {
    return (
      <a
        className={localClassName}
        href={href}
        onClick={onClick}
        data-testid={testIdButton}
      >
        {hasPreloader && (
          <Spinner
            className={styles.RectangleButton__Preloader}
            testId={testIdSpinner}
          />
        )}
        {icon && <span className={styles.RectangleButtonIcon}>{icon}</span>}
        <span className={styles.RectangleButton__Label}>{label}</span>
      </a>
    );
  }

  return (
    <button
      type={type}
      className={localClassName}
      onClick={onClick}
      disabled={isDisabled || hasPreloader}
      data-testid={testIdButton}
    >
      {hasPreloader && (
        <Spinner
          className={styles.RectangleButton__Preloader}
          testId={testIdSpinner}
        />
      )}
      {icon && <span className={styles.RectangleButtonIcon}>{icon}</span>}
      <div className={styles.RectangleButton__Label}>{label}</div>
    </button>
  );
};
