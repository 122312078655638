import React from 'react';

import styles from './ApplicationsTable.module.scss';
import { multiplyClassName } from 'utils/className';

type TProps = {
  children: React.ReactNode;
  testId: string;
  title: string;
  titleTestId: string;
  fallbackText?: string;
  groupNew?: boolean;
  fallbackTestId: string;
};

export const ApplicationsTableGroup = ({
  children,
  title,
  titleTestId,
  fallbackText,
  fallbackTestId,
  groupNew,
  testId
}: TProps) => (
  <div
    className={multiplyClassName([
      groupNew ? styles.ApplicationsTable__GroupNew : '',
      styles.ApplicationsTable__Group
    ])}
    data-testid={testId}
  >
    <div
      className={styles.ApplicationsTable__GroupTitle}
      data-testid={titleTestId}
    >
      {title}
    </div>
    {children ? (
      <div className={styles.ApplicationsTable__GroupContent}>{children}</div>
    ) : (
      <div
        className={styles.ApplicationsTable__GroupFallback}
        data-testid={fallbackTestId}
      >
        {fallbackText}
      </div>
    )}
  </div>
);
