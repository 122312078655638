import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { useServices } from 'hooks/useServices';
import { normalizeLogRecordToLogItem } from 'containers/CompetitionParticipantLog/utils/normalizeLogRecordToLogItem';
import { ModalPortal } from 'components/shared/Modal';
import { CompetitionParticipantLogContent } from 'containers/CompetitionParticipantLog/styled/CompetitionParticipantLogContent';
import { CompetitionParticipantLogItem } from 'containers/CompetitionParticipantLog/styled/CompetitionParticipantLogItem';
import { useAsyncController } from 'hooks/useAsyncController';
import { TCompetitionParticipantLogRecord } from 'types/state';
import { TGetCompetitionParticipantLogParams } from 'types/services';

type TProps = {
  competitionId: number;
  participantId: number;
  onClose(): void;
};

export const CompetitionParticipantLog = (props: TProps) => {
  const { competitionId, participantId, onClose } = props;

  const intl = useIntlContext();

  const { getCompetitionParticipantLog } = useServices();

  const {
    wrapAsync: wrapLogPulling,
    asyncProcess: logPullingProcess
  } = useAsyncController<TCompetitionParticipantLogRecord[]>();

  const getLog = React.useCallback(
    (params: TGetCompetitionParticipantLogParams) => {
      const promise = getCompetitionParticipantLog(params);

      wrapLogPulling(promise);
    },
    [getCompetitionParticipantLog, wrapLogPulling]
  );

  React.useEffect(() => {
    getLog({ competitionId, participantId });
  }, [getLog, competitionId, participantId]);

  const items = React.useMemo(() => {
    if (logPullingProcess.status === 'RESOLVED') {
      const { value } = logPullingProcess;

      return value.map(normalizeLogRecordToLogItem(intl));
    }
    return null;
  }, [intl, logPullingProcess]);

  const logItemsNode = React.useMemo(
    () =>
      items && items.map(item => <CompetitionParticipantLogItem {...item} />),
    [items]
  );

  return (
    <ModalPortal toRight>
      {logItemsNode && (
        <CompetitionParticipantLogContent onClose={onClose}>
          {logItemsNode}
        </CompetitionParticipantLogContent>
      )}
    </ModalPortal>
  );
};
