import { useUserRoleStrict } from 'hooks/useUserRole';
import { Section } from 'types/state';
import { UnreachableError } from 'utils/errors';

const adminSections: Section[] = [
  'applicationsRestaurant',
  'chatsRestaurant',
  'bookingRestaurant',
  'applicationsSport',
  'chatsSport',
  'bookingClub',
  'news',
  'competitions',
  'feedback',
  'members',
  'users'
];

const managerSections: Section[] = [
  'applicationsRestaurant',
  'chatsRestaurant',
  'bookingRestaurant',
  'applicationsSport',
  'chatsSport',
  'bookingClub',
  'news',
  'competitions',
  'feedback',
  'members',
  'users'
];

const curatorSections: Section[] = [
  'applicationsRestaurant',
  'chatsRestaurant',
  'bookingRestaurant',
  'applicationsSport',
  'chatsSport',
  'bookingClub',
  'competitions',
  'members',
  'users'
];

const securitySections: Section[] = [];

export const useAvailableSections = () => {
  const role = useUserRoleStrict();

  switch (role) {
    case 'ADMINISTRATOR':
      return adminSections;
    case 'MANAGER':
      return managerSections;
    case 'CURATOR':
      return curatorSections;
    case 'SECURITY':
      return securitySections;
    default:
      throw new UnreachableError(role);
  }
};
