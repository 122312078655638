import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import { getSportingServiceURL } from 'services/sporting/utils/getSportingURL';
import {
  SportingSecureParams,
  TSportingCompetitionsArticle
} from 'services/sporting/types';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  body: FormData;
};

export const addCompetition = async (
  params: SportingSecureParams<Payload>
): Promise<TSportingCompetitionsArticle> => {
  const {
    payload: { body }
  } = params;

  const headers = createSportingHeaders();

  const endpointURL = getSportingServiceURL('addCompetition');

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body,
    headers
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new UnexpectedError();
  }
};
