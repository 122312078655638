import React from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.css';

type TProps = {
  children: React.ReactNode;
  toRight?: boolean;
  noPadding?: boolean;
};

const ROOT_ID = 'modal-root';

export const ModalRoot = () => {
  return <div id={ROOT_ID} />;
};

export const ModalPortal = (props: TProps) => {
  const { children, toRight, noPadding } = props;

  const modalRoot = document.getElementById(ROOT_ID);

  if (modalRoot) {
    return ReactDOM.createPortal(
      <div
        className={`${styles.Modal__Overlay} ${(toRight
          ? styles.Modal__Overlay_toRight
          : '',
        noPadding ? styles.Modal__Overlay_noPadding : '')}`}
      >
        {children}
      </div>,
      modalRoot
    );
  }
  return null;
};
