import React from 'react';
import './DateLineItem.scss';
import { useIntlContext } from '../../hooks/useIntlContext';
import { multiplyClassName } from '../../utils/className';
import { Text } from 'design/text';

type Props = {
  date: Date;
  onSelect(date: Date): void;
  isActive(): boolean;
};
export const DateLineItem = (props: Props) => {
  const { isActive, date } = props;

  const active = isActive();

  const { shortDayNames, predicativeShortMonthNames } = useIntlContext();

  const className = multiplyClassName([
    'date-line-item',
    active ? 'active' : ''
  ]);

  return (
    <div className={className} onClick={() => props.onSelect(date)}>
      <div className={'date-line-item__day-of-week'}>
        <Text color="primary" size="s" bold={active}>
          {dayOfWeek(date, shortDayNames)}
        </Text>
      </div>
      <div className={'date-line-item__day'}>
        <Text color={active ? 'primary' : 'secondary'} size="xs">
          {date.getDate() + ' ' + predicativeShortMonthNames[date.getMonth()]}
        </Text>
      </div>
    </div>
  );
};

const dayOfWeek = (date: Date, shortDayNames: string[]) => {
  let day = shortDayNames[date.getDay()];
  return day.replace(day[0], day[0].toUpperCase());
};
