import { sportingAPI } from '../api';
import { CreateEmployeeParams } from 'types/services';
import { UserInfo } from 'types/state';
import { normalizeSportingUserRole } from '../utils/normalizeSportingUserRole';
import { normalizeSportingUserInfo } from '../utils/normalizeSportingUserInfo';

export const createEmployee = async (
  params: CreateEmployeeParams
): Promise<UserInfo> => {
  const { role, phoneNumber, firstName, lastName, login, password } = params;

  const data = await sportingAPI.service.createEmployee({
    payload: {
      role: normalizeSportingUserRole.toSportingUserRole(role),
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      username: login,
      password: password
    }
  });

  return normalizeSportingUserInfo.toUserInfo(data);
};
