import {
  SportingPlaceDestinationType,
  SportingSecureParams
} from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { appendHeadersWithJSON } from '../../../../utils/headers';
import { customFetch } from '../../../../utils/customFetch';
import { TimeConflictError, UnexpectedError } from '../../../../utils/errors';
import { TimeLineDay } from '../../../../types/state';

type TimelinePayload = {
  location: SportingPlaceDestinationType;
  forAllWeek: boolean;
  days: TimeLineDay[];
};

export const submitTimeline = async (
  params: SportingSecureParams<TimelinePayload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingBookingURL('submitTimeline');

  const headers = createSportingHeaders();
  appendHeadersWithJSON(headers);

  const body = JSON.stringify(payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body,
    headers
  });

  if (!response.ok) {
    if (response.status === 400) {
      const responseBody = await response.json();

      if (responseBody.message === 'Day has conflict oncoming reservation') {
        throw new TimeConflictError();
      }
    }
    throw new UnexpectedError();
  }
};
