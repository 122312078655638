import React from 'react';

import styles from './ReviewPreview.module.css';
import { testId } from 'utils/testId';

type TProps = {
  text: React.ReactNode;
};

export const ReviewPreviewTitle = ({ text }: TProps) => (
  <div
    className={styles.ReviewPreview__Title}
    data-testid={testId.REVIEW_PREVIEW_TITLE}
  >
    {text}
  </div>
);
