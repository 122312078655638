import { AppDispatch, SheduleInfo } from 'types/state';

export const updateSheduleInfoServiceThunk = (
  promise: Promise<SheduleInfo>
) => (dispatch: AppDispatch): Promise<void> => {
  dispatch({
    name: 'UpdateSheduleInfoService',
    status: 'PENDING'
  });

  return promise.then(sheduleInfo => {
    dispatch({
      name: 'UpdateSheduleInfoService',
      status: 'RESOLVED',
      payload: sheduleInfo
    });
  });
};
