import React from 'react';

import './chatPreviewLayout.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  title: React.ReactNode;
  date: React.ReactNode;
  message: React.ReactNode;
  bage: React.ReactNode;
  control: React.ReactNode;
  showControlOnHoverOnly?: boolean;
  onClick?(): void;
};

export const ChatPreviewLayout = (props: Props) => {
  const {
    message,
    title,
    date,
    bage,
    control,
    showControlOnHoverOnly,
    onClick
  } = props;

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const bem = createBEMConstructor('chatPreviewLayout');

  const wrapperClassName = bem('wrapper');

  const buttonClassName = bem('button');

  const rowClassName = bem('row');

  const dateSlotClassName = bem('dateSlot');

  const messageSlotClassName = bem('messageSlot');

  const titleSlotClassName = bem('titleSlot');

  const controlSlotClassName = bem('controlSlot', {
    onHoverOnly: showControlOnHoverOnly
  });

  const bageSlotClassName = bem('bageSlot');

  return (
    <div className={wrapperClassName}>
      <button className={buttonClassName} onClick={handleClick}>
        <div className={rowClassName}>
          <div className={titleSlotClassName}>{title}</div>
          <div className={dateSlotClassName}>{date}</div>
        </div>
        <div className={messageSlotClassName}>{message}</div>
        <div className={bageSlotClassName}>{bage}</div>
      </button>
      <div className={controlSlotClassName}>{control}</div>
    </div>
  );
};
