import React from 'react';

import styles from './SignInPage.module.css';

import { RectangleButton } from 'components/shared/RectangleButton';

type TProps = {
  testIdButton: string;
  testIdSpinner: string;
  hasPreloader: boolean;
  isDisabled: boolean;
  label: string;
  onClick(): void;
};

export const SignInPageSubmitButton = (props: TProps) => {
  return (
    <RectangleButton
      {...props}
      styleType={'primary'}
      className={styles.SignInPage__SubmitButton}
    />
  );
};
