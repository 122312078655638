import React from 'react';

import styles from './styled/CustomSelect.module.scss';
import { useToggler } from 'hooks/useToggler';
import { CustomSelectOption } from 'containers/CustomSelect/styled/CustomSelectOption';
import { CustomSelectButton } from 'containers/CustomSelect/styled/CustomSelectButton';
import { CustomSelectDropDown } from 'containers/CustomSelect/styled/CustomSelectDropDown';
import { SelectOption } from 'types/util';
import { multiplyClassName } from '../../utils/className';
import { PopupGroupLayoutComponent } from 'design/popupGroupLayout/popupGroupLayoutComponents';

export type CustomSelectControl = {
  toggle(): void;
};

type Props<ValueType extends string> = {
  selected: ValueType;
  options: SelectOption<ValueType>[];
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  popupUp?: boolean;
  formFieldBordered?: boolean;
  triggerTestId?: string;
  popupTestId?: string;
  invalid?: boolean;
  onChange(nextValue: ValueType): void;
};

export type CustomSelectProps<ValueType extends string> = Props<ValueType>;

export const CustomSelect = React.forwardRef<
  null | CustomSelectControl,
  Props<string>
>((props, ref) => {
  const {
    selected,
    placeholder,
    disabled,
    popupUp,
    formFieldBordered,
    triggerTestId,
    popupTestId,
    className,
    options: rawOptions,
    invalid,
    onChange
  } = props;

  const [opened, toggleOpened] = useToggler(false);

  React.useEffect(() => {
    if (ref !== null && typeof ref !== 'function') {
      // @ts-ignore
      ref.current = {
        toggle: toggleOpened
      };
    }
  }, [ref, toggleOpened]);

  React.useEffect(() => {
    if (disabled && opened) {
      toggleOpened();
    }
  }, [disabled, opened, toggleOpened]);

  const handleButtonClick = React.useCallback(toggleOpened, [toggleOpened]);

  const handleOutsideClick = React.useCallback(() => {
    if (opened) {
      toggleOpened();
    }
  }, [opened, toggleOpened]);

  const handleSelect = React.useCallback(
    (value: string) => {
      toggleOpened();
      onChange(value);
    },
    [onChange, toggleOpened]
  );

  const selectedOption = rawOptions.find(({ value }) => value === selected);

  if (!selectedOption && !placeholder) {
    throw new TypeError();
  }

  const buttonLabel = selectedOption
    ? selectedOption.label
    : placeholder
    ? placeholder
    : '';

  const options = React.useMemo(() => {
    return rawOptions.map(({ value, label, testId }) => ({
      label,
      value,
      testId,
      selected: value === selected,
      onClick() {
        handleSelect(value);
      }
    }));
  }, [rawOptions, handleSelect, selected]);

  const optionsNode = React.useMemo(
    () =>
      options.map(option => {
        return <CustomSelectOption key={option.value} {...option} />;
      }),
    [options]
  );

  const multiClassName = multiplyClassName([
    formFieldBordered ? styles.CustomSelect__FormFieldBordered : '',
    className ? className : ''
  ]);

  return (
    <PopupGroupLayoutComponent
      up={popupUp}
      onOutsideClick={handleOutsideClick}
      trigger={
        <CustomSelectButton
          className={multiClassName}
          label={buttonLabel}
          invalid={invalid}
          opened={opened}
          disabled={disabled}
          onClick={handleButtonClick}
          testId={triggerTestId}
        />
      }
      popup={
        opened && (
          <CustomSelectDropDown
            popupUp={popupUp}
            withoutArrow={formFieldBordered}
            testId={popupTestId}
          >
            {optionsNode}
          </CustomSelectDropDown>
        )
      }
    />
  );
});
