import React from 'react';

import { useToggler } from 'hooks/useToggler';
import { TNewsArticle } from 'types/state';

type TProps = {
  article: TNewsArticle;
  hasEditView: boolean;
  onChange: (nextData: Partial<TNewsArticle>) => void;
  onDeleted: () => void;
  onSumbited: (id: number, asDraft: boolean) => void;
  onEdit: () => void;
  onCancelEdit: () => void;
};

export type TNewsPreviewProps = TProps;

export const useNewsPreviewState = (props: TProps) => {
  const {
    article,
    hasEditView,
    onChange,
    onSumbited,
    onDeleted,
    onEdit,
    onCancelEdit
  } = props;

  const [hasDeleteView, toggleHasDeleteView] = useToggler(false);

  // Normalization

  const handleChange = onChange;

  const handleDelete = toggleHasDeleteView;

  const handleCancelDelete = toggleHasDeleteView;

  const handleDeleted = React.useCallback(() => {
    onDeleted();
    toggleHasDeleteView();
  }, [onDeleted, toggleHasDeleteView]);

  const handleCancelEdit = onCancelEdit;

  const handleEdit = onEdit;

  const handleSubmited = onSumbited;

  return {
    hasEditView,
    hasDeleteView,
    article,
    handleEdit,
    handleCancelEdit,
    handleDelete,
    handleCancelDelete,
    handleDeleted,
    handleChange,
    handleSubmited
  };
};
