import React from 'react';

import { TNewsArticle } from 'types/state';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';
import { getRawNewsArticle } from 'utils/getRawNewsArticle';

export const useNewsArticle = () => {
  // Состояние хранит объект выбранной статьи.
  const [selectedArticleId, setSelectedArticleId] = React.useState<
    null | number
  >(null);

  // Состояние хранит объект редактируемой статьи.
  const [editedArticle, setEditedArticle] = React.useState<null | TNewsArticle>(
    null
  );

  // Метод позволяет вносить точечные изменения при
  // редактировании статьи.
  const patchEditedArticle = React.useCallback(
    (nextData: Partial<TNewsArticle>) => {
      setEditedArticle(prevData => {
        if (prevData) {
          return {
            ...prevData,
            ...nextData
          };
        }
        // Не предполагается, что метод можно будет вызвать,
        // когда предыдущее состояние === null.
        throw new TypeError();
      });
    },
    []
  );

  // Метод для установки или сбрасывания id выбранной статьи.
  // Если id выбранной статьи является id статьи-кандидата
  // то в состояние редактируемой статьи записывается шаблон
  // статьи для создания.
  // Кроме того, в случае редактирования cуществующей статьи
  // сбрасывается состояние редактируемой статьи.
  const selectArticleById = React.useCallback((id: number | null) => {
    setSelectedArticleId(id);
    if (id === NEWS_ARTICLE_CANDIDATE_ID) {
      setEditedArticle(getRawNewsArticle());
    } else {
      setEditedArticle(null);
    }
  }, []);

  return {
    editedArticle,
    setEditedArticle,
    patchEditedArticle,
    selectArticleById,
    selectedArticleId
  };
};
