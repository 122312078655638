import React from 'react';

import { Text } from 'design/text';
import { BookingTimelineReservation } from 'types/bookingTimeline';
import { differenceInMinutes, format, isBefore } from 'date-fns';
import { BookingTimelineReservationLayout } from 'design/bookingTimelineReservationLayout/bookingTimelineReservationLayout';
import { BookingTimelineReservationPopupLayout } from 'design/bookingTimelineReservationPopupLayout/bookingTimelineReservationPopupLayout';
import { useNowISODate } from 'hooks/useNowISODate';
import { CalendarPeriod, PlaceItem } from 'types/state';
import { DATE_FNS__24H_TIME_FORMAT } from 'utils/constants';
import { useIntlContext } from 'hooks/useIntlContext';

type Props = {
  workingHours: CalendarPeriod;
  reservation: BookingTimelineReservation;
  placeItems: PlaceItem[];
  onReservationClick(id: number): void;
};

export const BookingTimelineReservationComponent = (props: Props) => {
  const {
    workingHours: {
      startDateISO: workingStartDateISO,
      endDateISO: workingEndDateISO
    },
    reservation: {
      id: reservationId,
      name,
      participantsCount,
      period: { startDateISO, endDateISO },
      comment,
      itemIds
    },
    placeItems,
    onReservationClick
  } = props;

  const localItemIds = React.useMemo(() => {
    const placeItemIds = placeItems.map(placeItem => placeItem.id);

    return itemIds.filter(currentId => placeItemIds.includes(currentId));
  }, [itemIds, placeItems]);

  const { bookingTimelineReservation: ownDictionary } = useIntlContext();

  const nowISODate = useNowISODate();

  const started = React.useMemo(() => {
    return isBefore(new Date(startDateISO), new Date(nowISODate));
  }, [nowISODate, startDateISO]);

  const ended = React.useMemo(() => {
    return isBefore(new Date(endDateISO), new Date(nowISODate));
  }, [endDateISO, nowISODate]);

  const timeText = React.useMemo(() => {
    return format(new Date(startDateISO), DATE_FNS__24H_TIME_FORMAT);
  }, [startDateISO]);

  const workingHoursMinutePercent = React.useMemo(() => {
    return (
      differenceInMinutes(
        new Date(workingEndDateISO),
        new Date(workingStartDateISO)
      ) / 100
    );
  }, [workingEndDateISO, workingStartDateISO]);

  const leftPercent = React.useMemo(() => {
    const startDiff = differenceInMinutes(
      new Date(startDateISO),
      new Date(workingStartDateISO)
    );

    return startDiff / workingHoursMinutePercent;
  }, [startDateISO, workingHoursMinutePercent, workingStartDateISO]);

  const topPercent = React.useMemo(() => {
    const firstSlotIndex = placeItems.findIndex(placeItem =>
      itemIds.includes(placeItem.id)
    );

    if (firstSlotIndex <= 0) {
      return 0;
    }

    const percent = placeItems.length / 100;

    return firstSlotIndex / percent;
  }, [placeItems, itemIds]);

  const widthPercent = React.useMemo(() => {
    const duration = differenceInMinutes(
      new Date(endDateISO),
      new Date(startDateISO)
    );

    return duration / workingHoursMinutePercent;
  }, [endDateISO, startDateISO, workingHoursMinutePercent]);

  const heightPercent = React.useMemo(() => {
    const percent = placeItems.length / 100;

    return localItemIds.length / percent;
  }, [placeItems.length, localItemIds.length]);

  const status = React.useMemo(() => {
    if (name === null) {
      return 'urgent';
    }

    if (started) {
      if (ended) {
        return 'past';
      }

      return 'current';
    } else {
      return 'future';
    }
  }, [ended, name, started]);

  return (
    <BookingTimelineReservationLayout
      id={reservationId}
      leftPercent={leftPercent}
      topPercent={topPercent}
      widthPercent={widthPercent}
      heightPercent={heightPercent}
      status={status}
      comment={comment}
      title={
        <Text color="primary" size="xs" weight="semi-bold">
          {name || ownDictionary.nameFallback}
        </Text>
      }
      participantsCount={
        <Text color="primary" size="xs">
          {participantsCount}
        </Text>
      }
      time={
        <Text color="primary" size="xs">
          {timeText}
        </Text>
      }
      onClick={() => onReservationClick(reservationId)}
      popup={
        <BookingTimelineReservationPopupLayout
          title={
            <Text color="primary" size="xs" weight="semi-bold">
              {name || ownDictionary.nameFallback}
            </Text>
          }
          participantsCount={
            <Text color="primary" size="xs">
              {participantsCount}
            </Text>
          }
          time={
            <Text color="primary" size="xs">
              {timeText}
            </Text>
          }
        />
      }
    />
  );
};
