import { SportingSecureParams, SportingWaiter } from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';
import { appendFormDataWithObjectValues } from '../../../../utils/appendFormDataWithObjectValues';

type Payload = {
  fio: string[];
};

export const addWaiters = async (
  params: SportingSecureParams<Payload>
): Promise<SportingWaiter[]> => {
  const { payload } = params;

  const endpointURL = getSportingBookingURL('addWaiters');

  const headers = createSportingHeaders();

  const body = new FormData();
  appendFormDataWithObjectValues(body, payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body,
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
  return response.json();
};
