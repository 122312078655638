import React from 'react';

import { CompetitionsFeedItemTemplate } from 'containers/CompetitionsFeedItem/view/styled/CompetitionsFeedItemTemplate';

type TProps = {
  hasSelectedView: boolean;
  title: string;
  pretitle: string;
  competitionsFeedItemTitlePlaceholder: string;
  requestsCount?: number;
  handleClick(): void;
};

export const CompetitionsFeedItemView = (props: TProps) => {
  const {
    title,
    competitionsFeedItemTitlePlaceholder,
    pretitle,
    requestsCount,
    handleClick,
    hasSelectedView
  } = props;

  const itemNode = React.useMemo(
    () => (
      <CompetitionsFeedItemTemplate
        title={title}
        titlePlaceholder={competitionsFeedItemTitlePlaceholder}
        pretitle={pretitle}
        count={requestsCount}
        hasSelectedView={hasSelectedView}
        onClick={handleClick}
      />
    ),
    [
      title,
      pretitle,
      requestsCount,
      competitionsFeedItemTitlePlaceholder,
      hasSelectedView,
      handleClick
    ]
  );

  return itemNode;
};
