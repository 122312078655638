import React from 'react';

import { SwitchInput } from 'components/SwitchInput';
import { SectionsFormLabel } from 'containers/SectionsForm/components/SectionsFormLabel';
import { Section } from 'types/state';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSectionsFormInputChecked } from 'hooks/sectionsForm/useSectionsFormInputChecked';
import { useSectionsFormInputLabel } from 'hooks/sectionsForm/useSectionsFormInputLabel';

type Props = {
  section: Section;
};

export const SectionsFormInput = (props: Props) => {
  const { section } = props;

  const dispatch = useAppDispatch();

  const checked = useSectionsFormInputChecked(section);

  const label = useSectionsFormInputLabel(section);

  const handleChange = React.useCallback(() => {
    dispatch({
      name: 'SECTIONS_FORM__INPUT_CHANGE',
      enabled: !checked,
      section
    });
  }, [checked, dispatch, section]);

  return (
    <SectionsFormLabel text={label}>
      <SwitchInput
        name="sections"
        checked={checked}
        value={section}
        onChange={handleChange}
      />
    </SectionsFormLabel>
  );
};
