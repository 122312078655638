import { useAppState } from 'hooks/useAppState';
import { LoginFormError, LoginFormValues } from 'types/state';
import { useIntlContext } from '../useIntlContext';

export const useLoginEditorState = () => {
  const { loginEditorState } = useAppState();

  return loginEditorState;
};

export const useLoginEditorFormValues = (): LoginFormValues => {
  const { formValues } = useLoginEditorState();

  return formValues;
};

export const useLoginEditorFormError = (): undefined | LoginFormError => {
  const { formError } = useLoginEditorState();

  return formError;
};

export const useLoginEditorFormErrorTextForLogin = (): string | undefined => {
  const formError = useLoginEditorFormError();

  const { loginEditorForm, unexpectedError } = useIntlContext();

  switch (formError) {
    case 'LOGIN_IS_EMPTY':
      return loginEditorForm.loginEmptyError;
    case 'LOGIN_IN_USE':
      return loginEditorForm.loginInUseError;
    case 'SERVICE_FAILED':
      return unexpectedError;
    default:
      return undefined;
  }
};

export const useLoginEditorFormErrorTextForPassword = ():
  | string
  | undefined => {
  const formError = useLoginEditorFormError();

  const { loginEditorForm, unexpectedError } = useIntlContext();

  switch (formError) {
    case 'PASSWORD_IS_EMPTY':
      return loginEditorForm.passwordEmptyError;
    case 'PASSWORD_INVALID':
      return loginEditorForm.passwordInvalidError;
    case 'SERVICE_FAILED':
      return unexpectedError;
    default:
      return undefined;
  }
};

export const useLoginFormBusy = (): boolean => {
  const { submited, submitDidSucceed } = useLoginEditorState();

  return submited && !submitDidSucceed;
};

export const useLoginFormSubmitDidSucceed = () => {
  const { submitDidSucceed } = useLoginEditorState();

  return submitDidSucceed;
};
