import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError, NotFoundError } from 'utils/errors';
import { TGetCompetitionParticipantLogParams } from 'types/services';
import { TCompetitionParticipantLogRecord } from 'types/state';
import { getUid } from 'utils/getUid';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const GET_COMPETITION_PARTICIPANT_LOG_MOCK_ID__CONNECTION_ERROR = -1;

const logMock: TCompetitionParticipantLogRecord[] = [
  {
    id: getUid(),
    date: new Date().toISOString(),
    status: 'CANCELATION_CONFIRMED',
    changedBy: 'Username 1'
  },
  {
    id: getUid(),
    date: new Date().toISOString(),
    status: 'CANCELATION_REQUESTED',
    changedBy: 'Username 2'
  },
  {
    id: getUid(),
    date: new Date().toISOString(),
    status: 'PARTICIPATION_CONFIRMED',
    changedBy: 'Username 3'
  },
  {
    id: getUid(),
    date: new Date().toISOString(),
    status: 'PARTICIPATION_REQUESTED',
    changedBy: 'Username 4'
  }
];

export const getCompetitionParticipantLog = async (
  params: TGetCompetitionParticipantLogParams
): Promise<TCompetitionParticipantLogRecord[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (
    params.competitionId ===
    GET_COMPETITION_PARTICIPANT_LOG_MOCK_ID__CONNECTION_ERROR
  ) {
    throw new HTTPConnectionError();
  }
  const competition = competitionsStoreMock
    .getAllArticles()
    .find(({ id }) => id === params.competitionId);
  if (!competition) {
    throw new NotFoundError();
  } else {
    return logMock;
  }
};
