import { AppEvent } from 'types/appEvent';
import { Cache } from 'types/util';
import { patchCache } from 'utils/patchCache';

export const newReviewsCountReducer = (
  state: Cache<number>,
  event: AppEvent
): Cache<number> => {
  switch (event.name) {
    case 'BADGE_UPDATE_REQUESTED': {
      if (event.section === 'feedback') {
        return patchCache(state)
          .invalidate()
          .done();
      }

      return state;
    }
    case 'NEW_REVIEWS_COUNT_SERVICE': {
      if (event.status === 'RESOLVED') {
        return patchCache(state)
          .setValue(event.payload)
          .done();
      }

      return state;
    }
    default:
      return state;
  }
};
