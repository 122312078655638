import { SetChatRoomReadStatusParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { chatRoomsStoreMock } from 'services/mock/utils/chatRoomsModel';
import { ChatRoom } from 'types/chats';

export const setChatRoomReadStatus = async (
  params: SetChatRoomReadStatusParams
) => {
  const { roomId, nextValue } = params;

  await asyncTimeout(SERVICES_MOCK_DELAY);

  const room = chatRoomsStoreMock.getRoomById(roomId);

  if (room) {
    const nextReadStatus = nextValue;

    const nextRoom: ChatRoom = {
      ...room,
      read: nextReadStatus,
      unreadMessagesCount: nextReadStatus ? 0 : room.unreadMessagesCount
    };

    chatRoomsStoreMock.replaceRoomById(roomId, nextRoom);
  }
};
