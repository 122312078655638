import React from 'react';

import styles from './ReviewPreview.module.css';
import { testId } from 'utils/testId';

type TProps = {
  children: React.ReactNode;
};

export const ReviewPreviewWrapper = ({ children }: TProps) => (
  <div
    className={styles.ReviewPreview__Scrollable}
    data-testid={testId.REVIEW_PREVIEW}
  >
    <div className={styles.ReviewPreview__Content}>{children}</div>
  </div>
);
