import React from 'react';

import './messageTemplatesListItemLayout.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  primaryAction: React.ReactNode;
  message: React.ReactNode;
  secondaryAction: React.ReactNode;
};

export const MessageTemplatesListItemLayout = (props: Props) => {
  const { primaryAction, message, secondaryAction } = props;

  const bem = createBEMConstructor('messageTemplatesListItemLayout');

  const wrapperClassName = bem('wrapper');

  const primaryActionSlotClassName = bem('primaryAction');

  const messageSlotClassName = bem('messageSlot');

  const secondaryActionSlotClassName = bem('secondaryAction');

  return (
    <div className={wrapperClassName}>
      <div className={primaryActionSlotClassName}>{primaryAction}</div>
      <div className={messageSlotClassName}>{message}</div>
      <div className={secondaryActionSlotClassName}>{secondaryAction}</div>
    </div>
  );
};
