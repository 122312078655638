import { AppEvent } from 'types/appEvent';

export const userToDeleteIdReducer = (
  state: null | number,
  event: AppEvent
): null | number => {
  switch (event.name) {
    case 'USER_FORM__DELETE': {
      return event.userId;
    }
    case 'DELETE_USER_MODAL__CANCEL': {
      return null;
    }
    case 'DELETE_USER_SERVICE': {
      if (event.status === 'PENDING') {
        return state;
      }

      return null;
    }
    default:
      return state;
  }
};
