import { LoginEditorState, LoginFormValues } from 'types/state';

export const createLoginEditorState = (): LoginEditorState => ({
  formValues: createLoginFormValues(),
  submited: false,
  submitDidSucceed: false
});

export const createLoginFormValues = (): LoginFormValues => ({
  login: '',
  password: ''
});
