import React from 'react';
import { SelectOption } from '../types/util';
import { useWaitersCache } from './booking/useWaitersCache';

export const useWaitersSelectOptions = () => {
  const waitersCache = useWaitersCache();

  return React.useMemo<SelectOption<string>[]>(() => {
    const waitersOptions = [];
    const waiters = Array.from(waitersCache.value.values());
    if (waiters) {
      const options = waiters.map(waiter => ({
        value: waiter.fio,
        label: waiter.fio
      }));
      waitersOptions.push(...options);
    }
    return waitersOptions;
  }, [waitersCache.value]);
};
