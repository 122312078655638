import React from 'react';

import { TReviewStatus, TReviewStatusSelectOption } from 'types/state';
import { testId } from 'utils/testId';
import { CustomSelect } from 'containers/CustomSelect';
import { ReviewPreviewWrapper } from 'containers/ReviewPreview/styled/ReviewPreviewWrapper';
import { ReviewPreviewStatus } from 'containers/ReviewPreview/styled/ReviewPreviewStatus';
import { ReviewPreviewTitle } from 'containers/ReviewPreview/styled/ReviewPreviewTitle';
import { ReviewPreviewInfoTable } from 'containers/ReviewPreview/styled/ReviewPreviewInfoTable';
import { ReviewPreviewInfoRow } from 'containers/ReviewPreview/styled/ReviewPreviewInfoRow';
import { ReviewPreviewMessage } from 'containers/ReviewPreview/styled/ReviewPreviewMessage';

type TProps = {
  title: string;
  date: string;
  dateLabel: string;
  orderNumber?: string;
  orderNumberLabel: string;
  name?: string;
  nameLabel: string;
  email?: string;
  emailLabel: string;
  phone?: string;
  phoneLabel: string;
  foodScore?: string;
  foodScoreLabel: string;
  serviceScore?: string;
  serviceScoreLabel: string;
  comment?: string;
  commentLabel: string;
  hasAccentStatusTheme: boolean;
  hasSecondaryStatusTheme: boolean;
  selectOptions: TReviewStatusSelectOption[];
  selectedValue: TReviewStatus;
  onSelectChange(nextValue: TReviewStatus): void;
};

export type TReviewPreviewViewProps = TProps;

export const ReviewPreviewView = (props: TProps) => {
  const {
    title,
    date,
    dateLabel,
    orderNumber,
    orderNumberLabel,
    name,
    nameLabel,
    email,
    emailLabel,
    phone,
    phoneLabel,
    foodScore,
    foodScoreLabel,
    serviceScore,
    serviceScoreLabel,
    comment,
    commentLabel,
    selectOptions,
    selectedValue,
    hasAccentStatusTheme,
    hasSecondaryStatusTheme,
    onSelectChange
  } = props;

  return (
    <ReviewPreviewWrapper>
      <ReviewPreviewStatus
        hasAccentTheme={hasAccentStatusTheme}
        hasSecondaryTheme={hasSecondaryStatusTheme}
      >
        <CustomSelect
          selected={selectedValue}
          options={selectOptions}
          onChange={onSelectChange}
          triggerTestId={testId.REVIEW_PREVIEW_SELECT}
        />
      </ReviewPreviewStatus>
      <ReviewPreviewTitle text={title} />
      <ReviewPreviewInfoTable>
        <ReviewPreviewInfoRow
          label={dateLabel}
          text={date}
          testId={testId.REVIEW_PREVIEW_DATE}
        />
        {orderNumber && (
          <ReviewPreviewInfoRow
            label={orderNumberLabel}
            text={orderNumber}
            testId={testId.REVIEW_PREVIEW_ORDER_NUMBER}
          />
        )}
        {name && (
          <ReviewPreviewInfoRow
            label={nameLabel}
            text={name}
            testId={testId.REVIEW_PREVIEW_NAME}
          />
        )}
        {phone && (
          <ReviewPreviewInfoRow
            label={phoneLabel}
            text={phone}
            testId={testId.REVIEW_PREVIEW_PHONE}
          />
        )}
        {email && (
          <ReviewPreviewInfoRow
            label={emailLabel}
            text={email}
            testId={testId.REVIEW_PREVIEW_EMAIL}
          />
        )}
        {foodScore && (
          <ReviewPreviewInfoRow
            label={foodScoreLabel}
            text={foodScore}
            testId={testId.REVIEW_PREVIEW_FOOD_SCORE}
          />
        )}
        {serviceScore && (
          <ReviewPreviewInfoRow
            label={serviceScoreLabel}
            text={serviceScore}
            testId={testId.REVIEW_PREVIEW_SERVICE_SCORE}
          />
        )}
      </ReviewPreviewInfoTable>
      {comment && <ReviewPreviewMessage label={commentLabel} text={comment} />}
    </ReviewPreviewWrapper>
  );
};
