import React from 'react';

import styles from './clientSearchResultsItemLayoutStyles.module.scss';
import { multiplyClassName } from 'utils/className';

type Props = {
  cardNumber: React.ReactNode;
  phoneNumber: React.ReactNode;
  fullName: React.ReactNode;
  blacklisted: boolean;
};

export const ClientSearchResultsItemLayoutComponent = (props: Props) => {
  const { cardNumber, phoneNumber, fullName, blacklisted } = props;

  const wrapperClassName = multiplyClassName([
    styles.wrapper,
    blacklisted ? styles.blacklisted : ''
  ]);

  return (
    <div className={wrapperClassName}>
      <div className={styles.cardNumber}>{cardNumber}</div>
      <div className={styles.phoneNumber}>{phoneNumber}</div>
      <div className={styles.name}>{fullName}</div>
    </div>
  );
};
