import React from 'react';

import styles from './NewsArticle.module.css';

type TProps = {
  testId?: string;
  children: React.ReactNode;
};

export const NewsArticleContent = ({ children, testId }: TProps) => (
  <div className={styles.NewsArticle__Content} data-testid={testId}>
    {children}
  </div>
);
