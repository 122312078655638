import React from 'react';

import styles from './CompetitionParticipants.module.css';

type TProps = {
  children: React.ReactNode;
};

export const CompetitionParticipantsBody = ({ children }: TProps) => {
  return (
    <tbody className={styles.CompetitionParticipants__Body}>{children}</tbody>
  );
};
