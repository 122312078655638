import React from 'react';

import styles from './CompetitionsArticle.module.css';

type TProps = {
  label: string;
};

export const CompetitionsArticleDuration = ({ label }: TProps) => {
  return <div className={styles.CompetitionsArticle__Duration}>{label}</div>;
};
