import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { SettingsBlockWrapper } from 'components/SettingsBlock/SettingsBlockWrapper';
import { SettingsBlockContent } from 'components/SettingsBlock/SettingsBlockContent';
import { SettingsBlockTitle } from 'components/SettingsBlock/SettingsBlockTitle';
import { SettingsBlockDescription } from 'components/SettingsBlock/SettingsBlockDescription';
import { PasswordForm } from 'containers/PasswordForm';

export const PasswordEditor = () => {
  const { passwordEditor: passwordEditorIntl } = useIntlContext();

  return (
    <SettingsBlockWrapper>
      <SettingsBlockContent position="left">
        <SettingsBlockTitle text={passwordEditorIntl.title} />
        <SettingsBlockDescription text={passwordEditorIntl.description} />
      </SettingsBlockContent>
      <SettingsBlockContent position="left">
        <PasswordForm />
      </SettingsBlockContent>
    </SettingsBlockWrapper>
  );
};
