import { asyncTimeout } from 'utils/asyncTimeout';
import {
  SERVICES_MOCK_DELAY,
  NEWS_ARTICLE_CANDIDATE_ID
} from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TSubmitNewsArticleParams } from 'types/services';
import { TNewsArticle } from 'types/state';
import { newsStoreMock } from 'services/mock/utils/newsModel';

export const SUBMIT_NEWS_ARTICLE_ID_MOCK__CONNECTION_ERROR = -2;

export const submitNewsArticle = async (
  params: TSubmitNewsArticleParams
): Promise<TNewsArticle> => {
  const { data, saveAsDraft } = params;
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (data.id === SUBMIT_NEWS_ARTICLE_ID_MOCK__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }
  const saveOrUpdate =
    data.id === NEWS_ARTICLE_CANDIDATE_ID
      ? newsStoreMock.saveArticle
      : newsStoreMock.updateArticle;
  if (saveAsDraft) {
    return saveOrUpdate({
      ...data,
      isDraft: true
    });
  } else {
    return saveOrUpdate({
      ...params.data,
      publishDate: new Date().toISOString(),
      isDraft: false
    });
  }
};
