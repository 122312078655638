import React from 'react';

import { useServices } from 'hooks/useServices';
import { useMessageTemplatesState } from 'hooks/messageTemplates/useMessageTemplatesState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from 'hooks/useAsyncController';

export const useAddMessageTemplateService = () => {
  const { templateCandidateText } = useMessageTemplatesState();

  const services = useServices();

  const { wrapAsync: wrapMessageCreation } = useAsyncController();

  const dispatch = useAppDispatch();

  const trimmedText = templateCandidateText.trim();

  const addMessageTemplate = React.useCallback(() => {
    if (trimmedText) {
      const promise = services
        .addMessageTemplate({
          text: trimmedText
        })
        .then(() => {
          dispatch({
            name: 'MESSAGE_TEMPLATES__TEMPLATE_ADDED'
          });
        });

      wrapMessageCreation(promise);
    }
  }, [dispatch, services, trimmedText, wrapMessageCreation]);

  return addMessageTemplate;
};
