import React from 'react';

import { TCompetitionsArticle } from 'types/state';
import { COMPETITIONS_ARTICLE_CANDIDATE_ID } from 'utils/constants';
import { getRawCompetitionsArticle } from 'utils/getRawCompetitionsArticle';

export const useCompetitionsArticle = () => {
  // Состояние хранит объект выбранной статьи.
  const [selectedArticleId, setSelectedArticleIdRaw] = React.useState<
    null | number
  >(null);

  // Состояние хранит объект редактируемой статьи.
  const [
    editedArticle,
    setEditedArticle
  ] = React.useState<null | TCompetitionsArticle>(null);

  // Метод позволяет вносить точечные изменения при
  // редактировании статьи.
  const patchEditedArticle = React.useCallback(
    (nextData: Partial<TCompetitionsArticle>) => {
      setEditedArticle(prevData => {
        if (prevData) {
          return {
            ...prevData,
            ...nextData
          };
        }
        // Не предполагается, что метод можно будет вызвать,
        // когда предыдущее состояние === null.
        throw new TypeError();
      });
    },
    []
  );

  // Метод для установки или сбрасывания id выбранной статьи.
  // Если id выбранной статьи является id статьи-кандидата
  // то в состояние редактируемой статьи записывается шаблон
  // статьи для создания.
  // Кроме того, в случае редактирования cуществующей статьи
  // сбрасывается состояние редактируемой статьи.
  const selectArticleById = React.useCallback((id: number | null) => {
    setSelectedArticleIdRaw(id);
    if (id === COMPETITIONS_ARTICLE_CANDIDATE_ID) {
      setEditedArticle(getRawCompetitionsArticle());
    } else {
      setEditedArticle(null);
    }
  }, []);

  return {
    editedArticle,
    setEditedArticle,
    patchEditedArticle,
    selectArticleById,
    selectedArticleId
  };
};
