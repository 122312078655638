import React from 'react';

import styles from './GuestScreen.module.css';

type TProps = {
  children: React.ReactNode;
  testId?: string;
};

export const GuestScreenTemplate = ({ testId, ...nativeProps }: TProps) => (
  <div
    {...nativeProps}
    className={styles.GuestScreen__Wrapper}
    data-testid={testId}
  />
);
