import React from 'react';

import { ServerEvent } from 'types/appEvent/serverEvent';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setCustomTimeout } from 'utils/setCustomTimeout';
import { SECOND } from 'utils/constants';

type DelayedEvent = {
  dispatchAt: number;
  event: ServerEvent;
};

const STOPPED_TYPING_EVENT_DELAY = SECOND * 60;

export const useServerEventEffects = () => {
  const dispatch = useAppDispatch();

  const [delayedEvents, setDelayetEvents] = React.useState<
    [string, DelayedEvent][]
  >([]);

  const setDelayedEvent = React.useCallback(
    (id: string, delayedEvent: DelayedEvent) => {
      setDelayetEvents(prev => {
        const map = new Map(prev);

        map.set(id, delayedEvent);

        return Array.from(map);
      });
    },
    []
  );

  const cancelDelayedEvent = React.useCallback((id: string) => {
    setDelayetEvents(prev => {
      const map = new Map(prev);

      map.delete(id);

      return Array.from(map);
    });
  }, []);

  const initEffects = React.useCallback(
    (event: ServerEvent) => {
      switch (event.name) {
        case 'USER_STARTED_TYPING':
        case 'USER_STOPPED_TYPING': {
          const id = `${event.roomId}-${event.userId}`;

          if (event.name === 'USER_STARTED_TYPING') {
            const cancelEvent: ServerEvent = {
              name: 'USER_STOPPED_TYPING',
              userId: event.userId,
              roomId: event.roomId
            };

            setDelayedEvent(id, {
              dispatchAt: STOPPED_TYPING_EVENT_DELAY + Date.now(),
              event: cancelEvent
            });
          }

          if (event.name === 'USER_STOPPED_TYPING') {
            cancelDelayedEvent(id);
          }
        }
      }
    },
    [cancelDelayedEvent, setDelayedEvent]
  );

  React.useEffect(() => {
    const cancellations = delayedEvents.map(([id, { dispatchAt, event }]) => {
      const delay = dispatchAt - Date.now();

      return setCustomTimeout(() => {
        dispatch(event);
        cancelDelayedEvent(id);
      }, delay);
    });

    return () => {
      cancellations.forEach(cancel => cancel());
    };
  });

  return initEffects;
};
