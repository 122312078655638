import React from 'react';

import styles from './ReviewNotes.module.css';

import { testId } from 'utils/testId';
import { REVIEW_NOTES_INPUT_MAX_LENGTH } from 'utils/constants';
import { useToggler } from 'hooks/useToggler';
import { ShadowContainer } from 'components/shared/ShadowContainer';

type TProps = {
  value: string;
  placeholder: string;
  disabled: boolean;
  onChange(nextValue: string): void;
  onBlur(): void;
};

const shadowThreshold = 30;

export const ReviewNotesInput = ({
  value,
  placeholder,
  disabled,
  onChange,
  onBlur
}: TProps) => {
  const [hasScrolledView, toggleHasScrolledView] = useToggler(false);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.currentTarget.value);
    },
    [onChange]
  );

  const handleScroll = React.useCallback(
    (e: React.SyntheticEvent) => {
      const node = e.currentTarget;
      if (
        (node.scrollTop >= shadowThreshold && !hasScrolledView) ||
        (node.scrollTop < shadowThreshold && hasScrolledView)
      ) {
        toggleHasScrolledView();
      }
    },
    [hasScrolledView, toggleHasScrolledView]
  );

  return (
    <ShadowContainer
      hasShadow={hasScrolledView}
      className={styles.ReviewNotes__Content}
    >
      <textarea
        className={styles.ReviewNotes__Input}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        data-testid={testId.REVIEW_NOTES_INPUT}
        maxLength={REVIEW_NOTES_INPUT_MAX_LENGTH}
        onChange={handleChange}
        onBlur={onBlur}
        onScroll={handleScroll}
      />
    </ShadowContainer>
  );
};
