import { TUpdateUserPasswordParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const updateUserPassword = async (
  params: TUpdateUserPasswordParams
): Promise<void> => {
  const { nextPassword, userId } = params;

  await sportingAPI.service.changePasswordForAdmin({
    payload: { newPassword: nextPassword, idUser: userId }
  });
};
