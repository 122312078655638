import React from 'react';

import { FallbackScreenComponent } from 'components/FallbackScreen/FallbackScreenComponents';
import { RectangleButton } from 'components/shared/RectangleButton';
import { Text } from 'design/text';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useIntlContext } from 'hooks/useIntlContext';
import { AuthorizationError } from 'utils/errors';
import { AppAuthorizationFallbackComponent } from './AppAuthorizationFallbackComponent';

type Props = {
  error: Error;
  recover(): void;
};

export const AppFallbackComponent: React.FC<Props> = props => {
  const { recover, error } = props;

  const dispatch = useAppDispatch();

  const { appFallback: ownDictionary } = useIntlContext();

  const handleSignedOut = React.useCallback(() => {
    dispatch({
      name: 'SESSION_TERMINATED'
    });

    recover();
  }, [dispatch, recover]);

  if (error instanceof AuthorizationError) {
    return <AppAuthorizationFallbackComponent onSignedOut={handleSignedOut} />;
  }

  return (
    <FallbackScreenComponent
      title={
        <Text size="l" bold>
          {ownDictionary.titleText}
        </Text>
      }
      details={<Text size="m">{ownDictionary.detailsText(error)}</Text>}
      action={
        <>
          <RectangleButton
            label={ownDictionary.refreshText}
            onClick={recover}
            type="button"
            styleType="primary"
          />
          <RectangleButton
            label={ownDictionary.signOutText}
            onClick={handleSignedOut}
            type="button"
            styleType="secondary"
          />
        </>
      }
    />
  );
};
