import React from 'react';

import { PopupGroupLayoutComponent } from 'design/popupGroupLayout/popupGroupLayoutComponents';
import { BookingIcon } from 'svg/25x25/booking';
import { CustomSelectDropDown } from './CustomSelect/styled/CustomSelectDropDown';
import { CustomSelectOption } from './CustomSelect/styled/CustomSelectOption';
import { ServiceScope } from 'types/serviceScope';
import { useIntlContext } from 'hooks/useIntlContext';
import { ApplicationsTableRoundButton } from 'design/applicationsTableRoundButton/applicationsTableRoundButtonComponents';
import { ActionControl } from 'design/actionControl';

type Props = {
  chat: boolean;
  onOptionClick(scope: ServiceScope): void;
};

export const BookingSelectControlComponent = (props: Props) => {
  const { onOptionClick, chat } = props;

  const [popupIsOpened, setPopupIsOpened] = React.useState(false);

  const { bookingSelectControl: ownDictionary } = useIntlContext();

  return (
    <PopupGroupLayoutComponent
      onOutsideClick={() => {
        setPopupIsOpened(false);
      }}
      trigger={
        chat ? (
          <ActionControl
            theme="round-secondary"
            onClick={() => {
              setPopupIsOpened(prev => !prev);
            }}
          >
            <BookingIcon />
          </ActionControl>
        ) : (
          <ApplicationsTableRoundButton
            theme="primary"
            icon={<BookingIcon />}
            onClick={() => {
              setPopupIsOpened(prev => !prev);
            }}
          />
        )
      }
      popup={
        popupIsOpened && (
          <CustomSelectDropDown>
            <CustomSelectOption
              selected={false}
              onClick={() => {
                setPopupIsOpened(false);
                onOptionClick('RESTAURANT');
              }}
              label={ownDictionary.restaurantLabel}
            />
            <CustomSelectOption
              selected={false}
              onClick={() => {
                setPopupIsOpened(false);
                onOptionClick('CLUB');
              }}
              label={ownDictionary.clubLabel}
            />
          </CustomSelectDropDown>
        )
      }
    />
  );
};
