import React from 'react';

import { RectangleButton } from 'components/shared/RectangleButton';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useIntlContext } from 'hooks/useIntlContext';
import { CustomInput } from 'components/shared/CustomInput';
import { usePasswordFormBusy } from 'hooks/usePasswordFormBusy';
import { usePasswordFormValues } from 'hooks/usePasswordFormValues';
import { usePasswordFormEmpty } from 'hooks/usePasswordFormEmpty';
import { usePasswordFormErrorText } from 'hooks/usePasswordFormErrorText';
import { SettingsFormWrapper } from 'components/SettingsForm/SettingsFormWrapper';
import { SettingsFormLegend } from 'components/SettingsForm/SettingsFormLegend';
import { SettingsFormFieldset } from 'components/SettingsForm/SettingsFormFieldset';
import { SettingsFormFooter } from 'components/SettingsForm/SettingsFormFooter';
import { usePasswordSubmitDidSucceed } from 'hooks/usePasswordSubmitDidSucceed';
import { usePasswordFormError } from 'hooks/usePasswordFormError';

export const PasswordForm = () => {
  const { passwordForm: passwordFormIntl } = useIntlContext();

  const passwordInputRef = React.useRef<null | HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const handleSubmit = React.useCallback(() => {
    dispatch({
      name: 'PASSWORD_FORM__SUBMIT'
    });
  }, [dispatch]);

  const handleCancel = React.useCallback(() => {
    dispatch({
      name: 'PASSWORD_FORM__CANCEL'
    });
  }, [dispatch]);

  const handlePasswordChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        name: 'PASSWORD_FORM__PASSWORD_INPUT_CHANGE',
        value: event.target.value
      });
    },
    [dispatch]
  );

  const handlePasswordConfirmChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        name: 'PASSWORD_FORM__PASSWORD_CONFIRM_INPUT_CHANGE',
        value: event.target.value
      });
    },
    [dispatch]
  );

  const empty = usePasswordFormEmpty();

  const submitDidSucceed = usePasswordSubmitDidSucceed();

  const busy = usePasswordFormBusy();

  const values = usePasswordFormValues();

  const legendErrorText = usePasswordFormErrorText();

  const error = usePasswordFormError();

  const legendTitleText = passwordFormIntl.legendTitleLabel;

  const submitButtonLabel =
    submitDidSucceed && empty
      ? passwordFormIntl.submitDidSucceedLabel
      : passwordFormIntl.submitLabel;

  const hasCancelButton = !empty && !busy;

  const hasFooter = !empty || submitDidSucceed;

  const submitButtonDisabled = busy || (submitDidSucceed && empty);

  const submitButtonHasPreloader = busy;

  const fieldsetDisabled = busy;

  React.useEffect(() => {
    const { current: inputElement } = passwordInputRef;

    if (inputElement && error === 'PASSWORDS_DID_NOT_MATCH') {
      inputElement.focus();
    }
  }, [error]);

  return (
    <SettingsFormWrapper onSubmit={handleSubmit}>
      <SettingsFormLegend
        titleText={legendTitleText}
        errorText={legendErrorText}
      />
      <SettingsFormFieldset disabled={fieldsetDisabled}>
        <CustomInput
          ref={passwordInputRef}
          type="password"
          name="new-password"
          placeholder={passwordFormIntl.passwordInputLabel}
          value={values.password}
          onChange={handlePasswordChange}
          autoComplete="new-password"
        />
        <CustomInput
          type="password"
          name="new-password-confirm"
          placeholder={passwordFormIntl.passwordConfirmInputLabel}
          value={values.passwordConfirm}
          onChange={handlePasswordConfirmChange}
          autoComplete="new-password"
        />
      </SettingsFormFieldset>
      {hasFooter && (
        <SettingsFormFooter>
          {hasCancelButton && (
            <RectangleButton
              type="button"
              label={passwordFormIntl.cancelLabel}
              styleType="secondary"
              onClick={handleCancel}
            />
          )}
          <RectangleButton
            label={submitButtonLabel}
            hasPreloader={submitButtonHasPreloader}
            styleType="primary"
            isDisabled={submitButtonDisabled}
          />
        </SettingsFormFooter>
      )}
    </SettingsFormWrapper>
  );
};
