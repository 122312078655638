import React from 'react';

import { TextInputControl } from 'design/textInputControl';
import { MessageBox } from 'design/messageBox';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useChatMessageForm } from 'hooks/chats/useChatsState';
import { ActionControl } from 'design/actionControl';
import { useIntlContext } from 'hooks/useIntlContext';
import {
  CHAT_MESSAGE_INPUT_MAX_LENGTH,
  CHAT_MESSAGE_INPUT_ID,
  KEY_ENTER,
  KEY_ARROW_UP
} from 'utils/constants';
import { focusChatMessageInput } from 'utils/chatUtils';
import { PaperPlaneIcon } from 'svg/24x24/paper-plane';

type Props = {
  roomId: number;
};

export const ChatsPageForm = (props: Props) => {
  const { roomId } = props;

  const returnFocusRef = React.useRef<boolean>(false);

  const dispatch = useAppDispatch();

  const { chatsPage: chatsPageIntl } = useIntlContext();

  const { value, status } = useChatMessageForm(roomId);

  const valueIsEmpty = value === '';

  const valueIsHollow = value.trim() === '';

  const handleTextInputChange = React.useCallback(
    (nextValue: string) => {
      dispatch({
        name: 'CHATS_PAGE__MESSAGE_CHANGE',
        nextValue,
        roomId
      });
    },
    [dispatch, roomId]
  );

  const submitMessage = React.useCallback(() => {
    if (!valueIsHollow) {
      returnFocusRef.current = true;

      dispatch({
        name: 'CHATS_PAGE__MESSAGE_SUBMIT',
        roomId
      });
    }
  }, [dispatch, roomId, valueIsHollow]);

  const handleArrowUp = React.useCallback(() => {
    if (valueIsEmpty) {
      dispatch({
        name: 'CHATS_PAGE__ARROW_UP_CLICKED_ON_EMPTY_INPUT',
        roomId
      });
    }
  }, [dispatch, roomId, valueIsEmpty]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === KEY_ENTER) {
        if (event.metaKey || event.ctrlKey) {
          submitMessage();
        }
      }

      if (event.key === KEY_ARROW_UP) {
        handleArrowUp();
      }
    },
    [handleArrowUp, submitMessage]
  );

  React.useEffect(() => {
    const { current: returnFocus } = returnFocusRef;

    if (returnFocus && status === 'IDLE') {
      focusChatMessageInput();

      returnFocusRef.current = false;
    }
  }, [status]);

  const disabled = status === 'SUBMITTED' || status === 'REJECTED';

  return (
    <MessageBox theme="input">
      <TextInputControl
        id={CHAT_MESSAGE_INPUT_ID}
        value={value}
        disabled={disabled}
        placeholder={chatsPageIntl.messageInputPlaceholder}
        maxLength={CHAT_MESSAGE_INPUT_MAX_LENGTH}
        multiline
        onValueChange={handleTextInputChange}
        onKeyDown={handleKeyDown}
      />
      <ActionControl
        disabled={disabled}
        hidden={valueIsHollow}
        theme="round-primary"
        onClick={submitMessage}
      >
        <PaperPlaneIcon />
      </ActionControl>
    </MessageBox>
  );
};
