import React from 'react';

import styles from './ReviewsPage.module.css';

import { Spinner } from 'components/shared/Spinner';
import { testId } from 'utils/testId';

export const ReviewsPagePreloader = () => (
  <div
    className={styles.ReviewsPage__Preloader}
    data-testid={testId.REVIEWS_PAGE_PRELOADER}
  >
    <Spinner className={styles.ReviewsPage__Spinner} />
  </div>
);
