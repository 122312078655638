import { UserInfo } from 'types/state';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { createUserInfoMock } from 'utils/createUserInfoMock';
import { AuthorizationError } from 'utils/errors';
import { sessionModel } from '../utils/sessonModel';

export const getUserInfo = async (): Promise<UserInfo> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  if (sessionModel.isExpired()) {
    throw new AuthorizationError();
  }

  return createUserInfoMock();
};
