import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { TNewsArticle } from 'types/state';
import { TGetNewsFeedParams } from 'types/services';
import { newsStoreMock } from 'services/mock/utils/newsModel';

export const GET_NEWS_FEED_PAGE_MOCK__CONNECTION_ERROR = -1;
export const GET_NEWS_FEED_PAGE_MOCK__EMPTY_FEED = -2;

export const getNewsFeed = async (
  params: TGetNewsFeedParams
): Promise<TNewsArticle[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (params.page === GET_NEWS_FEED_PAGE_MOCK__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }
  if (params.page === GET_NEWS_FEED_PAGE_MOCK__EMPTY_FEED) {
    return [];
  }
  return newsStoreMock.getArticlesByPage(params.page);
};
