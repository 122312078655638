import React from 'react';

import styles from './CompetitionParticipants.module.css';

import { TCompetitionParticipantStatus } from 'types/state';
import { CustomSelect } from 'containers/CustomSelect';

export type TCompetitionParticipantStatusSelectOption = {
  label: string;
  value: TCompetitionParticipantStatus;
};

type TProps = {
  selected: TCompetitionParticipantStatus;
  options: TCompetitionParticipantStatusSelectOption[];
  onChange(nextValue: TCompetitionParticipantStatus): void;
};

export const CompetitionParticipantsStatusSelect = (props: TProps) => {
  return (
    <CustomSelect
      className={`${styles.CompetitionParticipants__StatusSelect} ${
        props.selected === 'PARTICIPATION_REQUESTED'
          ? styles.CompetitionParticipants__StatusSelect_participationRequested
          : ''
      } ${
        props.selected === 'PARTICIPATION_CONFIRMED'
          ? styles.CompetitionParticipants__StatusSelect_participationConfirmed
          : ''
      } ${
        props.selected === 'CANCELATION_REQUESTED'
          ? styles.CompetitionParticipants__StatusSelect_cancelationRequested
          : ''
      } ${
        props.selected === 'CANCELATION_CONFIRMED'
          ? styles.CompetitionParticipants__StatusSelect_cancelationConfirmed
          : ''
      }`}
      {...props}
    />
  );
};
