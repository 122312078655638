import React from 'react';

import { useChatMessageForm } from 'hooks/chats/useChatsState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useServices } from 'hooks/useServices';
import { SubmitChatMessageParams } from 'types/services';
import { chatMessageSubmissionThunk } from 'thunks/chatMessageSubmissionThunk';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

export const useChatMessageSubmissionService = (roomId: number) => {
  const dispatch = useAppDispatch();

  const { status, submittedValue } = useChatMessageForm(roomId);

  const { submitChatMessage: submitChatMessageService } = useServices();

  const params = React.useMemo<SubmitChatMessageParams>(() => {
    return {
      text: submittedValue,
      roomId: roomId
    };
  }, [roomId, submittedValue]);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    if (status === 'SUBMITTED') {
      const promise = chatMessageSubmissionThunk(
        submitChatMessageService(params),
        params
      )(dispatch);

      wrapAsyncSafely(promise);
    }
  }, [dispatch, params, status, submitChatMessageService, wrapAsyncSafely]);
};
