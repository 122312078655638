import React from 'react';

import { TNewsArticle, TNewsFilter, TNewsPageScreenMap } from 'types/state';
import { NewsFeedItem } from 'containers/NewsFeedItem';
import { NewsPageTemplate } from 'components/NewsPage/NewsPageTemplate';
import { NewsPageRightSection } from 'components/NewsPage/NewsPageRightSection';
import { NewsPageRightSectionFallback } from 'components/NewsPage/NewsPageRightSectionFallback';
import { NewsPreview } from 'containers/NewsPreview';
import { NewsFeedControls } from 'containers/NewsFeedControls';
import { NewsFeedContent } from 'containers/NewsFeedContent';
import { NewsFeedWrapper } from 'components/NewsFeed/NewsFeedWrapper';

type TProps = {
  previewFallbackText: string;
  activeFilter: TNewsFilter;
  article: null | TNewsArticle;
  articleId: null | number;
  feedItems: TNewsArticle[];
  screenMap: TNewsPageScreenMap;
  handleLoadMore(): void;
  handleFeedItemClick(itemId: number): void;
  handleCreateArticle(): void;
  handleArticleChange(nextData: Partial<TNewsArticle>): void;
  handleArticleDeleted(): void;
  handleArticleSubmited(id: number, asDraft: boolean): void;
  handleEditArticle(): void;
  handleCancelEditArticle(): void;
};

export const NewsPageView = (props: TProps) => {
  const {
    previewFallbackText,
    activeFilter,
    article,
    articleId,
    screenMap,
    feedItems,
    handleLoadMore,
    handleFeedItemClick,
    handleCreateArticle,
    handleArticleChange,
    handleArticleDeleted,
    handleArticleSubmited,
    handleEditArticle,
    handleCancelEditArticle
  } = props;

  const newsList = React.useMemo(() => {
    if (feedItems) {
      return feedItems.map((feedItem: TNewsArticle) => {
        const hasSelectedView = articleId === feedItem.id;
        const data = article && articleId === feedItem.id ? article : feedItem;
        return (
          <NewsFeedItem
            key={feedItem.id}
            data={data}
            hasSelectedView={hasSelectedView}
            onClick={handleFeedItemClick}
          />
        );
      });
    }
    return null;
  }, [feedItems, handleFeedItemClick, articleId, article]);

  return (
    <NewsPageTemplate>
      <NewsFeedWrapper>
        <NewsFeedControls
          activeFilter={activeFilter}
          hasCreateArticleButton={
            screenMap.CONTENT_PREVIEW_SCREEN || screenMap.CONTENT_LOADING_SCREEN
          }
          isFilterDisabled={screenMap.CONTENT_EDITING_SCREEN}
          onCreateArticle={handleCreateArticle}
        />
        <NewsFeedContent
          onLoadMore={handleLoadMore}
          hasPreloader={
            screenMap.CONTENT_PRELOADING_SCREEN ||
            screenMap.CONTENT_LOADING_SCREEN
          }
          hasEmptyFallback={screenMap.NO_CONTENT_SCEEN}
        >
          {newsList}
        </NewsFeedContent>
      </NewsFeedWrapper>
      <NewsPageRightSection>
        {!screenMap.CONTENT_PRELOADING_SCREEN &&
          !screenMap.NO_CONTENT_SCEEN &&
          !!article && (
            <NewsPreview
              article={article}
              hasEditView={screenMap.CONTENT_EDITING_SCREEN}
              onChange={handleArticleChange}
              onDeleted={handleArticleDeleted}
              onSumbited={handleArticleSubmited}
              onEdit={handleEditArticle}
              onCancelEdit={handleCancelEditArticle}
            />
          )}
        {screenMap.NO_CONTENT_SCEEN && (
          <NewsPageRightSectionFallback
            text={previewFallbackText}
            onCreateItem={handleCreateArticle}
          />
        )}
      </NewsPageRightSection>
    </NewsPageTemplate>
  );
};
