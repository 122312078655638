import {
  SportingCreateReservationPayload,
  SportingSecureParams
} from '../../types';
import { getSportingBookingURL } from '../../utils/getSportingURL';
import { createSportingHeaders } from '../../utils/createSportingHeaders';
import { appendHeadersWithJSON } from '../../../../utils/headers';
import { customFetch } from '../../../../utils/customFetch';
import { UnexpectedError } from '../../../../utils/errors';

export const createReservation = async (
  params: SportingSecureParams<SportingCreateReservationPayload>
): Promise<void> => {
  const { payload } = params;

  const endpointURL = getSportingBookingURL('createReservation');

  const headers = createSportingHeaders();
  appendHeadersWithJSON(headers);

  const body = JSON.stringify(payload);

  const response = await customFetch(endpointURL, {
    method: 'POST',
    body,
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }
};
