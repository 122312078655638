import React from 'react';

import { validateNewsArticleImage } from 'utils/validateNewsArticleImage';
import { normalizeURLToFile } from 'utils/normalizeURLToFile';
import { TArticeImage } from 'types/state';
import { useIntlContext } from 'hooks/useIntlContext';
import { useAsyncController } from 'hooks/useAsyncController';

type TProps = {
  value: null | TArticeImage;
  ratio: number;
  croppedMinWidth: number;
  croppedMinHeight: number;
  onChange(nextValue: null | TArticeImage): void;
};

export type TImageInputProps = TProps;

export const useImageInputState = (props: TProps) => {
  const objectURLRef = React.useRef<null | string>(null);

  const { value, onChange, ratio, croppedMinWidth, croppedMinHeight } = props;

  const intl = useIntlContext();

  const {
    unexpectedError,
    imageInputPlaceholder,
    imageInputSizeMessage
  } = intl;

  const [nextOriginal, setNextOriginal] = React.useState<null | File>(null);
  const [nextCropped, setNextCropped] = React.useState<null | File>(null);
  const [errorMessage, setErrorMessage] = React.useState<null | string>(null);

  const validateNewsArticleImageService = React.useCallback(
    async (file: File) => {
      const errorMessage = await validateNewsArticleImage({
        file,
        intl
      });
      if (errorMessage) {
        onChange(null);
        setNextOriginal(null);
        setNextCropped(null);
        setErrorMessage(errorMessage);
      } else {
        setNextOriginal(file);
        setErrorMessage(null);
      }
    },
    [intl, onChange]
  );

  const {
    wrapAsync: wrapImageValidation,
    asyncProcess: imageValidationProcess
  } = useAsyncController();

  const validateImage = React.useCallback(
    (params: File) => {
      const promise = validateNewsArticleImageService(params);

      wrapImageValidation(promise);
    },
    [validateNewsArticleImageService, wrapImageValidation]
  );

  const {
    wrapAsync: wrapURLNormalization,
    asyncProcess: urlNormalizationProcess
  } = useAsyncController<File>();

  const normalizeImageURL = React.useCallback(
    (params: string) => {
      const promise = normalizeURLToFile(params).catch(error => error);

      wrapURLNormalization(promise);
    },
    [wrapURLNormalization]
  );

  // Effects

  React.useEffect(() => {
    if (urlNormalizationProcess.status === 'RESOLVED') {
      setNextOriginal(urlNormalizationProcess.value);
    }
    if (urlNormalizationProcess.status === 'REJECTED') {
      setErrorMessage(unexpectedError);
    }
  }, [unexpectedError, urlNormalizationProcess]);

  React.useEffect(() => {
    if (nextCropped && nextOriginal) {
      onChange({
        original: nextOriginal,
        cropped: nextCropped
      });
      setNextOriginal(null);
      setNextCropped(null);
    }
  }, [nextOriginal, nextCropped, onChange]);

  // TODO: сделать по-нормальному, в виде самоподчищающегося хука
  React.useEffect(() => {
    return () => {
      if (objectURLRef.current) {
        URL.revokeObjectURL(objectURLRef.current);
      }
    };
  }, []);

  // Normalization

  const handleChange = validateImage;

  const handleEdit = () => {
    if (value) {
      const { original } = value;
      if (original instanceof File) {
        setNextOriginal(original);
      } else {
        normalizeImageURL(original);
      }
    }
  };

  const handleDelete = () => {
    onChange(null);
    setNextOriginal(null);
    setNextCropped(null);
  };

  const handleCropped = React.useCallback(
    (file: File) => {
      setNextCropped(file);
    },
    [setNextCropped]
  );

  const handleCropCancel = () => {
    setNextOriginal(null);
  };

  const imagePreviewSrc = React.useMemo(() => {
    if (value) {
      if (value.cropped instanceof File) {
        objectURLRef.current = URL.createObjectURL(value.cropped);
        return objectURLRef.current;
      }
      return value.cropped;
    }
    return null;
  }, [value]);

  const hasPreloader = !!(
    imageValidationProcess.status === 'PENDING' ||
    urlNormalizationProcess.status === 'PENDING'
  );

  const infoMessage = imageInputSizeMessage;

  return {
    ratio,
    croppedMinWidth,
    croppedMinHeight,
    hasPreloader,
    imageInputPlaceholder,
    errorMessage,
    infoMessage,
    imagePreviewSrc,
    nextOriginal,
    handleEdit,
    handleChange,
    handleDelete,
    handleCropped,
    handleCropCancel
  };
};
