import { GetChatRoomsParams } from 'types/services';
import { ChatRoom, ChatMessage } from 'types/chats';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError } from 'utils/errors';
import { chatRoomsStoreMock } from 'services/mock/utils/chatRoomsModel';
import { chatMessagesStoreMock } from 'services/mock/utils/chatMessagesModel';

export const GET_CHAT_ROOMS_PAGE_MOCK__CONNECTION_ERROR = -1;
export const GET_CHAT_ROOMS_PAGE_MOCK__EMPTY_FEED = -2;

export const getChatRooms = async (
  params: GetChatRoomsParams
): Promise<[ChatRoom, ChatMessage][]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  if (params.page === GET_CHAT_ROOMS_PAGE_MOCK__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }

  if (params.page === GET_CHAT_ROOMS_PAGE_MOCK__EMPTY_FEED) {
    return [];
  }

  return chatRoomsStoreMock.getRoomsByPage(params.page).map(room => {
    const lastMessage = chatMessagesStoreMock.getMessageById(
      room.lastMessageId
    );

    if (!lastMessage) {
      throw new TypeError();
    }

    return [room, lastMessage];
  });
};
