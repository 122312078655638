import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { useServices } from 'hooks/useServices';
import { GetChatRoomMessagesParams } from 'types/services';
import { useActiveChatRoom } from 'hooks/chats/useActiveChatRoom';
import { chatRoomMessagesThunk } from 'thunks/chatRoomMessagesThunk';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';

export const useActiveChatRoomMessagesService = () => {
  const {
    id: actualChatRoomId,
    messageIdsList: activeChatRoomMessageIdsList
  } = useActiveChatRoom();

  const dispatch = useAppDispatch();

  const page = createListCacheSelectors(
    activeChatRoomMessageIdsList
  ).selectNextInvalidatedPage();

  const { getChatRoomMessages: getChatRoomMessagesService } = useServices();

  const params = React.useMemo<null | GetChatRoomMessagesParams>(() => {
    if (page && actualChatRoomId) {
      return {
        page,
        roomId: actualChatRoomId
      };
    }

    return null;
  }, [actualChatRoomId, page]);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    if (params) {
      const promise = chatRoomMessagesThunk(
        getChatRoomMessagesService(params),
        params
      )(dispatch);

      wrapAsyncSafely(promise);
    }
  }, [dispatch, getChatRoomMessagesService, params, wrapAsyncSafely]);
};
