import { CollectionCache } from 'types/util';
import { TApplication } from 'types/state';
import { AppEvent } from 'types/appEvent';
import { modifyCollectionCache } from 'utils/collectionCacheUtils';

export const applicationsCollectionReducer = (
  state: CollectionCache<number, TApplication>,
  event: AppEvent
): CollectionCache<number, TApplication> => {
  switch (event.name) {
    case 'NEW_SPORT_APPLICATIONS_SERVICE':
    case 'PROCESSED_SPORT_APPLICATIONS_SERVICE':
    case 'NEW_RESTAURANT_APPLICATIONS_SERVICE':
    case 'PROCESSED_RESTAURANT_APPLICATIONS_SERVICE':
      if (event.status === 'RESOLVED') {
        let modifier = modifyCollectionCache(state);

        event.payload.forEach(application => {
          modifier = modifier.putItem(application.id, application);
        });

        return modifier.return();
      }
      return state;
    default:
      return state;
  }
};
