import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { HTTPConnectionError, NotFoundError } from 'utils/errors';
import { TSetCompetitionNoteParams } from 'types/services';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const SET_COMPETITION_NOTE_MOCK_ID__CONNECTION_ERROR = -1;

export const setCompetitionNote = async (
  params: TSetCompetitionNoteParams
): Promise<void> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  if (params.id === SET_COMPETITION_NOTE_MOCK_ID__CONNECTION_ERROR) {
    throw new HTTPConnectionError();
  }
  const competition = competitionsStoreMock
    .getAllArticles()
    .find(({ id }) => id === params.id);
  if (!competition) {
    throw new NotFoundError();
  } else {
    competition.note = params.note;
  }
};
