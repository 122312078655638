import { getSportingChatURL } from 'services/sporting/utils/getSportingURL';
import { customFetch } from 'utils/customFetch';
import { UnexpectedError } from 'utils/errors';
import {
  SportingSecureParams,
  SportingServiceScope
} from 'services/sporting/types';
import { createQueryString } from 'utils/createQueryString';
import { createSportingHeaders } from 'services/sporting/utils/createSportingHeaders';

type Payload = {
  destinactionType: SportingServiceScope;
};

export const getUnreadedChats = async (
  params: SportingSecureParams<Payload>
): Promise<number[]> => {
  const { payload } = params;

  const endpointURL = getSportingChatURL(
    `getUnreadedChats?${createQueryString(payload)}`
  );

  const headers = createSportingHeaders();

  const response = await customFetch(endpointURL, {
    headers
  });

  if (!response.ok) {
    throw new UnexpectedError();
  }

  if (response.status === 200) {
    return response.json();
  }

  return [];
};
