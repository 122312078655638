import { AppEvent } from 'types/appEvent';
import { HTTPConnectionError } from 'utils/errors';

export const connectionErrorFlagReducer = (state: boolean, event: AppEvent) => {
  switch (event.name) {
    case 'SERVICE__ERROR': {
      if (event.payload instanceof HTTPConnectionError) {
        return true;
      }

      return state;
    }
    default:
      return state;
  }
};
