import { DeleteMessageTemplateParams } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { messageTemplateStoreMock } from 'services/mock/utils/messageTemplateModel';

export const deleteMessageTemplate = async (
  params: DeleteMessageTemplateParams
): Promise<void> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  messageTemplateStoreMock.deleteTemplateById(params.messageTemplateId);
};
