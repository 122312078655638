import React from 'react';

import { StandardLayout } from 'design/standardLayout';
import { MessageTemplatesList } from 'containers/MessageTemplates/MessageTemplatesList';
import { MessageTemplatesActions } from 'containers/MessageTemplates/MessageTemplatesActions';
import { useMessageTemplatesService } from 'hooks/messageTemplates/useMessageTemplatesServcie';
import { useMessageTemplatesState } from 'hooks/messageTemplates/useMessageTemplatesState';
import { createListCacheSelectors } from 'utils/listCacheUtils';

export const MessageTemplates = () => {
  useMessageTemplatesService();

  const { messageTemplatesList } = useMessageTemplatesState();

  const messageTemplatesListSelectors = createListCacheSelectors(
    messageTemplatesList
  );

  const messageTemplatesListEmpty =
    messageTemplatesListSelectors.selectAllItems().length === 0;

  const messageTemplatesListInvalidated =
    messageTemplatesListSelectors.selectInvalidatedPages().length > 0;

  const hasFooter =
    !messageTemplatesListInvalidated || !messageTemplatesListEmpty;

  return (
    <StandardLayout
      block="message-templates"
      header={null}
      body={<MessageTemplatesList />}
      footer={hasFooter && <MessageTemplatesActions />}
    />
  );
};
