import { sportingAPI } from '../api';

export const doPlacesHaveForthcomingReservations = async (
  placeIds: number[]
): Promise<boolean> => {
  const result = await sportingAPI.booking.haveOncomingReservations({
    placeIds
  });

  for (let value of Object.values(result)) {
    if (value) {
      return true;
    }
  }

  return false;
};
