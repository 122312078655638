import { MessageTemplatesState } from 'types/messageTemplate';
import { createListCache } from 'utils/listCacheUtils';

export const createMessageTemplatesState = (): MessageTemplatesState => ({
  mode: 'VIEW',
  messageTemplatesList: createListCache(),
  templateCandidateText: '',
  changedTemplates: [],
  deletedTemplateIds: []
});
