import { GetChatRoomMessagesParams } from 'types/services';
import { ChatMessage } from 'types/chats';
import { sportingAPI } from 'services/sporting/api';
import { normalizeSportingMessage } from 'services/sporting/utils/normalizeSportingMessage';
import { CHAT_MESSAGES_PAGE_LENGTH } from 'utils/constants';

export const getChatRoomMessages = async (
  params: GetChatRoomMessagesParams
): Promise<ChatMessage[]> => {
  const { page, roomId } = params;

  const data = await sportingAPI.chat.getMessages({
    payload: { idChat: roomId, page: page - 1, size: CHAT_MESSAGES_PAGE_LENGTH }
  });

  return data.content.map(sportingMessage =>
    normalizeSportingMessage(sportingMessage).toChatMessage()
  );
};
