import React from 'react';

import styles from './NewsPage.module.css';
import { CreateButton } from 'components/shared/CreateButton';
import { testId } from 'utils/testId';

type TProps = {
  text: string;
  onCreateItem(): void;
};

export const NewsPageRightSectionFallback = ({
  text,
  onCreateItem
}: TProps) => (
  <div className={styles.NewsPage__RightSectionFallback}>
    <button
      onClick={onCreateItem}
      className={styles.NewsPage__RightSectionFallbackClickableArea}
      data-testid={testId.NEWS_PREVIEW_CREATE_ARTICLE_BUTTON}
    >
      <CreateButton
        useDiv
        className={styles.NewsPage__RightSectionFallbackButton}
      />
      <div className={styles.NewsPage__RightSectionFallbackText}>{text}</div>
    </button>
  </div>
);
