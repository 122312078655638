import React from 'react';

import './messageTemplatesListLayout.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type Props = {
  children: React.ReactNode;
};

export const MessageTemplatesListLayout = (props: Props) => {
  const { children } = props;

  const bem = createBEMConstructor('messageTemplatesListLayout');

  const wrapperClassName = bem('wrapper');

  return <div className={wrapperClassName}>{children}</div>;
};
