import { ListCache } from 'types/util';
import { AppEvent } from 'types/appEvent';
import { modifyListCache, createListCache } from 'utils/listCacheUtils';
import { authorizedRoutes } from 'utils/authorizedRoutes';

export const newSportApplicationsListReducer = (
  state: ListCache<number>,
  event: AppEvent
): ListCache<number> => {
  switch (event.name) {
    case 'NEW_SPORT_APPLICATIONS_SERVICE':
      if (event.status === 'RESOLVED') {
        const ids = event.payload.map(item => item.id);

        return modifyListCache(state)
          .putItems(1, ids)
          .return();
      }

      return state;
    case 'APPLICATIONS_PAGE__NEW_APPLICATIONS_COUNT_CHANGED': {
      return modifyListCache(state)
        .invalidateAllPages()
        .return();
    }
    case 'ROUTER__LOCATION_PATHNAME_CHANGE':
      const shouldDrop =
        event.prevLocation === authorizedRoutes.applicationsSport();

      const shouldInvalidateFirstPage =
        event.nextLocation === authorizedRoutes.applicationsSport();

      if (shouldDrop) {
        return modifyListCache(createListCache<number>()).return();
      }

      if (shouldInvalidateFirstPage) {
        return modifyListCache(state)
          .invalidatePage(1)
          .return();
      }

      return state;
    default:
      return state;
  }
};
