import React from 'react';

import { CHECK_SMS_COUNT_INTERVAL } from 'utils/constants';
import { setCustomInterval } from 'utils/setCustomInterval';
import { useIntlContext } from 'hooks/useIntlContext';
import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from './useAsyncController';
import { AuthorizationError } from 'utils/errors';

export const useSMSCountService = () => {
  const dispatch = useAppDispatch();

  const { smsCountWarning } = useIntlContext();

  const services = useServices();

  // Состояние и метод отправки запроса за количеством оставшихся SMS.
  const {
    wrapAsync: wrapCountPulling,
    asyncProcess: countPullingProcess
  } = useAsyncController<number>();

  const pullCount = React.useCallback(() => {
    const promise = services.getAvailableSmsCount().catch(error => {
      if (error instanceof AuthorizationError) {
        throw error;
      }

      return error;
    });

    wrapCountPulling(promise);
  }, [services, wrapCountPulling]);

  // Эффект следит за состоянием запроса на получение
  // количества оставшихся SMS и отображает уведомление
  // в случае, если это количество меньше установленного
  // значения.
  React.useEffect(() => {
    if (countPullingProcess.status === 'PENDING') {
      dispatch({
        name: 'SMS_COUNT_SERVICE',
        status: 'PENDING'
      });
    }
    if (countPullingProcess.status === 'RESOLVED') {
      dispatch({
        name: 'SMS_COUNT_SERVICE',
        status: 'RESOLVED',
        payload: countPullingProcess.value
      });
    }
    if (countPullingProcess.status === 'REJECTED') {
      dispatch({
        name: 'SMS_COUNT_SERVICE',
        status: 'REJECTED',
        payload: countPullingProcess.error
      });
    }
  }, [countPullingProcess, dispatch, smsCountWarning]);

  // Эффект отправляет запрос за количеством оставшихся SMS
  // и создает интервал, который периодически повторят это действие.
  React.useEffect(() => {
    pullCount();

    return setCustomInterval(() => {
      pullCount();
    }, CHECK_SMS_COUNT_INTERVAL);
  }, [pullCount]);
};
