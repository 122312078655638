import { SignOutService } from 'types/services';
import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { sessionModel } from '../utils/sessonModel';

export const signOut: SignOutService = async () => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  sessionModel.delete();
};
