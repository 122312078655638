import { TFaviconType } from 'types/state';

const defaultFaviconSubPath = '/favicon-default/';
const attentionFaviconSubPath = '/favicon-attention/';

export const setFavicon = (faviconType: TFaviconType) => {
  const links = document.querySelectorAll('link');

  const subPathRegex = /\/favicon-[a-z]+\//;

  links.forEach(link => {
    if (link.href.endsWith('/favicon.ico')) {
      const nextSubPath =
        faviconType === 'ATTENTION'
          ? attentionFaviconSubPath
          : defaultFaviconSubPath;

      link.href = link.href.replace(subPathRegex, nextSubPath);
    }
  });
};
