import React from 'react';

import styles from './ReviewsPage.module.css';

type TProps = {
  children: React.ReactNode;
};

export const ReviewsPageFeed = ({ children }: TProps) => (
  <div className={styles.ReviewsPage__Feed}>{children}</div>
);
