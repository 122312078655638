import { AppEvent } from '../../types/appEvent';
import { BookingPage } from 'types/booking';

export const restaurantBookingPageReducer = (
  prev: BookingPage,
  event: AppEvent
): BookingPage => {
  switch (event.name) {
    case 'BookingPage__ChangeStartLineDate': {
      if (event.location === 'RESTAURANT') {
        return {
          ...prev,
          startLineDate: event.date
        };
      }
      return prev;
    }
    case 'BookingPage__DateSelected': {
      if (event.location === 'RESTAURANT') {
        return {
          ...prev,
          selectedDateISO: event.dateISO
        };
      }

      return prev;
    }
    default:
      return prev;
  }
};
