import React from 'react';

import styles from './NewsFeed.module.css';

type TProps = {
  text: string;
};

export const NewsFeedFallback = ({ text }: TProps) => (
  <div className={styles.NewsFeed__Fallback}>{text}</div>
);
