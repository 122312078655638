import React from 'react';

import { SettingsPageWrapper } from 'pages/SettingsPage/components/SettingsPageWrapper';
import { SettingsPageTitle } from 'pages/SettingsPage/components/SettingsPageTitle';
import { useIntlContext } from 'hooks/useIntlContext';
import { SettingsPageContent } from 'pages/SettingsPage/components/SettingsPageContent';
import { SectionsEditor } from 'containers/SectionsEditor';
import { PasswordEditor } from 'containers/PasswordEditor';
import { useBrowserTabTitleUpdater } from 'hooks/useBrowserTabTitleUpdater';
import { SheduleEditor } from 'containers/SheduleEditor';
import { useSettingsPageHasSheduleEditor } from 'hooks/useSettingsPageHasSheduleEditor';
import { LoginEditor } from 'containers/LoginEditor';
import { PersonalDataEditor } from 'containers/PersonalDataEditor';
import { usePhoneNumbersService } from 'hooks/usePhoneNumbersService';

export const SettingsPage = () => {
  useBrowserTabTitleUpdater();
  usePhoneNumbersService();

  const { settingsPage } = useIntlContext();

  const hasSheduleEditor = useSettingsPageHasSheduleEditor();

  return (
    <SettingsPageWrapper>
      <SettingsPageTitle text={settingsPage.title} />
      <SettingsPageContent>
        <SectionsEditor />
        {hasSheduleEditor && <SheduleEditor />}
        <PersonalDataEditor />
        <LoginEditor />
        <PasswordEditor />
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
};
