import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';
import { useAsyncController } from './useAsyncController';
import { useNewSportApplicationsCount } from './useNewSportApplicationsCount';

export const useNewSportApplicationsCountUpdateProcess = () => {
  const count = useNewSportApplicationsCount();

  const dispatch = useAppDispatch();

  const sections = useEnabledSectionsStrict();

  const {
    getNewSportApplicationsCount: getNewSportApplicationsCountService
  } = useServices();

  const { asyncProcess, wrapAsync } = useAsyncController<number>();

  const shouldUpdate =
    sections.includes('applicationsSport') && count.didInvalidate;

  React.useEffect(() => {
    if (shouldUpdate) {
      wrapAsync(getNewSportApplicationsCountService());
    }
  }, [getNewSportApplicationsCountService, shouldUpdate, wrapAsync]);

  React.useEffect(() => {
    if (asyncProcess.status === 'PENDING') {
      dispatch({
        name: 'NEW_APPLICATIONS_COUNT_SERVICE',
        status: 'PENDING',
        meta: 'CLUB'
      });
    }
    if (asyncProcess.status === 'RESOLVED') {
      dispatch({
        name: 'NEW_APPLICATIONS_COUNT_SERVICE',
        status: 'RESOLVED',
        payload: asyncProcess.value,
        meta: 'CLUB'
      });
    }
    if (asyncProcess.status === 'REJECTED') {
      dispatch({
        name: 'NEW_APPLICATIONS_COUNT_SERVICE',
        status: 'REJECTED',
        payload: asyncProcess.error,
        meta: 'CLUB'
      });
    }
  }, [asyncProcess, dispatch]);
};
