import { UserEditorState } from 'types/state';

export const patchUserEditorStatesByUserId = (
  state: Map<number, UserEditorState>
) => ({
  done() {
    return state;
  },
  setUserEditorState(userId: number, nextValue: UserEditorState) {
    const nextState = new Map(state);

    nextState.set(userId, nextValue);

    return patchUserEditorStatesByUserId(nextState);
  },
  removeUserEditorState(userId: number) {
    const nextState = new Map(state);

    nextState.delete(userId);

    return patchUserEditorStatesByUserId(nextState);
  }
});
