import { TCompetitionParticipantLogRecord } from 'types/state';
import { normalizeSportingCompetitionParticipantStatus } from 'services/sporting/utils/normalizeSportingCompetitionParticipantStatus';
import { TSportingStatusHistoryItem } from 'services/sporting/types';

export const normalizeSportingStatusHistoryItem = {
  toCompetitionParticipantLogRecord(
    item: TSportingStatusHistoryItem
  ): TCompetitionParticipantLogRecord {
    return {
      id: item.id,
      date: item.statusDate,
      status: normalizeSportingCompetitionParticipantStatus.toParticipantStatus(
        item.status
      ),
      changedBy: (() => {
        const { userName, userLastName, userLogin } = item;
        if (userLastName && userName) {
          return `${userLastName} ${userName}`;
        }
        return userLogin;
      })()
    };
  }
};
