import { TCompetitionParticipantStatus } from 'types/state';
import { TSportingCompetitionParticipantStatusId } from 'services/sporting/types';
import { UnreachableError } from 'utils/errors';

export const normalizeCompetitionParticipantStatus = {
  toSportingParticipantStatusId(
    status: TCompetitionParticipantStatus
  ): TSportingCompetitionParticipantStatusId {
    switch (status) {
      case 'PARTICIPATION_REQUESTED':
        return 1;
      case 'CANCELATION_REQUESTED':
        return 2;
      case 'PARTICIPATION_CONFIRMED':
        return 3;
      case 'CANCELATION_CONFIRMED':
        return 4;
      default:
        throw new UnreachableError(status);
    }
  }
};
