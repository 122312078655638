import { AppState } from 'types/appState';
import { AppEvent } from 'types/appEvent';
import { createLoggedReducer } from 'utils/createLoggedReducer';
import { createAppState } from 'utils/createAppState';
import { deviceFlagReducer } from 'reducers/deviceFlagReducer';
import { mediaQueryTypeReducer } from 'reducers/mediaQueryTypeReducer';
import { mobileDrawerOpenedReducer } from 'reducers/mobileDrawerOpenedReducer';
import { remainingSMSCountReducer } from 'reducers/remainingSMSCountReducer';
import { onlineFlagReducer } from 'reducers/onlineFlagReducer';
import { connectionErrorFlagReducer } from 'reducers/connectionErrorFlagReducer';
import { sessionReducer } from 'reducers/sessionReducer';
import { newSportApplicationsCountReducer } from 'reducers/newSportApplicationsCountReducer';
import { newRestaurantApplicationsCountReducer } from 'reducers/newRestaurantApplicationsCountReducer';
import { competitionsRequestsCountReducer } from 'reducers/competitionsRequestsCountReducer';
import { competitionRequestsCountsByIdReducer } from 'reducers/competitionRequestsCountsByIdReducer';
import { newReviewsCountReducer } from 'reducers/newReviewsCountReducer';
import { toastCandidatesReducer } from 'reducers/toastCandidatesReducer';
import { userInfoCacheReducer } from 'reducers/userInfoCacheReducer';
import { sectionsEditorStateReducer } from 'reducers/sectionsEditorStateReducer';
import { passwordEditorStateReducer } from 'reducers/passwordEditorStateReducer';
import { usersEditorStateReducer } from 'reducers/usersEditorStateReducer';
import { userToDeleteIdReducer } from 'reducers/userToDeleteIdReducer';
import { phoneNumbersReducer } from 'reducers/phoneNumbersReducer';
import { applicationsCollectionReducer } from 'reducers/applicationsCollectionReducer';
import { newSportApplicationsListReducer } from 'reducers/newSportApplicationsListReducer';
import { newRestaurantApplicationsListReducer } from 'reducers/newRestaurantApplicationsListReducer';
import { processedSportApplicationsListReducer } from 'reducers/processedSportApplicationsListReducer';
import { processedRestaurantApplicationsListReducer } from 'reducers/processedRestaurantApplicationsListReducer';
import { chatsStateReducer } from 'reducers/chatsStateReducer';
import { clientInfosCollectionReducer } from 'reducers/clientInfosCollectionReducer';
import { messageTemplatesStateReducer } from 'reducers/messageTemplatesStateReducer/messageTemplatesStateReducer';
import { visibleReducer } from 'reducers/visibleReducer';
import { reviewsCacheReducer } from './reviewsCacheReducer';
import { sheduleEditorStateReducer } from 'reducers/sheduleEditorStateReducer';
import { sheduleInfoCacheReducer } from 'reducers/sheduleInfoCacheReducer';
import { userToBlockedStateReducer } from 'reducers/employee/userToBlockedStateReducer';
import { employeeToEditReducer } from 'reducers/employee/employeeToEditReducer';
import { personalDataEditorStateReducer } from 'reducers/settings/personalDataEditorStateReducer';
import { loginEditorStateReducer } from 'reducers/settings/loginEditorStateReducer';
import { usersCacheReducer } from './employee/usersCacheReducer';
import { employeeEditorStateReducer } from './employee/employeeEditorStateReducer';
import { waitersCacheReducer } from './booking/waitersCacheReducer';
import { placesCacheReducer } from './booking/placesCacheReducer';
import { timeLinesCacheReducer } from './booking/timeLinesCacheReducer';
import { reservationsCacheReducer } from './booking/reservationsCacheReducer';
import { bookingPlacesEditorStateReducer } from './booking/bookingPlacesEditorStateReducer';
import { restaurantBookingPageReducer } from './booking/restaurantBookingPageReducer';
import { bookingReservationEditorStateReducer } from './booking/bookingReservationEditorStateReducer';
import { clubBookingPageReducer } from './booking/clubBookingPageReducer';

export const appStateReducer = createLoggedReducer(
  (prevState: AppState, event: AppEvent): AppState => {
    let state = prevState;

    if (
      (event.name === 'SESSION_RECIEVED' &&
        event.session.type === 'UNAUTHORIZED') ||
      event.name === 'SESSION_TERMINATED' ||
      event.name === 'NAVIGATION__SIGNED_OUT' ||
      (event.name === 'SIGN_IN_SERVICE' && event.status === 'RESOLVED')
    ) {
      state = createAppState();
    }

    return {
      visible: visibleReducer(state.visible, event),
      deviceFlag: deviceFlagReducer(state.deviceFlag, event),
      mediaQueryType: mediaQueryTypeReducer(state.mediaQueryType, event),
      mobileDrawerOpened: mobileDrawerOpenedReducer(
        state.mobileDrawerOpened,
        event
      ),
      remainingSMSCount: remainingSMSCountReducer(
        state.remainingSMSCount,
        event
      ),
      onlineFlag: onlineFlagReducer(state.onlineFlag, event),
      connectionErrorFlag: connectionErrorFlagReducer(
        state.connectionErrorFlag,
        event
      ),
      session: sessionReducer(state.session, event),
      newSportApplicationsCount: newSportApplicationsCountReducer(
        state.newSportApplicationsCount,
        event
      ),
      newRestaurantApplicationsCount: newRestaurantApplicationsCountReducer(
        state.newRestaurantApplicationsCount,
        event
      ),
      competitionsRequestsCount: competitionsRequestsCountReducer(
        state.competitionsRequestsCount,
        event
      ),
      competitionRequestsCountsById: competitionRequestsCountsByIdReducer(
        state.competitionRequestsCountsById,
        event
      ),
      newReviewsCount: newReviewsCountReducer(state.newReviewsCount, event),
      toastCandidates: toastCandidatesReducer(
        state.toastCandidates,
        event,
        state
      ),
      userInfoCache: userInfoCacheReducer(state.userInfoCache, event),
      sectionsEditorState: sectionsEditorStateReducer(
        state.sectionsEditorState,
        event
      ),
      personalDataEditorState: personalDataEditorStateReducer(
        state.personalDataEditorState,
        event
      ),
      loginEditorState: loginEditorStateReducer(state.loginEditorState, event),
      passwordEditorState: passwordEditorStateReducer(
        state.passwordEditorState,
        event
      ),
      sheduleEditorState: sheduleEditorStateReducer(
        state.sheduleEditorState,
        event
      ),
      usersEditorState: usersEditorStateReducer(state.usersEditorState, event),
      userToDeleteId: userToDeleteIdReducer(state.userToDeleteId, event),
      phoneNumbers: phoneNumbersReducer(state.phoneNumbers, event),
      applicationsCollection: applicationsCollectionReducer(
        state.applicationsCollection,
        event
      ),
      newSportApplicationsList: newSportApplicationsListReducer(
        state.newSportApplicationsList,
        event
      ),
      newRestaurantApplicationsList: newRestaurantApplicationsListReducer(
        state.newRestaurantApplicationsList,
        event
      ),
      processedSportApplicationsList: processedSportApplicationsListReducer(
        state.processedSportApplicationsList,
        event
      ),
      processedRestaurantApplicationsList: processedRestaurantApplicationsListReducer(
        state.processedRestaurantApplicationsList,
        event
      ),
      chatsState: chatsStateReducer(state.chatsState, event, state),
      clientInfosCollection: clientInfosCollectionReducer(
        state.clientInfosCollection,
        event
      ),
      messageTemplatesState: messageTemplatesStateReducer(
        state.messageTemplatesState,
        event
      ),
      reviewsCache: reviewsCacheReducer(state.reviewsCache, event),
      sheduleInfoCache: sheduleInfoCacheReducer(state.sheduleInfoCache, event),

      employeeToEdit: employeeToEditReducer(state.employeeToEdit, event),
      employeeEditorState: employeeEditorStateReducer(
        state.employeeEditorState,
        event
      ),
      usersCache: usersCacheReducer(state.usersCache, event),
      userToBlockedState: userToBlockedStateReducer(
        state.userToBlockedState,
        event
      ),
      waitersCache: waitersCacheReducer(state.waitersCache, event),
      placesCache: placesCacheReducer(state.placesCache, event),
      timeLinesCache: timeLinesCacheReducer(state.timeLinesCache, event),
      reservationsCache: reservationsCacheReducer(
        state.reservationsCache,
        event
      ),
      bookingPlacesEditorState: bookingPlacesEditorStateReducer(
        state.bookingPlacesEditorState,
        event
      ),
      restaurantBookingPage: restaurantBookingPageReducer(
        state.restaurantBookingPage,
        event
      ),
      clubBookingPage: clubBookingPageReducer(state.clubBookingPage, event),
      bookingReservationEditorState: bookingReservationEditorStateReducer(
        state.bookingReservationEditorState,
        event
      )
    };
  }
);
