import React from 'react';

import { RoundButton } from 'components/shared/RoundButton';
import { Spinner } from 'components/shared/Spinner';
import { TrashIcon } from 'svg/20x20/trash';

import styles from './ImageInput.module.css';
import { PencilIcon } from 'svg/24x24/pencil';

type TProps = {
  src: string;
  hasPreloader: boolean;
  onEdit(): void;
  onDelete(): void;
};

export const ImageInputPreview = ({
  src,
  hasPreloader,
  onDelete,
  onEdit
}: TProps) => (
  <div className={styles.ImageInput__Preview}>
    <img src={src} alt="" className={styles.ImageInput__PreviewImage} />
    {hasPreloader ? (
      <div className={styles.ImageInput__Preloader}>
        <Spinner className={styles.ImageInput__Spinner} />
      </div>
    ) : (
      <div className={styles.ImageInput__PreviewActions}>
        <RoundButton
          className={styles.ImageInput__PreviewAction}
          onClick={onEdit}
        >
          <PencilIcon />
        </RoundButton>
        <RoundButton
          className={styles.ImageInput__PreviewAction}
          onClick={onDelete}
        >
          <TrashIcon />
        </RoundButton>
      </div>
    )}
  </div>
);
