import { TSportingMenuItem } from 'services/sporting/types';
import { Section } from 'types/state';
import { UnreachableError } from 'utils/errors';

export const normalizeSportingMenuItem = {
  toSection(sportingMenuItem: TSportingMenuItem): Section {
    switch (sportingMenuItem) {
      case 'RESTARAUNT_CLAIMS':
        return 'applicationsRestaurant';
      case 'RESTARAUNT_CHATS':
        return 'chatsRestaurant';
      case 'BOOKING_RESTAURANT':
        return 'bookingRestaurant';
      case 'SHOOTING_CLAIMS':
        return 'applicationsSport';
      case 'SHOOTING_CHATS':
        return 'chatsSport';
      case 'BOOKING_CLUB':
        return 'bookingClub';
      case 'NEWS':
        return 'news';
      case 'COMPETITIONS':
        return 'competitions';
      case 'COMMENTS':
        return 'feedback';
      case 'VERIFICATION':
        return 'members';
      case 'USERS':
        return 'users';
      default:
        throw new UnreachableError(sportingMenuItem);
    }
  }
};
