import { TSubmitNewsArticleParams } from 'types/services';
import { NEWS_ARTICLE_CANDIDATE_ID } from 'utils/constants';
import { TNewsArticle } from 'types/state';
import { normalizeNewsArticle } from 'services/sporting/utils/normalizeNewsArticle';
import { normalizeSportingNewsArticle } from 'services/sporting/utils/normalizeSportingNewsArticle';
import { sportingAPI } from 'services/sporting/api';

export const submitNewsArticle = async (
  params: TSubmitNewsArticleParams
): Promise<TNewsArticle> => {
  const { data, saveAsDraft, sendNotification } = params;

  const body = normalizeNewsArticle.toSportingFormData(
    data,
    saveAsDraft,
    sendNotification
  );

  let promise;

  if (data.id !== NEWS_ARTICLE_CANDIDATE_ID) {
    promise = sportingAPI.service.updateNews({ payload: body });
  } else {
    promise = sportingAPI.service.addNews({ payload: body });
  }

  const sportnigArticle = await promise;

  return normalizeSportingNewsArticle.toNewsArticle(sportnigArticle);
};
