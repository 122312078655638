import { EmployeeFormValues } from 'types/state';

export const createNewEmployeeFormValues = (): EmployeeFormValues => ({
  id: 0,
  role: 'MANAGER',
  phoneNumber: '',
  lastName: '',
  firstName: '',
  login: '',
  password: '',
  passwordConfirm: ''
});
