import { useAppDispatch } from '../useAppDispatch';
import { useServices } from '../useServices';
import { useAsyncController } from '../useAsyncController';
import React from 'react';
import { TDeleteReservationParams } from '../../types/services';

export const useBookingReservationDeleteService = () => {
  const appDispatch = useAppDispatch();
  const services = useServices();

  const { wrapAsync: wrap, asyncProcess } = useAsyncController();

  const deleteReservation = React.useCallback(
    (params: TDeleteReservationParams) => {
      const promise = services.deleteReservation(params).then(() => {
        appDispatch({
          name: 'BOOKING_RESERVATION_DELETED',
          reservationId: params.formValues.id
        });
      });

      wrap(promise);
    },
    [appDispatch, services, wrap]
  );

  return { deleteReservation, asyncProcess };
};
