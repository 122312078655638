import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useServerEventEffects } from 'hooks/useServerEventEffects';
import { useSafeAsyncWrapper } from './useSafeAsyncWrapper';

export const useServerEventSubscription = () => {
  const willUnmountRef = React.useRef(false);

  const dispatch = useAppDispatch();

  const { getServerEvents: getServerEventsMethod } = useServices();

  const initServerEventEffects = useServerEventEffects();

  const pullNextServerEvents = React.useCallback((): Promise<void> => {
    const { current: willUnmount } = willUnmountRef;

    if (!willUnmount) {
      return (
        getServerEventsMethod()
          .then(events => {
            events?.forEach(event => {
              dispatch(event);
              initServerEventEffects(event);
            });
          })
          // Сразу же рекурсивно отправляется запрос на следующее событие (long polling).
          .then(pullNextServerEvents)
      );
    } else {
      return Promise.resolve();
    }
  }, [dispatch, getServerEventsMethod, initServerEventEffects]);

  const wrapAsyncSafely = useSafeAsyncWrapper();

  React.useEffect(() => {
    const promise = pullNextServerEvents();

    wrapAsyncSafely(promise);
  }, [pullNextServerEvents, wrapAsyncSafely]);

  React.useEffect(() => {
    return () => {
      willUnmountRef.current = true;
    };
  }, []);
};
