import React from 'react';

import styles from './contactInfoLayout.module.css';

import { createClassNameConstructor } from 'utils/craeteClassNameConstructor';

type Props = {
  fullName: React.ReactNode;
  phone: React.ReactNode;
  callAction: React.ReactNode;
  bookingAction: React.ReactNode;
};

export const ContactInfoLayout = (props: Props) => {
  const { fullName, phone, callAction, bookingAction } = props;

  const classNameConstructor = createClassNameConstructor(
    styles.ContactInfoLayout
  );

  const wrapperClassName = classNameConstructor
    .addClass(styles.ContactInfoLayout__Wrapper)
    .return();

  const infoBlockClassName = classNameConstructor
    .addClass(styles.ContactInfoLayout__InfoBlock)
    .return();

  const actionsBlockClassName = classNameConstructor
    .addClass(styles.ContactInfoLayout__ActionsBlock)
    .return();

  const fullNameSlotClassName = classNameConstructor
    .addClass(styles.ContactInfoLayout__FullNameSlot)
    .return();

  const phoneSlotClassName = classNameConstructor
    .addClass(styles.ContactInfoLayout__PhoneSlot)
    .return();

  const bookingActionSlotClassName = classNameConstructor
    .addClass(styles.ContactInfoLayout__BookingActionSlot)
    .return();

  const callActionSlotClassName = classNameConstructor
    .addClass(styles.ContactInfoLayout__CallActionSlot)
    .return();

  return (
    <div className={wrapperClassName}>
      <div className={infoBlockClassName}>
        <div className={fullNameSlotClassName}>{fullName}</div>
        <div className={phoneSlotClassName}>{phone}</div>
      </div>
      <div className={actionsBlockClassName}>
        <div className={bookingActionSlotClassName}>{bookingAction}</div>
        <div className={callActionSlotClassName}>{callAction}</div>
      </div>
    </div>
  );
};
