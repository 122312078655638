import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAsyncController } from './useAsyncController';
import { useEnabledSectionsStrict } from './useEnabledSections';
import { useCompetitionsRequestsCount } from './useCompetitionsRequestsCount';

export const useCompetitionsRequestsCountUpdateProcess = () => {
  const count = useCompetitionsRequestsCount();

  const {
    getAllCompetitionsRequestsCount: getCompetitionsRequestsCountService
  } = useServices();

  const dispatch = useAppDispatch();

  const { asyncProcess, wrapAsync } = useAsyncController<number>();

  const sections = useEnabledSectionsStrict();

  const shouldUpdate = sections.includes('competitions') && count.didInvalidate;

  React.useEffect(() => {
    if (shouldUpdate) {
      const promise = getCompetitionsRequestsCountService();

      wrapAsync(promise);
    }
  }, [getCompetitionsRequestsCountService, shouldUpdate, wrapAsync]);

  React.useEffect(() => {
    if (asyncProcess.status === 'PENDING') {
      dispatch({
        name: 'COMPETITIONS_REQUESTS_COUNT_SERVICE',
        status: 'PENDING'
      });
    }
    if (asyncProcess.status === 'RESOLVED') {
      dispatch({
        name: 'COMPETITIONS_REQUESTS_COUNT_SERVICE',
        status: 'RESOLVED',
        payload: asyncProcess.value
      });
    }
    if (asyncProcess.status === 'REJECTED') {
      dispatch({
        name: 'COMPETITIONS_REQUESTS_COUNT_SERVICE',
        status: 'REJECTED',
        payload: asyncProcess.error
      });
    }
  }, [asyncProcess, dispatch]);
};
