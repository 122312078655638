import React from 'react';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useServices } from 'hooks/useServices';
import { useAsyncController } from '../useAsyncController';
import { TSubmitPlacesParams } from 'types/services';
import { PlaceDeleteError } from 'utils/errors';

export const useBookingPlaceEditorService = () => {
  const appDispatch = useAppDispatch();

  const services = useServices();

  const { wrapAsync, asyncProcess } = useAsyncController();

  const submitPlaces = React.useCallback(
    (params: TSubmitPlacesParams) => {
      const promise = services
        .submitPlaces(params)
        .then(() => {
          appDispatch({
            name: 'BOOKING_PLACE_SUBMIT_DID_SUCCEED'
          });
        })
        .catch(error => {
          if (error instanceof PlaceDeleteError) {
            return error;
          }

          throw error;
        });

      wrapAsync(promise);
    },
    [appDispatch, services, wrapAsync]
  );

  return { asyncProcess, submitPlaces };
};
