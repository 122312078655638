import { TReviewStatus } from 'types/state';
import { TSportingCommentStatusId } from '../types';
import { UnreachableError } from 'utils/errors';

export const normalizeSportingCommentStatusId = {
  toReviewStatus(status: TSportingCommentStatusId): TReviewStatus {
    switch (status) {
      case 1:
        return 'NEW';
      case 2:
        return 'PROCESSED';
      default:
        throw new UnreachableError(status);
    }
  }
};
