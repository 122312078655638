import { asyncTimeout } from 'utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from 'utils/constants';
import { competitionsStoreMock } from 'services/mock/utils/competitionsModel';

export const GET_ALL_COMPETITIONS_PARTICIPANTS_COUNT_MOCK_ID__CONNECTION_ERROR = -1;

export const getAllCompetitionsRequestsCount = async (): Promise<number> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);

  const competitions = competitionsStoreMock.getAllArticles();

  return competitions.reduce((acc, { participants }) => {
    return (
      acc +
      participants.reduce((acc, { status }) => {
        if (
          status === 'CANCELATION_REQUESTED' ||
          status === 'PARTICIPATION_REQUESTED'
        ) {
          return acc + 1;
        }
        return acc;
      }, 0)
    );
  }, 0);
};
