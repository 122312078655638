import React from 'react';

import { ModalWindow } from '../Modal/ModalWindow';

import styles from './BookingModalLayoutStyles.module.scss';

type Props = {
  closeButton: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
};

export const BookingModalLayout = (props: Props) => {
  const { closeButton, body, footer } = props;

  return (
    <ModalWindow additionalClassName={styles.window}>
      <div className={styles.closeButtonSlot}>{closeButton}</div>
      <div className={styles.body}>{body}</div>
      <div className={styles.footerSlot}>{footer}</div>
    </ModalWindow>
  );
};
