import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { DNDAnchor } from 'design/dndAnchor';
import { MessageBox } from 'design/messageBox';
import { ActionControl } from 'design/actionControl';
import { MessageTemplatesListItemLayout } from 'design/messageTemplatesListItemLayout';
import { useMessageTemplatesState } from 'hooks/messageTemplates/useMessageTemplatesState';
import { MessageTemplate } from 'types/messageTemplate';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Text } from 'design/text';
import { useEditetMessageTemplate } from 'hooks/messageTemplates/useEditetMessageTemplate';
import { useIntlContext } from 'hooks/useIntlContext';
import { focusChatMessageInput } from 'utils/chatUtils';
import { ContentEditable } from 'design/contentEditable';
import { MESSAGE_TEMPLATE_MAX_LENGTH } from 'utils/constants';
import { useActiveChatRoom } from 'hooks/chats/useActiveChatRoom';
import { TrashIcon } from 'svg/20x20/trash';

type Props = {
  messageTemplate: MessageTemplate;
  index: number;
};

export const MessageTemplatesListItem = (props: Props) => {
  const { messageTemplate, index } = props;

  const { messageTemplates } = useIntlContext();

  const { mode, deletedTemplateIds } = useMessageTemplatesState();

  const { id: roomId } = useActiveChatRoom();

  const editedMessageTemplate = useEditetMessageTemplate(messageTemplate.id);

  const dispatch = useAppDispatch();

  const handleDeleteClick = React.useCallback(() => {
    dispatch({
      name: 'MESSAGE_TEMPLATES__DELETE_CLICKED',
      templateId: messageTemplate.id
    });
  }, [dispatch, messageTemplate.id]);

  const handleTextChange = React.useCallback(
    (nextValue: string) => {
      dispatch({
        name: 'MESSAGE_TEMPLATES__TEMPLATE_TEXT_CHANGED',
        template: messageTemplate,
        nextValue
      });
    },
    [dispatch, messageTemplate]
  );

  const handleTemplateClick = React.useCallback(() => {
    if (roomId !== null) {
      dispatch({
        name: 'MESSAGE_TEMPLATES__TEMPLATE_CLICKED',
        template: messageTemplate,
        roomId
      });

      focusChatMessageInput();
    }
  }, [dispatch, messageTemplate, roomId]);

  const inputValue = editedMessageTemplate
    ? editedMessageTemplate.text
    : messageTemplate.text;

  const deleted = deletedTemplateIds.includes(messageTemplate.id);

  if (deleted) {
    return null;
  }

  return (
    <Draggable draggableId={`${messageTemplate.id}`} index={index}>
      {provided => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <MessageTemplatesListItemLayout
            primaryAction={
              mode === 'VIEW' && (
                <div {...provided.dragHandleProps}>
                  <DNDAnchor />
                </div>
              )
            }
            message={
              mode === 'EDIT' ? (
                <MessageBox theme="template-input">
                  <Text>
                    <ContentEditable
                      placeholder={messageTemplates.inputPlaceholder}
                      value={inputValue}
                      maxLength={MESSAGE_TEMPLATE_MAX_LENGTH}
                      onValueChange={handleTextChange}
                    />
                  </Text>
                </MessageBox>
              ) : (
                <ActionControl theme="invisible" onClick={handleTemplateClick}>
                  <MessageBox theme="template">
                    <Text>{messageTemplate.text}</Text>
                  </MessageBox>
                </ActionControl>
              )
            }
            secondaryAction={
              mode === 'EDIT' && (
                <ActionControl
                  theme="square-delete"
                  onClick={handleDeleteClick}
                >
                  <TrashIcon />
                </ActionControl>
              )
            }
          />
        </div>
      )}
    </Draggable>
  );
};
