import React from 'react';

import './standardLayout.scss';

import { createBEMConstructor } from 'utils/createBEMConstructor';

type StandardLayoutBlock =
  | 'chats-navigation'
  | 'chats-content'
  | 'message-templates';

type Props = {
  block: StandardLayoutBlock;
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
};

export const StandardLayout = (props: Props) => {
  const { block, header, body, footer } = props;

  const bemConstructor = createBEMConstructor(block);

  const wrapperClassName = bemConstructor('wrapper');

  const headerClassName = bemConstructor('header');

  const bodyClassName = bemConstructor('body');

  const footerClassName = bemConstructor('footer');

  return (
    <div className={wrapperClassName}>
      <div className={headerClassName}>{header}</div>
      <div className={bodyClassName}>{body}</div>
      <div className={footerClassName}>{footer}</div>
    </div>
  );
};
