import { TAddWaitersParams } from '../../../types/services';
import { asyncTimeout } from '../../../utils/asyncTimeout';
import { SERVICES_MOCK_DELAY } from '../../../utils/constants';
import { Waiter } from '../../../types/state';

export const addWaiters = async (
  params: TAddWaitersParams
): Promise<Waiter[]> => {
  await asyncTimeout(SERVICES_MOCK_DELAY);
  return [];
};
