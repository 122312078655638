const DEV_URL = 'http://localhost:8070/api/v1/';

const PROD_URL = '/api/v1/';

const IMAGE_LOCATION_RAW = 'rawImages';

const IMAGE_LOCATION_PROCESSED_NEWS = 'procassedNews';

const IMAGE_LOCATION_PROCESSED_COMPETITIONS = 'procassedCompetitions';

const getSportingBaseURL = () => {
  return process.env.NODE_ENV === 'production' ? PROD_URL : DEV_URL;
};

export const getSportingAdminURL = (path: string) => {
  return `${getSportingBaseURL()}admin/${path}`;
};

export const getSportingAuthURL = (path: string) => {
  return `${getSportingBaseURL()}auth/${path}`;
};

export const getSportingServiceURL = (path: string) => {
  return `${getSportingBaseURL()}service/${path}`;
};

export const getSportingResourceURL = (path: string) => {
  return `${getSportingBaseURL()}resource/${path}`;
};

export const getSportingUserURL = (path: string) => {
  return `${getSportingBaseURL()}user/${path}`;
};

export const getSportingRawImageURL = (name: string) => {
  return getSportingResourceURL(
    `image?name=${name}&location=${IMAGE_LOCATION_RAW}`
  );
};

export const getSportingProcessedNewsImageURL = (name: string) => {
  return getSportingResourceURL(
    `image?name=${name}&location=${IMAGE_LOCATION_PROCESSED_NEWS}`
  );
};

export const getSportingProcessedCompetitionsImageURL = (name: string) => {
  return getSportingResourceURL(
    `image?name=${name}&location=${IMAGE_LOCATION_PROCESSED_COMPETITIONS}`
  );
};

export const getSportingChatURL = (path: string) => {
  return `${getSportingBaseURL()}chat/admin/${path}`;
};

export const getSportingBookingURL = (path: string) => {
  return `${getSportingBaseURL()}booking/${path}`;
};
