import { CollectionCache } from 'types/util';

export const createCollectionCache = <IdType, ItemType>(): CollectionCache<
  IdType,
  ItemType
> => ({
  byId: new Map<IdType, ItemType>(),
  invalidatedIds: new Set<IdType>()
});

export const modifyCollectionCache = <IdType, ItemType>(
  collection: CollectionCache<IdType, ItemType>
) => ({
  return() {
    return collection;
  },
  putItem(id: IdType, item: ItemType) {
    const nextById = new Map(collection.byId);

    nextById.set(id, item);

    const nextInvalidatedIds = new Set(collection.invalidatedIds);

    nextInvalidatedIds.delete(id);

    return modifyCollectionCache({
      ...collection,
      byId: nextById,
      invalidatedIds: nextInvalidatedIds
    });
  },
  removeItem(id: IdType) {
    const nextById = new Map(collection.byId);
    nextById.delete(id);

    const nextInvalidatedIds = new Set(collection.invalidatedIds);
    nextInvalidatedIds.delete(id);

    return modifyCollectionCache({
      ...collection,
      byId: nextById,
      invalidatedIds: nextInvalidatedIds
    });
  },
  invalidateItem(id: IdType) {
    const nextInvalidatedIds = new Set(collection.invalidatedIds);

    nextInvalidatedIds.add(id);

    return modifyCollectionCache({
      ...collection,
      invalidatedIds: nextInvalidatedIds
    });
  },
  invalidateAllItems() {
    const nextInvalidatedIds = new Set(collection.invalidatedIds);

    for (let id of Array.from(collection.byId.keys())) {
      nextInvalidatedIds.add(id);
    }

    return modifyCollectionCache({
      ...collection,
      invalidatedIds: nextInvalidatedIds
    });
  }
});

export const createCollectionCacheSelectors = <IdType, ItemType>(
  collection: CollectionCache<IdType, ItemType>
) => ({
  selectItem(id: IdType) {
    const item = collection.byId.get(id);

    return item || null;
  },
  selectItemStrict(id: IdType) {
    const item = collection.byId.get(id);

    if (item) {
      return item;
    }

    throw new TypeError();
  },
  selectItems(ids: IdType[]): (ItemType | null)[] {
    const items = ids.map(id => {
      const item = collection.byId.get(id);

      return item || null;
    });

    return items;
  },
  selectItemsStrict(ids: IdType[]): ItemType[] {
    const items = ids.map(id => {
      const item = collection.byId.get(id);

      if (item) {
        return item;
      }

      throw new TypeError();
    });

    return items;
  },
  selectAllIds(): IdType[] {
    return Array.from(collection.byId.keys());
  },
  selectInvalidatedIds(): IdType[] {
    return Array.from(collection.invalidatedIds.values());
  }
});
