import { TReview, TReviewFeedItem, TReviewStatus } from 'types/state';
import { TIntl } from 'types/intl';
import { createReviewTitle } from 'utils/createReviewTitle';
import { UnreachableError } from 'utils/errors';
import { setISOToDate } from 'utils/dateUtils';

const getReviewStatusIntl = (status: TReviewStatus, intl: TIntl) => {
  switch (status) {
    case 'NEW':
      return intl.reviewStatusNew;
    case 'PROCESSED':
      return intl.reviewStatusProcessed;
    default:
      throw new UnreachableError(status);
  }
};

const normalizeReviewDate = (dateISO: string, intl: TIntl) => {
  const date = setISOToDate(dateISO);
  return `${date.getDate()} ${
    intl.predicativeMonthNames[date.getMonth()]
  } ${date.getFullYear()}`;
};

export const normalizeReview = {
  toReviewsFeedItem(review: TReview, intl: TIntl): TReviewFeedItem {
    return {
      id: review.id,
      title: createReviewTitle(intl, review),
      date: normalizeReviewDate(review.createdAtDate, intl),
      status: getReviewStatusIntl(review.status, intl)
    };
  }
};
