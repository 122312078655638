import React from 'react';

import { useIntlContext } from 'hooks/useIntlContext';
import { SettingsBlockWrapper } from 'components/SettingsBlock/SettingsBlockWrapper';
import { SettingsBlockContent } from 'components/SettingsBlock/SettingsBlockContent';
import { SettingsBlockTitle } from 'components/SettingsBlock/SettingsBlockTitle';
import { SettingsBlockDescription } from 'components/SettingsBlock/SettingsBlockDescription';
import { SheduleEditorForm } from './SheduleEditorForm';
import { useAppState } from 'hooks/useAppState';
import { useServices } from 'hooks/useServices';
import { sheduleInfoServiceThunk } from 'thunks/sheduleInfoThunk';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { updateSheduleInfoServiceThunk } from 'thunks/updateSheduleInfoThunk';

export const SheduleEditor = () => {
  const { sheduleEditor: ownIntl } = useIntlContext();

  const { sheduleInfoCache, sheduleEditorState } = useAppState();

  const services = useServices();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (sheduleInfoCache.didInvalidate) {
      const promise = sheduleInfoServiceThunk(services.getSheduleInfo())(
        dispatch
      );

      wrapAsyncSafely(promise);
    }
  }, [dispatch, services, sheduleInfoCache.didInvalidate, wrapAsyncSafely]);

  React.useEffect(() => {
    if (sheduleEditorState.submited) {
      const promise = updateSheduleInfoServiceThunk(
        services.updateSheduleInfo(sheduleEditorState.formValues)
      )(dispatch);

      wrapAsyncSafely(promise);
    }
  }, [
    dispatch,
    services,
    sheduleEditorState.formValues,
    sheduleEditorState.submited,
    wrapAsyncSafely
  ]);

  return (
    <SettingsBlockWrapper>
      <SettingsBlockContent position="left">
        <SettingsBlockTitle text={ownIntl.title} />
        <SettingsBlockDescription text={ownIntl.description} />
      </SettingsBlockContent>
      <SettingsBlockContent position="left">
        <SheduleEditorForm />
      </SettingsBlockContent>
    </SettingsBlockWrapper>
  );
};
