import { UserInfo } from 'types/state';

export const createUserInfoMock = (): UserInfo => ({
  id: 1,
  lastName: 'Крутов',
  firstName: 'Михаил',
  role: 'ADMINISTRATOR',
  phoneNumber: 'test1',
  login: 'manager1',
  blocked: false,
  enabledSections: [
    'applicationsRestaurant',
    'chatsRestaurant',
    'bookingRestaurant',
    'applicationsSport',
    'bookingClub',
    'chatsSport',
    'competitions',
    'news',
    'feedback',
    'members',
    'users'
  ]
});
