import React from 'react';

import styles from './SettingsForm.module.css';

type Props = {
  children: React.ReactNode;
  disabled: boolean;
};

export const SettingsFormFieldset: React.FC<Props> = props => {
  const { children, disabled } = props;

  return (
    <fieldset className={styles.SettingsForm__Fieldset} disabled={disabled}>
      <div className={styles.SettingsForm__FieldsetInner}>{children}</div>
    </fieldset>
  );
};
