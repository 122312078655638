import { AppEvent } from 'types/appEvent';
import { Cache } from 'types/util';
import { patchCache } from 'utils/patchCache';

export const newSportApplicationsCountReducer = (
  state: Cache<number>,
  event: AppEvent
): Cache<number> => {
  switch (event.name) {
    case 'BADGE_UPDATE_REQUESTED': {
      if (event.section === 'applicationsSport') {
        return patchCache(state)
          .invalidate()
          .done();
      }

      return state;
    }
    case 'NEW_APPLICATIONS_COUNT_SERVICE': {
      if (event.status === 'RESOLVED') {
        if (event.meta === 'CLUB') {
          return patchCache(state)
            .setValue(event.payload)
            .done();
        }

        return state;
      }

      return state;
    }
    default:
      return state;
  }
};
