import React from 'react';

import { TReview } from 'types/state';
import { ReviewItem } from 'containers/ReviewItem';

import { ReviewsFeedPreloader } from 'containers/ReviewsFeed/view/styled/ReviewsFeedPreloader';
import { ReviewsFeedWrapper } from 'containers/ReviewsFeed/view/styled/ReviewsFeedWrapper';
import { ReviewsFeedTitle } from 'containers/ReviewsFeed/view/styled/ReviewsFeedTitle';
import { ReviewsFeedContent } from 'containers/ReviewsFeed/view/styled/ReviewsFeedContent';

type TProps = {
  title: string;
  items: TReview[];
  selectedItemId: number;
  hasPreloader: boolean;
  onItemClick(id: number): void;
  onContentScroll(event: React.SyntheticEvent): void;
};

export type TReviewsFeedViewProps = TProps;

export const ReviewsFeedView = (props: TProps) => {
  const {
    items,
    title,
    selectedItemId,
    hasPreloader,
    onItemClick,
    onContentScroll
  } = props;

  const reviewItemsNode = React.useMemo(() => {
    return items.map(item => (
      <ReviewItem
        key={item.id}
        selected={item.id === selectedItemId}
        review={item}
        onClick={onItemClick}
      />
    ));
  }, [items, selectedItemId, onItemClick]);

  return (
    <ReviewsFeedWrapper>
      <ReviewsFeedTitle text={title} />
      <ReviewsFeedContent onScroll={onContentScroll}>
        {reviewItemsNode}
        {hasPreloader && <ReviewsFeedPreloader />}
      </ReviewsFeedContent>
    </ReviewsFeedWrapper>
  );
};
