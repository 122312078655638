import React from 'react';

import styles from './NewsPage.module.css';

type TProps = {
  children: React.ReactNode;
};

export const NewsPageRightSection = ({ children }: TProps) => (
  <div className={styles.NewsPage__RightSection}>{children}</div>
);
