import { useSectionsEditorState } from 'hooks/useSectionsEditorState';
import { useEnabledSectionsStrict } from 'hooks/useEnabledSections';

export const useSectionsFormSynced = () => {
  const cachedEnabledSections = useEnabledSectionsStrict();

  const { formValues } = useSectionsEditorState();

  const notSynced = Array.from(formValues.entries()).some(
    ([section, editedEnabled]) => {
      const cachedEnabled = cachedEnabledSections.includes(section);

      return cachedEnabled !== editedEnabled;
    }
  );

  return !notSynced;
};
