import React from 'react';

import { useToggler } from 'hooks/useToggler';
import { ArticlePreviewContentShadow } from 'components/ArticlePreviewContent/ArticlePreviewContentShadow';
import { ArticlePreviewContentScrollable } from 'components/ArticlePreviewContent/ArticlePreviewContentScrollable';

type TProps = {
  children: React.ReactNode;
  hasScrollShadow: boolean;
};

export const ArticlePreviewContent = ({
  children,
  hasScrollShadow
}: TProps) => {
  const [hasScrolledView, toggleHasScrolledView] = useToggler();

  const handleScroll = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (hasScrollShadow) {
        const node = e.currentTarget;
        const bottomReached =
          node.scrollHeight === node.scrollTop + node.clientHeight;
        if (
          (bottomReached && hasScrolledView) ||
          (!bottomReached && !hasScrolledView)
        ) {
          toggleHasScrolledView();
        }
      }
    },
    [hasScrolledView, hasScrollShadow, toggleHasScrolledView]
  );
  return (
    <ArticlePreviewContentShadow active={hasScrolledView}>
      <ArticlePreviewContentScrollable onScroll={handleScroll}>
        {children}
      </ArticlePreviewContentScrollable>
    </ArticlePreviewContentShadow>
  );
};
