import {
  TCompetitionsArticle,
  TCompetitionParticipantStatus
} from 'types/state';
import { NotFoundError } from 'utils/errors';
import { getUid } from 'utils/getUid';
import { COMPETITIONS_FEED_PAGE_LENGTH } from 'utils/constants';
import {
  TCompetitionsStoreMockRecord,
  getCompetitionsStoreRecordMocks,
  competitionParticipantsMock
} from 'services/mock/utils/competitionsInstances';

// В этом файле содержится in-memory база данных,
// которая используется mock-сервисами для имитации
// взаимодействия с сервером при dev-разработке.

// Меняя эту константу можно влиять на количество
// предварительно созданных записей в базе данных.
const INITIAL_STORE_LENGTH = 0;

// База данных.
let storeMock: TCompetitionsStoreMockRecord[] = [
  ...getCompetitionsStoreRecordMocks(INITIAL_STORE_LENGTH)
];

// Методы взаимодействия с базой данных:

const getAllArticles = () => {
  return [...storeMock];
};

const getArticlesByPage = (page: number) => {
  const startIndex = (page - 1) * COMPETITIONS_FEED_PAGE_LENGTH;
  const endIndex = startIndex + COMPETITIONS_FEED_PAGE_LENGTH;

  return storeMock.slice(startIndex, endIndex);
};

const getArticleById = (targetId: number) =>
  storeMock.find(({ id }) => id === targetId);

const saveArticle = (article: TCompetitionsArticle) => {
  const length = storeMock.push({
    ...article,
    id: getUid(),
    participants: [...competitionParticipantsMock],
    note: ''
  });
  return storeMock[length - 1];
};

const updateArticle = (article: TCompetitionsArticle) => {
  const targetIndex = storeMock.findIndex(({ id }) => id === article.id);
  if (targetIndex > -1) {
    storeMock[targetIndex] = {
      ...article,
      participants: [...storeMock[targetIndex].participants],
      note: ''
    };
    return storeMock[targetIndex];
  } else {
    throw new NotFoundError();
  }
};

const deleteArticle = (targetId: number) => {
  storeMock = storeMock.filter(({ id }) => id !== targetId);
};

const updateParticipantStatus = (
  articleId: number,
  participantId: number,
  nextStatus: TCompetitionParticipantStatus
) => {
  const targetIndex = storeMock.findIndex(({ id }) => id === articleId);
  if (targetIndex > -1) {
    const nextParticipants = [...storeMock[targetIndex].participants];
    const targetParticipantIndex = nextParticipants.findIndex(
      ({ id }) => id === participantId
    );
    if (targetParticipantIndex > -1) {
      nextParticipants[targetParticipantIndex] = {
        ...nextParticipants[targetParticipantIndex],
        status: nextStatus
      };
      storeMock[targetIndex] = {
        ...storeMock[targetIndex],
        participants: nextParticipants
      };
    } else {
      throw new NotFoundError();
    }
  } else {
    throw new NotFoundError();
  }
};

export const competitionsStoreMock = {
  getArticlesByPage,
  getAllArticles,
  getArticleById,
  saveArticle,
  updateArticle,
  updateParticipantStatus,
  deleteArticle
};
