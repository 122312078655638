import { ListCache } from 'types/util';
import { AppEvent } from 'types/appEvent';
import { modifyListCache, createListCache } from 'utils/listCacheUtils';
import { AppState } from 'types/appState';

export const activeChatRoomMessageIdsListReducer = (
  state: ListCache<number>,
  event: AppEvent,
  appState: AppState
): ListCache<number> => {
  switch (event.name) {
    case 'CHATS_PAGE_PREVIEW__CLICKED':
    case 'CHATS_PAGE__CONTENT_DID_MOUNT': {
      return modifyListCache(createListCache<number>())
        .invalidatePage(1)
        .return();
    }
    case 'CHATS_PAGE_MESSAGES__SCROLL_LEFT_TO_LOAD_MORE_REACHED': {
      return modifyListCache(state)
        .invalidateNextPage()
        .return();
    }
    case 'CHAT_ROOM_MESSAGES_SERVICE': {
      if (event.status === 'RESOLVED') {
        return modifyListCache(state)
          .putItems(
            event.meta.page,
            event.payload.map(message => message.id)
          )
          .return();
      }

      return state;
    }
    case 'CHAT_MESSAGE_SUBMISSION_SERVICE': {
      if (event.status === 'RESOLVED') {
        return modifyListCache(state)
          .invalidateAllPages()
          .return();
      }

      return state;
    }
    case 'CHAT_MESSAGE_RECIEVED': {
      if (event.roomId === appState.chatsState.activeChatRoom.id) {
        return modifyListCache(state)
          .invalidateAllPages()
          .return();
      }

      return state;
    }
    case 'CHAT_MARKED_AS_READ': {
      if (
        event.roomId === appState.chatsState.activeChatRoom.id &&
        event.origin === 'CLIENT'
      ) {
        return modifyListCache(state)
          .invalidateAllPages()
          .return();
      }

      return state;
    }
    default:
      return state;
  }
};
