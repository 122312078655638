import React from 'react';

import { TIntl } from 'types/intl';
import { TServices } from 'types/services';
import { ServicesContext } from 'contexts/ServicesContext';
import { IntlContext } from 'contexts/IntlContext';
import { ModalRoot } from 'components/shared/Modal';
import { Root } from 'containers/Root';
import { Notification } from 'containers/Notification';
import { Theme } from 'components/Theme';
import { RouterProvider } from 'providers/RouterProvider';
import { AppStoreProvider } from 'providers/AppStoreProvider';
import { useAppHooks } from 'hooks/useAppHooks';
import { ErrorBoundaryComponent } from 'components/ErrorBoundary';
import { AppFallbackComponent } from 'containers/App/AppFallbackComponent';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type Props = {
  children?: React.ReactNode;
  intl: TIntl;
  services: TServices;
};

const AppProvider: React.FC<Props> = ({ intl, services, children }) => (
  // @ts-ignore (попробоавть обновить роутер)
  <RouterProvider>
    <DndProvider backend={HTML5Backend}>
      <ServicesContext.Provider value={services}>
        <IntlContext.Provider value={intl}>
          <AppStoreProvider>
            <Theme>{children}</Theme>
          </AppStoreProvider>
        </IntlContext.Provider>
      </ServicesContext.Provider>
    </DndProvider>
  </RouterProvider>
);

const AppView = () => {
  useAppHooks();

  return (
    <>
      <Root />
      <ModalRoot />
      <Notification />
    </>
  );
};

export const App = (props: Props) => {
  return (
    <AppProvider {...props}>
      <ErrorBoundaryComponent
        errorConstructorsToCatch={[Error]}
        fallbackComponent={AppFallbackComponent}
      >
        <AppView />
      </ErrorBoundaryComponent>
    </AppProvider>
  );
};
