import React from 'react';

import { MessageTemplatesActionsLayout } from 'design/messageTemplatesActionsLayout';
import { ActionControl } from 'design/actionControl';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useMessageTemplatesState } from 'hooks/messageTemplates/useMessageTemplatesState';
import { useServices } from 'hooks/useServices';
import { createListCacheSelectors } from 'utils/listCacheUtils';
import { Text } from 'design/text';
import { useIntlContext } from 'hooks/useIntlContext';
import { useAddMessageTemplateService } from 'hooks/messageTemplates/useAddMessageTemplateService';
import { useSafeAsyncWrapper } from 'hooks/useSafeAsyncWrapper';
import { PlusIcon } from 'svg/20x20/plus';
import { PencilIcon } from 'svg/24x24/pencil';
import { CloseIcon } from 'svg/25x25/close';
import { CheckIcon } from 'svg/24x24/check';

export const MessageTemplatesActions = () => {
  const {
    mode,
    templateCandidateText,
    changedTemplates,
    messageTemplatesList,
    deletedTemplateIds
  } = useMessageTemplatesState();

  const { messageTemplates } = useIntlContext();

  const { editMessageTemplates: editMessageTemplatesService } = useServices();

  const dispatch = useAppDispatch();

  const addMessageTemplate = useAddMessageTemplateService();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const editMessageTemplates = React.useCallback(() => {
    const promise = editMessageTemplatesService({
      changedTemplates: changedTemplates,
      deletedTemplateIds: deletedTemplateIds
    }).then(() => {
      dispatch({
        name: 'MESSAGE_TEMPLATES__TEMPLATES_EDITED'
      });
    });

    wrapAsyncSafely(promise);
  }, [
    changedTemplates,
    deletedTemplateIds,
    dispatch,
    editMessageTemplatesService,
    wrapAsyncSafely
  ]);

  const handleAddClick = React.useCallback(() => {
    dispatch({
      name: 'MESSAGE_TEMPLATES__ADD_CLICKED'
    });
  }, [dispatch]);

  const handleEditClick = React.useCallback(() => {
    dispatch({
      name: 'MESSAGE_TEMPLATES__EDIT_CLICKED'
    });
  }, [dispatch]);

  const handleCancelClick = React.useCallback(() => {
    dispatch({
      name: 'MESSAGE_TEMPLATES__CANCEL_CLICKED'
    });
  }, [dispatch]);

  const handleSubmitClick = React.useCallback(() => {
    if (mode === 'ADD') {
      addMessageTemplate();
    }

    if (mode === 'EDIT') {
      editMessageTemplates();
    }
  }, [addMessageTemplate, editMessageTemplates, mode]);

  const hasEditAction =
    createListCacheSelectors(messageTemplatesList).selectAllItems().length > 0;

  return (
    <MessageTemplatesActionsLayout>
      {mode === 'VIEW' && (
        <>
          <ActionControl theme="round-primary-alt" onClick={handleAddClick}>
            <PlusIcon />
          </ActionControl>
          {hasEditAction ? (
            <ActionControl
              theme="round-secondary-alt"
              onClick={handleEditClick}
            >
              <PencilIcon />
            </ActionControl>
          ) : (
            <Text>{messageTemplates.addTemplateLabel}</Text>
          )}
        </>
      )}
      {mode === 'EDIT' && (
        <>
          <ActionControl theme="round-cancel" onClick={handleCancelClick}>
            <CloseIcon />
          </ActionControl>
          <ActionControl theme="round-primary" onClick={handleSubmitClick}>
            <CheckIcon />
          </ActionControl>
        </>
      )}
      {mode === 'ADD' && (
        <>
          <ActionControl theme="round-cancel" onClick={handleCancelClick}>
            <CloseIcon />
          </ActionControl>
          {!!templateCandidateText.trim() && (
            <ActionControl theme="round-primary" onClick={handleSubmitClick}>
              <CheckIcon />
            </ActionControl>
          )}
        </>
      )}
    </MessageTemplatesActionsLayout>
  );
};
