import { getSportingAuthURL } from 'services/sporting/utils/getSportingURL';
import {
  AuthorizationError,
  HTTPConnectionError,
  UnexpectedError
} from 'utils/errors';

const { NODE_ENV } = process.env;

const shouldIncludeCredentials = NODE_ENV === 'development';

const extendToken = (() => {
  let tokenExtensionPromise: Promise<void> | null = null;

  const extendSportingToken = async () => {
    const endpointURL = getSportingAuthURL('extendToken');

    const response = await fetch(endpointURL, {
      method: 'POST',
      credentials: shouldIncludeCredentials ? 'include' : undefined
    });

    if (!response.ok) {
      if (response.status === 401) {
        throw new AuthorizationError();
      }

      throw new UnexpectedError();
    }
  };

  return () => {
    if (tokenExtensionPromise === null) {
      tokenExtensionPromise = extendSportingToken().finally(() => {
        tokenExtensionPromise = null;
      });
    }

    return tokenExtensionPromise;
  };
})();

export const customFetch = async (
  url: RequestInfo,
  params?: RequestInit
): Promise<Response> => {
  const finalParams: RequestInit = {
    ...params,
    credentials: shouldIncludeCredentials ? 'include' : undefined
  };

  return fetch(url, finalParams)
    .catch(error => {
      // TypeError-ошибки возникают в случае косяков со стороны
      // программиста, вроде неправильного метода запроса,
      // так что эти ошибки лучше бросать как есть.
      if (error instanceof TypeError) {
        throw error;
      }

      throw new HTTPConnectionError();
    })
    .then(response => {
      if (response.status === 401) {
        return extendToken().then(() => {
          return fetch(url, finalParams);
        });
      }

      return response;
    });
};
