import { TUpdateReservationParams } from '../../../types/services';
import { sportingAPI } from '../api';
import { normalizeBookingReservationFormValues } from '../utils/normalizeBookingReservationFormValues';

export const updateReservation = async (
  params: TUpdateReservationParams
): Promise<void> => {
  const { formValues } = params;
  const payload = normalizeBookingReservationFormValues(
    formValues
  ).toSportingUpdateReservationPayload();
  await sportingAPI.booking.updateReservation({ payload });
};
