import { useRouteMatch } from 'react-router-dom';
import { ServiceScope } from 'types/serviceScope';
import { authorizedRoutes } from 'utils/authorizedRoutes';

export const useChatDomain = (): ServiceScope => {
  const restaurantMatch = useRouteMatch(
    authorizedRoutes.chatsRestaurantPattern()
  );

  const sportMatch = useRouteMatch(authorizedRoutes.chatsSportPattern());

  if (restaurantMatch) {
    return 'RESTAURANT';
  }

  if (sportMatch) {
    return 'CLUB';
  }

  throw new TypeError();
};
