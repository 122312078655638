import React from 'react';

import { useServices } from 'hooks/useServices';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSession } from './useSession';
import { useSafeAsyncWrapper } from './useSafeAsyncWrapper';

export const useSessionService = () => {
  const session = useSession();

  const wrapAsyncSafely = useSafeAsyncWrapper();

  const { getPersistedSession } = useServices();

  const dispatch = useAppDispatch();

  const shouldPull = session === null;

  React.useEffect(() => {
    if (shouldPull) {
      wrapAsyncSafely(
        getPersistedSession().then(session => {
          dispatch({
            name: 'SESSION_RECIEVED',
            session
          });
        })
      );
    }
  }, [dispatch, getPersistedSession, shouldPull, wrapAsyncSafely]);
};
