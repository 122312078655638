import React from 'react';

import { TypingIndicatorLayout } from 'design/typingIndicatorLayout';
import { AnimatedDots } from 'design/animatedDots';
import { Text } from 'design/text';

import { useIntlContext } from 'hooks/useIntlContext';
import { useTypingUserNames } from 'hooks/chats/useTypingUserNames';

type Props = {
  roomId: number;
};

export const ChatsPageTypingIndicator = (props: Props) => {
  const { roomId } = props;

  const userNames = useTypingUserNames(roomId);

  const intl = useIntlContext();

  const text = userNames ? intl.chatsPage.typingText(userNames) : '';

  return (
    <TypingIndicatorLayout
      text={
        text ? (
          <Text color="secondary" size="s">
            {text}
            <AnimatedDots />
          </Text>
        ) : (
          ' '
        )
      }
    />
  );
};
