import React from 'react';

import styles from './ClipboardData.module.scss';

type TProps = {
  value: React.ReactNode;
};

export const ClipboardDataText = ({ value }: TProps) => (
  <div className={styles.ClipboardData__Text}>{value}</div>
);
