import React from 'react';
import FocusLock from 'react-focus-lock';

import styles from './Notification.module.css';

type TProps = {
  children: React.ReactNode;
  hasOverlay: boolean;
  testId?: string;
};

export const NotificationWrapper = ({
  hasOverlay,
  children,
  testId
}: TProps) => {
  const wrapperNode = React.useMemo(
    () => (
      <div
        className={`${styles.Notification__Wrapper} ${
          hasOverlay ? styles.Notification__Wrapper_overlay : ''
        }`}
        data-testid={testId}
      >
        {children}
      </div>
    ),
    [hasOverlay, children, testId]
  );

  if (hasOverlay) {
    return <FocusLock>{wrapperNode}</FocusLock>;
  }
  return wrapperNode;
};
