import React from 'react';

import styles from './Navigation.module.scss';
import { ReactComponent as LogoDesktop } from './NavigationLogo-lg.svg';
import { ReactComponent as LogoMobile } from './NavigationLogo-sm.svg';

import { multiplyClassName } from 'utils/className';

type Props = {
  children: React.ReactNode;
  testId: string;
};

export const NavigationWrapper: React.FC<Props> = ({ children, testId }) => (
  <div className={styles.Navigation__Wrapper} data-testid={testId}>
    <LogoDesktop
      className={multiplyClassName([
        styles.Navigation__Logo,
        styles.Navigation__Logo_Desktop
      ])}
    />
    <LogoMobile
      className={multiplyClassName([
        styles.Navigation__Logo,
        styles.Navigation__Logo_Mobile
      ])}
    />
    {children}
  </div>
);
