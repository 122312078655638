import React from 'react';

import { useOutsideClick } from 'hooks/useOutsideClick';

import styles from './CompetitionDurationInput.module.css';

type TProps = {
  children: React.ReactNode;
  onOutsideClick(): void;
};

export const CompetitionDurationInputWrapper = ({
  children,
  onOutsideClick
}: TProps) => {
  const wrapperNodeRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick(wrapperNodeRef, onOutsideClick);

  return (
    <div
      ref={wrapperNodeRef}
      className={styles.CompetitionDurationInput__Wrapper}
    >
      {children}
    </div>
  );
};
