import { UpdateAdministratorInfoParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const updateAdministratorInfo = async (
  params: UpdateAdministratorInfoParams
): Promise<void> => {
  const { userId, nextPassword, nextPhoneNumber } = params;

  // WARNING: при параллельной отправке запросов не сохранялся
  // новый телефонный номер.
  if (nextPassword) {
    await sportingAPI.service.changePasswordForAdmin({
      payload: { idUser: userId, newPassword: nextPassword }
    });
  }

  if (nextPhoneNumber) {
    const internalNumber =
      nextPhoneNumber === 'null' ? undefined : nextPhoneNumber;

    await sportingAPI.service.attachDevice({
      payload: {
        idUser: userId,
        internalNumber
      }
    });
  }
};
