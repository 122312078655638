import {
  isToday,
  isYesterday,
  createDateText,
  isWithinCurrentYear
} from 'utils/dateUtils';
import { TIntl } from 'types/intl';

export const createChatMessageDateText = (date: string, intl: TIntl) => {
  const publishDateObj = new Date(date);

  const wasPublishedToday = isToday(publishDateObj);

  if (wasPublishedToday) {
    return intl.today;
  }

  const wasPublishedYesterday = isYesterday(publishDateObj);

  if (wasPublishedYesterday) {
    return intl.yesterday;
  }

  const wasPublishedWithinCurrentYear = isWithinCurrentYear(publishDateObj);

  if (wasPublishedWithinCurrentYear) {
    return `${publishDateObj.getDate()} ${
      intl.predicativeMonthNames[publishDateObj.getMonth()]
    }`;
  }

  return createDateText(publishDateObj);
};
