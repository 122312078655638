import { TCompetitionParticipant } from 'types/state';
import { TSportingCompetitionParticipant } from 'services/sporting/types';
import { normalizeSportingCompetitionParticipantStatus } from 'services/sporting/utils/normalizeSportingCompetitionParticipantStatus';

export const normalizeSportingCompetitionParticipant = {
  toCompetitionParticipant(
    item: TSportingCompetitionParticipant
  ): TCompetitionParticipant {
    return {
      id: item.id,
      name: `${item.user.lastName} ${item.user.name}`,
      phone: item.user.mobilePhone,
      status: normalizeSportingCompetitionParticipantStatus.toParticipantStatus(
        item.actualStatus
      )
    };
  }
};
