import React from 'react';

import styles from './CompetitionsFeed.module.css';

import { CreateButton } from 'components/shared/CreateButton';
import { testId } from 'utils/testId';

type TProps = {
  onClick(): void;
};

export const CompetitionsFeedCreateButton = ({ onClick }: TProps) => (
  <CreateButton
    testId={testId.COMPETITIONS_FEED_CREATE_ARTICLE_BUTTON}
    className={styles.CompetitionsFeed__CreateItemButton}
    onClick={onClick}
  />
);
