import { TSetCompetitionNoteParams } from 'types/services';
import { sportingAPI } from 'services/sporting/api';

export const setCompetitionNote = async (
  params: TSetCompetitionNoteParams
): Promise<void> => {
  const { id, note } = params;

  await sportingAPI.service.setCompetitionNote({
    payload: { idCompetition: id, note }
  });
};
