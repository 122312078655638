import React from 'react';

import { ArticleEditorFooterWrapper } from 'components/ArticleEditorFooter/ArticleEditorFooterWrapper';
import { ArticleEditorFooterSubmitDraftButton } from 'components/ArticleEditorFooter/ArticleEditorFooterSubmitDraftButton';
import { ArticleEditorFooterCancelButton } from 'components/ArticleEditorFooter/ArticleEditorFooterCancelButton';
import { ArticleEditorFooterSubmitButton } from 'components/ArticleEditorFooter/ArticleEditorFooterSubmitButton';
import { testId } from 'utils/testId';

type TProps = {
  hasSubmitButtonPreloader: boolean;
  hasSubmitDraftButton: boolean;
  hasSubmitDraftButtonPreloader: boolean;
  isSubmitButtonDisabled: boolean;
  handleCancel(): void;
  handleSubmit(): void;
  handleSubmitDraft(): void;
};

export const CompetitionsPreviewFooterView = (props: TProps) => {
  const {
    handleCancel,
    handleSubmit,
    handleSubmitDraft,
    hasSubmitButtonPreloader,
    hasSubmitDraftButton,
    hasSubmitDraftButtonPreloader,
    isSubmitButtonDisabled
  } = props;

  return (
    <ArticleEditorFooterWrapper>
      {hasSubmitDraftButton && (
        <ArticleEditorFooterSubmitDraftButton
          hasPreloader={hasSubmitDraftButtonPreloader}
          onClick={handleSubmitDraft}
        />
      )}
      <ArticleEditorFooterCancelButton
        testId={testId.COMPETITIONS_ARTICLE_EDITOR_CANCEL_BUTTON}
        onClick={handleCancel}
      />
      <ArticleEditorFooterSubmitButton
        onClick={handleSubmit}
        isDisabled={isSubmitButtonDisabled}
        hasPreloader={hasSubmitButtonPreloader}
      />
    </ArticleEditorFooterWrapper>
  );
};
